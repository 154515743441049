import classes from "../GpiCopyFormModal.module.scss";

const RadioDetails = ({
  fieldValue,
  getEquipmentRadioTypes,
  getSelectedRadioTechnologies,
  getModulationTypes,
}) => {
  return (
    <div className={classes["details-content-container"]}>
      <div className={classes["details-field"]}>
        Equipment Radio Type: <span>{getEquipmentRadioTypes(fieldValue)}</span>
      </div>

      <div className={classes["details-field"]}>
        Radio Specification Comment:{" "}
        <span>{fieldValue?.radioSpecificationComment}</span>
      </div>

      <hr></hr>

      {fieldValue?.radios &&
        fieldValue.radios?.map((data, i) => {
          return (
            <div key={data.gPIRadioDetailsId}>
              <div className={classes["details-header"]}>Radio {i + 1}</div>
              <div className={classes["details-field"]}>
                Radio Technologies:{" "}
                <span>{getSelectedRadioTechnologies(data)}</span>
              </div>

              <div className={classes["details-field"]}>
                Frequency of Operation: <span>{data.operationFrequency}</span>
              </div>

              <div className={classes["details-field"]}>
                Antenna Gain: <span>{data.antennaGain}</span>
              </div>

              <div className={classes["details-field"]}>
                Modulation Types: <span>{getModulationTypes(data)}</span>
              </div>

              <div className={classes["details-field"]}>
                Emission Designation: <span>{data.emissionDesignation}</span>
              </div>

              <div className={classes["details-field"]}>
                Transmit of Power or Power Range:{" "}
                <span>{data.transmissionPowerOrPowerRange}</span>
              </div>

              <div className={classes["details-field"]}>
                Bandwidth: <span>{data.bandwidth}</span>
              </div>

              <div className={classes["details-field"]}>
                Channel Spacing: <span>{data.channelSpacing}</span>
              </div>

              <hr></hr>
            </div>
          );
        })}
    </div>
  );
};

export default RadioDetails;
