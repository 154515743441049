import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import classes from "./ProjectCreationRestrictionModal.module.scss";
import { ProjectCreationRestrictionModel } from "./ProjectCreationRestrictionModel";

interface ProjectCreationRestrictionModalProps {
  onClose: () => void;
  visible: boolean;
  projectCreationRestrictionModel: ProjectCreationRestrictionModel;
}

const ProjectCreationRestrictionModal = (
  props: ProjectCreationRestrictionModalProps
) => {

  const headerTemplate = () => {
    return (
      <h3 className={classes["dialog-header__title"]}>
        {props.projectCreationRestrictionModel.hasMultipleServiceLines &&
            !props.projectCreationRestrictionModel.hasInvalidIndustryCode && <label>FLEX Project With Multiple Order Lines</label> }

          {props.projectCreationRestrictionModel.hasInvalidIndustryCode && 
            !props.projectCreationRestrictionModel.hasMultipleServiceLines && <label>Invalid Industry Code</label> }

          {props.projectCreationRestrictionModel.hasInvalidIndustryCode &&
            props.projectCreationRestrictionModel.hasMultipleServiceLines && <label>Invalid Industry Code and Multiple Order Lines Detected</label>}
      </h3>
    );
  };

  const footerTemplate = () => {
    return (
      <div className={classes["dialog-footer"]}>
        <Button
          onClick={props.onClose}
          className={classes["btn-ok"]}
          label={"OK"}
        />
      </div>
    );
  };

  const invalidIndustryCodeMessage = (
    <>
     <label className={classes["verbiage"]}>
        The GMAWB project cannot be created, because the selected order line(s) 
        contain the wrong Industry code. Only the following 
        order lines are valid for GMAWB Project Creation:
     </label>
     <label className={classes["verbiage"]}>
          For Service Lines 256, 260 and 712: Industry Code "111" only <br/>
          For Service Line 763: any Industry code
     </label>
     <label className={classes["verbiage"]}>
          Please reach out to the FLEX Order Creator to correct this.
     </label>
    </>
  );

  const multipleServiceLineMessage = (
    <>
      <label className={classes["verbiage"]}>
        The GMAWB project cannot be created, because the current FLEX Project
        contains multiple GMA Order Lines. <br />
      </label>
      <label className={classes["verbiage"]}>
        Please reach out to the FLEX Order Creator to request a single FLEX
        Project per GMA Order Line.
      </label>
    </>
  );

  const multipleServiceLineAndInvalidIndustryCodeMessage = (
    <>
  <label className={classes["verbiage"]}>
      <strong>The GMAWB project cannot be created due to the following issues:</strong>
      <br/>
      <label style={{textIndent: "1rem"}}>
          1. The selected order line(s) contain the wrong Industry code. <br/>
          2. The current FLEX Project contains multiple GMA Order Lines.
      </label>
    </label>

    <label className={classes["verbiage"]}>
    <strong>Please note the following requirements for valid GMAWB Project creation:</strong>
      <br/>
      <label style={{textIndent: "1rem"}}>
      - For Service Lines 256, 260 and 712: Industry Code "111" only <br/>
      - For Service Line 763: any Industry code
      </label>
    </label>

    <label className={classes["verbiage"]}>
    <strong>Action Required:</strong>
    <br/>
      <label style={{textIndent: "1rem"}}>
        - Contact the FLEX Order Creator to correct the Industry code(s). <br/>
        - Request a single FLEX Project per GMA Order Line.
      </label>
    </label>

    <label className={classes["verbiage"]}>
      Once these issues are resolved, you can proceed with the GMAWB project creation.
    </label>
  </>
  );

  return (
    <Dialog
      className={classes["dialog"]}
      style={{ width: "45rem" }}
      visible={props.visible}
      onHide={props.onClose}
      header={headerTemplate}
      footer={footerTemplate}
    >
      <div className={classes["dialog-body"]}>
          {props.projectCreationRestrictionModel.hasMultipleServiceLines &&
            !props.projectCreationRestrictionModel.hasInvalidIndustryCode && multipleServiceLineMessage }

          {props.projectCreationRestrictionModel.hasInvalidIndustryCode && 
            !props.projectCreationRestrictionModel.hasMultipleServiceLines && invalidIndustryCodeMessage }

          {props.projectCreationRestrictionModel.hasInvalidIndustryCode &&
            props.projectCreationRestrictionModel.hasMultipleServiceLines && multipleServiceLineAndInvalidIndustryCodeMessage}

      </div>
    </Dialog>
  );
};

export default ProjectCreationRestrictionModal;
