import React, { useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import "./CertificateListGrid.scss";
import CertificateListHeader from "../certificate-list-header/CerificatetListHeader";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateSelectedCertificateId } from "../../../../features/certificateList/certificateListSlice";
import { updatePartySiteNumber } from "../../../../features/createCertificate/createCertificateSlice";
import GridAction from "./grid-action/GridAction";
import CustomPaginator from "../../../../shared/custom-paginator/CustomPaginator";

const CertificateListGrid = (props) => {
  const dispatch = useDispatch();
  const clv = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dropdownOptions, setDropDownOptions] = useState([
    { label: 100, value: 100 },
    { label: 500, value: 500 },
    { label: 1000, value: 1000 },
  ]);
  const [startWidth, setStartWidth] = useState(0);

  const [dataTableData, setDataTableData] = useState([]);
  const [isDragResizing, setIsDragResizing] = useState(false);
  const [draggedColumn, setDraggedColumn] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const childPopOverRef = useRef();

  const {
    loading,
    setLoading,
    currentPageRef,
    columns,
    setColumns,
    frozenColCount,
    certListData,
    refiners,
    statusRefiners,
    customFilters,
    globalSearchText,
    activeCount,
    expiringSoonCount,
    withDrawnCount,
    underRevisionCount,
    obsoleteCount,
    totalCertificateCount,
    totalPageCount,
    pageCount,
    currentPage,
    lazyParams,
    favoriteName,
    favoriteList,
    onClickStatusFilter,
    onChangePage,
    setCertListColumns,
    setCertFrozenCount,
    setShowCertificateDetails,
    onSaveFavorite,
    onEditFavorite,
    onDeleteFavorite,
    onClickFavorites,
    onClearFilters,
    onActivateCertificate,
    onDeleteCertificate,
    isReadOnly
  } = props;

  const stockBodyTemplate = (rowData, col) => {
    const stockClassName = classNames({
      "text-overflow-ellipsis": true,
      "td-active":
        rowData &&
        rowData.certStatus &&
        rowData.certStatus.toLowerCase() === "active",
      "td-expiring-soon":
        rowData &&
        rowData.certStatus &&
        rowData.certStatus.toLowerCase() === "expiring soon",
      "td-under-revision":
        rowData &&
        rowData.certStatus &&
        rowData.certStatus.toLowerCase() === "under revision",
      "td-obsolete":
        rowData &&
        rowData.certStatus &&
        rowData.certStatus.toLowerCase() === "obsolete",
      "td-withdrawn":
        rowData &&
        rowData.certStatus &&
        rowData.certStatus.toLowerCase() === "withdrawn",
    });

    return (
      <div title={rowData[col.field]} className={stockClassName}>
        {rowData[col.field]}
      </div>
    );
  };

  const getColumnWithPx = (width) => {
    let final = width - 25;
    return final + "px";
  };

  const onStartDragResize = (e, data, col, colIndex) => {
    setDraggedColumn(col.id);
    setStartWidth(col.widthNum);
    setIsDragResizing(true);
  };

  const onDragResize = (e, data, col, colIndex) => {
    if (isDragResizing) {
      setColumns((prevState) => {
        let newState = JSON.parse(JSON.stringify(prevState));

        const resultingWidthNum = newState[colIndex].widthNum + data.x;

        if (resultingWidthNum >= startWidth) {
          newState[colIndex].width = resultingWidthNum + "px";
        }

        return newState;
      });
      setTimeout(() => {
        setIsDragResizing(false);
        setDraggedColumn("");
      }, 5000);
    }
  };

  const dynamicColumns = columns.map((col, i) => {
    let leftProp = getColumnWithPx(col.widthNum);
    return (
      <Column
        sortable
        key={col.id}
        columnKey={col.id}
        field={col.id}
        header={() => (
          <div>
            <span title={col.value}>{col.value}</span>

            {col.isFrozen && (
              <>
                <div
                  style={{
                    position: "absolute",
                    left: leftProp,
                    top: "0",
                    zIndex: "99999 !important",
                    cursor: "col-resize",
                  }}
                ></div>
              </>
            )}
          </div>
        )}
        className={col.orderNo == frozenColCount && "last-frozen"}
        hidden={!col.isShown}
        alignFrozen="left"
        frozen={col.isFrozen}
        style={{ flexGrow: 1, flexBasis: col.width, width: col.width }}
        body={stockBodyTemplate.bind(col)}
      />
    );
  });

  const onColumnResize = (e) => {
    const { column, element } = e;

    const fields = columns.map((f) => {
      if (f.id === column.props.field) {
        return {
          ...f,
          width: element.offsetWidth,
        };
      } else {
        return f;
      }
    });

    setCertListColumns(fields);
  };

  const handleSort = (sortField, sortOrder) => {
    if (certListData.length === 0) {
      setDataTableData([]);
      return;
    }

    setSortField(sortField);
    setSortOrder(sortOrder);

    if (!sortField || !sortOrder) setDataTableData(certListData);
    else {
      let sortedData = [];

      const isFieldNumerical = () => certListData.every(
        (x) => x[sortField].length == 0 || !isNaN(x[sortField])
      );
      const isFieldDate = () => certListData.every(
        (x) => !x[sortField] || (new Date(x[sortField]) != "Invalid Date" && isNaN(x[sortField]))
      );
      
      if (isFieldNumerical()) {
        sortedData = [...certListData].sort((a, b) => {
          const toNum = (value) => {
            return isNaN(value) ? 0 : parseInt(value);
          };

          const x = toNum(a[sortField]);
          const y = toNum(b[sortField]);

          return sortOrder == 1 ? x - y : y - x;
        });
      } else if (isFieldDate()) {
        sortedData = [...certListData].sort((a, b) => {
          const toDate = (value) => {
            return new Date(value) == "Invalid Date"
              ? new Date(0)
              : new Date(value);
          };

          const x = toDate(a[sortField]);
          const y = toDate(b[sortField]);

          return sortOrder == 1 ? x - y : y - x;
        });
      } else {
        sortedData = [...certListData].sort((a, b) => {
          return sortOrder == 1
          ? a[sortField].localeCompare(b[sortField])
          : b[sortField].localeCompare(a[sortField]);
        });
      }

      setDataTableData(sortedData);
    }
  };

  const onColReorder = (e) => {
    if (e.dropIndex === 0 || e.dropIndex === columns.length + 1) {
      clv.current.reset();
    } else {
      let certColumns = columns.map((col, i) => {
        const orderNo = e.columns.findIndex((fi) => fi.props.field === col.id);

        return {
          ...col,
          orderNo: orderNo,
        };
      });

      certColumns.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });

      updateCertListColumns(frozenColCount, certColumns);
    }
  };

  const updateCertListColumns = (frozenCount, certFields) => {
    const fields = certFields.map((f) => {
      if (f.orderNo <= frozenCount) {
        return {
          ...f,
          isShown: true,
          isFrozen: true,
        };
      } else {
        return {
          ...f,
          isFrozen: false,
        };
      }
    });

    setCertListColumns(fields);
    setCertFrozenCount(frozenCount);
  };

  // HACK: Workaround for incorrect left position computation of primereact datatable
  // when arranging frozen columns.
  useEffect(() => {
    setTimeout(() => {
      var frozenColumns = document.querySelectorAll("th.p-frozen-column");
      var currentLeft = 0;

      for (let x = 0; x < frozenColumns.length; x++) {
        const frozenCol = frozenColumns[x];
        frozenCol.style.left = currentLeft + "px";
        currentLeft += frozenCol.offsetWidth;
      }

      setTimeout(() => {
        for (let x = 0; x < frozenColCount; x++) {
          clv.current.resetColumnOrder();
        }
      });
    });
  }, [columns]);

  useEffect(() => {
    handleSort(sortField, sortOrder);
  }, [certListData]);

  const onRowDoubleClick = (event) => {
    const { data } = event;
    dispatch(updateSelectedCertificateId(data.certificateId));
    dispatch(updatePartySiteNumber(data.ownerReference));
    setShowCertificateDetails(true);
  };

  const onClickIncreaseWidth = () => {
    let newColumns = JSON.parse(JSON.stringify(columns));
    newColumns[0].width = "300px";
    setColumns(newColumns);
  };

  const onActionButtonClick = (event, row) => {
    setSelectedData(row);
    childPopOverRef.current.openPopUp(event);
  };

  return (
    <>
      <div className="ul-grid__column -x100 -x100-mobile">
        <div className="ul-grid__row -wrap">
          <CertificateListHeader
            columns={columns}
            activeCount={activeCount}
            frozenColCount={frozenColCount}
            expiringSoonCount={expiringSoonCount}
            withDrawnCount={withDrawnCount}
            underRevisionCount={underRevisionCount}
            obsoleteCount={obsoleteCount}
            totalCertificateCount={totalCertificateCount}
            favoriteName={favoriteName}
            favoriteList={favoriteList}
            onClickStatusFilter={onClickStatusFilter}
            updateCertListColumns={updateCertListColumns}
            setCertListColumns={setCertListColumns}
            onSaveFavorite={onSaveFavorite}
            onEditFavorite={onEditFavorite}
            onDeleteFavorite={onDeleteFavorite}
            onClickFavorites={onClickFavorites}
            onClearFilters={onClearFilters}
            dataTableData={dataTableData}
            selectedRows={selectedRows}
            customFilters={customFilters}
            globalSearchText={globalSearchText}
            refiners={refiners}
            statusRefiners={statusRefiners}
          />
        </div>

        <div className="ul-grid__row -wrap">
          <div className="ul-grid__column -x100 -x100-mobile  cert-list-grid-container">
            <DataTable
            frozenWidth={"650px"}
              ref={clv}
              scrollable
              removableSort
              scrollDirection="both"
              value={dataTableData}
              size="small"
              lazy
              sortField={sortField}
              sortOrder={sortOrder}
              onPage
              resizableColumns
              reorderableColumns
              onColumnResizeEnd={onColumnResize}
              onColReorder={onColReorder}
              onSort={(e) => handleSort(e.sortField, e.sortOrder)}
              columnResizeMode="expand"
              responsiveLayout="scroll"
              dataKey="certificateId"
              selection={selectedRows}
              onSelectionChange={(e) => setSelectedRows(e.value)}
              onRowDoubleClick={onRowDoubleClick}
              loading={loading}
            >
              <Column
                selectionMode="multiple"
                columnKey="select"
                alignFrozen="left"
                reorderable={false}
                frozen
                style={{ width: "40px" }}
                headerStyle={{ width: "40px" }}
              ></Column>
              {dynamicColumns}
              <Column
                reorderable={false}
                frozen
                alignFrozen="right"
                columnKey="elipsis"
                header=""
                style={{ width: "2.5rem", padding: "0 0.5rem 0 0.5rem" }}
                body={(rowData) => (
                  <div
                    className="td-action-item"
                    onClick={(e) => onActionButtonClick(e, rowData)}
                  >
                    <i className="pi pi-ellipsis-v"></i>
                  </div>
                )}
              />
            </DataTable>
            <CustomPaginator
              currentPageRef={currentPageRef}
              dropdownOptions={dropdownOptions}
              lazyParams={lazyParams}
              currentPage={currentPage}
              pageCount={pageCount}
              totalPageCount={totalPageCount}
              totalRecordCount={totalCertificateCount}
              onChangePage={onChangePage}
            ></CustomPaginator>
          </div>
        </div>
      </div>

      <GridAction
        isReadOnly={isReadOnly}
        setLoading={setLoading}
        childPopOverRef={childPopOverRef}
        selectedData={selectedData}
        onActivateCertificate={onActivateCertificate}
        setShowCertificateDetails={setShowCertificateDetails}
        onDeleteCertificate={onDeleteCertificate}
      ></GridAction>
    </>
  );
};

export default CertificateListGrid;
