import RequestFactory from "./RequestFactory";
import {LocalStorageModel} from "../shared/models/SaveLocalStorageModel";

const documentUrl = process.env.REACT_APP_DOCUMENT_URL;

const DocumentService = {
  getLocalStorage: async (packageId: any) =>
    await RequestFactory.get(
      documentUrl,
      `/document/get-local-storage?packageId=${packageId}`
    ),
  getIpModelDetails: async (assetId: any) =>
    await RequestFactory.get(
      documentUrl,
      `/ipintegration/get-ip-model-details?assetId=${assetId}`
    ),
  getIpAssetDetails: async (assetId: any, modelName: any, certificateId = null) =>
    await RequestFactory.get(
      documentUrl,
      `/ipintegration/get-ip-asset-details?assetId=${assetId}&modelName=${modelName}${
        certificateId ? `&certificateId=${certificateId}` : ""
      }`
    ),
  getIpCertificateProductStandard: async (certificateId: any, modelName: any) =>
    await RequestFactory.get(
      documentUrl,
      `/ipintegration/get-ip-certificate-product-standard?certificateId=${certificateId}&modelName=${modelName}`
    ),
  getListScheme: async () =>
    await RequestFactory.get(documentUrl, `/reference/listscheme`),
  getListPackageDetails: async (packageId:any ) =>
    await RequestFactory.get(
      documentUrl,
      `/workflow/listpackagedetail?packageId=${packageId}`
    ),

  saveLocalStorage: async (localStorageModel: LocalStorageModel) =>
      await RequestFactory.post(
        documentUrl,
        '/document/save-local-storage',
        localStorageModel
      )
};

export default DocumentService;
