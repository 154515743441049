import { Button } from "primereact/button";
import ULModal from "../../../../shared/ul-modal/ULModal";
import PmrE2EProjectDetailsOrderlineModel from "../../shared/interfaces/pmr-e2e-project-details-orderline-model";
import { Field, FieldProps, Form, FormikProvider, useFormik } from "formik";
import {
  InvoicePriceTemplateInput,
  defaultInvoicePriceAmountProps,
  invoicePriceValidationSchema,
  ulPayment,
} from "../invoice-price-template/InvoicePriceTemplate";
import { InputNumber } from "primereact/inputnumber";
import PmrMultiSelectCurrencies from "../../shared/components/pmr-multiselect-currencies/PmrMultiSelectCurrencies";
import classes from "./InvoicePriceModal.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrencies } from "../../../../features/projectManagementPmr/projectManagementPmrSlice";
import { useState } from "react";
import PmrE2EProjectModel from "../../shared/interfaces/pmr-e2e-project-model";
import clsx from "clsx";
import UpdateProjectlineCurrencyModel from "../../../../shared/models/currency-models/UpdateProjectlineCurrencyModel";
import { updateOrderLineInvoice } from "../../../../features/projectManagement/projectManagementSlice";
import CurrencyService from "../../../../services/CurrencyService";
import useToastr from "../../../../hooks/useToastr";

interface InvoicePriceModalProps {
  visible: boolean;
  onHide: () => void;
  data: PmrE2EProjectDetailsOrderlineModel[];
  project: PmrE2EProjectModel;
}
export default function InvoicePriceModal({
  visible,
  onHide: _onHide,
  project,
  data,
}: InvoicePriceModalProps) {
  const currencies = useSelector(selectCurrencies);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showSuccess } = useToastr();

  const defaultCurrencies = new Set(
    data.filter((item) => !!item.currency).map((item) => item.currency)
  );
  const defaultCurrency =
    defaultCurrencies.size === 1
      ? currencies.find((item) => item.code === [...defaultCurrencies][0])
      : null;

  const hasEdit = project.e2EProjectLineMilestoneGroupItems.some(
    (milestone) => milestone.name === ulPayment
  );

  const onHide = () => {
    formik.resetForm();
    _onHide();
  };

  const onSubmit = async (values: InvoicePriceTemplateInput) => {
    if (!formik.isValid) return;
    setIsSubmitting(true);
    try {
      if (!selectedCurrency) {
        console.warn("Currency not found");
        return;
      }

      const request = data.map(
        (item) =>
          ({
            e2eProjectLineId: item.id,
            invoicePriceAmount: values.invoicePriceAmount ?? null,
            invoiceCurrencyCode: selectedCurrency?.code,
            invoiceCurrencyId: selectedCurrency.id,
          } as UpdateProjectlineCurrencyModel)
      );

      await CurrencyService.updateProjectlineCurrency(request);
      dispatch(updateOrderLineInvoice(request));
      formik.resetForm();
      onHide();
      showSuccess(
        "Invoice Price Updated",
        `Invoice Price for selected Order Lines successfully updated.`
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formik = useFormik<InvoicePriceTemplateInput>({
    initialValues: {
      invoicePriceAmount: null,
      invoicePriceCurrency: defaultCurrency?.id,
    },
    onSubmit,
    validationSchema: invoicePriceValidationSchema,
    enableReinitialize: true,
  });

  const selectedCurrency = currencies.find(
    (item) => item.id === formik.values?.invoicePriceCurrency
  );
  return (
    <ULModal
      visible={visible && hasEdit}
      onHide={onHide}
      header="Input Invoice Price"
      footer={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            className="default-cancel-button"
            onClick={onHide}
            label="CANCEL"
          />
          <Button
            className="default-save-button"
            form="invoice-price-form-modal"
            type="submit"
            disabled={!formik.isValid}
            loading={isSubmitting}
            label="SAVE"
          />
        </div>
      }
    >
      <p style={{ marginBottom: "25px" }}>
        Enter Invoice Amount and Currency for selected Order Lines.
      </p>
      <FormikProvider value={formik}>
        <Form
          className={classes["invoice-price-form"]}
          id="invoice-price-form-modal"
        >
          <div className={classes["invoice-price-field"]}>
            <label htmlFor="invoicePriceAmount">Invoice Amount</label>
            <Field name="invoicePriceAmount">
              {({ field, meta, form }: FieldProps) => (
                <InputNumber
                  {...field}
                  {...defaultInvoicePriceAmountProps}
                  onChange={(event) =>
                    form.getFieldHelpers(field.name).setValue(event.value)
                  }
                  inputId="invoicePriceAmount"
                  style={{ width: "100%" }}
                  className={clsx(meta.error && "p-invalid")}
                  disabled={isSubmitting}
                />
              )}
            </Field>
          </div>

          <div className={classes["invoice-price-field"]}>
            <label htmlFor="invoicePriceCurrency">Currency</label>
            <Field name="invoicePriceCurrency">
              {({ field, meta }: FieldProps) => (
                <PmrMultiSelectCurrencies
                  {...field}
                  style={{
                    width: "100%",
                  }}
                  inputId="invoicePriceCurrency"
                  className={clsx(meta.error && "p-invalid")}
                  disabled={isSubmitting}
                />
              )}
            </Field>
          </div>
        </Form>
      </FormikProvider>
    </ULModal>
  );
}
