import { createSlice } from "@reduxjs/toolkit";
import {
  ProjectTemplateMapping,
  ProjectTemplateWrapper,
} from "./projectTemplateMappingSliceInterfaces";

const initialState: ProjectTemplateMapping = {};

export const createProjectTemplateMappingSlice = createSlice({
  name: "projectTemplateMapping",
  initialState: initialState as ProjectTemplateMapping,
  reducers: {
    resetTemplate: () => initialState,
    addToaster: (state, action) => {
      state.ToastMessage = action.payload;
    },
    removeToaster: (state) => {
      state.ToastMessage = undefined;
    },
  },
});

export const { addToaster, removeToaster } =
  createProjectTemplateMappingSlice.actions;

export const selectToaster = (state: ProjectTemplateWrapper) =>
  state.projectTemplateMapping.ToastMessage;

export default createProjectTemplateMappingSlice.reducer;
