import RequestFactory from "./RequestFactory";

const favoritesUrl = process.env.REACT_APP_GMAE2E_URL;

const FavoriteService = {
  get: async (favoriteTypesId) =>
    await RequestFactory.get(
      favoritesUrl,
      `/api/favorites/get?favoriteTypesId=${favoriteTypesId}`
    ),
  upsert: async (model) =>
    await RequestFactory.post(favoritesUrl, `/api/favorites/upsert`, model),

  delete: async (model) =>
    await RequestFactory.post(favoritesUrl, `/api/favorites/delete`, model)
};

export default FavoriteService;
