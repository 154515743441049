import { InputText } from "primereact/inputtext";
import ULModal from "../../../shared/ul-modal/ULModal";
import { Button } from "primereact/button";
import classes from "./GenerateLinkModal.module.scss";
import clsx from "clsx";
import { useState } from "react";
import { Tooltip, TooltipProps } from "primereact/tooltip";

export interface GenerateLinkSelectedProject {
  flexProjectNumber: string;
  orderLineDescription: string;
  e2EProjectId: string;
  e2EProjectName: string;
}

interface GenerateLinkModalProps {
  visible: boolean;
  onHide: () => void;
  data?: GenerateLinkSelectedProject | null;
}

const tooltipText = "Link Copied!";
const tooltipDefaultProps: TooltipProps = {
  mouseTrackTop: 30,
  mouseTrackLeft: 5,
  mouseTrack: true,
};

export default function GenerateLinkModal({
  data,
  onHide,
  visible,
}: GenerateLinkModalProps) {
  const encodedLineDescription = encodeURIComponent(
    data?.orderLineDescription ?? ""
  );
  const encodedProjectName = encodeURIComponent(data?.e2EProjectName ?? "");
  const fpnURL = `${process.env.REACT_APP_GMAWB_ALL_PROJECTS_URL}?bypass=true&flexProjectNumber=${data?.flexProjectNumber}`;
  const fonURL = `${process.env.REACT_APP_GMAWB_ALL_PROJECTS_URL}?bypass=true&orderLineDescription=${encodedLineDescription}&e2EProjectId=${data?.e2EProjectId}&e2EProjectName=${encodedProjectName}`;

  const [copiedText, setCopiedText] = useState({
    fpn: false,
    fon: false,
  });

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const _onHide = () => {
    if (onHide) onHide();
    setCopiedText({
      fpn: false,
      fon: false,
    });
  };

  return (
    <ULModal
      visible={visible}
      onHide={_onHide}
      header="Share Link"
      footer={
        <div
          className={clsx(
            classes["d-flex"],
            classes["d-flex-column"],
            classes["d-flex-align-items-center"]
          )}
        >
          <Button className="p-button-secondary" onClick={onHide}>
            CLOSE
          </Button>
        </div>
      }
    >
      <div className={clsx(classes["d-flex"], classes["d-flex-column"])}>
        <div className={clsx(classes["d-flex"], classes["d-flex-column"])}>
          <label className="font-weight-bold">
            Link to FLEX Project Number
          </label>
          <div className={clsx(classes["d-flex"], classes["d-flex-row"])}>
            <InputText value={fpnURL} readOnly width={"350px"} />

            <Tooltip
              {...tooltipDefaultProps}
              target="#fpn-button"
              className={clsx(
                classes["custom-tooltip"],
                copiedText.fpn && classes["active"]
              )}
            />
            <Button
              id="fpn-button"
              onClick={(event) => {
                copyToClipboard(fpnURL);
                setCopiedText((prev) => ({ ...prev, fpn: true }));
              }}
              data-pr-tooltip={tooltipText}
            >
              COPY LINK
            </Button>
          </div>
        </div>
        <div className={clsx(classes["d-flex"], classes["d-flex-column"])}>
          <label className="font-weight-bold">
            Link to selected Order Line
          </label>
          <div className={clsx(classes["d-flex"], classes["d-flex-row"])}>
            <InputText value={fonURL} readOnly width={"350px"} />
            <Tooltip
              {...tooltipDefaultProps}
              target="#fon-button"
              className={clsx(
                classes["custom-tooltip"],
                copiedText.fon && classes["active"]
              )}
            />
            <Button
              id="fon-button"
              onClick={() => {
                copyToClipboard(fonURL);
                setCopiedText((prev) => ({ ...prev, fon: true }));
              }}
              data-pr-tooltip={tooltipText}
            >
              COPY LINK
            </Button>
          </div>
        </div>
      </div>
    </ULModal>
  );
}
