import { INVOICE_PRICE } from "../../../utils/constants/feature-flag.constants";

export interface GridColumnModel {
  id: string;
  value: string;
  selected: boolean;
  orderBy: string;
  isShown: boolean;
  isFrozen: boolean;
  width: string;
  widthNum: number;
  orderNo: number;
}

export interface GridColumnModelList extends GridColumnModel {
  displayType: string;
}
export const pWqColumnProjectHandler = "flexHandler";
export const projectWorkQueueGridColumns: GridColumnModelList[] = [
  {
    id: "flexProjectNumber",
    value: "Project Number",
    selected: false,
    orderBy: "flexProjectNumber",
    isShown: true,
    isFrozen: true,
    width: "200px",
    widthNum: 180,
    orderNo: 1,
    displayType: "text",
  },
  {
    id: "flexProjectName",
    value: "FLEX Project Name",
    selected: false,
    orderBy: "flexProjectName",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 2,
    displayType: "text",
  },
  {
    id: "orderNumber",
    value: "Order Number",
    selected: false,
    orderBy: "orderNumber",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 3,
    displayType: "text",
  },
  {
    id: "orderLineNumberDescription",
    value: "Order Line Number",
    selected: false,
    orderBy: "orderLineNumber",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 4,
    displayType: "text",
  },
  {
    id: "soldToCompanyName",
    value: "FLEX Sold To (Customer/Company Name)",
    selected: false,
    orderBy: "soldToCompanyName",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 5,
    displayType: "text",
  },
  {
    id: "soldToContactPsn",
    value: "FLEX Sold To (PSN)",
    selected: false,
    orderBy: "soldToContactPsn",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 6,
    displayType: "text",
  },
  {
    id: "shipToCompanyName",
    value: "FLEX Ship To (Company Name)",
    selected: false,
    orderBy: "shipToCompanyName",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 7,
    displayType: "text",
  },
  {
    id: "shipToContactPsn",
    value: "FLEX Ship To (PSN)",
    selected: false,
    orderBy: "shipToContactPsn",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 8,
    displayType: "text",
  },
  {
    id: "flexProjectEcd",
    value: "FLEX Project ECD",
    selected: false,
    orderBy: "flexProjectEcd",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 9,
    displayType: "date",
  },
  {
    id: "orderLineEcd",
    value: "Order Line ECD",
    selected: false,
    orderBy: "orderLineEcd",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 10,
    displayType: "date",
  },
  {
    id: "priceWithCurrency",
    value: INVOICE_PRICE ? "Quotation Price" : "Price",
    selected: false,
    orderBy: "price",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 11,
    displayType: "text",
  },
  {
    id: "orderLineStatus",
    value: "Order Line Status",
    selected: false,
    orderBy: "orderLineStatus",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 12,
    displayType: "text",
  },
  {
    id: "flexHandler",
    value: "FLEX Handler",
    selected: false,
    orderBy: "flexHandler",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 13,
    displayType: "text",
  },
  {
    id: "orderBookedDate",
    value: "Order Booked Date",
    selected: false,
    orderBy: "orderBookedDate",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 14,
    displayType: "date",
  },
  {
    id: "flexProjectCreated",
    value: "FLEX Project Created Date",
    selected: false,
    orderBy: "flexProjectCreated",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 15,
    displayType: "date",
  },
];
