import { DataTable } from "primereact/datatable";
import { ProjectTemplateMappingModel } from "../../../shared/models/ProjectTemplateMappingModel";
import { Column } from "primereact/column";
import ProjectTemplateMappingViewFlexTask from "../project-template-mapping-view-flex-task/ProjectTemplateMappingViewFlexTask";

interface ProjectTemplateMappingGridProps {
  e2eGrid: ProjectTemplateMappingModel[];
}

const ProjectTemplateMappingGrid = (
  componentProps: ProjectTemplateMappingGridProps
) => {
  const FlexTaskIdNameHeaderTemplate = () => {
    return (
      <>
        <div className={"ul-grid__row"}>
          <div className="ul-grid__column -x50">
            <div className={"column-fill"}>FLEX Task ID</div>
          </div>
          <div className={"ul-grid__column -x70"}>
            <div className={"column-fill"}>FLEX Task Name</div>
          </div>
        </div>
      </>
    );
  };

  const FlexTaskNameTemplate = (rowData: ProjectTemplateMappingModel) => {
    return (
      <>
        <div className={"ul-grid__row"}>
          <div className="ul-grid__column -x20">
            <div className={"column-fill"}>{rowData.flexTaskNumber}</div>
          </div>
          <div className={"ul-grid__column -x60"}>
            <div className={"column-fill"}>{rowData.flexTaskName}</div>
          </div>
          <div className={"ul-grid__column -x20"}>
            <div className={"column-fill"}>
              <ProjectTemplateMappingViewFlexTask flexTask={rowData} />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <DataTable
        value={componentProps.e2eGrid}
        size="small"
        responsiveLayout="scroll"
        groupRowsBy="flexTaskNumber"
        rowGroupMode="rowspan"
        scrollHeight="calc(60vh - 140px)"
        showGridlines
      >
        <Column
          field="flexTaskNumber"
          header={FlexTaskIdNameHeaderTemplate}
          body={FlexTaskNameTemplate}
          style={{ width: "35%" }}
        ></Column>
        <Column
          field="e2EMilestoneName"
          header="GMA WB Template Milestone"
          style={{ width: "30%" }}
        ></Column>
        <Column
          field="e2EMilestonePredecessorName"
          header="GMA WB Template Milestone Predecessor"
          style={{ width: "30%" }}
        ></Column>
        <Column style={{ width: "5%" }}></Column>
      </DataTable>
    </div>
  );
};

export default ProjectTemplateMappingGrid;
