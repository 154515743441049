import { Button } from "primereact/button";
import React from "react";
import { useDispatch } from "react-redux";
import { addToaster } from "../../../features/projectTemplateMapping/projectTemplateMappingSlice";

import { ToasterMessage } from "../../../features/projectTemplateMapping/projectTemplateMappingSliceInterfaces";
import useToastr from "../../../hooks/useToastr";
import ProjectTemplateMappingService from "../../../services/ProjectTemplateMappingService";
import ConfirmationModal from "../../../shared/confirmation-modal/ConfirmationModal";
import { ProjectTemplateMappingHeaderModel } from "../../../shared/models/ProjectTemplateMappingHeader.model";
import { ProjectTemplateMappingModel } from "../../../shared/models/ProjectTemplateMappingModel";
import { ProjectTemplateMappingRequestModel } from "../../../shared/models/service-models/ProjectTemplateMappingRequest.model";
import { SelectListResultModel } from "../../../shared/models/service-models/SelectListResult.model";
import { TemplateAssociationModel } from "../../../shared/models/TemplateAssociation.model";
import {
  BlockUIProps,
  ProjectTemplateComponentErrors,
  ProjectTemplateMilestoneErrors,
} from "../ProjectTemplateMapping";
import { ProjectTemplateMappingModes } from "../shared/enums/ProjectTemplateMappingModes";
import { ProjectTemplateStatus } from "../shared/enums/ProjectTemplateStatus";
import classes from "./ProjectTemplateMappingFooter.module.scss";
import { isValidFilename } from "../../../utils/helpers/file.helpers";
import { ProjectTemplateErrorMessages } from "../../template/shared/enums/ProjectTemplateErrorMessages";
import { refinerRequestName } from "../../../utils/constants/project-management.constants";

interface ProjectTemplateMappingInitialLoadingFlag {
  IsAssociatedSchemeLoading: boolean;
  IsHeaderLoading: boolean;
  IsTemplateMappingLoading: boolean;
}

interface ProjectTemplateMappingFooterProps {
  headerData: ProjectTemplateMappingHeaderModel;
  assocSchemeData: TemplateAssociationModel[];
  e2eGridData: ProjectTemplateMappingModel[];
  additionalInfoList: Array<SelectListResultModel>;

  setBlockUI: React.Dispatch<React.SetStateAction<BlockUIProps>>;
  handleCancel: Function;
  loadingStates: ProjectTemplateMappingInitialLoadingFlag;
  componentErrors: ProjectTemplateComponentErrors | null;
  setComponentErrors: React.Dispatch<
    React.SetStateAction<ProjectTemplateComponentErrors | null>
  >;
  historyNavigate: Function;
  isCancelModalVisible: boolean;
  handleConfirmationModalResult: Function;
  unmodifiedMappingJsonValue: string;
}

const ProjectTemplateMappingFooter = (
  componentProps: ProjectTemplateMappingFooterProps
) => {
  const dispatch = useDispatch();
  const { showSuccess, showError } = useToastr();
  const { assocSchemeData } = componentProps;

  const isUntouched =
    componentProps.headerData.templateMode === ProjectTemplateMappingModes.Edit
      ? componentProps.unmodifiedMappingJsonValue === ""
        ? true
        : componentProps.unmodifiedMappingJsonValue ===
          JSON.stringify({
            header: componentProps.headerData,
            schemes: componentProps.assocSchemeData,
            grid: componentProps.e2eGridData,
          })
      : false;

  const hasHeaderErrors =
    componentProps.componentErrors?.headerErrors &&
    componentProps.componentErrors?.headerErrors.templateName != null;

  const hasMilestoneErrors =
    !!componentProps?.componentErrors?.milestoneErrors &&
    componentProps?.componentErrors?.milestoneErrors.length > 0;

  const componentStillLoading =
    componentProps.loadingStates.IsAssociatedSchemeLoading ||
    componentProps.loadingStates.IsHeaderLoading ||
    componentProps.loadingStates.IsTemplateMappingLoading;

  const hasNullValueInProperty = (arr: any[], properties: string[]) => {
    return arr.some((i) => properties.some((p) => i[p] === null));
  };

  const hasAssociatedSchemeValue = hasNullValueInProperty(assocSchemeData, [
    "schemeId",
    "deliveryPathId",
    "applicationTypeId",
  ]);

  const disablePublishButton: boolean =
    componentProps.headerData.templateMode ===
      ProjectTemplateMappingModes.View ||
    componentStillLoading ||
    hasHeaderErrors ||
    hasAssociatedSchemeValue ||
    hasMilestoneErrors ||
    (isUntouched &&
      componentProps.headerData.templateStatus ===
        ProjectTemplateStatus.Published);

  const disableSaveAsDraftButton: boolean =
    componentProps.headerData.templateMode ===
      ProjectTemplateMappingModes.View ||
    componentStillLoading ||
    hasHeaderErrors ||
    hasMilestoneErrors ||
    isUntouched;

  const cancelButtonLabel =
    componentProps.headerData.templateMode !== ProjectTemplateMappingModes.View
      ? "Cancel"
      : "Back to Template List";

  enum SaveType {
    Publish,
    Draft,
  }

  const handleSave = (saveType: SaveType) => {
    if (saveType === SaveType.Publish) {
      const hasDuplicates = assocSchemeData.find((a) => a.isDuplicate);

      if (hasDuplicates) {
        handleErrorToast(
          "A duplicate entry has been identified in the Associated Scheme section. Please edit entry then try again."
        );
        return;
      }
    }

    if (!isValidFilename(componentProps.headerData.name.trim())) {
      componentProps.setComponentErrors((current: any) => ({
        ...current,
        headerErrors: {
          ...current?.headerErrors,
          templateName:
            ProjectTemplateErrorMessages.TEMPLATE_NAME_CONTAINS_INVALID_CHARS,
        },
      }));

      return;
    }

    componentProps.setBlockUI({ blockUI: true, blockUIMessage: "Saving..." });

    ProjectTemplateMappingService.verifyTemplateDuplicate({
      flexTemplateId: componentProps.headerData.flexTemplateId,
      templateName: componentProps.headerData.name.trim(),
      correlationId:
        componentProps.headerData.templateMode ===
        ProjectTemplateMappingModes.Edit
          ? componentProps.headerData.correlationId
          : null,
    }).then((response) => {
      if (response.data.hasError) {
        const CreateHyperlink = () => {
          if (!response.data.hasUsedInE2EProjects) {
            return <>{response.message}</>;
          }

          const manipulateErrorMessage = response.message.split("{0}");
          return (
            <>
              <span>
                {manipulateErrorMessage[0]}
                {manipulateErrorMessage[1]}
              </span>
            </>
          );
        };

        componentProps.setComponentErrors((current: any) => ({
          ...current,
          headerErrors: {
            ...current?.headerErrors,
            templateName: <CreateHyperlink />,
          },
        }));
        componentProps.setBlockUI({ blockUI: false, blockUIMessage: "" });
      } else {
        const modifyHeader = structuredClone(componentProps.headerData);
        modifyHeader.name = modifyHeader.name.trim();
        modifyHeader.status =
          saveType === SaveType.Publish ? "Published" : "Draft";
        modifyHeader.templateStatus =
          saveType === SaveType.Publish
            ? ProjectTemplateStatus.Published
            : ProjectTemplateStatus.Draft;

        modifyHeader.additionalInfoId =
          modifyHeader.additionalInfoId == ""
            ? null
            : modifyHeader.additionalInfoId;

        let additionalInfoName: any;

        if (componentProps.headerData.additionalInfoId != "") {
          let additionalInfoItem = componentProps.additionalInfoList.find(
            (x) => x.id == componentProps.headerData.additionalInfoId
          );

          if (additionalInfoItem) {
            additionalInfoName = additionalInfoItem.value;
          }
        }
        const schemes = componentProps.assocSchemeData.map((m, index) => {
          return {
            ...m,
            sortOrder: index + 1,
            additionalInfoId:
              componentProps.headerData.additionalInfoId == ""
                ? null
                : componentProps.headerData.additionalInfoId,
            additionalInfoName: additionalInfoName,
            additionalInfoListResult: componentProps.additionalInfoList.filter(
              (x) => x.id != ""
            ),
          };
        });

        const projectTemplatePayload: ProjectTemplateMappingRequestModel = {
          Header: modifyHeader,
          AssociationSchemeInfos: schemes,
          TemplateTaskInfos: componentProps.e2eGridData,
        };

        if (
          componentProps.headerData.templateMode ===
            ProjectTemplateMappingModes.Add ||
          componentProps.headerData.templateMode ===
            ProjectTemplateMappingModes.Copy
        ) {
          projectTemplatePayload.Header.id = null;
          projectTemplatePayload.Header.formattedVersion = "0";
          projectTemplatePayload.Header.correlationId = null;
          postTemplate(projectTemplatePayload);
        } else {
          componentProps.headerData.templateStatus ===
          ProjectTemplateStatus.Draft
            ? patchTemplate(projectTemplatePayload) //Draft -> Draft && Draft -> Publish = Patch
            : postTemplate(projectTemplatePayload); //Publish -> Publish && Publish -> Draft = Post
        }
      }
    });

    if (
      !componentProps.headerData?.id &&
      sessionStorage.getItem(refinerRequestName) !== null
    ) {
      sessionStorage.removeItem(refinerRequestName);
    }
  };

  const postTemplate = (payload: ProjectTemplateMappingRequestModel) => {
    ProjectTemplateMappingService.postProjectTemplate(payload)
      .then((response) => {
        if (response.isSuccess === true) {
          handleUpsertTemplateSuccess();
        } else {
          if (response.data && response.data.templateTaskInfos) {
            componentProps.setComponentErrors((current: any) => ({
              ...current,
              milestoneErrors: response.data.templateTaskInfos.map(
                (data: ProjectTemplateMappingModel) => {
                  return {
                    milestone: data.promptMilestone ? data.errorMessage : null,
                    predecessor: data.promptPredecessor
                      ? data.errorMessage
                      : null,
                    sortOrder: data.sortOrder,
                  } as ProjectTemplateMilestoneErrors;
                }
              ),
            }));
          }
          componentProps.setBlockUI({ blockUI: false, blockUIMessage: "" });
        }
      })
      .catch((error) => {
        console.error(error);
        componentProps.setBlockUI({ blockUI: false, blockUIMessage: "" });
      });
  };

  const patchTemplate = (payload: ProjectTemplateMappingRequestModel) => {
    ProjectTemplateMappingService.patchProjectTemplate(payload)
      .then((response) => {
        if (response.isSuccess === true) {
          handleUpsertTemplateSuccess();
        } else {
          if (response.data && response.data.templateTaskInfos) {
            componentProps.setComponentErrors((current: any) => ({
              ...current,
              milestoneErrors: response.data.templateTaskInfos.map(
                (data: ProjectTemplateMappingModel) => {
                  return {
                    milestone: data.promptMilestone ? data.errorMessage : null,
                    predecessor: data.promptPredecessor
                      ? data.errorMessage
                      : null,
                    sortOrder: data.sortOrder,
                  } as ProjectTemplateMilestoneErrors;
                }
              ),
            }));
          }
          componentProps.setBlockUI({ blockUI: false, blockUIMessage: "" });
        }
      })
      .catch((error) => {
        console.error(error);
        componentProps.setBlockUI({ blockUI: false, blockUIMessage: "" });
      });
  };

  const handleUpsertTemplateSuccess = () => {
    const toastMsg: ToasterMessage = {
      type: "Success",
      message: toastMessage(),
    };
    showSuccess(toastMsg.type, toastMsg.message);
    dispatch(addToaster(toastMsg));

    componentProps.historyNavigate("/");
  };

  const handleErrorToast = (message: string) => {
    const toastMsg: ToasterMessage = {
      type: "Error",
      message,
    };
    showError("Cannot publish E2E Template", toastMsg.message);
    dispatch(addToaster(toastMsg));
  };

  const toastMessage = () => {
    return componentProps.headerData.correlationId === null
      ? `E2E Template created successfully`
      : `E2E Template updated successfully`;
  };

  const cancelConfirmatonMessage = () => {
    return (
      <div>
        <p>
          Some fields already have data in place. This action will discard all
          changes you made and E2E template will not be saved.
        </p>
        <br />
        <p>Would you like still like to continue?</p>
      </div>
    );
  };

  const handleCancelConfirmationModalNo = () => {
    componentProps.handleConfirmationModalResult(true);
  };

  const handleCancelConfirmationModalYes = () => {
    componentProps.handleConfirmationModalResult(false);
  };

  return (
    <>
      <div className={`${classes["template-footer__container"]}`}>
        <div className={`${classes["template-footer__row"]}`}>
          <Button
            disabled={disablePublishButton}
            className={`${classes["publish-button"]}`}
            onClick={() => handleSave(SaveType.Publish)}
          >
            Publish
          </Button>
          <Button
            disabled={disableSaveAsDraftButton}
            className={`${classes["save-as-draft-button"]}`}
            onClick={() => handleSave(SaveType.Draft)}
          >
            Save as Draft
          </Button>
          <Button
            className={`${classes["cancel-button"]}`}
            onClick={() => componentProps.handleCancel()}
          >
            {cancelButtonLabel}
          </Button>
        </div>
      </div>

      <ConfirmationModal
        visible={componentProps.isCancelModalVisible}
        onCancel={handleCancelConfirmationModalNo}
        onConfirm={handleCancelConfirmationModalYes}
        headerDisplay={`Cancel Creation of E2E Template`}
        confirmationMessageHTML={cancelConfirmatonMessage()}
        buttonNoDisplay={`No`}
        buttonYesDisplay={`Yes`}
      />
    </>
  );
};

export default ProjectTemplateMappingFooter;
