import "./CustomFilter.scss";
import classes from "./CustomFilter.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFilter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import YourFiltersItem from "./your-filters-item/YourFiltersItem";

const CustomFilter = (props) => {
  const op = useRef(null);
  const [selectedColumnId, setSelectedColumnId] = useState(null);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [inputtedSearchText, setInputtedSearchText] = useState("");
  const [isSetFilterBtnDisabled, setIsSetFilterBtnDisabled] = useState(true);
  const [refinerHeaders, setRefinerHeaders] = useState([]);

  const {
    globalSearchTextRef,
    customFilters,
    setCustomFilters,
    onClickSetCustomFilters,
    columns,
    conditions,
    refiners,
    refinerColumns,
    hasRefiners,
    onClickRemoveRefiner,
    onClickRemoveRefinerGroup,
    onClickGlobalSearch,
    onClickClearYourFilters,
    onClickClearCustomFilters,
  } = props;

  useEffect(() => {
    let refinerHeadersTemp = [];

    if (refiners && Array.isArray(refiners) && refiners.length > 0) {
      refiners.forEach((ref) => {
        if (refinerHeadersTemp.indexOf(ref.Id) === -1) {
          refinerHeadersTemp.push(ref.Id);
        }
      });
    }
    setRefinerHeaders(refinerHeadersTemp);
  }, [refiners]);

  const onChangeColumn = (e) => {
    const id = columns.find((item) => item.value === e.value).id;
    setSelectedColumnId(id);
    setSelectedColumnName(e.value);
  };

  const onChangeCondition = (e) => {
    setSelectedCondition(e.value);
  };

  const onClickAddNewCustomFilter = () => {
    let similarCustomFilter = customFilters.find(
      (i) => i.Id === selectedColumnId
    );
    if (
      selectedColumnId &&
      selectedCondition &&
      inputtedSearchText &&
      !similarCustomFilter
    ) {
      setIsSetFilterBtnDisabled(false);
      setCustomFilters((prevState) => {
        return [
          ...prevState,
          {
            Id: selectedColumnId,
            ColumnName: selectedColumnName,
            Condition: selectedCondition,
            Value: inputtedSearchText,
          },
        ];
      });
      clearAllSelectedInputs();
    }
  };

  const onClickFilterIcon = (e) => {
    op.current.toggle(e);
  };

  const onClickDeleteCustomFilter = (filter) => {
    let customFiltersTemp = customFilters.filter(
      (i) =>
        i.ColumnName !== filter.ColumnName &&
        i.Condition !== filter.Condition &&
        i.Value !== filter.Value
    );
    setCustomFilters(customFiltersTemp);
    if (customFiltersTemp.length === 0) {
      setIsSetFilterBtnDisabled(true);
    }
  };

  const onClickCancelBtn = (e) => {
    op.current.target.click();
  };

  const clearAllSelectedInputs = () => {
    setSelectedColumnName(null);
    setSelectedColumnId(null);
    setSelectedCondition(null);
    setInputtedSearchText("");
  };

  const onClickClearFilter = (e) => {
    clearAllSelectedInputs();
    onClickClearCustomFilters();
    setIsSetFilterBtnDisabled(true);
  };

  const onSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      onClickGlobalSearch(globalSearchTextRef.current.value);
    }
  };

  const onClickSearchIcon = () => {
    onClickGlobalSearch(globalSearchTextRef.current.value);
  };

  const onClearSearch = () => {
    globalSearchTextRef.current.value = "";
    onClickGlobalSearch("");
  };

  return (
    <div className="ul-card -app custom-filter-section">
      <div className="ul-card__item -bordered ul-card__head filter-menu-label filter-menu-text-container">
        <span className="filter-menu-text font-weight-semi-bold">
          Filter Menu
        </span>
      </div>

      <div className="ul-card__item ul-card__body custom-filters-input">
        <div className="font-weight-bold">Custom Filters</div>

        <div className="search-filter-div">
          <div className="ul-form__controlGroup">
            <p class="ul-form__control input-container-with-close">
              <input
                type="text"
                placeholder="Search"
                ref={globalSearchTextRef}
                onKeyPress={(e) => onSearchKeyPress(e)}
                className="ul-form__controlGroupItem ul-textbox search-input-with-close"
              />
              <FontAwesomeIcon
                icon={faXmark}
                onClick={onClearSearch}
                className="ul-form__controlIcon search-close-icon"
              />
            </p>
            <button
              className="ul-button -app-tertiary -medium -icon search-icon-btn"
              aria-label="add"
              onClick={onClickSearchIcon}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
          <button
            className="ul-button -app-tertiary -medium -icon filter-icon-btn"
            aria-label="add"
            onClick={onClickFilterIcon}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          <OverlayPanel
            ref={op}
            style={{ width: "53rem", marginLeft: "2.5em", marginTop: "-2.5em" }}
          >
            <div class="header">
              <span className="font-weight-bold">Filter Certificates</span>
              <FontAwesomeIcon icon={faXmark} onClick={onClickCancelBtn} />
            </div>
            <hr />

            <br />
            <div className="ul-grid__row -wrap">
              <div className="ul-grid__column -x40 -x100-mobile">
                <Dropdown
                  value={selectedColumnName}
                  options={columns}
                  onChange={onChangeColumn}
                  optionLabel="value"
                  placeholder=" --- Select a field --- "
                  style={{ width: "100%", maxWidth: "100%" }}
                />
              </div>

              <div className="ul-grid__column -x20 -x100-mobile d-flex justify-content-center">
                <Dropdown
                  value={selectedCondition}
                  options={conditions}
                  onChange={onChangeCondition}
                  placeholder="condition"
                />
              </div>

              <div className="ul-grid__column -x30 -x100-mobile">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    value={inputtedSearchText}
                    onChange={(e) => setInputtedSearchText(e.target.value)}
                    placeholder="Search"
                  />
                </span>
              </div>

              <div className="ul-grid__column -x10 -x100-mobile">
                <Button
                  className={classes["add-new-btn"]}
                  style={{ marginLeft: "1rem" }}
                  icon="pi pi-plus"
                  onClick={onClickAddNewCustomFilter}
                />
              </div>
            </div>

            <div className="custom-filter-list">
              {customFilters.map((filter, index) => (
                <div key={index}>
                  <hr />
                  <div className="ul-grid__row -wrap p-0">
                    <div className="ul-grid__column -x30 -x100-mobile font-weight-bold">
                      {filter.ColumnName}
                    </div>
                    <div className="ul-grid__column -x30 -x100-mobile">
                      {filter.Condition}
                    </div>
                    <div className="ul-grid__column -x30 -x100-mobile">
                      {filter.Value}
                    </div>
                    <div className="ul-grid__column -x10 -x100-mobile">
                      <Button
                        className="delete-filter-btn"
                        style={{ marginLeft: "1rem" }}
                        icon="pi pi-minus"
                        onClick={() => onClickDeleteCustomFilter(filter)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <br />
            <div className="ul-grid__row -wrap">
              <hr />
              <div>
                <span className="filter-inputs-div float-right">
                  <button
                    className={`ul-button -app-primary -medium -icon set-filter-btn 
                        ${
                          isSetFilterBtnDisabled
                            ? "set-filter-btn-disabled"
                            : ""
                        }`}
                    onClick={onClickSetCustomFilters}
                  >
                    SET FILTER
                  </button>

                  <button
                    className={`ul-button -app-tertiary -medium -icon clear-filter-btn  
                        ${
                          isSetFilterBtnDisabled
                            ? "clear-filter-btn-disabled"
                            : ""
                        }`}
                    onClick={onClickClearFilter}
                    disabled={isSetFilterBtnDisabled}
                  >
                    CLEAR FILTER
                  </button>

                  <button
                    className="ul-button -app-secondary -medium -icon cancel-filter-btn"
                    onClick={onClickCancelBtn}
                  >
                    CANCEL
                  </button>
                </span>
              </div>
            </div>
          </OverlayPanel>
        </div>
      </div>

      <hr className="filter-hr" />

      <div>
        <div className="ul-card__body font-weight-bold your-filters-text">
          Your Filters
        </div>

        <div>
          {refinerHeaders.map((refinerId) => (
            <div key={refinerId}>
              <YourFiltersItem
                refinerColumns={refinerColumns}
                refinerId={refinerId}
                columnRefiners={refiners.filter((i) => i.Id === refinerId)}
                onClickRemoveRefiner={onClickRemoveRefiner}
                onClickRemoveRefinerGroup={onClickRemoveRefinerGroup}
              />

              <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                <hr />
              </div>
            </div>
          ))}
        </div>

        <div className="c-clear-container ul-card__body d-flex justify-content-center align-content-center">
          <button
            className={`ul-button -app-tertiary -medium filter-cancel-button ${
              !hasRefiners ? "clear-filter-btn-disabled" : ""
            }`}
            aria-label="add"
            onClick={onClickClearYourFilters}
            disabled={!hasRefiners}
          >
            CLEAR
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomFilter;
