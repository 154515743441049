import { IProjectTaskMilestoneSteps } from "../shared/interfaces/pmr-e2e-project-task-milestone-step";

export const ProjectTasksMilestoneSteps: IProjectTaskMilestoneSteps[] = [
  {
    taskName: "Project Planning",
    sortOrder: 1,
    milestones: [
      {
        name: "UL: Send GI/R2C to Customer",
        sortOrder: 1,
      },
      {
        name: "UL: Send Application Forms to Customer",
        sortOrder: 2,
      },
    ],
  },
  {
    taskName: "Sample / Information Received",
    sortOrder: 2,
    milestones: [
      {
        name: "Customer: Send All Documents to UL",
        sortOrder: 1,
      },
      {
        name: "Customer: Send All Samples to UL",
        sortOrder: 2,
      },
    ],
  },
  {
    taskName: "Product / Information Review",
    sortOrder: 3,
    milestones: [
      {
        name: "UL: Review and Prepare Application Package",
        sortOrder: 1,
      },
      {
        name: "UL: Payment",
        sortOrder: 2,
      },
    ],
  },
  {
    taskName: "Certification Office Review",
    sortOrder: 4,
    milestones: [
      {
        name: "UL: Submit to Authority/Authority Review",
        sortOrder: 1,
      },
      {
        name: "UL: Submit Samples to Test Lab/In-Country Testing",
        sortOrder: 2,
      },
      {
        name: "UL: Payment",
        sortOrder: 3,
      },
      {
        name: "UL: Receive/Send Certificate to Customer",
        sortOrder: 4,
      },
    ],
  },
  {
    taskName: "GMA TC_Processing (non-UL CO)",
    sortOrder: 5,
    milestones: [
      {
        name: "UL: GMA Transaction Center",
        sortOrder: 1,
      },
    ],
  },
  {
    taskName: "Project Completion",
    sortOrder: 6,
    milestones: [
      {
        name: "UL: Payment",
        sortOrder: 1,
      },
      {
        name: "UL: Sample Return",
        sortOrder: 2,
      },
      {
        name: "UL: Project Completion",
        sortOrder: 3,
      },
    ],
  },
];
