import { useDispatch, useSelector } from "react-redux";
import {
  selectApplicantInformation,
  selectGPICustomerInputValidationResult,
  updateApplicantInformation,
  updateGPICustomerInputValidationResult,
} from "../../../../features/generalProductInformation/generalProductInformationSlice";
import CustomerInformation from "../shared/CustomerInformation/CustomerInformation";

const ApplicantInformation = ({ disableIntegration }) => {
  const dispatch = useDispatch();
  const applicantInformationValues = useSelector(selectApplicantInformation);
  const gPICustomerInputValidationResultValues = useSelector(
    selectGPICustomerInputValidationResult
  );

  const handleChangeCustomerInformation = (customerInformation) => {
    dispatch(
      updateApplicantInformation({
        ...applicantInformationValues,
        ...customerInformation,
      })
    );

    dispatch(
      updateGPICustomerInputValidationResult(
        gPICustomerInputValidationResultValues?.errors?.filter(
          (e) => e.entity !== "Applicant"
        )
      )
    );
  };

  const customErrors = gPICustomerInputValidationResultValues?.errors?.filter(
    (e) => e.entity === "Applicant"
  );

  return (
    <div className="form-section">
      <div className="form-section-header">
        <h3 className="form-section-title">
          Applicant Information
          <small>
            ( Company that is applying for the service or specified by the
            requestor as the applicant. This is not an agent. )
          </small>
        </h3>
      </div>

      <div className="form-section-content">
        <CustomerInformation
          customErrors={customErrors}
          disableIntegration={disableIntegration}
          onChange={handleChangeCustomerInformation}
          customerInformation={applicantInformationValues}
          section="applicant"
        />
      </div>
    </div>
  );
};

export default ApplicantInformation;
