import * as yup from "yup";
import customerInformationSchema from "../customer-information-schema";

const representativeSchema = customerInformationSchema;

export default yup.array().of(
  yup.object({
    uLToProvide: yup.boolean(),
    representatives: yup
      .array()
      .when("uLToProvide", {
        is: (uLToProvide) => { return !uLToProvide },
        then: yup.array().of(representativeSchema),
    })
  })
);

export { representativeSchema };
