import RequestFactory from "./RequestFactory";

const certificateUrl = process.env.REACT_APP_CERTIFICATE_URL;

const ProjectDetailsService = {
  getProjectSuggestions: async (projectNumber) =>
    await RequestFactory.get(
      certificateUrl,
      `/project/suggestions?projectNumber=${projectNumber}`
    ),
  getOrderSuggestions: async (orderNumber) =>
    await RequestFactory.get(
      certificateUrl,
      `/project/ordersuggestions?orderNumber=${orderNumber}`
    ),
  getProjectDetail: async (projectNumber) =>
    await RequestFactory.get(
      certificateUrl,
      `/project/odata-get-project-detail?projectNo=${projectNumber}`
    ),
  getProjectWorkflowDetail: async (projectId, lineNumber) =>
    await RequestFactory.get(
      certificateUrl,
      `/project/get-project-workflow-detail?projectId=${projectId}&lineNumber=${lineNumber}`
    ),
  getUserDetails: async (userEmail) =>
    await RequestFactory.get(
      certificateUrl,
      `/certificate/get-user-details?userEmail=${userEmail}`
    ),
  saveProjectDetail: async (project) =>
    await RequestFactory.post(
      certificateUrl,
      `/project/save-project-detail`,
      project
    ),
};

export default ProjectDetailsService;
