import "../project-template-scheme-dropdown/ProjectTemplateSchemeDropdown.scss";
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyType } from "primereact/column";
import { TemplateAssociationModel } from "../../../shared/models/TemplateAssociation.model";
interface ProjectTemplateSchemeGridProps {
  assocSchemeData: TemplateAssociationModel[];
}

const ProjectTemplateSchemeGrid = (
  componentProps: ProjectTemplateSchemeGridProps
) => {
  const columns = [
    {
      field: "schemeName",
      headerText: "Certificate Scheme",
      width: "10rem",
    },
    {
      field: "applicationTypeName",
      headerText: "Application Type",
      width: "10rem",
    },
    {
      field: "deliveryPathName",
      headerText: "Delivery Path(s)",
      width: "10rem",
    },
  ];

  const renderSchemeTemplate = (e: TemplateAssociationModel) => {
    if (e.schemeName) return e.schemeName;

    return "No Data";
  };

  const renderDynamicColumns = () =>
    columns.map((c) => (
      <Column
        key={c.headerText}
        field={c.field}
        header={c.headerText}
        style={{ width: c.width }}
        body={c.field === "schemeName" ? renderSchemeTemplate : null}
      />
    ));
  return (
    <>
      <DataTable
        value={componentProps.assocSchemeData}
        scrollable
        columnResizeMode="expand"
        responsiveLayout="scroll"
        showGridlines
        size={"small"}
      >
        {renderDynamicColumns()}
      </DataTable>
    </>
  );
};
export default ProjectTemplateSchemeGrid;
