import { useFormikContext } from "formik";
import {
  PmrRefiner,
  PmrRefinerGroup,
} from "../../shared/components/pmr-refiners/PmrRefiners";
import PmrMultiSelectRefiner, {
  onPmrMultiSelectModelChange,
  showMultiSelectRefinerByLabel,
  sortMultiSelectRefinerByLabel,
} from "../../shared/components/pmr-multiselect-refiner/PmrMultiselectRefiner";
import PmrCheckboxRefiner, {
  sortCheckboxExternalSrc,
} from "../../shared/components/pmr-checkbox-refiner/PmrCheckboxRefiner";
import PmrDaterangeRefiner, {
  PmrDaterangeSelectedRefinerTemplate,
} from "../../shared/components/pmr-daterange-refiner/PmrDaterangeRefiner";
import { authProvider } from "../../../../providers/authProvider";
import ProjectRefinersService from "../../../../services/ProjectRefinersService";
import ProjectStatus from "../../shared/enums/project-status";
import { forwardRef } from "react";
import { USE_PROJECT_HANDLER_MAPPING } from "../../../../utils/constants/feature-flag.constants";

interface ProjectLevelRefinerProps extends PmrRefinerGroup {}

function ProjectLevelRefiner({}: ProjectLevelRefinerProps, ref: any) {
  const { handleChange, values, getFieldHelpers } = useFormikContext<any>();
  const userLoggedIn = authProvider.getAccount();

  //#region Project Contributors
  const handleProjectContributorsModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await ProjectRefinersService.getProjectContributors({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
      sort: "displayName",
      sortBy: "asc",
    });

    return {
      items: result.data.records.map((item: any) =>
        USE_PROJECT_HANDLER_MAPPING
          ? {
              label: item.displayNameWithEmail,
              value: item.username,
              displayName: item.displayName,
            }
          : {
              label: item.displayNameWithEmail,
              value: item.displayName,
            }
      ),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region Sold To
  const handleSoldToRefinerModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await ProjectRefinersService.getProjectSoldTo({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
      sort: "partyName",
      sortBy: "asc",
    });

    return {
      items: result.data.records.map((item: any) => ({
        label: `${item.partySiteNumber} - ${item.partyName}`,
        value: {
          partyName: item.partyName,
          partySiteNumber: item.partySiteNumber,
        },
      })),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region Project Name
  const handleProjectNameModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await ProjectRefinersService.getGMAWBProject({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
      sort: "name",
      sortBy: "asc",
    });

    return {
      items: result.data.records.map((item: any) => ({
        label: item.name,
        value: item.id,
      })),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region Project Creator
  const handleProjectCreatorModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await ProjectRefinersService.getProjectCreator({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
      sort: "displayName",
      sortBy: "asc",
    });

    return {
      items: result.data.records.map((item: any) =>
        USE_PROJECT_HANDLER_MAPPING
          ? {
              label: item.displayNameWithEmail,
              value: item.username,
              displayName: item.displayName,
            }
          : {
              label: item.displayNameWithEmail,
              value: item.displayName,
            }
      ),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region Project Status
  const projectStatusOptions = [
    { label: "Completed", value: ProjectStatus.Completed },
    { label: "In Progress", value: ProjectStatus.InProgress },
    { label: "On Hold", value: ProjectStatus.OnHold },
  ];
  //#endregion

  return (
    <span ref={ref}>
      <PmrRefiner container label="PROJECTS" name="projects">
        <PmrRefiner
          label="Contributors"
          name="contributors"
          selectedRefinerTemplate={(value) =>
            USE_PROJECT_HANDLER_MAPPING
              ? value.displayName +
                (userLoggedIn.userName === value.value ? " (You)" : "")
              : value.value +
                (userLoggedIn.name === value.value ? " (You)" : "")
          }
          selectedRefinerSort={sortMultiSelectRefinerByLabel}
        >
          <PmrMultiSelectRefiner
            onModelChange={handleProjectContributorsModelChange}
            name="projects.contributors"
            onChange={handleChange}
            value={values?.projects?.contributors}
            filterProps={{
              type: "text",
            }}
          />
        </PmrRefiner>

        <PmrRefiner
          label="FLEX Sold To (Customer Company Name)"
          name="soldTos"
          selectedRefinerTemplate={showMultiSelectRefinerByLabel}
          selectedRefinerSort={(firstItem, secondItem) =>
            firstItem.value.partyName.localeCompare(secondItem.value.partyName)
          }
        >
          <PmrMultiSelectRefiner
            resetFilterOnHide={false}
            onModelChange={handleSoldToRefinerModelChange}
            name="projects.soldTos"
            onChange={handleChange}
            value={values?.projects?.soldTos}
            filterProps={{
              minLength: 3,
              type: "text",
              placeholder: 'Enter minimum of "3" digits',
            }}
          />
        </PmrRefiner>
        <PmrRefiner
          label="GMAWB Project"
          name="projectNames"
          selectedRefinerTemplate={showMultiSelectRefinerByLabel}
          selectedRefinerSort={sortMultiSelectRefinerByLabel}
        >
          <PmrMultiSelectRefiner
            onModelChange={handleProjectNameModelChange}
            name="projects.projectNames"
            onChange={handleChange}
            value={values?.projects?.projectNames}
            filterProps={{
              type: "text",
            }}
          />
        </PmrRefiner>
        <PmrRefiner
          label="GMAWB Project Created Date"
          name="createdDateUTC"
          selectedRefinerTemplate={PmrDaterangeSelectedRefinerTemplate}
        >
          <PmrDaterangeRefiner
            name="projects.createdDateUTC"
            values={values?.projects?.createdDateUTC}
            onChange={handleChange}
          />
        </PmrRefiner>
        <PmrRefiner
          label="GMAWB Project Creator"
          name="projectCreators"
          selectedRefinerTemplate={(value) =>
            USE_PROJECT_HANDLER_MAPPING ? value.displayName : value.value
          }
          selectedRefinerSort={sortMultiSelectRefinerByLabel}
        >
          <PmrMultiSelectRefiner
            onModelChange={handleProjectCreatorModelChange}
            name="projects.projectCreators"
            onChange={handleChange}
            value={values?.projects?.projectCreators}
            filterProps={{
              type: "text",
            }}
          />
        </PmrRefiner>
        <PmrRefiner
          label="GMAWB Project Status"
          name="projectStatuses"
          selectedRefinerTemplate={(value) =>
            projectStatusOptions.find((option) => option.value === value)?.label
          }
          selectedRefinerSort={(first, second) =>
            sortCheckboxExternalSrc(first, second, projectStatusOptions)
          }
        >
          <PmrCheckboxRefiner
            items={projectStatusOptions}
            name="projects.projectStatuses"
            value={values?.projects?.projectStatuses}
            onChange={handleChange}
            onSelectAll={(value) =>
              getFieldHelpers("projects.projectStatuses").setValue(value)
            }
          />
        </PmrRefiner>
      </PmrRefiner>
    </span>
  );
}

export default forwardRef(ProjectLevelRefiner);
