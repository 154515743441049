import { E2EProjectLineModel } from "../shared/models/E2EProject.model";
import { GetDeliveryPathByCatalogResultModel } from "../shared/models/service-models/GetDeliveryPathByCatalogResult.model";
import { ResultModel } from "../shared/models/service-models/Result.model";
import { SelectCertificateSchemeListResultModel } from "../shared/models/service-models/SelectCertificateSchemeList.model";
import { SelectListResultModel } from "../shared/models/service-models/SelectListResult.model";
import RequestFactory from "./RequestFactory";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

const ReferenceService = {
  getCertificateSchemeList: async () =>
    (await RequestFactory.get(
      GMAE2EURL,
      "/api/reference/get-certificate-scheme-list"
    )) as ResultModel<Array<SelectCertificateSchemeListResultModel>>,
  getTechnologyList: async () =>
    (await RequestFactory.get(
      GMAE2EURL,
      "/api/reference/get-technology-list"
    )) as ResultModel<Array<SelectListResultModel>>,
  getDeliveryPathByCatalog: async (itemNumberCsv: string) =>
    (await RequestFactory.get(
      GMAE2EURL,
      `/api/reference/get-delivery-path-list-by-catalog?itemNumberCsv=${itemNumberCsv}`
    )) as ResultModel<Array<GetDeliveryPathByCatalogResultModel>>,
  getApplicationTypeByCertificateSchemeId: async (
    certificateSchemeId?: string | null
  ) =>
    (await RequestFactory.get(
      GMAE2EURL,
      `/api/reference/get-application-Type-by-certificate-scheme-id?certificateSchemeId=${certificateSchemeId}`
    )) as ResultModel<any>,
  getDeliveryPathByCertificateScheme: async (request: E2EProjectLineModel) =>
    (await RequestFactory.get(
      GMAE2EURL,
      `/api/reference/get-delivery-path-by-certificate-scheme?certificateSchemeId=${request.schemeId}&applicationTypeId=${request.applicationTypeId}`
    )) as ResultModel<any>,
  getAdditionalInfoByCertificateScheme: async (request: E2EProjectLineModel) =>
    (await RequestFactory.get(
      GMAE2EURL,
      `/api/reference/get-additional-info-by-certificate-scheme?certificateSchemeId=${request.schemeId}&applicationTypeId=${request.applicationTypeId}&deliveryPathId=${request.deliveryPathId}`
    )) as ResultModel<any>,
  getLeadTime: async (request: E2EProjectLineModel) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/reference/get-lead-time?schemeId=${
        request.schemeId
      }&deliveryPathId=${request.deliveryPathId}&applicationTypeId=${
        request.applicationTypeId
      }&additionalInfoId=${request.additionalInfoId ?? ""}&leadTypeId=${
        request.leadTypeId
      }`
    ),
  getSubContractorDetailsList: async (schemeId: string, statusId: string) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/reference/get-subcontractor-details-list?schemeId=${schemeId}&applicationTypeId=${statusId}`
    ),
};

export default ReferenceService;
