import { useEffect, useState } from "react";
import Details from "./Details/Details";
import RevisionHistory from "./RevisionHistory/RevisionHistory";
import LinkedProducts from "./LinkedProducts/LinkedProducts";
import classes from "./CertificateProductDetails.module.scss";
import Header from "./Header/Header";
import Remarks from "./Remarks/Remarks";
import Attachments from "./Attachments/Attachments";
import CloseIcon from "../../../../shared/close-icon/CloseIcon";
import ULTabs from "../../../../shared/ul-tabs/ULTabs";
import CertificateListService from "../../../../services/CertificateListService";
import { useSelector } from "react-redux";
import { selectCertificateList } from "../../../../features/certificateList/certificateListSlice";
import Actions from "./Actions/Actions";

const CertificateProductDetails = ({ setIsClickedOutSide }) => {
  const { selectedCertificateId } = useSelector(selectCertificateList);
  const [details, setDetails] = useState(null);
  const [isFetchingCertificateProductDetails, setIsFetchingCertificateProductDetails] =
    useState(true);

  const closeIcon = (
    <CloseIcon onCloseEvent={() => setIsClickedOutSide(true)} />
  );

  const getDetails = async (selectedCertificateId) => {
    const details = await CertificateListService.getCertificateDetails(
      selectedCertificateId
    );
    setDetails(details);
    setIsFetchingCertificateProductDetails(false);
  };

  const components = [
    {
      headerName: "Details",
      components: (
        <>
          <Actions status={details?.certificateStatus} />
          <Details details={details} />
          <LinkedProducts products={details?.linkedProducts} />
          <Remarks remarks={details?.remarks} />
          <Attachments />
        </>
      ),
    },
    {
      headerName: "Revision History",
      components: <RevisionHistory revisions={details?.revisionHistory} />,
    },
  ];

  useEffect(() => {
    getDetails(selectedCertificateId);
  }, [selectedCertificateId]);

  return (
    <div
      className={`${classes["details-font"]} ${classes["certificate-details-layout"]}`}
    >
      {isFetchingCertificateProductDetails && (
        <div className={classes["loading-container"]}>
          <div>Retrieving certificate details......</div>
          <div>
            <svg className="ul-progressCircle -medium">
              <circle
                className="ul-progressCircle__outer"
                cx="50%"
                cy="50%"
                r="35%"
              />
              <circle
                className="ul-progressCircle__inner"
                cx="50%"
                cy="50%"
                r="35%"
              />
            </svg>
          </div>
        </div>
      )}
      {!isFetchingCertificateProductDetails && (
        <>
          <Header closeIcon={closeIcon} details={details} />
          <ULTabs components={components} />
        </>
      )}
    </div>
  );
};

export default CertificateProductDetails;
