import classes from "./AddAttachmentsBulk.module.scss";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUpload,
  faPlus,
  faCircleCheck,
  faXmark,
  faTrash,
  faCircleExclamation,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { saveAs } from "file-saver";
import CertificateListService from "../../../../../services/CertificateListService";
import {
  validFileName,
  validFileSize,
  validFileType,
} from "../../../../../utils/helpers/file-validation.helpers";
import { CertificateDocumentType } from "../../../../../enums/CertificateDocumentType";
import { CertificateDocumentStatus } from "../../../../../enums/CertificateDocumentStatus";
import { Tooltip } from "primereact/tooltip";
import EcmIntegrationService from "../../../../../services/EcmIntegrationService";
import ConfirmationModal from "../../../../../shared/confirmation-modal/ConfirmationModal";
import useToastr from "../../../../../hooks/useToastr";
import { ConfirmDialog } from "primereact/confirmdialog";
import clsx from "clsx";
import { copyText } from "../../../../../utils/helpers/clipboard.helpers";
import FeatureFlagService from "../../../../../services/FeatureFlagService";
import { useTestReportValidator } from "../test-report-matched-found-popup/useTestReportValidator";
import {
  selectCertDetailValues,
  selectProdDetailValues,
} from "../../../../../features/createCertificate/createCertificateSlice";
import { TestReportMatchedFoundFileAssociatedCertificateModel } from "../../../../../shared/models/TestReportMatchedFoundFile.model";
import { CertificateEditDataModel } from "../../../../../shared/models/CertificateEditData.model";
export interface IAttachmentBulkFileObject {
  id?: string;
  group_index: number;
  isValid: boolean;
  file?: File;
  name: string;
  fileResult: string;
  status: CertificateDocumentStatus;
  ecmFileNodeId?: string;
  isDeleted: boolean;
  isShowInUi: boolean;
  ecmNodeId: string;
  associatedCertificates: TestReportMatchedFoundFileAssociatedCertificateModel[];
}

interface IAttachmentObject {
  id: string;
  name: string;
  ecmFileNodeId: string;
  status: string;
  group_index: string;
}

const maxValidFileCount: number = 15;
const AddAttachmentsBulk = (props: any) => {
  const {
    setAttachments,
    setAttachmentActiveTabIndex,
    setOutputAttachmentValues,
    inputAttachments,
    hasAttachmentLabel,
    testReportsValidatorHook,
    details,
  } = props;
  const dispatch = useDispatch();
  const [uploadFileList, setUploadFileList] = useState([[], [], []] as any);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const attachmentTabs = ["Certificate", "Test Report", "Others"];
  const [tooltipEcmNodeId, setTooltipEcmNodeId] = useState("Click to Copy");
  const [showDeleteFileConfirmation, setShowDeleteFileConfirmation] =
    useState(false);
  const [showMaxFileConfirmation, setShowMaxFileConfirmation] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(0);
  const [hasReachedMaxValidFileCount, setHasReachedMaxValidFileCount] =
    useState(false);

  const { showSuccess, showError } = useToastr();
  const productDetailsValues = useSelector(selectProdDetailValues);
  const certificateDetailsValues = useSelector(selectCertDetailValues);
  const validateFile = (fileObject: IAttachmentBulkFileObject) => {
    fileObject.isValid = true;

    let errorMessageList: string[] = [];
    if (!validFileSize(fileObject.file?.size)) {
      fileObject.isValid = false;
      errorMessageList.push(`File is too big`);
    }
    
    if (!validFileType(fileObject.name)) {
      fileObject.isValid = false;
      errorMessageList.push(`File type not allowed`);
    }

    if (!validFileName(fileObject.name)) {
      fileObject.isValid = false;
      errorMessageList.push(`Filename is invalid`);
    }

    const hasDuplicate =
      uploadFileList[activeTabIndex]?.some(
        (existingFile: any) => existingFile.name == fileObject.name
      ) === true;

    if (hasDuplicate) {
      fileObject.isValid = false;
      errorMessageList.push(`Duplicate file already exists`);
    }
    if (errorMessageList.length > 0)
      errorMessageList.push(`Please upload other file.`);
    fileObject.fileResult = errorMessageList.join(`. `);

    if (errorMessageList.length == 0)
      fileObject.fileResult =
        activeTabIndex != CertificateDocumentType.Others
          ? `File has been uploaded successfully and ready to link in WB and ECM TIC.`
          : `File has been uploaded successfully`;
  };

  const onAttachedFiles = (forAttachedFiles: IAttachmentBulkFileObject[]) => {
    const prevUploadFileList: any = uploadFileList.map(
      (attachmentGroup: any) => attachmentGroup
    );
    if (prevUploadFileList)
      prevUploadFileList[activeTabIndex].unshift(...forAttachedFiles);

    setUploadFileList(prevUploadFileList);
    sendAttachmentValuesToParent(prevUploadFileList);
  };

  const validateTestReport = async (files: IAttachmentBulkFileObject[]) => {
    let ownerReference =
      certificateDetailsValues.formValues?.ownerReference ??
      details.editData.find(
        (data: CertificateEditDataModel) =>
          data.attribute_name === "ownerReference"
      ).value[0];

    let scheme =
      certificateDetailsValues.formValues?.certificateType ??
      details.certificateScheme;

    if (
      FeatureFlagService.getECMFeatureFlag() &&
      FeatureFlagService.getLinkTestReportToMultipleCertificateFlag()
    ) {
      let baseModel = productDetailsValues.modelList.find(
        (model: any) => model.isBaseModel
      )?.modelName;

      if (!baseModel) {
        if (productDetailsValues?.modelList.length > 0) {
          baseModel = productDetailsValues.modelList[0].modelName;
        }
      }
      let filesToValidate = files.map((file) => file.name);

      return await testReportsValidatorHook.validateTestReports(
        filesToValidate,
        ownerReference,
        scheme,
        baseModel
      );
    }

    return null;
  };

  const onDropFiles = async (incomingFiles: File[]) => {
    let newFileList: IAttachmentBulkFileObject[] = [];
    incomingFiles.forEach((file: File) => {
      const fileObject: any = {};
      fileObject.id = "";
      fileObject.file = file;
      fileObject.name = file.name;
      fileObject[`group_index`] = activeTabIndex;
      fileObject.status = CertificateDocumentStatus.NotYetUploaded;
      validateFile(fileObject);
      newFileList.unshift(fileObject);
    });

    if (
      FeatureFlagService.getECMFeatureFlag() &&
      FeatureFlagService.getLinkTestReportToMultipleCertificateFlag()
    ) {
      if (activeTabIndex == 1) {
        const validationResult = await validateTestReport(
          newFileList.filter((x) => x.isValid)
        );

        if (validationResult?.hasDuplicate) {
          newFileList.forEach((newFile) => {
            var matchedTestReport = validationResult.matchedTestReports?.find(
              (attachment: any) => attachment.name == newFile.name
            );

            if (matchedTestReport) {
              newFile.ecmNodeId = matchedTestReport.ecmNodeId;
              newFile.status = matchedTestReport.isInEcmTic
                ? CertificateDocumentStatus.TestReportDuplicate
                : CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed;

              newFile.associatedCertificates =
                matchedTestReport.associatedCertificates;
            }
          });
        }
      }
    }

    const incomingValidFileCount = newFileList.filter(
      (file: IAttachmentBulkFileObject) => file.isValid == true
    ).length;

    const currentValidFileCount = uploadFileList[activeTabIndex].filter(
      (file: IAttachmentBulkFileObject) => file.isValid == true || file.id != ""
    ).length;

    if (currentValidFileCount == maxValidFileCount) {
      return;
    } else if (
      incomingValidFileCount + currentValidFileCount >
      maxValidFileCount
    ) {
      setShowMaxFileConfirmation(true);
    } else {
      onAttachedFiles(newFileList);
    }
  };

  const checkHasReachMaxValidFileCount = () => {
    const currentValidFileCount = uploadFileList[activeTabIndex].filter(
      (file: IAttachmentBulkFileObject) => file.isValid == true || file.id != ""
    ).length;
    if (currentValidFileCount >= maxValidFileCount)
      setHasReachedMaxValidFileCount(true);
    else setHasReachedMaxValidFileCount(false);
  };

  const sendAttachmentValuesToParent = (fileList: any) => {
    if (setOutputAttachmentValues) {
      setOutputAttachmentValues(fileList);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, open } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      onDrop: onDropFiles,
    });

  const renderDropzoneActive = () => {
    return (
      <>
        {isDragActive && !hasReachedMaxValidFileCount && (
          <div className={classes["attachment-dropzone"]}>
            <div className={classes["attachment-dropzone-content"]}>
              <div className={classes["attachment-dropzone-content-icon"]}>
                <FontAwesomeIcon icon={faCloudUpload} size="2x" />
              </div>
              <div className={classes["attachment-dropzone-content-text"]}>
                Drop files for upload
              </div>
            </div>
          </div>
        )}
        {isDragActive && hasReachedMaxValidFileCount && (
          <div
            className={`${classes["attachment-dropzone"]} ${classes["invalid"]}`}
          >
            <div className={classes["attachment-dropzone-content"]}>
              <div className={classes["attachment-dropzone-content-icon"]}>
                <FontAwesomeIcon icon={faCircleExclamation} size="2x" />
              </div>
              <div className={classes["attachment-dropzone-content-text"]}>
                Maximum valid uploaded files of {maxValidFileCount} has been
                reached.
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderAddFileHeader = () => {
    return (
      <div className={classes["attachment-add-file-container"]}>
        <input {...getInputProps()} />
        <div className={classes["attachment-add-file-buttons-container"]}>
          {activeTabIndex != 2 && (
            <div className={classes["left-section"]}>
              <div className={classes["legends"]}>
                <div className={classes["legend"]}>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size={"xs"}
                    className={classes["icon-1"]}
                  />
                  <span className={classes["legend-label"]}>
                    Linked/Saved to WB & ECM TIC
                  </span>
                </div>
                <div className={classes["legend"]}>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size={"xs"}
                    className={classes["icon-2"]}
                  />
                  <span className={classes["legend-label"]}>
                    Saved in ECM TIC
                  </span>
                </div>
                <div className={classes["legend"]}>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size={"xs"}
                    className={classes["icon-3"]}
                  />
                  <span className={classes["legend-label"]}>
                    Ready to Link/Save to WB & ECM TIC
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className={classes["right-section"]}>
            {hasReachedMaxValidFileCount && (
              <span className={classes["invalid-add-file-info"]}>
                Maximum valid uploaded files of {maxValidFileCount} has been
                reached.
              </span>
            )}
            <button
              className="ul-button -app-primary -small"
              onClick={open}
              disabled={hasReachedMaxValidFileCount}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" />
              Add File
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderEmptyDatatable = () => {
    return (
      <div className={classes["datatable-emptymessage-container"]}>
        <div>
          <div>
            <FontAwesomeIcon icon={faCloudUpload} size="lg" />
          </div>
          <div className={classes["datatable-emptymessage-label"]}>
            Drag and Drop Files here
          </div>
        </div>
      </div>
    );
  };

  const columnList: any[] = [];

  const tableColumnHeader = (col: any) => (
    <div>
      <span title={col.header}>{col.header}</span>
    </div>
  );

  const tableColumnBody = (rowData: any, col: any) => {
    const stockClassName = classNames({
      "text-overflow-ellipsis": true,
    });
    return (
      <div title={rowData[col.field]} className={stockClassName}>
        {rowData[col.field]}
      </div>
    );
  };

  const renderDynamicColumns = columnList.map((col: any) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={tableColumnHeader(col)}
        style={{ flexGrow: 1, flexBasis: col.width, width: col.width }}
        body={tableColumnBody.bind(col)}
      />
    );
  });

  const gridFileNameTemplate = (rowData: any) => {
    const stockClassName = classNames(
      {
        "text-overflow-ellipsis": true,
      },
      classes["grid-filename"]
    );
    return (
      <div title={rowData.name} className={stockClassName}>
        {rowData.id != "" && (
          <a className={classes["link"]} onClick={() => downloadFile(rowData)}>
            {rowData.name}
          </a>
        )}
        {rowData.id == "" && (
          <span
            className={
              !rowData.isValid ||
              rowData.status ==
                CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
                ? classes["error"]
                : ""
            }
          >
            {rowData.name}
          </span>
        )}
      </div>
    );
  };

  const gridStatusIconTemplate = (rowData: any, props: any) => {
    return (
      <>
        {rowData.status == CertificateDocumentStatus.LinkedToWbAndEcmTic && (
          <div className={`tooltip-status-target${props.rowIndex}`}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              size={"xs"}
              className={classes["icon-1"]}
            />
            <Tooltip
              target={`.tooltip-status-target${props.rowIndex}`}
              className="custom-tooltip"
              position="right"
            >
              <div>Linked/Saved to WB & ECM TIC</div>
            </Tooltip>
          </div>
        )}
        {rowData.status == CertificateDocumentStatus.SavedInEcmTic && (
          <div className={`tooltip-status-target${props.rowIndex}`}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              size={"xs"}
              className={classes["icon-2"]}
            />
            <Tooltip
              target={`.tooltip-status-target${props.rowIndex}`}
              className="custom-tooltip"
              position="right"
            >
              <div>Saved in ECM TIC</div>
            </Tooltip>
          </div>
        )}
        {rowData.status ==
          CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic && (
          <div className={`tooltip-status-target${props.rowIndex}`}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              size={"xs"}
              className={classes["icon-3"]}
            />
            <Tooltip
              target={`.tooltip-status-target${props.rowIndex}`}
              className="custom-tooltip"
              position="right"
            >
              <div>Ready to Link/Save to WB & ECM TIC</div>
            </Tooltip>
          </div>
        )}
        {rowData.status == CertificateDocumentStatus.TestReportDuplicate &&
          rowData.id && (
            <div className={`tooltip-status-target${props.rowIndex}`}>
              <FontAwesomeIcon
                icon={faCircleCheck}
                size={"xs"}
                className={classes["icon-3"]}
              />
              <Tooltip
                target={`.tooltip-status-target${props.rowIndex}`}
                className="custom-tooltip"
                position="right"
              >
                <div>Ready to Link/Save to WB & ECM TIC</div>
              </Tooltip>
            </div>
          )}

        {rowData.status ==
          CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed &&
          rowData.id && (
            <div className={`tooltip-status-target${props.rowIndex}`}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                size={"xs"}
                className={classes["icon-4"]}
              />
              <Tooltip
                target={`.tooltip-status-target${props.rowIndex}`}
                className="custom-tooltip"
                position="right"
              >
                <div>
                  File already exists in ECM TIC. Please continue processing the
                  linking of file.
                </div>
              </Tooltip>
            </div>
          )}
      </>
    );
  };

  const gridEcmNodeIdTemplate = (rowData: any) => {
    return rowData.status == CertificateDocumentStatus.TestReportDuplicate ||
      rowData.status ==
        CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed ? (
      <></>
    ) : (
      <>
        {rowData.ecmFileNodeId && (
          <div className="tooltip-ecmfilenode-target">
            <a
              onClick={() => {
                copyText(rowData.ecmFileNodeId);
                setTooltipEcmNodeId("Copied!");
              }}
              onMouseOver={() => setTooltipEcmNodeId("Click to Copy")}
            >
              {rowData.ecmFileNodeId}
            </a>

            <Tooltip
              target=".tooltip-ecmfilenode-target"
              className="custom-tooltip"
              position="right"
            >
              <div>{tooltipEcmNodeId}</div>
            </Tooltip>
          </div>
        )}
      </>
    );
  };

  const gridStatusTemplate = (rowData: any, props: any) => {
    const stockClassName = clsx(
      {
        "text-overflow-ellipsis": true,
      },
      classes["gridaction-container"]
    );

    const statusClassName = clsx(classes["fileresult"], {
      [classes["success"]]:
        rowData.isValid &&
        rowData.status != CertificateDocumentStatus.TestReportDuplicate &&
        rowData.status !=
          CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed,

      [classes["warning"]]:
        rowData.status == CertificateDocumentStatus.TestReportDuplicate,
      [classes["error"]]:
        !rowData.isValid ||
        rowData.status ==
          CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed,
    });
    let status = "";
    switch (rowData.status) {
      case CertificateDocumentStatus.TestReportDuplicate:
        status =
          "This Test Report already exists in ECM TIC. Please link the existing Test Report or remove this file.";
        break;
      case CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed:
        status = "";
        break;
      default:
        status = rowData.fileResult;
    }

    return (
      <div className={stockClassName} title={status}>
        <span className={statusClassName}>{status}</span>
      </div>
    );
  };
  const gridActionTemplate = (rowData: any, props: any) => {
    return (
      <div className={classes["gridaction-container"]}>
        {rowData.isValid &&
          (rowData.status == CertificateDocumentStatus.NotYetUploaded ||
            (rowData.status == CertificateDocumentStatus.TestReportDuplicate &&
              !rowData.id)) && (
            <Button
              className="p-button-rounded p-button-secondary p-button-text p-button-sm"
              onClick={() => deleteAttachment(props.rowIndex)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          )}
        {(rowData.status ==
          CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic ||
          (rowData.status == CertificateDocumentStatus.TestReportDuplicate &&
            rowData.id)) && (
          <Button
            className="p-button-rounded p-button-secondary p-button-text p-button-sm"
            title="Delete Attachment"
            onClick={() => {
              handleDeletAttachment(props.rowIndex);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </div>
    );
  };
  const downloadFile = async (file: any) => {
    switch (file.status) {
      case CertificateDocumentStatus.LinkedToWbAndEcmTic:
      case CertificateDocumentStatus.SavedInEcmTic:
        await EcmIntegrationService.downloadEcmFile(file.ecmFileNodeId).then(
          (response) => {
            if (response) {
              saveAs(response, file.name);
            } else {
              showError(
                "Unable to Download File",
                "There is a problem while connecting to ECM Folder. Please try again later."
              );
            }
          }
        );
        break;
      case CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic:
        await CertificateListService.downloadCertificateDocument(file.id).then(
          (response) => {
            if (response) {
              saveAs(response, file.name);
            } else {
              showError("Error!", "File does not exists.");
            }
          }
        );
        break;
    }
  };
  const deleteAttachment = (index: any) => {
    const prevUploadFileList: any = uploadFileList.map(
      (attachmentGroup: any) => attachmentGroup
    );
    if (prevUploadFileList) {
      prevUploadFileList[activeTabIndex].splice(index, 1);
    }

    setUploadFileList(prevUploadFileList);
    sendAttachmentValuesToParent(prevUploadFileList);
  };

  const deleteConfirmationMessage = () => {
    return (
      <span>
        The document(s) will be deleted from GMAWB File Storage.
        <br />
        <br />
        Would you like to continue?
      </span>
    );
  };

  const maxFileDialogBody = () => {
    return (
      <>
        <div className="custom-confirm-dialog-body">
          <div className="custom-space-below">
            Maximum valid file count is exceeded.
          </div>
          <div>Please upload 15 or fewer valid file(s) types.</div>
        </div>
      </>
    );
  };

  const maxFileFooter = () => {
    return (
      <>
        <div className="custom-confirm-dialog-footer">
          <button
            className="ul-button -medium default-cancel-button custom-confirm-dialog-reject-btn"
            onClick={() => setShowMaxFileConfirmation(false)}
          >
            CLOSE
          </button>
        </div>
      </>
    );
  };

  const handleDeletAttachment = (index: any) => {
    setFileToDelete(index);
    setShowDeleteFileConfirmation(true);
  };

  const handleDeleteFileToBlobStorage = async () => {
    const prevUploadFileList: any = uploadFileList.map(
      (attachmentGroup: any) => attachmentGroup
    );

    if (!prevUploadFileList) return;

    const file = prevUploadFileList[activeTabIndex][fileToDelete];
    CertificateListService.deleteFileFromBlob(file.id)
      .then((response) => {
        if (!response?.isSuccess) {
          setShowDeleteFileConfirmation(false);
          showError("Error!", "Attachment(s) has not been deleted.");
          return;
        }

        showSuccess(
          "Success!",
          "Attachment(s) has been deleted successfully from GMAWB File Storage."
        );

        prevUploadFileList[activeTabIndex].splice(fileToDelete, 1);

        if (setAttachments) {
          const flatAttachmentList = prevUploadFileList
            .flatMap((attachment: IAttachmentObject) => attachment)
            .filter((fileData: IAttachmentObject) => fileData.id != "");

          const fileList = flatAttachmentList.map(
            (attachment: IAttachmentObject) => {
              return {
                id: attachment.id,
                originalFileName: attachment.name,
                ecmFileNodeId: attachment.ecmFileNodeId,
                status: attachment.status,
                documentType: attachment.group_index,
              };
            }
          );

          setAttachments(fileList);
        }

        setShowDeleteFileConfirmation(false);
        sendAttachmentValuesToParent(prevUploadFileList);
        setUploadFileList(prevUploadFileList);
      })

      .catch((error) => {
        setShowDeleteFileConfirmation(false);
        showError("Error!", "Attachment(s) has not been deleted.");
        return;
      });
  };

  useEffect(() => {
    processInputAttachments(inputAttachments);
  }, [inputAttachments]);

  const processInputAttachments = (inputAttachments: any) => {
    if (!inputAttachments) return;

    let newFileList: IAttachmentBulkFileObject[] = [];
    inputAttachments.forEach((file: any) => {
      const fileObject: any = {};
      fileObject.id = file.id;
      fileObject.name = file.originalFileName;
      fileObject[`group_index`] = file.documentType;
      fileObject.status = file.status;
      fileObject.ecmFileNodeId = file.ecmFileNodeId;
      newFileList.unshift(fileObject);
    });

    const certificateAttachments = newFileList.filter(
      (file: IAttachmentBulkFileObject) =>
        file[`group_index`] == CertificateDocumentType.Certificate
    );

    const testReportAttachments = newFileList.filter(
      (file: IAttachmentBulkFileObject) =>
        file[`group_index`] == CertificateDocumentType.TestReport
    );

    const othersAttachments = newFileList.filter(
      (file: IAttachmentBulkFileObject) =>
        file[`group_index`] == CertificateDocumentType.Others
    );

    const loadedAttachments = [
      certificateAttachments,
      testReportAttachments,
      othersAttachments,
    ];

    setUploadFileList(loadedAttachments);
    sendAttachmentValuesToParent(loadedAttachments);
  };

  useEffect(() => {
    checkHasReachMaxValidFileCount();
  }, [uploadFileList]);

  useEffect(() => {
    checkHasReachMaxValidFileCount();
  }, [activeTabIndex]);

  return (
    <>
      <div className={classes["attachment-container"]}>
        <div className={classes["attachment-header"]}>
          {hasAttachmentLabel && <>Attachments </>}
          <span className={classes["attachment-header-note"]}>
            Note: You can drag and drop files inside this panel | Maximum upload
            file size: (50MB) | Allowed File Type: (JPG, JPEG, DOC, DOCX, BMP,
            GIF, PDF, PNG)
          </span>
        </div>
        <div
          className={`${classes["attachment-body"]} add-attachment-bulk-body-overrides`}
        >
          <TabView
            style={{ height: "100%" }}
            activeIndex={activeTabIndex}
            onTabChange={(e) => {
              setActiveTabIndex(e.index);
              if (setAttachmentActiveTabIndex)
                setAttachmentActiveTabIndex(e.index);
            }}
          >
            {attachmentTabs.map((tab, tabIndex) => (
              <TabPanel
                className="new-cert-prod-dtls-tab-panel"
                header={tab}
                key={"tab-panel-" + tab}
              >
                <div
                  {...getRootProps({
                    className: `${classes[`attachment-panel-body`]} ${
                      isDragActive && classes[`is-drag-active`]
                    }`,
                  })}
                >
                  {renderDropzoneActive()}
                  {renderAddFileHeader()}
                  <div className={classes["datatable-container"]}>
                    <DataTable
                      emptyMessage={renderEmptyDatatable()}
                      size="small"
                      value={uploadFileList[tabIndex]}
                      responsiveLayout="scroll"
                      scrollable
                    >
                      <Column
                        style={{
                          flexGrow: 1,
                          flexBasis: "37%",
                          width: "37%",
                        }}
                        header="Document File Name"
                        body={gridFileNameTemplate}
                      ></Column>
                      {tabIndex != CertificateDocumentType.Others && (
                        <Column
                          style={{
                            flexGrow: 1,
                            flexBasis: "3%",
                            width: "3%",
                          }}
                          header=""
                          body={gridStatusIconTemplate}
                        ></Column>
                      )}

                      {tabIndex != CertificateDocumentType.Others && (
                        <Column
                          style={{
                            flexGrow: 1,
                            flexBasis: "15%",
                            width: "15%",
                          }}
                          header="ECM TIC Node ID"
                          body={gridEcmNodeIdTemplate}
                        ></Column>
                      )}

                      {renderDynamicColumns}

                      <Column
                        style={{
                          flexGrow: 1,
                          flexBasis:
                            tabIndex != CertificateDocumentType.Others
                              ? "43%"
                              : "58%",
                          width:
                            tabIndex != CertificateDocumentType.Others
                              ? "43%"
                              : "58%",
                          paddingRight: "0px",
                        }}
                        align="right"
                        header=""
                        body={gridStatusTemplate}
                      ></Column>
                      <Column
                        align="left"
                        style={{
                          flexGrow: 1,
                          flexBasis: "2%",
                          width: "2%",
                          paddingLeft: "0px",
                        }}
                        header=""
                        body={gridActionTemplate}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </TabPanel>
            ))}
          </TabView>
        </div>
      </div>

      <ConfirmationModal
        visible={showDeleteFileConfirmation}
        onCancel={() => setShowDeleteFileConfirmation(false)}
        onConfirm={() => handleDeleteFileToBlobStorage()}
        headerDisplay={"Delete File Confirmation"}
        confirmationMessageHTML={deleteConfirmationMessage()}
        buttonNoDisplay={`No`}
        buttonYesDisplay={`Yes`}
      />
      <ConfirmDialog
        style={{ width: "520px" }}
        className="custom-confirm-dialog"
        visible={showMaxFileConfirmation}
        header="Valid File Count Exceeded"
        message={maxFileDialogBody}
        footer={maxFileFooter}
        onHide={() => setShowMaxFileConfirmation(false)}
      />
    </>
  );
};
export default AddAttachmentsBulk;
