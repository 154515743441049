import { Badge } from "primereact/badge";
import classes from "./ProjectSingleCollaboratorAvatar.module.scss";
import clsx from "clsx";
import HandlerType from "../../enums/handler-type";
interface ProjectSingleCollaboratorAvatarProps {
  name: string;
  handlerType: HandlerType;
}
const getInitials = (name: string) => {
  return name
    ?.split(",")
    ?.map((value) => value.trim())
    ?.reverse()
    ?.map((value, index, array) =>
      index === 0 || index + 1 === array.length
        ? (value[0] ?? "").toUpperCase()
        : null
    )
    .join("");
};
const getHandlerClass = (handlerType: HandlerType) =>
  classes[HandlerType[handlerType]?.toLowerCase()];
const ProjectSingleCollaboratorAvatar = (
  props: ProjectSingleCollaboratorAvatarProps
) => {
  return (
    <>
      {props.name && (
        <div className={classes["badge-container"]}>
          {
            <span title={props.name}>
              <Badge
                value={getInitials(props.name)}
                className={clsx(
                  classes["badge"],
                  getHandlerClass(props.handlerType)
                )}
                size="large"
              ></Badge>
            </span>
          }
        </div>
      )}
    </>
  );
};
export default ProjectSingleCollaboratorAvatar;
