import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCertificateId: null,
  isReloadGrid: null,
  isReloadDetails: null,
  isLoadingCertificateList: false,
};

export const certificateListSlice = createSlice({
  name: "certificateList",
  initialState,
  reducers: {
    updateSelectedCertificateId: (state, action) => {
      state.selectedCertificateId = action.payload;
    },
    reloadCertificateList: (state, action) => {
      state.isReloadGrid = action.payload;
    },
    reloadCertificateDetails: (state, action) => {
      state.isReloadDetails = action.payload;
    },
    updateIsLoadingCertificateList: (state, action) => {
      state.isLoadingCertificateList = action.payload;
    },
  },
});

export const {
  updateSelectedCertificateId,
  reloadCertificateList,
  reloadCertificateDetails,
  updateIsLoadingCertificateList,
} = certificateListSlice.actions;

export const selectCertificateList = (state) => state.certificateList;

export const selectIsLoadingCertificateList = (state) =>
  state.certificateList.isLoadingCertificateList;

export default certificateListSlice.reducer;
