import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputText } from "primereact/inputtext";
import SchemeInformationDetailRepresentative from "./scheme-information-detail-representative/SchemeInformationDetailRepresentative";

import CollapsibleForms from "../../form/collapsible-forms/CollapsibleForms";
import classes from "./SchemeInformation.module.scss";

const SchemeInformation = ({
  schemes,
  // The actual list coming from integrated api.
  schemesList,
  onRemoveScheme,
  onProviderChange,
  onFieldChange,
  onAddRepresentative,
  onRemoveRepresentative,
  onChangeRepresentative,
  isReadOnly,
  isUserToProvideOnly,
  disableIntegration,
}) => {
  const localRepProviders = [
    {
      value: "ulToProvide",
      label: "UL to provide",
    },
    {
      value: "customerToProvide",
      label: "Customer to provide",
    },
  ];

  const isLoadingSchemesList = !schemesList?.length && !disableIntegration;

  return (
    <div>
      <div className="form-section">
        <div className="form-section-header">
          <h4 className="step-title">
            The following countries will require you to supply the local
            importer/representative. UL cannot provide the services for the
            following schemes.
          </h4>
        </div>
      </div>

      {isLoadingSchemesList && (
        <h3 className={classes["loading"]}>Loading ...</h3>
      )}

      {!isLoadingSchemesList && (
        <>
          {schemes.filter((x) => !x.hasMultipleRepresentative).length === 0 && (
            <h3 className={`${classes["no-scheme-header"]}`}>
              No scheme/s selected
            </h3>
          )}

          <CollapsibleForms
            items={schemes.filter((x) => !x.hasMultipleRepresentative)}
            formTitleTemplate={(scheme, i, isActive) => {
              return <>{scheme.certScheme || scheme.schemeName}</>;
            }}
            formTemplate={(scheme, i, expand) => (
              <SchemeInformationDetailRepresentative
                onFormFocus={expand}
                schemes={schemes}
                scheme={scheme}
                onFieldChange={onFieldChange}
                onAddRepresentative={onAddRepresentative}
                onRemoveRepresentative={onRemoveRepresentative}
                onChangeRepresentative={onChangeRepresentative}
                disableIntegration={disableIntegration}
                controlClassName={`schemeInfoDetailRep-${i}`}
              />
            )}
            deletable={true}
            canDeleteAll={true}
            showDeleteConfirmationMessage={false}
            onDeleteItem={(i, scheme) => onRemoveScheme(scheme)}
          />
        </>
      )}

      <br />

      {!isUserToProvideOnly && (
        <>
          <div className="form-section">
            <div className="form-section-header">
              <h4 className="step-title">
                The following countries will require a local representative
                where UL can support with a local representative service.
              </h4>
            </div>

            <div className={`${classes["header-container"]}`}>
              <h4>
                UL has selected the appropriate choice based on the quotation
                provided. If any changes need to be made, please update as
                appropriate.
              </h4>
              <label>
                Where it is indicated "Customer to provide", please provide the
                local representative details.
              </label>
            </div>
            <div className="form-section-content">
              <h4>
                The following countries require a local representative, which
                you will supply based on
              </h4>
            </div>

            <div className="form-section-content">
              {schemes
                .filter((x) => x.hasMultipleRepresentative)
                ?.map((scheme, i) => (
                  <div
                    key={scheme.certScheme}
                    className={`${classes["provide-section"]}`}
                  >
                    <InputText
                      value={
                        schemesList?.find((s) => s.schemeId === scheme.schemeId)
                          ?.certScheme
                      }
                      className={`provide-section-${i}`}
                      disabled={true}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => onRemoveScheme(scheme)}
                      className={`remove-scheme-btn-${i}`}
                    />

                    <div
                      className={classes["local-rep-provider-radio-buttons"]}
                    >
                      {localRepProviders.map((v) => (
                        <div
                          key={v.value}
                          className={`radio-button-container`}
                          onChange={(e) => onProviderChange(e, scheme)}
                          disabled={isReadOnly}
                        >
                          <input
                            checked={
                              (v.value === "ulToProvide" &&
                                scheme.uLToProvide) ||
                              (v.value === "customerToProvide" &&
                                !scheme.uLToProvide)
                            }
                            readOnly
                            id={v.value + scheme.certScheme}
                            type="radio"
                            name={"local-rep-providers" + scheme.certScheme}
                            disabled={isReadOnly}
                            className={"local-rep-providers" + scheme.certScheme}
                          />
                          <label
                            value={v.value}
                            htmlFor={v.value + scheme.certScheme}
                          >
                            {v.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {isLoadingSchemesList && (
            <h3 className={classes["loading"]}>Loading ...</h3>
          )}

          {!isLoadingSchemesList && (
            <>
              {schemes.filter((x) => x.hasMultipleRepresentative).length ===
                0 && (
                  <h3 className={`${classes["no-scheme-header"]}`}>
                    No scheme/s selected
                  </h3>
                )}

              <CollapsibleForms
                items={schemes.filter(
                  (x) => x.hasMultipleRepresentative && !x.uLToProvide
                )}
                formTitleTemplate={(scheme) => {
                  return <>{scheme.certScheme || scheme.schemeName}</>;
                }}
                formTemplate={(scheme, i, expand) => (
                  <SchemeInformationDetailRepresentative
                    onFormFocus={expand}
                    schemes={schemes}
                    scheme={scheme}
                    onFieldChange={onFieldChange}
                    onAddRepresentative={onAddRepresentative}
                    onRemoveRepresentative={onRemoveRepresentative}
                    onChangeRepresentative={onChangeRepresentative}
                    controlClassName={`schemeInfoDetailRep-${i}`}
                  />
                )}
                deletable={true}
                canDeleteAll={true}
                showDeleteConfirmationMessage={false}
                onDeleteItem={(i, scheme) => onRemoveScheme(scheme)}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SchemeInformation;
