import { useState, useEffect, useRef } from "react";

const useClickedOutside = () => {
  const [isClickedOutSide, setIsClickedOutSide] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsClickedOutSide(true);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isClickedOutSide, setIsClickedOutSide };
};

export default useClickedOutside;
