import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectE2EProjectList,
  updateE2EProjectList,
} from "../../../features/projectWorkQueue/projectWorkQueueSlice";
import classes from "./ProjectAlreadyExistsModal.module.scss";
import { useSignalR } from "../../../hooks/useSignalR";
import { toCamel } from "../../../utils/helpers/object.helpers";
import ProjectService from "../../../services/ProjectService";
import * as _ from "lodash";

interface ProjectAlreadyExistsModalProps {
  onProceed: (projectId: string, projectName: string) => void;
  onCancel: () => void;
  visible: boolean;
  accountNo: string;
  partySiteNumber: string;
}
interface ProjectListOptions {
  id: string;
  name: string;
  status: string | null;
}

const ProjectAlreadyExistsModal = (props: ProjectAlreadyExistsModalProps) => {
  const dispatch = useDispatch();
  const projectListSelector = useSelector(selectE2EProjectList);
  const projectList = useSelector(selectE2EProjectList);

  const [projectId, setProjectId] = useState<string | null>(null);
  const [projectName, setProjectName] = useState<string>("");
  const [accountNo, setAccountNo] = useState<string>(props.accountNo);
  const [partySiteNumber, setPartySiteNumber] = useState<string>(
    props.partySiteNumber
  );

  const dropdownRef = useRef<any>(null);

  const createNewProjectOption: ProjectListOptions = {
    id: "0",
    name: "<Create New GMAWB Project>",
    status: null,
  };
  const [dropdownOptions, setDropdownOptions] = useState<ProjectListOptions[]>([
    createNewProjectOption,
  ]);

  const projectStatusUpdates = (process.env.REACT_APP_GMAE2E_URL +
    "/hubs/project_updatestatus") as string;

  const { connection: projectStatusUpdatesSignalRConnection } =
    useSignalR(projectStatusUpdates);

  useEffect(() => {
    if (!props.visible) {
      setProjectId(null);
      setProjectName("");
      setAccountNo("");
      setPartySiteNumber("");
    }
  }, [props]);

  useEffect(() => {
    if (projectListSelector.length) {
      let dropdownList = _.map(projectListSelector, (project) => {
        let item: ProjectListOptions = {
          id: project["id"]?.toString() ?? "",
          name: project["name"]?.toString() ?? "",
          status: project["status"]?.toString() ?? "",
        };
        return item;
      });

      setDropdownOptions([createNewProjectOption, ...dropdownList]);
    }
  }, [projectListSelector]);

  const headerTemplate = () => {
    return (
      <h3 className={classes["dialog-header__title"]}>
        GMAWB Project(s) Already Exists
      </h3>
    );
  };

  const handleProceed = async () => {
    props.onProceed(projectId!, projectName!);
  };

  const footerTemplate = () => {
    return (
      <div className={classes["dialog-footer"]}>
        <Button
          onClick={handleProceed}
          className={classes["btn-proceed"]}
          disabled={
            projectId === null ||
            projectId === "" ||
            (projectId === "0" ? false : projectName === "")
          }
          label={"Proceed"}
        />
        <Button
          onClick={onHide}
          className={classes["btn-cancel"]}
          label={"Cancel"}
        />
      </div>
    );
  };

  const handleSelectProjectName = (e: any) => {
    setProjectId(e.value);
    const project = projectList.find((info) => info.id === e.value);
    setProjectName(project?.name ?? "");
  };

  const onHide = () => {
    props.onCancel();
  };

  const projectsDropdown = (
    <Dropdown
      placeholder="-- Select Project Name --"
      ref={dropdownRef}
      filter
      filterBy={"name"}
      className={classes["project-name__input"]}
      value={projectId}
      options={dropdownOptions}
      optionLabel="name"
      optionValue="id"
      onChange={handleSelectProjectName}
    />
  );

  const onDragStart = () => {
    dropdownRef.current?.setState({ overlayVisible: false });
  };

  const updateProjectList = async (
    messagedResult: any,
    selectedProjectId: string | null
  ) => {
    if (!messagedResult) return;

    let projectStatus = _.map(messagedResult, (project) => {
      return {
        id: project["id"],
        name: project["name"],
        status: project["status"],
      };
    });

    let hasCanceledOrCompleted = _.filter(projectStatus, (project) => {
      return project.status === 3 || project.status === 4;
    }).length;

    if (!hasCanceledOrCompleted) return;

    if (selectedProjectId === projectStatus[0].id) {
      setProjectId(null);
      setProjectName("");
    }

    const fetchedExistingProjects =
      await ProjectService.getProjectListByCustomer(accountNo, partySiteNumber);

    if (
      !fetchedExistingProjects.isSuccess ||
      !fetchedExistingProjects.data.length
    )
      return;

    let newProjectList = _.map(fetchedExistingProjects.data, (project) => {
      let item: ProjectListOptions = {
        id: project["id"]?.toString() ?? "",
        name: project["name"]?.toString() ?? "",
        status: project["status"]?.toString() ?? "",
      };
      return item;
    });

    setDropdownOptions([createNewProjectOption, ...newProjectList]);
    dispatch(updateE2EProjectList(fetchedExistingProjects.data));
  };

  useEffect(() => {
    if (projectStatusUpdatesSignalRConnection) {
      projectStatusUpdatesSignalRConnection.on(
        "projectStatusUpdated",
        async (result) => {
          if (result) {
            const parsedResult = toCamel(JSON.parse(result));
            updateProjectList(parsedResult, projectId);
          }
        }
      );
    }
  }, [projectStatusUpdatesSignalRConnection, projectId]);

  return (
    <Dialog
      className={classes["dialog"]}
      style={{ width: "40vw" }}
      visible={props.visible}
      header={headerTemplate}
      onHide={onHide}
      footer={footerTemplate}
      onDragStart={onDragStart}
    >
      <div className={classes["dialog-body"]}>
        <div className={classes["project-name"]}>
          <>
            <label className={classes["verbiage"]}>
              There are already existing projects from the same company. <br />
              Would you like to add your selected order lines to this project
              instead? Please select your project below:
            </label>
            {projectsDropdown}
          </>
        </div>
      </div>
    </Dialog>
  );
};

export default ProjectAlreadyExistsModal;
