import RequestFactory from "./RequestFactory";

const generalProductInformationUrl =
  process.env.REACT_APP_GENERAL_PRODUCT_INFORMATION_URL;

const CustomerService = {
  validateOtp: (generalProductInfoId: string, otp: string) =>
    RequestFactory.post(
      generalProductInformationUrl,
      "/api/customer/validate-otp",
      { otp, generalProductInfoId }
    ),
  onUnauthorized: () => {
    sessionStorage.removeItem("gpi_token");

    if (window.location.pathname !== "/customer-view/otp")
      window.location.reload();
  },
};

export default CustomerService;
