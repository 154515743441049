import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classes from "./ProjectAssignCoHandlerDialog.module.scss";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { E2EUserPaginatedModel } from "../../../../../shared/models/E2EUserPaginatedModel";
import GMAWBUsersService from "../../../../../services/GMAWBUsersService";
import { useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "primereact/tooltip";
import useToastr from "../../../../../hooks/useToastr";
import PmrE2EProjectTaskHandler from "../../interfaces/pmr-e2e-project-task-handlers";
import HandlerType from "../../enums/handler-type";
import { selectShowFlexTasks } from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
import {
  updateFlexTaskOrderLineContributor,
  updateFlexTaskCoHandler,
  upsertProjectFlexTasksContributors,
  updateProjectLevelContributors,
  updateFlexTaskMilestoneOrderLineContributor,
} from "../../../../../features/projectManagement/projectManagementSlice";
import ProjectService from "../../../../../services/ProjectService";
import PmrE2EProjectTaskModel, {
  PmrE2EProjectTaskModelList,
} from "../../interfaces/pmr-e2e-project-task-model";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import ProjectAssignCoHandlerDropdown from "./project-assign-cohandler-dropdown-dialog/ProjectAssignCoHandlerDropdown";
import { AssignCoHandlerModel } from "../../../../../shared/models/service-models/AssignCOHandler.model";

interface ProjectAssignCoHandlerDialogProps {
  onClose: () => void;
  show: boolean;
  assignCoHandlerParam: AssignCoHandlerModel;
}

interface AssignCoHandlerDetails {
  projectId?: string;
  e2EProjectLineFlexTaskId?: string;
  e2EProjectLineMilestoneId?: string;
  projectNumber?: string;
  flexHandlerDetails?: E2EUserPaginatedModel;
  gmaWbHandlerDetails?: E2EUserPaginatedModel;
  taskHandlerDetails?: E2EUserPaginatedModel | null;
  milestoneHandlerDetails?: E2EUserPaginatedModel;
  isAssignCoHandlerMailboxNotRequired: boolean;
  e2EProjectLineId: string | undefined;
}

export interface ProjectUserSource {
  currentSkip: number;
  currentTake: number;
  currentSearchPhrase: string;
  data: E2EUserPaginatedModel[];
}

interface DropdownLoading {
  flexDropdownLoading: boolean;
  gmawbDropdownLoading: boolean;
  taskHandlerDropdownLoading: boolean;
  milestoneHandlerDropdownLoading: boolean;
}

const ProjectAssignCoHandlerDialog = (
  props: ProjectAssignCoHandlerDialogProps
) => {
  const initDropdownValues: ProjectUserSource = {
    currentSkip: 0,
    currentTake: 5,
    currentSearchPhrase: "",
    data: [],
  };
  const showFlexTasks = useSelector(selectShowFlexTasks);
  const { showSuccess } = useToastr();
  const dispatch = useDispatch();

  const [
    isAssignCoHandlerMailboxNotRequired,
    setIsAssignCoHandlerMailboxNotRequired,
  ] = useState<boolean>(false);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

  const [taskHandlerDropdownOptions, setTaskHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);

  const [dropdownLoading, setDropdownLoading] = useState<DropdownLoading>({
    flexDropdownLoading: true,
    gmawbDropdownLoading: true,
    taskHandlerDropdownLoading: true,
    milestoneHandlerDropdownLoading: !showFlexTasks.isOn,
  });

  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  const fetchTaskHandlerCollaborator =
    props.assignCoHandlerParam?.collaborators?.find(
      (collaborator) => collaborator?.handlerType === HandlerType.TaskHandler
    );

  const [taskHandler, setTaskHandler] = useState<E2EUserPaginatedModel>();
  const [originalTaskHandler, setOriginalTaskHandler] =
    useState<E2EUserPaginatedModel>();
  const [milestoneHandler, setMilestoneHandler] =
    useState<E2EUserPaginatedModel>();
  useEffect(() => {
    if (props.show) {
      setIsModalLoading(true);
      setIsAssignCoHandlerMailboxNotRequired(
        props.assignCoHandlerParam.isAssignCoHandlerMailboxNotRequired ?? false
      );
      GMAWBUsersService.getCOHandlerPaginatedUsers(
        0,
        5,
        HandlerType.TaskHandler,
        false,
        ""
      ).then((response) => {
        let taskHandlerData: E2EUserPaginatedModel[] = structuredClone(
          response?.data
        );
        if (fetchTaskHandlerCollaborator) {
          if (
            taskHandlerData?.some(
              (data) =>
                data.normalizedEmail === fetchTaskHandlerCollaborator.userEmail
            )
          ) {
            taskHandlerData = taskHandlerData?.filter(
              (data) =>
                data.normalizedEmail !== fetchTaskHandlerCollaborator.userEmail
            );
          }
          taskHandlerData?.unshift({
            displayName: fetchTaskHandlerCollaborator.displayName,
            normalizedEmail: fetchTaskHandlerCollaborator.userEmail,
          });
        }

        setTaskHandlerDropdownOptions((current) => ({
          ...current,
          data: taskHandlerData,
        }));

        setTaskHandler((current) => ({
          ...current,
          normalizedEmail: fetchTaskHandlerCollaborator?.userEmail!,
          displayName: fetchTaskHandlerCollaborator?.displayName!,
        }));

        setOriginalTaskHandler((current) => ({
          ...current,
          normalizedEmail: fetchTaskHandlerCollaborator?.userEmail!,
          displayName: fetchTaskHandlerCollaborator?.displayName!,
        }));

        setDropdownLoading((current) => ({
          ...current,
          taskHandlerDropdownLoading: false,
        }));
        setIsModalLoading(false);
      });
    }
  }, [props.show]);

  useEffect(() => {
    if (
      !dropdownLoading.flexDropdownLoading &&
      !dropdownLoading.gmawbDropdownLoading &&
      !dropdownLoading.taskHandlerDropdownLoading
    ) {
      if (
        !showFlexTasks.isOn &&
        !dropdownLoading.milestoneHandlerDropdownLoading
      )
        setIsModalLoading(false);

      if (showFlexTasks.isOn) setIsModalLoading(false);
    }
  }, [dropdownLoading]);

  const dialogStyle = {
    width: "32rem",
  };

  const handlerUpdateProjectLevelContributors = async () => {
    const response = await ProjectService.getProjectContributorsList(
      props.assignCoHandlerParam.projectId ?? ""
    );
    if (response.isSuccess) {
      dispatch(
        updateProjectLevelContributors({
          projectId: props.assignCoHandlerParam.projectId,
          projectHandlers: response.data.projectHandlers,
        })
      );
    }
  };

  const handleUpdateFlexTaskLevelContributors = async (
    isMilestoneListLoaded: boolean
  ) => {
    const response = await ProjectService.getProjectTasksByProject({
      projectId: props.assignCoHandlerParam.projectId,
      isMilestoneListLoaded: isMilestoneListLoaded,
    });

    if (response.isSuccess) {
      let records = response?.data?.e2EProjectLineFlexTasks?.map(
        (task: PmrE2EProjectTaskModel, index: number) => {
          task.workBreakdownStructure = index + 1;
          task.isChecked = false;
          return task;
        }
      );

      dispatch(
        upsertProjectFlexTasksContributors({
          e2eProjectId: props.assignCoHandlerParam.projectId,
          tasks: records,
        } as PmrE2EProjectTaskModelList)
      );
    }
  };

  const handleSave = async () => {
    setIsSaveLoading(true);
    setMilestoneHandler(taskHandler);
    const payload: AssignCoHandlerDetails = {
      projectId: props.assignCoHandlerParam.projectId,
      projectNumber: props.assignCoHandlerParam.projectNumber,
      e2EProjectLineFlexTaskId:
        props.assignCoHandlerParam?.e2EProjectLineFlexTaskId,
      e2EProjectLineMilestoneId:
        props?.assignCoHandlerParam.e2EProjectLineMilestoneId,

      taskHandlerDetails:
        originalTaskHandler?.normalizedEmail != taskHandler?.normalizedEmail
          ? taskHandler
          : null,
      e2EProjectLineId: props.assignCoHandlerParam.e2EProjectLineId,
      milestoneHandlerDetails: taskHandler,
      isAssignCoHandlerMailboxNotRequired,
    };

    const updateResult = await GMAWBUsersService.assignCoHandler(payload);
    props.onClose();

    if (updateResult.isSuccess) {
      showSuccess("CO Handler Assigned", updateResult.message);

      dispatch(
        updateFlexTaskCoHandler({
          projectId: props.assignCoHandlerParam.projectId,
          workBreakdownStructure:
            props.assignCoHandlerParam.workBreakDownStructure,
          taskHandler,
          e2EProjectLineMilestoneId:
            props.assignCoHandlerParam.e2EProjectLineMilestoneId,
          e2EProjectLineId: props.assignCoHandlerParam.e2EProjectLineId,
          isAssignCoHandlerMailboxNotRequired,
          e2EProjectLineFlexTaskId:
            props.assignCoHandlerParam.e2EProjectLineFlexTaskId,
          isMilestoneLevel: props.assignCoHandlerParam.isMilestonelevel,
        })
      );

      handleUpdateFlexTaskLevelContributors(
        props.assignCoHandlerParam.isMilestonelevel
      );
      handlerUpdateProjectLevelContributors();
    } else {
      showSuccess("CO Handler Assigned Error", updateResult.message);
    }

    setIsSaveLoading(false);
  };

  const header = (
    <div>
      <span>Assign CO Handler</span>
    </div>
  );

  const footer = (
    <div className={classes["footer"]}>
      <div>
        <Button
          className={`${classes["button-cancel"]}`}
          label="CANCEL"
          onClick={props.onClose}
          disabled={isModalLoading}
        />
      </div>
      <div className={`${classes["button-save-wrapper"]}`}>
        <Button
          className={`${classes["button-save"]}`}
          label="SAVE"
          onClick={handleSave}
          disabled={isModalLoading || !taskHandler?.displayName}
          loading={isSaveLoading}
        />
      </div>
    </div>
  );

  const onHide = () => {
    setTaskHandlerDropdownOptions(initDropdownValues);
    setTaskHandler(undefined);
    setIsModalLoading(false);
    setIsSaveLoading(false);
    props.onClose();
  };

  return (
    <Dialog
      draggable={false}
      resizable={false}
      className={classes["assign-collaborator-dialog"]}
      header={header}
      footer={footer}
      visible={props.show}
      style={dialogStyle}
      modal
      onHide={onHide}
      contentClassName={classes["content"]}
    >
      {isModalLoading && (
        <div className={`${classes["spinner__container"]}`}>
          <ProgressSpinner
            strokeWidth="5"
            animationDuration="5s"
            className={`${classes["spinner"]}`}
          />
          <div>Loading CO Handlers.</div>
        </div>
      )}
      {!isModalLoading && [
        <div className={classes["dropdown"]}>
          {props.assignCoHandlerParam?.showTimeStampError && (
            <>
              <label className={`${classes["template-error-label"]}`}>
                You are not authorized to complete this Task/Milestone.
              </label>
              <label className={`${classes["template-error-label-mb1"]}`}>
                Please assign the Task and Milestone to a CO Handler.
              </label>
            </>
          )}
          <span>
            <b>Task Handler / Milestone Handler</b>
          </span>
          <ProjectAssignCoHandlerDropdown
            value={taskHandler}
            dropdownOptions={taskHandlerDropdownOptions}
            setValue={setTaskHandler}
            setDropdownOptions={setTaskHandlerDropdownOptions}
            handlerType={HandlerType.TaskHandler}
            isGmaTcTask={false}
          />
          <div className={classes["assign-co-container"]}>
            <Checkbox
              key="assignCoHandler"
              checked={isAssignCoHandlerMailboxNotRequired}
              onChange={(event: CheckboxChangeParams) => {
                setIsAssignCoHandlerMailboxNotRequired(event.checked);
              }}
              className={classes["assign-co-container-checkbox"]}
            />
            <label
              htmlFor="assignCoHandler"
              className={`${classes["assign-co-label"]}`}
            >
              UL CO Mailbox Assignment Not Required
            </label>
          </div>
        </div>,
      ]}
    </Dialog>
  );
};

export default ProjectAssignCoHandlerDialog;
