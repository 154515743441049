import { Dialog } from "primereact/dialog";
import classes from "./CompleteGpiFormConfirmationDialog.module.scss";

export interface ICompleteGpiFormConfirmationDialogProps {
  visible: boolean;
  onHide: () => void;
  onConfirm: () => void;
}

export default function CompleteGpiFormConfirmationDialog({
  visible,
  onHide,
  onConfirm,
}: ICompleteGpiFormConfirmationDialogProps) {
  const header = () => {
    return (
      <>
        <h4 className={classes["modal-header"]}>
          Complete General Information Form
        </h4>
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <div className={`text-center ${classes["footer-content"]}`}>
          <button
            className={`ul-button -app-primary ${classes["btn-yes"]}`}
            onClick={() => onConfirm()}
          >
            YES
          </button>

          <button className={`ul-button`} onClick={() => onHide()}>
            NO
          </button>
        </div>
      </>
    );
  };

  return (
    <Dialog
      closable={true}
      draggable={false}
      resizable={false}
      header={header}
      footer={footer}
      onHide={() => onHide()}
      visible={visible}
      style={{ width: "630px" }}
      className={classes["container"]}
    >
      <p className={classes["body-content"]}>
        By clicking the "Finish" button, you confirm that all changes/updates in the 
        GPI Form are accurate and final. If any changes/edits need to be made after 
        finishing the General Information form, the project handler will send an updated link.
        <br /> <br /> Would you like to continue?
      </p>
    </Dialog>
  );
}
