import * as yup from "yup";
import customerInformationSchema from "../customer-information-schema";

const applicantInformationSchema = customerInformationSchema;
const manufacturerInforationSchema = customerInformationSchema;
const ulFileNumberSchema = yup.object({
  hasExistingULFileNumber: yup.boolean(),
  fileNumber: yup.string().nullable().when("hasExistingULFileNumber", {
    is: true,
    then: yup.string().nullable().required(),
  }),
  volumeNumber: yup.string().nullable().when("hasExistingULFileNumber", {
    is: true,
    then: yup.string().nullable().required(),
  }),
  sectionNumber: yup.string().nullable().when("hasExistingULFileNumber", {
    is: true,
    then: yup.string().nullable().required(),
  }),
  certificateNumber: yup
    .string()
    .nullable()
    .when(["isULCBCertificateNumber", "isOtherGMACertificate"], {
      is: (isULCBCertificateNumber, isOtherGMACertificate) =>
        isULCBCertificateNumber || isOtherGMACertificate,
      then: yup.string().nullable().required(),
    }),
  certificateScheme: yup.string().nullable().when("isOtherGMACertificate", {
    is: true,
    then: yup.string().nullable().required(),
  }),
});

const factorySiteInformationSchema = yup.object({
  factorySites: yup.array().required().of(customerInformationSchema),
});

export default yup.object({
  applicantInformation: applicantInformationSchema,
  manufacturerInformation: manufacturerInforationSchema,
  ulFileNumber: ulFileNumberSchema,
  factorySiteInformation: factorySiteInformationSchema,
});

export {
  applicantInformationSchema,
  manufacturerInforationSchema,
  ulFileNumberSchema,
  factorySiteInformationSchema,
  customerInformationSchema,
};
