export const AddTimestampTooltip =
  "Add timestamp functionality is not allowed if: " +
  "\n - the selected order line Task or Milestone has already been Completed. " +
  "\n - the selected order line Task or Milestone has been Cancelled. " +
  "\n - the predecessor Task has not yet been Completed.";

export const RemoveTimestampTooltip =
  "Cannot remove timestamp if: " +
  "\n - Selected Milestone belongs to a Completed RR FLEX Task," +
  "\n - Selected Milestone already have Completed successor Task/Milestones, and" +
  "\n - Order Line has been Completed (All milestones/tasks until Project Completion have been Completed)";

export const LogWorkHoursTooltip =
  "You must place the Task or Milestone back to In Progress before you can record additional hours.";

export const OpportunitiesDocumentsDisabledTooltip =
  "Opportunities number is not available for this Order Line.";
export const ECMProjectNodeIDDisabledTooltip =
  "ECM Project Node ID is not available for this Order Line.";
export const ECMProjectNodeIDTooltip =
  "Click to launch ECM and go to the Project folder.";
export const OpportunitiesDocumentsTooltip =
  "Click to launch ECM and go to the ECM Opportunities Documents page.";
export const checkingAvailabilityTooltip = "Checking availability...";
