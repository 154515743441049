import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import ProjectService from "../services/ProjectService";
import { validateSpecialCharacters } from "../utils/helpers/input.helpers";
import { useSelector } from "react-redux";
import { selectOriginalProjectName } from "../features/projectWorkQueue/projectWorkQueueSlice";

export function useE2EProjectNameValidator(
  projectName: string,
  isEditGMAWBProject: boolean = false
) {
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const originalProjectName = useSelector(selectOriginalProjectName);

  useEffect(() => {
    setIsDuplicate(false);
    const isNameValidResult = validateSpecialCharacters(
      projectName,
      "Project Name"
    );

    if (!projectName || !isNameValidResult.valid) return;

    if (
      isEditGMAWBProject &&
      projectName?.trim()?.toLocaleLowerCase() ==
        originalProjectName?.trim()?.toLocaleLowerCase()
    )
      return;

    setIsValidating(true);
    debouncedE2EProjectNameDuplicateValidation(projectName);
  }, [projectName]);

  const debouncedE2EProjectNameDuplicateValidation = useCallback(
    debounce(async (projectName: string) => {
      setIsValidating(false);

      const isValid = await ProjectService.validateE2EProjectName({
        projectName,
      });

      setIsDuplicate(!!isValid.message);
      setIsValidating(false);
    }, 600),
    []
  );

  const getErrorMessage = () => {
    if (!projectName) return "Please enter a Project Name";

    const isNameValidResult = validateSpecialCharacters(
      projectName,
      "Project Name"
    );

    if (!isNameValidResult.valid) return isNameValidResult.errorMessage;

    if (
      isEditGMAWBProject &&
      projectName?.trim()?.toLocaleLowerCase() ==
        originalProjectName?.trim()?.toLocaleLowerCase()
    )
      return;

    if (isDuplicate)
      return "GMAWB Project name already exists. Please enter a new project name.";
  };

  const errorMessage = getErrorMessage();
  const isEmpty = !projectName;
  const isValid = !errorMessage;

  return {
    isEmpty,
    isValidating,
    errorMessage,
    isValid,
  };
}
