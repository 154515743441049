import { Dialog } from "primereact/dialog";
import classes from "./MismatchConfirmationDialog.module.scss";

export interface IMismatchConfimationDialogProps {
  visible: boolean;
  onConfirm: () => void;
  onHide: () => void;
  isReplaceManufacturer: boolean;
  isReplaceFactories: boolean;
  isReplaceModels: boolean;
}

export default function MismatchConfirmationDialog({
  visible,
  onConfirm,
  onHide,
  isReplaceManufacturer,
  isReplaceFactories,
  isReplaceModels,
}: IMismatchConfimationDialogProps) {
  const header = () => {
    return (
      <>
        <h4 className={classes["modal-header"]}>
          Mismatch Confirmation Message
        </h4>
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <div className={`text-center ${classes["footer-content"]}`}>
          <button
            className={`ul-button -app-primary`}
            onClick={() => onConfirm()}
          >
            Yes
          </button>

          <button
            className={`ul-button -app-secondary`}
            onClick={() => onHide()}
          >
            No
          </button>
        </div>
      </>
    );
  };

  const confirmationSections = () => {
    let message = "";
    if (isReplaceManufacturer) {
      message += "manufacturer details";

      if (isReplaceFactories && isReplaceModels) {
        message += ", ";
      }

      if (
        (isReplaceFactories && !isReplaceModels) ||
        (!isReplaceFactories && isReplaceModels)
      ) {
        message += " and ";
      }
    }

    if (isReplaceFactories) {
      message += "factory details";

      if (isReplaceModels) {
        message += " and ";
      }
    }

    if (isReplaceModels) {
      message += "model(s)";
    }

    return message;
  };

  return (
    <Dialog
      closable={true}
      draggable={false}
      resizable={false}
      header={header}
      footer={footer}
      onHide={() => onHide()}
      visible={visible}
      style={{ width: "630px" }}
      className={classes["container"]}
    >
      <p className={classes["body-content"]}>
        The following section(s) have been updated to align with the existing
        certification workbench records.
        <br />
        <ul>
          {isReplaceManufacturer && <li>Manufacturer details</li>}
          {isReplaceFactories && <li>Factory details</li>}
          {isReplaceModels && <li>Model(s)</li>}
        </ul>
        <br />
        Do you want to proceed with updating the {confirmationSections()} to
        align with the Workbench record?
      </p>
    </Dialog>
  );
}
