import { uniq } from "lodash";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ConfirmationModalV2 from "../../../../../shared/confirmation-modal-v2/ConfirmationModalV2";
import { E2EProjectLineECDAdjustmentModel } from "../../../../../shared/models/service-models/E2EProjectLineECDAdjustmentModel";
import classes from "./ECDAdjustmentsConfirmationDialog.module.scss";
import { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";

type Props = {
  visible: boolean;
  e2eProjectLineECDAdjustments: E2EProjectLineECDAdjustmentModel[];
  onConfirm: (adjustECDProjectLineIds: string[]) => void;
  onCancel: () => void;
  e2eProjectLineIds?: string[];
  isKeepButtonVisible?: boolean;
  isCancelButtonVisible?: boolean;
  forceProjectNameColumnDisplay?: boolean;
  customConfirmationMessage?: string;
  tableHeader?: string | ReactNode;
  isLoading?: boolean;
  aboveECDAdjustmentsTableMessage?: ReactNode;
  belowECDAdjustmentsTableMessage?: ReactNode;
  customModalHeader?: string;
  isECDAdjustmentsLoading?: boolean;
  isKeepECDDisabled?: boolean;
  keepButtonTooltip?: string;
  isAdjustECDDisabled?: boolean;
};

export default function ECDAdjustmentsConfirmationDialog({
  visible,
  e2eProjectLineECDAdjustments,
  onConfirm,
  onCancel,
  e2eProjectLineIds,
  isKeepButtonVisible = true,
  isCancelButtonVisible = false,
  tableHeader = "",
  isLoading = false,
  customModalHeader,
  isECDAdjustmentsLoading,
  isKeepECDDisabled,
  keepButtonTooltip = "",
  isAdjustECDDisabled = false,
}: Props) {
  const isMultipleOrderLines =
    !!e2eProjectLineIds && uniq(e2eProjectLineIds).length > 1;

  const [selectedE2EProjectLines, setSelectedE2EProjectLines] = useState<
    E2EProjectLineECDAdjustmentModel[]
  >([]);

  useEffect(() => {
    if (!visible) setSelectedE2EProjectLines([]);
  }, [visible]);

  useEffect(() => {
    if (isMultipleOrderLines) {
      setSelectedE2EProjectLines(e2eProjectLineECDAdjustments);
    }
  }, [e2eProjectLineECDAdjustments]);

  const dateFormatter = (dateInput: Date): string => {
    if (!dateInput) return "";

    return moment(dateInput).format("DD MMM yyyy");
  };

  const table = (
    <DataTable
      loading={isECDAdjustmentsLoading}
      onSelectionChange={(e) => {
        setSelectedE2EProjectLines(e.value);
      }}
      selection={selectedE2EProjectLines}
      value={e2eProjectLineECDAdjustments}
      className={classes["adjustments-table"]}
      size="small"
      showGridlines={true}
    >
      {isMultipleOrderLines && (
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
      )}
      <Column header="GMAWB Project Name" field="e2EProjectName" />
      <Column header="Order Line Number" field="orderlineDescription" />
      <Column
        header="Current ECD"
        body={(params) => {
          return params.currentECD == "Invalid Date"
            ? ""
            : dateFormatter(params.currentECD);
        }}
      />
      <Column
        header="Adjusted ECD"
        body={(params) => {
          return dateFormatter(params.adjustedECD);
        }}
      />
    </DataTable>
  );

  const modalHeader = customModalHeader
    ? customModalHeader
    : isMultipleOrderLines
    ? "Estimated Completion Date (ECD)"
    : "Adjust Expected Completion Date (ECD)";

  const selectedOrderlinesCountInfo =
    selectedE2EProjectLines.length === e2eProjectLineECDAdjustments.length
      ? "All"
      : selectedE2EProjectLines.length;

  const renderTableHeader = () => {
    if (tableHeader) return tableHeader;

    if (isMultipleOrderLines) {
      return (
        <>
          The completion date for the selected order lines deviates from the
          allotted due date. <br /> <br /> Please select orderline(s) whether to
          keep or adjust the ECD.
          <br /> <br />
          <div className={classes["selected-lines-info"]}>
            ({selectedOrderlinesCountInfo}) Order lines Selected
          </div>
        </>
      );
    }

    return (
      <>
        The Completion Date for this Task/Milestone deviates from its originally
        scheduled Due Date, which impacts the Order Line ECD. <br /> <br />
        Do you want wish to keep the current Order Line ECD or adjust it to the
        new date?
      </>
    );
  };

  const renderTableFooter = () => {
    if (isMultipleOrderLines) {
      return (
        <div>
          <div className={classes["keep-note"]}>
            <label>*KEEP SELECTED - </label> ECD of the selected order line(s)
            will be kept; unselected order line(s) will be adjusted accordingly.
          </div>

          <div className={classes["adjust-note"]}>
            <label>*ADJUST SELECTED - </label> ECD of the selected order line(s)
            will be adjusted; unselected order line(s) will be kept accordingly.
          </div>
        </div>
      );
    }

    return (
      <span className={classes["action-note"]}>
        * Task and Milestone dates will be adjusted accordingly
      </span>
    );
  };

  const bodyTemplate = (
    <>
      {renderTableHeader()}
      {table}
      {renderTableFooter()}
    </>
  );

  const handleProceed = (isAdjust: boolean) => {
    if (!isMultipleOrderLines) {
      return onConfirm(
        isAdjust ? [e2eProjectLineECDAdjustments[0].e2EProjectLineId] : []
      );
    }

    const selectedLineIds = selectedE2EProjectLines.map(
      (line) => line.e2EProjectLineId
    );

    const projectLineIdsToAdjustECD = e2eProjectLineECDAdjustments
      .filter((line) =>
        isAdjust
          ? selectedLineIds.includes(line.e2EProjectLineId)
          : !selectedLineIds.includes(line.e2EProjectLineId)
      )
      .map((line) => line.e2EProjectLineId);

    onConfirm(projectLineIdsToAdjustECD);
  };

  const isECDActionsDisabled = () => {
    if (isECDAdjustmentsLoading) return true;
    if (!isMultipleOrderLines) return false;

    return !selectedE2EProjectLines.length;
  };

  const footerTemplate = (
    <>
      {isCancelButtonVisible && (
        <Button
          disabled={isLoading}
          onClick={() => onCancel()}
          className={classes["btn-cancel"]}
        >
          CANCEL
        </Button>
      )}
      {isKeepButtonVisible && (
        <span title={keepButtonTooltip}>
          <Button
            loading={isLoading}
            disabled={isECDActionsDisabled() || isKeepECDDisabled}
            onClick={() => handleProceed(false)}
            className={classes["btn-keep"]}
          >
            {isMultipleOrderLines ? "KEEP SELECTED" : "KEEP"}
          </Button>
        </span>
      )}
      <Button
        loading={isLoading}
        disabled={isECDActionsDisabled() || isAdjustECDDisabled}
        onClick={() => handleProceed(true)}
        className={classes["btn-adjust"]}
      >
        {isMultipleOrderLines ? "ADJUST SELECTED" : "ADJUST"}
      </Button>
    </>
  );

  return (
    <ConfirmationModalV2
      closable={!isLoading}
      bodyClassName={classes["modal-body"]}
      style={{ width: "55vw" }}
      visible={visible}
      buttonYesDisplay="KEEP"
      buttonNoDisplay="ADJUST"
      onClose={onCancel}
      headerDisplay={modalHeader}
      confirmationMessageHTML={bodyTemplate}
      customFooter={footerTemplate}
    ></ConfirmationModalV2>
  );
}
