
export type CustomOneULPaginatorProps = {
    pageSizes: Array<number>;
    skip: number;
    take: number;
    firstIndex: number;
    lastIndex: number;
    totalRecords: number;
    totalPages: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    pageSizeOnChange: Function;
    manualPageOnChange: Function;
    pageOnChange: Function;
    disableJumpToInput: boolean;
    legendTemplate?:any;
}
const CustomOneULPaginator = (
    props: CustomOneULPaginatorProps
) => {
    return(
        <div className={`ul-pagination`}>
          <div className="ul-pagination__itemsPerPage">
            <label>Items per page</label>
            <select
              value={props.take}
              onChange={(e) => props.pageSizeOnChange(e)}
              className="ul-select ul-pagination__select -records"
              id="itemsPerPage"
            >
              {props.pageSizes.map((data) => {
                return (
                  <option key={data} value={data}>
                    {data}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={`ul-pagination__stats`}>
            {props.firstIndex} - {props.lastIndex} of {props.totalRecords}
          </div>
          {props.legendTemplate}
          <div className="ul-pagination__pager">
            <button
              className="ul-button -app-secondary -small"
              disabled={!props.hasPreviousPage}
              onClick={() => props.pageOnChange(-(props.skip! - 1))}
            >
              <i className="material-icons ul-button__icon">first_page</i>
            </button>
            <button
              className="ul-button -app-secondary -small"
              disabled={!props.hasPreviousPage}
              onClick={() => props.pageOnChange(-1)}
            >
              <i className="material-icons">navigate_before</i>
            </button>
            <input
              type="number"
              aria-label="page-number"
              className="ul-textbox ul-pagination__jumpTo"
              onChange={(e) => props.manualPageOnChange(e)}
              value={props.totalRecords > 0 ? props.skip : 0}
              min={1}
              max={props.totalPages}
              pattern="[0-9]*"
              disabled={props.totalPages === 0 || props.disableJumpToInput}
            />
            <span className="ul-pagination__pagerTotal">
              of {props.totalPages} page(s)
            </span>
            <button
              className="ul-button -app-secondary -small"
              disabled={!props.hasNextPage}
              onClick={() => props.pageOnChange(1)}
            >
              <i className="material-icons ul-button__icon">navigate_next</i>
            </button>
            <button
              className="ul-button -app-secondary -small"
              disabled={!props.hasNextPage}
              onClick={() => props.pageOnChange(props.totalPages - props.skip!)}
            >
              <i className="material-icons">last_page</i>
            </button>
          </div>
        </div>
      );
}

export default CustomOneULPaginator
