import { ColumnAlignFrozenType, ColumnAlignType } from "primereact/column";

export interface GridColumnModel {
  id: string;
  value: string;
  selected: boolean;
  orderBy: string;
  isShown: boolean;
  isFrozen: boolean;
  width: string;
  widthNum: number;
  orderNo: number;
  align?: ColumnAlignType;
  isColumnNotReordable?: boolean;
  isFlexWidth?: boolean;
  colspan?: number;
  minWidth?: number;
  orderNoShowHide?: number;
  canHide?: boolean;
  frozenPermanent?: boolean;
  alignFrozen?: ColumnAlignFrozenType;
  originalWidth?: number;
}
export const projectColumnCheckbox = "checkbox";
export const projectColumnExpand = "expand";
export const projectColumnStatus = "status";
export const projectColumnCollaborator = "e2EProjectHandlers";
export const projectColumnOpenNewTab = "opennewtab";
export const projectColumnCreatedDateUtc = "createdDateUtc";
export const actionsColumnTab = "actionsColumnTab";
export const projectListcolumns: GridColumnModel[] = [
  {
    id: projectColumnCheckbox,
    value: "",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "50px",
    widthNum: 50,
    orderNo: 1,
    isColumnNotReordable: true,
    isFlexWidth: false,
    align: "center",
    orderNoShowHide: 0,
    canHide: false,
    frozenPermanent: true,
  },
  {
    id: projectColumnExpand,
    value: "",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "70px",
    widthNum: 70,
    orderNo: 2,
    isColumnNotReordable: true,
    isFlexWidth: false,
    align: "center",
    orderNoShowHide: 0,
    canHide: false,
    frozenPermanent: true,
  },
  {
    id: "name",
    value: "GMAWB Project",
    selected: false,
    orderBy: "name",
    isShown: true,
    isFrozen: true,
    width: "250px",
    widthNum: 250,
    orderNo: 3,
    isFlexWidth: true,
    isColumnNotReordable: true,
    orderNoShowHide: 1,
    canHide: false,
    frozenPermanent: true,
  },
  {
    id: "createdByDisplayName",
    value: "GMAWB Project Creator",
    selected: false,
    orderBy: "createdByDisplayName",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 200,
    orderNo: 4,
    isFlexWidth: true,
    orderNoShowHide: 2,
    canHide: true,
  },
  {
    id: "flexSoldTo",
    value: "FLEX Sold To (Customer Company Name)",
    selected: false,
    orderBy: "flexSoldTo",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 200,
    orderNo: 5,
    isFlexWidth: true,
    orderNoShowHide: 3,
    canHide: true,
  },
  {
    id: "partySiteNumber",
    value: "FLEX Sold To (Party Site Number)",
    selected: false,
    orderBy: "partySiteNumber",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 200,
    orderNo: 6,
    isFlexWidth: true,
    orderNoShowHide: 4,
    canHide: true,
  },
  {
    id: projectColumnStatus,
    value: "GMAWB Project Status",
    selected: false,
    orderBy: "status",
    isShown: true,
    isFrozen: false,
    width: "150px",
    widthNum: 150,
    orderNo: 7,
    isFlexWidth: true,
    orderNoShowHide: 5,
    canHide: true,
  },
  {
    id: projectColumnCreatedDateUtc,
    value: "GMAWB Project Created Date",
    selected: false,
    orderBy: projectColumnCreatedDateUtc,
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 200,
    orderNo: 8,
    isFlexWidth: true,
    orderNoShowHide: 6,
    canHide: true,
  },
  {
    id: projectColumnCollaborator,
    value: "Contributors",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "220px",
    widthNum: 220,
    orderNo: 9,
    isFlexWidth: false,
    orderNoShowHide: 7,
    canHide: true,
  },
  {
    id: actionsColumnTab,
    value: "",
    selected: false,
    orderBy: "",
    isShown: true,
    width: "75px",
    widthNum: 75,
    orderNo: 10,
    isColumnNotReordable: true,
    isFlexWidth: false,
    orderNoShowHide: 0,
    canHide: false,
    isFrozen: true,
    alignFrozen: "right",
  },
];
