import {
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { confirmDialog } from "primereact/confirmdialog";
import { InputNumber } from "primereact/inputnumber";
import { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsReadOnly } from "../../../../features/generalProductInformation/generalProductInformationSlice";
import classes from "./CollapsibleForms.module.scss";

const CollapsibleForms = forwardRef(
  (
    {
      showNumberOfItemsInput,
      itemsCount,
      onSetItemsCount,
      onAddItems,
      onRemoveItems,
      items,
      confirmDeleteMultipleMessageTemplate,
      selectable,
      deletable,
      selectedAll,
      onChangeSelectedAll,
      numberOfItemsInputLabel,
      selectAllLabel,
      formTitleTemplate,
      customOptionsTemplate,
      onDeleteItem,
      confirmDeleteSingleMessageTemplate,
      formTemplate,
      onSelectItem,
      confirmDeleteHeaderLabel,
      showCollapseAll,
      shownItemIndices,
      // If set, shownItemIndices becomes controlled.
      onSetShownItemIndices,
      // If set to true, items could be deleted until there is no items left.
      // Otherwise retain 1 item.
      canDeleteAll,
      showDeleteConfirmationMessage,
      maxNumberOfItemsAllowed,
    },
    ref
  ) => {
    const [showAllItems, setShowAllItems] = useState(true);
    const [_shownItemIndices, _setShowItemIndices] = useState([0]);
    const [numberOfItemsErrorMessage, setNumberOfItemsErrorMessage] =
      useState("");

    const isReadOnly = useSelector(selectIsReadOnly);

    const handleChangeItemsCount = (e) => {
      onSetItemsCount(e.value);
    };

    const handleExpand = (index) => {
      if (!getShownItemIndices().includes(index))
        handleSetShownItemIndices([...getShownItemIndices(), index]);
    };

    if (ref) {
      ref.current = {
        expand: handleExpand,
      };
    }

    const handleBlurItemsCount = (e) => {
      const count = parseInt(e.target.value);

      if (isNaN(count) || count < 1) {
        if (!e.value) return;
      }

      if (count > maxNumberOfItemsAllowed) {
        setNumberOfItemsErrorMessage(
          "Number entered exceeded maximum allowable value. Please enter value 1 up to " +
            maxNumberOfItemsAllowed
        );
        return;
      }

      setNumberOfItemsErrorMessage("");

      if (count > items.length) onAddItems(count - items.length);
      if (count < items.length) handleDeleteItemsByCount(count);
    };

    const handleDeleteItemsByCount = (count) => {
      const deleteCount = items.length - count;

      confirmDialog({
        message: confirmDeleteMultipleMessageTemplate(deleteCount),
        header: confirmDeleteHeaderLabel,
        className: classes["delete-confirmation-dialog"],
        accept: () => onRemoveItems(deleteCount),
        reject: () => onSetItemsCount(items.length),
        onHide: (result) => {
          if (!result) onSetItemsCount(items.length);
        },
      });
    };

    const handleSetShownItemIndices = (itemIndices) =>
      onSetShownItemIndices
        ? onSetShownItemIndices(itemIndices)
        : _setShowItemIndices(itemIndices);

    const getShownItemIndices = () =>
      (onSetShownItemIndices ? shownItemIndices : _shownItemIndices) || [];

    const handleClickItemsCollapseIcon = () => {
      const expandAll = showAllItems == false;

      if (expandAll) {
        const itemsCount = items.length;
        const itemsIndices = [...Array(itemsCount).keys()];

        handleSetShownItemIndices(itemsIndices);
      } else {
        handleSetShownItemIndices([]);
      }

      setShowAllItems(!showAllItems);
    };

    const handleClickItemCollapseIcon = (itemIndex) => {
      const newShownItemIndices = [...getShownItemIndices()];

      const index = newShownItemIndices.indexOf(itemIndex);

      if (index === -1) {
        newShownItemIndices.push(itemIndex);
      } else {
        newShownItemIndices.splice(index, 1);
      }

      handleSetShownItemIndices(newShownItemIndices);
    };

    const handleDeleteItem = (index) => {
      if (!canDeleteAll && items.length == 1) return;

      if (showDeleteConfirmationMessage === false) {
        onDeleteItem(index, items[index]);
        return;
      }

      confirmDialog({
        message: confirmDeleteSingleMessageTemplate(),
        header: confirmDeleteHeaderLabel,
        className: classes["delete-confirmation-dialog"],
        accept: () => onDeleteItem(index, items[index]),
      });
    };

    const numberOfItemsInput = (
      <>
        <div className={classes["number-of-items"]}>
          <h4 className={classes["label"]}>{numberOfItemsInputLabel}</h4>
          <InputNumber
            useGrouping={false}
            min={1}
            onBlur={handleBlurItemsCount}
            onChange={handleChangeItemsCount}
            value={itemsCount}
            inputClassName={`${classes["input"]} ul-textbox ${
              numberOfItemsErrorMessage ? "p-invalid" : ""
            }`}
            disabled={isReadOnly}
          />
        </div>

        {numberOfItemsErrorMessage && (
          <span style={{ color: "red" }}>{numberOfItemsErrorMessage}</span>
        )}
      </>
    );

    return (
      <>
        {showNumberOfItemsInput && numberOfItemsInput}

        {items?.length > 0 && (
          <>
            {selectable && (
              <div className="question-w-checkbox-container">
                <Checkbox
                  inputId="checkAll"
                  checked={selectedAll}
                  onChange={({ checked }) => onChangeSelectedAll(checked)}
                  disabled={isReadOnly}
                />
                <label htmlFor="checkAll">{selectAllLabel}</label>
              </div>
            )}

            {showCollapseAll !== false && (
              <div className={classes["btn-collapse-all-container"]}>
                <Button
                  className={`ul-button -app-secondary ${classes["btn-collapse-all"]}`}
                  onClick={handleClickItemsCollapseIcon}
                  tooltip={showAllItems ? "Collapse all" : "Expand all"}
                  disabled={isReadOnly}
                >
                  <FontAwesomeIcon
                    icon={showAllItems ? faAngleUp : faAngleDown}
                    size="1x"
                  />
                </Button>
              </div>
            )}

            <div className={classes["items-container"]}>
              {items.map((item, index) => {
                const formContent =
                  formTemplate &&
                  formTemplate(item, index, () => handleExpand(index));
                const isActive = getShownItemIndices().includes(index);

                return (
                  <div
                    key={index}
                    className={
                      classes["item-container"] +
                      " " +
                      (!formContent ? classes["no-content"] : "")
                    }
                  >
                    <div className={classes["header"]}>
                      <div
                        className={
                          selectable ? classes["left-15"] : classes["left"]
                        }
                      >
                        {selectable && (
                          <Checkbox
                            className={classes["info-checkbox"]}
                            checked={item.isSelected}
                            onChange={({ checked }) => onSelectItem(checked)}
                            disabled={isReadOnly}
                          />
                        )}

                        <FontAwesomeIcon
                          className={
                            classes["collapse-icon"] +
                            " " +
                            (isActive
                              ? classes["fa-angle-down"]
                              : classes["fa-angle-right"])
                          }
                          icon={isActive ? faAngleDown : faAngleRight}
                          size="1x"
                          onClick={() => handleClickItemCollapseIcon(index)}
                        />
                        <h4 className={classes["title"]}>
                          {formTitleTemplate(item, index, isActive)}
                        </h4>
                      </div>

                      <div className={classes["right"]}>
                        <div className={classes["custom-options-container"]}>
                          {customOptionsTemplate &&
                            customOptionsTemplate(item, index)}
                        </div>

                        {isReadOnly
                          ? isReadOnly
                          : deletable && (
                              <div className={classes["delete-item"]}>
                                <FontAwesomeIcon
                                  onClick={() => handleDeleteItem(index)}
                                  className={classes["trash-icon"]}
                                  icon={faTrashAlt}
                                  size="1x"
                                />
                              </div>
                            )}
                      </div>
                    </div>

                    <div
                      className={`${classes["content"]} ${
                        formContent && isActive ? "" : classes["hidden"]
                      }`}
                    >
                      {formContent}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </>
    );
  }
);

export default CollapsibleForms;
