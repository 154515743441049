import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import DocumentKnowledgeService from "../../../services/DocumentKnowledgeService";
import { appInsights } from "../../../services/appInsights";
import { DocumentKnowledgeRequestModel } from "../../../shared/service-models/DocumentKnowledgeRequestModel.model";
import { SelectListResultModel } from "../../../shared/service-models/SelectListResult.mode";
import classes from "./DocKnowledgeMultiSelects.module.scss";

interface DocKnowledgeMultiSelectProps {
  handleRequestModelChange: Function;
}

const DocKnowledgeMultiSelects = (props: DocKnowledgeMultiSelectProps) => {
  const logPrefix = `DocKnowledgeMultiSelects - ${uuidv4()}`;

  const { handleRequestModelChange } = props;
  const [certSchemeList, setCerSchemeList] = useState([]);
  const [selectedSchemes, setSelectedSchemes] = useState<
    SelectListResultModel[]
  >([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [deliveryPathList, setDeliveryPathList] = useState<
    SelectListResultModel[]
  >([]);
  const [applicationTypeList, setApplicationTypeList] = useState<
    SelectListResultModel[]
  >([]);
  const [technologyList, setTechnologyList] = useState<SelectListResultModel[]>(
    []
  );
  const [selectedDeliveryPaths, setSelectedDeliveryPaths] = useState<
    SelectListResultModel[]
  >([]);
  const [selectedApplicationTypes, setSelectedApplicationTypes] = useState<
    SelectListResultModel[]
  >([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState<
    SelectListResultModel[]
  >([]);

  const [requestModel, setRequestModel] =
    useState<DocumentKnowledgeRequestModel>({
      schemeSelected: "",
      devPathSelected: "",
      applicationTypeSelected: "",
      technologySelected: "",
    });

  useEffect(() => {
    async function initializeLists() {
      try {
        const result = await DocumentKnowledgeService.getDropdowns({});
        if (result.length > 0) {
          const schemes = result[0].CertScheme.map(
            ({ value, ...rest }: any) => {
              return {
                ...rest,
                name: value,
              };
            }
          );
          setCerSchemeList(schemes);
        }
      } catch (error) {
        appInsights?.trackTrace({
          message: `${logPrefix} - initializeLists - ${JSON.stringify(error)}`,
          severityLevel: SeverityLevel.Error,
        });
      }
    }
    initializeLists();
  }, []);

  useEffect(() => {
    handleRequestModelChange(requestModel);
  }, [requestModel]);

  const mapDeliveryPaths = (deliveryPaths: any[]) => {
    return deliveryPaths.map(({ value, ...rest }: any) => {
      return { ...rest, name: value };
    });
  };

  const mapApplicationTypes = (applicationTypes: any[]) => {
    return applicationTypes.map(({ value, ...rest }: any) => {
      return { ...rest, name: value };
    });
  };

  const mapTechnologies = (technologies: any[]) => {
    return technologies.map(({ value, ...rest }: any) => {
      return { ...rest, name: value };
    });
  };

  const handleCertSchemeChange = async ({
    value,
  }: {
    value: SelectListResultModel[];
  }) => {
    setSelectedSchemes(value);

    const schemeSelected = value.map((scheme) => ({
      ...scheme,
      selected: true,
    }));

    setRequestModel((prevState: DocumentKnowledgeRequestModel) => ({
      ...prevState,
      schemeSelected: JSON.stringify(schemeSelected),
    }));

    if (schemeSelected.length === 0) {
      setSelectedDeliveryPaths([]);
      setSelectedApplicationTypes([]);
      setSelectedTechnologies([]);
      setIsDisabled(true);

      return;
    }

    try {
      const result = await DocumentKnowledgeService.getDropdowns({
        schemeSelected: JSON.stringify(schemeSelected),
      });

      if (result.length === 0) return;

      const { DeliveryPath, ApplicationType, Technology } = result[0];

      const deliveryPaths = mapDeliveryPaths(DeliveryPath);
      const applicationTypes = mapApplicationTypes(ApplicationType);
      const technologies = mapTechnologies(Technology);

      setDeliveryPathList(deliveryPaths);
      setApplicationTypeList(applicationTypes);
      setTechnologyList(technologies);

      setIsDisabled(false);
    } catch (error) {
      appInsights?.trackTrace({
        message: `${logPrefix} - handleCertSchemeChange - ${JSON.stringify(
          error
        )}`,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

  const handleDeliveryPathChange = ({
    value,
  }: {
    value: SelectListResultModel[];
  }) => {
    setSelectedDeliveryPaths(value);
    setRequestModel((prevState: DocumentKnowledgeRequestModel) => ({
      ...prevState,
      devPathSelected: JSON.stringify(value),
    }));
  };

  const handleApplicationTypeChange = ({
    value,
  }: {
    value: SelectListResultModel[];
  }) => {
    setSelectedApplicationTypes(value);
    setRequestModel((prevState: DocumentKnowledgeRequestModel) => ({
      ...prevState,
      applicationTypeSelected: JSON.stringify(value),
    }));
  };

  const handleTechnologyChange = ({
    value,
  }: {
    value: SelectListResultModel[];
  }) => {
    setSelectedTechnologies(value);
    setRequestModel((prevState: DocumentKnowledgeRequestModel) => ({
      ...prevState,
      technologySelected: JSON.stringify(value),
    }));
  };

  return (
    <div className={classes["select-container"]}>
      <div className={classes["ul-form__control"]}>
        <label className={classes["select-label"]}>Certification Scheme</label>
        <MultiSelect
          value={selectedSchemes}
          onChange={handleCertSchemeChange}
          options={certSchemeList}
          placeholder="-- Select --"
          filter
          optionLabel="name"
        />
      </div>
      <div className={classes["ul-form__control"]}>
        <label className={classes["select-label"]}>Delivery Path</label>
        <MultiSelect
          value={selectedDeliveryPaths}
          options={deliveryPathList}
          onChange={handleDeliveryPathChange}
          disabled={isDisabled}
          placeholder="-- Select --"
          filter
          optionLabel="name"
        />
      </div>
      <div className={classes["ul-form__control"]}>
        <label className={classes["select-label"]}>Application Type</label>
        <MultiSelect
          value={selectedApplicationTypes}
          options={applicationTypeList}
          onChange={handleApplicationTypeChange}
          disabled={isDisabled}
          placeholder="-- Select --"
          filter
          optionLabel="name"
        />
      </div>
      <div className={classes["ul-form__control"]}>
        <label className={classes["select-label"]}>Technology</label>
        <MultiSelect
          value={selectedTechnologies}
          options={technologyList}
          onChange={handleTechnologyChange}
          disabled={isDisabled}
          placeholder="-- Select --"
          filter
          optionLabel="name"
        />
      </div>
    </div>
  );
};

export default DocKnowledgeMultiSelects;
