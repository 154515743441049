import classes from "./ProjectFullViewWithConfig.module.scss";
import ProjectListDetailTab from "../project-list-detail-tab/ProjectListDetailTab";
import PmrE2EProjectModel from "../shared/interfaces/pmr-e2e-project-model";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faClose, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import ProjectStatusTemplate from "../shared/components/project-status-template/ProjectStatusTemplate";
import ProjectCollaboratorAvatar from "../shared/components/project-collaborator-avatar/ProjectCollaboratorAvatar";
import * as ProjectListPmr from "../project-list-pmr/ProjectListPmr";
import * as axios from "axios";
import {
  projectColumnStatus,
  projectColumnCollaborator,
} from "../data/project-grid-columns";
import ProjectListHeaderActions from "../shared/components/project-list-header-actions/ProjectListHeaderActions";
import {
  updateIsRefinerLoading,
  updateShowFlexTaskFullProjectView,
  updateShowHideFullProjectView,
} from "../../../features/projectManagementPmr/projectManagementPmrSlice";
import maintenanceIcon from "../../../assets/images/maintenance-icon.png";
import { Tooltip } from "primereact/tooltip";
import clsx from "clsx";
import useProjectManagementPmrSignalr from "../../../hooks/useProjectManagementPmr";
import { updateProjectManagementViewProjects, updateRefinerLineResult } from "../../../features/projectManagement/projectManagementSlice";
import ProjectService from "../../../services/ProjectService";
import { PmrE2EProjectFullViewModel } from "../shared/interfaces/pmr-e2e-project-full-view-model";
import { Skeleton } from "primereact/skeleton";
import { transformOptionLabelValueToValue } from "../../../utils/helpers/object.helpers";
import OrderLineRefinersService from "../../../services/OrderLineRefinersService";
import { Message } from "primereact/message";

export const projectFullViewMessageKey = "project-full-view-message";

const ProjectFullViewWithConfig = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);
  const segments = pathname.split("/");
  const projectName = segments[segments.length - 1];
  const urlSearch = new URLSearchParams(location.search);
  const [project, setProject] = useState<PmrE2EProjectModel>();
  const getProjectListCancelSource = useRef<any>(null);
  const applyOrderLineRefinersCancelSource = useRef<any>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isProjectFullViewKeyFound, setIsProjectFullViewKeyFound] = useState<boolean>(true);
  const projectManagementPmrSignalrHook = useProjectManagementPmrSignalr();
  const [projectFullViewData, setProjectFullViewData] = useState<PmrE2EProjectFullViewModel>();
  const [isBannerShown, setIsBannerShown] = useState<boolean>(true);
  
  useEffect(() => {
    const getFullViewProjectKey = urlSearch.get("fullViewProjectKey");
    if(getFullViewProjectKey) {
      ProjectService.getProjectFullViewCacheKey(getFullViewProjectKey)
        .then((response) => {
          if(response.isSuccess){
            setProjectFullViewData(JSON.parse(response.data));
            setIsProjectFullViewKeyFound(true);
          }
          else{
            setIsProjectFullViewKeyFound(false);
            getProject(projectName, null);
          }
        })
    }
    else{
      getProject(projectName, null);
    }
  },[])

  useEffect(() => {
    if(!projectFullViewData) return;
    dispatch(updateShowHideFullProjectView(projectFullViewData.showHideColumn));
    dispatch(updateShowFlexTaskFullProjectView(projectFullViewData.showFlexTasks));
    getProject(projectFullViewData.project.name, projectFullViewData);
  },[projectFullViewData])

  const getProject = async (projectName: string, projectFullViewData: PmrE2EProjectFullViewModel | null) => {
    const hasProjectFullViewData = projectFullViewData !== null;
    const model = {
      skip: 0,
      take: 1,
      sort: "createdDateUtc",
      sortBy: "DESC",
      searchString: projectName,
      showFlexTask: hasProjectFullViewData ? projectFullViewData.showFlexTasks.isOn : true,
      ...projectFullViewData?.refiners,
      isFirstAndExactProjectName: true,
    };

    if (getProjectListCancelSource.current) {
      getProjectListCancelSource.current.cancel();
    }

    getProjectListCancelSource.current = (axios as any).CancelToken.source();

    try {
      const response = await ProjectListPmr.getProjectsHelper(
        model,
        getProjectListCancelSource.current
      );

      if (!response.isSuccess) return;

      dispatch(updateProjectManagementViewProjects(response.records));
      if (response.records[0]) setProject(response.records[0]);

      if(projectFullViewData !== null) {
        applyOrderLineRefiners(projectFullViewData);
      }else{
        setIsLoaded(true);
      }

    } catch (e) {
      if ((axios as any).isCancel(e)) {
        console.warn("Get Project List request cancelled");
        return;
      }
    } 
  };

  const applyOrderLineRefiners = async (projectFullViewData: PmrE2EProjectFullViewModel) => {
    dispatch(updateIsRefinerLoading(true));

    if (applyOrderLineRefinersCancelSource.current) {
      applyOrderLineRefinersCancelSource.current.cancel();
    }

    applyOrderLineRefinersCancelSource.current = (
      axios as any
    ).CancelToken.source();

    let request = {
      showFlexTask: projectFullViewData.showFlexTasks.isOn,
      e2eProjectIds: projectFullViewData.project?.id ? [projectFullViewData.project.id] : [],
      orderLines: undefined,
    };

    if (projectFullViewData?.refiners.orderLines) {
      request = {
        ...transformOptionLabelValueToValue(projectFullViewData?.refiners.orderLines),
        ...request,
      };
    }

    try {
      const response =
        await OrderLineRefinersService.getFilteredTaskAndMilestone(
          request,
          applyOrderLineRefinersCancelSource.current
        );

      dispatch(updateRefinerLineResult(response.data));
      setIsLoaded(true);
    } catch (e) {
      if ((axios as any).isCancel(e)) {
        return;
      }
    }
  };

  return (
    <>
     {urlSearch.get("fullViewProjectKey") !== null && !isProjectFullViewKeyFound && isLoaded && isBannerShown && (
        <div className={classes["full-view-icon-banner"]}>
          <Message className={classes["banner"]} content={ 
            <>
              <FontAwesomeIcon className={classes["icon"]} icon={faInfoCircle}/>
              Configured view for this link has expired and will be reset to the default view. 
              To view this with your set Favorites default view, please open this GMAWB Project to a new browser tab.
              <FontAwesomeIcon className={classes["close-icon"]} icon={faClose} onClick={() => setIsBannerShown(false)}/>
            </>
          }/>
        </div>
     )}
      {!isLoaded && (
        <div className={classes["project-full-view-container"]} style={{marginTop: "1rem"}}>
          <div className={classes["header"]}>
            <Skeleton width="20rem" height="2rem" />
          </div>
          <div style={{paddingTop: "1rem"}}>
            <Skeleton shape="circle" size="2rem" className="p-mr-2"></Skeleton>
          </div>
          <div style={{paddingTop: "2rem"}}>
            <Skeleton width="15rem" height="2rem"></Skeleton>
          </div>
          <div style={{paddingTop: "1rem"}}>
            <Skeleton width="100%" height="35rem"></Skeleton>
          </div>
        </div>
      )}

      {project != null && isLoaded && (
        <div className={classes["project-full-view-container"]}>
          <div className={classes["header"]}>
            <h2>{projectName}</h2>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className={clsx(classes["info-icon"], `info-icon`)}
            />

            <Tooltip
              position="bottom"
              mouseTrack
              className={`${classes["tooltip"]}`}
              target={`.info-icon`}
            >
              <div className={`${classes["tooltip-title"]}`}>
                GMAWB Project Creator
              </div>
              {
                <div className={`${classes["tooltip-label"]}`}>
                  {project.createdByDisplayName}
                </div>
              }
              <br />
              <div className={`${classes["tooltip-title"]}`}>
                FLEX Sold To (Customer Company Name)
              </div>
              {
                <div className={`${classes["tooltip-label"]}`}>
                  {project.flexSoldTo}
                </div>
              }

              <br />
              <div className={`${classes["tooltip-title"]}`}>
                FLEX Sold To (Party Site Number)
              </div>
              {
                <div className={`${classes["tooltip-label"]}`}>
                  {project.partySiteNumber}
                </div>
              }
            </Tooltip>

            <ProjectStatusTemplate
              tooltip="Status determined based on underlying GMAWB Task Statuses."
              status={project[projectColumnStatus]}
            ></ProjectStatusTemplate>
          </div>
          <ProjectCollaboratorAvatar
            collaborator={project[projectColumnCollaborator]}
          ></ProjectCollaboratorAvatar>
          <div className={`${classes["body"]}`}>
            <ProjectListHeaderActions
              filterByProject={true}
              project={project}
            />

            <div className={`${classes["table-div"]}`}>
              <ProjectListDetailTab
                project={project}
                projectId={project.id || ""}
                overrideActiveTabIndex={null}
                depthIndices={[0]}
              ></ProjectListDetailTab>
            </div>
          </div>
        </div>
      )}

      {urlSearch.get("fullViewProjectKey") === null && project == null && isLoaded && (
        <div className={classes["project-full-view-container"]}>
          <div className={classes["error-div"]}>
            <img src={maintenanceIcon} />
            <h1>Server Error</h1>
            <h3>GMAWB Project "{projectName}" does not exist.</h3>
          </div>
        </div>
      )}

      {urlSearch.get("fullViewProjectKey") !== null && !isProjectFullViewKeyFound && isLoaded && (
        <div className={classes["project-full-view-container"]}>
        <div className={classes["error-div"]}>
          <img src={maintenanceIcon} />
          <h1>Server Error</h1>
          <h3>The link is invalid or has expired.</h3>
        </div>
      </div>
      )}
    </>
  );
};

export default ProjectFullViewWithConfig;
