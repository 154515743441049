import classes from "./StepMenus.module.scss";

enum StepStatuses {
  Completed,
  Error,
  Warning,
  Default
}

type StepMenusProps = {
  steps: any;
  activeIndex: number;
  onNavigateStep: (i: number) => void;
  isReadOnly: boolean;
};

const StepMenus: React.FC<StepMenusProps> = ({
  steps,
  activeIndex,
  onNavigateStep,
  isReadOnly
}) => {
  const getStepItemClassName = (
    status: string,
    index: number
  ) => {
    if (activeIndex === index) return "-active";
    if (isReadOnly) return "-default";

    switch (status) {
      case StepStatuses[StepStatuses.Completed]:
        return "-complete";
      case StepStatuses[StepStatuses.Error]:
        return "-danger";
      case StepStatuses[StepStatuses.Warning]:
        return "-warning";
      default:
        return "-default";
    }
  };

  const getStepIcon = (status: String, index: number) => {
    if (activeIndex === index) return "pending";
    if (isReadOnly) return "more_horiz";

    switch (status) {
      case StepStatuses[StepStatuses.Completed]:
        return "done";
      case StepStatuses[StepStatuses.Error]:
        return "report";
      case StepStatuses[StepStatuses.Warning]:
        return "warning";
      default:
        return "more_horiz";
    }
  };

  return (
    <div className="ul-progressSteps">
      {steps.map((step: any, i: number) => {
        return (
          <div
            key={step.label}
            onClick={() => {
              i !== activeIndex && onNavigateStep && onNavigateStep(i);
            }}
            className={`ul-progressSteps__item ${getStepItemClassName(
              step.status,
              i
            )} ${classes["step-item"]}`}
          >
            <i
              className={`material-icons ul-progressSteps__icon ${classes["step-icon"]}`}
            >
              {getStepIcon(step.status, i)}
            </i>
            <span
              className={`ul-progressSteps__description ${classes["step-description"]}`}
            >
              {step.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default StepMenus;
