import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCertificateNumber,
  selectCertificateScheme,
  selectCopyPartyInformation,
  selectIsCertNumberOnMaxlength,
  selectHasExistingModels,
  updateCertDetailsFlatAttrList,
  updateCertDetailsFormValues,
  updateIsCertNumberOnMaxlength,
  updateProdDetailsModelList,
  updateHasExistingModels,
  selectProductOwner,
  selectCertDetailValues,
  updateModelList,
  selectHasEmptyProductStandardRow,
  selectStandardValue,
  selectCertDetailFormValues,
  updateHasCertificateProductStandard,
} from "../../../../../features/createCertificate/createCertificateSlice";
import CertificateListService from "../../../../../services/CertificateListService";
import classes from "./AddNewCertDetails.module.scss";
import "./AddNewCertDetails.scss";
import DynamicFieldButtons from "./dynamic-field-multiple/DynamicFieldButtons";
import DynamicFieldMultiple from "./dynamic-field-multiple/DynamicFieldMultiple";

const AddNewCertDetails = (props) => {
  const {
    schemeList,
    setIsValidStep,
    isEditCert,
    isModifyCert,
    hierarchyId,
    editData,
    hasRevisions,
    setIsDisableContinue,
    formValues,
    setFormValues,
    onContinue,
    certificateStatus,
    formAttributesWithError,
    setFormAttributesWithError,
    formValidityMessages,
    setFormValidityMessages,
    scrollIntoBlankRequiredElement,
    setScrollIntoBlankRequiredElement,
    currentStep,
    isCertificateSchemeUpdatable,
    setIsCertificateHaveStandard,
  } = props;

  const [attributeFlatList, setAttributeFlatList] = useState([]);
  const [formDisplay, setFormDisplay] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState(null);
  const [isShowCertSchemeDropdown, setIsShowCertSchemeDropdown] =
    useState(true);
  const [isFetchingCertAttributes, setIsFetchingCertAttributes] =
    useState(false);
  const [isShowNoStandard, setIsShowNoStandard] = useState(false);
  const [addDynamicField, setAddDynamicField] = useState(false);
  const [dropdownRef, setDropdownRef] = useState();
  const [productOwnerFilter, setProductOwnerFilter] = useState("");

  const dispatch = useDispatch();
  const copyCertificateNumber = useSelector(selectCertificateNumber);
  const copyPartyInformation = useSelector(selectCopyPartyInformation);
  const isCertNameOnMaxLength = useSelector(selectIsCertNumberOnMaxlength);
  const certificateScheme = useSelector(selectCertificateScheme);
  const hasExistingModels = useSelector(selectHasExistingModels);
  const productOwner = useSelector(selectProductOwner);
  const certDetails = useSelector(selectCertDetailValues);
  const hasEmptyProductRow = useSelector(selectHasEmptyProductStandardRow);
  const standardValues = useSelector(selectStandardValue);
  const certificateDetailFormValues = useSelector(selectCertDetailFormValues);

  useEffect(() => {
    let isStep3Valid = selectedScheme ? true : false;
    if (selectedScheme) {
      for (const prop in formValidityMessages) {
        if (isModifyCert || isEditCert) {
          setFormAttributesWithError(formValidityMessages);
        }
        if (formValidityMessages[prop]) {
          if (isModifyCert || isEditCert) {
            setFormAttributesWithError(formValidityMessages);
          }
          isStep3Valid = false;

          if (!(isModifyCert || isEditCert)) {
            const getBrandOnlyErrorMessage = Object.entries(
              formValidityMessages ?? []
            )
              ?.filter((formValidityMessage) => formValidityMessage[1])
              .map((value) => value[0]);

            if (
              getBrandOnlyErrorMessage.length === 1 &&
              getBrandOnlyErrorMessage.some(
                (formValidityStringKey) => formValidityStringKey === "brand"
              )
            ) {
              isStep3Valid =
                certificateDetailFormValues.brand.length > 0 &&
                certificateDetailFormValues.brand.some(
                  (brandItems) => brandItems
                );
            }
          }
        }
      }
    }

    setIsValidStep((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[3] = isStep3Valid;
      return newState;
    });
  }, [formValidityMessages, setIsValidStep, selectedScheme]);

  useEffect(() => {
    if (isEditCert || isModifyCert) {
      const errorClassNameInput = classes["certDetails-error-input"];
      const errorClassNameMultiselect =
        classes["certDetails-error-multiselect"];
      const errorClassNameBrand = classes["certDetails-error-brand"];
      const errorClassNameStandard = classes["certDetails-error-standard"];

      if (hasEmptyProductRow && standardValues && standardValues.length === 0) {
        const standardElement = document.getElementById(`certDetails-standard`);
        if (standardElement) {
          const standardField = standardElement
            .querySelector("div")
            .getElementsByClassName("p-multiselect")[0];
          !hasEmptyProductRow
            ? standardField?.classList.remove(errorClassNameStandard)
            : standardField?.classList.add(errorClassNameStandard);
        }
      }

      if (formAttributesWithError) {
        Object.entries(formAttributesWithError).forEach((field) => {
          const getElement = document.getElementById(`certDetails-${field[0]}`);
          if (getElement) {
            if (
              field[0] === "brandOwner" ||
              field[0] === "localRepresentative" ||
              field[0] === "applicant"
            ) {
              !field[1]
                ? getElement?.classList.remove(errorClassNameMultiselect)
                : getElement?.classList.add(errorClassNameMultiselect);
            } else if (field[0] === "brand") {
              const brandField = getElement
                .querySelector("div")
                .getElementsByClassName("p-inputtext")[0];

              brandField.value
                ? brandField?.classList.remove(errorClassNameBrand)
                : brandField?.classList.add(errorClassNameBrand);
            } else {
              !field[1]
                ? getElement?.classList.remove(errorClassNameInput)
                : getElement?.classList.add(errorClassNameInput);
            }
          }
        });
      }

      if (
        scrollIntoBlankRequiredElement &&
        scrollIntoBlankRequiredElement.length > 0
      ) {
        const scrollIntoSingleElement = document.getElementById(
          scrollIntoBlankRequiredElement[0]
        );
        if (scrollIntoSingleElement) {
          scrollIntoSingleElement.scrollIntoView({
            behavior: "smooth",
          });
        }

        setScrollIntoBlankRequiredElement(null);
      }
    }

    return () => {
      setScrollIntoBlankRequiredElement(null);
    };
  }, [
    scrollIntoBlankRequiredElement,
    setScrollIntoBlankRequiredElement,
    formAttributesWithError,
    hasEmptyProductRow,
    formDisplay,
    certificateDetailFormValues,
    currentStep,
  ]);

  useEffect(() => {
    setSelectedScheme(certificateScheme);

    if (certificateScheme) {
      setIsShowCertSchemeDropdown(false);
      setIsFetchingCertAttributes(true);
      setIsDisableContinue(true);
      getCertificateAttributes(certificateScheme);
    } else {
      setIsShowCertSchemeDropdown(true);
    }
  }, [certificateScheme]);

  const hasStandardCertificate = (certificateAttributes) => {
    if (
      !Array.isArray(certificateAttributes) ||
      certificateAttributes.length === 0
    ) {
      return false;
    }

    for (const element of certificateAttributes) {
      if (
        typeof element === "object" &&
        Array.isArray(element.cert_attributes)
      ) {
        for (const attribute of element.cert_attributes) {
          if (attribute.attribute_name === "standard") {
            return true;
          }
        }
      }
    }

    return false;
  };

  const getCertificateAttributes = async function (
    value,
    isChangeScheme = false
  ) {
    const hierarchyIdValue =
      isModifyCert || isModifyCert === undefined ? "" : hierarchyId;
    const result = await CertificateListService.getCertificateAttributes(
      value,
      hierarchyIdValue
    );

    if (isModifyCert || isEditCert) {
      if (hasStandardCertificate(result)) {
        dispatch(updateHasCertificateProductStandard(true));
      } else {
        dispatch(updateHasCertificateProductStandard(false));
      }
    }

    if (result && Array.isArray(result) && result.length > 0) {
      let formValuesTemp = {};
      let formDisplayTemp = [];
      let formValidityMessagesTemp = {};
      let attrFlatListTemp = [];

      let certNumber = await generateCertificateNumber(value);

      if (isEditCert || isModifyCert) {
        props.setIsCertificateHaveStandard(
          result[0].cert_attributes?.some(
            (x) => x.attribute_name === "standard"
          )
        );
      }

      result.forEach((certGroup) => {
        let formDisplayUnderGroupTemp = [];
        let formDisplayUnderGroupIndex = -1;

        certGroup.cert_attributes.forEach((attribute, index) => {
          let attributeFlat = JSON.parse(JSON.stringify(attribute));
          attributeFlat["cert_group"] = certGroup.cert_group;
          attributeFlat["value"] = null;
          attrFlatListTemp.push(attributeFlat);

          formValidityMessagesTemp[attribute.attribute_name] =
            attribute.is_required && !attribute.default_value
              ? attribute.display_name + " is required."
              : "";

          if (
            attribute.is_select_multiple ||
            attribute.data_group ||
            attribute.max_value !== "1"
          ) {
            formValuesTemp[attribute.attribute_name] = attribute.default_value
              ? [attribute.default_value]
              : [];
          } else {
            formValuesTemp[attribute.attribute_name] = attribute.default_value;
          }

          if (certNumber) {
            formValuesTemp["certificateName"] = certNumber;
            formValidityMessagesTemp["certificateName"] = "";
          }

          if (
            index > 0 &&
            attribute.data_group &&
            !attribute.is_select_multiple
          ) {
            if (
              attribute.data_group ===
              certGroup.cert_attributes[index - 1].data_group
            ) {
              formDisplayUnderGroupTemp[
                formDisplayUnderGroupIndex
              ].attribute[0].push(attribute);
            } else {
              formDisplayUnderGroupTemp.push({
                attribute: [[attribute]],
                type: "table",
              });
              formDisplayUnderGroupIndex += 1;
            }
          } else {
            if (attribute.max_value !== "1") {
              if (!attribute.is_select_multiple) {
                formDisplayUnderGroupTemp.push({
                  attribute: [attribute],
                  type: "list",
                });
              } else {
                formDisplayUnderGroupTemp.push({
                  attribute: attribute,
                  type: "selectmultiple",
                });
              }
            } else {
              formDisplayUnderGroupTemp.push({
                attribute: attribute,
                type: "single",
              });
            }
            formDisplayUnderGroupIndex += 1;
          }
        });

        formDisplayTemp.push({
          ...certGroup,
          attributesDisplay: formDisplayUnderGroupTemp,
        });
      });

      if (copyPartyInformation) {
        const partyAttributeNames = attrFlatListTemp
          .filter((a) => a.cert_group === "B_PARTY TYPE")
          .map((a) => a.name);
        const partiesData = editData.filter(
          (e) =>
            partyAttributeNames.includes(e.attribute_name) &&
            e.value?.length > 0
        );

        partiesData?.forEach((partyData) => {
          const partyAttribute = attrFlatListTemp.find(
            (a) => a.name === partyData.attribute_name
          );
          if (partyAttribute.max_value !== "1") {
            formValuesTemp[partyAttribute.attribute_name] = partyData.value;
          } else {
            formValuesTemp[partyAttribute.attribute_name] = partyData.value[0];
          }
          formValidityMessagesTemp[partyAttribute.attribute_name] = "";

          if (partyAttribute.max_value !== "1" && !partyAttribute.data_group) {
            formDisplayTemp.forEach((row) => {
              if (row.cert_group === partyAttribute["cert_group"]) {
                row.attributesDisplay.forEach((display) => {
                  if (
                    Array.isArray(display.attribute) &&
                    display.attribute.length > 0
                  ) {
                    if (
                      display.attribute[0]["attribute_name"] ===
                      partyAttribute["attribute_name"]
                    ) {
                      const fieldRowCount = partyData["value"].length;

                      const newAttr = JSON.parse(
                        JSON.stringify(display.attribute[0])
                      );
                      display.attribute = [];
                      for (let count = 1; count <= fieldRowCount; count++) {
                        display.attribute.push(newAttr);
                      }
                    }
                  }
                });
              }
            });
          }
        });
      }

      if (isEditCert) {
        let attributeFlatListFromDbTemp = editData;

        attrFlatListTemp.forEach((attribute) => {
          if (
            isChangeScheme &&
            (attribute.attribute_name === "certificateType" ||
              attribute.attribute_name === "issuingBody")
          )
            return;

          const attrFromDb = attributeFlatListFromDbTemp.find(
            (i) =>
              i.attribute_name ===
              (attribute["cert_group"] === "B_PARTY TYPE"
                ? attribute["name"]
                : attribute["attribute_name"])
          );

          if (!attrFromDb) return;
          if (attribute.max_value !== "1") {
            if (attribute["attribute_name"] === "deliverable") {
              attribute["value"] =
                attrFromDb["value"].length > 0 &&
                attrFromDb["value"][0].split(";");
            } else {
              attribute["value"] = attrFromDb["value"];
            }
          } else {
            attribute["value"] =
              attrFromDb["value"].length > 0 && attrFromDb["value"][0];
          }

          if (attribute["value"]) {
            formValuesTemp[attribute["attribute_name"]] = attribute["value"];
          }

          if (
            (attribute["is_required"] && attribute["value"]) ||
            !attribute["is_required"]
          ) {
            formValidityMessagesTemp[attribute["attribute_name"]] = "";
          }

          if (attribute.max_value !== "1" && !attribute.data_group) {
            formDisplayTemp.forEach((row) => {
              if (row.cert_group === attribute["cert_group"]) {
                row.attributesDisplay.forEach((display) => {
                  if (
                    Array.isArray(display.attribute) &&
                    display.attribute.length > 0
                  ) {
                    if (
                      display.attribute[0]["attribute_name"] ===
                      attribute["attribute_name"]
                    ) {
                      const fieldRowCount = attrFromDb["value"].length;

                      const newAttr = JSON.parse(
                        JSON.stringify(display.attribute[0])
                      );
                      display.attribute = [];
                      for (let count = 1; count <= fieldRowCount; count++) {
                        display.attribute.push(newAttr);
                      }
                    }
                  }
                });
              }
            });
          } else if (attribute.max_value !== "1" && attribute.data_group) {
            formDisplayTemp.forEach((certGroupRow) => {
              if (certGroupRow.cert_group === attribute["cert_group"]) {
                certGroupRow.attributesDisplay.forEach((display) => {
                  if (
                    Array.isArray(display.attribute) &&
                    display.attribute.length > 0 &&
                    display.attribute[0].length > 0
                  ) {
                    const foundField = display.attribute[0].find(
                      (fieldColumn) =>
                        fieldColumn["attribute_name"] ===
                        attribute["attribute_name"]
                    );

                    if (foundField) {
                      const fieldRowCount = attrFromDb["value"].length;

                      const newAttr = JSON.parse(
                        JSON.stringify(display.attribute[0])
                      );
                      display.attribute = [];

                      for (let count = 1; count <= fieldRowCount; count++) {
                        display.attribute.push(newAttr);
                      }
                    }
                  }
                });
              }
            });
          }
        });
      }

      setFormValues(formValuesTemp);
      dispatch(updateCertDetailsFormValues(formValuesTemp));
      setFormDisplay(formDisplayTemp);
      setFormValidityMessages(formValidityMessagesTemp);
      setAttributeFlatList(attrFlatListTemp);
      dispatch(updateCertDetailsFlatAttrList(attrFlatListTemp));
      dispatch(
        updateIsCertNumberOnMaxlength(
          formValuesTemp.certificateName.length >= 50
        )
      );
    } else {
      setFormValidityMessages({});
      setFormValues({});
      setFormDisplay([]);
      setAttributeFlatList([]);
    }

    setIsFetchingCertAttributes(false);
    setIsDisableContinue(false);
  };

  const generateCertificateNumber = async function (certficateScheme) {
    if (!certficateScheme || isEditCert || isModifyCert) return;

    if (copyCertificateNumber) return copyCertificateNumber;

    const data = {
      certificateScheme: certficateScheme,
      issuingBody: "",
    };

    const result = await CertificateListService.generateCertficateNumber(data);

    return result?.data?.number;
  };

  const onChangeScheme = async (value) => {
    setIsShowCertSchemeDropdown(false);
    setIsFetchingCertAttributes(true);
    setIsDisableContinue(true);
    await getCertificateAttributes(value, true);
    setSelectedScheme(value);
  };

  const onAddDynamicFieldMultiple = (certGroupIndex, attrIndex) => {
    setFormDisplay((prevState) => {
      let formDisplayTemp = JSON.parse(JSON.stringify(prevState));
      formDisplayTemp[certGroupIndex].attributesDisplay[
        attrIndex
      ].attribute.push(
        formDisplayTemp[certGroupIndex].attributesDisplay[attrIndex]
          .attribute[0]
      );
      return formDisplayTemp;
    });

    setAddDynamicField(true);
  };

  const onRemoveDynamicFieldMultiple = (
    attrId,
    certGroupIndex,
    attrIndex,
    removeIndex
  ) => {
    setFormDisplay((prevState) => {
      let formDisplayTemp = JSON.parse(JSON.stringify(prevState));
      formDisplayTemp[certGroupIndex].attributesDisplay[
        attrIndex
      ].attribute.splice(removeIndex, 1);

      return formDisplayTemp;
    });

    setFormValues((prevState) => {
      let formValuesTemp = JSON.parse(JSON.stringify(prevState));
      formValuesTemp[attrId].splice(removeIndex, 1);

      if (attrId === "brand" && (isEditCert || isModifyCert)) {
        const brandField = formValuesTemp[attrId];

        const isAnyBrandFieldHasValue =
          brandField && brandField.length > 0 && brandField.some((x) => x);

        setFormValidityMessages((prevState) => {
          let formValidityTemp = JSON.parse(JSON.stringify(prevState));

          formValidityTemp[attrId] = isAnyBrandFieldHasValue
            ? ""
            : "Trademark/Brand is required";

          return formValidityTemp;
        });
      }

      return formValuesTemp;
    });
  };

  const showCertSchemeDropdown = () => {
    setIsShowCertSchemeDropdown(true);
    setSelectedScheme("");
  };

  const getIfThereIsAnyDateValidityMessage = (param) => {
    for (const prop in formValidityMessages) {
      const message = formValidityMessages[prop];
      if (message && message === param) {
        return true;
      }
    }

    return false;
  };

  const modalHeader = (
    <>
      <h5 className={classes["modal-header"]}>
        Change Product Owner PSN Confirmation
      </h5>
    </>
  );

  const footer = (
    <>
      <div className={classes["footer-container"]}>
        <Button
          label="Yes"
          onClick={() => onChangeProductOwner()}
          className={`ul-button -app-primary`}
        />
        <Button
          label="No"
          onClick={() => onHideProductOwnerMessage()}
          className={`ul-button -medium default-cancel-button ${classes["btn-no"]}`}
        />
      </div>
    </>
  );

  const onHideProductOwnerMessage = () => {
    if (productOwnerFilter !== "") dropdownRef.current.resetFilter();

    dispatch(updateHasExistingModels(false));
    dispatch(updateCertDetailsFormValues(certDetails.formValues));
  };

  const onChangeProductOwner = () => {
    const prevValue = certDetails.formValues;

    let newState = {
      ...prevValue,
      ownerReference: productOwner,
    };

    dispatch(updateCertDetailsFormValues(newState));
    dispatch(updateHasExistingModels(false));
    dispatch(updateProdDetailsModelList([]));
    dispatch(updateModelList([]));
  };

  const shouldViewEditStanndardDetailsDisplay = (attrDisplay) =>
    attrDisplay.attribute.display_name === "Standard" &&
    (isEditCert || isModifyCert);

  return (
    <>
      <div>
        <span>Enter certificate details below</span>
        <span className="text-red"> (* = Required field)</span>
      </div>

      {isShowCertSchemeDropdown && (
        <div className="select-cert-scheme-div">
          <span className="select-cert-scheme-label">
            *Certification Scheme
          </span>
          <span>
            <Dropdown
              inputId="dropdown"
              value={selectedScheme}
              options={schemeList}
              optionLabel="hierarchyValue"
              optionValue="hierarchyValue"
              placeholder="-- Click to type below --"
              filter
              onChange={(e) => onChangeScheme(e.value)}
            />
          </span>
        </div>
      )}

      {!isShowCertSchemeDropdown &&
        !isFetchingCertAttributes &&
        isCertificateSchemeUpdatable && (
          <div className="change-cert-scheme-div">
            <span
              onClick={showCertSchemeDropdown}
              className="change-cert-scheme-btn text-blue"
            >
              Change Certificate Scheme?
            </span>
          </div>
        )}

      {isShowNoStandard && (
        <div className={`${classes["no-standard-toast"]}`}>
          <div
            className={`ul-grid__row text-center ${classes["no-standard-toast-message"]}`}
          >
            <div
              classname="ul-grid__column -x5"
              style={{ marginLeft: "0.5rem" }}
            >
              <FontAwesomeIcon icon={faInfoCircle} size="xl" />
            </div>
            <div className="ul-grid__column -x90">
              No Standard is/are available for the selected Product Category{" "}
            </div>
            <div
              className={`ul-grid__column -x5 ${classes["no-standard-toast-icon-close"]}`}
            >
              <span
                onClick={() => setIsShowNoStandard(false)}
                className={`${classes["no-standard-toast-icon-close"]}`}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
          </div>
        </div>
      )}

      {isFetchingCertAttributes && (
        <div className="loading-cert-attr-div">
          <div>
            <div className="fetching-attr-load-label">
              Fetching attributes...
            </div>
            <div>
              <svg className="ul-progressCircle -medium">
                <circle
                  className="ul-progressCircle__outer"
                  cx="50%"
                  cy="50%"
                  r="35%"
                />
                <circle
                  className="ul-progressCircle__inner"
                  cx="50%"
                  cy="50%"
                  r="35%"
                />
              </svg>
            </div>
          </div>
        </div>
      )}

      {!isShowCertSchemeDropdown &&
        !isFetchingCertAttributes &&
        formDisplay &&
        Array.isArray(formDisplay) &&
        formDisplay.length > 0 &&
        formDisplay.map((certGroup, certGroupIndex) => (
          <div
            key={certGroup.cert_group}
            className="add-new-cert-grouped-flds-container"
          >
            <div className="add-new-cert-grouped-flds-hdr">
              <span>{certGroup.cert_group_text}</span>
            </div>
            <div className="add-new-cert-grouped-flds-inputs">
              <div className="ul-grid__row -wrap">
                {certGroup.attributesDisplay &&
                  certGroup.attributesDisplay.map((attrDisplay, attrIndex) => (
                    <div
                      id={`certDetails-${
                        Array.isArray(attrDisplay.attribute) &&
                        attrDisplay.attribute.length > 0
                          ? attrDisplay.attribute[0]["attribute_name"]
                          : attrDisplay.attribute.attribute_name
                      }`}
                      className={`ul-grid__column 
                    ${
                      attrDisplay.attribute &&
                      Array.isArray(attrDisplay.attribute) &&
                      attrDisplay.attribute.length > 0 &&
                      attrDisplay.attribute[0]["attribute_name"] === "comment"
                        ? "-x60 dynamic-fld-list"
                        : attrDisplay.type === "list"
                        ? "-x30 dynamic-fld-list"
                        : attrDisplay.type === "single" ||
                          attrDisplay.type === "selectmultiple"
                        ? "-x30"
                        : "-x100 dynamic-fld-table"
                    } 
                    -offset-5-desktop 
                    -x100-mobile dynamic-fld-div
                    `}
                      key={
                        "dynamic-field-div" + certGroupIndex + "-" + attrIndex
                      }
                    >
                      {attrDisplay.type === "list" &&
                        attrDisplay.attribute.length > 0 && (
                          <div className="dynamic-fld-card">
                            <div className="ul-grid__row">
                              <div className="ul-grid__column -x80 dynamic-fld-card-hdr">
                                <span>
                                  {attrDisplay.attribute[0]["display_name"]}{" "}
                                </span>
                                {attrDisplay.attribute[0].is_required && (
                                  <span className="text-red"> *</span>
                                )}
                              </div>
                              <div className="ul-grid__column -x20"></div>
                            </div>

                            {attrDisplay.attribute.map((field, fieldIndex) => (
                              <div
                                key={
                                  "dynamic-field-multiple-" +
                                  certGroupIndex +
                                  "-" +
                                  attrIndex +
                                  "-" +
                                  fieldIndex +
                                  "-" +
                                  field.id
                                }
                                className="ul-grid__row"
                              >
                                <div className="ul-grid__column -x80 dynamic-fld-card-body">
                                  <DynamicFieldMultiple
                                    setIsShowNoStandard={setIsShowNoStandard}
                                    selectedScheme={selectedScheme}
                                    field={field}
                                    isRequired={field.is_required}
                                    formValues={formValues}
                                    attributeFlatList={attributeFlatList}
                                    formDisplay={formDisplay}
                                    setFormValues={setFormValues}
                                    certGroupIndex={certGroupIndex}
                                    setFormValidityMessages={
                                      setFormValidityMessages
                                    }
                                    fieldIndex={fieldIndex}
                                    fieldType={attrDisplay.type}
                                    isEditCert={isEditCert}
                                    addDynamicField={addDynamicField}
                                  />
                                </div>

                                {field.max_value !== "1" && (
                                  <DynamicFieldButtons
                                    setIsShowNoStandard={isShowNoStandard}
                                    rowIndex={fieldIndex}
                                    rowLength={attrDisplay.attribute.length}
                                    onAddDynamicFieldMultiple={() =>
                                      onAddDynamicFieldMultiple(
                                        certGroupIndex,
                                        attrIndex
                                      )
                                    }
                                    onRemoveDynamicFieldMultiple={() =>
                                      onRemoveDynamicFieldMultiple(
                                        field.attribute_name,
                                        certGroupIndex,
                                        attrIndex,
                                        fieldIndex
                                      )
                                    }
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                        )}

                      {attrDisplay.type === "table" && (
                        <div className="dynamic-fld-card">
                          <div className="ul-grid__row">
                            {attrDisplay.attribute[0].map((field) => (
                              <div
                                key={
                                  "dynamic-field-table-label-" +
                                  certGroupIndex +
                                  "-" +
                                  attrIndex +
                                  "-" +
                                  field.id
                                }
                                className="ul-grid__column -x30 dynamic-fld-card-hdr"
                              >
                                <span> {field.display_name}</span>
                                {field.is_required && (
                                  <span className="text-red"> *</span>
                                )}
                              </div>
                            ))}
                          </div>

                          {attrDisplay.attribute.map((row, rowIndex) => (
                            <div
                              key={
                                "dyanmic-field-table-row-" +
                                certGroupIndex +
                                "-" +
                                attrIndex +
                                "-" +
                                rowIndex
                              }
                              className="ul-grid__row"
                            >
                              {row.map((field, fieldIndex) => (
                                <div
                                  key={
                                    "dynamic-field-table-multiple-" +
                                    certGroupIndex +
                                    "-" +
                                    rowIndex +
                                    "-" +
                                    fieldIndex
                                  }
                                  className="ul-grid__column -x30 dynamic-fld-card-body"
                                >
                                  <DynamicFieldMultiple
                                    setIsShowNoStandard={setIsShowNoStandard}
                                    selectedScheme={selectedScheme}
                                    field={field}
                                    isRequired={field.is_required}
                                    formValues={formValues}
                                    attributeFlatList={attributeFlatList}
                                    setFormValues={setFormValues}
                                    setFormDisplay={setFormDisplay}
                                    certGroupIndex={certGroupIndex}
                                    setFormValidityMessages={
                                      setFormValidityMessages
                                    }
                                    fieldIndex={fieldIndex}
                                    rowIndex={rowIndex}
                                    fieldType={attrDisplay.type}
                                  />
                                </div>
                              ))}

                              {row[0].max_value !== "1" && (
                                <DynamicFieldButtons
                                  setIsShowNoStandard={isShowNoStandard}
                                  rowIndex={rowIndex}
                                  rowLength={attrDisplay.attribute.length}
                                  onAddDynamicFieldMultiple={() =>
                                    onAddDynamicFieldMultiple(
                                      certGroupIndex,
                                      attrIndex
                                    )
                                  }
                                  onRemoveDynamicFieldMultiple={() =>
                                    onRemoveDynamicFieldMultiple(
                                      row[0].id,
                                      certGroupIndex,
                                      attrIndex,
                                      rowIndex
                                    )
                                  }
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      )}

                      {(attrDisplay.type === "single" ||
                        attrDisplay.type === "selectmultiple") && (
                        <div>
                          <div className="dynamic-fld-lbl">
                            <span>{attrDisplay.attribute.display_name}</span>
                            {attrDisplay.attribute.is_required && (
                              <span className="text-red"> * </span>
                            )}
                            {attrDisplay.attribute.display_name ===
                              "Product Owner" && (
                              <span>
                                <FontAwesomeIcon
                                  className={`${classes["psn-info-icon"]}`}
                                  icon={faInfoCircle}
                                  size="xs"
                                  title="Product Owner Party Site Number will be inherited into the Model Search Party Site Number field"
                                />
                              </span>
                            )}

                            {shouldViewEditStanndardDetailsDisplay(
                              attrDisplay
                            ) && (
                              <button
                                onClick={onContinue}
                                className={`${classes["btn-view-standard-details"]}`}
                              >
                                View/Edit Details
                              </button>
                            )}
                          </div>
                          <DynamicFieldMultiple
                            setIsShowNoStandard={setIsShowNoStandard}
                            field={attrDisplay.attribute}
                            isRequired={attrDisplay.attribute.is_required}
                            formValues={formValues}
                            attributeFlatList={attributeFlatList}
                            setFormValues={setFormValues}
                            setFormValidityMessages={setFormValidityMessages}
                            formDisplay={formDisplay}
                            certGroupIndex={certGroupIndex}
                            fieldIndex={0}
                            selectedScheme={selectedScheme}
                            fieldType={attrDisplay.type}
                            isEditCert={isEditCert}
                            isModifyCert={isModifyCert}
                            hasRevisions={hasRevisions}
                            setDropdownRef={setDropdownRef}
                            setProductOwnerFilter={setProductOwnerFilter}
                            showMultiSelectValuesTooltip={
                              attrDisplay.attribute.display_name === "Standard"
                            }
                            isFieldDisabled={
                              attrDisplay.attribute.display_name ===
                                "Standard" &&
                              (isModifyCert ||
                                certificateStatus === "Under Revision")
                            }
                          />
                          {attrDisplay.attribute.attribute_name ===
                            "certificateName" &&
                            isCertNameOnMaxLength && (
                              <span className="text-red">
                                {" "}
                                You have reached the maximum character length
                                (50 characters).{" "}
                              </span>
                            )}

                          {shouldViewEditStanndardDetailsDisplay(
                            attrDisplay
                          ) && (
                            <div
                              className={
                                classes["view-edit-standard-details-note"]
                              }
                            >
                              Note: Click View/Edit Details above to make
                              changes to Product Standard in Step 3.
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              {getIfThereIsAnyDateValidityMessage(
                "Expiry Date must be greater than or equal to Issue Date"
              ) &&
                certGroup.cert_group_text === "Certify" && (
                  <div className="text-red">
                    Expiry Date must be greater than or equal to Issue Date'
                  </div>
                )}
            </div>
          </div>
        ))}
      <Dialog
        visible={hasExistingModels}
        header={modalHeader}
        footer={footer}
        style={{ width: "30vw" }}
        onHide={() => onHideProductOwnerMessage()}
        className={classes["psn-confirmation-container"]}
      >
        <p className={classes["body-content"]}>
          Changing the Product Owner PSN will automatically delete the selected
          model(s).
          <br />
          <br />
          Do you want to proceed?
        </p>
      </Dialog>
    </>
  );
};

export default AddNewCertDetails;
