const certBulkImportColumns = [
  "country",
  "certificateType",
  "certificateName",
  "applicant",
  "pty_site",
  "prd_name",
  //"mod_no",
  "product_type",
  "issueDate",
  "expiryDate",
  "certificateType",
  "issuingBody",
  //"brd",
  //"mfr",
  //"holder",
  //"loc_rep",
  //"fty",
  //"std",
  "testReportRefNo",

  //"tst_rep_date",
  //"tst_lab",
  //"hdlr_name",
  //"prj_no",
  //"odr_no",
  //"line_no",
  //"subcon",
  //"hdlr_co",
  //"rmk",

  //Oracle Account Number(TBD)
  //"account_number",
];

export default certBulkImportColumns;
