import classes from "../GpiCopyFormModal.module.scss";
import CompanyInformation from "./CompanyInformationTemplate";

const LocalRepImporter = ({
  fieldValue,
  notULProvidedLocalReps,
  ulProvidedLocalReps,
}) => {
  return (
    <div className={classes["details-content-container"]}>
      <div className={classes["details-field"]}>
        The following countries will require you to supply the local
        importer/representative. UL cannot provide the services for the
        following schemes.
      </div>

      {notULProvidedLocalReps(fieldValue).map((data) => {
        return (
          <div key={data.schemeId}>
            <div className={classes["details-header"]}>{data.schemeName}</div>
            {data.representatives?.map((rep, i) => {
              return (
                <div key={rep.gPICompanyInfoId}>
                  <div className={classes["details-field"]}>
                    Representative {i + 1}
                  </div>
                  <CompanyInformation fieldValue={rep} />
                </div>
              );
            })}
          </div>
        );
      })}

      <hr />

      <div className={classes["details-field"]}>
        The following countries will require a local representative where UL can
        support with a local representative service.
      </div>

      {ulProvidedLocalReps(fieldValue).map((data) => {
        return (
          <div key={data.schemeId}>
            {data.uLToProvide ? (
              <div className={classes["details-header"]}>UL to Provide</div>
            ) : (
              <>
                <div className={classes["details-header"]}>
                  {data.schemeName}
                </div>
                {data.representatives?.map((rep, i) => {
                  return (
                    <div key={rep.gPICompanyInfoId}>
                      <div className={classes["details-field"]}>
                        Representative {i + 1}
                      </div>
                      <CompanyInformation fieldValue={rep} />
                    </div>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LocalRepImporter;
