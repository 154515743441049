import classes from "./ProjectOrderlineStatusBulkEditDialog.module.scss";
import { useEffect, useState } from "react";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import PmrE2EProjectDetailsOrderlineModel, {
  getDisplay,
  isCompletedStatusEnabled,
  isJapan,
} from "../../interfaces/pmr-e2e-project-details-orderline-model";
import {
  ProjectOrderlineStatusObject,
  ProjectOrderlineStatusOnHoldReason,
} from "../../interfaces/pmr-e2e-project-orderline-status-model";
import ProjectOrderlineStatus from "../../enums/project-orderline-status";
import { Tooltip } from "primereact/tooltip";
import FlexIntegrationService from "../../../../../services/FlexIntegrationService";
import useToastr from "../../../../../hooks/useToastr";
import ProjectService from "../../../../../services/ProjectService";
import { uniqBy } from "lodash";
import useProjectOrderlineDetail from "../../../../../hooks/useProjectOrderlineDetail";
import { FlexProjectStatusOnHoldReason } from "../../interfaces/pmr-e2e-flex-project-status-onhold-reason-model";
import FlexProjectStatus from "../../enums/flex-project-status";
import ProjectOrderlineStatusConfirmDialog from "../project-orderline-status-confirm-dialog/ProjectOrderlineStatusConfirmDialog";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";

interface ProjectOrderlineStatusBulkEditDialogProps {
  closeModal: () => void;
  visible: boolean;
  selectedRows: PmrE2EProjectDetailsOrderlineModel[];
  reloadList: () => void;
  projectId: string;
}
const ProjectOrderlineStatusEditDialog = (
  props: ProjectOrderlineStatusBulkEditDialogProps
) => {
  const [isConfirmDialogShow, setIsConfirmDialogShow] = useState(false);
  const [confirmDialogMessageText, setConfirmDialogMessageText] = useState("");
  const { showSuccess, showError, showInfo } = useToastr();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] =
    useState<ProjectOrderlineStatusObject | null>(null);
  const [previousSelectedStatus, setPreviousSelectedStatus] =
    useState<ProjectOrderlineStatusObject | null>(null);
  const [isOnHoldReasonShow, setIsOnHoldReasonShow] = useState(false);
  const [isOnHoldFlexProjectReasonShow, setIsOnHoldFlexProjectReasonShow] =
    useState(false);
  const [reasons, setReasons] = useState(new Array());
  const [flexProjectReasons, setFlexProjectReasons] = useState(new Array());
  const [selectedReason, setSelectedReason] =
    useState<ProjectOrderlineStatusOnHoldReason | null>(null);
  const [selectedFlexProjectReason, setSelectedFlexProjectReason] =
    useState<FlexProjectStatusOnHoldReason | null>(null);
  const [flexProjectToUpdate, setFlexProjectToUpdate] = useState(new Array());
  const [statusList, setStatusList] = useState<ProjectOrderlineStatusObject[]>(
    new Array()
  );
  const projectOrderlineDetailHook = useProjectOrderlineDetail(props.projectId);

  useEffect(() => {
    if (props.visible && props.selectedRows.length > 0) {
      getStatusList();
    } else {
      hideModal();
    }
  }, [props.visible]);

  const getStatusList = () => {
    const isCompletedStatusEnabledLocal = props.selectedRows.every((line) =>
      isCompletedStatusEnabled(line)
    );
    const isJapanLocal = props.selectedRows.every((line) => isJapan(line));
    const statuses = [
      {
        name: "In Progress",
        code: ProjectOrderlineStatus.InProgress,
        disabled: false,
        tooltip: ``,
      },
      {
        name: "On Hold",
        code: ProjectOrderlineStatus.OnHold,
        disabled: false,
        tooltip: ``,
      },
    ];
    const statusCompleted = {
      name: "Completed",
      code: ProjectOrderlineStatus.Completed,
      disabled: !isCompletedStatusEnabledLocal,
      tooltip: isCompletedStatusEnabledLocal
        ? ``
        : `Cannot change status to "Completed" manually.\n Please complete all FLEX Tasks/GMAWB Milestones to be updated automatically.`,
    };
    if (isJapanLocal) statuses.push(statusCompleted);

    setStatusList(statuses);
    const uniqStatus = uniqBy(props.selectedRows, `orderLineStatus`);
    if (uniqStatus.length == 1) {
      const currentStatus: any = statuses.find(
        (status) => status.code == uniqStatus[0]?.orderLineStatus
      );
      setSelectedStatus(currentStatus);
      setPreviousSelectedStatus(currentStatus);
    }
  };

  const onStatusChange = async (e: DropdownChangeParams) => {
    setSelectedStatus(e.value);
    if (e.value.code == ProjectOrderlineStatus.OnHold) {
      setIsOnHoldReasonShow(true);
      setIsOnHoldFlexProjectReasonShow(true);
      await loadReasonData();
    } else {
      setSelectedReason(null);
      setIsOnHoldReasonShow(false);
      setIsOnHoldFlexProjectReasonShow(false);
    }
    if (e.value.code != ProjectOrderlineStatus.Completed) {
      const lines = getLinesForUpdate(e.value.code);
      const uniqFlexProject = uniqBy(lines, `e2EFlexProjectId`);
      const flexProjects = uniqFlexProject.map((item) => {
        return {
          e2EFlexProjectId: item.e2EFlexProjectId,
          flexProjectNumber: item.projectNumber,
        };
      });
      setFlexProjectToUpdate(flexProjects);
    }
  };

  const loadReasonData = async () => {
    setIsLoading(true);
    let [projectLineReasonResult, flexProjectReasonResult] = await Promise.all([
      FlexIntegrationService.getProjectLineOnHoldReasons(),
      FlexIntegrationService.getFlexProjectOnHoldReasons(),
    ]);
    if (projectLineReasonResult) setReasons(projectLineReasonResult);
    if (flexProjectReasonResult) setFlexProjectReasons(flexProjectReasonResult);
    setIsLoading(false);
  };

  const getLinesForUpdate = (statusCode: any) => {
    return props.selectedRows;
  };

  const confirmSave = async () => {
    if (selectedStatus?.code == ProjectOrderlineStatus.InProgress) {
      confirmShowInprogressConfirmation();
    } else if (selectedStatus?.code == ProjectOrderlineStatus.OnHold) {
      const isSaveFlexProject = selectedFlexProjectReason ? true : false;
      save(isSaveFlexProject);
    } else {
      save(false);
    }
  };
  const confirmShowInprogressConfirmation = () => {
    const flexprojectForInprogress = props.selectedRows.filter(
      (item) => item.e2EFlexProjectStatus == FlexProjectStatus.OnHold
    );
    if (flexprojectForInprogress.length == 0) {
      save(false);
    } else {
      const isMultipleLine = props.selectedRows.length > 1;
      const data = props.selectedRows[0];
      let text = `Updating the Order Line Status for '<strong>${data?.orderLineNumber}</strong>' to '<strong>In Progress</strong>' will also set the associated FLEX Project Status to '<strong>In Progress</strong>'.`;
      if (isMultipleLine)
        text = `Updating the Order Line Statuses for your selected order lines to '<strong>In Progress</strong>' will also set their associated FLEX Project Statuses to '<strong>In Progress</strong>'.`;
      text += `</br></br>How do you want to proceed?`;
      setConfirmDialogMessageText(text);
      setIsConfirmDialogShow(true);
    }
  };
  const save = async (isSaveFlexProject: boolean) => {
    const listForUpdate = getLinesForUpdate(selectedStatus?.code);
    if (listForUpdate.length == 0) return;
    //orderlines to update status
    const listForUpdatePayload = listForUpdate.map((item) => {
      const obj = {
        e2EProjectLineId: item.id,
        status: selectedStatus?.code,
        fulFillmentStatusDetailId:
          selectedStatus?.code == ProjectOrderlineStatus.OnHold
            ? selectedReason?.fulFillmentStatusDetailId
            : null,
        fulFillmentStatusDetail:
          selectedStatus?.code == ProjectOrderlineStatus.OnHold
            ? selectedReason?.fulFillmentStatusDetail
            : null,
        e2EFlexProjectId: item.e2EFlexProjectId,
      };
      return obj;
    });

    let flexProjectToUpdateList: any[] = [];
    if (isSaveFlexProject) {
      //flex project list if has for update status
      if (flexProjectToUpdate?.length > 0) {
        flexProjectToUpdateList = flexProjectToUpdate.map((item) => {
          return {
            e2EFlexProjectId: item.e2EFlexProjectId,
            status: projectOrderlineDetailHook.getFlexProjectStatus(
              selectedStatus?.code
            ),
            projectStatusReasonId:
              selectedStatus?.code == ProjectOrderlineStatus.OnHold
                ? selectedFlexProjectReason?.projectStatusDetailId
                : null,
            projectStatusReason:
              selectedStatus?.code == ProjectOrderlineStatus.OnHold
                ? selectedFlexProjectReason?.projectStatusReason
                : null,
          };
        });
      }
    }

    projectOrderlineDetailHook.setWritebackStatusInprogressOnSave(
      listForUpdate
    );
    setIsSaving(true);
    const model = {
      projectLineStatuses: listForUpdatePayload,
      flexProjectStatuses: flexProjectToUpdateList,
    };
    const result = await ProjectService.updateOrderlineStatus(model);
    if (result.isSuccess) {
      hideModal();
    } else {
      let header = `Update Failed`;
      const isMultipleLine = props.selectedRows.length > 1;
      const data = props.selectedRows[0];
      let text = `Failed to update status for '<strong>${data?.orderLineNumber}</strong>'. Please try again. Contact the support team if issue persists.`;
      if (isMultipleLine)
        text = `Failed to update statuses for selected order lines. Please try again. Contact the support team if issue persists.`;
      showError(header, text);

      projectOrderlineDetailHook.setWritebackStatusToNone(listForUpdate);
    }
    setIsSaving(false);
  };

  const statusOptionTemplate = (option: ProjectOrderlineStatusObject) => {
    return (
      <div
        className={`status-${option.code}`}
        style={{
          width: "100%",
          pointerEvents: "auto",
        }}
      >
        {getDisplay(option.code)}
        <Tooltip
          target={`.status-${option.code}`}
          className="custom-tooltip"
          my="left top"
          at="left bottom"
        >
          {option.tooltip}
        </Tooltip>
      </div>
    );
  };
  const onReasonChange = (e: DropdownChangeParams) => {
    setSelectedReason(e.value);
  };
  const onFlexProjectReasonChange = (e: DropdownChangeParams) => {
    setSelectedFlexProjectReason(e.value);
  };
  const renderBody = () => {
    return (
      <>
        <div>
          <Dropdown
            value={selectedStatus}
            options={statusList}
            optionLabel="name"
            onChange={onStatusChange}
            className={classes["custom-dropdown"]}
            style={{ width: "100%", marginBottom: "5px" }}
            placeholder="Select Order Line Status"
            optionDisabled="disabled"
            itemTemplate={statusOptionTemplate}
          />
          <div>
            <i style={{ fontSize: ".9rem" }}>
              Note: Selected status will apply to all selected order lines.
            </i>
          </div>
        </div>
        {isOnHoldReasonShow && (
          <>
            <div style={{ marginTop: "1rem" }}>
              Please select a reason from the list below:
              <span className={classes["error"]}>*</span>
            </div>
            <div>
              <Dropdown
                value={selectedReason}
                options={reasons}
                optionLabel="fulFillmentStatusDetail"
                onChange={onReasonChange}
                className={classes["custom-dropdown"]}
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
                placeholder="Select order line 'On Hold' reason"
                showClear
              />
              <div>
                <i style={{ fontSize: ".9rem" }}>
                  Note: Selected reason will apply to all selected order lines.
                </i>
              </div>
            </div>
          </>
        )}
        {isOnHoldReasonShow && isOnHoldFlexProjectReasonShow && (
          <>
            <div>
              <hr className={classes["hr"]}></hr>
            </div>
            <div style={{ marginTop: "1rem" }}>
              Please select a reason that will apply to the project list down
              below:
            </div>
            <div style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>
              • Project Number {renderFlexProjectForUpdate()}
            </div>
            <div>
              <Dropdown
                value={selectedFlexProjectReason}
                options={flexProjectReasons}
                optionLabel="projectStatusReason"
                onChange={onFlexProjectReasonChange}
                className={classes["custom-dropdown"]}
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
                placeholder="Select FLEX Project 'On Hold' reason"
                showClear
              />
            </div>
          </>
        )}
      </>
    );
  };
  const isConfirmButtonDisabled = () => {
    return (
      isSaving ||
      isLoading ||
      !selectedStatus ||
      isOrderlineReasonNotValid() ||
      (previousSelectedStatus != null &&
        previousSelectedStatus?.code == selectedStatus?.code)
    );
  };
  const isOrderlineReasonNotValid = () => {
    return (
      selectedStatus?.code == ProjectOrderlineStatus.OnHold && !selectedReason
    );
  };
  const confirmButtonToolTip = () => {
    return isOrderlineReasonNotValid()
      ? "Please select a reason for putting the order line 'On Hold'."
      : "";
  };
  const renderFlexProjectForUpdate = () => {
    return (
      <>
        {flexProjectToUpdate.map((item) => item.flexProjectNumber).join(", ")}
      </>
    );
  };
  const renderFooter = (
    <>
      <hr className="modal-footer-hr" />
      <div className={`${classes["dialog-footer"]} ${classes["center-items"]}`}>
        <button
          className={`ul-button -medium ${classes["dialog-cancel-btn"]}`}
          onClick={() => {
            hideModal();
          }}
        >
          CANCEL
        </button>
        <button
          className={`ul-button -medium ${classes["dialog-save-btn"]} confirm-button-target`}
          disabled={isConfirmButtonDisabled()}
          onClick={confirmSave}
        >
          CONFIRM
        </button>
        <Tooltip
          target={`.confirm-button-target`}
          className="custom-tooltip"
          my="left top"
          at="left bottom"
          showOnDisabled={true}
        >
          {confirmButtonToolTip()}
        </Tooltip>
      </div>
    </>
  );
  const hideModal = () => {
    setIsOnHoldReasonShow(false);
    setSelectedReason(null);
    setSelectedStatus(null);
    setPreviousSelectedStatus(null);
    setSelectedFlexProjectReason(null);
    setFlexProjectToUpdate(new Array());
    setIsOnHoldFlexProjectReasonShow(false);
    props.closeModal();
  };
  return (
    <>
      <Dialog
        className={classes["custom-dialog"]}
        header={`Change Order Line Status`}
        footer={renderFooter}
        draggable={false}
        visible={props.visible}
        onHide={hideModal}
        style={{ width: "30vw" }}
      >
        <hr className="modal-header-hr" />
        <div className={classes["body-container"]}>
          <div
            style={{
              display: isSaving || isLoading ? "none" : "",
            }}
          >
            {renderBody()}
          </div>
          {(isSaving || isLoading) && (
            <div className={classes["loading-div"]}>
              <div>
                {isSaving && `Saving`} {isLoading && `Loading`} ......
              </div>
              <div>
                <svg className="ul-progressCircle -medium">
                  <circle
                    className="ul-progressCircle__outer"
                    cx="50%"
                    cy="50%"
                    r="35%"
                  />
                  <circle
                    className="ul-progressCircle__inner"
                    cx="50%"
                    cy="50%"
                    r="35%"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </Dialog>
      {isConfirmDialogShow && (
        <ProjectOrderlineStatusConfirmDialog
          isConfirmDialogShow={isConfirmDialogShow}
          setIsConfirmDialogShow={setIsConfirmDialogShow}
          messageText={confirmDialogMessageText}
          saveOrderline={() => {
            save(false);
          }}
          saveOrderlineAndFlexProject={() => {
            save(true);
          }}
          isMultipleLines={props.selectedRows.length > 1}
        ></ProjectOrderlineStatusConfirmDialog>
      )}
    </>
  );
};
export default ProjectOrderlineStatusEditDialog;
