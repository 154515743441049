import classes from "../GpiCopyFormModal.module.scss";

const ProductDetails = ({
  fieldValue,
  getProductTypes,
  getModelVariants,
  getOperationClasses,
}) => {
  return (
    <div className={classes["details-content-container"]}>
      <div className={classes["details-field"]}>
        Product Types:{" "}
        <span>
          {fieldValue?.gPIProductType?.productTypes
            ?.map((x) => x.hierarchyValue)
            ?.join(", ")}
        </span>
      </div>

      <div className={classes["details-field"]}>
        Product Name: <span>{fieldValue?.gPIProductType?.productName}</span>
      </div>

      <div className={classes["details-header"]}>Models</div>
      <div className={classes["details-field"]}>
        Base Model:{" "}
        <span>{fieldValue?.gPIProductType?.baseModel?.modelName}</span>
      </div>

      <div className={classes["details-field"]}>
        Model Variants:{" "}
        <span>
          {getModelVariants(fieldValue?.gPIProductType?.modelVariants)}
        </span>
      </div>

      <br />
      <div className={classes["details-field"]}>
        Marketing Name: <span>{fieldValue?.gPIProductType?.marketingName}</span>
      </div>

      <div className={classes["details-field"]}>
        Brand or Trademark:{" "}
        <span>{fieldValue?.gPIProductType?.brandOrTrademark}</span>
      </div>

      <div className={classes["details-field"]}>
        Uploaded: brand or trademark:{" "}
        {fieldValue?.gPIProductType?.brandOrTrademarkAttachments.map((data) => {
          return (
            <div className={classes["details-field"]}>
              {data.attachment.filename}
            </div>
          );
        })}
      </div>

      <br />
      <div className={classes["details-field"]}>
        Product Description:{" "}
        <span>{fieldValue?.gPIProductType?.productDescription}</span>
      </div>

      {/* VOLTAGE DETAILS */}
      <br></br>
      <div className={classes["details-field"]}>
        Rating Voltage:{" "}
        <span>
          {fieldValue?.gPIVoltageDetails?.voltageType &&
            fieldValue?.gPIVoltageDetails?.voltageType + " Voltage"}
        </span>
      </div>
      <div className={classes["details-field"]}>
        {fieldValue?.gPIVoltageDetails?.productRatings}
      </div>

      {fieldValue?.gPIVoltageDetails?.voltageType === "DC" &&
        fieldValue?.gPIVoltageDetails?.connectsToMainVoltage && (
          <>
            <div className={classes["details-field"]}>
              {fieldValue?.gPIVoltageDetails?.connectsToMainVoltage &&
                "Product connects to mains voltage via an external power supply."}
            </div>
            <div className={classes["details-field"]}>
              {fieldValue?.gPIVoltageDetails
                ?.externalPowerSupplyWillBeShipped &&
                "External power supply will be shipped in the box with the end product."}
            </div>
          </>
        )}

      {fieldValue?.gPIVoltageDetails?.voltageType === "DC" &&
        fieldValue?.gPIVoltageDetails?.containsBattery && (
          <>
            <div className={classes["details-field"]}>
              Product contains a Battery:{" "}
              <span>
                {fieldValue?.gPIVoltageDetails?.batteryType === "Other"
                  ? fieldValue?.gPIVoltageDetails?.otherBatteryType
                  : fieldValue?.gPIVoltageDetails?.batteryType}
              </span>
            </div>

            <div className={classes["details-field"]}>
              {fieldValue?.gPIVoltageDetails?.isBatteryUserReplaceable &&
                "User replaceable battery."}
            </div>

            <div className={classes["details-field"]}>
              {fieldValue?.gPIVoltageDetails?.willBatteryBeShipped &&
                "Battery will be shipped with the end product."}
            </div>
          </>
        )}

      {fieldValue?.gPIVoltageDetails?.voltageType === "AC" && (
        <div className={classes["details-field"]}>
          Rated (Frequency):{" "}
          <span>{fieldValue?.gPIVoltageDetails?.ratedFrequency}</span>
        </div>
      )}

      <div className={classes["details-field"]}>
        Rated (Current):{" "}
        <span>{fieldValue?.gPIVoltageDetails?.ratedCurrent}</span>
      </div>

      <div className={classes["details-field"]}>
        Operating Temperature Range:{" "}
        <span>{fieldValue?.gPIVoltageDetails?.operatingTemperatureRange}</span>
      </div>

      <div className={classes["details-field"]}>
        Operation Class:{" "}
        <span>{getOperationClasses(fieldValue?.gPIVoltageDetails)}</span>
      </div>

      <div className={classes["details-field"]}>
        Harmonized Shipping (HS) Code:{" "}
        <span>{fieldValue?.gPIVoltageDetails?.harmonizedShippingCode}</span>
      </div>

      <div className={classes["details-field"]}>
        Product Classification:{" "}
        <span>
          {fieldValue?.gPIVoltageDetails?.productClassificationClass1 &&
            "Class I Cord Connected Device - 3-Prong Plug (Excluding Power Supplies). "}
        </span>
        <span>
          {fieldValue?.gPIVoltageDetails?.productClassificationClass2 &&
            "Class II Cord Connected Device - 2-Prong Plug (Excluding Power Supplies). "}
        </span>
        <span>
          {fieldValue?.gPIVoltageDetails?.productClassificationClass3 &&
            "Class III - No Internal Power Supply. Laptops, etc., with Low Voltage DC Input. Products typically provided with external power supplies. "}
        </span>
      </div>
    </div>
  );
};

export default ProductDetails;
