import { Dialog } from "primereact/dialog";
import classes from "./ConfirmEmailAddressOnGpiCompleteDialog.module.scss";
import { useRef } from "react";

export interface IConfirmEmailAddressOnGpiCompleteDialogProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  OnChangeEmailAddress: (emailAddress: any) => void;
  customerEmailAddress: string;
  isShowCustomerEmailErrorMsg: boolean;
  customerEmailErrorMsg: string;
}

export default function ConfirmEmailAddressOnGpiCompleteDialog({
  visible,
  onCancel,
  onSubmit,
  OnChangeEmailAddress,
  customerEmailAddress,
  isShowCustomerEmailErrorMsg,
  customerEmailErrorMsg
}: IConfirmEmailAddressOnGpiCompleteDialogProps) {
  const emailAddInputRef = useRef<HTMLInputElement>(null);
  const header = () => {
    return (
      <>
        <h5>Confirm Email Address</h5>
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <div className={`text-center ${classes["footer-content"]}`}>
          <button
            className={`ul-button -app-primary ${classes["btn-yes"]}`}
            onClick={() => onSubmit()}
          >
            SUBMIT
          </button>

          <button className={`ul-button`} onClick={() => onCancel()}>
            CANCEL
          </button>
        </div>
      </>
    );
  };

  const handleOnModalShow = () => {
    if (visible && emailAddInputRef.current) {
      emailAddInputRef.current.focus();
      emailAddInputRef.current.select();
    }
  };

  const handleChangeEmailAddress = (e: any) => {
    const value = e.target.value;
    OnChangeEmailAddress(value);
  };

  return (
    <Dialog
      closable={true}
      draggable={false}
      resizable={false}
      header={header}
      footer={footer}
      onHide={() => onCancel()}
      onShow={handleOnModalShow}
      visible={visible}
      style={{ width: "500px" }}
      className={classes["container"]}
    >
      <p className={classes["body-content"]}>
        Please verify that the email address below is yours and is correct.
        Otherwise, please enter your email address.
      </p>
      <div>
        <label>Email Address</label>
        <input
          type="text"
          ref={emailAddInputRef}
          onChange={handleChangeEmailAddress}
          className={`${classes["ul-textbox"]} ${
            isShowCustomerEmailErrorMsg ? classes["error"] : ""
          } ul-textbox`}
          value={customerEmailAddress}
        ></input>
        {isShowCustomerEmailErrorMsg && (
          <label className={classes["error-message"]}>
            <i className="pi pi-times error-message"></i>
            {customerEmailErrorMsg}
          </label>
        )}
      </div>
    </Dialog>
  );
}
