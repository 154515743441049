import React, { useEffect, useState } from 'react'
import { E2EUserPaginatedModel } from '../../../../../shared/models/E2EUserPaginatedModel'
import HandlerType from '../../enums/handler-type';
import GMAWBUsersService from '../../../../../services/GMAWBUsersService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import classes from "./PmrBulkHandlerReassignmentDialog.module.scss";
import ProjectCollaboratorAssignmentDropdown from '../project-collaborator-assignment-dialog/project-collaborator-assignment-dropdown/ProjectCollaboratorAssignmentDropdown';
import { UpdateProjectLineHandlerRequestModel } from '../../../../../shared/models/UpdateProjectLineHandlerRequest.model';
import ProjectService from '../../../../../services/ProjectService';
import useToastr from '../../../../../hooks/useToastr';

interface PmrHandlerReassignmentDialogProps {
    projectId: string;
    orderLineIds: Array<string>;
    handlerType: HandlerType;
    visible: boolean;
    onClose: () => void;
    getList: () => void;
}

interface ProjectUserSource {
    currentSkip: number;
    currentTake: number;
    currentSearchPhrase: string;
    data: E2EUserPaginatedModel[];
  }
  
  const initDropdownValues: ProjectUserSource = {
    currentSkip: 0,
    currentTake: 5,
    currentSearchPhrase: "",
    data: [],
  };
const PmrBulkHandlerReassignmentDialog = (props: PmrHandlerReassignmentDialogProps) => {
  const [selectedHandler, setSelectedHandler] = useState<E2EUserPaginatedModel>();
  const [handlerDropdownOptions, setHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);
  const [isHandlerSaving, setIsHandlerSaving] = useState<boolean>(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState<boolean>(false);

  const handlerTypeName = props.handlerType === HandlerType.FlexHandler ? "FLEX Handler" : props.handlerType === HandlerType.GmaWBHandler ? "GMAWB Handler" : undefined;

  const { showSuccess, showError } = useToastr();

    useEffect(() => {
        setIsDropdownLoading(true);
        GMAWBUsersService.getPaginatedUsers(
          0,
          5,
          props.handlerType,
          false,
          ""
        ).then((response) => {
          let flexData: E2EUserPaginatedModel[] = structuredClone(response?.data);

          setHandlerDropdownOptions((current) => ({
            ...current,
            data: flexData,
          }));
    
          setIsDropdownLoading(false);
        });
    },[])


  const onHandleSave = () => {
    if (selectedHandler) {
      setIsHandlerSaving(true);
      let handlerTypeMessage = "";
      if(props.handlerType ===  HandlerType.FlexHandler)
          handlerTypeMessage = "FLEX Handler"

      if(props.handlerType === HandlerType.GmaWBHandler)
          handlerTypeMessage = "GMAWB Handler"

      const newHandler: UpdateProjectLineHandlerRequestModel = {
        e2eProjectId: props.projectId,
        e2eProjectLineId: props.orderLineIds,
        handlerInformation: selectedHandler,
        handlerType: props.handlerType,
      };

      ProjectService.updateProjectLineHandler(newHandler)
        .then((response) => {
          if (response.isSuccess) {
            props.getList();
            showSuccess(
              `${handlerTypeMessage}s Reassigned`,
              `${handlerTypeMessage}s for selected order lines successfully reassigned`
            );
            props.onClose();
          }
          if (!response.isSuccess) {
            showError(
              `${handlerTypeMessage} Reassignment Failed`,
              `Failed to reassign ${handlerTypeMessage}. Please try again or contact support if issue persists.`
            );
          }
        })
        .catch((error) => {
          console.error(error);
          showError(
            `${handlerTypeMessage} Reassignment Failed`,
            `Failed to reassign ${handlerTypeMessage}. Please try again or contact support if issue persists.`
          );
        })
        .finally(() => setIsHandlerSaving(false));
    }
    setIsHandlerSaving(true);

  }

  const onHandleCancel = () => {
    props.onClose();
  }
  
  const footer = (
    <div className={classes["footer"]}>
      <div>
        <Button
          className={`${classes["button-cancel"]}`}
          label="CANCEL"
          onClick={onHandleCancel}
          disabled={isDropdownLoading}
        />
      </div>
      <div className={`${classes["button-save-wrapper"]}`}>
        <Button
          className={`${classes["button-save"]}`}
          label="SAVE"
          onClick={onHandleSave}
          disabled={isDropdownLoading || isHandlerSaving || selectedHandler === undefined}
          loading={isHandlerSaving}
        />
      </div>
    </div>
  );
  
  const header = (
    <div className={`${classes["header"]}`}>
      <span>Assign {handlerTypeName}</span>
    </div>
  );

  return (
    <Dialog
      draggable={false}
      resizable={false}
      className={classes["pmr-handler-reassignment-dialog"]}
      header={header}
      footer={footer}
      visible={props.visible}
      style={{width: "30rem"}}
      modal
      onHide={onHandleCancel}
      contentClassName={classes["content"]}
    >
      {isDropdownLoading && (
        <div className={`${classes["spinner__container"]}`}>
          <ProgressSpinner
            strokeWidth="5"
            animationDuration="5s"
            className={`${classes["spinner"]}`}
          />
          <div>Loading Contributors.</div>
        </div>
      )}
      {!isDropdownLoading && (
        <div className={classes["dropdown"]}>
          <ProjectCollaboratorAssignmentDropdown
            value={selectedHandler}
            dropdownOptions={handlerDropdownOptions}
            setValue={setSelectedHandler}
            setDropdownOptions={setHandlerDropdownOptions}
            handlerType={props.handlerType}
            isGmaTcTask={false}
            isDisabled={isHandlerSaving}
          />
        </div>
        )
      }
    </Dialog>
  );
}

export default PmrBulkHandlerReassignmentDialog