const certificateCustomFilterColumns = [
  {
    id: "certificateScheme",
    value: "Certificate Scheme",
  },
  {
    id: "certificationBody",
    value: "Certification Body",
  },
  {
    id: "mark",
    value: "Mark",
  },
  {
    id: "partySiteNumber",
    value: "Product Owner Party Site Number",
  },
  {
    id: "ccn",
    value: "Scope/CCN",
  },
];

export default certificateCustomFilterColumns;
