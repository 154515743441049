import debounce from "lodash.debounce";
import { useCallback, useState } from "react";
import Refiners from "../../../components/refiners/Refiners";
import GeneralProductInformationService from "../../../services/GeneralProductInformationService";
import GPIFilterTypeEnum from "./../gpiFilterTypeEnum";
const GeneralProductInformationRefiners = ({
  onSelectedRefinersChange,
  selectedRefiners,
  modelRefinerState,
}) => {
  const [refiners, setRefiners] = useState([
    {
      field: "agents",
      label: "Agent Name",
      loading: false,
      options: [],
      filterType: GPIFilterTypeEnum.AgentName,
    },
    {
      field: "applicants",
      label: "Applicant Name",
      loading: false,
      options: [],
      filterType: GPIFilterTypeEnum.ApplicantName,
    },
    {
      field: "factories",
      label: "Factory Name",
      loading: false,
      options: [],
      filterType: GPIFilterTypeEnum.FactoryName,
    },
    {
      field: "creatorName",
      label: "GPI Creator Name",
      loading: false,
      options: [],
      filterType: GPIFilterTypeEnum.GPICreatorName,
    },
    {
      field: "formNames",
      label: "GPI Form Name",
      loading: false,
      options: [],
      filterType: GPIFilterTypeEnum.GPIFormName,
    },
    {
      field: "manufacturers",
      label: "Manufacturer Name",
      loading: false,
      options: [],
      filterType: GPIFilterTypeEnum.ManufacturerName,
    },
    {
      field: "models",
      label: "Model",
      loading: false,
      options: [],
      filterType: GPIFilterTypeEnum.Model,
    },
  ]);

  const updateRefiner = (refiner, updateFunc) => {
    setRefiners((currRefiners) =>
      currRefiners.map((r) => (refiner.field === r.field ? updateFunc(r) : r))
    );
  };

  const handleSelectedRefinerChange = (refiner, value) => {
    const newRefiners = { ...selectedRefiners, [refiner.field]: value };
    onSelectedRefinersChange(newRefiners);
  };

  const companyNameOptionsMapper = (company) => ({
    label: company.gPICompanyContactInfo.gPICompanyInfo.companyName,
  });

  const flatObjectMapper = (gpi, field) => ({
    label: gpi[field],
  });

  const modelVariantsMapper = (item) => ({
    label: item.modelVariant.modelName,
  });

  const isCompanyEntity = (entity) =>
    [
      "gPIAgentInfo",
      "gPIApplicantInfo",
      "gPIFactoryInfo",
      "gPIManufacturerInfo",
    ].includes(entity);

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  const handleSelectedRefinerRemove = (refiner) => {
    const newRefiners = { ...selectedRefiners, [refiner.field]: [] };

    onSelectedRefinersChange(newRefiners);
  };

  const handleSelectedRefinersRemove = () => {
    onSelectedRefinersChange({});
  };

  const handleRefinerFilterRemove = (refiner, removedFilterValue) => {
    const newRefiners = {
      ...selectedRefiners,
      [refiner.field]: selectedRefiners[refiner.field].filter(
        (v) => v !== removedFilterValue
      ),
    };

    onSelectedRefinersChange(newRefiners);
  };

  const handleRefinerFilter = async (refiner, searchPhrase) => {
    const res = await GeneralProductInformationService.getGPIListFilters({
      filterType: refiner.filterType,
      filterText: searchPhrase,
    });

    if (res.isSuccess) {
      updateRefiner(refiner, (f) => ({
        ...f,
        loading: false,
        options: res.data,
      }));
    }
  };

  const debouncedRefinerFilter = useCallback(
    debounce(handleRefinerFilter, 1000),
    []
  );

  return (
    <Refiners
      refiners={refiners}
      selectedRefiners={selectedRefiners}
      onSelectedRefinerChange={handleSelectedRefinerChange}
      onFilterRefiner={(refiner, searchPhrase) => {
        updateRefiner(refiner, (f) => ({ ...f, loading: true }));
        debouncedRefinerFilter(refiner, searchPhrase);
      }}
      onRemoveSelectedRefiner={handleSelectedRefinerRemove}
      onRemoveSelectedRefinerFilter={handleRefinerFilterRemove}
      onRemoveSelectedRefiners={handleSelectedRefinersRemove}
    />
  );
};

export default GeneralProductInformationRefiners;
