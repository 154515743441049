import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayPanel } from "primereact/overlaypanel";
import { forwardRef, useImperativeHandle, useRef } from "react";
import "./NewGridActionPopover.scss";

interface NewGridActionPopoverProps {
  actionList: any[];
  isDisable: boolean;
}

interface NewGridActionPopoverEvent {
  openPopUp: (event: any) => void;
  closePopUp: () => void;
}

const NewGridActionPopover = forwardRef<
  NewGridActionPopoverEvent,
  NewGridActionPopoverProps
>((props, ref) => {
  const op = useRef<any>(null);
  const { actionList, isDisable } = props;

  useImperativeHandle(ref, () => ({
    openPopUp(event) {
      event.stopPropagation();
      if (op.current?.toggle) op?.current?.toggle(event);
    },
    closePopUp() {
      if (!op.current) return;
      op.current.hide();
    },
  }));

  const onOverlayPanelShow = () => {
    op.current?.align();
  };

  return (
    <>
      <OverlayPanel
        className="grid-action-popover"
        ref={op}
        onShow={onOverlayPanelShow}
      >
        {actionList.map(function (item: any, index: number) {
          return (
            <div>
              <div
                key={`grid-action-popover-item-${index}`}
                className={`grid-action-popover-item ${
                  isDisable || item.isDisabled ? "disabled" : "active"
                }`}
                onClick={isDisable || item.isDisabled ? undefined : item.action}
                title={item.isShowTooltip ? item.tooltip : ""}
              >
                <span className="icon-image">
                  {item.icon && (
                    <span>
                      <FontAwesomeIcon icon={item.icon} />
                    </span>
                  )}
                  {item.imageSource && (
                    <span>
                      <img src={item.imageSource} />
                    </span>
                  )}
                </span>

                <span className="action-text-display">{item.textDisplay}</span>
              </div>
              <div>{item.hasLineBreak && <hr className="line-break" />}</div>
            </div>
          );
        })}
      </OverlayPanel>
    </>
  );
});

export default NewGridActionPopover;
