import { Dialog } from "primereact/dialog";
import classes from "./SplashPage.module.scss";

const SplashPage = ({ visible, onHide }) => {
  const header = () => {
    return (
      <>
        <h4 className={classes["modal-header"]}>Important Reminder!</h4>
        <hr className={classes["modal-header-hr"]} />
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <hr className={classes["modal-footer-hr"]} />
        <div className={`text-center ${classes['footer-content']}`}>
          <button
            className={`ul-button -app-primary ${classes["btn-agree"]}`}
            onClick={() => onHide && onHide()}
          >
            I Agree
          </button>
        </div>
      </>
    );
  };

  return (
    <Dialog
      closable={false}
      draggable={false}
      resizable={false}
      header={header}
      footer={footer}
      onHide={() => onHide && onHide()}
      visible={visible}
      style={{ width: "630px" }}
      className={classes["container"]}
    >
      <p className={classes["body-content"]}>
        The following Information is being collected to enable the creation of
        the necessary documents to support the Global Market Access Project.
        Please ensure all information is accurate, as this information will be
        utilized for all communications and application forms.
        <br /> <br />
        Should you have any questions, please do not hesitate to contact your UL
        Project Handler.
      </p>
    </Dialog>
  );
};

export default SplashPage;
