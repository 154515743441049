import { E2EProjectLineCollaboratorModel } from "../../../../../shared/models/E2EProject.model";
import { formatDateToGanttChartDate } from "../../utils/DateHelper";
import classes from "./ProjectAvatar.module.scss";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
interface ProjectAvatarProps {
  AvatarList: Array<E2EProjectLineCollaboratorModel>;
}

export const ProjectAvatar = (props: ProjectAvatarProps) => {
  const colorPlaceHolder = [
    "#122C49",
    "#577E9E",
    "#BCE4F7",
    "#E5DDCA",
  ];

  const getNameInitials = (name: string) => {
    const avatarName = name.split(" ");
    return (avatarName[0][0] + avatarName[1][0]);
  }

  const renderList = props.AvatarList.map((avatar, index) => {
    const avatarInitials = getNameInitials(avatar.name);
    const color = ['e2e handler', 'task handler'].includes(avatar.collaboratorType.toLowerCase()) ? "#000000" : "#ffffff";
    const backgroundColor = avatar.collaboratorType.toLowerCase() === 'task handler'
      ? colorPlaceHolder[3]
      : avatar.collaboratorType.toLowerCase() === 'e2e handler'
        ? colorPlaceHolder[2]
        : avatar.collaboratorType.toLowerCase() === 'flex handler'
          ? colorPlaceHolder[1]
          : colorPlaceHolder[0];
    return (
      <div key={index + (avatar.id ?? "")}>
        {(index < 7) && (
          <span
          className={`${classes["icon"]} ${classes["circle"]}`}
          style={{
          color: color,
          fontWeight: '600',
          backgroundColor: backgroundColor,
          cursor: 'default'
          }}
          >
          {avatarInitials}
          </span>
        )}
      </div>
    );
  });

  const renderMoreList: JSX.Element =
     (
      <span
      key={"more"}
      className={classes["more"]}
      >
      +{props.AvatarList.length - 7}
      </span>
    )
  ;

  const tooltipContent = () => {
    return <>
    <span className={`ul-grid__row -wrap ${classes["tooltipScroll"]}`}>
      {props.AvatarList.map((avatar,index) => {
          const avatarInitials = getNameInitials(avatar.name);
          const color = ['e2e handler', 'task handler'].includes(avatar.collaboratorType.toLowerCase()) ? "#000000" : "#ffffff";
          const backgroundColor = avatar.collaboratorType.toLowerCase() === 'task handler'
          ? colorPlaceHolder[3]
          : avatar.collaboratorType.toLowerCase() === 'e2e handler'
            ? colorPlaceHolder[2]
            : avatar.collaboratorType.toLowerCase() === 'flex handler'
              ? colorPlaceHolder[1]
              : colorPlaceHolder[0];
          const verticalLineBgColor = (props.AvatarList.length - 1) !== index && index !== 0 ? 'gray' : 'white'
          const verticalLineBorder = (props.AvatarList.length - 1) !== index && index !== 0 ? '1.5px solid gray' : '1.5px solid white'
          let avatarDetails = "";
          if('CEM / Order Owner'.toLocaleLowerCase() === avatar.collaboratorType.toLocaleLowerCase()) 
            avatarDetails = avatar.collaboratorType;
          else if ('Flex Handler'.toLocaleLowerCase() === avatar.collaboratorType.toLocaleLowerCase())
            avatarDetails = avatar.collaboratorType + " | " + formatDateToGanttChartDate(avatar.assignedDate);
          else
            avatarDetails = "Assigned as " + avatar.collaboratorType + " | " + formatDateToGanttChartDate(avatar.assignedDate);
          
          return (
            <div key={avatar.id} className={`ul-grid__column ${classes["tooltipContainer"]}`}>
              <div className={`ul-grid__row -wrap ${classes["wrapPosition"]}`}>
                <div className={`ul-grid__column -x30 ${classes["avatarInitialsContainer"]}`}>
                  <div
                  style={{
                    color: color,
                    backgroundColor: backgroundColor,
                  }}
                  className={`${classes["circle"]}`}
                  >
                    {avatarInitials}
                  </div>
                </div>
                <div className="ul-grid__column -x70">
                  <div className="ul-grid__row -wrap">
                    <div className="ul-grid__column">
                      <label className={`${classes["collaboratorName"]}`}>
                        {avatar.name}
                      </label>
                    </div>
                    <div className="ul-grid__column">
                      <label key={avatar.e2EProjectLineId + index} className={`${classes["handler"]}`}>
                        {avatarDetails}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr 
              className={`${classes["verticalLine"]}`}
              style={{
                backgroundColor: verticalLineBgColor, border: verticalLineBorder}}
              />
            </div>
          );
        })} 
      </span>
    </>
  };

  const renderListContent: JSX.Element = (
    <>
    <TooltipComponent className="tooltip-box" 
    width={'400px'} 
    content={tooltipContent}  
    showTipPointer={true}>
    <div className="ul-grid__row -wrap collaborator-list">
      {renderList}
      {(props.AvatarList.length > 7) && renderMoreList}
    </div>
    </TooltipComponent>
    </>
  );

  return (
  <div className={classes["wrapper"]}>
    <div className="ul-grid__column">
      <div className={`ul-grid__row -wrap`}>
        {renderListContent}
      </div>
    </div>
  </div>
  );
};
