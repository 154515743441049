import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmDialog } from "primereact/confirmdialog";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  customerInformationInitialState,
  selectGPICustomerInputValidationResult,
  selectIsReadOnly,
  selectLocalRepImporter,
  updateIsSaveDisabled,
  updateLocalRepImporters,
} from "../../../features/generalProductInformation/generalProductInformationSlice";
import useToastr from "../../../hooks/useToastr";
import GeneralProductInformationService from "../../../services/GeneralProductInformationService";
import PickList from "../../../shared/pick-list/PickList";
import FormContext from "../form/context/FormContext";
import classes from "./LocalRepImporter.module.scss";
import SchemeInformation from "./scheme-information/SchemeInformation";

const LocalRepImporter = ({
  showFormError,
  disableIntegration,
  isUserToProvideOnly,
  localRepSchemesList,
}) => {
  const { showSuccess } = useToastr();
  const dispatch = useDispatch();
  const localRepListValues = useSelector(selectLocalRepImporter);
  const [schemesList, setSchemesList] = useState([]);
  // const [currentSchemeSelection, setCurrentSchemeSelection] = useState();
  const isReadOnly = useSelector(selectIsReadOnly);

  const gPICustomerInputValidationResultValues = useSelector(
    selectGPICustomerInputValidationResult
  );

  const getSchemeErrorMessage = (scheme) => {
    return gPICustomerInputValidationResultValues?.errors?.find(
      (x) => x.field === scheme
    )?.errorMessage;
  };

  const handleChangeLocalRepList = (newScheme) => {
    let localImporters = JSON.parse(JSON.stringify([...localRepListValues]));

    if (!localImporters.find((x) => x.schemeId === newScheme.schemeId)) {
      localImporters.push(newScheme);
    }

    const newSchemes = localImporters.map((currentScheme, i) => {
      if (currentScheme.schemeId === newScheme.schemeId) {
        return newScheme;
      }

      return currentScheme;
    });

    dispatch(updateLocalRepImporters(newSchemes));
  };

  useEffect(() => {
    if (!disableIntegration) setSchemesList(localRepSchemesList);
  }, [disableIntegration]);

  const onAddScheme = async (scheme) => {
    if (!localRepListValues.find((x) => x.schemeId === scheme.schemeId)) {
      const newItem = {
        ...schemesList.find((x) => x.schemeId === scheme.schemeId),
      };

      newItem.schemeId = scheme.schemeId;
      newItem.schemeName = newItem.certScheme;
      newItem.uLToProvide = null;

      if (newItem.hasMultipleRepresentative) {
        newItem.uLToProvide = newItem.ulToProvide;
      }

      newItem.representatives = newItem.ulToProvide
        ? []
        : [
            {
              partySiteNumber: "",
              companyName: "",
              streetAddress: "",
              city: "",
              country: "",
              zipCode: "",
              stateOrProvince: "",
              contactPerson: "",
              phone: "",
              fax: "",
              title: "",
              email: "",
              website: "",
            },
          ];

      newItem.activeRepIndexes = [];
      handleChangeLocalRepList(newItem);

      if (!isReadOnly) {
        dispatch(updateIsSaveDisabled(false));
      }
    }
  };

  const onAddRepresentative = async (scheme) => {
    let newScheme = JSON.parse(JSON.stringify(scheme));

    let newRep = {
      partySiteNumber: "",
      companyName: "",
      streetAddress: "",
      city: "",
      country: "",
      zipCode: "",
      stateOrProvince: "",
      contactPerson: "",
      phone: "",
      fax: "",
      title: "",
      email: "",
      website: "",
    };

    newScheme.representatives.push(newRep);

    updateScheme(newScheme);
  };

  const onRemoveScheme = async (scheme) => {
    confirmDialog({
      message:
        "Are you sure you want to remove this scheme? The associated representative information (if any) will also be removed.",
      header: "Delete Scheme",
      className: classes["delete-confirmation-dialog"],
      accept: () => {
        const newSchemes = localRepListValues.filter(
          (x) => x.schemeId !== scheme.schemeId
        );

        dispatch(updateLocalRepImporters(newSchemes));
        showSuccess("Success!", "Scheme removed successfully.");

        if (!isReadOnly) {
          dispatch(updateIsSaveDisabled(false));
        }
      },
    });
  };

  const onRemoveRepresentative = async (scheme, repIndex) => {
    let newScheme = JSON.parse(JSON.stringify(scheme));

    newScheme.representatives = newScheme.representatives.filter(
      (val, i, arr) => {
        return i !== repIndex;
      }
    );

    updateScheme(newScheme);
    showSuccess("Representative information removed successfully.");
  };

  const onChangeRepresentative = async (scheme, value, representativeIndex) => {
    const newLocalRepList = localRepListValues.map((localRep) => {
      const newLocalRep = { ...localRep };

      newLocalRep.representatives = newLocalRep.representatives.map((r) => ({
        ...r,

        // If changed representative is set as source value and selected by other representative,
        // clear that selection.
        source:
          r.source?.schemeId === scheme.schemeId &&
          r.source?.representativeIndex === representativeIndex
            ? undefined
            : r.source,
      }));

      if (newLocalRep.schemeId === scheme.schemeId)
        newLocalRep.representatives[representativeIndex] = value;

      return newLocalRep;
    });

    dispatch(updateLocalRepImporters(newLocalRepList));
  };

  const updateScheme = async (updatedScheme) => {
    localRepListValues.forEach((localRep) => {
      if (localRep.schemeId === updatedScheme.schemeId)
        handleChangeLocalRepList(updatedScheme);
    });
  };

  const onProviderChange = async (e, scheme) => {
    let updatedScheme = JSON.parse(JSON.stringify(scheme));

    updatedScheme.uLToProvide = !updatedScheme.uLToProvide;

    if (updatedScheme.uLToProvide) updatedScheme.representatives = [];
    else updatedScheme.representatives = [customerInformationInitialState];

    updateScheme(updatedScheme);

    if (!isReadOnly) {
      dispatch(updateIsSaveDisabled(false));
    }
  };

  const schemesSourceListItemTemplate = (scheme) => {
    return <div title={scheme.certScheme}>{scheme.certScheme}</div>;
  };

  const selectedItemTemplate = (scheme) => {
    const schemeName =
      scheme.schemeName ??
      schemesList?.find((s) => s.schemeId === scheme.schemeId)?.certScheme;

    const error = getSchemeErrorMessage(schemeName);

    return (
      <div title={schemeName}>
        {schemeName}{" "}
        {error && (
          <FontAwesomeIcon
            className={classes["scheme-error__icon"]}
            icon={faExclamationCircle}
          />
        )}
      </div>
    );
  };

  const localRepList = localRepListValues.map((l) => {
    const scheme = schemesList.find((s) => s.schemeId === l.schemeId);

    return {
      ...l,
      ...(scheme ? scheme : {}),
    };
  });

  const hasLocalRepError = localRepList.some((x) =>
    getSchemeErrorMessage(x.certScheme)
  );

  const errorMessage = hasLocalRepError
    ? "Selected Certificate Scheme(s) does not match/exist in the list. Please remove from the list(s)"
    : "";

  return (
    <div className={classes["container"]}>
      <FormContext.Provider value={{ showFormError }}>
        {!disableIntegration && (
          <div className="form-section">
            <div className="form-section-header">
              <h2 className="step-title">Scheme Selection</h2>
              <div className={`${classes["header-items"]}`}>
                <div className={classes["add-scheme-container"]}>
                  <h4>Please add the certificate scheme/s for processing</h4>

                  <div className="d-flex justify-content-center">
                    <PickList
                      labelField="certScheme"
                      emptyMessage="No certificate scheme(s) selected"
                      containerClassName={classes["pick-list-container"]}
                      keyField={"schemeId"}
                      onAddItem={onAddScheme}
                      onRemoveItem={onRemoveScheme}
                      sourceListItemTemplate={schemesSourceListItemTemplate}
                      sourceList={schemesList}
                      selectedItemTemplate={selectedItemTemplate}
                      selectedItems={localRepListValues}
                      errorMessage={errorMessage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <h2 className="step-title">Local Rep Importer</h2>

        <SchemeInformation
          schemesList={schemesList}
          schemes={localRepList}
          onChangeRepresentative={onChangeRepresentative}
          onRemoveScheme={onRemoveScheme}
          onProviderChange={onProviderChange}
          onChange={handleChangeLocalRepList}
          onAddRepresentative={onAddRepresentative}
          onRemoveRepresentative={onRemoveRepresentative}
          isReadOnly={isReadOnly}
          disableIntegration={disableIntegration}
          isUserToProvideOnly={isUserToProvideOnly}
        />
      </FormContext.Provider>
    </div>
  );
};

export default LocalRepImporter;
