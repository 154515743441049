import moment from "moment";

export const formatDateToGanttChartDate = (
  sourceDate: Date | string | undefined | null
): string => {
  if (sourceDate === null || sourceDate === undefined)
    return moment().format("DD MMM YYYY");

  return moment(sourceDate).format("DD MMM YYYY");
};

export const formatDate = (
  sourceDate: Date | string | undefined | null
): string => {
  if (sourceDate === null || sourceDate === undefined)
    return moment().format("DD MMM YYYY");

  return moment(sourceDate).format("DD MMM YYYY");
};

export const formatDateWithTimezone = (
  sourceDate: Date | string | undefined | null,
  format: string = "DD MMM yyyy"
): string | null => {
  if (sourceDate === null || sourceDate === undefined) return null;
  if (sourceDate?.toString().indexOf("T") > -1)
    sourceDate = sourceDate.toString().split("T")[0];

  return moment(sourceDate).format(format);
};

export const enumerateDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const dates = [];

  const currDate = moment(startDate).startOf("day");
  const lastDate = moment(endDate).startOf("day");

  while (currDate.add(1, "days").diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};

export const getUniqueDates = (dates: Date[]) => {
  return dates.filter(
    (date, i, self) =>
      self.findIndex((d) => d.getTime() === date.getTime()) === i
  );
};

export const addBusinessDays = (originalDate: Date, numDaysToAdd: number) => {
  const Sunday = 0;
  const Saturday = 6;
  let daysRemaining = numDaysToAdd;

  const newDate = moment(originalDate);

  while (daysRemaining > 0) {
    newDate.add(1, "days");
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysRemaining--;
    }
  }

  return newDate.toDate();
};
