import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import classes from "./DeliveryPathAndAssociatedSchemeModal.module.scss";
export type DeliveryPathAndAssociatedSchemeModalProps = {
  content?: string[];
  header?: string;
  op: any;
};

const DeliveryPathAndAssociatedSchemeModal = (
  props: DeliveryPathAndAssociatedSchemeModalProps
) => {
  return (
    <div>
      <OverlayPanel
        ref={props.op}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "450px" }}
        showCloseIcon
        className={`${classes["overlayCloseButton"]}`}
      >
        <div className={`ul-grid__row -wrap ${classes["overlayContent"]}`}>
          <div className="ul-grid__column">
            <label className={`${classes["header"]}`}>{props.header}</label>
          </div>
          <div className={`ul-grid__column`}>
            <ul
              className={`ul-grid__column ${classes["divScroll"]} ${classes["ul"]}`}
            >
              {props.content?.map((item: string) => {
                return (
                  <li
                    key={item}
                    className={`${classes["itemLabel"]}`}
                    title={item}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default DeliveryPathAndAssociatedSchemeModal;
