import FormContext from "../form/context/FormContext";
import ProductType from "./product-type/ProductType";
import RadioDetails from "./radio-details/RadioDetails";
import VoltageDetails from "./voltage-details/VoltageDetails";

const ProductDetails = ({ showFormError, isReadOnly, disableIntegration }) => {
  return (
    <>
      <FormContext.Provider value={{ showFormError, isReadOnly }}>
        <h2 className="step-title">Product Details</h2>
        <ProductType disableIntegration={disableIntegration} />
        <VoltageDetails />
        <RadioDetails />
      </FormContext.Provider>
    </>
  );
};

export default ProductDetails;
