import React, { useEffect } from "react";
import classes from "./ProjectDetailsInfo.module.scss";
import { ProjectDetailInfoItem } from "../ProjectDetails";
import { Skeleton } from "primereact/skeleton";
interface ProjectDetailsInfoProps {
  header: string;
  isLoading: boolean;
  fieldKeyValue?: ProjectDetailInfoItem[];
  isRightPanelHidden: boolean;
  flexProjectId?: string;
}

const ProjectDetailsInfo = (props: ProjectDetailsInfoProps) => {
  const agentInfoUrl = `${process.env.REACT_APP_FLEX_PROJECT_AGENT_INFO_URL}${props.flexProjectId}`;
  return (
    <div className={`${classes["project-details-info__container"]}`}>
      <h4 className={`${classes["project-details-info-header"]}`}>
        {props.header}
      </h4>

      {props.header === "Agent Information" ? (
        <p className={classes["agent-information-note"]}>
          {`Note: Agent Information details cannot be pulled from FLEX as of this time.`}
          <br />
          {`Click this `}
          <a href={agentInfoUrl} target="_blank" rel="noopener noreferrer">
            link
          </a>
          {` to navigate to the FLEX Project's Agent Information page.`}
        </p>
      ) : (
        props.fieldKeyValue?.map((field, index) => {
          return (
            <div
              key={`${field}-${index}`}
              className={
                props.isRightPanelHidden
                  ? `${classes["project-details-info-item-collapsed"]}`
                  : `${classes["project-details-info-item"]}`
              }
            >
              <div className={`${classes["project-details-info-item-key"]}`}>
                {field.key}
              </div>
              <div className={`${classes["project-details-info-item-value"]}`}>
                {props.isLoading ? (
                  <Skeleton width="100%" height="1rem" />
                ) : (
                  field.value
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default ProjectDetailsInfo;
