import {
  faClose,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import { defaultErrorMessage } from "../../../utils/constants/gpi-form-constants";
import classes from "./DataDiscrepancy.module.scss";

interface DataDiscrepancyProps {
  currentValue: string | React.ReactNode;
  previousValue: string | React.ReactNode;
  wbValue: string | React.ReactNode;
  onKeep?: (
    isFromExistingCertificate: boolean,
    currentValue: string | React.ReactNode,
    wbValue: string | React.ReactNode,
    previousValue: string | React.ReactNode
  ) => void;
  onReplace: (
    isFromExistingCertificate: boolean,
    currentValue: string | React.ReactNode,
    wbValue: string | React.ReactNode,
    previousValue: string | React.ReactNode
  ) => void;
  isVisible: boolean;
  errorMessage: string | React.ReactNode;
  updateMessage: string;
  confirmationMessage: string;
  replaceMessage: string;
  keepMessage: string;
  isFromExistingCertificate: boolean;
}

const DataDiscrepancy = ({
  currentValue,
  previousValue,
  wbValue,
  onKeep,
  onReplace,
  isVisible,
  errorMessage,
  updateMessage,
  confirmationMessage,
  replaceMessage,
  keepMessage,
  isFromExistingCertificate,
}: DataDiscrepancyProps) => {
  const overlayRef = useRef<OverlayPanel>(null);
  if (isFromExistingCertificate && (currentValue === wbValue || !isVisible))
    return null;

  if (
    !isFromExistingCertificate &&
    (currentValue === previousValue || !isVisible)
  )
    return null;

  const handleBtnViewDetailsClick = (e: any) => {
    overlayRef.current?.toggle(e);
  };

  const handleBtnCloseDetailsClick = () => {
    overlayRef.current?.hide();
  };

  const handleBtnKeepClick = () => {
    overlayRef.current?.hide();
    onKeep &&
      onKeep(isFromExistingCertificate, currentValue, wbValue, previousValue);
  };

  const handleBtnReplaceClick = () => {
    overlayRef.current?.hide();
    onReplace(isFromExistingCertificate, currentValue, wbValue, previousValue);
  };

  return (
    <>
      <div className={classes["container"]}>
        <button
          onClick={handleBtnViewDetailsClick}
          className={classes["btn-view-details"]}
        >
          <FontAwesomeIcon
            style={{ marginLeft: "-5px" }}
            className={classes["exclamation-icon"]}
            icon={faExclamationCircle}
          />
        </button>

        {errorMessage === undefined ? defaultErrorMessage : errorMessage}
      </div>

      <OverlayPanel
        className={classes["details-overlay-panel"]}
        ref={overlayRef}
      >
        <div className={classes["overlay-panel-title"]}>
          <div className={classes["values-container"]}>
            <div className={classes["title__value"]}>
              Current: {currentValue}
            </div>
            {isFromExistingCertificate && (
              <div className={classes["title__value"]}>WB Value: {wbValue}</div>
            )}
            {!isFromExistingCertificate && (
              <div className={classes["title__value"]}>
                Previous: {previousValue}
              </div>
            )}
          </div>

          <div>
            <button
              onClick={handleBtnCloseDetailsClick}
              className={classes["btn-close-overlay"]}
            >
              <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
            </button>
          </div>
        </div>

        <div className={classes["overlay-panel-body"]}>
          {updateMessage}
          <br />
          <br />
          {confirmationMessage}
          <br />
          <ul>
            <li>{replaceMessage}</li>
            <li>{keepMessage}</li>
          </ul>
        </div>

        <div className={classes["overlay-panel-footer"]}>
          <button
            onClick={handleBtnReplaceClick}
            className={classes["btn-replace"]}
          >
            Replace
          </button>
          <button onClick={handleBtnKeepClick} className={classes["btn-keep"]}>
            Keep
          </button>
        </div>
      </OverlayPanel>
    </>
  );
};

export default DataDiscrepancy;
