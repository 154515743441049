import { AxiosError } from "axios";
import { Password } from 'primereact/password';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import CustomerService from "../../services/CustomerService";
import GpiAuthService from "../../services/GpiAuthService";
import classes from "./CustomerViewOtpAuthenticator.module.scss";

export interface ICustomerViewOtpAuthenticatorProps {}

export default function CustomerViewOtpAuthenticator(
  props: ICustomerViewOtpAuthenticatorProps
) {
  const [errorMessage, setErrorMessage] = useState("");
  const [enteredOTP, setEnteredOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const gpiId = params.id;
  const [currentStatusCode, setCurrentStatusCode] = useState(0);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    CustomerService.validateOtp(gpiId, enteredOTP)
      .then((result) => {
        sessionStorage.setItem("gpi_token", result.message);
        setErrorMessage("");

        const { gpiId } = GpiAuthService.getGpiAuthInfo();
        history.push("/general-product-information?id=" + gpiId);
      })
      .catch((ex: AxiosError) => {
        let newErrorMessage = "";
        setCurrentStatusCode(ex.response?.status || 0);

        if (ex.response?.status === 410)
          newErrorMessage =
            "Your password has expired. Please contact your UL Project Handler for a new password.";
        else if (ex.response?.status === 401)
          newErrorMessage = "Incorrect password. Please try again.";
        else
          newErrorMessage =
            "There is an error processing your request. Please try again later.";

        setErrorMessage(newErrorMessage);
        setLoading(false);
      });
  };

  const isContinueDisabled =
    currentStatusCode === 410 || !enteredOTP || loading;

  return (
    <div className={classes["container"]}>
      <div>
        <div className={classes["header"]}>
          <h4>General Product Information</h4>
        </div>

        <div className={classes["content"]}>
          <h5 className={classes["title"]}>OTP Verification</h5>

          <p>Please type the password sent to your email.</p>

          <form onSubmit={handleSubmit}>
            <label className={classes["label-for-password"]}>Password</label>
            <Password
              feedback={false}
              toggleMask={true}
              disabled={loading}
              value={enteredOTP}
              onChange={(e) => setEnteredOTP(e.target.value)}
              inputClassName={`${errorMessage ? classes["error"] : ""}`}
              type="password"
            />

            {errorMessage && (
              <label className={classes["error-message"]}>
                {" "}
                <span className="material-icons">close</span>
                {errorMessage}
              </label>
            )}

            <button
              disabled={isContinueDisabled}
              type="submit"
              className={classes["btn-continue"]}
            >
              CONTINUE
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
