import { ResultModel } from "../models/result.model";
import { EditMilestoneDateModel } from "../shared/models/service-models/EditMilestoneDate.model";
import { EditMilestoneDateResultModel } from "../shared/models/service-models/EditMilestoneDateResult.model";
import RequestFactory from "./RequestFactory";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

const MilestoneDateService = {
  getMilestoneMinimumDate: async (
    e2eProjectLineMilestoneId: string,
    isStartDate: boolean
  ) =>
    (await RequestFactory.post(
      GMAE2EURL,
      `/api/milestone-date/get-milestone-minimum-date?e2eProjectLineMilestoneId=${e2eProjectLineMilestoneId}&isStartDate=${isStartDate}`
    )) as Promise<ResultModel<Date | null>>,

  editDate: async (model: EditMilestoneDateModel) =>
    (await RequestFactory.post(
      GMAE2EURL,
      `/api/milestone-date/edit-date`,
      model
    )) as Promise<ResultModel<EditMilestoneDateResultModel>>,
};

export default MilestoneDateService;
