import { GridColumnModel } from "./project-work-queue-grid-columns";

export const compareDeliveryPathGridColumns: GridColumnModel[] = [
  {
    id: "country",
    value: "Country",
    selected: false,
    orderBy: "", 
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 180,
    orderNo: 1
  },
  {
    id: "subcontractor",
    value: "Path/Subcon Name",
    selected: false,
    orderBy: "", 
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 180,
    orderNo: 1
  },
  {
    id: "leadTimeAgentNumber",
    value: "Lead Time - Agent (weeks)",
    selected: false,
    orderBy: "", 
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 180,
    orderNo: 1
  },
  {
    id: "leadType",
    value: "Lead Time Type",
    selected: false,
    orderBy: "", 
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 180,
    orderNo: 1
  },
  {
    id: "basePrice",
    value: "Base Price",
    selected: false,
    orderBy: "", 
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 180,
    orderNo: 1
  },
  {
    id: "currency",
    value: "Currency",
    selected: false,
    orderBy: "", 
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 180,
    orderNo: 1
  },
  {
    id: "listValidity",
    value: "Certificate Validity",
    selected: false,
    orderBy: "", 
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 180,
    orderNo: 1
  },
]