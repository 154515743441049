import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "primereact/badge";

import "./DynamicFieldButtons.scss"

const DynamicFieldButtons = (props) => {
  const {
    onAddDynamicFieldMultiple,
    onRemoveDynamicFieldMultiple,
    rowLength,
    rowIndex,
  } = props;
  return (
    <div className="ul-grid__column -x30">
      {rowLength > 1 && (
        <span
          className="cursor-pointer dynamic-fd-add-remove-btn-span"
          onClick={onRemoveDynamicFieldMultiple}
        >
          <Badge
            value={<FontAwesomeIcon icon={faMinus} />}
            style={{
              backgroundColor: "transparent",
              color: "#ff5050",
              border: "solid 1px #ff5050",
              borderRadius: "50%",
            }}
            size="large"
          ></Badge>
        </span>
      )}

      {rowLength === rowIndex + 1 && (
        <span className="cursor-pointer dynamic-fd-add-remove-btn-span" onClick={onAddDynamicFieldMultiple}>
          <Badge
            value={<FontAwesomeIcon icon={faPlus} />}
            style={{
              backgroundColor: "transparent",
              color: "#94beec",
              border: "solid 1px #94beec",
              borderRadius: "50%",
            }}
            size="large"
          ></Badge>
        </span>
      )}
    </div>
  );
};

export default DynamicFieldButtons;
