import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCompressArrowsAlt, faExpandArrowsAlt
} from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import Toast from "./components/Toast";
import AuthorizedContent from "./layout/AuthorizedContent";
import CustomerView from "./layout/CustomerView";

library.add(faExpandArrowsAlt, faCompressArrowsAlt);

const isCustomerView = process.env.REACT_APP_IS_CUSTOMER_VIEW === "true";

const App = () => {
  return (
    <Router>
      <Toast />
      {isCustomerView && <CustomerView />}
      {!isCustomerView && <AuthorizedContent />}
    </Router>
  );
};

export default App;
