import React from "react";
import classes from "./ProjectDetailsOrderLineGrid.module.scss";
import { FlexProjectOrderLineItem } from "../ProjectDetails";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export interface ProjectDetailsOrderLineGridProps {
  items: FlexProjectOrderLineItem[];
  isLoading: boolean;
}

const ProjectDetailsOrderLineGrid = (
  props: ProjectDetailsOrderLineGridProps
) => {
  return (
    <div className={`${classes["projectDetailsOrderLineGrid__container"]}`}>
    <DataTable className={`${classes["order-line-grid"]}`} value={props.items} columnResizeMode="fit" loading={props.isLoading} >
      <Column field="lineNumber" header="Line Number" style={{width:'30%'}}></Column>
      <Column field="name" header="Name" style={{width:'40%'}}></Column>
      <Column field="status" header="Order Line Status" style={{width:'30%'}}></Column>
    </DataTable>
    </div>
  );
};

export default ProjectDetailsOrderLineGrid;
