import { useDispatch } from "react-redux";
import {
  updateAllProjectLevels,
  updateTimeEntryModalSyncing,
} from "../features/projectManagement/projectManagementSlice";
import { SyncTimeEntryRequestModel } from "../pages/project-management-pmr/shared/interfaces/pmr-e2e-time-entry-sync-model";
import ProjectTimeEntryService from "../services/ProjectTimeEntryService";

const useTimeEntrySync = () => {
  const dispatch = useDispatch();
  const syncTimeEntryForEachTask = async (
    e2EProjectLineFlexTaskIds: string[]
  ) => {
    for (let taskId of e2EProjectLineFlexTaskIds) {
      syncTimeEntryTask(taskId);
    }
  };
  const syncTimeEntryTask = async (taskId: string) => {
    try {
      const syncModel: SyncTimeEntryRequestModel = {
        e2EProjectId: "00000000-0000-0000-0000-000000000000",
        e2EProjectLineFlexTaskIds: [taskId],
      };
      const resultSyncTimeEntryLevels =
        await ProjectTimeEntryService.syncTimeEntryLevels(syncModel);

      resultSyncTimeEntryLevels?.data.forEach(async (projectSync: any) => {
        const updatedFlexTasks = projectSync?.e2EProjectLineFlexTasks;
        const updateTaskLine = projectSync?.e2EProjectOrderlineLevels;
        const milestoneLevels = projectSync?.e2EProjectLineMilestones;
        const projectId = projectSync?.e2eProjectId;

        dispatch(
          updateAllProjectLevels({
            e2eProjectId: projectId,
            tasks: updatedFlexTasks,
            taskLines: updateTaskLine,
            milestoneLevels,
          })
        );
      });
    } catch (error) {}
  };
  const syncTimeEntryModal = async (
    e2EProjectLineFlexTaskIds: string[],
    startDate: string | undefined,
    endDate: string | undefined
  ) => {
    try {
      dispatch(updateTimeEntryModalSyncing(true));

      const syncModel: SyncTimeEntryRequestModel = {
        e2EProjectId: "00000000-0000-0000-0000-000000000000",
        e2EProjectLineFlexTaskIds,
        startDate: startDate,
        endDate: endDate,
      };

      const resultSyncTimeEntryLevels =
        await ProjectTimeEntryService.syncTimeEntryLevels(syncModel);

      resultSyncTimeEntryLevels?.data.forEach(async (projectSync: any) => {
        const updatedFlexTasks = projectSync?.e2EProjectLineFlexTasks;
        const updateTaskLine = projectSync?.e2EProjectOrderlineLevels;
        const milestoneLevels = projectSync?.e2EProjectLineMilestones;
        const projectId = projectSync?.e2eProjectId;

        dispatch(
          updateAllProjectLevels({
            e2eProjectId: projectId,
            tasks: updatedFlexTasks,
            taskLines: updateTaskLine,
            milestoneLevels,
          })
        );
      });
    } catch (error) {
      dispatch(updateTimeEntryModalSyncing(false));
      return;
    }
    dispatch(updateTimeEntryModalSyncing(false));
  };
  return {
    syncTimeEntryForEachTask,
    syncTimeEntryModal,
  };
};
export default useTimeEntrySync;
