import "./UploadFiles.scss";
import { FileUploader } from "react-drag-drop-files";

import { useState } from "react";

import { ProgressBar } from "primereact/progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faFile,
  faFileExcel

} from "@fortawesome/free-solid-svg-icons";
import CloseIcon from "../../../../../shared/close-icon/CloseIcon";
import classes from "./UploadFiles.module.scss";

const UploadFiles = (props) => {

  const [isUploading, setIsUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const { uploadedFiles, handleUpload,handleRemoveItem } = props;



  return (
    <div className="create-options-container">
      <span>&nbsp;</span>
      <div className="options-container">
        <div className="drag-and-drop-container">
          {isUploading && (
            <ProgressBar
              value={uploadPercentage}
              className="progress-bar"
              color="#28a745"
            />
          )}
          {!isUploading && (
            <FileUploader
              handleChange={handleUpload}
              name="file"
              children={
              <div className={classes["file-upload-children"]}>
                  <button className={classes["file-upload-children-button"]}>SELECT FILES</button>

                  <label className={classes["file-upload-children-label"]}>Drag files here to upload</label>
              </div>}
              classes="file-upload"
            />
          )}
        </div>
        <div className="vl"></div>



      </div>
      <div class="fileList">
        {uploadedFiles.map(function (item, index) {
          return <div class="box">
            <div className="icon">  <FontAwesomeIcon icon={faFileExcel} /></div>
            <div className="filename">
              {item.fileName}
              <span>File(s) successfully uploaded.</span>
            </div>
            <div className="exit"> <CloseIcon onCloseEvent={() => handleRemoveItem(item.fileName)}/></div>
          </div>;
        })}

      </div>
    </div>
  );
};


export default UploadFiles;
