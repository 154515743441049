import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    selectedSchemes: [],
    selectedDeliveryPaths: [],
    selectedApplicationTypes: [],
    selectedTechnologies: [],
  },
};

export const dkDropdownSlice = createSlice({
  name: "dkDropdown",
  initialState,
  reducers: {
    updateSelectedSchemes: (state, action) => {
      state.value.selectedSchemes = action.payload;
    },
    updateSelectedDeliveryPaths: (state, action) => {
      state.value.selectedDeliveryPaths = action.payload;
    },
    updateSelectedApplicationTypes: (state, action) => {
      state.value.selectedApplicationTypes = action.payload;
    },
    updateSelectedTechnologies: (state, action) => {
      state.value.selectedTechnologies = action.payload;
    },
  },
});

export const {
  updateSelectedSchemes,
  updateSelectedDeliveryPaths,
  updateSelectedApplicationTypes,
  updateSelectedTechnologies,
} = dkDropdownSlice.actions;

export const selectValues = (state) => state.dkDropdown.value;

export default dkDropdownSlice.reducer;
