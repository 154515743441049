import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import classes from "./Actions.module.scss";

const Actions = ({ status }) => {
  const modify = (
    <div className={classes.action}>
      <span className={`material-icons ${classes.icon}`}>auto_fix_normal</span>
      Modify
    </div>
  );

  const copy = (
    <div className={classes.action}>
      <span className={`material-icons ${classes.icon}`}>file_copy</span>
      Create Copy
    </div>
  );

  const withdraw = (
    <div className={classes.action}>
      <span className={`material-icons ${classes.icon}`}>archive</span>
      Withdraw
    </div>
  );

  const edit = (
    <div className={classes.action}>
      <FontAwesomeIcon
        icon={faPenToSquare}
        className={`material-icons ${classes.icon}`}
      />
      Edit
    </div>
  );

  const activate = (
    <div className={classes.action}>
      <span className={`material-icons ${classes.icon}`}>check_circle</span>
      Activate
    </div>
  );

  const attach = (
    <div className={classes.action}>
      <span className={`material-icons ${classes.icon}`}>attach_file</span>
      Attach
    </div>
  );

  return (
    <>
      <div className={classes.container}>
        {status === "Active" && (
          <>
            {modify}
            {copy}
            {withdraw}
            {attach}
          </>
        )}
        {status === "Under Revision" && (
          <>
            {edit}
            {activate}
            {copy}
            {attach}
          </>
        )}
        {(status === "Withdrawn" || status === "Obsolete") && copy}
      </div>
      <hr className={classes.line} />
    </>
  );
};

export default Actions;
