import "./ImportResult.scss";
const ImportResult = (props) => {

    const { totalUploadedFiles,reset }= props;
    return (
        <div className="import-result-container">
            <p>Your request for bulk import of <b>{totalUploadedFiles} file(s)</b> is now in progress.</p>

   
     
            <p className="">You will receive an e-mail once processing is completed.</p>
            <p className="link-btn"><a onClick={reset}>Click here to start over and upload more files.</a></p>

        </div>
    );
};


export default ImportResult;
