import React from "react";
import { useSelector } from "react-redux";
import { values } from "./../../../../features/dkList/dkListSlice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DataTableSortProps } from "../../DocumentKnowledge";

interface LocalRepListProps {
  isJapanese: boolean;
  sorting: DataTableSortProps;
  setSorting: React.Dispatch<React.SetStateAction<DataTableSortProps>>;
  isCollapsed: boolean;
  collapsedTemplate: (rowData: any, field: any) => {};
}

const LocalRepList = (props: LocalRepListProps) => {
  const value = useSelector(values);

  const fieldsEnglish = {
    certificationScheme: "Certification Scheme",
    segment: "Segment",
    isCustomerProvided: "Customer-Provided",
    companyName: "Company Name",
    address: "Address",
    postCode: "Post Code",
    contactPerson: "Contact Person",
    position: "Position/Department",
    telephoneNumber: "Telephone Number",
    faxNumber: "Fax Number",
    email: "E-Mail",
    others: "Others",
  };

  const fieldsJapanese = {
    certificationScheme: "Certification Scheme",
    segment: "Segment",
    isCustomerProvided: "Customer-Provided",
    companyName: "Company Name",
    address: "Address",
    postCode: "Post Code",
    contactPerson: "Contact Person",
    position: "Position/Department",
    telephoneNumber: "Telephone Number",
    faxNumber: "Fax Number",
    email: "E-Mail",
    others: "Others",
  };

  const onSortChange = (sortProps: any) => {
    props.setSorting((current: any) => ({
      ...current,
      localRep: {
        column: sortProps.sortField,
        sort: sortProps.sortOrder,
      },
    }));
  };

  return (
    <DataTable
      key={"localRepsGrid"}
      loading={value.isLoadingLocalRepListData}
      className={`ul-table`}
      value={value.localReps}
      resizableColumns
      sortMode="single"
      removableSort
      responsiveLayout="scroll"
      sortField={props.sorting.localRep.column}
      sortOrder={props.sorting.localRep.sort}
      onSort={onSortChange}
      showGridlines
      columnResizeMode="expand"
    >
      <Column field="id" header="Id" hidden={true}></Column>
      {Object.entries(props.isJapanese ? fieldsJapanese : fieldsEnglish).map(
        (col) => {
          return (
            <Column
              key={`localRepsGrid-${col[0]}`}
              field={col[0]}
              header={col[1]}
              sortable
              body={
                !props.isCollapsed
                  ? (e) => props.collapsedTemplate(e, col[0])
                  : null
              }
            ></Column>
          );
        }
      )}
    </DataTable>
  );
};

export default LocalRepList;
