import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import dkDropdownReducer from "../features/dkdropdown/dkDropdownSlice";
import dkListReducer from "../features/dkList/dkListSlice";
import createCertificateReducer from "../features/createCertificate/createCertificateSlice";
import certificateListReducer from "../features/certificateList/certificateListSlice";
import toastrReducer from "../features/toastr/toastrSlice";
import generalProductInformationReducer from "../features/generalProductInformation/generalProductInformationSlice";
import projectTemplateMappingReducer from "../features/projectTemplateMapping/projectTemplateMappingSlice";
import projectTemplateListReducer from "../features/projectTemplateList/projectTemplateListSlice";
import projectManagementReducer from "../features/projectManagement/projectManagementSlice.ts";
import projectWorkQueueReducer from "../features/projectWorkQueue/projectWorkQueueSlice";
import projectManagementPmrReducer from "../features/projectManagementPmr/projectManagementPmrSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    dkDropdown: dkDropdownReducer,
    dkList: dkListReducer,
    createCertificate: createCertificateReducer,
    certificateList: certificateListReducer,
    toastr: toastrReducer,
    generalProductInformation: generalProductInformationReducer,
    projectTemplateMapping: projectTemplateMappingReducer,
    projectTemplateList: projectTemplateListReducer,
    projectManagement: projectManagementReducer,
    projectWorkQueue: projectWorkQueueReducer,
    projectManagementPmr: projectManagementPmrReducer,
  },
});
