const certificateGridColumns = [
    {
      id: "certificateName",
      value: "Certificate Number",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: true,
      width: "180px",
      widthNum: 180,
      orderNo: 1
    },
    {
      id: "certificateStatus",
      value: "Certificate Status",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "160px",
      widthNum: 160,
      orderNo: 2
    },
    {
      id: "certificateType",
      value: "Certificate Scheme",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "170px",
      widthNum: 170,
      orderNo: 3
    },
  
    {
      id: "shiptoCustomer",
      value: "Applicant",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "140px",
      widthNum: 140,
      orderNo: 4
    },
    {
      id: "shiptoPartysite",
      value: "Applicant Party Site Number",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "180px",
      widthNum: 180,
      orderNo: 5
    },
    {
      id: "issueDate",
      value: "Issue Date",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "100px",
      widthNum: 100,
      orderNo: 6
    },
    {
      id: "mark",
      value: "Mark",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "65px",
      widthNum: 65,
      orderNo: 7
    },
    {
      id: "expiryDate",
      value: "Expiration Date",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "145px",
      widthNum: 145,
      orderNo: 8
    },
    {
      id: "withdrawalDate",
      value: "Withdrawn Date",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "150px",
      widthNum: 150,
      orderNo: 9
    },
    {
      id: "soldtoCustomer",
      value: "Product Owner",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "100px",
      widthNum: 100,
      orderNo: 10
    },
  
    {
      id: "soldtoPartysite",
      value: "Product Owner Party Site Number",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "180px",
      widthNum: 180,
      orderNo: 11
    },
    {
      id: "issuingBody",
      value: "Certification Body",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "160px",
      widthNum: 160,
      orderNo: 12
    },
    {
      id: "createdOn",
      value: "Created On",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "110px",
      widthNum: 110,
      orderNo: 13
    },
    {
      id: "updatedOn",
      value: "Updated On",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "115px",
      widthNum: 115,
      orderNo: 14
    },
  
    {
      id: "createdBy",
      value: "Created By",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "110px",
      widthNum: 110,
      orderNo: 15
    },
    {
      id: "updatedBy",
      value: "Updated By",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "110px",
      widthNum: 110,
      orderNo: 16
    },
    {
      id: "version",
      value: "Certificate Version",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "170px",
      widthNum: 170,
      orderNo: 17
    },
    {
      id: "revisionNumber",
      value: "Revision Number",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "160px",
      widthNum: 160,
      orderNo: 18
    },
    {
      id: "revisionDate",
      value: "Revision Date",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "130px",
      widthNum: 130,
      orderNo: 19
    },
    {
      id: "notification_date",
      value: "Notification Date",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "160px",
      widthNum: 160,
      orderNo: 20
    },   
    {
      id: "notification_sent_to",
      value: "Notification Sent To",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "180px",
      widthNum: 180,
      orderNo: 21
    }
  ];
  
  export default certificateGridColumns;
  