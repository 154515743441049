import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import ProjectRefinersService, {
  IGetPWQRefinersOracleCompanyInfo,
  IGetPWQRefinersPaginationRequest,
} from "../../../services/ProjectRefinersService";
import { RefinerType } from "../../../enums/RefinerType";
import ProjectWorkQueueBaseRefiners from "../project-work-queue-base-refners/ProjectWorkQueueBaseRefiners";
import { IGetFlexProjectsQuery } from "../../../shared/models/refiner-models/get-flex-projects";
import ProjectWorkQueueRefinerService from "../../../services/ProjectWorkQueueRefinerService";
import { PaginatedResultModel } from "../../../shared/models/service-models/PaginatedResult.model";
import { ResultModel } from "../../../models/result.model";
import { IGetProjectNumbersQuery } from "../../../shared/models/refiner-models/get-project-numbers";
import { PaginatedRequestModel } from "../../../shared/models/service-models/PaginatedRequest.model";
import { INVOICE_PRICE } from "../../../utils/constants/feature-flag.constants";

interface ProjectWorkQueueRefinersProps {
  onSelectedRefinersChange: any;
  selectedRefiners: any;
  onCustomSearch: (searchText: string) => void;
  onCustomSearchTextChange: (searchText: string) => void;
  customSearchText: string;
  disabledRefiners: any;
  addOrderLineDefaultRefiners: any;
}

const mapShipToSoldToOption = ({
  partySiteNumber,
  partyName,
}: {
  partySiteNumber: string;
  partyName: string;
}) => ({
  label: `${partySiteNumber} - ${partyName}`,
  value: {
    partySiteNumber,
    partyName,
  },
});

const ProjectWorkQueueRefiners = ({
  selectedRefiners,
  onSelectedRefinersChange,
  onCustomSearch,
  onCustomSearchTextChange,
  customSearchText,
  disabledRefiners,
  addOrderLineDefaultRefiners,
}: ProjectWorkQueueRefinersProps) => {
  /**
   * Multi-select refiners:
   * Flex handler and order creator can share 1 function since they're similar
   * but coding it manually offers flexibility
   */
  //#region Flex Handler
  const [flexHandlerRequest, setFlexHandlerRequest] =
    useState<PaginatedRequestModel>({
      take: 5,
      skip: 0,
      sort: "displayName",
      sortBy: "asc",
    });

  useEffect(() => {
    loadFlexHandlerRefinerOptions();
  }, [flexHandlerRequest]);

  const loadFlexHandlerRefinerOptions = async () => {
    const refiner = refiners.find((ref) => ref.field === "flexHandler");
    if (!refiner) {
      console.warn("flexHandler not found in refiners");
      return;
    }

    updateRefiner(refiner, (prev: any) => ({
      ...prev,
      loading: true,
    }));

    const { data }: ResultModel<PaginatedResultModel<any>> =
      await ProjectWorkQueueRefinerService.getFlexHandlers(flexHandlerRequest);
    const { skip } = flexHandlerRequest;
    let { options: prevOptions } = refiner;

    if ((skip ?? 0) <= 0) prevOptions = [];

    const newOptions = (data?.records ?? []).map((item: any) => ({
      label: item.email,
    }));

    const options = (prevOptions as any[]).concat(newOptions);

    updateRefiner(refiner, (prev: any) => ({
      ...prev,
      loading: false,
      options,
      displayShowMore: data.hasNextPage,
    }));
  };

  const handleFlexHandlerShowMore = () => {
    setFlexHandlerRequest((prev) => ({
      ...prev,
      skip: (prev.skip ?? 0) + (prev.take ?? 5),
    }));
  };
  //#endregion

  //#region Ship to
  const [flexCompanyNameRequest, setFlexCompanyNameRequest] =
    useState<IGetPWQRefinersOracleCompanyInfo>({
      skip: 0,
      take: 5,
      accountNumber: "",
      partyName: "",
      partySiteNumber: "",
    });

  useEffect(() => {
    loadFlexCompanyNamerRefinerOptions();
  }, [flexCompanyNameRequest]);

  const loadFlexCompanyNamerRefinerOptions = async () => {
    const refiner = refiners.find((ref) => ref.field === "flexCompanyName");
    if (!refiner) {
      console.warn("flex company name not found in refiners");
      return;
    }

    updateRefiner(refiner, (prev: any) => ({
      ...prev,
      loading: true,
    }));

    const { take, skip } = flexCompanyNameRequest;
    let { options: prevOptions } = refiner;

    const result = await ProjectRefinersService.getPWQRefinersOracleCompanyInfo(
      flexCompanyNameRequest
    );

    if (skip <= 0) prevOptions = [];

    // HACK: handle 204 for the meantime
    const newOptions = ((typeof result === "string" ? null : result) ?? [])
      .filter(
        (item: any) =>
          !prevOptions?.some((y: any) => y.value === item.partyName)
      )
      .map(mapShipToSoldToOption);

    const options = prevOptions.concat(newOptions);

    updateRefiner(refiner, (prev: any) => ({
      ...prev,
      loading: false,
      options,
      displayShowMore: newOptions.length >= take,
    }));
  };

  const handleFlexCompanyNameShowMore = () => {
    setFlexCompanyNameRequest((prev) => ({
      ...prev,
      skip: prev.skip + prev.take,
    }));
  };
  //#endregion

  //#region Sold To
  const [applicantSoldToRequest, setApplicantSoldToRequest] =
    useState<IGetPWQRefinersOracleCompanyInfo>({
      skip: 0,
      take: 5,
      accountNumber: "",
      partyName: "",
      partySiteNumber: "",
    });

  useEffect(() => {
    loadApplicantSoldToRefinerOptions();
  }, [applicantSoldToRequest]);

  const loadApplicantSoldToRefinerOptions = async () => {
    const refiner = refiners.find((ref) => ref.field === "applicantSoldTo");
    if (!refiner) {
      console.warn("applicantSoldTo not found in refiners");
      return;
    }

    updateRefiner(refiner, (prev: any) => ({
      ...prev,
      loading: true,
    }));

    let { options: prevOptions } = refiner;
    const { take, skip } = applicantSoldToRequest;

    const result = await ProjectRefinersService.getPWQRefinersOracleCompanyInfo(
      applicantSoldToRequest
    );

    if (skip <= 0) prevOptions = [];

    const newOptions = ((typeof result === "string" ? null : result) ?? [])
      .filter(
        (item: any) =>
          !prevOptions?.some(
            (prevOption: any) => prevOption.value === item.partySiteNumber
          )
      )
      .map(mapShipToSoldToOption);

    const options = (prevOptions as any[]).concat(newOptions);

    updateRefiner(refiner, (prev: any) => ({
      ...prev,
      loading: false,
      options,
      displayShowMore: newOptions.length >= take,
    }));
  };

  const handleApplicantSoldToShowMore = () => {
    setApplicantSoldToRequest((prev) => ({
      ...prev,
      skip: prev.skip + prev.take,
    }));
  };
  //#endregion

  //#region Order Number
  const [orderNumberRequest, setOrderNumberRequest] =
    useState<IGetProjectNumbersQuery>({
      take: 5,
      skip: 0,
    });

  useEffect(() => {
    loadOrderNumberRefinerOptions();
  }, [orderNumberRequest]);

  const loadOrderNumberRefinerOptions = async () => {
    const refiner = refiners.find((ref) => ref.field === "orderNumber");
    if (!refiner) {
      console.warn("orderNumber not found in refiners");
      return;
    }

    updateRefiner(refiner, (prev: any) => ({
      ...prev,
      loading: true,
    }));

    const { data }: ResultModel<PaginatedResultModel<any>> =
      await ProjectWorkQueueRefinerService.getFlexProjectOrderNumbers(
        orderNumberRequest
      );
    const { skip } = orderNumberRequest;
    let { options: prevOptions } = refiner;

    if ((skip ?? 0) <= 0) prevOptions = [];

    const newOptions = ((data.records ?? []) as any[]).map((item) => ({
      label: item.orderNumber,
    }));

    const options = (prevOptions as any[]).concat(newOptions);

    updateRefiner(refiner, (prev: any) => ({
      ...prev,
      loading: false,
      options, // simulate infinite scroll
      displayShowMore: data.hasNextPage,
    }));
  };

  const handleOrderNumberShowMore = () => {
    setOrderNumberRequest((prev) => ({
      ...prev,
      skip: (prev.skip ?? 0) + (prev.take ?? 5),
    }));
  };
  //#endregion

  //#region Project Number
  const [projectNumberRequest, setProjectNumberRequest] =
    useState<IGetFlexProjectsQuery>({
      take: 5,
      skip: 0,
    });

  useEffect(() => {
    loadProjectNumberRefinerOptions();
  }, [projectNumberRequest]);

  const loadProjectNumberRefinerOptions = async () => {
    const refiner = refiners.find((ref) => ref.field === "projectNumber");
    if (!refiner) {
      console.warn("projectNumber not found in refiners");
      return;
    }

    updateRefiner(refiner, (prev: any) => ({
      ...prev,
      loading: true,
    }));

    const { data }: ResultModel<PaginatedResultModel<any>> =
      await ProjectWorkQueueRefinerService.getFlexProjectNumbers(
        projectNumberRequest
      );

    let { options: prevOptions } = refiner;
    if ((projectNumberRequest.skip ?? 0) <= 0) prevOptions = [];

    const newOptions = ((data?.records ?? []) as any[]) // value is nullable here
      .map((item) => ({
        label: item.projectNumber,
      }));

    const options = (prevOptions as any[]).concat(newOptions);

    updateRefiner(refiner, (prev: any) => ({
      ...prev,
      loading: false,
      options, // simulate infinite scroll
      displayShowMore: data.hasNextPage,
    }));
  };

  const handleProjectNumberShowMore = () => {
    setProjectNumberRequest((prev) => ({
      ...prev,
      skip: (prev?.skip ?? 0) + (prev?.take ?? 5),
    }));
  };
  //#endregion

  const [refiners, setRefiners] = useState([
    {
      field: "flexProjectEcd",
      label: "FLEX Project ECD",
      loading: false,
      options: [],
      element: "Calendar",
      displayPastDue: true,
    },
    {
      field: "flexHandler",
      label: "FLEX Handler",
      loading: false,
      options: [],
      onShowMore: handleFlexHandlerShowMore,
    },
    {
      field: "flexProjectCreatedDate",
      label: "FLEX Project Created Date",
      loading: false,
      options: [],
      element: "Calendar",
    },
    {
      field: "flexCompanyName",
      label: "FLEX Company Name",
      loading: false,
      options: [],
      onShowMore: handleFlexCompanyNameShowMore,
      filterPlaceholder: 'Enter minimum of "3" digits',
      filterMinLength: 3,
    },
    {
      field: "orderBookedDate",
      label: "Order Booked Date",
      loading: false,
      options: [],
      element: "Calendar",
    },
    {
      field: "status",
      label: "Order Line Status",
      loading: false,
      options: [
        {
          label: "In Progress",
          value: "InProgress",
        },
        {
          label: "On Hold",
          value: "OnHold",
        },
      ],
      element: "Checkbox",
    },
    {
      field: "orderNumber",
      label: "Order Number",
      loading: false,
      options: [],
      onShowMore: handleOrderNumberShowMore,
      filterPlaceholder: 'Enter minimum of "5" digits',
      filterType: "number",
      filterMinLength: 5,
    },
    {
      field: "price",
      label: INVOICE_PRICE ? "Quotation Price" : "Price",
      loading: false,
      options: [],
      element: "Numeric",
    },
    {
      field: "projectNumber",
      label: "Project Number",
      loading: false,
      options: [],
      onShowMore: handleProjectNumberShowMore,
      filterPlaceholder: 'Enter minimum of "5" digits',
      filterType: "number",
      filterMinLength: 5,
    },
  ]);

  const dateFormatter = (date: string | Date) => {
    return date ? moment(date).format("MM/DD/yyyy") : "";
  };

  const handleSelectedRefinerChange = (
    refiner: any,
    value: any,
    type: string
  ) => {
    // process value here eg. format value
    let newValue = value;

    if (refiner.element === "Calendar" && value instanceof Date) {
      newValue = dateFormatter(value);
    }

    // process structure here eg. nested object or atomic only
    let finalValue = newValue;
    if (refiner.element === "Calendar") {
      finalValue = {
        // register from date from date to
        ...(type === "DateFrom" || type === "DateTo"
          ? {
              DateFrom: selectedRefiners[refiner.field]?.DateFrom,
              DateTo: selectedRefiners[refiner.field]?.DateTo,
            }
          : {}),

        // register value eg.(DateOn,EqualTo...)
        [type]: newValue,
      };
    }

    if (Array.isArray(finalValue)) {
      finalValue.sort((a, b) => {
        if (refiner.field === "flexCompanyName") {
          return a.value.partyName.localeCompare(b.value.partyName);
        }
        return a.label.localeCompare(b?.label);
      });
    }

    const newRefiners = {
      ...selectedRefiners,
      [refiner.field]: finalValue,
    };

    const refinerKeys = Object.keys(newRefiners[refiner.field]);
    refinerKeys.forEach((key) => {
      if (
        !newRefiners[refiner.field][key] &&
        newRefiners[refiner.field][key] !== 0
      )
        delete newRefiners[refiner.field][key];
    });

    if (Object.keys(newRefiners[refiner.field]).length <= 0) {
      delete newRefiners[refiner.field];
    }

    onSelectedRefinersChange(newRefiners);
  };

  const updateRefiner = (refiner: any, updateFunc: any) => {
    setRefiners((currRefiners) =>
      currRefiners.map((r) => (refiner.field === r.field ? updateFunc(r) : r))
    );
  };

  const distinct = (x: any, i: number, arr: any[]) => arr.indexOf(x) === i;

  const handleRefinerFilter = async (refiner: any, searchPhrase: string) => {
    switch (refiner.field) {
      case "projectNumber":
        setProjectNumberRequest((prev) => ({
          ...prev,
          searchString: searchPhrase,
          skip: 0,
        }));
        break;
      case "orderNumber":
        setOrderNumberRequest((prev) => ({
          ...prev,
          searchString: searchPhrase,
          skip: 0,
        }));
        break;
      case "applicantSoldTo":
        setApplicantSoldToRequest((prev) => ({
          ...prev,
          partyName: searchPhrase,
          partySiteNumber: searchPhrase,
          skip: 0,
        }));
        break;
      case "flexHandler":
        setFlexHandlerRequest((prev) => ({
          ...prev,
          searchString: searchPhrase,
          skip: 0,
        }));
        break;
      case "flexCompanyName":
        setFlexCompanyNameRequest((prev) => ({
          ...prev,
          partyName: searchPhrase,
          partySiteNumber: searchPhrase,
          skip: 0,
        }));
        break;
    }
  };

  const debouncedRefinerFilter = useCallback(
    debounce(handleRefinerFilter, 1500),
    []
  );

  const handleSelectedRefinerRemove = (refiner: any, type: string) => {
    let newRefiners: any = {};

    if (
      (refiner.element === "Calendar" || refiner.element === "Numeric") &&
      !!type
    ) {
      // type is undefined here when minimized icon is clicked
      newRefiners = {
        ...selectedRefiners,
        [refiner.field]: { ...selectedRefiners[refiner.field], [type]: null },
      };

      delete newRefiners[refiner.field][type];
      if (Object.keys(newRefiners[refiner.field]).length <= 0) {
        delete newRefiners[refiner.field];
      }
    } else {
      for (const key in selectedRefiners) {
        if (key !== refiner.field) {
          newRefiners[key] = selectedRefiners[key];
        }
      }
    }

    onSelectedRefinersChange(newRefiners);
  };

  const handleRefinerFilterRemove = (refiner: any, removedFilterValue: any) => {
    const newRefiners = {
      ...selectedRefiners,
      [refiner.field]: selectedRefiners[refiner.field].filter(
        (v: any) => v !== removedFilterValue
      ),
    };

    if (newRefiners[refiner.field].length <= 0) {
      delete newRefiners[refiner.field];
    }

    onSelectedRefinersChange(newRefiners);
  };

  const handleSelectedRefinersRemove = () => {
    if (disabledRefiners?.field) {
      const { status, ...rest } = addOrderLineDefaultRefiners;
      onSelectedRefinersChange({
        ...rest,
      });
      return;
    }
    onSelectedRefinersChange({});
  };

  return (
    <ProjectWorkQueueBaseRefiners
      onCustomSearch={onCustomSearch}
      onCustomSearchTextChange={onCustomSearchTextChange}
      customSearchText={customSearchText}
      showCustomSearch={true}
      refiners={refiners}
      selectedRefiners={selectedRefiners}
      onSelectedRefinerChange={handleSelectedRefinerChange}
      onFilterRefiner={(refiner: any, searchPhrase: any) => {
        updateRefiner(refiner, (f: any) => ({ ...f, loading: true }));
        debouncedRefinerFilter(refiner, searchPhrase);
      }}
      onRemoveSelectedRefiner={handleSelectedRefinerRemove}
      onRemoveSelectedRefinerFilter={handleRefinerFilterRemove}
      onRemoveSelectedRefiners={handleSelectedRefinersRemove}
      disabledRefiners={disabledRefiners}
      customSearchLabel={"Search"}
      customSearchPlaceholder={"enter keywords..."}
    />
  );
};

export default ProjectWorkQueueRefiners;
