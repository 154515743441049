import { IGetFlexHandlerRegionsQuery } from "../shared/models/refiner-models/get-flex-handler-regions";
import { IGetFlexProjectsQuery } from "../shared/models/refiner-models/get-flex-projects";
import { IGetFlexTaskNamesQuery } from "../shared/models/refiner-models/get-flex-task-names";
import { IGetMilestoneNamesQuery } from "../shared/models/refiner-models/get-milestone-names";
import { IGetOrderNumbersQuery } from "../shared/models/refiner-models/get-order-numbers";
import { IGetOrderLineNumbersQuery } from "../shared/models/refiner-models/get-orderline-numbers";
import { IGetPOCCountriesQuery } from "../shared/models/refiner-models/get-poc-countries";
import { IGetPOSCountriesQuery } from "../shared/models/refiner-models/get-pos-countries";
import { IGetProjectContributorsQuery } from "../shared/models/refiner-models/get-project-contributors";
import RequestFactory from "./RequestFactory";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

const OrderLineRefinersService = {
  getProjectContributors: async (body: IGetProjectContributorsQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/orderline-refiners/get-project-contributors",
      body
    ),
  getOrderLineNumbers: async (body: IGetOrderLineNumbersQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/orderline-refiners/get-orderline-numbers",
      body
    ),
  getOrderNumbers: async (body: IGetOrderNumbersQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/orderline-refiners/get-order-numbers",
      body
    ),
  getProjectNumbers: async (body: IGetOrderNumbersQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/orderline-refiners/get-project-numbers",
      body
    ),
  getMilestoneNames: async (body: IGetMilestoneNamesQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/orderline-refiners/get-milestone-names",
      body
    ),
  getFlexProjects: async (body: IGetFlexProjectsQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/orderline-refiners/get-flex-projects",
      body
    ),
  getFlexTaskNames: async (body: IGetFlexTaskNamesQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/orderline-refiners/get-flex-task-names",
      body
    ),
  getPOCCountries: async (body: IGetPOCCountriesQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/orderline-refiners/get-poc-countries",
      body
    ),
  getPOSCountries: async (body: IGetPOSCountriesQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/orderline-refiners/get-pos-countries",
      body
    ),
  getFlexHandlerRegions: async (body: IGetFlexHandlerRegionsQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/orderline-refiners/get-flex-handler-regions",
      body
    ),
  getFilteredTaskAndMilestone: async (
    request: {
      showFlexTask: boolean;
      e2eProjectIds: string[];
    },
    cancelSource: any = undefined
  ) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/orderline-refiners/get-filtered-task-and-milestone",
      request,
      () => {
        if (cancelSource === undefined) return undefined;

        return {
          cancelToken: cancelSource.token,
        } as any;
      }
    ),
};
export default OrderLineRefinersService;
