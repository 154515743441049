import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import classes from "./ConfirmationModalV2.module.scss";
import clsx from "clsx";

interface ConfirmationModalProps {
  onPositive?: () => void;
  onNegative?: () => void;
  onClose: () => void;
  visible: boolean;
  headerDisplay: string;
  buttonYesDisplay?: string;
  buttonNoDisplay?: string;
  confirmationMessageHTML: Element | JSX.Element | string;
  style?: object | undefined;
  className?: string;
  customFooter?: Element | JSX.Element | string;
  bodyClassName?: string;
  closable?: boolean;
}

const ConfirmationModalV2 = ({
  onPositive,
  onNegative,
  onClose,
  visible,
  headerDisplay,
  buttonYesDisplay,
  buttonNoDisplay,
  confirmationMessageHTML,
  style,
  className,
  customFooter,
  bodyClassName,
  closable = true,
}: ConfirmationModalProps) => {
  const headerTemplate = () => {
    return <h3 className={classes["dialog-header__title"]}>{headerDisplay}</h3>;
  };

  const footerTemplate = () => {
    return (
      <div className={classes["dialog-footer"]}>
        {customFooter ? (
          customFooter
        ) : (
          <>
            <Button onClick={onPositive} className={classes["btn-confirm"]}>
              {buttonYesDisplay}
            </Button>
            <Button onClick={onNegative} className={classes["btn-cancel"]}>
              {buttonNoDisplay}
            </Button>
          </>
        )}
      </div>
    );
  };

  return (
    <Dialog
      closable={closable}
      className={clsx(classes["dialog"], className)}
      style={style || { width: "40vw" }}
      visible={visible}
      header={headerTemplate}
      onHide={onClose}
      footer={footerTemplate}
    >
      <div className={clsx(classes["dialog-body"], bodyClassName)}>
        {confirmationMessageHTML}
      </div>
    </Dialog>
  );
};

export default ConfirmationModalV2;
