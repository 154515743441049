import {
  faFileInvoice,
  faStamp,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import useToastr from "../../../../../hooks/useToastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./ProjectListHeaderActions.module.scss";
import {
  selectShowFlexTasks,
  updateShowFlexTasks,
} from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
import {
  selectProjectFlexTasksList,
  selectRefinerLineResult,
  selectSelectedMilestones,
  selectSelectedTaskOrderlines,
  unselectAllMilestones,
  unselectAllTaskOrderlines,
} from "../../../../../features/projectManagement/projectManagementSlice";
import { Tooltip } from "primereact/tooltip";
import { uniqBy } from "lodash";
import { InputSwitch, InputSwitchChangeParams } from "primereact/inputswitch";
import useProjectTimestamp from "../../../../../hooks/useProjectTimestamp";
import ProjectStatus from "../../../shared/enums/project-status";
import { PmrE2EProjectFlexTaskMilestones } from "../../../shared/interfaces/pmr-e2e-project-milestone";
import PmrE2EProjectOrderlineModel from "../../../shared/interfaces/pmr-e2e-project-orderline-model";
import ProjectTaskStatus from "../../../shared/enums/project-task-status";
import TimestampElements from "../../../../project-management/shared/components/timestamp-elements/TimestampElements";
import TimestampConfirmationDialog from "../../../shared/components/timestamp-confirmation-dialog/TimestampConfirmationDialog";
import TimeEntryModal from "../../../time-entry-modal/TimeEntryModal";
import TimeEntryModalSourceType from "../../../shared/enums/time-entry-modal-source-type";
import PmrE2EProjectModel from "../../../shared/interfaces/pmr-e2e-project-model";
import { ECDAdjustmentsErrorType } from "../../enums/ecd-adjustments-error-type";
import { ProjectManagementPmrShowFlexTasks } from "../../../../../features/projectManagementPmr/prohectManagementPmrSliceInterfaces";
import {
  coHandlerErrorHeader,
  coHandlerErrorMessage,
} from "../../../../../utils/constants/projects.constants";

interface ProjectListHeaderActionsProps {
  project: PmrE2EProjectModel | null;
  filterByProject: boolean;
}

const ProjectListHeaderActions = (props: ProjectListHeaderActionsProps) => {
  const dispatch = useDispatch();
  const { showError } = useToastr();
  const mileStoneIdsFilter =
    props.project?.e2EProjectLineMilestoneGroupItems.map(
      (m) => m.e2EProjectLineMilestoneId
    ) || [];

  const selectedMilestonesSelector = useSelector(selectSelectedMilestones);
  const selectedMilestones =
    props == null || !props.filterByProject
      ? selectedMilestonesSelector
      : selectedMilestonesSelector.filter((milestoneItem) => {
          return mileStoneIdsFilter.includes(milestoneItem.id);
        }) || [];

  const taskOrderLineIdsFilter =
    props.project?.e2EProjectLineFlexTaskGroupItems.map(
      (m) => m.e2EProjectLineFlexTaskId
    ) || [];

  const selectedTaskOrderlinesSelector = useSelector(
    selectSelectedTaskOrderlines
  );

  const selectedTaskOrderlines =
    props == null || !props.filterByProject
      ? selectedTaskOrderlinesSelector
      : selectedTaskOrderlinesSelector.filter((taskOrderLineItem) => {
          return taskOrderLineIdsFilter.includes(
            taskOrderLineItem.e2EProjectLineFlexTaskId
          );
        }) || [];

  const showFlexTaskOnly = useSelector(selectShowFlexTasks);
  const projectTimestampHook = useProjectTimestamp();
  const refinerLineResult = useSelector(selectRefinerLineResult);
  const [
    timestampConfirmationDialogVisible,
    setTimestampConfirmationDialogVisible,
  ] = useState(false);

  const changeFlexTaskOnly = (props: InputSwitchChangeParams) => {
    const isFlexTaskOnly = props.value;
    const showFlexTask: ProjectManagementPmrShowFlexTasks = {
      isOn: isFlexTaskOnly,
      isFromClicked: true,
    };
    dispatch(updateShowFlexTasks(showFlexTask));
    if (isFlexTaskOnly) dispatch(unselectAllMilestones());
    else dispatch(unselectAllTaskOrderlines());
  };
  const [timeEntryModalVisible, setTimeEntryModalVisible] = useState(false);
  const [localSelectedTaskOrderline, setLocalSelectedTaskOrderline] = useState(
    new Array()
  );

  const projectFlexTasksList = useSelector(selectProjectFlexTasksList);
  const isTimeEntrySyncing = projectFlexTasksList.some((taskList) =>
    taskList.tasks.some((task) =>
      task.timeEntrySnycFlexTaskList.some((te) => te.isTimeEntrySyncing)
    )
  );

  const flexTaskToggleClass = clsx([classes["flexTaskToggle"]], {
    [classes["flexTaskToggle--checked"]]: showFlexTaskOnly.isOn,
  });

  //#region filtered resources
  const refinerResultFilterMilestone = (
    milestone: PmrE2EProjectFlexTaskMilestones
  ) => refinerLineResult.milestoneIds?.includes(milestone.id);

  const refinerResultFilterTask = (task: PmrE2EProjectOrderlineModel) =>
    refinerLineResult.flexTaskIds?.includes(task.e2EProjectLineFlexTaskId);

  const filteredTasks = refinerLineResult.flexTaskIds
    ? selectedTaskOrderlines.filter(refinerResultFilterTask)
    : selectedTaskOrderlines;

  const filteredMilestones = refinerLineResult.milestoneIds
    ? selectedMilestones.filter(refinerResultFilterMilestone)
    : selectedMilestones;
  //#endregion

  const nonCompletedMilestones = filteredMilestones.filter(
    (milestone) => milestone.status !== ProjectStatus.Completed
  );

  const nonCancelledMilestones = filteredMilestones.filter(
    (milestone) => milestone.status !== ProjectStatus.Cancelled
  );

  const nonCompletedTasks = filteredTasks.filter(
    (taskOrderline) => taskOrderline.status !== ProjectTaskStatus.Completed
  );

  const nonCancelledTasks = filteredTasks.filter(
    (taskOrderline) => taskOrderline.status !== ProjectTaskStatus.Cancelled
  );
  const isAddTimestampEnabled = (): boolean => {
    return showFlexTaskOnly.isOn
      ? nonCompletedTasks.length > 0 && nonCancelledTasks.length > 0
      : nonCompletedMilestones.length > 0 && nonCancelledMilestones.length > 0;
  };

  const handleTimestamp = async () => {
    let milestoneIds: string[] = [];
    let taskIds: string[] = [];

    if (showFlexTaskOnly.isOn) {
      let selectedTasks = structuredClone(selectedTaskOrderlines);
      if (refinerLineResult.flexTaskIds) {
        selectedTasks = selectedTasks.filter(refinerResultFilterTask);
      }

      taskIds = selectedTasks
        .filter(projectTimestampHook.isTaskTimestampable)
        .map(({ e2EProjectLineFlexTaskId }) => e2EProjectLineFlexTaskId);

      if (taskIds.length === 0) return;
    } else {
      let _selectedMilestones = structuredClone(selectedMilestones);
      if (refinerLineResult.milestoneIds) {
        _selectedMilestones = _selectedMilestones.filter(
          refinerResultFilterMilestone
        );
      }

      milestoneIds = _selectedMilestones
        .filter(projectTimestampHook.isMilestoneTimestampable)
        .map(({ id }) => id);

      if (milestoneIds.length === 0) return;
    }

    const ecdAdjustmentsResult =
      await projectTimestampHook.checkProjectLinesECDAdjustments(
        {
          taskIds,
          milestoneIds,
          includeMilestonePredecessorsForTimestamping: true,
          isMilestoneLoaded: !showFlexTaskOnly.isOn,
        },
        false
      );

    const { data, isSuccess, message } = ecdAdjustmentsResult || {};
    const {
      ecdAdjustments = [],
      e2EProjectLineMilestoneIds = [],
      e2EProjectLineFlexTaskIds = [],
      hasProjectLineWithMultipleMilestones = false,
      hasJumpedMilestones = false,
    } = data || {};

    if (!isSuccess) {
      if (data?.errorType == ECDAdjustmentsErrorType.CoHandlerError) {
        data?.coHandlerErrorModel.forEach((item) => {
          showError(
            coHandlerErrorHeader,
            coHandlerErrorMessage.replace("@orderNumber", item.orderLineNumber)
          );
        });
      }
      return;
    }

    if (ecdAdjustments.length !== 0) return;

    if (hasProjectLineWithMultipleMilestones && hasJumpedMilestones) {
      projectTimestampHook.setTimestampingStatus(
        e2EProjectLineMilestoneIds,
        e2EProjectLineFlexTaskIds,
        false
      );
      setTimestampConfirmationDialogVisible(true);

      return;
    }

    const timestampResult = await projectTimestampHook.timestampMilestones({
      milestoneIds,
      taskIds,
      adjustECDProjectLineIds: [],
      isFromECDAdjustmentPrompt: false,
      includeMilestonePredecessorsForTimestamping: true,
      isTimestampedThroughTasks: showFlexTaskOnly.isOn,
    });

    if (timestampResult?.isSuccess) unselectAll();
  };
  const unselectAll = () => {
    dispatch(unselectAllMilestones());
    dispatch(unselectAllTaskOrderlines());
  };
  const handleECDAdjustmentsConfirm = async (
    adjustECDProjectLineIds: string[]
  ) => {
    projectTimestampHook.hideECDAdjustmentsDialog();
    const result = await projectTimestampHook.timestampMilestones({
      milestoneIds: projectTimestampHook.currentMilestoneIds,
      taskIds: projectTimestampHook.currentTaskIds,
      adjustECDProjectLineIds,
      isFromECDAdjustmentPrompt: true,
      includeMilestonePredecessorsForTimestamping: true,
      isTimestampedThroughTasks: showFlexTaskOnly.isOn,
    });

    if (result?.isSuccess) unselectAll();
  };

  const handleTimestampConfirm = async () => {
    setTimestampConfirmationDialogVisible(false);

    if (!projectTimestampHook.currentECDAdjustmentsResult?.isSuccess) {
      showError(
        "Unable to complete timestamp",
        projectTimestampHook.currentECDAdjustmentsResult?.message
      );
      return;
    }

    projectTimestampHook.setMilestonesTimestampingStatus(
      projectTimestampHook.currentMilestoneIds,
      true
    );

    const timestampResult = await projectTimestampHook.timestampMilestones({
      milestoneIds: projectTimestampHook.currentMilestoneIds,
      taskIds: projectTimestampHook.currentTaskIds,
      adjustECDProjectLineIds: [],
      isFromECDAdjustmentPrompt: false,
      includeMilestonePredecessorsForTimestamping: true,
      isTimestampedThroughTasks: showFlexTaskOnly.isOn,
    });

    if (timestampResult?.isSuccess) unselectAll();
  };

  const isTimeEntryEnabled = (): boolean => {
    return showFlexTaskOnly.isOn
      ? nonCompletedTasks.length > 0 &&
          nonCancelledTasks.length > 0 &&
          !isTimeEntrySyncing
      : nonCompletedMilestones.length > 0 &&
          nonCancelledMilestones.length > 0 &&
          !isTimeEntrySyncing;
  };

  const handleTimeEntry = async () => {
    if (showFlexTaskOnly.isOn) {
      setLocalSelectedTaskOrderline(nonCompletedTasks);
    } else {
      const tasks = uniqBy(nonCompletedMilestones, "e2EProjectLineFlexTaskId");
      setLocalSelectedTaskOrderline(tasks);
    }
    setTimeEntryModalVisible(true);
  };

  return (
    <>
      <div className={`${classes["project-header-actions-container"]}`}>
        <div> FLEX Tasks only </div>
        <div>
          <InputSwitch
            id="checked"
            checked={showFlexTaskOnly.isOn}
            className={flexTaskToggleClass}
            onChange={changeFlexTaskOnly}
          ></InputSwitch>
        </div>
        <div className={`${classes["vl"]}`}></div>
        <div className={`add-timestamp-target`}>
          <button
            disabled={!isAddTimestampEnabled()}
            onClick={handleTimestamp}
            type="button"
            className={`ul-button ${classes["btn-icon"]} ${classes["add-timestamp"]} ${classes["green"]} `}
          >
            <span>
              <FontAwesomeIcon icon={faStamp} />
            </span>
          </button>
          <Tooltip
            target={`.add-timestamp-target`}
            className="custom-tooltip"
            my="left top"
            at="left bottom"
          >
            <div>
              <strong>Add Timestamp</strong>
            </div>
            <div>
              Click to mark the selected Task or Milestone as Completed and
              advance to the next phase.
            </div>
          </Tooltip>
        </div>
        <div className={`${classes["vl"]}`}></div>
        <div className={`timeentry-target`}>
          <button
            disabled={!isTimeEntryEnabled()}
            onClick={handleTimeEntry}
            type="button"
            className={`ul-button ${classes["btn-icon"]} ${classes["edit-timeentry"]} timeentry-target`}
          >
            <span>
              <FontAwesomeIcon icon={faStopwatch} />
            </span>
          </button>
          <Tooltip
            target={`.timeentry-target`}
            className="custom-tooltip"
            my="left top"
            at="left bottom"
          >
            <div>
              <strong>Time Entry</strong>
            </div>
            <div>
              Click to log hours onto Time Entry for selected order lines.
            </div>
          </Tooltip>
        </div>
        <div className={`${classes["vl"]}`}></div>
        <div className="generatestatusreport-target">
          <button
            type="button"
            className={`ul-button ${classes["btn-icon"]} ${classes["generate-report"]} ${classes["blue"]} `}
            disabled={true}
          >
            <FontAwesomeIcon icon={faFileInvoice}></FontAwesomeIcon>
          </button>
          <Tooltip
            target={`.generatestatusreport-target`}
            className="custom-tooltip"
            my="left top"
            at="left bottom"
          >
            <div>
              <strong>Generate Status Report</strong>
            </div>
            <div>Feature coming soon...</div>
          </Tooltip>
        </div>
      </div>

      {timeEntryModalVisible && (
        <TimeEntryModal
          timeEntryModalSourceType={TimeEntryModalSourceType.TimeEntry}
          closeTimeEntryModal={() => setTimeEntryModalVisible(false)}
          visible={timeEntryModalVisible}
          e2eProjectLineFlexTaskIds={localSelectedTaskOrderline.map(
            (x) => x.e2EProjectLineFlexTaskId
          )}
        />
      )}

      <TimestampElements
        projectTimestampHook={projectTimestampHook}
        onECDAdjustmentsConfirm={handleECDAdjustmentsConfirm}
      />

      <TimestampConfirmationDialog
        visible={timestampConfirmationDialogVisible}
        onCancel={() => setTimestampConfirmationDialogVisible(false)}
        onConfirm={handleTimestampConfirm}
        e2eProjectLineIds={projectTimestampHook.currentProjectLineIds}
      />
    </>
  );
};

export default ProjectListHeaderActions;
