import { ResultModel } from "../models/result.model";
import { FlexProjectTemplateDetailModel } from "../shared/models/FlexProjectTemplateTaskDetail.model";
import { FlexTaskSettingsModel } from "../shared/models/FlexTaskSettings.model";
import { E2EMilestoneFlexTaskDefaultResultModel } from "../shared/models/service-models/E2EMilestoneFlexTaskDefaultResult.model";
import { E2EMilestoneResultModel } from "../shared/models/service-models/E2EMilestoneResult.model";
import { FlexTemplateTaskResultModel } from "../shared/models/service-models/FlexTemplateTaskResult.model";
import { ProjectTemplateDuplicateRequest } from "../shared/models/service-models/ProjectTemplateDuplicateRequest";
import { ProjectTemplateMappingRequestModel } from "../shared/models/service-models/ProjectTemplateMappingRequest.model";
import { TemplateNameVerificationResultModel } from "../shared/models/service-models/TemplateNameVerificationResult.model";
import RequestFactory from "./RequestFactory";

const projectUrl = process.env.REACT_APP_GMAE2E_URL;

const ProjectTemplateMappingService = {
  getAssociationSchemeDropdown: async (data: any) =>
    await RequestFactory.get(
      projectUrl,
      "/api/template/association-scheme-dropdown/" + data
    ),

  getAssociationSchemeDropdownByAdditionalInfoId: async (data: any) =>
    await RequestFactory.post(
      projectUrl,
      `/api/template/association-scheme-dropdown-by-additionalInfoId`,
      data
    ),

  getCertificateSchemeDropdown: async () =>
    await RequestFactory.get(
      projectUrl,
      "/api/template/certificate-scheme-dropdown"
    ),

  getProjectTemplate: async () =>
    await RequestFactory.get(projectUrl, "/api/template/project-template"),

  getProjectTemplateById: async (data: any) =>
    await RequestFactory.get(
      projectUrl,
      "/api/template/project-template/" + data
    ),

  postProjectTemplate: async (payload: ProjectTemplateMappingRequestModel) =>
    await RequestFactory.post(
      projectUrl,
      "/api/template/project-template",
      payload
    ),

  patchProjectTemplate: async (payload: ProjectTemplateMappingRequestModel) =>
    await RequestFactory.patch(
      projectUrl,
      "/api/template/project-template/" + payload.Header.id,
      payload
    ),

  getTemplateHistory: async (data: any) =>
    await RequestFactory.get(
      projectUrl,
      "/api/template/template-history/" + data
    ),

  getFlexTasks: async (data: any) =>
    (await RequestFactory.get(
      projectUrl,
      "/api/template/flex-template-task/" + data
    )) as FlexTemplateTaskResultModel,

  getFlexTemplateListIntegration: async () =>
    (await RequestFactory.get(
      projectUrl,
      "/api/flex-integration/get-flex-templates"
    )) as FlexProjectTemplateDetailModel[],

  getFlexTemplateDetailIntegration: async (data: any) =>
    (await RequestFactory.get(
      projectUrl,
      "/api/flex-integration/flex-template-details?taskId=" + data
    )) as FlexProjectTemplateDetailModel,

  verifyTemplateDuplicate: async (payload: ProjectTemplateDuplicateRequest) =>
    (await RequestFactory.post(
      projectUrl,
      "/api/template/template-duplication",
      payload
    )) as TemplateNameVerificationResultModel,

  getE2EMilestones: async () =>
    (await RequestFactory.get(
      projectUrl,
      "/api/template/e2e-milestones"
    )) as E2EMilestoneResultModel,

  getE2EMilestoneFlexTaskDefault: async (flexCorrelationId: any) =>
    (await RequestFactory.get(
      projectUrl,
      `/api/template/milestone-flextask-default?flexCorrelationId=${flexCorrelationId}`
    )) as E2EMilestoneFlexTaskDefaultResultModel,

  getAdditionalInfo: async (
    schemeId: string,
    deliveryPathId: string,
    applicationTypeId: string
  ) =>
    await RequestFactory.post(
      projectUrl,
      `/api/template/get-additional-info?schemeId=${schemeId}&deliveryPathId=${deliveryPathId}&applicationTypeId=${applicationTypeId}`
    ),

  getAdditionalInfoAll: async () =>
    await RequestFactory.get(
      projectUrl,
      "/api/template/get-additional-info-all"
    ),
  getSchemesByAddInfoId: async (additinalInfoId: string) =>
    await RequestFactory.get(
      projectUrl,
      `/api/template/get-scheme-by-addinfoid?additinalInfoId=${additinalInfoId}`
    ),
  getDeliveryPathBySchemeId: async (schemeId: string) =>
    await RequestFactory.get(
      projectUrl,
      `/api/template/get-deliverypath-by-schemeId?schemeId=${schemeId}`
    ),
  getApplicationTypeBySchemeId: async (schemeId: string) =>
    await RequestFactory.get(
      projectUrl,
      `/api/template/get-applicaitontype-by-schemeId?schemeId=${schemeId}`
    ),
  getFlexTaskSettings: async (): Promise<
    ResultModel<FlexTaskSettingsModel[]>
  > =>
    await RequestFactory.get(
      projectUrl,
      "/api/flex-integration/get-flex-task-settings"
    ),
};

export default ProjectTemplateMappingService;
