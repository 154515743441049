import {
  faCaretDown,
  faCheck,
  faClose,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
} from "primereact/autocomplete";
import { useEffect, useRef, useState } from "react";
import { E2EUserModel } from "../../../models/E2EUserModel";
import { AssignFlexHandlerStatus } from "../../../models/flex/AssignFlexHandlerStatus.model";
import classes from "./AssignFlexHandlerCell.module.scss";
import { updateColumnWidth } from "../../../shared/datatable-helper/datatable-helper";
import { pWqColumnProjectHandler } from "../../project-management/data/project-work-queue-grid-columns";
import { GetPwqProjectLineDisplayModel } from "../../../models/project-work-queue/GetPwqProjectLineDisplayModel.model";

interface AssignFlexHandlerCellProps {
  projectServiceLine: GetPwqProjectLineDisplayModel;
  status: AssignFlexHandlerStatus;
  onAssignFlexHandler: (projectId: string, newHandlerEmail: string) => void;
  inProgressAssignedFlexHandler: string | undefined;
  projectHandlers: E2EUserModel[];
  setColumns: any;
}

const AssignFlexHandlerCell = ({
  projectServiceLine,
  status,
  onAssignFlexHandler,
  inProgressAssignedFlexHandler,
  projectHandlers,
  setColumns,
}: AssignFlexHandlerCellProps) => {
  const projectHandler = projectServiceLine.flexHandler;

  const [isEditActive, setisEditActive] = useState(false);
  const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState<
    E2EUserModel[]
  >([]);
  const [selectedProjectHandler, setSelectedProjectHandler] =
    useState<E2EUserModel>();
  const [hasSelectedValidValue, setHasSelectedValidValue] = useState(false);

  const autoCompleteInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditActive) autoCompleteInputRef.current?.select();
  }, [isEditActive]);

  const doCompleteMethod = ({
    query: search,
  }: AutoCompleteCompleteMethodParams) => {
    if (!search) {
      setAutoCompleteSuggestions([...projectHandlers]);
      return;
    }

    const filtered = projectHandlers.filter((p) =>
      p.displayName?.toLowerCase()?.includes(search.toLowerCase())
    );

    setAutoCompleteSuggestions(filtered);
  };

  const handleProjectHandlerChange = (e: AutoCompleteChangeParams) => {
    setHasSelectedValidValue(false);
    setSelectedProjectHandler(e.value);
  };

  const handleProjectHandlerSelect = () => {
    setHasSelectedValidValue(true);
  };

  const handleDoubleClickCell = () => {
    if (isEditActive || status === "IN PROGRESS") return;
    updateColumnWidth(setColumns, "400px", pWqColumnProjectHandler);
    setHasSelectedValidValue(true);
    setSelectedProjectHandler({
      displayName: projectHandler,
      normalizedEmail: projectHandler,
    });
    setisEditActive(true);
  };

  const handleClose = () => {
    setisEditActive(false);
    updateColumnWidth(setColumns, "300px", pWqColumnProjectHandler);
  };

  const handleConfirm = () => {
    setisEditActive(false);
    updateColumnWidth(setColumns, "300px", pWqColumnProjectHandler);
    onAssignFlexHandler(
      projectServiceLine.flexProjectId,
      selectedProjectHandler?.normalizedEmail || ""
    );
  };

  const renderCellContent = () => {
    if (status === "IN PROGRESS") {
      return (
        <div className={classes["handler-cell--saving"]}>
          <span>{inProgressAssignedFlexHandler}</span>
          <FontAwesomeIcon icon={faSpinner} spin={true} />
        </div>
      );
    }

    if (isEditActive) {
      return (
        <div className={classes["edit-handler-container"]}>
          <AutoComplete
            field="displayName"
            dropdownIcon={<FontAwesomeIcon icon={faCaretDown} />}
            className={classes["edit-handler__autocomplete"]}
            inputRef={autoCompleteInputRef}
            value={selectedProjectHandler}
            onChange={handleProjectHandlerChange}
            onSelect={handleProjectHandlerSelect}
            suggestions={autoCompleteSuggestions}
            completeMethod={doCompleteMethod}
            dropdown
            forceSelection
            aria-label={projectHandler}
          />
          <button
            onClick={handleConfirm}
            disabled={!hasSelectedValidValue}
            className={`${classes["edit-handler__btn-confirm"]}`}
          >
            <FontAwesomeIcon title="Confirm" size="1x" icon={faCheck} />
          </button>

          <button
            onClick={handleClose}
            className={`${classes["edit-handler__btn-cancel"]}`}
          >
            <FontAwesomeIcon title="Cancel" size="1x" icon={faClose} />
          </button>
        </div>
      );
    }

    return (
      <>
        <div
          title={projectServiceLine.flexHandler}
          className="text-overflow-ellipsis"
        >
          {projectServiceLine.flexHandler}
        </div>
      </>
    );
  };

  return (
    <div
      className={`${classes["container"]}`}
      onDoubleClick={handleDoubleClickCell}
    >
      {renderCellContent()}
    </div>
  );
};

export default AssignFlexHandlerCell;
