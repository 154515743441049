import { faInfoCircle, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "primereact/skeleton";
import { useState, useEffect } from "react";
import { ArrowContainer, Popover } from "react-tiny-popover";
import DocumentService from "../../../services/DocumentService";
import ProductTypeService from "../../../services/ProductTypeService";
import CertificateListService from "../../../services/CertificateListService";
import classes from "./IpModelDetailsPopover.module.scss";
import { formatDateToString } from "../../../utils/helpers/date.helpers";

const workBenchProductUrl = process.env.REACT_APP_WORKBENCH_PRODUCT_URL;
const workBenchCollectionUrl = process.env.REACT_APP_WORKBENCH_COLLECTION_URL;

const IpModelDetailsPopover = ({
  onOpenWindow,
  isWindowOpen,
  model,
  onCloseWindow,
  onClickOutside,
  includeBasicModelDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [ipAssetDetails, setIpAssetDetails] = useState(null);
  const [_model, _setModel] = useState(null);

  const handleOpen = async (e) => {
    onOpenWindow(e, model);
    setLoading(true);

    const getIpAssetDetails = await DocumentService.getIpAssetDetails(
      model.assetId,
      model.modelName
    );

    setIpAssetDetails(getIpAssetDetails?.data?.certificates);

    if (includeBasicModelDetails) {
      const getBasicModelDetails = await ProductTypeService.getIpModelDetails({
        assetId: model.assetId,
        includeE2E: false,
      });

      if (getBasicModelDetails?.data?.length > 0) {
        _setModel(getBasicModelDetails.data[0]);
      }
    }

    setLoading(false);
  };

  const rowTemplate = (value, label) => {
    const divstyle = { whiteSpace: "pre-line" };
    return (
      <div className={classes["row"]}>
        <div className={classes["label"]}>{label}:</div>
        <div className={classes["value"]}>
          {loading ? (
            <Skeleton className={classes["skeleton"]} />
          ) : (
            <div style={divstyle}>{value || "-"}</div>
          )}
        </div>
      </div>
    );
  };

  const workBenchLinkTemplate = (model) => {
    const goToUrl = `${workBenchProductUrl}${model.assetId}`;
    const goToCollectionUrl = `${workBenchCollectionUrl}${model.collectionId}`;
    return (
      <>
        <div className={classes["row"]}>
          <div className={classes["label"]}></div>
          <div className={classes["value"]}>
            <a target="_blank" href={goToUrl} rel="noreferrer">
              Open Model In Workbench
            </a>
          </div>
        </div>
        {model.collectionId && (
          <div className={classes["row"]}>
            <div className={classes["label"]}></div>
            <div className={classes["value"]}>
              <a target="_blank" href={goToCollectionUrl} rel="noreferrer">
                Open Collection In Workbench
              </a>
            </div>
          </div>
        )}
      </>
    );
  };

  const constructCertificateHeader = (certificate) => {
    return (
      <>
        <hr className={classes["certificate-separator"]} />
        <div className={classes["row"]}>
          <div className={classes["certificate-header"]}>
            {`${certificate?.certificateName} | ${certificate?.certificateScheme} | ${certificate?.productOwner}`}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!isWindowOpen) setIpAssetDetails(null);
  }, []);

  return (
    <Popover
      isOpen={isWindowOpen}
      positions={["right", "left"]}
      onClickOutside={onClickOutside}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"gray"}
          arrowSize={10}
          arrowStyle={{ opacity: 0.7 }}
          className="popover-arrow-container"
        >
          <div className={classes["container"]}>
            <div className={classes["close-icon-container"]}>
              <FontAwesomeIcon
                onClick={onCloseWindow}
                icon={faClose}
                className={classes["close-icon"]}
                size="s"
              />
            </div>
            {includeBasicModelDetails && (
              <>
                {rowTemplate(_model?.familySeries, "Family / Series")}
                {rowTemplate(_model?.status, "Status")}
                {rowTemplate(_model?.applicantName, "Applicant")}
                {rowTemplate(_model?.partySiteNumber, "Applicant PSN")}
                {rowTemplate(
                  formatDateToString(_model?.updatedOn),
                  "Last Update Date"
                )}
                <br />
              </>
            )}

            {rowTemplate(model?.productType, "Product Type")}
            {rowTemplate(model?.assetVersion, "Version")}
            <br />

            {ipAssetDetails?.map((certificate) => {
              return (
                <>
                  {constructCertificateHeader(certificate)}
                  {rowTemplate(certificate.scopeCcn, "Scope/CCN")}
                  {rowTemplate(
                    certificate.certificateScheme,
                    "Certification Scheme"
                  )}
                  <br />
                </>
              );
            })}

            {workBenchLinkTemplate(model)}
          </div>
        </ArrowContainer>
      )}
    >
      <div>
        <FontAwesomeIcon
          className={`${classes["model-info-popover"]}`}
          onClick={(e) => (isWindowOpen ? onCloseWindow(e) : handleOpen(e))}
          icon={faInfoCircle}
          size="s"
          title={`${model?.modelName} Details`}
        />
      </div>
    </Popover>
  );
};

export default IpModelDetailsPopover;
