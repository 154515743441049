import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputText } from "primereact/inputtext";
import React, { useContext } from "react";
import DataDiscrepancy from "../../DataDiscrepancy/DataDiscrepancy";
import FormContext from "../context/FormContext";
import classes from "./FormControl.module.scss";

const FormControl = ({
  control: Control,
  label,
  value,
  schema,
  schemaContext,
  style,
  controlClassName,
  helpText,
  hideHelpTextOnError,
  labelTemplate,
  containerClassName,
  helpTextTemplate,
  schemaValue,
  forceShowError,
  showRequiredErrorMessage,
  customErrorMessage,
  makeContainerFocusable,
  field,
  rootObject,
  dataDiscrepancyOptions,
  appendDiscrepancy = false,
}) => {
  const { showFormError, isReadOnly } = useContext(FormContext);

  const getValue = () => {
    if (field) return rootObject[field];
    return value;
  };

  const displayDiscrepancy =
    dataDiscrepancyOptions?.previousValue !==
    dataDiscrepancyOptions?.currentValue &&
    dataDiscrepancyOptions?.isVisible &&
    dataDiscrepancyOptions?.currentValue !== "";

  const validate = () => {
    if (!schema) {
      return {
        isValid: true,
      };
    }

    try {
      if (field) schema.validateSyncAt(field, rootObject);
      else
        schema.validateSync(schemaValue || getValue(), {
          context: schemaContext,
        });

      return {
        isValid: true,
      };
    } catch (error) {
      return {
        isValid: false,
        type: error.type,
        message: error.message || error,
      };
    }
  };

  const validationState = validate();

  const showErrorMessage =
    ((showFormError || forceShowError) &&
      !validationState.isValid &&
      (validationState.type !== "required" || showRequiredErrorMessage)) ||
    customErrorMessage ||
    displayDiscrepancy;

  const errorMessage = customErrorMessage || validationState.message;

  const createMarkup = (content) => {
    return { __html: content };
  };

  return (
    <div
      tabIndex={-1}
      style={style}
      className={`${makeContainerFocusable ? "focusable" : ""} ${classes["form-field"]
        } form-field ${customErrorMessage ? "custom-error" : ""
        } ${containerClassName} ${!((showFormError || customErrorMessage) && errorMessage)
          ? ""
          : `${classes["error"]} error ${validationState.type === "required"
            ? "required-error"
            : "invalid-data-error"
          }`
        }`}
    >
      {(labelTemplate || label) && (
        <h5 className={classes["form-label"]}>
          {labelTemplate ? labelTemplate() : label}
        </h5>
      )}

      <div>
        {React.cloneElement(Control, {
          isReadOnly: isReadOnly || Control.props.readOnly,
          disabled: isReadOnly || Control.props.disabled,
          value: getValue(),
          className:
            classes["form-control"] +
            " form-control " +
            (Control.type === InputText ? "ul-textbox " : "") +
            (Control.props.readOnly ? "p-inputwrapper-read-only " : "") +
            controlClassName,
        })}

        {(helpTextTemplate || helpText) &&
          !(showErrorMessage && hideHelpTextOnError) && (
            <label className={classes["help-text"]}>
              {helpTextTemplate ? helpTextTemplate() : helpText}
            </label>
          )}

        {displayDiscrepancy && dataDiscrepancyOptions && (
          <DataDiscrepancy {...dataDiscrepancyOptions} />
        )}

        {showErrorMessage &&
          errorMessage &&
          (!displayDiscrepancy || appendDiscrepancy) && (
            <div className={classes["error-message"]}>
              <FontAwesomeIcon icon={faClose} />{" "}
              <span dangerouslySetInnerHTML={createMarkup(errorMessage)} />
            </div>
          )}
      </div>
    </div>
  );
};

export default FormControl;
