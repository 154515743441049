import "primeicons/primeicons.css"; //icons
import "primereact/resources/primereact.min.css"; //core css
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import React from "react";
import AzureAD from "react-aad-msal";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./app/store";
import "./index.css";
import { authProvider } from "./providers/authProvider";
import * as serviceWorker from "./serviceWorker";
import { registerLicense } from "@syncfusion/ej2-base";

// If user has requested specific pathname other than the default page,
// store it in local storage then redirect the user after authentication.
if (window.location.pathname !== "/") {
  localStorage.setItem("requested-path", window.location.pathname);
}

const isCustomerView = process.env.REACT_APP_IS_CUSTOMER_VIEW === "true";

const appWithStore = (
  <Provider store={store}>
    <App />
  </Provider>
);

registerLicense(
  "Mgo+DSMBaFt/QHRqVVhjVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSH9TdERnUXtWdHJRQw==;Mgo+DSMBPh8sVXJ0S0J+XE9HflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Td0RjW35aeHBdQmdVVA==;ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRiWX1fdHxRTmVZV0E=;OTE2MTQ1QDMyMzAyZTM0MmUzMGNKT3FDRUJzdmMxencwQTJ5cmdBUGwzVXZ3aTNId1RVNFJrOGMxYUZ4dW89;OTE2MTQ2QDMyMzAyZTM0MmUzMFNGN2EzUUdUMERzWG85bWtXSEZmMHpIenB3Y0o1ZXJqNzJNdzhHdm5BVm89;NRAiBiAaIQQuGjN/V0Z+WE9EaFxKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUViWH9ccXBdQmhaU0Bz;OTE2MTQ4QDMyMzAyZTM0MmUzMEtZTnRUd1ZVVEJMU1pWL2wwY1hRT2JBRmJpckdlN0FhT1AxTU5lTHBnTzQ9;OTE2MTQ5QDMyMzAyZTM0MmUzMG90eFQ5b1R5TGprc3VRbnEreGFnQzFIOWNOdjB6RDFBQnFLSzM0SjNPTnM9;Mgo+DSMBMAY9C3t2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRiWX1fdHxRTmZVVUE=;OTE2MTUxQDMyMzAyZTM0MmUzMFllY1BWc01WeFRBeW80MHNCb3BueFB2QjNRTHJLNC9tMDU0aXJiVEJzWTg9;OTE2MTUyQDMyMzAyZTM0MmUzMGR3L1draUtMcGxHeGxlM3JWOUgvWDZqMlVRWmxNckIrdTZsMVVpeE9xeWc9;OTE2MTUzQDMyMzAyZTM0MmUzMEtZTnRUd1ZVVEJMU1pWL2wwY1hRT2JBRmJpckdlN0FhT1AxTU5lTHBnTzQ9"
);

ReactDOM.render(
  <React.StrictMode>
    {!isCustomerView && (
      <AzureAD provider={authProvider} forceLogin={true}>
        {appWithStore}
      </AzureAD>
    )}

    {isCustomerView && appWithStore}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
