import {
  faClose,
  faCopy,
  faDownload,
  faEdit,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ValidationStatus from "../../enums/ValidationStatus";
import useGeneralProductInfoDataTable from "../../hooks/useGeneralProductInfoDataTable";
import { useSignalR } from "../../hooks/useSignalR";
import useToastr from "../../hooks/useToastr";
import { authProvider } from "../../providers/authProvider";
import GeneralProductInformationService from "../../services/GeneralProductInformationService";
import CustomPaginator from "../../shared/custom-paginator/CustomPaginator";
import GridActionPopover from "../../shared/grid-action-popover/GridActionPopover";
import SpinnerLoaderSVG from "../../shared/spinner-loader-svg/SpinnerLoaderSvg";
import classes from "./GeneralProductInformationList.module.scss";
import GeneralProductInformationRefiners from "./general-product-information-refiners/GeneralProductInformationRefiners";
import GpiCopyFormModal from "./gpi-copy-form-modal/GpiCopyFormModal";
import GpiFormNameModal, {
  duplicateFormNameErrorMessage,
} from "./gpi-form-name-modal/GpiFormNameModal";

const GeneralProductInformation = () => {
  const history = useHistory();
  const { showSuccess, showError, showWarning } = useToastr();
  const actionsPopOverRef = useRef();
  const [selectedGpiFormId, setSelectedGpiFormId] = useState("");
  const [gpiFormName, setGpiFormName] = useState("");
  const [gpiFormNameModalVisible, setGpiFormNameModalVisible] = useState(false);
  const [gpiFormNameModalError, setGpiFormNameModalError] = useState("");
  const [gpiFormNameSaving, setGpiFormNameSaving] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [selectedRefiners, setSelectedRefiners] = useState({
    creatorName: [{ label: authProvider.getAccountInfo()?.account?.name }],
  });
  const [isModelRefiner, setIsModelRefiner] = useState(false);
  const [downloadingGPIFormIds, setDownloadingGPIFormIds] = useState([]);
  const [isGpiCopyFormModalVisible, setGpiCopyFormModalVisible] =
    useState(false);
  const [
    isShowOnlyGpiWithOngoingValidation,
    setIsShowOnlyGpiWithOngoingValidation,
  ] = useState(false);
  const noSelectionWarningMessage =
    "No GPI form(s) selected. Please select a GPI form(s) then try again.";
  const hasAnySelectedRefiner = (refiners) =>
    Object.keys(refiners)?.some((key) => refiners[key].length > 0);
  const hubUrl = process.env.REACT_APP_GMAE2E_FUNC_URL + "/api";
  const { connection: signalRConnection } = useSignalR(hubUrl);

  useEffect(() => {
    refreshDataTable();
  }, []);

  const fetchedDataMapper = (data) => {
    return data.map((d) => ({
      ...d,
      creatorName: d.creatorName,
      applicantName:
        d.applicantInfo?.gpiCompanyContactInfo?.gpiCompanyInfo?.companyName,
      manufacturerName:
        d.manufacturerInfo?.gpiCompanyContactInfo?.gpiCompanyInfo?.companyName,
      productNames: d.gpiProductType?.productName,
      factoryNames: d.factories
        ?.map((f) => f.gpiCompanyContactInfo?.gpiCompanyInfo?.companyName)
        ?.filter((f) => f)
        ?.join(", "),
      agentName:
        d.agentInfo?.gpiCompanyContactInfo?.gpiCompanyInfo?.companyName,
      lastUpdateDate: formatDate(d.modifiedDateUtc || d.createdDateUtc),
      isReadyForTranscription: d.isReadyForTranscription ? "Y" : "N",
      models: formatModel(
        d.gpiProductType?.baseModel?.modelName,
        d.gpiProductType?.modelVariants
      ),
      baseModel: d.gpiProductType?.baseModel?.modelName,
      modelVariants: d.gpiProductType?.modelVariants,
      ipModelDetailsLoading: false,
      ipModelDetailsOpen: false,
      validationPopoverOpen: false,
      validationStatus: d.validationStatus,
      id: d.id,
      gpiFormName: d.gpiFormName,
    }));
  };

  const datatableColumns = [
    {
      id: "gpiFormName",
      value: "GPI Form Name",
      width: "220px",
    },
    {
      id: "applicantName",
      value: "Applicant Name",
      width: "220px",
    },
    {
      id: "models",
      value: "Models",
      width: "175px",
    },
    {
      id: "creatorName",
      value: "GPI Form Creator",
      width: "180px",
    },
    {
      id: "lastUpdateDate",
      value: "Last Update Date",
      width: "180px",
    },
    {
      id: "productNames",
      value: "Product Name/s",
      width: "220px",
    },
    {
      id: "manufacturerName",
      value: "Manufacturer Name",
      width: "220px",
    },
    {
      id: "factoryNames",
      value: "Factory Name/s",
      width: "220px",
    },
    {
      id: "agentName",
      value: "Agent Name",
      width: "220px",
    },
  ];

  const datatableHook = useGeneralProductInfoDataTable(
    datatableColumns,
    fetchedDataMapper,
    signalRConnection
  );

  useEffect(() => {
    if (!datatableHook.isValidationBannerVisible) {
      setIsShowOnlyGpiWithOngoingValidation(false);
    }
  }, [datatableHook.isValidationBannerVisible]);

  useEffect(() => {
    if (signalRConnection) {
      const gpiIds = datatableHook.dataTableData?.map((g) => g.id);
      GeneralProductInformationService.joinGpiValidationGroup(gpiIds);

      signalRConnection.on("gpi-validation-start", (result) => {
        const fcnResult = JSON.parse(result);
        const gpi = gpiIds?.find(
          (g) => g == fcnResult.data?.generalProductInfoId
        );

        if (!gpi) return;
        datatableHook.updateValidationBanner(datatableHook.dataTableData);
        refreshDataTable();
      });

      signalRConnection.on("company-details-validation", (result) => {
        const fcnResult = JSON.parse(result);
        const gpi = gpiIds?.find(
          (g) => g == fcnResult.data?.generalProductInfoId
        );

        if (!gpi) return;
        datatableHook.updateValidationBanner(datatableHook.dataTableData);
        refreshDataTable();
        if (fcnResult.isSuccess) {
          showSuccess(
            "Success!",
            "GPI Form validation has been completed successfully."
          );
        }
      });

      signalRConnection.on("gpi-validation", (result) => {
        const fcnResult = JSON.parse(result);
        const gpi = gpiIds?.find(
          (g) => g == fcnResult.data?.generalProductInfoId
        );

        if (!gpi) return;

        datatableHook.updateValidationBanner(datatableHook.dataTableData);
        refreshDataTable();
        if (fcnResult.isSuccess) {
          showSuccess(
            "Success!",
            "GPI Form validation has been completed successfully."
          );
        }
      });
    }
  }, [signalRConnection]);

  const handleGpiFormNameEdit = (e) => {
    if (isNotOwnedGpiSelected([selectedGpiForm])) {
      showError(
        "Error!",
        "Cannot edit GPI Form name of form(s) created by other user(s)."
      );

      return;
    }

    setGpiFormNameModalError("");
    setGpiFormName(selectedGpiForm.gpiFormName);
    setGpiFormNameModalVisible(true);

    e.stopPropagation();
  };

  const handleGpiFormCopy = () => {
    if (isGpiCopyFormModalVisible) {
      setGpiCopyFormModalVisible(false);
    } else {
      setGpiCopyFormModalVisible(true);
    }
  };

  const datatableActions = [
    {
      icon: faDownload,
      textDisplay: "Download GPI Form",
      action: (e) => {
        handleDownloadGPIFormClick(selectedGpiFormId);
        e.stopPropagation();
      },
      isDisabled: downloadingGPIFormIds.includes(selectedGpiFormId),
    },
    {
      icon: faEdit,
      textDisplay: "Edit GPI Form Name",
      action: handleGpiFormNameEdit,
    },
    {
      icon: faCopy,
      textDisplay: "Copy GPI Form",
      action: (e) => {
        handleGpiFormCopy();
      },
    },
    {
      icon: faTrashCan,
      textDisplay: "Delete GPI Form",
      action: (e) => {
        handleDeleteGeneralProductInfoItems([selectedGpiFormId]);
      },
    },
  ];

  const dataFields = [
    {
      id: "selectAll",
      value: "Select All",
    },
    {
      id: "applicantInformation",
      value: "Applicant Information",
    },
    {
      id: "agentInformation",
      value: "Agent Information",
    },
    {
      id: "existingCertificates",
      value: "Existing Certificates",
    },
    {
      id: "manufacturerInformation",
      value: "Manufacturer Information",
    },
    {
      id: "factorySiteInformation",
      value: "Factory/Production Site Information",
    },
    {
      id: "productDetails",
      value: "Product Details",
    },
    {
      id: "radioDetails",
      value: "Radio Details",
    },
    {
      id: "localRepImporter",
      value: "Local Rep Importer",
    },
  ];

  const refinerSearchWhereExpressionBuilder = (
    refinerValue,
    concatenetorFunction
  ) => {
    const whereExpression = refinerValue
      ?.map((r) => `{ eq: "${r.label}"}`)
      ?.join(" ");

    if (!whereExpression) return "";

    const result = `{or: [${whereExpression}]}`;

    return concatenetorFunction(result);
  };

  const searchPhraseWhereExpressionBuilder = (
    searchPhrase,
    concatenetorFunction
  ) => {
    const result = `{or:[{contains: "${searchPhrase}"}]}`;

    return concatenetorFunction(result);
  };

  const formatDate = (date) => {
    if (!date) return "";

    return moment(new Date(date)).format("DD MMM YYYY");
  };

  const formatModel = (base, variants) => {
    let baseModel = base ? base : "";
    let modelVariants =
      variants?.length > 0
        ? variants.map((i) => i.modelVariant.modelName).join(", ")
        : "";

    if (base && variants?.length > 0) {
      return baseModel + ", " + modelVariants;
    } else {
      if (baseModel) return baseModel + " ";
      if (variants?.length > 0) return modelVariants;
    }
  };

  const selectedGpiForm = datatableHook.dataTableData?.find(
    (d) => d.id === selectedGpiFormId
  );

  const refreshDataTable = () => {
    datatableHook.populateDataTable(
      datatableHook.pageInfo.lazyParams.page,
      datatableHook.pageInfo.lazyParams.rows,
      searchPhrase,
      selectedRefiners
    );
  };

  const handleDeleteGeneralProductInfoItems = (ids) => {
    if (
      isNotOwnedGpiSelected(
        datatableHook.dataTableData.filter((d) => ids.includes(d.id))
      )
    ) {
      showError(
        "Error!",
        "Cannot delete GPI form(s) created by other user(s)."
      );
      return;
    }

    confirmDialog({
      message: (
        <>
          <p>Are you sure you want to delete this form(s)?</p>
          <p>Once deleted, it can't be recovered.</p>
        </>
      ),
      header: "Delete GI Form(s)",
      className: classes["delete-confirmation-dialog-one-ul-design"],
      accept: async () => {
        datatableHook.setLoading(true);
        const result =
          await GeneralProductInformationService.deleteGeneralProductInfoItems(
            ids
          );
        datatableHook.setLoading(false);

        if (!result.isSuccess) {
          showError(
            "Operation Failed",
            "There is an error processing your request."
          );

          return;
        }

        showSuccess("Success!", "GPI Form(s) deleted successfully.");
        refreshDataTable();

        // Remove deleted items from selected items as well.
        datatableHook.setSelectedRows(
          datatableHook.selectedRows.filter((s) => !ids.includes(s.id))
        );
      },
    });
  };

  const handleSearchKeypress = (e) => {
    if (e.key === "Enter") {
      setSearchPhrase(e.target.value);

      datatableHook.populateDataTable(
        1,
        datatableHook.pageInfo.pageCount,
        e.target.value,
        selectedRefiners
      );
    }
  };

  const isNoRowsSelected = !(datatableHook.selectedRows?.length > 0);

  const isDownloadDisabled =
    isNoRowsSelected | (datatableHook.selectedRows?.length > 10);

  const selectColumn = (
    <Column
      selectionMode="multiple"
      columnKey="select"
      alignFrozen="left"
      reorderable={false}
      frozen
      style={{ width: "40px" }}
      headerStyle={{ width: "40px" }}
    />
  );

  // Since react is in iframe, we should post message to its parent window (our angular proj) to give signal for navigation.
  const handleNavigateToGpiForm = (id, stepIndexSavePoint) => {
    let navigationInfo = "navigateToGpiWizard";

    if (id) navigationInfo += "_" + id;

    if (stepIndexSavePoint > -1) navigationInfo += "_" + stepIndexSavePoint;

    window.parent.postMessage(navigationInfo, "*");

    // React will be standalone in development, thus it is okay to ensure that the user would really navigate directly via react route.
    if (process.env.NODE_ENV === "development") {
      setTimeout(() => {
        history.push(
          "/general-product-information/form" + (id ? "?id=" + id : "")
        );
      });
    }
  };

  const isNotOwnedGpiSelected = (gpis) => {
    const userName = authProvider.getAccountInfo()?.account?.userName;

    return gpis.filter((s) => s.createdBy !== userName).length > 0;
  };

  const handleSelectedGPIsDelete = () => {
    if (isNoRowsSelected) {
      showWarning("Warning!", noSelectionWarningMessage);
      return;
    }

    handleDeleteGeneralProductInfoItems(
      datatableHook.selectedRows.map((s) => s.id)
    );
  };

  const handleSelectedGPIsDownload = async () => {
    const ids = datatableHook.selectedRows.map((s) => s.id);

    await GeneralProductInformationService.downloadMultipleAsWord(ids);
  };

  const handleEllpsisActionIconClick = (rowData) => (e) => {
    setSelectedGpiFormId(rowData.id);
    actionsPopOverRef.current.openPopUp(e);

    e.stopPropagation();
  };

  const ellipsisActionIcon = (
    <Column
      reorderable={false}
      frozen
      alignFrozen="right"
      columnKey="elipsis"
      header=""
      style={{ width: "2.5rem", padding: "0 0.5rem 0 0.5rem" }}
      body={(rowData) => (
        <div
          className="td-action-item"
          onClick={handleEllpsisActionIconClick(rowData)}
        >
          <i className="pi pi-ellipsis-v"></i>
        </div>
      )}
    />
  );

  const handleGpiFormNameModalHide = () => {
    setGpiFormNameModalVisible(false);
  };

  const updateFormNameOnDataTable = (newGpiFormName) => {
    datatableHook.updateList((currentList) =>
      currentList.map((g) =>
        g.id === selectedGpiFormId ? { ...g, gpiFormName: newGpiFormName } : g
      )
    );
  };

  const handleGpiFormNameSave = async (newGpiFormName) => {
    try {
      setGpiFormNameSaving(true);
      await GeneralProductInformationService.updateGpiFormName(
        selectedGpiFormId,
        newGpiFormName
      );
      updateFormNameOnDataTable(newGpiFormName);
      showSuccess("Success!", "GPI Form name updated successfully.");
      setGpiFormNameModalVisible(false);
      refreshDataTable();
    } catch (ex) {
      if (ex.response?.status === 400) {
        setGpiFormNameModalError(duplicateFormNameErrorMessage);
      }
    }

    setGpiFormNameSaving(false);
  };

  const handleDownloadGPIFormClick = async (id) => {
    setDownloadingGPIFormIds((prev) => [...prev, id]);
    await GeneralProductInformationService.downloadAsWord(id);
    setDownloadingGPIFormIds((prev) => prev.filter((x) => x !== id));
  };

  const handleSelectedRefinersChange = (selectedRefiners) => {
    setSelectedRefiners(selectedRefiners);

    datatableHook.populateDataTable(
      1,
      datatableHook.pageInfo.pageCount,
      searchPhrase,
      selectedRefiners
    );
  };

  const handleIsModelRefinerChange = (modelRefiner) => {
    setIsModelRefiner(modelRefiner);
  };

  const getValidationProgressBanner = () => {
    const isWarning = datatableHook.validationBannerType == "warning";
    const message = isWarning
      ? "icon has encountered an error while running the validations."
      : "icon has ongoing validations. You may still open, update and save your progress in the GPI form(s).";
    const onlyGpiWithValidationMessage = isShowOnlyGpiWithOngoingValidation
      ? "Show all forms"
      : "Show only GPI forms with ongoing validations";
    const iconType = isWarning ? "warning" : "info";
    return (
      datatableHook.isValidationBannerVisible && (
        <div
          className={`${classes["banner"]} ${classes[datatableHook.validationBannerType]
            }`}
        >
          <div>
            <h5 className={classes["message"]}>GPI Form(s) with </h5>
            <SpinnerLoaderSVG classType={iconType} />
            <h5 className={`${classes["message"]} ${classes["no-margin"]}`}>
              {message} &nbsp;
            </h5>
            {!isWarning && (
              <h5 className={classes["message"]}>
                <a
                  className={classes["link"]}
                  onClick={() => toggleShowOnlyGpiWithValidation()}
                >
                  {onlyGpiWithValidationMessage}
                </a>
              </h5>
            )}
          </div>

          <FontAwesomeIcon
            onClick={() => closeValidationBanner()}
            className={classes["close-icon"]}
            icon={faClose}
          />
        </div>
      )
    );
  };

  const closeValidationBanner = () => {
    datatableHook.setIsValidationBannerVisible(false);
  };

  const toggleShowOnlyGpiWithValidation = () => {
    if (!isShowOnlyGpiWithOngoingValidation) {
      setIsShowOnlyGpiWithOngoingValidation(true);
      datatableHook.setUnfilteredData(datatableHook.dataTableData);
      datatableHook.setDatatableData(
        datatableHook.dataTableData.filter(
          (g) => g.validationStatus == ValidationStatus.InProgress
        )
      );
      return;
    }

    setIsShowOnlyGpiWithOngoingValidation(false);
    datatableHook.setDatatableData(datatableHook.unfilteredData);
  };

  return (
    <div className={classes["container"]}>
      <GeneralProductInformationRefiners
        onSelectedRefinersChange={handleSelectedRefinersChange}
        selectedRefiners={selectedRefiners}
        modelRefinerState={handleIsModelRefinerChange}
      />

      <div className={classes["main"]}>
        <div className={classes["header"]}>
          {getValidationProgressBanner()}
          <div className={classes["sub-header"]}>
            <div className={classes["search-container"]}>
              <span className="p-input-icon-right">
                <i className="pi pi-search" />
                <InputText
                  onKeyPress={handleSearchKeypress}
                  placeholder="Search"
                />
              </span>
            </div>

            <button
              onClick={() => handleNavigateToGpiForm()}
              className={`ul-button ${classes["btn-launcher"]}`}
            >
              Launch GPI Wizard
            </button>

            <Button
              tooltip="Delete selected GPI Form(s)"
              tooltipOptions={{ position: "left" }}
              onClick={handleSelectedGPIsDelete}
              className={`ul-button ${classes["icon-button"]}`}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </Button>

            <Button
              disabled={isDownloadDisabled}
              data-pr-showondisabled
              onClick={handleSelectedGPIsDownload}
              className={`ul-button ${classes["icon-button"]} download`}
            >
              <i
                style={{ marginTop: "0px", top: "0.25rem" }}
                className="ul-icon material-icons"
              >
                download
              </i>
            </Button>

            <Tooltip target=".download" position="left">
              {isDownloadDisabled
                ? "Maximum Download is 10 GPI Forms"
                : "Download selected GPI Form(s)"}
            </Tooltip>
          </div>
        </div>

        <GpiFormNameModal
          highlightFormNameInputOnShow={true}
          isSaving={gpiFormNameSaving}
          error={gpiFormNameModalError}
          visible={gpiFormNameModalVisible}
          formName={gpiFormName}
          checkDuplicateFormName={false}
          onChangeFormName={setGpiFormName}
          label={"GPI Form Name"}
          currentFormName={selectedGpiForm?.gpiFormName}
          onCancel={handleGpiFormNameModalHide}
          onHide={handleGpiFormNameModalHide}
          onSave={handleGpiFormNameSave}
        />

        <GpiCopyFormModal
          dataFields={dataFields}
          selectedGpiForm={selectedGpiForm}
          visible={isGpiCopyFormModalVisible}
          onHide={() => handleGpiFormCopy()}
        />

        <GridActionPopover
          ref={actionsPopOverRef}
          actionList={datatableActions}
        ></GridActionPopover>

        <DataTable
          onSelectionChange={(e) => {
            datatableHook.setSelectedRows(e.value);
          }}
          onRowDoubleClick={(e) => {
            handleNavigateToGpiForm(e.data.id, e.data.stepIndexSavePoint);
          }}
          sortField={datatableHook.sortField}
          sortOrder={datatableHook.sortOrder}
          onSort={(e) => datatableHook.handleSort(e.sortField, e.sortOrder)}
          scrollDirection="both"
          onColumnResizeEnd={datatableHook.onColumnResize}
          frozen
          lazy
          size="small"
          scrollable
          selection={datatableHook.selectedRows}
          loading={datatableHook.loading}
          value={datatableHook.dataTableData}
          removableSort
          resizableColumns
          columnResizeMode="expand"
          responsiveLayout="scroll"
        >
          {selectColumn}
          {datatableHook.dynamicColumns()}
          {ellipsisActionIcon}
        </DataTable>

        <CustomPaginator
          onChangePage={datatableHook.handleChangePage}
          isOffsetPagination={true}
          isStrictNavigation={true}
          {...datatableHook.pageInfo}
          currentPageRef={datatableHook.currentPageInputRef}
        />
      </div>
    </div>
  );
};

export default GeneralProductInformation;
