import CustomerNameLookup from "../../shared/model-search/customer-name-lookup/CustomerNameLookup";
import classes from "./CustomerNameLookupPage.module.scss";

const CustomerNameLookupPage = () => {
  return (
    <div className={classes["customer-name-lookup-page-container"]}>
      <CustomerNameLookup tableHeight="calc(100vh - 250px)" />
    </div>
  );
};

export default CustomerNameLookupPage;
