import "./ImportSpreadSheet.scss";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FileUploader } from "react-drag-drop-files";
import BulkImportService from "../../../../../services/BulkImportService";
import { ProgressBar } from "primereact/progressbar";

const ImportSpreadSheet = ({ onHide }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const uploadProgressEvent = (progressEvent) => {
    const { loaded, total } = progressEvent;
    const percent = Math.floor((loaded * 100) / total);
    setUploadPercentage(percent);

    if (percent === 100) {
      setTimeout(() => {
        setIsUploading(false);
        onHide("displayAddNewCertModal");
      }, 2000);
    }
  };

  const handleChange = async (file) => {
    if (file) {
      if (!isValidFile(file.name)) {
        alert("Bulk Import, Selected file format is not supported.");
        return;
      }
      const formData = new FormData();
      formData.append("file", file, file.name);
      setIsUploading(true);
      BulkImportService.uploadBulkImportTemplate(formData, uploadProgressEvent);
    }
  };

  const isValidFile = (fileName) => {
    var ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (ext.toLowerCase() === "xlsx" || ext.toLowerCase() === "xls") {
      return true;
    } else {
      return false;
    }
  };

  const getTemplate = async () => {
    const response = await BulkImportService.downloadBulkImportTemplate();
    const data = new Blob([response]);
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "GMAWB Bulk Import Template.xlsx");
    link.click();
  };

  return (
    <div className="create-options-container">
      <span>&nbsp;</span>
      <div className="options-container">
        <div className="drag-and-drop-container">
          {isUploading && (
            <ProgressBar
              value={uploadPercentage}
              className="progress-bar"
              color="#28a745"
            />
          )}
          {!isUploading && (
            <FileUploader
              handleChange={handleChange}
              name="file"
              children={<p>Click here or drag and drop files here to upload</p>}
              classes="file-upload"
            />
          )}
        </div>
        <div className="vl"></div>
        <div className="download-icon-container" onClick={getTemplate}>
          <FontAwesomeIcon icon={faDownload} className="download-icon" />
          <span className="download-title">
            Download a sample spreadsheet to use as a template
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImportSpreadSheet;
