import clsx from "clsx";
import classes from "./ProjectOrderlineStatusTemplate.module.scss";
import ProjectOrderlineStatus from "../../enums/project-orderline-status";
import { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import ProjectService from "../../../../../services/ProjectService";
import PmrE2EProjectDetailsOrderlineModel, {
  getDisplay,
  isCompletedStatusEnabled,
  isJapan,
  isRowUpdating,
} from "../../interfaces/pmr-e2e-project-details-orderline-model";
import useToastr from "../../../../../hooks/useToastr";
import ProjectOrderlineStatusOnHoldReasonDialog from "../project-orderline-status-onhold-reason-dialog/ProjectOrderlineStatusOnHoldReasonDialog";
import { Tooltip } from "primereact/tooltip";
import {
  ProjectOrderlineStatusObject,
  ProjectOrderlineStatusOnHoldReason,
} from "../../interfaces/pmr-e2e-project-orderline-status-model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { selectOrderlineDetailList } from "../../../../../features/projectManagement/projectManagementSlice";
import useProjectOrderlineDetail from "../../../../../hooks/useProjectOrderlineDetail";
import { FlexProjectStatusOnHoldReason } from "../../interfaces/pmr-e2e-flex-project-status-onhold-reason-model";
import FlexIntegrationService from "../../../../../services/FlexIntegrationService";
import ProjectOrderlineStatusConfirmDialog from "../project-orderline-status-confirm-dialog/ProjectOrderlineStatusConfirmDialog";
import FlexProjectStatus from "../../enums/flex-project-status";

interface ProjectOrderlineStatusTemplateProps {
  status: ProjectOrderlineStatus;
  orderlineDetail: PmrE2EProjectDetailsOrderlineModel;
  reloadList: () => void;
  projectId: string;
}
const ProjectOrderlineStatusTemplate = (
  props: ProjectOrderlineStatusTemplateProps
) => {
  const [isConfirmDialogShow, setIsConfirmDialogShow] = useState(false);
  const [confirmDialogMessageText, setConfirmDialogMessageText] = useState("");
  const { showSuccess, showError, showInfo } = useToastr();
  const statusOptionsRef = useRef<HTMLDivElement>(null);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedStatus, setUpdatedStatus] =
    useState<ProjectOrderlineStatusObject>();
  const [isOnHoldReasonVisible, setIsOnHoldReasonVisible] = useState(false);
  const [flexProjectToUpdate, setFlexProjectToUpdate] = useState(new Array());

  const StatusList: ProjectOrderlineStatusObject[] = [
    {
      name: "In Progress",
      code: ProjectOrderlineStatus.InProgress,
      disabled: false,
      tooltip: ``,
    },
    {
      name: "On Hold",
      code: ProjectOrderlineStatus.OnHold,
      disabled: false,
      tooltip: ``,
    },
  ];
  const statusCompleted = {
    name: "Completed",
    code: ProjectOrderlineStatus.Completed,
    disabled: !isCompletedStatusEnabled(props.orderlineDetail),
    tooltip: isCompletedStatusEnabled(props.orderlineDetail)
      ? ``
      : `Cannot change status to "Completed" manually.\n Please complete all FLEX Tasks/GMAWB Milestones to be updated automatically.`,
  };
  const isJapanLocal = isJapan(props.orderlineDetail);
  if (isJapanLocal) StatusList.push(statusCompleted);

  const [currentStatus, setCurrentStatus] = useState(
    StatusList.find((data) => data.code === props.status)
  );

  const projectOrderlineDetailHook = useProjectOrderlineDetail(props.projectId);

  const handleStatusClick = (status: ProjectOrderlineStatus) => {
    if (status === ProjectOrderlineStatus.Completed) return;
    setCurrentStatus(StatusList.find((data) => data.code === status));
    setIsEditStatus(true);
  };
  const handleOutsideClick = (event: any) => {
    if (
      updatedStatus?.code !== ProjectOrderlineStatus.OnHold &&
      statusOptionsRef.current &&
      !statusOptionsRef.current.contains(event.target)
    ) {
      setIsEditStatus(false);
    }
  };
  const onStatusChange = async (e: DropdownChangeParams) => {
    setCurrentStatus(e.value);
    setUpdatedStatus(e.value);
    handleStatusChange(e.value);
  };

  const handleStatusChange = async (status: ProjectOrderlineStatusObject) => {
    setIsLoading(true);
    let flexProjectToUpdateList = [];
    if (status.code != ProjectOrderlineStatus.Completed) {
      const toUpdate = {
        e2EFlexProjectId: props.orderlineDetail.e2EFlexProjectId,
        flexProjectNumber: props.orderlineDetail.projectNumber,
      };
      flexProjectToUpdateList.push(toUpdate);
      setFlexProjectToUpdate([toUpdate]);
    }

    if (status.code === ProjectOrderlineStatus.OnHold) {
      setIsEditStatus(true);
      setIsOnHoldReasonVisible(true);
    } else if (status.code === ProjectOrderlineStatus.InProgress) {
      confirmShowInprogressConfirmation(status);
    } else {
      await saveStatus(
        status,
        undefined,
        flexProjectToUpdateList,
        undefined,
        false
      );
    }
    setIsLoading(false);
  };

  const confirmShowInprogressConfirmation = (
    status: ProjectOrderlineStatusObject
  ) => {
    const isFlexProjectOnHold =
      props.orderlineDetail.e2EFlexProjectStatus == FlexProjectStatus.OnHold;
    if (!isFlexProjectOnHold) {
      saveStatus(status, undefined, flexProjectToUpdate, undefined, false);
    } else {
      const data = props.orderlineDetail;
      let text = `Updating the Order Line Status for '<strong>${data?.orderLineNumber}</strong>' to '<strong>In Progress</strong>' will also set the associated FLEX Project Status to '<strong>In Progress</strong>'.`;
      text += `</br></br>How do you want to proceed?`;
      setConfirmDialogMessageText(text);
      setIsConfirmDialogShow(true);
    }
  };

  const onCancelOnHoldReason = () => {
    setIsOnHoldReasonVisible(false);
    setIsEditStatus(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [currentStatus]);

  const handleSelectOnHoldReason = (
    onHoldReason: ProjectOrderlineStatusOnHoldReason,
    flexProjectOnholdReason: FlexProjectStatusOnHoldReason
  ) => {
    const isSaveFlexProject = flexProjectOnholdReason ? true : false;
    saveStatus(
      updatedStatus,
      onHoldReason,
      flexProjectToUpdate,
      flexProjectOnholdReason,
      isSaveFlexProject
    );
  };

  const saveStatus = async (
    status: ProjectOrderlineStatusObject | undefined,
    onHoldReason: ProjectOrderlineStatusOnHoldReason | undefined,
    flexProjectToUpdateList: any[],
    flexProjectOnholdReason: FlexProjectStatusOnHoldReason | undefined,
    isSaveFlexProject: boolean
  ) => {
    //orderlines to update status
    const listForUpdatePayload = [
      {
        e2EProjectLineId: props.orderlineDetail.id,
        status: status?.code,
        fulFillmentStatusDetailId:
          status?.code == ProjectOrderlineStatus.OnHold
            ? onHoldReason?.fulFillmentStatusDetailId
            : null,
        fulFillmentStatusDetail:
          status?.code == ProjectOrderlineStatus.OnHold
            ? onHoldReason?.fulFillmentStatusDetail
            : null,
        e2EFlexProjectId: props.orderlineDetail.e2EFlexProjectId,
      },
    ];

    let flexProjectToUpdateRequestList: any[] = [];
    //flex project list if has for update status
    if (isSaveFlexProject) {
      if (flexProjectToUpdateList && flexProjectToUpdateList.length > 0) {
        flexProjectToUpdateRequestList = flexProjectToUpdateList.map((item) => {
          return {
            e2EFlexProjectId: item.e2EFlexProjectId,
            status: projectOrderlineDetailHook.getFlexProjectStatus(
              status?.code
            ),
            projectStatusReasonId:
              status?.code == ProjectOrderlineStatus.OnHold
                ? flexProjectOnholdReason?.projectStatusDetailId
                : null,
            projectStatusReason:
              status?.code == ProjectOrderlineStatus.OnHold
                ? flexProjectOnholdReason?.projectStatusReason
                : null,
          };
        });
      }
    }

    const model = {
      projectLineStatuses: listForUpdatePayload,
      flexProjectStatuses: flexProjectToUpdateRequestList,
    };

    projectOrderlineDetailHook.setWritebackStatusInprogressOnSave([
      ...[props.orderlineDetail],
    ]);

    const result = await ProjectService.updateOrderlineStatus(model);
    if (result.isSuccess) {
    } else {
      let header = `Update Failed`;
      let text = `Failed to update status for '<strong>${props.orderlineDetail.orderLineNumber}</strong>'. Please try again. Contact the support team if issue persists.`;
      showError(header, text);
      projectOrderlineDetailHook.setWritebackStatusToNone([
        ...[props.orderlineDetail],
      ]);
    }
    setIsEditStatus(false);
    setCurrentStatus(undefined);
    setUpdatedStatus(undefined);
    setIsOnHoldReasonVisible(false);
  };

  const statusOptionTemplate = (option: ProjectOrderlineStatusObject) => {
    return (
      <div
        className={`status-${option.code}`}
        style={{
          width: "100%",
          pointerEvents: "auto",
        }}
      >
        {getDisplay(option.code)}
        <Tooltip
          target={`.status-${option.code}`}
          className="custom-tooltip"
          my="left top"
          at="left bottom"
        >
          {option.tooltip}
        </Tooltip>
      </div>
    );
  };

  const getClass = () => {
    switch (props.status) {
      case ProjectOrderlineStatus.InProgress:
        return classes["inprogress"];
      case ProjectOrderlineStatus.OnHold:
        return classes["onhold"];
      case ProjectOrderlineStatus.Completed:
        return classes["completed"];
      default:
        return "disabled";
    }
  };

  const loadingIndicator = (
    <div style={{ textAlign: "center", width: "100%" }}>
      <FontAwesomeIcon icon={faSpinner} spin={true} />
    </div>
  );

  if (isRowUpdating(props.orderlineDetail)) return loadingIndicator;

  return (
    <>
      {isEditStatus ? (
        <span ref={statusOptionsRef}>
          <Dropdown
            value={currentStatus}
            options={StatusList}
            optionLabel="name"
            onChange={onStatusChange}
            className={classes["custom-dropdown"]}
            style={{ fontSize: "20px" }}
            optionDisabled="disabled"
            itemTemplate={statusOptionTemplate}
            disabled={isLoading}
          />
        </span>
      ) : (
        <span
          className={clsx(
            classes["custom-badge"],
            getClass(),
            props.orderlineDetail.orderLineStatus ==
              ProjectOrderlineStatus.OnHold
              ? `tooltip-status-target-${props.orderlineDetail?.id}`
              : ``
          )}
          onClick={() => handleStatusClick(props.status)}
        >
          {getDisplay(props.status)}
          <Tooltip
            target={`.tooltip-status-target-${props.orderlineDetail?.id}`}
            className="custom-tooltip"
            my="left top"
            at="left bottom"
          >
            {props.orderlineDetail?.fulFillmentStatusDetail}
          </Tooltip>
        </span>
      )}
      {isOnHoldReasonVisible && (
        <ProjectOrderlineStatusOnHoldReasonDialog
          flexProjectToUpdate={flexProjectToUpdate}
          closeModal={() => onCancelOnHoldReason()}
          visible={isOnHoldReasonVisible}
          handleSelectOnHoldReason={handleSelectOnHoldReason}
        />
      )}
      {isConfirmDialogShow && (
        <ProjectOrderlineStatusConfirmDialog
          isConfirmDialogShow={isConfirmDialogShow}
          setIsConfirmDialogShow={setIsConfirmDialogShow}
          messageText={confirmDialogMessageText}
          saveOrderline={() => {
            saveStatus(
              updatedStatus,
              undefined,
              flexProjectToUpdate,
              undefined,
              false
            );
          }}
          saveOrderlineAndFlexProject={() => {
            saveStatus(
              updatedStatus,
              undefined,
              flexProjectToUpdate,
              undefined,
              true
            );
          }}
          isMultipleLines={false}
        ></ProjectOrderlineStatusConfirmDialog>
      )}
    </>
  );
};
export default ProjectOrderlineStatusTemplate;
