import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./YourFiltersItem.scss";
import {
  faTimes,
  faMinus,
  faCaretDown,
  faCaretRight
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const YourFiltersItem = (props) => {
  const [isShownYourFiltersBody, setIsShownYourFiltersBody] = useState(true);

  const { refinerColumns, refinerId, columnRefiners, onClickRemoveRefiner, onClickRemoveRefinerGroup } = props;

  const toggleYourFiltersBody = () => {
    setIsShownYourFiltersBody((prevState) => !prevState);
  };

  const getHeaderNameByKey = (key) => {
    const headerObj = refinerColumns.find((i) => i.Id === key);
    return headerObj ? headerObj.Value : "";
  };


  return (
    <>
      <div className="ul-grid__row -wrap font-weight-bold your-filters-header">
        <div className="ul-grid__column -x90 cursor-pointer" onClick={toggleYourFiltersBody}>
          <span>
            <FontAwesomeIcon
              icon={isShownYourFiltersBody? faCaretDown : faCaretRight}
              onClick={toggleYourFiltersBody}
            />
          </span>
          <span>&nbsp;&nbsp;{getHeaderNameByKey(refinerId)}</span>
        </div>
        <div className="ul-grid__column -x10 cursor-pointer">
          <FontAwesomeIcon icon={faMinus} onClick={() => onClickRemoveRefinerGroup(refinerId)} />
        </div>
      </div>
      {isShownYourFiltersBody && (
        <>
          {columnRefiners.map((item) => (
            <div key={item.Id+item.DisplayText} className="ul-grid__row -wrap your-filters-body">
              <div className="ul-grid__column -x90">{item.DisplayText}</div>
              <div className="ul-grid__column -x10 cursor-pointer">
                <FontAwesomeIcon icon={faTimes} onClick={() => onClickRemoveRefiner(item)} />
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};
export default YourFiltersItem;
