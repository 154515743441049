import { ReactNode } from "react";
import { BlockUI } from "primereact/blockui";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ProjectTemplateMappingService from "../../services/ProjectTemplateMappingService";
import { E2EMilestoneModel } from "../../shared/models/E2EMilestone.model";
import {
  FlexProjectTemplateDetailModel,
  FlexProjectTemplateTaskDetailModel,
} from "../../shared/models/FlexProjectTemplateTaskDetail.model";
import { ProjectTemplateMappingHeaderModel } from "../../shared/models/ProjectTemplateMappingHeader.model";
import { ProjectTemplateMappingModel } from "../../shared/models/ProjectTemplateMappingModel";
import { TemplateAssociationModel } from "../../shared/models/TemplateAssociation.model";
import { Accordion, AccordionTab } from "primereact/accordion";
import ProjectTemplateMappingFooter from "./project-template-mapping-footer/ProjectTemplateMappingFooter";
import ProjectTemplateMappingEditGrid from "./project-template-mapping-grid/project-template-mapping-edit-grid.tsx/ProjectTemplateMappingEditGrid";
import ProjectTemplateMappingGrid from "./project-template-mapping-grid/ProjectTemplateMappingGrid";
import ProjectTemplateMappingHeader from "./project-template-mapping-header/ProjectTemplateMappingHeader";
import ProjectTemplateSchemeEditGrid from "./project-template-scheme-grid/project-template-scheme-edit-grid/ProjectTemplateSchemeEditGrid";
import ProjectTemplateSchemeGrid from "./project-template-scheme-grid/ProjectTemplateSchemeGrid";
import classes from "./ProjectTemplateMapping.module.scss";
import { ProjectTemplateMappingModes } from "./shared/enums/ProjectTemplateMappingModes";
import useToastr from "../../hooks/useToastr";
import { SelectListResultModel } from "../../shared/models/service-models/SelectListResult.model";
import { checkDuplicates } from "./project-template-scheme-grid/project-template-scheme-edit-grid/ProjectTemplateSchemeEditGrid";
import projectTemplateMappingSlice from "../../features/projectTemplateMapping/projectTemplateMappingSlice";
import { FlexTaskSettingsModel } from "../../shared/models/FlexTaskSettings.model";
import { moveItem } from "../../utils/helpers/array.helpers";

interface ProjectTemplateMappingInitialLoadingFlag {
  IsAssociatedSchemeLoading: boolean;
  IsHeaderLoading: boolean;
  IsTemplateMappingLoading: boolean;
  isFetchSchemesLoading: boolean;
}

export interface ProjectTemplateComponentData {
  flexTemplateList: FlexProjectTemplateDetailModel[];
  header?: ProjectTemplateMappingHeaderModel;
  associatedSchemeData: TemplateAssociationModel[];
  e2eGridData: ProjectTemplateMappingModel[];
  e2eMilestoneDropdownValues: E2EMilestoneModel[];
  flexTaskDetails?: FlexProjectTemplateDetailModel;
  predecessorDropdownItems: E2EPredecessorPropsWrapper[];
  additionalInfoList: Array<SelectListResultModel>;
  certificateSchemeList: Array<SelectListResultModel>;
}

export interface ProjectTemplateComponentErrors {
  headerErrors: ProjectTemplateHeaderErrors;
  milestoneErrors: ProjectTemplateMilestoneErrors[];
}

interface ProjectTemplateHeaderErrors {
  templateName: string | ReactNode | null;
}

export interface ProjectTemplateMilestoneErrors {
  milestone: string | null;
  predecessor: string | null;
  sortOrder: number | null;
  flexTaskNumber: number | null;
}

export interface BlockUIProps {
  blockUI: boolean;
  blockUIMessage: string;
}

interface E2EPredecessorProps {
  [key: string]: string | number;
  id: string;
  name: string;
}

export interface E2EPredecessorPropsWrapper {
  sortOrder: number;
  predecessorItems: E2EPredecessorProps[];
}

const ProjectTemplateMapping = () => {
  const { showSuccess, showError } = useToastr();
  const queryParams = new URLSearchParams(window.location.search);
  const getTemplateId = queryParams.get("id");
  const history = useHistory();

  const location = useLocation<ProjectTemplateMappingHeaderModel>();

  const [fetchNewFlexTasks, setFetchNewFlexTasks] = useState<boolean>(false);
  const [flexTaskSettings, setFlexTaskSettings] = useState<
    FlexTaskSettingsModel[]
  >([]);

  const resetBlockUI: BlockUIProps = {
    blockUI: false,
    blockUIMessage: "",
  };

  const [blockUI, setBlockUI] = useState<BlockUIProps>(resetBlockUI);
  const [isCancelConfirmatonModalVisible, setIsCancelConfirmatonModalVisible] =
    useState<boolean>(false);

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState<boolean>(false);

  const [componentLoadingProps, setComponentLoadingProps] =
    useState<ProjectTemplateMappingInitialLoadingFlag>({
      IsAssociatedSchemeLoading: true,
      IsHeaderLoading: true,
      IsTemplateMappingLoading: true,
      isFetchSchemesLoading: true,
    });

  const [componentData, setComponentData] =
    useState<ProjectTemplateComponentData>({
      flexTemplateList: [],
      associatedSchemeData: [],
      e2eGridData: [],
      e2eMilestoneDropdownValues: [],
      predecessorDropdownItems: [],
      additionalInfoList: [],
      certificateSchemeList: [],
    });

  const [componentErrors, setComponentErrors] =
    useState<ProjectTemplateComponentErrors | null>(null);

  const itemsPlaceholderForSkeleton = Array.from({ length: 5 }, (v, i) => i);

  const [unmodifiedMappingJsonValue, setUnmodifiedMappingJsonValue] =
    useState<string>("");

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      ProjectTemplateMappingService.getE2EMilestones().then((milestones) => {
        if (getTemplateId) {
          fetchProjectTemplateById(getTemplateId);
        }

        if (location.state) {
          if (location.state?.id) {
            fetchProjectTemplateById(location.state.id);
          } else {
            ProjectTemplateMappingService.getFlexTemplateListIntegration().then(
              (flexResponse) => {
                setComponentData((current) => ({
                  ...current,
                  header: location.state,
                  associatedSchemeData: [
                    {
                      id: null,
                      schemeId: null,
                      schemeName: null,
                      certificateSchemeList: [],
                      deliveryPathId: null,
                      deliveryPathName: null,
                      deliveryPathListResult: [],
                      applicationTypeId: null,
                      applicationTypeName: null,
                      applicationTypeListResult: [],
                      additionalInfoId: null,
                      additionalInfoName: null,
                      additionalInfoListResult: [],
                    },
                  ],
                  flexTemplateList: flexResponse,
                }));
              }
            );

            fetchFlexTasks(
              location.state.flexTemplateId,
              location.state.flexCorrelationId!,
              milestones.data
            );
          }
        }
        setComponentData((current) => ({
          ...current,
          e2eMilestoneDropdownValues: milestones.data,
        }));
      });
    }

    return () => {
      mounted = false;
      window.history.replaceState({}, document.title);
    };
  }, []);

  useEffect(() => {
    fetchAdditionalInfo();
  }, []);

  useEffect(() => {
    fetchCertificateSchemeByAddInfo();
  }, [componentData.header?.additionalInfoId]);

  useEffect(() => {
    ProjectTemplateMappingService.getFlexTaskSettings().then((result) => {
      if (result.isSuccess) setFlexTaskSettings(result.data);
    });
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (
        componentData.header &&
        componentData.flexTemplateList &&
        componentLoadingProps.IsHeaderLoading
      ) {
        setComponentLoadingProps((current) => ({
          ...current,
          IsHeaderLoading: false,
        }));
      }

      if (
        componentData.associatedSchemeData &&
        componentData.associatedSchemeData.length > 0 &&
        componentLoadingProps.IsAssociatedSchemeLoading
      ) {
        setComponentLoadingProps((current) => ({
          ...current,
          IsAssociatedSchemeLoading: false,
        }));
      }

      if (
        componentData.e2eGridData &&
        componentData.e2eGridData.length > 0 &&
        componentData.e2eMilestoneDropdownValues &&
        componentData.e2eMilestoneDropdownValues.length > 0 &&
        componentLoadingProps.IsTemplateMappingLoading
      ) {
        setComponentLoadingProps((current) => ({
          ...current,
          IsTemplateMappingLoading: false,
        }));
      }

      if (
        !componentLoadingProps.IsHeaderLoading &&
        !componentLoadingProps.IsTemplateMappingLoading &&
        !componentLoadingProps.IsAssociatedSchemeLoading &&
        unmodifiedMappingJsonValue === "" &&
        componentData.header?.templateMode !== ProjectTemplateMappingModes.View
      ) {
        setUnmodifiedMappingJsonValue(
          JSON.stringify({
            header: componentData.header,
            schemes: componentData.associatedSchemeData,
            grid: componentData.e2eGridData,
          })
        );
      }
    }

    return () => {
      mounted = false;
    };
  }, [componentData]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (
        componentData.header &&
        fetchNewFlexTasks &&
        componentData.e2eMilestoneDropdownValues.length > 0
      ) {
        setBlockUI({
          blockUI: true,
          blockUIMessage: "Fetching New Flex Tasks...",
        });

        fetchFlexTasks(
          componentData.header.flexTemplateId,
          componentData.header.flexCorrelationId!,
          componentData.e2eMilestoneDropdownValues
        );
      }
    }

    return () => {
      mounted = false;
    };
  }, [fetchNewFlexTasks, componentData.header]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setComponentData((current) => ({
        ...current,
        predecessorDropdownItems: populateE2EPredecessor(
          componentData.e2eGridData
        ),
      }));
    }

    return () => {
      mounted = false;
    };
  }, [componentData.e2eGridData]);

  const historyNavigate = (path: string) => {
    history.push(path);
  };

  const populateE2EPredecessor = (
    e2eGridData: ProjectTemplateMappingModel[]
  ) => {
    const returnData: E2EPredecessorPropsWrapper[] = e2eGridData.map(
      (data, index) => {
        return {
          sortOrder: index,
          predecessorItems: e2eGridData
            .filter(
              (x) =>
                x.e2EMilestoneId &&
                x.e2EMilestoneId !== "" &&
                x.sortOrder < index
            )
            .map((e2emilestones) => {
              return {
                id: e2emilestones.e2EMilestoneId,
                name: e2emilestones.e2EMilestoneName,
              } as E2EPredecessorProps;
            }),
        } as E2EPredecessorPropsWrapper;
      }
    );

    return returnData;
  };

  const sortFlexTasks = (
    tasks: FlexProjectTemplateTaskDetailModel[] | null,
    taskNameToCustomOrder: string[]
  ) => {
    const tasksCopy = [...(tasks || [])].sort(
      (
        a: FlexProjectTemplateTaskDetailModel,
        b: FlexProjectTemplateTaskDetailModel
      ) => (a.taskNumber! > b.taskNumber! ? 1 : -1)
    );

    tasks?.forEach((task) => {
      const taskCopy = tasksCopy.find((c) => c === task)!;
      const taskCopyIndex = tasksCopy.findIndex((c) => c === task);

      const predecessorElementIndex = tasksCopy.findIndex(
        (t) => t?.taskNumber === +taskCopy.predecessorTaskNumber!
      );

      if (
        predecessorElementIndex > -1 &&
        taskNameToCustomOrder.includes(taskCopy.taskName!)
      ) {
        moveItem(tasksCopy, taskCopyIndex, predecessorElementIndex + 1);
      }
    });

    return tasksCopy;
  };

  const fetchFlexTasks = (
    flexTemplateId: string,
    flexCorrelationId: string,
    milestones: E2EMilestoneModel[]
  ) => {
    setComponentLoadingProps((current) => ({
      ...current,
      IsTemplateMappingLoading: true,
    }));

    ProjectTemplateMappingService.getFlexTemplateDetailIntegration(
      flexTemplateId
    )
      .then((response) => {
        const flexTemplates: ProjectTemplateMappingModel[] = sortFlexTasks(
          response.projectTemplateTaskTemplateDetails,
          ["GMA TC_Processing (non-UL CO)"]
        )!
          .filter(
            (x) =>
              !x.taskName?.toLowerCase().includes("flex template confirmed")
          )
          .map(
            (flexTasks: FlexProjectTemplateTaskDetailModel, index: number) => {
              const taskItem: ProjectTemplateMappingModel = {
                id: null,
                flexTaskNumber: flexTasks.taskNumber!,
                sortOrder: index,
                flexProjectTemplateTaskTemplateId:
                  flexTasks.projectTemplateTaskTemplateId!,
                flexTaskName: flexTasks.taskName!,
                flexTaskDescription: flexTasks.taskDescription!,
                flexTaskAverageDuration: flexTasks.taskAverageDuration!,
                flexTaskRevenuePhase: flexTasks.taskRevenuePhase!,
                flexParentTaskNumber: flexTasks.parentTaskNumber!,
                flexPredecessorTaskNumber: flexTasks.predecessorTaskNumber!,
                e2EMilestoneId: null,
                e2EMilestoneName: null,
                e2EMilestonePredecessorId: null,
                e2EMilestonePredecessorName: null,
              };

              return taskItem;
            }
          );

        let sortOrder = 0;
        ProjectTemplateMappingService.getE2EMilestoneFlexTaskDefault(
          flexCorrelationId
        ).then((defaultResponse) => {
          if (defaultResponse.data) {
            const newGrid = flexTemplates
              .map((gridItem) => {
                const groupedMilestones = defaultResponse.data.find(
                  (defaultTask) =>
                    defaultTask.flexTaskName?.toUpperCase() ===
                    gridItem.flexTaskName?.toUpperCase()
                );

                if (groupedMilestones) {
                  return groupedMilestones.list.map(
                    (milestone) =>
                      ({
                        e2EMilestoneId: milestone.e2EMilestoneId,
                        e2EMilestoneName: milestones.find(
                          (x) => x.id === milestone.e2EMilestoneId
                        )?.name,
                        e2EMilestonePredecessorId:
                          milestone.e2EMilestonePredecessorId,
                        e2EMilestonePredecessorName: milestones.find(
                          (x) => x.id === milestone.e2EMilestonePredecessorId
                        )?.name,
                        flexParentTaskNumber: gridItem.flexParentTaskNumber,
                        flexPredecessorTaskNumber:
                          gridItem.flexPredecessorTaskNumber,
                        flexProjectTemplateTaskTemplateId:
                          gridItem.flexProjectTemplateTaskTemplateId,
                        flexTaskAverageDuration:
                          gridItem.flexTaskAverageDuration,
                        flexTaskDescription: gridItem.flexTaskDescription,
                        flexTaskName: gridItem.flexTaskName,
                        flexTaskNumber: gridItem.flexTaskNumber,
                        flexTaskRevenuePhase: gridItem.flexTaskRevenuePhase,
                        id: gridItem.id,
                        sortOrder: sortOrder++,
                      } as ProjectTemplateMappingModel)
                  );
                } else {
                  gridItem.sortOrder = sortOrder++;
                  return gridItem;
                }
              })
              .flat();

            setComponentData((current) => ({
              ...current,
              e2eGridData: newGrid,
              flexTaskDetails: response,
            }));
          } else {
            setComponentData((current) => ({
              ...current,
              e2eGridData: flexTemplates,
              flexTaskDetails: response,
            }));
          }
        });
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setFetchNewFlexTasks(false);

        if (isConfirmationModalVisible) {
          handleFlexTemplateModalVisibility(false);
          showSuccess(
            "Change Mapped FLEX Template",
            "Mapped FLEX Template updated successfully."
          );
        }

        setComponentErrors((current: any) => ({
          ...current,
          milestoneErrors: [],
        }));
        setBlockUI(resetBlockUI);
      });
  };

  const fetchAdditionalInfo = () => {
    ProjectTemplateMappingService.getAdditionalInfoAll()
      .then((response) => {
        setComponentData((current) => ({
          ...current,
          additionalInfoList: response.data,
        }));
      })
      .catch((error) => console.error(error));
  };
  const fetchCertificateSchemeByAddInfo = () => {
    if (!componentData.header?.additionalInfoId) {
      setComponentLoadingProps((current) => ({
        ...current,
        isFetchSchemesLoading: false,
      }));

      setComponentData((current) => ({
        ...current,
        certificateSchemeList: [],
      }));

      return;
    }
    setComponentLoadingProps((current) => ({
      ...current,
      isFetchSchemesLoading: true,
    }));
    ProjectTemplateMappingService.getSchemesByAddInfoId(
      componentData.header?.additionalInfoId ?? ""
    )
      .then((response) => {
        setComponentData((current) => ({
          ...current,
          certificateSchemeList: response.data,
        }));

        setComponentLoadingProps((current) => ({
          ...current,
          isFetchSchemesLoading: false,
        }));
      })
      .catch((error) => console.error(error));
  };

  const fetchProjectTemplateById = (templateId: string) => {
    ProjectTemplateMappingService.getProjectTemplateById(templateId)
      .then((response) => {
        ProjectTemplateMappingService.getFlexTemplateListIntegration().then(
          (flexResponse: any) => {
            if (getTemplateId) {
              response.data.header.templateMode =
                ProjectTemplateMappingModes.View;
            }

            ProjectTemplateMappingService.getFlexTemplateDetailIntegration(
              response.data.header.flexTemplateId
            ).then((flexTemplateDetailResponse) => {
              if (location.state?.name) {
                response.data.header.name = location.state.name;
              }

              if (
                location.state?.templateMode ===
                ProjectTemplateMappingModes.Copy
              ) {
                response.data.header.templateMode = location.state.templateMode;
                response.data.header.id = null;
                response.data.header.correlationId = null;
              }

              response.data.templateTaskInfos.map(
                (tasks: ProjectTemplateMappingModel) => {
                  if (
                    flexTemplateDetailResponse?.projectTemplateTaskTemplateDetails &&
                    flexTemplateDetailResponse
                      ?.projectTemplateTaskTemplateDetails?.length > 0
                  ) {
                    const getFlexDetails =
                      flexTemplateDetailResponse.projectTemplateTaskTemplateDetails?.find(
                        (x) => x.taskNumber === tasks.flexTaskNumber
                      );

                    if (getFlexDetails) {
                      tasks.flexTaskDescription =
                        getFlexDetails.taskDescription!;
                      tasks.flexTaskAverageDuration =
                        getFlexDetails.taskAverageDuration!;
                      tasks.flexTaskRevenuePhase =
                        getFlexDetails.taskRevenuePhase!;
                      tasks.flexParentTaskNumber =
                        getFlexDetails.parentTaskNumber!;
                      tasks.flexPredecessorTaskNumber =
                        getFlexDetails.predecessorTaskNumber!;
                    }
                  }

                  return tasks;
                }
              );

              if (response.data.associationSchemeInfos.length > 0) {
                response.data.header.additionalInfoId =
                  response.data.associationSchemeInfos[0].additionalInfoId;
              }

              setComponentData((current) => ({
                ...current,
                header: response.data.header,
                flexTemplateList: flexResponse,
                associatedSchemeData: response.data.associationSchemeInfos,
                e2eGridData: response.data.templateTaskInfos,
                flexTaskDetails: flexTemplateDetailResponse,
              }));
            });
          }
        );
      })
      .catch((error) => console.error(error));
  };

  const assocSchemeAccordion = () => {
    if (
      componentData.header &&
      componentData.associatedSchemeData &&
      componentData.associatedSchemeData.length > 0
    ) {
      if (
        componentData.header.templateMode !== ProjectTemplateMappingModes.View
      ) {
        const newAssocSchemeData = checkDuplicates(
          componentData.associatedSchemeData
        );

        return (
          <div className={`${classes["assocSchemeAccordion"]}`}>
            <ProjectTemplateSchemeEditGrid
              assocSchemeData={newAssocSchemeData}
              setComponentData={setComponentData}
              schemeList={componentData.certificateSchemeList}
              additionalInfoId={componentData.header.additionalInfoId ?? ""}
              isFetchSchemesLoading={
                componentLoadingProps.isFetchSchemesLoading
              }
            />
          </div>
        );
      } else {
        return (
          <div className={`${classes["assocSchemeAccordion"]}`}>
            <ProjectTemplateSchemeGrid
              assocSchemeData={componentData.associatedSchemeData}
            />
          </div>
        );
      }
    } else {
      return (
        <DataTable
          value={itemsPlaceholderForSkeleton}
          className={`p-datatable-striped`}
          size={"small"}
        >
          <Column
            header={"Certificate Scheme"}
            style={{ width: "25%" }}
            body={<Skeleton />}
          ></Column>

          <Column
            header="Application Type"
            style={{ width: "25%" }}
            body={<Skeleton />}
          ></Column>
          <Column
            header="Delivery Path(s)"
            style={{ width: "25%" }}
            body={<Skeleton />}
          ></Column>
        </DataTable>
      );
    }
  };

  const TemplateMappingComponent = () => {
    if (
      componentData.header &&
      componentData.e2eGridData &&
      componentData.e2eMilestoneDropdownValues
    ) {
      if (
        componentData.header.templateMode !== ProjectTemplateMappingModes.View
      ) {
        if (componentData.e2eGridData.length === 0) {
          return <TemplateMappingSkeleton />;
        } else {
          return (
            <ProjectTemplateMappingEditGrid
              flexTaskSettings={flexTaskSettings}
              headerData={componentData.header}
              e2eGrid={componentData.e2eGridData}
              setComponentData={setComponentData}
              e2EMilestoneDropDownValues={
                componentData.e2eMilestoneDropdownValues
              }
              componentErrors={componentErrors}
              setComponentErrors={setComponentErrors}
              predecessorDropdownItems={componentData.predecessorDropdownItems}
              populateE2EPredecessor={populateE2EPredecessor}
            />
          );
        }
      } else {
        return (
          <ProjectTemplateMappingGrid e2eGrid={componentData.e2eGridData} />
        );
      }
    } else {
      return <TemplateMappingSkeleton />;
    }
  };

  const TemplateMappingSkeleton = () => {
    const FlexTaskIdNameHeaderTemplate = () => {
      return (
        <>
          <div className={"ul-grid__row"}>
            <div className="ul-grid__column -x50">
              <div className={"column-fill"}>FLEX Task ID</div>
            </div>
            <div className={"ul-grid__column -x70"}>
              <div className={"column-fill"}>FLEX Task Name</div>
            </div>
          </div>
        </>
      );
    };
    return (
      <DataTable
        value={itemsPlaceholderForSkeleton}
        className={`p-datatable-striped`}
        size={"small"}
      >
        <Column
          header={FlexTaskIdNameHeaderTemplate}
          style={{ width: "40%" }}
          body={<Skeleton />}
        ></Column>
        <Column
          header="GMA WB Template Milestone"
          style={{ width: "30%" }}
          body={<Skeleton />}
        ></Column>
        <Column
          header="GMA WB Template Milestone Predecessor"
          style={{ width: "30%" }}
          body={<Skeleton />}
        ></Column>
      </DataTable>
    );
  };

  const blockedTemplateBody = () => {
    return (
      <div className={`${classes["project-template__spinner"]}`}>
        <ProgressSpinner className={`${classes["spinner"]}`} />
        <p className={`${classes["spinner-label"]}`}>
          {blockUI.blockUIMessage}
        </p>
      </div>
    );
  };

  const handleCancel = () => {
    if (componentData.header) {
      const isUntouched =
        componentData.header.templateMode !== ProjectTemplateMappingModes.View
          ? componentData.header.templateMode ===
              ProjectTemplateMappingModes.Add ||
            componentData.header.templateMode ===
              ProjectTemplateMappingModes.Copy
            ? false
            : unmodifiedMappingJsonValue === ""
            ? true
            : unmodifiedMappingJsonValue ===
              JSON.stringify({
                header: componentData.header,
                schemes: componentData.associatedSchemeData,
                grid: componentData.e2eGridData,
              })
          : true;

      if (!isUntouched) {
        setIsCancelConfirmatonModalVisible(true);
      } else {
        history.push("/template-list-view");
      }
    }
  };

  const handleConfirmationModalResult = (isNo: boolean) => {
    if (!isNo) history.push("/template-list-view");
    else setIsCancelConfirmatonModalVisible(false);
  };

  const handleFlexTemplateModalVisibility = (isOpen: boolean) => {
    setIsConfirmationModalVisible(isOpen);
  };
  return (
    <div
      id="template-admin-body"
      className={`${classes["project-template__body"]}`}
    >
      <BlockUI
        blocked={blockUI.blockUI}
        fullScreen={true}
        template={blockedTemplateBody}
      >
        <div className={`${classes["project-template__header"]}`}>
          {componentLoadingProps.IsHeaderLoading === false &&
            componentData.header && (
              <ProjectTemplateMappingHeader
                headerData={componentData.header}
                setComponentData={setComponentData}
                flexTemplateList={componentData.flexTemplateList}
                setFetchNewFlexTasks={setFetchNewFlexTasks}
                componentErrors={componentErrors}
                setComponentErrors={setComponentErrors}
                isConfirmationModalVisible={isConfirmationModalVisible}
                FlexTemplateModalVisibility={handleFlexTemplateModalVisibility}
                additionalInfoList={componentData.additionalInfoList}
                associatedSchemeData={componentData.associatedSchemeData}
              />
            )}

          {componentLoadingProps.IsHeaderLoading === true && (
            <div className="ul-grid__row" style={{ gap: "20px" }}>
              <div className="ul-grid__column">
                <div className="column-fill -x40">
                  <Skeleton height="3rem" className="mb-2"></Skeleton>
                </div>
              </div>
              <div className="ul-grid__column -x20">
                <div className="column-fill">
                  <Skeleton height="3rem" className="mb-2"></Skeleton>
                </div>
              </div>
              <div className="ul-grid__column -x40">
                <div className="column-fill">
                  <Skeleton height="3rem" className="mb-2"></Skeleton>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={`${classes["project-template__associatedSchemes"]}`}>
          <Accordion activeIndex={0}>
            <AccordionTab header={"Associated Schemes"}>
              {assocSchemeAccordion()}
            </AccordionTab>
          </Accordion>
        </div>
        <div className={`${classes["project-template__e2eMilestones"]}`}>
          {TemplateMappingComponent()}
        </div>

        <div className={`${classes["footer"]}`}>
          {componentLoadingProps.IsHeaderLoading === false &&
            componentData.header &&
            ProjectTemplateMappingFooter({
              headerData: componentData?.header,
              assocSchemeData: componentData.associatedSchemeData,
              e2eGridData: componentData.e2eGridData,
              setBlockUI: setBlockUI,
              handleCancel: handleCancel,
              loadingStates: componentLoadingProps,
              componentErrors: componentErrors,
              setComponentErrors: setComponentErrors,
              historyNavigate: historyNavigate,
              unmodifiedMappingJsonValue: unmodifiedMappingJsonValue,
              isCancelModalVisible: isCancelConfirmatonModalVisible,
              handleConfirmationModalResult: handleConfirmationModalResult,
              additionalInfoList: componentData.additionalInfoList,
            })}
        </div>
      </BlockUI>
    </div>
  );
};

export default ProjectTemplateMapping;
