import { OverlayPanel } from "primereact/overlaypanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import classes from "./ShowHideColumnsPrimeDataTable.module.scss";
import { GridColumnModel } from "../../pages/project-management/data/project-work-queue-grid-columns";

interface ShowHideColumnsPrimeDataTableProps {
  fields: GridColumnModel[];
  frozenColumnCount: number;
  updateColumns: Function;
  setColumns: Function;
  maxFrozenColumnCount: number;
  hasShowAndFreezeSelection: boolean;
}

const ShowHideColumnsPrimeDataTable = (
  props: ShowHideColumnsPrimeDataTableProps
) => {
  const op = useRef<any>(null);
  const isSelectAll = props.fields.every((f: any) => f.isShown);
  const dynamicOptions = [...Array(props.maxFrozenColumnCount).keys()].map(
    (num: number, i: number) => {
      return (
        <option
          key={i}
          value={num + 1}
          aria-selected={props.frozenColumnCount == num + 1 ? true : false}
        >
          {num + 1}
        </option>
      );
    }
  );

  const rowClass = (data: any) => {
    if (props.hasShowAndFreezeSelection) {
      return {
        [classes.rowfrozen]: data.isFrozen,
      };
    }
    return {};
  };

  const onRowReorder = (e: any) => {
    const newColumns = [...e.value];
    const columns = newColumns.map((col, i) => {
      return { ...col, orderNo: i + 1 };
    });

    props.updateColumns(props.frozenColumnCount, columns);
  };

  const showColumn = (e: any) => {
    const { id, checked } = e.target;
    const columns = props.fields.map((f: any) => {
      if (f.id === id) {
        return {
          ...f,
          isShown: checked,
        };
      }
      return f;
    });

    props.setColumns(columns);
  };

  const onFrozenColCountChange = (e: any) => {
    const frozenCount = e.target.value;
    props.updateColumns(frozenCount, props.fields);
  };

  const onSelectAll = (e: any) => {
    const { checked } = e.target;

    const columns = props.fields.map((f: any) => {
      if (checked) {
        return {
          ...f,
          isShown: true,
        };
      }

      if (f.isFrozen) {
        return f;
      }

      return {
        ...f,
        isShown: false,
      };
    });

    props.setColumns(columns);
  };

  return (
    <>
      <button
        title="Show/Hide Columns"
        type="button"
        className={`ul-button ${classes["show-hide-button"]}`}
        onClick={(e) => op.current.toggle(e)}
      >
        <FontAwesomeIcon icon={faBookOpen}></FontAwesomeIcon>
      </button>

      <OverlayPanel
        ref={op}
        dismissable
        showCloseIcon
        className={`${classes["overlay-close-button"]}`}
      >
        <div className={classes["title-caption"]}>
          <strong>Show/Hide Columns</strong>
        </div>
        {props.hasShowAndFreezeSelection && (
          <div className={classes["freeze-column"]}>
            <span>Show/Freeze </span>
            <span>
              <select
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
                value={props.frozenColumnCount}
                onChange={onFrozenColCountChange}
              >
                {dynamicOptions}
              </select>
            </span>
            <span> Columns </span>
          </div>
        )}
        <DataTable
          scrollable
          removableSort
          scrollHeight="400px"
          scrollDirection="both"
          value={props.fields}
          rowClassName={rowClass}
          size="small"
          showGridlines
          onRowReorder={onRowReorder}
          columnResizeMode="expand"
          responsiveLayout="scroll"
        >
          <Column
            rowReorder
            className={classes["drag-body"]}
            style={{ width: "30px" }}
          ></Column>
          <Column
            key="isShown"
            className={classes["checkbox-body"]}
            header={
              <input
                type="checkbox"
                className={classes["checkbox"]}
                onChange={onSelectAll}
                checked={isSelectAll}
              />
            }
            body={(rowData, column) => (
              <input
                type="checkbox"
                id={rowData.id}
                className={classes["checkbox"]}
                onChange={showColumn}
                checked={rowData.isShown}
                disabled={rowData.isFrozen}
              />
            )}
            style={{ width: "40px" }}
          ></Column>
          <Column
            key="orderNo"
            field="orderNo"
            header="Order"
            headerClassName={classes["table-header"]}
            style={{ width: "70px", justifyContent: "center" }}
          ></Column>
          <Column
            key="value"
            field="value"
            header="Column Header"
            headerClassName={classes["table-header"]}
            style={{
              flexGrow: 1,
              flexBasis: "110px",
              justifyContent: "center",
            }}
          ></Column>
        </DataTable>
      </OverlayPanel>
    </>
  );
};

export default ShowHideColumnsPrimeDataTable;
