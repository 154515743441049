import { OverlayPanel } from "primereact/overlaypanel";
import classes from "./CompareDeliveryPathModal.module.scss";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { compareDeliveryPathGridColumns } from "../../../project-management/data/compare-delivery-path-grid-columns";
import { GridColumnModel } from "../../../project-management/data/project-work-queue-grid-columns";
import ReferenceService from "../../../../services/ReferenceService";
import { E2EProjectLineModel } from "../../../../shared/models/E2EProject.model";
import { GetSubContractorDetailsListResultModel } from "../../../../shared/models/service-models/GetSubContractorDetailsListResult.model";
import { Dialog } from "primereact/dialog";

export interface CompareDeliveryPathModalProps {
  data: E2EProjectLineModel;
  projectLines: E2EProjectLineModel[];
  selectedLineIds: string[];
  getAdditionalInfoList(
    data: E2EProjectLineModel,
    lines: E2EProjectLineModel[],
    isNewValueNotEmpty: boolean
  ): Promise<void>;
}

const CompareDeliveryPathModal = (props: CompareDeliveryPathModalProps) => {
  const [visible, setVisible] = useState(false);
  const op = useRef<OverlayPanel>(null);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<
    GetSubContractorDetailsListResultModel[]
  >([]);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [columns, setColumns] = useState(compareDeliveryPathGridColumns);

  const getSubContractorDetailsList = (
    props: CompareDeliveryPathModalProps
  ) => {
    setLoading(true);
    ReferenceService.getSubContractorDetailsList(
      props.data.schemeId!,
      props.data.applicationTypeId!
    )
      .then((response) => {
        setValues(response);
        setInitialSelectedValue(response);
        return response;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const setInitialSelectedValue = (
    values: GetSubContractorDetailsListResultModel[]
  ) => {
    const initialSelectedValue = values?.find(
      (x: GetSubContractorDetailsListResultModel) =>
        x.subContractorId === props.data.deliveryPathId
    );
    setSelectedValue(initialSelectedValue);
  };

  useEffect(() => {
    getSubContractorDetailsList(props);
  }, [props.data.deliveryPathId, props.data.schemeId]);

  const renderDynamicColumns = () =>
    columns.map((c: GridColumnModel) => (
      <Column
        key={c.value}
        field={c.id}
        header={c.value}
        frozen={c.isFrozen}
        alignFrozen="left"
        hidden={!c.isShown}
        style={{ flexGrow: 1, flexBasis: c.width, width: c.width }}
        body={(e) => (
          <>
            <span title={e[c.id]}>{e[c.id]}</span>
          </>
        )}
      />
    ));

  const applyChanges = async (data: E2EProjectLineModel) => {
    let parameter = { ...data };

    if (data.flexProjectLineId === "0") {
      const list = props.projectLines.map((m: E2EProjectLineModel) => {
        const isSelected = props.selectedLineIds.find(
          (f) => f === m.flexProjectLineId
        );

        if (
          props.selectedLineIds.length === 0 ||
          m.flexProjectLineId === "0" ||
          isSelected
        ) {
          const deliveryPathAvailable = m.deliveryPathList?.find(
            (f: any) => f.id === selectedValue.subContractorId
          );

          if (deliveryPathAvailable) {
            return {
              ...m,
              deliveryPathId: selectedValue.subContractorId,
            };
          } else {
            return { ...m };
          }
        }

        return { ...m };
      });

      props.getAdditionalInfoList(parameter, list, true);
    } else {
      parameter.deliveryPathId = selectedValue.subContractorId;

      const list = props.projectLines.map((m: E2EProjectLineModel) => {
        if (m.flexProjectLineId === data.flexProjectLineId) {
          return {
            ...m,
            deliveryPathId: selectedValue.subContractorId,
          };
        }

        return { ...m };
      });

      props.getAdditionalInfoList(parameter, list, true);
    }
  };

  const headerTemplate = () => (
    <div
      className="ul-grid__column"
      style={{ marginTop: "10px", marginBottom: "20px" }}
    >
      <strong>Compare Delivery Path</strong>
    </div>
  );

  const footerTemplate = () => (
    <div className="ul-grid__column">
      <div
        className="ul-grid__row -wrap"
        style={{
          justifyContent: "right",
          paddingTop: "20px",
        }}
      >
        <Button
          className={`${classes["button"]}`}
          onClick={() => {
            applyChanges(props.data);
            setVisible(false);
          }}
        >
          Apply
        </Button>
        <Button
          className={`p-button-secondary ${classes["button"]}`}
          onClick={() => {
            setInitialSelectedValue(values);
            setVisible(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        className={`p-button-rounded ${classes["btn-compare-delivery-path"]}`}
      >
        <FontAwesomeIcon icon={faExchangeAlt} />
      </Button>
      <Dialog
        className={classes["dialog"]}
        style={{ width: "40vw" }}
        visible={visible}
        header={headerTemplate}
        onHide={() => setVisible(false)}
        footer={footerTemplate}
      >
        <div className="ul-grid__column">
          <div className="ul-grid__row -wrap">
            {headerTemplate}
            <div
              className="ul-grid__column"
              style={{ height: "calc(40vh - 100px)" }}
            >
              <hr />
              <DataTable
                style={{ width: "100%", fontSize: "13px" }}
                scrollHeight="calc(36vh - 100px)"
                tableClassName={`${classes["data-table-height"]}`}
                size="small"
                scrollable
                scrollDirection="both"
                value={values}
                responsiveLayout="scroll"
                loading={loading}
                selectionMode="single"
                showGridlines
                resizableColumns
                selection={selectedValue}
                onSelectionChange={(e) => setSelectedValue(e.value)}
              >
                <Column
                  reorderable={false}
                  selectionMode="single"
                  alignFrozen="left"
                  frozen
                  style={{ width: "3rem" }}
                />
                {renderDynamicColumns()}
              </DataTable>
            </div>
          </div>
          {footerTemplate}
        </div>
      </Dialog>
    </>
  );
};

export default CompareDeliveryPathModal;
