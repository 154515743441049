import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useRef } from "react";
import { ProjectTemplateMappingModel } from "../../../../shared/models/ProjectTemplateMappingModel";

import ProjectTemplateMappingViewFlexTask from "../../project-template-mapping-view-flex-task/ProjectTemplateMappingViewFlexTask";
import classes from "./ProjectTemplateMappingEditGrid.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faClose } from "@fortawesome/free-solid-svg-icons";

import FormTemplateError from "../../shared/component/FormTemplateError";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { E2EMilestoneModel } from "../../../../shared/models/E2EMilestone.model";
import { ProjectTemplateMappingHeaderModel } from "../../../../shared/models/ProjectTemplateMappingHeader.model";
import {
  E2EPredecessorPropsWrapper,
  ProjectTemplateComponentData,
  ProjectTemplateComponentErrors,
  ProjectTemplateMilestoneErrors,
} from "../../ProjectTemplateMapping";
import { distinctArrayObject } from "../../../../utils/helpers/array.helpers";
import { FlexTaskSettingsModel } from "../../../../shared/models/FlexTaskSettings.model";

interface ProjectTemplateMappingEditGridProps {
  headerData: ProjectTemplateMappingHeaderModel;
  e2eGrid: ProjectTemplateMappingModel[];
  setComponentData: React.Dispatch<
    React.SetStateAction<ProjectTemplateComponentData>
  >;
  e2EMilestoneDropDownValues: E2EMilestoneModel[];
  componentErrors: ProjectTemplateComponentErrors | null;
  setComponentErrors: React.Dispatch<
    React.SetStateAction<ProjectTemplateComponentErrors | null>
  >;
  predecessorDropdownItems: E2EPredecessorPropsWrapper[];
  flexTaskSettings: FlexTaskSettingsModel[];
  populateE2EPredecessor: (
    e2eGridData: ProjectTemplateMappingModel[]
  ) => E2EPredecessorPropsWrapper[];
}

const ProjectTemplateMappingEditGrid = (
  componentProps: ProjectTemplateMappingEditGridProps
) => {
  const dataTableRef = useRef<DataTable>(null);

  const selectPlaceHolderLabel: string =
    "-- Select or enter new adhoc task name --";

  /** UI Events and Templates **/

  const onE2EMilestoneOrPredecessorChange = (
    props: any,
    rowData: ProjectTemplateMappingModel,
    IsPredecessor: boolean
  ) => {
    const tempData: ProjectTemplateMappingModel[] = componentProps.e2eGrid.map(
      (data) => {
        return {
          ...data,
        };
      }
    );

    if (!IsPredecessor) {
      tempData[rowData.sortOrder].e2EMilestoneId = props.value;
      tempData[rowData.sortOrder].e2EMilestoneName =
        props.originalEvent.target.innerText.trim();

      if (!tempData[rowData.sortOrder].e2EMilestoneId) {
        tempData[rowData.sortOrder].e2EMilestonePredecessorId = null;
        tempData[rowData.sortOrder].e2EMilestonePredecessorName = null;

        // Clear successor's predecessor.
        const successorSortOrder = rowData.sortOrder + 1;
        if (successorSortOrder in tempData) {
          tempData[successorSortOrder].e2EMilestonePredecessorId = null;
          tempData[successorSortOrder].e2EMilestonePredecessorName = null;
        }
      }
    }

    if (IsPredecessor) {
      tempData[rowData.sortOrder].e2EMilestonePredecessorId = props.value;
      tempData[rowData.sortOrder].e2EMilestonePredecessorName =
        props.originalEvent.target.innerText.trim();
    }

    if (
      componentProps.componentErrors?.milestoneErrors &&
      componentProps.componentErrors?.milestoneErrors.length > 0 &&
      props.value
    ) {
      let componentErrorsCopy = structuredClone(
        componentProps.componentErrors.milestoneErrors
      );

      componentErrorsCopy = componentErrorsCopy.filter(
        (x) => x.sortOrder !== rowData.sortOrder
      );

      componentProps.setComponentErrors((current: any) => ({
        ...current,
        milestoneErrors: componentErrorsCopy,
      }));
    }
    componentProps.setComponentData((current) => ({
      ...current,
      e2eGridData: tempData,
      predecessorDropdownItems: componentProps.populateE2EPredecessor(tempData),
    }));
  };

  const FlexTaskIdNameHeaderTemplate = () => {
    return (
      <>
        <div className={"ul-grid__row"}>
          <div className="ul-grid__column -x50">
            <div className={"column-fill"}>FLEX Task ID</div>
          </div>
          <div className={"ul-grid__column -x70"}>
            <div className={"column-fill"}>FLEX Task Name</div>
          </div>
        </div>
      </>
    );
  };

  const FlexTaskNameTemplate = (rowData: ProjectTemplateMappingModel) => {
    return (
      <>
        <div className={"ul-grid__row"}>
          <div className="ul-grid__column -x20">
            <div className={"column-fill"}>{rowData.flexTaskNumber}</div>
          </div>
          <div className={"ul-grid__column -x60"}>
            <div className={"column-fill"}>{rowData.flexTaskName}</div>
          </div>
          <div className={"ul-grid__column -x20"}>
            <div className={"column-fill"}>
              <ProjectTemplateMappingViewFlexTask flexTask={rowData} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const MilestoneTemplate = (rowData: ProjectTemplateMappingModel) => {
    let getMilestoneNode: ProjectTemplateMilestoneErrors | null = null;
    if (componentProps.componentErrors?.milestoneErrors) {
      getMilestoneNode =
        componentProps.componentErrors?.milestoneErrors.find(
          (x) => x.sortOrder === rowData.sortOrder
        ) || null;
    }
    const gmaTcTask = "GMA TC_Processing (non-UL CO)";

    const isGmaTcTask = rowData.flexTaskName
      .toLowerCase()
      .includes(gmaTcTask.toLowerCase());

    let e2eMilestones = [...componentProps.e2EMilestoneDropDownValues];

    const tcTaskMilestoneName = "UL: GMA Transaction Center";

    e2eMilestones = e2eMilestones.filter((e2emilestone) =>
      isGmaTcTask
        ? e2emilestone.name === tcTaskMilestoneName
        : e2emilestone.name !== tcTaskMilestoneName
    );

    const filteredE2EMilestoneDropdownValues = e2eMilestones.filter(
      (e2emilestone) => {
        const isMilestoneInE2EGrid = componentProps.e2eGrid
          .filter(
            (x) =>
              x.flexTaskNumber === rowData.flexTaskNumber &&
              x.sortOrder !== rowData.sortOrder
          )
          .some((x) => x.e2EMilestoneId === e2emilestone.id);

        return !isMilestoneInE2EGrid;
      }
    );

    return (
      <div className={`${classes["e2eDropdown-box__container"]}`}>
        <Dropdown
          disabled={isGmaTcTask}
          dataKey={rowData.id!}
          className={`${classes["e2eDropdown-box"]} ${
            !!getMilestoneNode?.milestone
              ? `p-invalid ${classes["e2eDropdown-box--error"]}`
              : ""
          }`}
          optionLabel="name"
          optionValue="id"
          options={filteredE2EMilestoneDropdownValues}
          onChange={(e) => onE2EMilestoneOrPredecessorChange(e, rowData, false)}
          placeholder={selectPlaceHolderLabel}
          value={
            componentProps.e2eGrid[rowData.sortOrder]?.e2EMilestoneId || ""
          }
          showClear={
            !!componentProps.e2eGrid[rowData.sortOrder]?.e2EMilestoneId
          }
        />
        {getMilestoneNode != null && (
          <FormTemplateError
            key={rowData.sortOrder}
            errorMessage={getMilestoneNode.milestone || ""}
            visible={!!getMilestoneNode.milestone}
            icon={faClose}
          />
        )}
      </div>
    );
  };

  const MilestonePredecessorTemplate = (
    rowData: ProjectTemplateMappingModel
  ) => {
    let getMilestoneNode: ProjectTemplateMilestoneErrors | null = null;
    if (componentProps.componentErrors?.milestoneErrors) {
      getMilestoneNode =
        componentProps.componentErrors?.milestoneErrors.find(
          (x) => x.sortOrder === rowData.sortOrder
        ) || null;
    }
    if (rowData.sortOrder === 0) {
      return (
        <div className={`${classes["e2eDropdown-box__container"]}`}>
          <Dropdown
            className={classes["e2eDropdown-box"]}
            disabled={true}
            placeholder={"-- None --"}
          />
          {getMilestoneNode != null && (
            <FormTemplateError
              key={rowData.sortOrder}
              errorMessage={getMilestoneNode.predecessor || ""}
              visible={false}
              icon={faClose}
            />
          )}
        </div>
      );
    }

    const findPredecessorDropdownItems =
      componentProps.predecessorDropdownItems.find(
        (x) => x.sortOrder === rowData.sortOrder
      )?.predecessorItems;

    const filteredPredecessorDropDownItems =
      findPredecessorDropdownItems?.filter((f) => {
        const milestone = componentProps.e2EMilestoneDropDownValues.find(
          (milestone) => milestone.id === f.id
        );

        return milestone?.isOrderable && f.id !== rowData.e2EMilestoneId;
      });

    const distinctPredecessorDropDownItems = distinctArrayObject(
      filteredPredecessorDropDownItems
    );

    return (
      <div className={`${classes["e2eDropdown-box__container"]}`}>
        <Dropdown
          className={`${classes["e2eDropdown-box"]} ${
            !!getMilestoneNode?.predecessor
              ? `p-invalid ${classes["e2eDropdown-box--error"]}`
              : ""
          }`}
          optionLabel="name"
          optionValue="id"
          options={distinctPredecessorDropDownItems ?? []}
          placeholder={
            (findPredecessorDropdownItems !== undefined
              ? findPredecessorDropdownItems.length > 0
              : false) &&
            rowData.e2EMilestoneId !== null &&
            rowData.e2EMilestoneId !== undefined
              ? selectPlaceHolderLabel
              : "-- None --"
          }
          onChange={(e) => onE2EMilestoneOrPredecessorChange(e, rowData, true)}
          value={
            componentProps.e2eGrid[rowData.sortOrder]
              ?.e2EMilestonePredecessorId || ""
          }
          disabled={
            rowData.e2EMilestoneId === null ||
            rowData.e2EMilestoneId === undefined
          }
          showClear={
            !!componentProps.e2eGrid[rowData.sortOrder]
              ?.e2EMilestonePredecessorId
          }
        />
        {getMilestoneNode != null && (
          <FormTemplateError
            key={rowData.sortOrder}
            errorMessage={getMilestoneNode.predecessor || ""}
            visible={!!getMilestoneNode.predecessor}
            icon={faClose}
          />
        )}
      </div>
    );
  };

  const onAddNewRowClick = (
    rowData: ProjectTemplateMappingModel,
    rowIndex: number
  ) => {
    let copyCurrentGridData = structuredClone(componentProps.e2eGrid);
    let copyCurrentGridError = structuredClone(
      componentProps.componentErrors?.milestoneErrors
    );

    copyCurrentGridData.splice(rowIndex + 1, 0, {
      id: null,
      flexProjectTemplateTaskTemplateId:
        rowData.flexProjectTemplateTaskTemplateId,
      flexTaskName: rowData.flexTaskName,
      sortOrder: 0,
      flexTaskNumber: rowData.flexTaskNumber,
      flexTaskDescription: rowData.flexTaskDescription,
      flexTaskAverageDuration: rowData.flexTaskAverageDuration,
      flexTaskRevenuePhase: rowData.flexTaskRevenuePhase,
      flexParentTaskNumber: rowData.flexParentTaskNumber,
      flexPredecessorTaskNumber: rowData.flexPredecessorTaskNumber,
      e2EMilestoneId: null,
      e2EMilestoneName: null,
      e2EMilestonePredecessorId: null,
      e2EMilestonePredecessorName: null,
    });

    copyCurrentGridData = copyCurrentGridData
      .sort(
        (a: ProjectTemplateMappingModel, b: ProjectTemplateMappingModel) => {
          return a.flexTaskNumber - b.flexTaskNumber;
        }
      )
      .map((data, index) => {
        data.sortOrder = index;
        return {
          ...data,
        };
      });

    componentProps.setComponentData((current) => ({
      ...current,
      e2eGridData: copyCurrentGridData,
    }));

    if (copyCurrentGridError) {
      copyCurrentGridError = copyCurrentGridError.map((data) => {
        if (data.sortOrder) {
          data.sortOrder =
            data.sortOrder === rowIndex
              ? data.sortOrder
              : data.sortOrder > rowIndex
              ? data.sortOrder + 1
              : data.sortOrder;
        }

        return {
          ...data,
        };
      });

      componentProps.setComponentErrors((current: any) => ({
        ...current,
        milestoneErrors: copyCurrentGridError,
      }));
    }
  };
  const onDeleteRowClick = (rowIndex: number) => {
    let copyCurrentGridData = structuredClone(componentProps.e2eGrid);
    let copyCurrentGridError = structuredClone(
      componentProps.componentErrors?.milestoneErrors
    );

    copyCurrentGridData.splice(rowIndex, 1);

    copyCurrentGridData = copyCurrentGridData
      .sort(
        (a: ProjectTemplateMappingModel, b: ProjectTemplateMappingModel) => {
          return a.flexTaskNumber - b.flexTaskNumber;
        }
      )
      .map((data, index) => {
        data.sortOrder = index;
        return {
          ...data,
        };
      });

    componentProps.setComponentData((current) => ({
      ...current,
      e2eGridData: copyCurrentGridData,
    }));

    if (copyCurrentGridError) {
      copyCurrentGridError = copyCurrentGridError.filter(
        (x) => x.sortOrder !== rowIndex
      );

      copyCurrentGridError = copyCurrentGridError.map((data, index) => {
        if (data.sortOrder) {
          if (data.sortOrder) {
            data.sortOrder =
              data.sortOrder === rowIndex
                ? data.sortOrder
                : data.sortOrder > rowIndex
                ? data.sortOrder - 1
                : data.sortOrder;
          }
        }
        return {
          ...data,
        };
      });

      componentProps.setComponentErrors((current: any) => ({
        ...current,
        milestoneErrors: copyCurrentGridError,
      }));
    }
  };

  const getFlexTaskSettings = (
    taskName: string
  ): FlexTaskSettingsModel | undefined => {
    return componentProps.flexTaskSettings?.find(
      (settings) => settings.flexTaskName === taskName
    );
  };

  const buttonTemplate = (rowData: ProjectTemplateMappingModel, props: any) => {
    const flexTaskCount = componentProps.e2eGrid.filter(
      (x) => x.flexTaskNumber === rowData.flexTaskNumber
    ).length;

    const flexTaskSettings = getFlexTaskSettings(rowData.flexTaskName);

    const shouldDisplayDeleteButton =
      flexTaskSettings === undefined ||
      flexTaskCount > flexTaskSettings.minGMAWBTemplateMilestones;

    const shouldDisplayAddButton =
      flexTaskSettings === undefined ||
      flexTaskCount < flexTaskSettings.maxGMAWBTemplateMilestones;

    return (
      <div className={`ul-grid__row ${classes["button-wrapper"]}`}>
        {shouldDisplayDeleteButton && (
          <Button
            className={`${classes["button"]} ${
              flexTaskCount === 1 ? classes["button--disabled"] : ""
            }`}
            disabled={flexTaskCount > 1 ? false : true}
            onClick={(e) => {
              onDeleteRowClick(props.rowIndex);
            }}
          >
            <span>
              <FontAwesomeIcon
                className={`${classes["icon"]}`}
                icon={faMinus}
              />
            </span>
          </Button>
        )}

        {shouldDisplayAddButton && (
          <Button
            className={`${classes["button"]} ${
              flexTaskCount >= 10 ? classes["button--disabled"] : ""
            }`}
            onClick={(e) => onAddNewRowClick(rowData, props.rowIndex)}
            disabled={flexTaskCount >= 10 ? true : false}
          >
            <span>
              <FontAwesomeIcon className={`${classes["icon"]}`} icon={faPlus} />
            </span>
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        <DataTable
          value={componentProps.e2eGrid}
          size="small"
          responsiveLayout="scroll"
          groupRowsBy="flexTaskNumber"
          rowGroupMode="rowspan"
          ref={dataTableRef}
          tableClassName={classes["table--height"]}
          scrollHeight="calc(80vh - 140px)"
          showGridlines
        >
          <Column
            field="flexTaskNumber"
            header={FlexTaskIdNameHeaderTemplate}
            body={FlexTaskNameTemplate}
            style={{ width: "35%" }}
          ></Column>
          <Column
            field="e2EMilestone"
            header="GMA WB Template Milestone"
            body={MilestoneTemplate}
            style={{ width: "30%" }}
          ></Column>
          <Column
            field="e2EMilestonePredecessor"
            header="GMA WB Template Milestone Predecessor"
            body={MilestonePredecessorTemplate}
            style={{ width: "30%" }}
          ></Column>
          <Column body={buttonTemplate} style={{ width: "5%" }}></Column>
        </DataTable>
      </div>
    </>
  );
};

export default ProjectTemplateMappingEditGrid;
