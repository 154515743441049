import {
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
} from "@microsoft/signalr";
import axios from "axios";
import { useEffect, useState } from "react";
import { authProvider } from "../providers/authProvider";

export function useSignalR(url: string, connectByEmail?: boolean) {
  const [connection, setConnection] = useState<HubConnection>();
  const [error, setError] = useState<any>();

  useEffect(() => {
    const userInfo = authProvider.getAccountInfo();
    const userId = connectByEmail
      ? userInfo?.account?.idToken?.email
      : userInfo?.account?.idToken?.preferred_username?.toLowerCase();

    const httpOptions: IHttpConnectionOptions = {
      httpClient: {
        post: async (url: string, httpOptions: any) => {
          const headers = {
            ...httpOptions.headers,
            "x-ms-signalr-userid": userId,
          };

          const response = await axios.post(url, {}, { headers });

          return {
            statusCode: response.status,
            statusText: response.statusText,
            content: JSON.stringify(response.data),
          };
        },
        getCookieString: () => {
          return "";
        },
      } as any,
      accessTokenFactory: () => {
        return authProvider.getAccountInfo()?.jwtIdToken || "";
      },
    };
    // create a new SignalR connection
    const newConnection = new HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(url, httpOptions)
      .build();

    // start the connection
    newConnection
      .start()
      .then(() => {
        setConnection(newConnection);
      })
      .catch((e) => {
        setError(e);
      });

    // clean up
    return () => {
      newConnection.stop();
    };
  }, []);

  return {
    connection,
    error,
  };
}
