import { faClose, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import classes from "./ProjectTemplateMappingViewFlexTask.module.scss";

const ProjectTemplateMappingViewFlexTask = (props: any) => {
  const opFlexTask = useRef<OverlayPanel>(null);
  const { flexTask } = props;

  const toggleDetails = (e: any) => {
    if (opFlexTask.current != null) {
      opFlexTask.current.toggle(e);
    }
  };

  return (
    <>
      <span onClick={toggleDetails}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          className={classes["information-icon"]}
        />
      </span>

      <OverlayPanel ref={opFlexTask} dismissable>
        <div className={classes["container"]}>
          <div className={classes["close-icon-container"]}>
            <FontAwesomeIcon
              onClick={toggleDetails}
              icon={faClose}
              className={classes["close-icon"]}
            />
          </div>
          <div className={classes["row"]}>
            <div className={classes["label"]}>Is RR Task?</div>
            <div className={classes["value"]}>
              <div>
                {flexTask.flexTaskRevenuePhase &&
                flexTask.flexTaskRevenuePhase != ""
                  ? "Yes"
                  : "No"}
              </div>
            </div>
          </div>
          <div className={classes["row"]}>
            <div className={classes["label"]}>Revenue Phase:</div>
            <div className={classes["value"]}>
              <div>{flexTask.flexTaskRevenuePhase}</div>
            </div>
          </div>
          <div className={classes["row"]}>
            <div className={classes["label"]}>Short Description:</div>
            <div className={classes["value"]}>
              <div>{flexTask.flexTaskDescription}</div>
            </div>
          </div>
          <div className={classes["row"]}>
            <div className={classes["label"]}>Predecessor (FLEX Task ID):</div>
            <div className={classes["value"]}>
              <div>{flexTask.flexPredecessorTaskNumber}</div>
            </div>
          </div>
        </div>
      </OverlayPanel>
    </>
  );
};

export default ProjectTemplateMappingViewFlexTask;
