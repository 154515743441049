import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar
        , faPencil
        , faTrash } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import classes from "./CertificateListFavorites.module.scss";
import useToastr from "./../../../../hooks/useToastr";


const CertificateListFavorites = (props) => {
    const opFav = useRef(null);
    const [showEditFavoriteModal, setShowEditFavoriteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [description, setDescription] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    const { showWarning } = useToastr();

    const { favoriteName,
            favoriteList,
            onSaveFavorite,
            onEditFavorite,
            onDeleteFavorite,
            onClickFavorites,
            onClearFilters } = props;

    const dynamicList = favoriteList.map((fav, i) => {
        if(fav.isDefault){
            return (
                <div key={fav.savedViewFavoriteId} className={classes['item']}>
                    <div 
                        className={`${classes['fav']} ${classes['elipsis']}`} 
                        onClick={() => onClickFavorites(fav.savedViewFavoriteId)}
                        title={fav.description}> {fav.description}
                    </div>
                    
                    <div className={classes['fav-action']}>
                        <FontAwesomeIcon 
                            icon={faStar} 
                            className={classes['fav-star']}>
                        </FontAwesomeIcon>
                        <FontAwesomeIcon 
                            icon={faPencil}
                            className={`${classes['fav-pencil']} ${classes['action']}`}
                            onClick={() => {onEdit(fav.savedViewFavoriteId)}}>
                        </FontAwesomeIcon>
                        <FontAwesomeIcon 
                            icon={faTrash}
                            className={`${classes['fav-trash']}`}
                            onClick={() => {onDelete(fav.savedViewFavoriteId)}}>
                        </FontAwesomeIcon>
                    </div>
                </div>
            );
        }
        else{
            return (
                <div key={fav.savedViewFavoriteId} className={classes['item']}>
                    <div 
                        className={`${classes['fav']} ${classes['elipsis']}`} 
                        onClick={() => onClickFavorites(fav.savedViewFavoriteId)}
                        title={fav.description}> {fav.description}
                    </div>
                    
                    <div className={classes['fav-action']}>
                        <FontAwesomeIcon 
                            icon={faPencil}
                            className={`${classes['fav-pencil']} ${classes['action']}`}
                            onClick={() => {onEdit(fav.savedViewFavoriteId)}}>
                        </FontAwesomeIcon>
                        <FontAwesomeIcon 
                            icon={faTrash}
                            className={`${classes['fav-trash']}`}
                            onClick={() => {onDelete(fav.savedViewFavoriteId)}}>
                        </FontAwesomeIcon>
                    </div>
                </div>
            );
        }
    });

    const saveFavorite = () => {
        if(description.trim() === ""){
            showWarning("Warning", 'Please enter a valid Favorite name');
        }
        else{
            const isExists = favoriteList.find(f => f.description === description.trim());
            
            if(isExists){
                showWarning("Warning", 'Favorite Name already exists');
            }
            else{
                onSaveFavorite(description.trim(), isDefault);
        
                setDescription('');
                setIsDefault(false);
            }
        }
    };

    const onEdit = (id) => {
        setSelectedId(id);
        const selectedFavorite = favoriteList.find(f => f.savedViewFavoriteId === id);
        setDescription(selectedFavorite.description);
        setIsDefault(selectedFavorite.isDefault);
        setShowEditFavoriteModal(true);
    };

    const editFavorite = () => {
        if(description.trim() === ""){
            showWarning("Warning", 'Please enter a valid Favorite name');
        }
        else{
            const isExists = favoriteList.find(f => f.savedViewFavoriteId !== selectedId && f.description === description.trim());

            if(isExists){
                showWarning("Warning", 'Favorite Name already exists');
            }
            else{
                onEditFavorite(selectedId, description.trim(), isDefault);
        
                setShowEditFavoriteModal(false);
                setSelectedId(0);
                setDescription('');
                setIsDefault(false);
            }
        }
    };

    const onDelete = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete this from My Favorites list?',
            header: 'Delete Saved Favorite',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-secondary',
            accept: () => deleteFavorite(id)
        });
    };

    const deleteFavorite = (id) => {
        onDeleteFavorite(id)
    };

    const renderFooter = () => {
        return (
            <div>
                <Button label="Cancel" className="p-button-secondary" onClick={() => setShowEditFavoriteModal(false)} />
                <Button label="Save" className="p-button-info" onClick={editFavorite} autoFocus />
            </div>
        );
    };

    return (
        <>
            <button 
                type="button" 
                className={`ul-button ${classes['favorites']}`} 
                onClick={(e) => opFav.current.toggle(e)}>
                <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                <span>{favoriteName}</span>
            </button>

            <OverlayPanel ref={opFav} dismissable>
                <div className={classes['fav-header']}>
                    <div 
                        className={classes['sub-title']} >
                            Add Favorites
                        </div>
                    <div>
                        <input 
                            type="textbox" 
                            value={description} 
                            onChange={(e) => {setDescription(e.target.value)}} 
                        />
                        <button 
                            className={`ul-button -app-primary -small ${classes['save-button']}`} 
                            onClick={saveFavorite}> Save
                        </button>
                    </div>
                    <div className={classes['def-and-clear']}>
                        <div className={classes['set-as-default']}>
                            <input 
                                type="checkbox" 
                                checked={isDefault} 
                                onChange={(e) => {setIsDefault(e.target.checked)}}/> Set as default
                        </div>
                        <div 
                            className={classes['clear-filters']}
                            onClick={onClearFilters}>Clear filters</div>
                    </div>
                </div>
                <div className={classes['fav-body']}>
                    <div className={classes['sub-title']}>My Favorites</div>
                    <div className={classes['list']}>
                        {dynamicList}
                    </div>
                </div>
            </OverlayPanel>

            <Dialog
                header="Edit Favorite"
                visible={showEditFavoriteModal}
                position="top"
                modal
                style={{width:'30vw'}}
                onHide={() => setShowEditFavoriteModal(false)}
                footer={renderFooter}
            >
                <div>
                    <label className={classes['edit-label']}> Name</label>
                    <input 
                        type="textbox" 
                        className="ul-textbox"
                        value={description} 
                        onChange={(e) => {setDescription(e.target.value)}} />
                </div>
                <div>
                    <input 
                        type="checkbox" 
                        className={classes['edit-checkbox']}
                        checked={isDefault} 
                        onChange={(e) => {setIsDefault(e.target.checked)}}/>
                    <label className={classes['edit-label']}> Set as default</label>
                </div>
            </Dialog>
        </>
    );
};

export default CertificateListFavorites;