import ProjectList from "./project-list/ProjectList";
import classes from "./ProjectManagement.module.scss";
import ProjectListHeader from "./project-list-header/ProjectListHeader";
import ProjectLineList from "./project-line-list/ProjectLineList";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedViewBy,
  updateProjectList,
  selectRequest,
  updateLoader,
} from "../../features/projectManagement/projectManagementSlice";
import ProjectService from "../../services/ProjectService";
import { PaginatedResultModel } from "../../shared/models/service-models/PaginatedResult.model";
import { ResultModel } from "../../models/result.model";
import {
  E2EProjectLineModel,
  E2EProjectModel,
} from "../../shared/models/E2EProject.model";
import { formatDateToGanttChartDate } from "../../shared/date-helper/DateHelper";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import ProjectManagementRefiners from "./project-management-refiners/ProjectManagementRefiners";
import { ProjectManagementViewByEnum } from "../../enums/ProjectManagementViewBy";
import useToastr from "../../hooks/useToastr";

export const pendingProjectCreatedSuccessMessageKey =
  "pending-project-created-success-toast-message";

function ProjectManagement() {
  const selectedView = useSelector(selectSelectedViewBy);
  const dispatch = useDispatch();
  const request = useSelector(selectRequest);
  const [isRefinerVisible, setRefinerVisible] = useState(true);
  const { showSuccess } = useToastr();

  useEffect(() => {
    // HACK: Since the react is inside the iframe, showing success notification on
    // cdp modal doesn't work because the page is being redirected immediately to here.
    // Remove once the react shell is completed.
    const pendingProjectCreatedToastNotifMessage = sessionStorage.getItem(
      pendingProjectCreatedSuccessMessageKey
    );

    if (pendingProjectCreatedToastNotifMessage) {
      showSuccess("Success", pendingProjectCreatedToastNotifMessage);
      sessionStorage.removeItem(pendingProjectCreatedSuccessMessageKey);
    }
  }, []);

  const handleListLoading = (viewBy: any) => {
    dispatch(updateLoader(true));
    if (viewBy.code == ProjectManagementViewByEnum.GmaWbProject) {
      ProjectService.getProjectList(request).then(
        (result: ResultModel<PaginatedResultModel<E2EProjectModel[]>>) => {
          if (result.isSuccess) {
            let records = result.data.records;
            records.forEach((project: E2EProjectModel) => {
              project.createdDateUtc = formatDateToGanttChartDate(
                project.createdDateUtc!
              );
              project.modifiedDateUtc = formatDateToGanttChartDate(
                project.modifiedDateUtc!
              );
            });

            dispatch(
              updateProjectList({
                projects: records,

                paginationModel: {
                  totalPages: result.data.totalPages,
                  totalRecords: result.data.total,
                  firstIndex: result.data.firstIndex,
                  lastIndex: result.data.lastIndex,
                  hasNextPage: result.data.hasNextPage,
                  hasPreviousPage: result.data.hasPreviousPage,
                },
              })
            );
            dispatch(updateLoader(false));
          }
        }
      );
    } else {
      ProjectService.getProjectLineList(request).then(
        (result: ResultModel<PaginatedResultModel<E2EProjectLineModel[]>>) => {
          if (result.isSuccess) {
            let records = result.data.records;
            records.forEach((project: E2EProjectLineModel) => {
              project.createdDateUtc = formatDateToGanttChartDate(
                project.createdDateUtc!
              );
              project.modifiedDateUtc = formatDateToGanttChartDate(
                project.modifiedDateUtc!
              );
            });
            dispatch(
              updateProjectList({
                projects: records,

                paginationModel: {
                  totalPages: result.data.totalPages,
                  totalRecords: result.data.total,
                  firstIndex: result.data.firstIndex,
                  lastIndex: result.data.lastIndex,
                  hasNextPage: result.data.hasNextPage,
                  hasPreviousPage: result.data.hasPreviousPage,
                },
              })
            );
            dispatch(updateLoader(false));
          }
        }
      );
    }
  };
  const enablerAction = () => {
    setRefinerVisible(!isRefinerVisible);
  };
  return (
    <div className={`ul-grid__row  ${classes["project-mangement-container"]}`}>
      {isRefinerVisible ? (
        <div
          className={`ul-grid__column -x20 -offset-10-desktop -x100-mobile ${classes["project-management-refiners-container"]}`}
        >
          <ProjectManagementRefiners></ProjectManagementRefiners>
        </div>
      ) : null}
      <div
        className={
          !isRefinerVisible
            ? `${classes["project-management-main-full"]}`
            : `${classes["project-management-main"]}`
        }
      >
        <div className="enabler">
          <button
            title="Click here to show/hide"
            className={`${!isRefinerVisible ? "hide" : ""}`}
            onClick={enablerAction}
          >
            {isRefinerVisible ? (
              <FontAwesomeIcon icon={faBars} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </button>
        </div>
        <div className="ul-grid__column -x100 -x100-mobile">
          <div className="ul-grid__row -wrap">
            <ProjectListHeader onHandleListLoading={handleListLoading} />
          </div>
          <div className="ul-grid__row -wrap">
            {selectedView.code === ProjectManagementViewByEnum.GmaWbProject ? (
              <ProjectList onHandleListLoading={handleListLoading} />
            ) : (
              <ProjectLineList onHandleListLoading={handleListLoading} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectManagement;
