import RequestFactory from "./RequestFactory";
import TemplateListRequestModel from "../shared/models/service-models/TemplateListRequest.model";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

const TemplateService = {
  deleteTemplates: async (ids: string[]) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/template/delete-templates",
      ids
    ),
  getProjectTemplateList: async (request: TemplateListRequestModel) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/template/get-template-list",
      request
    ),
  getProjectTemplateListRefiners: async (searchString: string, field: string) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/template/get-template-list-refiners?searchString=${searchString}&field=${field}`
    ),
    getTemplateVersion:async (correlationId: string) => 
    await RequestFactory.get(
      GMAE2EURL,
      `/api/template/template-versions/${correlationId}`
    ),
    getTemplatesByFlexCorrelationId:async (flexCorrelationId: string) => 
    await RequestFactory.get(
      GMAE2EURL,
      `/api/template/get-templates-by-flex-correlation-id?flexCorrelationId=${flexCorrelationId}`
    )
};

export default TemplateService;
