import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import React, { useEffect, useState } from "react";
import classes from "./PmrChecboxRefiner.module.scss";

export type PmrCheckboxRefinerProps = {
  items: Array<any>;
  name?: string;
  value?: any[];
  onChange?: (event: CheckboxChangeParams) => void;
  onSelectAll?: (value: any[]) => void;
  showSelectAll?: boolean;
};

export const sortCheckboxExternalSrc = (
  first: any,
  second: any,
  src: any[]
) => {
  const firstSrc = src.find((item) => item.value === first);
  const secondSrc = src.find((item) => item.value === second);
  return `${firstSrc?.label}`.localeCompare(`${secondSrc?.label}`);
};

export type PmrCheckboxRefinerRef = {};
const PmrCheckboxRefiner = React.forwardRef<
  PmrCheckboxRefinerRef,
  PmrCheckboxRefinerProps
>((props, ref) => {
  const {
    items,
    name,
    value: formValue,
    onChange,
    onSelectAll,
    showSelectAll,
  } = props;
  const selectAllId = `${name}-select-all`;
  const [selectAll, setSelectAll] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const handleSelectAll = (event: CheckboxChangeParams) => {
    if (!onSelectAll) return;
    onSelectAll(
      event.checked ? items.map((item) => item.value ?? item.label) : []
    );
  };

  const handleOnChange = (event: CheckboxChangeParams) => {
    if (onChange) onChange(event);
  };

  useEffect(() => {
    setSelectAll(formValue?.length === items.length);
  }, [formValue]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div className={classes["checkbox-container"]}>
      {(showSelectAll ?? true) && (
        <div className="p-field-checkbox">
          <Checkbox
            inputId={selectAllId}
            name={name}
            checked={selectAll}
            onChange={handleSelectAll}
          />
          <label htmlFor={selectAllId}>Select All</label>
        </div>
      )}
      {items.map((item, key) => {
        const value = item.value ?? item.label;
        const inputId = `${name}${key}`;
        const isChecked = formValue?.some(
          (itemValue) => JSON.stringify(value) === JSON.stringify(itemValue)
        );
        return (
          <div className="p-field-checkbox" key={key}>
            <Checkbox
              inputId={inputId}
              name={name ?? item.name}
              value={value}
              checked={isChecked}
              onChange={handleOnChange}
            />
            <label htmlFor={inputId}>{item.label}</label>
          </div>
        );
      })}
    </div>
  );
});

export default PmrCheckboxRefiner;
