import { uniq } from "lodash";
import { Button } from "primereact/button";
import ConfirmationModalV2 from "../../../../../shared/confirmation-modal-v2/ConfirmationModalV2";
import classes from "./TimestampConfirmationDialog.module.scss";

type Props = {
  visible: boolean;
  e2eProjectLineIds: string[];
  onConfirm: () => void;
  onCancel: () => void;
};

export default function TimestampConfirmationDialog({
  visible,
  onConfirm,
  onCancel,
  e2eProjectLineIds,
}: Props) {
  const isMultipleOrderLines =
    !!e2eProjectLineIds && uniq(e2eProjectLineIds).length > 1;

  const modalHeader = isMultipleOrderLines
    ? "Targeted Completion for Selected Order Lines"
    : "Timestamp Confirmation";

  const bodyMessage = isMultipleOrderLines
    ? `You are about to mark multiple Milestones for your selected order lines as "Completed"`
    : "You are about to complete multiple milestones for this order line.";

  const bodyTemplate = (
    <>
      <span>{bodyMessage}</span>
      <br />
      <br />
      <span>Click "Confirm" to proceed.</span>
    </>
  );

  const footerTemplate = (
    <>
      <Button onClick={() => onCancel()} className={classes["btn-cancel"]}>
        CANCEL
      </Button>

      <Button onClick={() => onConfirm()} className={classes["btn-confirm"]}>
        CONFIRM
      </Button>
    </>
  );

  return (
    <ConfirmationModalV2
      bodyClassName={classes["modal-body"]}
      style={{ width: "40vw" }}
      visible={visible}
      buttonYesDisplay="CONFIRM"
      buttonNoDisplay="CANCEL"
      onPositive={onConfirm}
      onNegative={onCancel}
      onClose={onCancel}
      headerDisplay={modalHeader}
      customFooter={footerTemplate}
      confirmationMessageHTML={bodyTemplate}
    ></ConfirmationModalV2>
  );
}
