import { useEffect, useState } from "react";
import classes from "./ProjectLineList.module.scss";
import "./ProjectLineList.scss";
import CustomOneULPaginator from "../../../shared/custom-oneul-paginator/CustomOneULPaginator";
import { ProjectAvatar } from "../shared/components/project-avatar/ProjectAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faCircleInfo,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import useToastr from "../../../hooks/useToastr";
import {
  E2EProjectLineCollaboratorModel,
  E2EProjectLineModel,
} from "../../../shared/models/E2EProject.model";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProjects,
  selectPaginationModel,
  selectIsLoading,
  selectRequest,
  updateRequest,
  selectSelectedViewBy,
} from "../../../features/projectManagement/projectManagementSlice";
import { classNames } from "primereact/utils";
import ProjectGridLegend from "../shared/components/project-grid-legend/ProjectGridLegend";
const ProjectLineList = (props: any) => {
  const { onHandleListLoading } = props;
  const dispatch = useDispatch();
  let pageSizes: number[] = [50, 100, 200, 500, 1000];
  let loadLazyTimeout: any = null;

  const projects = useSelector(selectProjects);
  const paginationModel = useSelector(selectPaginationModel);
  const isLoading = useSelector(selectIsLoading);
  const request = useSelector(selectRequest);
  const [disableJumpToInput, setDisableJumpToInput] = useState(false);

  const [selectedRecords, setSelectedRecords] = useState<E2EProjectLineModel[]>(
    []
  );
  const [expandedRows, setExpandedRows] = useState([]);

  const { showSuccess, showError } = useToastr();

  const columns: any = [
    {
      field: "flexProjectNumber",
      header: "Project Number",
      width: "230px",
    },
    {
      field: "applicantName",
      header: "Applicant",
      width: "230px",
    },
    {
      field: "orderLineNumber",
      header: "Order Line Number",
      width: "230px",
    },
    {
      field: "projectName",
      header: "Project Name",
      width: "230px",
    },
    {
      field: "orderNumber",
      header: "Order Number",
      width: "230px",
    },
    {
      field: "e2e Project",
      header: "E2E Project",
      width: "230px",
    },
    {
      field: "status",
      header: "Status",
      width: "230px",
    },
  ];

  const tableColumnHeader = (col: any) => (
    <div>
      <span title={col.header}>{col.header}</span>
    </div>
  );

  const tableColumnBody = (rowData: any, col: any) => {
    const stockClassName = classNames({
      "text-overflow-ellipsis": true,
    });
    return (
      <div title={rowData[col.field]} className={stockClassName}>
        {rowData[col.field]}
      </div>
    );
  };

  const dynamicColumns = columns.map((col: any, index: any) => {
    return (
      <Column
        sortable
        key={col.header + index}
        field={col.field}
        header={tableColumnHeader(col)}
        style={{ flexGrow: 1, flexBasis: col.width, width: col.width }}
        body={tableColumnBody.bind(col)}
      />
    );
  });

  const rowDetailsTemplate = (e: any) => {
    return (
      <div>
        <span>sample data only.</span>
      </div>
    );
  };

  const renderCollaboratorsTemplate = (e2EProjectLine: E2EProjectLineModel) => {
    let E2Ecollaborators: E2EProjectLineCollaboratorModel[] = [];
    if (e2EProjectLine) {
      if (e2EProjectLine.e2EProjectLineCollaborators) {
        e2EProjectLine.e2EProjectLineCollaborators.forEach((collaborator) => {
          E2Ecollaborators.push({
            ...collaborator,
            ordinal:
              collaborator.collaboratorType === "CEM / Order Owner"
                ? 1
                : collaborator.collaboratorType === "FLEX Handler"
                ? 2
                : collaborator.collaboratorType === "E2E Handler"
                ? 3
                : 4,
          });
        });
      }
    }

    E2Ecollaborators = E2Ecollaborators.sort((a, b) => a.ordinal! - b.ordinal!);
    return (
      <>
        <div key={e2EProjectLine.id} className="ul-grid__column ">
          <ProjectAvatar
            key={e2EProjectLine.id}
            AvatarList={E2Ecollaborators}
          />
        </div>
      </>
    );
  };

  const renderE2EProjectName = (e2EProject: any) => {
    if (!e2EProject) return <></>;
    return (
      <div className="ul-grid__column" key={e2EProject.id}>
        <div className="ul-grid__row -wrap">
          <div
            title={e2EProject.name}
            className={`${classes["defaultCursor"]} ${classes["elipsisContainer"]} ul-grid__column -x90`}
          >
            {e2EProject.name}
          </div>
          <div className={`ul-grid__column -x10`}>
            <FontAwesomeIcon
              title="View E2E Project information"
              icon={faCircleInfo}
              className={classes["icon"]}
            />
          </div>
        </div>
      </div>
    );
  };

  const pageSizeOnChange = (data: any) => {
    dispatch(updateRequest({ property: "take", value: data.target.value }));
    onHandleListLoading(selectSelectedViewBy);
  };

  const pageOnChange = (data: number) => {
    dispatch(updateRequest({ property: "skip", value: request.skip! + data }));
    onHandleListLoading(selectSelectedViewBy);
  };

  const manualPageOnChange = (data: any) => {
    let value = data.target.value.replace(/\D/g, "");
    let skip =
      value === "" || value === "0" ? request.skip! : Number.parseInt(value);
    if (skip <= paginationModel.totalPages && skip !== request.skip) {
      dispatch(updateRequest({ property: "skip", value: skip }));
      onHandleListLoading(selectSelectedViewBy);
    }
  };
  const actionHeaderTemplate = (
    <Button
      className="p-button-rounded p-button-secondary p-button-text"
      style={{ cursor: "default", pointerEvents: "none" }}
    >
      <FontAwesomeIcon icon={faBookOpen} />
    </Button>
  );

  const actionTemplate = () => {
    return (
      <Button className="p-button-rounded p-button-secondary p-button-text">
        <FontAwesomeIcon icon={faUpRightFromSquare} />
      </Button>
    );
  };

  return (
    <div className="ul-grid__column">
      <div className="ul-grid__row -wrap">
        <div className="ul-grid__column"></div>
        <div className={`ul-grid__column project-line-list-grid-container`}>
          <DataTable
            dataKey="id"
            value={projects}
            loading={isLoading}
            selection={selectedRecords}
            onSelectionChange={(e: any) => setSelectedRecords(e.value)}
            expandedRows={expandedRows}
            onRowToggle={(e: any) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowDetailsTemplate}
            scrollable
            resizableColumns
            size="small"
            selectionMode="checkbox"
            scrollDirection="both"
            showGridlines
          >
            <Column
              // frozen
              selectionMode="multiple"
              style={{ width: "50px" }}
              headerClassName={`${classes["hideHeaderCheckbox"]}`}
            />
            <Column expander={true} style={{ width: "50px" }} />
            {dynamicColumns}
            <Column
              frozen
              alignFrozen="right"
              header={actionHeaderTemplate}
              body={actionTemplate}
              style={{ width: "50px" }}
            />
          </DataTable>
        </div>
        <div className="ul-grid__column">
          <CustomOneULPaginator
            pageSizes={pageSizes}
            skip={request.skip!}
            take={request.take!}
            firstIndex={paginationModel.firstIndex}
            lastIndex={paginationModel.lastIndex}
            totalRecords={paginationModel.totalRecords}
            totalPages={paginationModel.totalPages}
            hasPreviousPage={paginationModel.hasPreviousPage}
            hasNextPage={paginationModel.hasNextPage}
            pageSizeOnChange={pageSizeOnChange}
            manualPageOnChange={manualPageOnChange}
            pageOnChange={pageOnChange}
            disableJumpToInput={disableJumpToInput}
            legendTemplate={<ProjectGridLegend />}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectLineList;
