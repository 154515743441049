import { Dialog } from "primereact/dialog";
import classes from "./CloseRowEditModeConfirmationDialog.module.scss";

const CloseRowEditModeConfirmationDialog = ({
  visible,
  onConfirm,
  onCancel,
}) => {
  const header = () => {
    return (
      <>
        <h4 className={classes["modal-header"]}>
          Unsaved Changes Confirmation
        </h4>
        <hr className={classes["modal-header-hr"]} />
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <hr className={classes["modal-footer-hr"]} />
        <div className={`text-center ${classes["footer-content"]}`}>
          <button
            className={`ul-button -app-primary ${classes["btn-yes"]}`}
            onClick={() => onConfirm && onConfirm()}
          >
            YES
          </button>

          <button
            className={`ul-button -app-secondary ${classes["btn-no"]}`}
            onClick={() => onCancel && onCancel()}
          >
            NO
          </button>
        </div>
      </>
    );
  };

  return (
    <Dialog
      closable={true}
      draggable={false}
      resizable={false}
      header={header}
      footer={footer}
      onHide={() => onCancel && onCancel()}
      visible={visible}
      style={{ width: "630px" }}
      className={classes["container"]}
    >
      <p className={classes["body-content"]}>
        You have updated information that has not been saved. Any unsaved
        changes will be lost.
        <br /> <br />
        Would you like to continue?
      </p>
    </Dialog>
  );
};

export default CloseRowEditModeConfirmationDialog;
