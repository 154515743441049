import { Dialog } from "primereact/dialog";
import { useState } from "react";
import CustomerNameLookup from "./CustomerNameLookup";
import classes from "./CustomerNameLookup.module.scss";
import "./CustomerNameLookup.scss";

const CustomerNameLookupDialog = (props) => {
  const {
    displayCustomerNameLookup,
    onHideCustomerNameLookup,
    setPartySiteNumber,
    useCloseButtonOnly,
  } = props;

  const [currentRowSelected, setCurrentRowSelected] = useState("");

  const onClickSelectParty = () => {
    onHideCustomerNameLookup("displayCustomerNameLookup");
    setPartySiteNumber(currentRowSelected.partySiteNumber);
  };

  const customerLookupDialogFooter = (
    <>
      <div className={`${classes["footer-btns"]}`}>
        {!useCloseButtonOnly && (
          <button
            className={`ul-button -medium ${classes["select-party-btn"]}`}
            disabled={currentRowSelected === ""}
            onClick={onClickSelectParty}
          >
            SELECT PARTY
          </button>
        )}

        <button
          className={`ul-button -medium ${classes["cancel-btn"]}`}
          onClick={() => onHideCustomerNameLookup("displayCustomerNameLookup")}
        >
          {!useCloseButtonOnly ? "CANCEL" : "CLOSE"}
        </button>
      </div>
    </>
  );

  return (
    <>
      <Dialog
        header="Customer Name Lookup"
        onHide={() => onHideCustomerNameLookup("displayCustomerNameLookup")}
        style={{ width: "80vw", height: "80vh" }}
        visible={displayCustomerNameLookup}
        footer={customerLookupDialogFooter}
      >
        <CustomerNameLookup tableHeight="100%"  containerHeight="calc(100% - 190px)" onCurrentRowSelected={setCurrentRowSelected} />
      </Dialog>
    </>
  );
};

export default CustomerNameLookupDialog;
