import ProjectTaskStatus from "../../pages/project-management-pmr/shared/enums/project-task-status";

export interface IProjectTaskStatus {
  name: string;
  code: number;
  disabled: boolean;
  tooltipMessage: string;
}

export const getFlexTaskStatusList = (
  isAdhoc: boolean | null
): IProjectTaskStatus[] => {
  console.log(isAdhoc);
  let items = [];
  items.push({
    name: "In Progress",
    code: ProjectTaskStatus.InProgress,
    disabled: false,
    tooltipMessage:
      "This task will only progress once its Start Date commences.",
  });
  if (isAdhoc) {
    items.push({
      name: "Not Started",
      code: ProjectTaskStatus.NotStarted,
      disabled: false,
      tooltipMessage: "",
    });
  }
  items.push({
    name: "On Hold",
    code: 300,
    disabled: false,
    tooltipMessage: "",
  });
  items.push({
    name: "Cancelled",
    code: ProjectTaskStatus.Cancelled,
    disabled: false,
    tooltipMessage: "Tasks with logged work hours cannot be cancelled.",
  });

  return items;
};
