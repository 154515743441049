enum GPIFilterTypeEnum {
  AgentName = 1,
  ApplicantName = 2,
  FactoryName = 3,
  GPICreatorName = 4,
  GPIFormName = 5,
  ManufacturerName = 6,
  Model = 7,
}

export default GPIFilterTypeEnum;
