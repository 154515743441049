import { Dispatch } from "react";
import { ResultModel } from "../models/result.model";
import GetAllCurrencyResultModel from "../shared/models/currency-models/GetAllCurrencyResultModel";
import RequestFactory from "./RequestFactory";
import UpdateProjectlineCurrencyModel from "../shared/models/currency-models/UpdateProjectlineCurrencyModel";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

const CurrencyService = {
  getAllCurrency: async () =>
    RequestFactory.get(GMAE2EURL, "/api/currency") as Promise<
      ResultModel<GetAllCurrencyResultModel[]>
    >,
  updateProjectlineCurrency: async (
    request: UpdateProjectlineCurrencyModel[]
  ) =>
    RequestFactory.post(
      GMAE2EURL,
      "/api/currency/update-projectline-currency",
      request
    ),
};

export default CurrencyService;
