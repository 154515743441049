import { useEffect } from "react";
import { useSignalR } from "./useSignalR";
import { toCamel } from "../utils/helpers/object.helpers";
import useProjectTimestamp from "./useProjectTimestamp";
import useProjectOrderlineDetail from "./useProjectOrderlineDetail";
import {
  PmrE2EProjectLineUpdateStatusResult,
  PmrE2EProjectLineUpdateStatusResultModel,
} from "../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-details-orderline-model";
import {
  selectExpandedRowsProjectList,
  updateE2EProjectsStatuses,
  updateIsMismatchECD,
  updateRefreshProjectId,
} from "../features/projectManagement/projectManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import { IPmrGpiWorkflowCreationModel } from "../pages/project-management-pmr/shared/interfaces/pmr-e2e-create-gpi-workflow-model";
import useToastr from "./useToastr";

const useProjectManagementPmrSignalr = () => {
  const dispatch = useDispatch();
  const expandedRows = useSelector(selectExpandedRowsProjectList);
  const { showSuccess } = useToastr();

  const milestoneTimestampsHubUrl = (process.env.REACT_APP_GMAE2E_URL +
    "/hubs/milestone_timestamps") as string;

  const { connection: milestoneTimestampsConnection } = useSignalR(
    milestoneTimestampsHubUrl
  );
  const projectLineUpdateStatusHubUrl = (process.env.REACT_APP_GMAE2E_URL +
    "/hubs/projectline_updatestatus") as string;

  const { connection: projectLineUpdateStatusConnection } = useSignalR(
    projectLineUpdateStatusHubUrl
  );

  const projectStatusUpdates = (process.env.REACT_APP_GMAE2E_URL +
    "/hubs/project_updatestatus") as string;

  const { connection: projectStatusUpdatesSignalRConnection } =
    useSignalR(projectStatusUpdates);

  const functionHubUrl = (process.env.REACT_APP_GMAE2E_FUNC_URL +
    "/api") as string;
  const { connection: functionSignalRConnection } = useSignalR(functionHubUrl);

  const projectLineIsMismatchECD = (process.env.REACT_APP_GMAE2E_URL +
    "/hubs/projectline_ismismatch_ecd_update") as string;
  const { connection: projectLineIsMismatchECDConnection } = useSignalR(
    projectLineIsMismatchECD
  );

  const projectTimestampHook = useProjectTimestamp();
  const projectOrderlineDetailHook = useProjectOrderlineDetail();

  useEffect(() => {
    if (milestoneTimestampsConnection) {
      milestoneTimestampsConnection.on("finished", (result) => {
        if (result) {
          console.log(`milestoneTimestampsConnection finished`);
          console.log(`milestoneTimestampsConnection finished`, result);
          const parsedResult = toCamel(JSON.parse(result));
          projectTimestampHook.onTimestampFinished(parsedResult);
        }
      });
      milestoneTimestampsConnection.on(
        "projectLineStatusWritebackInprogress",
        (result) => {
          if (result) {
            //TODO:To remove for debugging purposes on dev
            console.log(
              `milestoneTimestampsConnection projectLineStatusWritebackInprogress`
            );
            console.log(
              `milestoneTimestampsConnection projectLineStatusWritebackInprogress`,
              result
            );
            const results: PmrE2EProjectLineUpdateStatusResult[] = toCamel(
              JSON.parse(result)
            );
            projectOrderlineDetailHook.applySignalrOrderlineStatusWritebackInprogress(
              results
            );
          }
        }
      );
      milestoneTimestampsConnection.on(
        "japanForOrderLineCompletion",
        (result) => {
          if (result) {
            //TODO:To remove for debugging purposes on dev
            console.log(
              `milestoneTimestampsConnection japanForOrderLineCompletion`
            );
            console.log(
              `milestoneTimestampsConnection japanForOrderLineCompletion`,
              result
            );
            const results: PmrE2EProjectLineUpdateStatusResultModel = toCamel(
              JSON.parse(result)
            );
            projectOrderlineDetailHook.applySignalrJapanOrderLineForCompletion(
              results
            );
          }
        }
      );
      milestoneTimestampsConnection.on(
        "japanForFlexProjectCompletion",
        (result) => {
          if (result) {
            //TODO:To remove for debugging purposes on dev
            console.log(
              `milestoneTimestampsConnection japanForFlexProjectCompletion`
            );
            console.log(
              `milestoneTimestampsConnection japanForFlexProjectCompletion`,
              result
            );
            const results: PmrE2EProjectLineUpdateStatusResultModel = toCamel(
              JSON.parse(result)
            );
            projectOrderlineDetailHook.applySignalrJapanFlexProjectForCompletion(
              results
            );
          }
        }
      );
    }
  }, [milestoneTimestampsConnection]);

  useEffect(() => {
    if (projectStatusUpdatesSignalRConnection) {
      projectStatusUpdatesSignalRConnection.on(
        "projectStatusUpdated",
        (result) => {
          if (result) {
            const parsedResult = toCamel(JSON.parse(result));
            dispatch(updateE2EProjectsStatuses(parsedResult));
          }
        }
      );
    }
  }, [projectStatusUpdatesSignalRConnection]);

  useEffect(() => {
    if (projectLineUpdateStatusConnection) {
      projectLineUpdateStatusConnection.on(
        "projectLineStatusUpdated",
        (result) => {
          if (result) {
            //currently no implementation
          }
        }
      );
    }
  }, [projectLineUpdateStatusConnection]);

  useEffect(() => {
    if (functionSignalRConnection) {
      functionSignalRConnection.on("project-line-status-updated", (result) => {
        if (result) {
          //TODO:To remove for debugging purposes on dev
          console.log(`functionSignalRConnection project-line-status-updated`);
          console.log(
            `functionSignalRConnection project-line-status-updated`,
            result
          );
          const resultModel: PmrE2EProjectLineUpdateStatusResultModel = toCamel(
            JSON.parse(result)
          );
          projectOrderlineDetailHook.applySignalrFlexProjectLineStatusUpdate(
            resultModel
          );
        }
      });
      functionSignalRConnection.on("flex-project-status-updated", (result) => {
        if (result) {
          //TODO:To remove for debugging purposes on dev
          console.log(`functionSignalRConnection flex-project-status-updated`);
          console.log(
            `functionSignalRConnection flex-project-status-updated fromMessage`,
            result
          );
          const resultModel: PmrE2EProjectLineUpdateStatusResultModel = toCamel(
            JSON.parse(result)
          );
          projectOrderlineDetailHook.applySignalrFlexProjectStatusUpdate(
            resultModel
          );
        }
      });

      functionSignalRConnection.on(
        "project-line-ecd-updated",
        (result: any) => {
          if (result) {
            let params = JSON.parse(result);
            setProjectLineIsMismatchECD(
              params.e2EProjectLineId,
              params.e2EProjectId,
              true,
              true,
              params.newECD
            );
          }
        }
      );

      functionSignalRConnection.on(
        "gpi-workflow-creation-success",
        (result) => {
          if (result) {
            console.log(
              `functionSignalRConnection GPI and Workflow Creation Successful`
            );

            const resultModel: IPmrGpiWorkflowCreationModel = toCamel(
              JSON.parse(result)
            );

            if (expandedRows[resultModel.e2EProjectId]) {
              showSuccess(
                "DM Integrations Ready",
                "DM integrations are complete. Icons are now enabled and ready for use."
              );
            }

            dispatch(updateRefreshProjectId(resultModel.e2EProjectId));
          }
        }
      );
    }
  }, [functionSignalRConnection]);

  useEffect(() => {
    if (projectLineIsMismatchECDConnection) {
      projectLineIsMismatchECDConnection.on(
        "project-line-ismismatch-ecd-updated-success",
        (params) => {
          if (params) {
            setProjectLineIsMismatchECD(
              params.e2EProjectLineId,
              params.e2EProjectId,
              false,
              params.e2EProjectHasMismatchECD
            );
          }
        }
      );
    }
  }, [projectLineIsMismatchECDConnection]);

  const setProjectLineIsMismatchECD = (
    orderLineId: string,
    projectId: string,
    status: boolean,
    projectHasMismatchECD: boolean,
    newECD?: string
  ) => {
    dispatch(
      updateIsMismatchECD({
        e2eProjectId: projectId,
        e2EProjectLineId: orderLineId,
        isMismatchECD: status,
        e2EProjectHasMismatchECD: projectHasMismatchECD,
        newECD: newECD,
      })
    );
  };

  return {};
};
export default useProjectManagementPmrSignalr;
