import { Tooltip } from "primereact/tooltip";
import classes from "./TaskCommentButton.module.scss";
import { faCommenting } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PmrE2EProjectOrderlineModel from "../../shared/interfaces/pmr-e2e-project-orderline-model";
import { v4 as uuidv4 } from "uuid";
import { classNames } from "primereact/utils";
import FlexIntegrationService from "../../../../services/FlexIntegrationService";
import RemarksHistoryDialog from "../../shared/components/remarks-history-dialog/RemarksHistoryDialog";
import RemarksTypesEnum from "../../shared/components/remarks-history-dialog/RemarksTypeEnum";
import { useState } from "react";
import { RemarksHistoryModel } from "../../../../shared/models/service-models/RemarksHistoryModel";
import moment from "moment";
import { CreateFlexTaskCommentModel } from "../../../../shared/models/CreateFlexTaskComment.model.";
import { orderBy } from "lodash";
import { FlexTaskCommentModel } from "../../../../shared/models/FlexTaskDetail.model";
import useToastr from "../../../../hooks/useToastr";
import ProjectService from "../../../../services/ProjectService";
import { useDispatch } from "react-redux";
import { updateE2EProjectLineFlexTasksComment } from "../../../../features/projectManagement/projectManagementSlice";
import ProjectTaskStatus from "../../shared/enums/project-task-status";

type Props = { rowData: PmrE2EProjectOrderlineModel };

const TaskCommentButton = (props: Props) => {
  const tooltipClassNameTarget = "tooltip_" + uuidv4();
  const [taskComments, setTaskComments] = useState<FlexTaskCommentModel[]>([]);
  const [isLoadingComments, setIsLoadingComments] = useState<boolean>(false);
  const [isCommentsDialogVisible, setIsCommentsDialogVisible] =
    useState<boolean>(false);
  const { showSuccess, showError } = useToastr();
  const dispatch = useDispatch();
  const [isAddCommentEnabled, setIsAddCommentEnabled] = useState(false);

  if (!props.rowData.isAdHoc) {
    return null;
  }

  const openCommentsModal = async () => {
    setIsLoadingComments(true);
    setIsCommentsDialogVisible(true);

    try {
      const result = await FlexIntegrationService.getAllTaskComments(
        props.rowData.flexProjectTaskId
      );

      const orderedComments: FlexTaskCommentModel[] = orderBy(
        result.data,
        "createdDate",
        "desc"
      );
      setTaskComments(orderedComments);

      const canAddComment =
        await ProjectService.canUserAddE2EProjectLineFlexTaskComment(
          props.rowData.flexProjectTaskId
        );
      setIsAddCommentEnabled(canAddComment.data);
    } catch (error) {}

    setIsLoadingComments(false);
  };

  const handleAddComment = async (params: any) => {
    const model: CreateFlexTaskCommentModel = {
      flexTaskId: props.rowData.flexProjectTaskId,
      newComment: params.value,
    };

    try {
      const result = await ProjectService.createFlexTaskComment(model);
      if (!result?.isSuccess) throw result.message;

      setTaskComments((prev) => [result.data, ...prev]);

      showSuccess(
        "Comment Added",
        `Your comment for <b>'${props.rowData.orderLineDescription}'</b> has been successfully added.`
      );

      dispatch(
        updateE2EProjectLineFlexTasksComment({
          flexProjectTaskId: props.rowData.flexProjectTaskId,
          comment: result.data.text,
          commentedBy: result.data.createdByEmail,
        })
      );
    } catch (error) {
      showError(
        "Comment Error",
        "There was an error adding your comment. Please try again. Contact our support team if issue persists."
      );
    }
  };

  const mappedComments = taskComments.map((task) => ({
    user: task.createdByDisplayName!,
    dateTimeDisplay: moment(task.createdDate!).format(
      "DD MMM YYYY | hh:mm:ss A"
    ),
    value: task.text!,
    displayName: task.createdByDisplayName!,
  }));

  return (
    <>
      {!!props.rowData.comment && (
        <Tooltip
          className={classes["comment-tooltip"]}
          target={`.${tooltipClassNameTarget}`}
        >
          <b>{props.rowData.commentedBy}</b>
          <div>{props.rowData.comment}</div>
        </Tooltip>
      )}

      <button
        onClick={openCommentsModal}
        className={classNames(classes["btn-comment"], tooltipClassNameTarget)}
      >
        <FontAwesomeIcon icon={faCommenting} />
      </button>

      <RemarksHistoryDialog
        isAddDisabled={
          props.rowData.status === ProjectTaskStatus.Completed ||
          !isAddCommentEnabled
        }
        onAdd={handleAddComment}
        disableAddButtonWhenCommentIsEmpty
        isModalLoading={isLoadingComments}
        closeDialog={() => setIsCommentsDialogVisible(false)}
        header="Comments"
        e2eProjectLineId={props.rowData.e2EProjectLineId!}
        isOpen={isCommentsDialogVisible}
        remarkType={RemarksTypesEnum.TaskComment}
        list={mappedComments}
      />
    </>
  );
};

export default TaskCommentButton;
