import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import classes from "./CreateOptions.module.scss";

const CreateOptions = ({ setCurrentStep, setModalSteps }) => {
  const onClickManually = () => {
    setModalSteps((prevState) => {
      const additionalSteps = [{ number: 3 }, { number: 4 }];
      const newSteps = prevState.concat(additionalSteps);
      return newSteps;
    });
    setCurrentStep(2);
  };

  const onImportSpreadsheet = () => {
    //No Bulk Import Using IP Integration - Disable First
    return;
    setCurrentStep(2);
  };

  return (
    <div className={`${classes["create-options-container"]}`}>
      <span className={`${classes["action-sub-title"]}`}>
        Choose your preferred way to add certificates
      </span>
      <div className={`${classes["options-container"]}`}>
        <div className={`${classes["options"]}`} onClick={onImportSpreadsheet}>
          <FontAwesomeIcon
            icon={faFileImport}
            className={`${classes["import-icon"]}`}
          />
          <span className={`${classes["action-title"]}`}>
            Import from a spreadsheet
          </span>
          <span className={`${classes["action-sub-title"]}`}>
            Upload a spreadsheet with data for one or more certificates
          </span>
        </div>
        <div className={`${classes["options"]}`} onClick={onClickManually}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            className={`${classes["manual-icon"]}`}
          />
          <span className={`${classes["action-title"]}`}>
            Enter New Certificate Manually
          </span>
          <span className={`${classes["action-sub-title"]}`}>
            Type in your certificate data for one certificate at a time
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreateOptions;
