import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import classes from "./TimeEntryModal.module.scss";
import clsx from "clsx";
import { startOfWeek, addWeeks, format, endOfWeek, addDays } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faInfoCircle,
  faQuestionCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { useDispatch, useSelector } from "react-redux";
import ProjectTimeEntryService from "../../../services/ProjectTimeEntryService";
import { flatten, get, uniqBy } from "lodash";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import useToastr from "../../../hooks/useToastr";
import PmrE2EProjectOrderlineModel from "../shared/interfaces/pmr-e2e-project-orderline-model";
import { updateTimeEntryUpdateTaskOrderlines } from "../../../features/projectManagementPmr/projectManagementPmrSlice";
import TimeEntryModalSourceType from "../shared/enums/time-entry-modal-source-type";
import { InputSwitch, InputSwitchChangeParams } from "primereact/inputswitch";
import {
  selectFlexTaskMilestonesList,
  selectFlexTaskOrderLinesList,
  selectIsTimeEntryModalSyncing,
  selectProjectFlexTasksList,
  selectSelectedTaskOrderlines,
  selectUnfilteredFlexTaskMilestonesList,
  updateAllProjectLevels,
  updateTimeEntryModalSyncing,
} from "../../../features/projectManagement/projectManagementSlice";
import { SyncTimeEntryRequestModel } from "../shared/interfaces/pmr-e2e-time-entry-sync-model";
import useTimeEntrySync from "../../../hooks/useTimeEntrySync";

interface TimeEntryModalProps {
  closeTimeEntryModal: () => void;
  visible: boolean;
  e2eProjectLineFlexTaskIds: string[];
  timeEntryModalSourceType: TimeEntryModalSourceType;
}
interface TimeEntryMilestone {
  id?: string;
  name?: string;
}
interface TimeEntryWorkHours {
  timeEntryDate: string;
  totalTimeEntryValue: number;
  newTotalTimeEntryValue?: number;
  distributeTotal?: number;
  hasError?: boolean;
  isEarlierThanOrderBookedDate: boolean;
}
interface TimeEntryTask {
  id?: string;
  ids: string[];
  flexProjectNumber?: string;
  isRevenueRecognition?: boolean;
  orderLineDescription?: string;
  flexTaskName?: string;
  e2EProjectLineMilestones?: TimeEntryMilestone[];
  userWorkHours?: TimeEntryWorkHours[];
  name?: string;
}
interface TimeEntryPreserveWeekData {
  week: Date;
  taskList: TimeEntryTask[];
  totalTimeEntryPerDayList: TimeEntryWorkHours[];
}
const TimeEntryModal: React.FC<TimeEntryModalProps> = (
  props: TimeEntryModalProps
) => {
  const dispatch = useDispatch();
  const { showSuccess, showError } = useToastr();
  const [orderlineList, setOrderlineList] = useState(new Array());
  const [selectedOrderLines, setSelectedOrderLines] = useState(new Array());
  const [selectedWeek, setSelectedWeek] = useState(startOfWeek(new Date()));
  const [selectedWeekDropdown, setSelectedWeekDropdown] = useState<any>();
  const [availableWeeks, setAvailableWeeks] = useState(new Array());
  const [isDistributeHoursSwitched, setIsDistributeHoursSwitched] =
    useState(true);
  const [taskList, setTaskList] = useState<TimeEntryTask[]>(new Array());
  const [totalTimeEntryPerDayList, setTotalTimeEntryPerDayList] = useState<
    TimeEntryWorkHours[]
  >(new Array());
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSingleTimeEntry, setIsSingleTimeEntry] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [preserveWeekData, setPreserveWeekData] = useState<
    TimeEntryPreserveWeekData[]
  >(new Array());
  const selectedOrderLinesTimeEntry = useSelector(selectSelectedTaskOrderlines);
  const projectFlexTasksList = useSelector(selectProjectFlexTasksList);
  const projectFlexTaskOrderlinesList = useSelector(
    selectFlexTaskOrderLinesList
  );
  const projectMilestonesList = useSelector(
    selectUnfilteredFlexTaskMilestonesList
  );
  const isTimeEntryModalSyncing = useSelector(selectIsTimeEntryModalSyncing);
  const timeEntrySyncHook = useTimeEntrySync();
  useEffect(() => {
    if (props.visible && props.e2eProjectLineFlexTaskIds.length == 0) {
      props.closeTimeEntryModal();
    } else if (props.visible && props.e2eProjectLineFlexTaskIds.length > 0) {
      setDatas();
      getTimeEntryDetails(selectedWeek, props.e2eProjectLineFlexTaskIds);
    }
  }, [props.visible]);

  //On Change of assign my hours dropdown
  useEffect(() => {
    if (!isInitialLoad) {
      const e2EProjectLineFlexTaskIds = flatten(
        selectedOrderLines.map((group) => group.ids)
      );
      if (e2EProjectLineFlexTaskIds.length > 0)
        getTimeEntryDetails(selectedWeek, e2EProjectLineFlexTaskIds, true);
    }
  }, [selectedOrderLines]);

  //On Change of selected week
  useEffect(() => {
    if (!isInitialLoad) {
      const e2EProjectLineFlexTaskIds = flatten(
        selectedOrderLines.map((group) => group.ids)
      );
      if (e2EProjectLineFlexTaskIds.length > 0)
        getTimeEntryDetails(selectedWeek, e2EProjectLineFlexTaskIds);
    }
  }, [selectedWeek]);

  const reset = () => {
    //setIsDistributeHoursSwitched(false);
    setTaskList(new Array());
    setTotalTimeEntryPerDayList(new Array());
  };

  useEffect(() => {
    if (!taskList) return;
    computeColumnHeader();
  }, [taskList]);

  const computeColumnHeader = () => {
    setTotalTimeEntryPerDayList((prevlist: TimeEntryWorkHours[]) => {
      let newList = [...prevlist];

      newList.forEach((totalWorkHours: TimeEntryWorkHours) => {
        totalWorkHours.newTotalTimeEntryValue = 0;
        totalWorkHours.hasError = false;

        taskList.forEach((task: TimeEntryTask) => {
          const value =
            task.userWorkHours?.find(
              (x) => x.timeEntryDate == totalWorkHours.timeEntryDate
            )?.totalTimeEntryValue ?? 0;
          totalWorkHours.newTotalTimeEntryValue =
            (totalWorkHours.newTotalTimeEntryValue ?? 0) + value;
        });
        if (
          totalWorkHours.newTotalTimeEntryValue +
            totalWorkHours.totalTimeEntryValue >
          24
        ) {
          totalWorkHours.hasError = true;
        }
        totalWorkHours.distributeTotal = JSON.parse(
          JSON.stringify(totalWorkHours.newTotalTimeEntryValue)
        );
      });
      return newList;
    });
  };

  const processDistributeHours = (
    localTotalTimeEntryPerDayList: TimeEntryWorkHours[],
    timeEntryDate: string
  ) => {
    const hasError = localTotalTimeEntryPerDayList.some(
      (item) => (item.distributeTotal ?? 0) + item.totalTimeEntryValue > 24
    );
    if (hasError) return;

    setTaskList((prevlist: TimeEntryTask[]) => {
      let tasks = [...prevlist];

      for (let totalWorkHours of localTotalTimeEntryPerDayList) {
        if (totalWorkHours.timeEntryDate != timeEntryDate) continue;
        const validTaskRowCount = tasks
          .flatMap((task) => task.userWorkHours)
          .filter(
            (userWorkHour) =>
              userWorkHour?.timeEntryDate == timeEntryDate &&
              !userWorkHour.isEarlierThanOrderBookedDate
          ).length;
        let distributedValue = 0;
        const distributeTotal = totalWorkHours.distributeTotal ?? 0;
        if (distributeTotal != 0 && validTaskRowCount != 0)
          distributedValue = distributeTotal / validTaskRowCount;

        tasks.forEach((task: TimeEntryTask) => {
          task.userWorkHours?.forEach((userWorkHour: TimeEntryWorkHours) => {
            if (
              userWorkHour.timeEntryDate == totalWorkHours.timeEntryDate &&
              !userWorkHour.isEarlierThanOrderBookedDate
            )
              userWorkHour.totalTimeEntryValue = distributedValue;
          });
        });
      }
      return tasks;
    });
  };

  const setDatas = () => {
    const availableWeekList = [];
    availableWeekList.push({
      ...selectedDateRange("MMM dd, yyyy", selectedWeek),
      isCurrentWeek: true,
    });
    setSelectedWeekDropdown({
      ...selectedDateRange("MMM dd, yyyy", selectedWeek),
      isCurrentWeek: true,
    });
    availableWeekList.push({
      ...selectedDateRange("MMM dd, yyyy", addWeeks(selectedWeek, -1)),
      isCurrentWeek: false,
    });
    setAvailableWeeks(availableWeekList);
  };

  const getTimeEntryDetails = async (
    week: Date,
    e2EProjectLineFlexTaskIds: string[],
    executeDistributeHours: boolean = false
  ) => {
    setIsTableLoading(true);

    await getDataFromApi(
      week,
      e2EProjectLineFlexTaskIds,
      executeDistributeHours
    );

    setIsTableLoading(false);
  };

  const getDataFromApi = async (
    week: Date,
    e2EProjectLineFlexTaskIds: string[],
    executeDistributeHours: boolean = false
  ) => {
    const model = {
      startDate: selectedDateRange("yyyy-MM-dd", week).startOfWeek,
      endDate: selectedDateRange("yyyy-MM-dd", week).endOfWeekDate,
      e2EProjectLineFlexTaskIds: e2EProjectLineFlexTaskIds,
    };
    await processTimeEntrySync(e2EProjectLineFlexTaskIds);
    const resultTotalWorkHoursPerDay =
      await ProjectTimeEntryService.getUserTimeEntriesByDateRange(model);
    let totalWorkHours: TimeEntryWorkHours[] =
      resultTotalWorkHoursPerDay.data ?? [];

    const resultUserWorkHoursPerTask =
      await ProjectTimeEntryService.getUserTimeEntriesByTaskAndDateRange(model);

    let tasks: TimeEntryTask[] = [];
    tasks = resultUserWorkHoursPerTask.data ?? [];

    if (tasks.length > 1) setIsSingleTimeEntry(false);
    else setIsDistributeHoursSwitched(false);

    if (isInitialLoad) {
      let groupOrderlineList: TimeEntryTask[] = tasks.map(
        (item: TimeEntryTask) => {
          let returnItem: TimeEntryTask = {
            ids: [],
            flexProjectNumber: item.flexProjectNumber,
            orderLineDescription: item.orderLineDescription,
            name: `${item.flexProjectNumber} | ${item.orderLineDescription}`,
          };
          return returnItem;
        }
      );
      const uniqOrderLines = uniqBy(groupOrderlineList, `name`);
      uniqOrderLines.forEach((item: TimeEntryTask) => {
        const ids: any = tasks
          .filter(
            (task) =>
              task.flexProjectNumber == item.flexProjectNumber &&
              task.orderLineDescription == item.orderLineDescription
          )
          .map((task) => task.id);

        item.ids = ids;
      });
      setOrderlineList(uniqOrderLines);
      setSelectedOrderLines(uniqOrderLines);
      setIsInitialLoad(false);
    }

    const preserveData = preserveWeekData.find(
      (preserveData: TimeEntryPreserveWeekData) =>
        selectedDateRange("yyyy-MM-dd", preserveData.week).startOfWeek ==
        selectedDateRange("yyyy-MM-dd", week).startOfWeek
    );

    if (preserveData) {
      setTotalTimeEntryPerDayList(preserveData.totalTimeEntryPerDayList);
      //if distribute mode and has data, just distribute total column header values
      if (isDistributeHoursSwitched && executeDistributeHours) {
        for (let totalWorkHourPerDay of preserveData.totalTimeEntryPerDayList) {
          if (totalWorkHourPerDay.distributeTotal != 0) {
            let distributeValue = 0;
            const total = totalWorkHourPerDay.distributeTotal || 0;
            distributeValue = total / tasks.length;
            for (let task of tasks) {
              for (let userWork of task.userWorkHours || []) {
                if (userWork.timeEntryDate == totalWorkHourPerDay.timeEntryDate)
                  userWork.totalTimeEntryValue = distributeValue;
              }
            }
          }
        }
      } else {
        for (let task of tasks) {
          const preserveTaskData = preserveData.taskList.find(
            (preserveTask) => task.id == preserveTask.id
          );
          if (!preserveTaskData) continue;

          for (let userWork of task.userWorkHours || []) {
            const timeEntryValue =
              preserveTaskData.userWorkHours?.find(
                (preserveWorkHour) =>
                  preserveWorkHour.timeEntryDate == userWork.timeEntryDate
              )?.totalTimeEntryValue || 0;
            userWork.totalTimeEntryValue = timeEntryValue;
          }
        }
      }

      //Filter out this weeks data
      setPreserveWeekData((prevList) => {
        let newList = [...prevList];
        newList = newList.filter(
          (preserveData: TimeEntryPreserveWeekData) =>
            selectedDateRange("yyyy-MM-dd", preserveData.week).startOfWeek !=
            selectedDateRange("yyyy-MM-dd", week).startOfWeek
        );
        return newList;
      });
    } else {
      setTotalTimeEntryPerDayList(totalWorkHours);
    }
    setTaskList(tasks);
  };

  const processTimeEntrySync = async (e2EProjectLineFlexTaskIds: string[]) => {
    const isTaskOrderlineTimeEntrySyncing = projectFlexTasksList.some(
      (taskList) =>
        taskList.tasks.some((task) =>
          task.timeEntrySnycFlexTaskList.some((te) => te.isTimeEntrySyncing)
        )
    );

    if (!isTaskOrderlineTimeEntrySyncing && !isTimeEntryModalSyncing) {
      let calls: Promise<void>[] = [];
      for (let id of e2EProjectLineFlexTaskIds) {
        calls.push(
          timeEntrySyncHook.syncTimeEntryModal(
            [id],
            selectedDateRange("yyyy-MM-dd", selectedWeek).startOfWeek,
            selectedDateRange("yyyy-MM-dd", selectedWeek).endOfWeekDate
          )
        );
      }
      await Promise.all(calls);
    }
  };

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const selectedDateRange = (
    dateFormat: string = "MMM dd, yyyy",
    week: any
  ) => {
    if (!week) return {};
    const startOfWeek = format(week, dateFormat);
    const endOfWeekDate = format(endOfWeek(week), dateFormat);
    const weekRange = `${startOfWeek} - ${endOfWeekDate}`;
    return {
      startOfWeek: startOfWeek,
      endOfWeekDate: endOfWeekDate,
      weekRange: weekRange,
      week,
    };
  };
  const changeWeek = (direction: number) => {
    preserveCurrentWeekData();
    setSelectedWeek((currentWeek) => addWeeks(currentWeek, direction));
    setSelectedWeekDropdown((prevSelectedWeek: any) => {
      return {
        ...selectedDateRange(
          "MMM dd, yyyy",
          addWeeks(prevSelectedWeek.week, direction)
        ),
        isCurrentWeek: direction == 1,
      };
    });
    reset();
  };
  const preserveCurrentWeekData = () => {
    const preserve: TimeEntryPreserveWeekData = {
      week: selectedWeek,
      taskList: taskList,
      totalTimeEntryPerDayList: totalTimeEntryPerDayList,
    };
    setPreserveWeekData((prevList) => {
      let newList = [...prevList];
      newList = [...newList, preserve];
      return newList;
    });
  };
  const headerContent = (
    <>
      Log Work Hours to Time Entry &nbsp;
      <button className={`${classes["popover-icon"]} header-target`}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </button>
      <Tooltip
        target={`.header-target`}
        className="custom-tooltip"
        my="left top"
        at="left bottom"
      >
        <div>
          <div>Tips:</div>
          <div>1. Click on a cell to enter hours for a specific date.</div>
          <div>
            2.{" "}
            <span
              className={`${classes["text-light-blue"]} ${classes["text-bold"]}`}
            >
              (0.00)
            </span>{" "}
            - This is your current number of logged work hours for the specific
            date.
          </div>
          <div>
            3. Please enter valid hours in the range of{" "}
            <span className={classes["text-bold"]}>0-24</span> and restrict the
            precision of decimal value to{" "}
            <span className={classes["text-bold"]}>
              2. (eg 2.25, 3.56, 0.44).
            </span>
          </div>
        </div>
      </Tooltip>
    </>
  );

  const onHideModal = () => {
    props.closeTimeEntryModal();
  };

  const tableColumnBody = (rowData: TimeEntryTask, field: string) => {
    return (
      <div title={get(rowData, field)} className={"text-container-ellipsis"}>
        {get(rowData, field)}
      </div>
    );
  };

  const renderStaticColumns = () => {
    return [
      <Column
        header={
          <div className={classes["column-header-padding-left"]}>
            Project Number
          </div>
        }
        body={(rowData: TimeEntryTask) =>
          tableColumnBody(rowData, "flexProjectNumber")
        }
        style={{
          width: "90px",
          flexBasis: "90px",
          flexGrow: 1,
        }}
      />,
      <Column
        key="orderLineDescription"
        header={
          <div className={classes["column-header-padding-left"]}>
            Order Line Number
          </div>
        }
        body={(rowData: TimeEntryTask) =>
          tableColumnBody(rowData, "orderLineDescription")
        }
        style={{
          width: "200px",
          flexBasis: "200px",
          flexGrow: 1,
        }}
      />,
      <Column
        key="flexTaskName"
        header={
          <div className={classes["column-header-padding-left"]}>Task Name</div>
        }
        field="flexTaskName"
        body={(rowData: TimeEntryTask) => {
          return (
            <>
              <button
                className={`${classes["popover-icon"]} tooltip-tasknameicon-target-${rowData.id}`}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </button>
              <div
                className={clsx(
                  "text-container-ellipsis",
                  rowData.isRevenueRecognition && classes["text-brown"],
                  classes["task-name"],
                  `tooltip-taskname-target-${rowData.id}`
                )}
              >
                {rowData.flexTaskName}
                <Tooltip
                  target={`.tooltip-taskname-target-${rowData.id}`}
                  className="custom-tooltip"
                  my="left top"
                  at="left bottom"
                >
                  {rowData.isRevenueRecognition
                    ? "This is a Revenue Recognition Task"
                    : `${rowData.flexTaskName}`}
                </Tooltip>
              </div>
              <Tooltip
                target={`.tooltip-tasknameicon-target-${rowData.id}`}
                className="custom-tooltip"
                my="left top"
                at="left bottom"
              >
                <div className={classes["text-bold"]}>
                  Logged Work Hours will be equally distributed to these
                  Milestones:
                </div>
                {rowData.e2EProjectLineMilestones?.map((milestone: any) => {
                  return (
                    <div style={{ marginTop: "15px" }}>{milestone.name}</div>
                  );
                })}
              </Tooltip>
            </>
          );
        }}
        style={{
          width: "200px",
          flexBasis: "200px",
          flexGrow: 1,
        }}
      />,
    ];
  };

  const getDay = (index: number, dateFormat: string = "EEE, MM/dd") => {
    return format(addDays(selectedWeek, index), dateFormat);
  };
  const getColumnHeaderValue = (index: number): TimeEntryWorkHours => {
    const formattedDay = getDay(index, "yyyy-MM-dd");
    const workHoursPerDay = totalTimeEntryPerDayList.find(
      (item) => item.timeEntryDate == formattedDay
    );

    return (
      workHoursPerDay ?? {
        timeEntryDate: formattedDay,
        totalTimeEntryValue: 0,
        newTotalTimeEntryValue: 0,
        distributeTotal: 0,
        hasError: false,
        isEarlierThanOrderBookedDate: false,
      }
    );
  };
  const tableHeaderValueChanged = (value: number, timeEntryDate: string) => {
    setTotalTimeEntryPerDayList((prevList: TimeEntryWorkHours[]) => {
      const totalWorkHoursList = prevList.map((item: TimeEntryWorkHours) => {
        let returnItem = item;
        if (item.timeEntryDate == timeEntryDate) {
          returnItem.distributeTotal = value || 0;
        }
        return returnItem;
      });
      processDistributeHours(totalWorkHoursList, timeEntryDate);
      return totalWorkHoursList;
    });
  };
  const tableDayColumnHeaderTooltip = (
    timeEntryDate: string,
    hasError: boolean
  ) => {
    if (isAllTaskRowInvalid(timeEntryDate))
      return `Logging hours before the order booked date is not permitted.`;
    if (hasError)
      return `Invalid entry. You have exceeded the maximum number of accepted work hours per day. Adjust your time input.`;
    return `Enter hours (0-24); decimals up to two places allowed.`;
  };
  const tableDayColumnheaderDisabled = (timeEntryDate: string) => {
    return isAllTaskRowInvalid(timeEntryDate);
  };
  const tableDayColumnHeaderCursorNotAllowed = (timeEntryDate: string) => {
    return isAllTaskRowInvalid(timeEntryDate);
  };
  const isAllTaskRowInvalid = (timeEntryDate: string) => {
    const tasksRowCount = taskList.length;
    const inValidTaskRowCount = taskList
      .flatMap((task) => task.userWorkHours)
      .filter(
        (userWorkHour) =>
          userWorkHour?.timeEntryDate == timeEntryDate &&
          userWorkHour.isEarlierThanOrderBookedDate
      ).length;
    return tasksRowCount == inValidTaskRowCount;
  };
  const tableDayColumnHeader = (index: number) => {
    const hasError =
      getColumnHeaderValue(index).totalTimeEntryValue +
        (getColumnHeaderValue(index).distributeTotal ?? 0) >
      24;
    return (
      <div className={classes["day-column-header-container"]}>
        <div className={`${classes["day-column-header-top"]} `}>
          <Button
            className="p-button-text p-button-plain"
            tooltip={`This is your current number of logged work hours for this date.\nYou have (${
              24 - getColumnHeaderValue(index).totalTimeEntryValue
            }) remaining hours you can consume.`}
            tooltipOptions={{
              className: `custom-tooltip`,
              my: "left top",
              at: "left bottom",
            }}
            style={{ paddingRight: "5px" }}
          >
            <span
              className={`${classes["text-light-blue"]} tooltip-daycolumnheader-target-${index}`}
            >
              ({getColumnHeaderValue(index).totalTimeEntryValue?.toFixed(2)})
            </span>
          </Button>
          {getDay(index)}
        </div>
        <div className={classes["day-column-header-bottom"]}>
          {isDistributeHoursSwitched ? (
            <div
              className={clsx(
                classes["input-number"],
                hasError && classes["input-error"]
              )}
            >
              <InputNumber
                style={{
                  cursor: tableDayColumnHeaderCursorNotAllowed(
                    getColumnHeaderValue(index).timeEntryDate
                  )
                    ? "not-allowed"
                    : "auto",
                }}
                tooltip={tableDayColumnHeaderTooltip(
                  getColumnHeaderValue(index).timeEntryDate,
                  hasError
                )}
                tooltipOptions={{
                  className: `custom-tooltip`,
                  my: "left top",
                  at: `left bottom`,
                }}
                disabled={tableDayColumnheaderDisabled(
                  getColumnHeaderValue(index).timeEntryDate
                )}
                placeholder="0.00"
                maxFractionDigits={2}
                min={0}
                value={getColumnHeaderValue(index).distributeTotal}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === "Enter") {
                    const newValue = (event.target as HTMLInputElement).value;
                    const parsedToIntNewValue = newValue ? +newValue : 0;
                    tableHeaderValueChanged(
                      parsedToIntNewValue,
                      getColumnHeaderValue(index).timeEntryDate
                    );
                  }
                }}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  const newValue = (event.target as HTMLInputElement).value;
                  const parsedToIntNewValue = newValue ? +newValue : 0;
                  tableHeaderValueChanged(
                    parsedToIntNewValue,
                    getColumnHeaderValue(index).timeEntryDate
                  );
                }}
              />
            </div>
          ) : (
            <label
              className={clsx(
                getColumnHeaderValue(index).hasError && classes["text-red"]
              )}
            >
              ({getColumnHeaderValue(index).newTotalTimeEntryValue?.toFixed(2)})
            </label>
          )}
        </div>
      </div>
    );
  };

  const tableDayBodyTooltip = (
    rowData: TimeEntryTask,
    index: number,
    workHoursPerDay?: TimeEntryWorkHours
  ) => {
    if (get(rowData, `userWorkHours[${index}].isEarlierThanOrderBookedDate`))
      return `Logging hours before the order booked date is not permitted.`;
    if (workHoursPerDay?.hasError)
      return `Invalid entry. You have exceeded the maximum number of accepted work hours per day. Adjust your time input.`;
    return `Enter hours (0-24); decimals up to two places allowed.`;
  };

  const tableDayBodyDisabled = (rowData: TimeEntryTask, index: number) => {
    return (
      isDistributeHoursSwitched ||
      get(rowData, `userWorkHours[${index}].isEarlierThanOrderBookedDate`)
    );
  };

  const tableDayBodyCursorNotAllowed = (
    rowData: TimeEntryTask,
    index: number
  ) => {
    return get(rowData, `userWorkHours[${index}].isEarlierThanOrderBookedDate`);
  };
  const tableDayBody = (rowData: TimeEntryTask, index: number) => {
    const formattedDay = getDay(index, "yyyy-MM-dd");
    const workHoursPerDay = totalTimeEntryPerDayList.find(
      (item) => item.timeEntryDate == formattedDay
    );
    return (
      <div className={classes["day-column-body"]}>
        <div
          className={clsx(
            classes["input-number"],
            workHoursPerDay?.hasError && classes["input-error"]
          )}
        >
          <InputNumber
            style={{
              cursor: tableDayBodyCursorNotAllowed(rowData, index)
                ? "not-allowed"
                : "auto",
            }}
            tooltip={tableDayBodyTooltip(rowData, index, workHoursPerDay)}
            tooltipOptions={{
              className: `custom-tooltip`,
              my: "left top",
              at: `left bottom`,
            }}
            maxFractionDigits={2}
            min={0}
            disabled={tableDayBodyDisabled(rowData, index)}
            value={get(rowData, `userWorkHours[${index}].totalTimeEntryValue`)}
            onValueChange={(e) => {
              setTaskList((prevList: TimeEntryTask[]) => {
                const taskList = prevList.map((item: TimeEntryTask) => {
                  const formattedDay = getDay(index, "yyyy-MM-dd");
                  let returnItem = item;
                  if (item.id == rowData.id) {
                    item.userWorkHours?.forEach(
                      (workHours: TimeEntryWorkHours) => {
                        if (workHours.timeEntryDate == formattedDay) {
                          workHours.totalTimeEntryValue = e.value || 0;
                        }
                      }
                    );
                  }
                  return returnItem;
                });
                return taskList;
              });
            }}
          />
        </div>
      </div>
    );
  };

  const renderDayColumn = (day: string, index: number) => {
    return (
      <Column
        key={index}
        header={tableDayColumnHeader(index)}
        body={(rowData) => tableDayBody(rowData, index)}
        style={{
          width: "100px",
          flexBasis: "100px",
          flexGrow: 1,
        }}
      />
    );
  };
  const changeDistributeHours = (props: InputSwitchChangeParams) => {
    const isOn = props.value;
    setIsDistributeHoursSwitched(isOn);
  };
  const renderDistributeHoursButton = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p>
          <strong> Enter work hours for each day</strong> |
          <i>
            &nbsp; (<span className={classes["text-brown"]}>"Task Name"</span>
            &nbsp;-&nbsp;
            <span>Revenue Recognition Task)</span>
          </i>
        </p>
        {!isSingleTimeEntry && (
          <>
            <div className={classes["distribute-switch-container"]}>
              <span className={classes["distribute-switch-label"]}>
                Distribute Hours
              </span>
              <InputSwitch
                id="checked"
                checked={isDistributeHoursSwitched}
                className={clsx(
                  classes["distribute-switch"],
                  isDistributeHoursSwitched && classes["is-on"]
                )}
                onChange={changeDistributeHours}
                disabled={isTableHasError()}
              ></InputSwitch>
            </div>
          </>
        )}
      </div>
    );
  };
  const renderDataTable = () => {
    return (
      <>
        <div className={classes["header-container"]}>
          {!isSingleTimeEntry && (
            <div className={classes["assign-hours-container"]}>
              <div
                className={`${classes["header-label"]} ${classes["header-label-assignhours"]}`}
              >
                <div className={classes["left"]}>
                  Assign my hours
                  <Button
                    className="p-button-text p-button-plain"
                    tooltip={`Select the order lines to input your work hours`}
                    tooltipOptions={{
                      className: `custom-tooltip`,
                      my: "left top",
                      at: "left bottom",
                    }}
                    style={{ paddingLeft: "5px" }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Button>
                </div>
                <div className={classes["right"]}>
                  ({orderlineList.length} selected order lines)
                </div>
              </div>
              <div>
                <MultiSelect
                  className={classes["dropdown-assign-hours"]}
                  value={selectedOrderLines}
                  options={orderlineList}
                  onChange={(e) => {
                    if (e.target.value.length == 0) return;
                    preserveCurrentWeekData();
                    setSelectedOrderLines(e.value);
                  }}
                  placeholder="Select an Option"
                  filter
                  optionLabel="name"
                  maxSelectedLabels={orderlineList.length - 1}
                  selectedItemsLabel="All"
                  disabled={
                    isSaving ||
                    isTableLoading ||
                    orderlineList.length == 1 ||
                    isTableHasError()
                  }
                />
              </div>
            </div>
          )}
          <div className={`${classes["select-week-container"]}`}>
            <div className={classes["header-label"]}>Select your work week</div>
            <div>
              <Button
                className={`p-button-text ${classes["select-week-btn-left"]}`}
                onClick={() => changeWeek(-1)}
                disabled={
                  !selectedWeekDropdown?.isCurrentWeek ||
                  isTableLoading ||
                  isTableHasError()
                }
                icon="pi pi-chevron-left"
              />
              <Dropdown
                style={{ width: "255px" }}
                className={`${classes["dropdown-week-range"]}`}
                options={availableWeeks}
                optionLabel="weekRange"
                value={selectedWeekDropdown}
                onChange={(e) => {
                  preserveCurrentWeekData();
                  setSelectedWeekDropdown(e.value);
                  setSelectedWeek(e.value?.week);
                }}
                disabled={isTableLoading || isTableHasError()}
              />
              <Button
                className={`p-button-text ${classes["select-week-btn-right"]}`}
                onClick={() => changeWeek(1)}
                disabled={
                  selectedWeekDropdown?.isCurrentWeek ||
                  isTableLoading ||
                  isTableHasError()
                }
                icon="pi pi-chevron-right"
              />
            </div>
          </div>
        </div>
        {renderDistributeHoursButton()}
        <div className={classes["datatable-container"]}>
          <DataTable
            value={taskList}
            loading={isTableLoading}
            scrollable
            size="small"
            showGridlines
          >
            {renderStaticColumns()}
            {daysOfWeek.map(renderDayColumn)}
          </DataTable>
        </div>
      </>
    );
  };

  const save = async () => {
    setIsSaving(true);
    const userTimeEntriesTaskList = taskList.map((item: TimeEntryTask) => {
      let returnItem: any = {};
      returnItem.e2EProjectLineFlexTaskId = item.id;
      returnItem.timeEntries = [];
      item.userWorkHours?.forEach((workHours: TimeEntryWorkHours) => {
        returnItem.timeEntries.push({
          timeEntryDate: workHours.timeEntryDate,
          timeEntryValue: workHours.totalTimeEntryValue,
        });
      });

      return returnItem;
    });
    if (preserveWeekData && preserveWeekData.length == 1) {
      const preserveData = preserveWeekData[0];
      userTimeEntriesTaskList.forEach((item) => {
        preserveData.taskList.forEach((preserveTask) => {
          if (item.e2EProjectLineFlexTaskId == preserveTask.id) {
            preserveTask.userWorkHours?.forEach(
              (workHours: TimeEntryWorkHours) => {
                item.timeEntries.push({
                  timeEntryDate: workHours.timeEntryDate,
                  timeEntryValue: workHours.totalTimeEntryValue,
                });
              }
            );
          }
        });
      });
    }
    const updateModel = {
      timeEntryPerTask: userTimeEntriesTaskList,
    };
    const response = await ProjectTimeEntryService.updateFlexTaskTimeEntry(
      updateModel
    );
    if (response?.isSuccess) {
      showSuccess(
        "Success!",
        `Work hours successfully logged and updated in the system.${
          props.timeEntryModalSourceType == TimeEntryModalSourceType.TimeStamp
            ? ` You may now timestamp again.`
            : ``
        }`
      );
      const updatedTaskOrderlines = userTimeEntriesTaskList.map((task) => {
        return { e2EProjectLineFlexTaskId: task.e2EProjectLineFlexTaskId };
      });
      dispatch(updateTimeEntryUpdateTaskOrderlines(updatedTaskOrderlines));
      props.closeTimeEntryModal();
    } else {
      showError(
        "Failed to log hours",
        "Failed to log hours due to a system error. Please try again later or contact support if the problem persists."
      );
    }

    setIsSaving(false);
  };
  const isTableHasError = () => {
    const hasTableBodyError = totalTimeEntryPerDayList.some(
      (totalTimeEntry) => totalTimeEntry.hasError == true
    );
    return hasTableBodyError || isTableHeaderHasError();
  };
  const isTableHeaderHasError = () => {
    return daysOfWeek.some((item: string, index: number) => {
      const hasError =
        getColumnHeaderValue(index).totalTimeEntryValue +
          (getColumnHeaderValue(index).distributeTotal ?? 0) >
        24;
      return hasError;
    });
  };
  const isSaveButtonDisabled = () => {
    return isSaving || isTableHasError();
  };
  const renderFooter = (
    <>
      <hr className="modal-footer-hr" />
      <div className={`${classes["dialog-footer"]} ${classes["center-items"]}`}>
        <button
          className={`ul-button -medium ${classes["dialog-cancel-btn"]}`}
          onClick={() => {
            props.closeTimeEntryModal();
          }}
        >
          CANCEL
        </button>
        <button
          className={`ul-button -medium ${classes["dialog-save-btn"]}`}
          disabled={isSaveButtonDisabled()}
          onClick={save}
        >
          SAVE
        </button>
      </div>
    </>
  );

  return (
    <Dialog
      className={classes["time-entry-dialog"]}
      header={headerContent}
      footer={renderFooter}
      draggable={false}
      visible={props.visible}
      onHide={onHideModal}
      style={{ width: "90vw", minHeight: "85vh" }}
    >
      <hr className="modal-header-hr" />
      <div className={classes["timeentry-container"]}>
        <div
          style={{
            display: isSaving ? "none" : "",
          }}
        >
          {renderDataTable()}
        </div>
        {isSaving && (
          <div className={classes["loading-div"]}>
            <div>Saving Time Entry......</div>
            <div>
              <svg className="ul-progressCircle -medium">
                <circle
                  className="ul-progressCircle__outer"
                  cx="50%"
                  cy="50%"
                  r="35%"
                />
                <circle
                  className="ul-progressCircle__inner"
                  cx="50%"
                  cy="50%"
                  r="35%"
                />
              </svg>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default TimeEntryModal;
