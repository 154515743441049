import ProjectTaskStatus from "../../../pages/project-management-pmr/shared/enums/project-task-status";
import PmrE2EProjectTaskModel from "../../../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-task-model";

export const calculateOrderlinesTaskStatus = (task: PmrE2EProjectTaskModel) => {
  const taskStatuses = task.e2EProjectLineFlexTaskGroupItems.map(
    (item) => item.flexTaskStatus
  );

  const isAllStatusesEqual = taskStatuses.every(
    (status) => status === taskStatuses[0]
  );

  let status: ProjectTaskStatus = ProjectTaskStatus.InProgress;

  if (isAllStatusesEqual) status = taskStatuses[0];
  else if (
    taskStatuses.every(
      (status) =>
        status === ProjectTaskStatus.Cancelled ||
        status === ProjectTaskStatus.Completed
    )
  )
    status = ProjectTaskStatus.Completed;
  else if (
    taskStatuses.every(
      (status) =>
        status !== ProjectTaskStatus.InProgress &&
        status !== ProjectTaskStatus.OnHold
    ) &&
    taskStatuses.some((status) => status === ProjectTaskStatus.NotScheduled)
  )
    status = ProjectTaskStatus.NotScheduled;
  else if (
    taskStatuses.every((status) => status === ProjectTaskStatus.Cancelled)
  )
    status = ProjectTaskStatus.Cancelled;
  else if (
    taskStatuses.some((status) => status === ProjectTaskStatus.OnHold) &&
    taskStatuses.every((status) => status !== ProjectTaskStatus.InProgress)
  )
    status = ProjectTaskStatus.OnHold;

  return status;
};
