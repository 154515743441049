import React from "react";
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import classes from "./LinkedProducts.module.scss";
const linkedProductsColumn = [
  {
    id: "modelName",
    value: "Model Name",
    width: "100%",
  },
  {
    id: "productType",
    value: "Product Type",
    width: "100%",
  },
];

const LinkedProducts = ({ products }: any) => {
  const renderColumnData = (props: any, options: ColumnBodyOptions) => {
    return (
      <span className={`${classes["table-cell-item__span"]}`}>
        {props[options.field]}
      </span>
    );
  };

  return (
    <div className={`${classes["certificate-linked-products__container"]}`}>
      <div className={`${classes["title__section"]}`}>Linked Products</div>
      <DataTable
        scrollable
        removableSort
        scrollHeight={products?.length > 10 ? "25rem" : undefined}
        scrollDirection="both"
        value={products}
        dataKey="id"
        columnResizeMode="expand"
        autoLayout
        responsiveLayout="scroll"
      >
        {linkedProductsColumn.map((col) => (
          <Column
            key={col.id}
            columnKey={col.id}
            body={renderColumnData}
            field={col.id}
            header={col.value}
            style={{ width: col.width, fontSize: 14 }}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default LinkedProducts;
