import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import launchEcmIcon from "../../../pages/project-management-pmr/shared/icons/launchEcm.png";
import launchFlexIcon from "../../../pages/project-management-pmr/shared/icons/launchFlex.png";
import { OpportunitiesDocumentsDisabledTooltip } from "../../project-management-pmr/shared/constants/ProjectOrderLineKebabActionTooltip";
import classes from "./ActionsColumnBody.module.scss";
import { GetPwqProjectLineDisplayModel } from "../../../models/project-work-queue/GetPwqProjectLineDisplayModel.model";

type Props = {
  rowData: GetPwqProjectLineDisplayModel;
};

const ActionsColumnBody = (props: Props) => {
  const actionsColumnOverlayPanel = useRef<OverlayPanel>(null);
  const opportunityNumber = props.rowData.opportunityNumber;

  const ecdDocumentsPageUrl = `${
    process.env.REACT_APP_ECM_OPPORTUNITIES_NODE
  }?filter=${encodeURIComponent(`{"name":"${opportunityNumber}"}`)}`;

  const flexPageUrl = `${process.env.REACT_APP_FLEX_PROJECT_URL}${props.rowData.flexProjectId}`;

  return (
    <>
      <OverlayPanel
        ref={actionsColumnOverlayPanel}
        className={classes["overlay-panel"]}
      >
        <div className={classes["actions-container"]}>
          <a
            href={flexPageUrl}
            target="_blank"
            className={classes["action-item"]}
          >
            <img src={launchFlexIcon} alt="FLEX" />
            FLEX
          </a>
          <a
            href={
              !opportunityNumber ? "javascript:void(0)" : ecdDocumentsPageUrl
            }
            target={!opportunityNumber ? "_self" : "_blank"}
            title={
              !opportunityNumber ? OpportunitiesDocumentsDisabledTooltip : ""
            }
            className={clsx(
              classes["action-item"],
              !opportunityNumber && classes["disabled"]
            )}
          >
            <img src={launchEcmIcon} alt="ECM" />
            ECM Opportunity Documents
          </a>
        </div>
      </OverlayPanel>
      <button
        className={classes["action-button"]}
        onClick={(e) => {
          actionsColumnOverlayPanel.current?.toggle(e);
        }}
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </button>
    </>
  );
};

export default ActionsColumnBody;
