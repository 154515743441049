import { Column } from "primereact/column";
import classes from "./CertificatePendingDocumentList.module.scss";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import CertificateListService from "../../services/CertificateListService";
import { PaginatedRequestModel } from "../../shared/models/service-models/PaginatedRequest.model";
import { PaginatedResultModel } from "../../shared/models/service-models/PaginatedResult.model";
import { ResultModel } from "../../models/result.model";
import { formatDate } from "../../shared/date-helper/DateHelper";
import { CertificateDocumentType } from "../../enums/CertificateDocumentType";
import { CertificateDocumentStatus } from "../../enums/CertificateDocumentStatus";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faTrash, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../shared/confirmation-modal/ConfirmationModal";
import useToastr from "../../hooks/useToastr";
import { useSignalR } from "../../hooks/useSignalR";
import {
  findSortKey,
  getSortOrder,
} from "../../shared/datatable-helper/datatable-helper";
import useLazyLoadDataTable from "../../hooks/useLazyLoadDataTable";
interface IFailedCertificateDocument {
  documentId: string;
  certificateDetailId: string;
  certificateNumber: string;
  certificateScheme: string;
  documentCategory: CertificateDocumentType;
  documentCategoryText?: string;
  documentName: string;
  dateAttached: string;
  status: CertificateDocumentStatus;
}
const CertificatePendingDocumentList = () => {
  const [list, setList] = useState([] as IFailedCertificateDocument[]);
  const [selectedRows, setSelectedRows] = useState([] as any[]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [showDeleteFileConfirmation, setShowDeleteFileConfirmation] =
    useState(false);

  const { showSuccess, showError } = useToastr();
  const [linkAllButtonLabel, setLinkAllButtonLabel] = useState("");
  const [deleteAllButtonLabel, setDeleteAllButtonLabel] = useState("");
  const [isSingleActionButtonDisabled, setIsSingleActionButtonDisabled] =
    useState(false);
  const [isMultipleButtonDisabled, setIsMultipleButtonDisabled] =
    useState(false);
  const [isLinkAllClicked, setIsLinkAllClicked] = useState(false);
  const [isDeleteAllClicked, setIsDeleteAllClicked] = useState(false);
  const hubUrl = (process.env.REACT_APP_GMAE2E_URL +
    "/hubs/pending_document_list") as string;
  const { connection: signalRConnection } = useSignalR(hubUrl);
  const functionHubUrl = (process.env.REACT_APP_GMAE2E_FUNC_URL +
    "/api") as string;
  const { connection: functionSignalRConnection } = useSignalR(functionHubUrl);

  const dataTableRef = useRef<any>(null);
  const {
    handleSort,
    sortField,
    sortOrder,
    currentPageNo,
    itemsPerPage,
    setHasNextPage,
    reloadList,
  } = useLazyLoadDataTable(50, "DateAttached", dataTableRef, loadList);

  useEffect(() => {
    if (selectedRows.length > 0) {
      setLinkAllButtonLabel("Link All Selected to WB and ECM TIC");
      setDeleteAllButtonLabel("Delete All Selected");
      setIsSingleActionButtonDisabled(true);
    } else {
      setLinkAllButtonLabel("Link All to WB and ECM TIC");
      setDeleteAllButtonLabel("Delete All");
      setIsSingleActionButtonDisabled(false);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (list?.length > 0) setIsMultipleButtonDisabled(false);
    else setIsMultipleButtonDisabled(true);
  }, [list]);

  useEffect(() => {
    if (signalRConnection) {
      signalRConnection.on("refreshPendingDocumentList", (result) => {
        if (result) {
          setList((prevList: any) => {
            const newList = prevList.map(
              (document: IFailedCertificateDocument) => {
                if (
                  result.find(
                    (resultDocId: any) => document.documentId == resultDocId
                  )
                ) {
                  return {
                    ...document,
                    status:
                      document.status ==
                      CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
                        ? CertificateDocumentStatus.TestReportDuplicateLinkToEcmInProgress
                        : CertificateDocumentStatus.RetryUploadToEcmTicInProgress,
                  };
                }
                return document;
              }
            );
            return newList;
          });
        }
      });
    }
  }, [signalRConnection]);

  useEffect(() => {
    if (functionSignalRConnection) {
      functionSignalRConnection.on(
        "pending-document-list-success",
        (result) => {
          const jsonResult = JSON.parse(result);
          if (
            !jsonResult?.certificateDocumentIds ||
            jsonResult?.certificateDocumentIds?.length <= 0
          )
            return;

          setList((prevList: any) => {
            return prevList.filter((doc: any) => {
              return !jsonResult.certificateDocumentIds.includes(
                doc.documentId
              );
            });
          });
        }
      );
      functionSignalRConnection.on("pending-document-list-failed", (result) => {
        const jsonResult = JSON.parse(result);
        if (
          !jsonResult?.certificateDocumentIds ||
          jsonResult?.certificateDocumentIds?.length <= 0
        )
          return;

        setList((prevList: any) => {
          const newList = prevList.map(
            (document: IFailedCertificateDocument) => {
              if (
                jsonResult.certificateDocumentIds.find(
                  (resultDocId: any) => document.documentId == resultDocId
                )
              ) {
                return {
                  ...document,
                  status:
                    document.status ==
                      CertificateDocumentStatus.TestReportDuplicateLinkToEcmInProgress ||
                    document.status ==
                      CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
                      ? CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
                      : CertificateDocumentStatus.UploadToEcmFailed,
                };
              }
              return document;
            }
          );
          return newList;
        });
      });
    }
  }, [functionSignalRConnection]);

  async function loadList() {
    setIsLoadingList(true);

    const model: PaginatedRequestModel = {
      skip: currentPageNo,
      take: itemsPerPage,
      searchString: "",
      sort: findSortKey(columns, sortField, "DateAttached"),
      sortBy: getSortOrder(sortOrder),
    };

    const result = await getList(model).catch((error) => {
      showError(`Error`, `Error while getting the data.`);
    });

    if (result?.isSuccess) {
      let records = result.data.records || [];

      setHasNextPage(result.data.hasNextPage);

      records.forEach((certificateDocument: IFailedCertificateDocument) => {
        certificateDocument.dateAttached = formatDate(
          certificateDocument.dateAttached
        );
        certificateDocument.documentCategoryText =
          getCertificateDocumentTypeText(certificateDocument.documentCategory);
      });

      setList((prevList) => {
        let newList =
          currentPageNo == 1 ? [] : JSON.parse(JSON.stringify(prevList));
        newList.push(...records);

        return newList;
      });
    }

    setIsLoadingList(false);
  }

  const getList = async (
    model: PaginatedRequestModel
  ): Promise<
    ResultModel<PaginatedResultModel<IFailedCertificateDocument[]>>
  > => {
    return CertificateListService.getCertificateDocumentsFailedEcmUpload(model);
  };

  const getCertificateDocumentTypeText = (type?: CertificateDocumentType) => {
    switch (type) {
      case CertificateDocumentType.Certificate:
        return "Certificate";
      case CertificateDocumentType.TestReport:
        return "Test Report";
      case CertificateDocumentType.Others:
        return "Others";
    }
  };

  const getLinkDisplay = (status: CertificateDocumentStatus) => {
    if (
      status == CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
    ) {
      return "Continue Linking Existing File from ECM TIC";
    } else {
      return "Link File to WB and ECM TIC";
    }
  };

  const handleSingleDelete = (data: any) => {
    const currentList = [...selectedRows];
    currentList.push(data);
    setSelectedRows(currentList);
  };

  const handleMultipleDelete = () => {
    if (selectedRows?.length <= 0) {
      setIsDeleteAllClicked(true);
      setSelectedRows(list);
    } else {
      setIsDeleteAllClicked(false);
    }

    setShowDeleteFileConfirmation(true);
  };

  const handleDelete = async () => {
    if (selectedRows?.length <= 0)
      return showError("Failed!", "There are no selected row(s).");

    setIsLoadingList(true);
    resetDialog();

    const result =
      await CertificateListService.deleteBatchCertificateDocumentsFailedEcmUpload(
        {
          documentIds: selectedRows.map((s) => {
            return s.documentId;
          }),
          deleteAll: isDeleteAllClicked,
        }
      ).catch((error) => {});

    if (result?.isSuccess) {
      showSuccess("Success!", "Attachment(s) has been deleted. ");
    } else {
      showError(
        "Error!",
        result ? result.message : `Attachment(s) has not been deleted.`
      );
    }
    reloadList();
    resetValues();
  };

  const handleCancelDelete = () => {
    resetValues();
    resetDialog();
  };

  const handleMultipleLinkToEcm = () => {
    if (selectedRows?.length <= 0) {
      setIsLinkAllClicked(true);
      setSelectedRows(list);
      handleConfirmLinkToEcm(list);
    } else {
      setIsLinkAllClicked(false);
      handleConfirmLinkToEcm(selectedRows);
    }
  };

  const handleSingleLinkToEcm = async (data: any) => {
    const currentList = [...selectedRows];
    currentList.push(data);
    setSelectedRows(currentList);

    setIsLoadingList(true);
    resetDialog();

    const result = await CertificateListService.reuploadAndLinkDocsBatch({
      certificateDocumentIds: [data.documentId],
      isLinkAll: isLinkAllClicked,
    }).catch((error) => {});

    resetValues();

    if (!result || !result?.isSuccess) {
      showError(
        "Error!",
        result
          ? result.message
          : `Error while saving the certificate attachment.`
      );
    }

    setIsLoadingList(false);
  };

  const handleConfirmLinkToEcm = async (list: any[]) => {
    if (list?.length <= 0)
      return showError("Failed!", "There are no selected row(s).");

    setIsLoadingList(true);
    resetDialog();

    const result = await CertificateListService.reuploadAndLinkDocsBatch({
      certificateDocumentIds: list.map((s) => {
        return s.documentId;
      }),
      isLinkAll: isLinkAllClicked,
    }).catch((error) => {});

    resetValues();

    if (!result || !result?.isSuccess) {
      showError(
        "Error!",
        result
          ? result.message
          : `Error while saving the certificate attachment.`
      );
    }

    setIsLoadingList(false);
  };

  const handleCancelLinkToEcm = () => {
    resetValues();
    resetDialog();
  };

  const resetDialog = () => {
    setShowDeleteFileConfirmation(false);
  };

  const resetValues = () => {
    setSelectedRows([]);
    setIsLinkAllClicked(false);
    setIsDeleteAllClicked(false);
  };

  const tableColumnHeader = (col: any) => (
    <div>
      <span title={col.header}>{col.header}</span>
    </div>
  );

  const columns: any = [
    {
      field: "certificateNumber",
      header: "Certificate Number",
      sortKey: "CertificateNumber",
      width: "20%",
    },
    {
      field: "certificateScheme",
      header: "Certificate Scheme",
      sortKey: "CertificateScheme",
      width: "18%",
    },
    {
      field: "documentCategoryText",
      header: "Document Category",
      sortKey: "DocumentCategory",
      width: "12%",
    },
    {
      field: "documentName",
      header: "Document Name",
      sortKey: "DocumentName",
      width: "17%",
    },
    {
      field: "dateAttached",
      header: "Date Attached",
      sortKey: "DateAttached",
      width: "11%",
    },
  ];

  const tableColumnBody = (rowData: any, col: any) => {
    const stockClassName = classNames({
      "text-overflow-ellipsis": true,
    });
    return (
      <div title={rowData[col.field]} className={stockClassName}>
        {rowData[col.field]}
      </div>
    );
  };

  const renderEmptyDatatable = () => {
    return (
      <div className={classes["datatable-emptymessage-container"]}>
        <div>
          <div className={classes["datatable-emptymessage-label"]}>
            No pending documents available
          </div>
        </div>
      </div>
    );
  };

  const renderDynamicColumns = columns.map((col: any) => {
    return (
      <Column
        sortable
        key={col.field}
        field={col.field}
        header={tableColumnHeader(col)}
        style={{ flexGrow: 1, flexBasis: col.width, width: col.width }}
        body={tableColumnBody.bind(col)}
      />
    );
  });

  const gridLinkActionTemplate = (rowData: any, props: any) => {
    return (
      <div className={classes["gridaction-container"]}>
        {(rowData.status == CertificateDocumentStatus.UploadToEcmFailed ||
          rowData.status ==
            CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed) && (
          <Button
            className={`${classes["text-btn"]} ${classes["linkToWbAndEcm"]}`}
            onClick={() => {
              handleSingleLinkToEcm(rowData);
              //  handleConfirmLinkToEcm(rowData);
            }}
            title={getLinkDisplay(rowData.status)}
            disabled={isSingleActionButtonDisabled}
          >
            <span>
              {" "}
              <FontAwesomeIcon className={classes["icons"]} icon={faLink} />
              {getLinkDisplay(rowData.status)}
            </span>
          </Button>
        )}
        {(rowData.status ==
          CertificateDocumentStatus.RetryUploadToEcmTicInProgress ||
          rowData.status ==
            CertificateDocumentStatus.TestReportDuplicateLinkToEcmInProgress) && (
          <span>
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          </span>
        )}
      </div>
    );
  };

  const gridDeleteActionTemplate = (rowData: any, props: any) => {
    return (
      <div className={classes["gridaction-container"]}>
        {rowData.status == CertificateDocumentStatus.UploadToEcmFailed && (
          <Button
            className="p-button-rounded p-button-secondary p-button-text p-button-sm"
            title="Delete Attachment"
            onClick={() => {
              setShowDeleteFileConfirmation(true);
              handleSingleDelete(rowData);
            }}
            disabled={isSingleActionButtonDisabled}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </div>
    );
  };

  const hideColumnHeader = <span></span>;

  const deleteConfirmationMessage = () => {
    return (
      <span>
        The document(s) will be deleted from GMAWB File Storage.
        <br />
        <br />
        Would you like to continue?
      </span>
    );
  };

  const displayLinkEcmModalText = () => {
    return (
      <span>
        File(s) will be saved to ECM TIC and cannot be deleted.
        <br />
        <br />
        Would you like to continue?
      </span>
    );
  };

  const getRowClassName = (data: any) => {
    if (
      data.status == CertificateDocumentStatus.RetryUploadToEcmTicInProgress ||
      data.status ==
        CertificateDocumentStatus.TestReportDuplicateLinkToEcmInProgress
    ) {
      return classes["already-selected"];
    }

    return "";
  };

  return (
    <>
      <div className={`${classes["page-container"]}`}>
        <div className={classes["header-container"]}>
          <div className={classes["button-container"]}>
            <button
              className={`ul-button -small ${classes["link-btn"]}`}
              onClick={handleMultipleLinkToEcm}
              disabled={isMultipleButtonDisabled && !isLoadingList}
            >
              {linkAllButtonLabel}
            </button>
            <button
              className={`ul-button -small ${classes["delete-btn"]}`}
              onClick={handleMultipleDelete}
              disabled={isMultipleButtonDisabled && !isLoadingList}
            >
              {deleteAllButtonLabel}
            </button>
          </div>
          <div className={classes["note-container"]}>
            <span>
              Note: All documents listed are pending documents due to an error
              while saving on ECM TIC
            </span>
          </div>
        </div>
        <div className={`${classes["datatable-container"]}`}>
          <DataTable
            dataKey="documentId"
            emptyMessage={renderEmptyDatatable()}
            size="small"
            value={list}
            responsiveLayout="scroll"
            lazy
            scrollable
            resizableColumns
            scrollDirection="both"
            selection={selectedRows}
            removableSort
            onSort={(e) => handleSort(e)}
            sortField={sortField}
            sortOrder={sortOrder}
            onSelectionChange={(e) => setSelectedRows(e.value)}
            loading={isLoadingList}
            ref={dataTableRef}
            rowClassName={getRowClassName}
          >
            <Column
              header={hideColumnHeader}
              selectionMode="multiple"
              headerClassName={`${classes["hideHeaderCheckbox"]}`}
              style={{
                flexGrow: 1,
              }}
              columnKey="select"
              alignFrozen="left"
              reorderable={false}
              frozen
            />
            {renderDynamicColumns}
            <Column
              align="right"
              header=""
              style={{
                flexGrow: 1,
                flexBasis: "18%",
                width: "18%",
              }}
              body={gridLinkActionTemplate}
            ></Column>
            <Column
              align="center"
              header=""
              style={{
                flexGrow: 1,
                flexBasis: "2%",
                width: "2%",
              }}
              body={gridDeleteActionTemplate}
            ></Column>
          </DataTable>
        </div>
      </div>
      <ConfirmationModal
        visible={showDeleteFileConfirmation}
        onCancel={() => handleCancelDelete()}
        onConfirm={() => handleDelete()}
        headerDisplay={"Delete File Confirmation"}
        confirmationMessageHTML={deleteConfirmationMessage()}
        buttonNoDisplay={`No`}
        buttonYesDisplay={`Yes`}
      />
      {/* <ConfirmationModal
        visible={displayLinkEcmModalPrompt}
        onCancel={() => handleCancelLinkToEcm()}
        onConfirm={() => handleConfirmLinkToEcm()}
        headerDisplay="Link File to WB and ECM TIC Confirmation"
        confirmationMessageHTML={displayLinkEcmModalText()}
        buttonNoDisplay={`No`}
        buttonYesDisplay={`Yes`}
      /> */}
    </>
  );
};

export default CertificatePendingDocumentList;
