export interface GridColumnModel {
  id: string;
  value: string;
  selected: boolean;
  orderBy: string;
  isShown: boolean;
  isFrozen: boolean;
  width: string;
  widthNum: number;
  orderNo: number;
  required: boolean;
}

export const projectWorkQueueGridCDPColumns: GridColumnModel[] = [
  {
    id: "flexProjectNumber",
    value: "Project Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "7rem",
    widthNum: 180,
    orderNo: 1,
    required: false,
  },
  {
    id: "flexProjectName",
    value: "FLEX Project Name",
    width: "9rem",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    widthNum: 180,
    orderNo: 2,
    required: false,
  },
  {
    id: "orderNumber",
    value: "Order Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "6.5rem",
    widthNum: 180,
    orderNo: 3,
    required: false,
  },
  {
    id: "orderLineDescription",
    value: "Order Line Number",
    width: "10rem",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    widthNum: 180,
    orderNo: 4,
    required: false,
  },
  {
    id: "flexProjectTemplateName",
    value: "FLEX Template",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "7rem",
    widthNum: 180,
    orderNo: 5,
    required: false,
  },
  {
    id: "isShowAllScheme",
    value: "All Schemes",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "5.3rem",
    widthNum: 180,
    orderNo: 6,
    required: false,
  },
  {
    id: "certificateScheme",
    value: "Certificate Scheme",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "14rem",
    widthNum: 180,
    orderNo: 7,
    required: true,
  },
  {
    id: "applicationType",
    value: "Application Type",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "14rem",
    widthNum: 180,
    orderNo: 8,
    required: true,
  },
  {
    id: "additionalInfo",
    value: "Additional Info",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "14rem",
    widthNum: 180,
    orderNo: 9,
    required: true,
  },
  {
    id: "deliveryPath",
    value: "Delivery Path",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "14rem",
    widthNum: 180,
    orderNo: 10,
    required: true,
  },
  {
    id: "leadTime",
    value: "Lead Time (weeks)",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "8rem",
    widthNum: 180,
    orderNo: 11,
    required: false,
  },
  {
    id: "technology",
    value: "Technology",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "15rem",
    widthNum: 180,
    orderNo: 12,
    required: true,
  },
  {
    id: "e2eTemplate",
    value: "GMAWB Template",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "16rem",
    widthNum: 180,
    orderNo: 13,
    required: true,
  },
  {
    id: "modelName",
    value: "Model Name",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "20rem",
    widthNum: 180,
    orderNo: 14,
    required: false,
  },
  {
    id: "baseModel",
    value: "Product Type",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "15rem",
    widthNum: 180,
    orderNo: 15,
    required: false,
  },
  {
    id: "modelPsn",
    value: "Model Party Site Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "15rem",
    widthNum: 180,
    orderNo: 16,
    required: false,
  },
];
