import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateSelectedCertificateId } from "../../../../features/certificateList/certificateListSlice";
import CustomPaginator from "../../../../shared/custom-paginator/CustomPaginator";
import CerificateProductListHeader from "../certificate-product-list-header/CerificateProductListHeader";
import classes from "./CertificateProductListGrid.module.scss";
import "./CertificateProductListGrid.scss";

import CertificateDetails from "./../../../certificate-list-view/cetificate-list/certificate-details/CertificateDetails";

const CertificateProductListGrid = (props) => {
  const dispatch = useDispatch();
  const clv = useRef(null);
  const [showCertificateDetails, setShowCertificateDetails] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [dropdownOptions, setDropDownOptions] = useState([
    { label: 100, value: 100 },
    { label: 500, value: 500 },
    { label: 1000, value: 1000 },
  ]);

  const {
    loading,
    currentPageRef,
    columns,
    subColumns,
    frozenColCount,
    certListData,
    totalCertificateCount,
    totalPageCount,
    pageCount,
    currentPage,
    lazyParams,
    favoriteName,
    favoriteList,
    onClickStatusFilter,
    onChangePage,
    setCertListColumns,
    setCertFrozenCount,
    setShowCertificateProductDetails,
    setIsClickedOutSide,
    onSaveFavorite,
    onEditFavorite,
    onDeleteFavorite,
    onClickFavorites,
    onClearFilters,
  } = props;

  const clickableSubBodyTemplate = (rowData, col) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => openCertDetailsWindow(rowData)}
      >
        {rowData.certificateName}
      </div>
    );
  };

  const dynamicColumns = columns.map((col, i) => {
    return (
      <Column
        key={col.id}
        columnKey={col.id}
        field={col.id}
        header={() => <div title={col.value}>{col.value}</div>}
        hidden={!col.isShown}
        alignFrozen="left"
        frozen={col.isFrozen}
        style={{ flexGrow: 1, flexBasis: col.width, width: col.width }}
      />
    );
  });

  const dynamicSubColumns = subColumns.map((col, i) => {
    if (col.value === "Certificate Number") {
      return (
        <Column
          key={col.id}
          columnKey={col.id}
          field={col.id}
          header={col.value}
          style={{ width: col.width }}
          body={clickableSubBodyTemplate}
        />
      );
    } else {
      return (
        <Column
          key={col.id}
          columnKey={col.id}
          field={col.id}
          header={col.value}
          style={{ width: col.width }}
        />
      );
    }
  });

  const onColumnResize = (e) => {
    const { column, element } = e;

    const fields = columns.map((f) => {
      if (f.id === column.props.field) {
        return {
          ...f,
          width: element.offsetWidth,
        };
      } else {
        return f;
      }
    });

    setCertListColumns(fields);
  };

  const onColReorder = (e) => {
    if (e.dropIndex === 0 || e.dropIndex === columns.length + 1) {
      clv.current.reset();
    } else {
      let certColumns = columns.map((col, i) => {
        const orderNo = e.columns.findIndex((fi) => fi.props.field === col.id);

        return {
          ...col,
          orderNo: orderNo,
        };
      });

      certColumns.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });

      updateCertListColumns(frozenColCount, certColumns);
    }
  };

  const updateCertListColumns = (frozenCount, certFields) => {
    const fields = certFields.map((f) => {
      if (f.orderNo <= frozenCount) {
        return {
          ...f,
          isShown: true,
          isFrozen: true,
        };
      } else {
        return {
          ...f,
          isFrozen: false,
        };
      }
    });

    setCertListColumns(fields);
    setCertFrozenCount(frozenCount);
  };

  const onRowDoubleClick = (event) => {
    const { data } = event;
    dispatch(updateSelectedCertificateId(data.certificateId));
    setShowCertificateProductDetails(true);
    setIsClickedOutSide(false);
  };

  const onRowExpand = (event) => {};

  const onRowCollapse = (event) => {};

  const openCertDetailsWindow = (data) => {
    dispatch(updateSelectedCertificateId(data.certificateId));
    setShowCertificateDetails(true);
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className={`${classes["cert-prod-list-sub-row"]}`}>
        <DataTable
          scrollable
          scrollHeight="10rem"
          scrollDirection="both"
          value={data.certificates}
          responsiveLayout="scroll"
          dataKey="uniqueCertificateId"
        >
          <Column
            field="uniqueCertificateId"
            header=""
            expander
            style={{ width: "40px" }}
            alignFrozen="left"
            frozen
            reorderable={false}
            body={(data) => (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => openCertDetailsWindow(data)}
              >
                <FontAwesomeIcon icon={faEye} />
              </div>
            )}
          />
          {dynamicSubColumns}
        </DataTable>
      </div>
    );
  };

  const expandAll = () => {
    let _expandedRows = {};
    certListData.forEach((p) => (_expandedRows[`${p.assetId}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  return (
    <div className="ul-grid__column -x100 -x100-mobile">
      <CertificateDetails
        showCertificateDetails={showCertificateDetails}
        setShowCertificateDetails={setShowCertificateDetails}
      />
      <div className="ul-grid__row -wrap">
        <CerificateProductListHeader
          columns={columns}
          expandAll={expandAll}
          collapseAll={collapseAll}
          expandedRows={expandedRows}
          frozenColCount={frozenColCount}
          favoriteName={favoriteName}
          favoriteList={favoriteList}
          onClickStatusFilter={onClickStatusFilter}
          setCertListColumns={setCertListColumns}
          setCertFrozenCount={setCertFrozenCount}
          onSaveFavorite={onSaveFavorite}
          onEditFavorite={onEditFavorite}
          onDeleteFavorite={onDeleteFavorite}
          onClickFavorites={onClickFavorites}
          onClearFilters={onClearFilters}
        />
      </div>

      <div className="ul-grid__row -wrap">
        <div className="ul-grid__column -x100 -x100-mobile  cert-prod-list-grid-container">
          <DataTable
            ref={clv}
            scrollable
            scrollHeight="80vh"
            scrollDirection="both"
            value={certListData}
            size="small"
            lazy
            onPage
            resizableColumns
            reorderableColumns
            onColumnResizeEnd={onColumnResize}
            onColReorder={onColReorder}
            columnResizeMode="expand"
            responsiveLayout="scroll"
            dataKey="assetId"
            selection={selectedRows}
            onSelectionChange={(e) => setSelectedRows(e.value)}
            onRowDoubleClick={onRowDoubleClick}
            loading={loading}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            onRowExpand={onRowExpand}
            onRowCollapse={onRowCollapse}
            rowExpansionTemplate={rowExpansionTemplate}
          >
            <Column
              field="assetId"
              header=""
              expander
              style={{ width: "40px" }}
              alignFrozen="left"
              frozen
              reorderable={false}
            />
            {dynamicColumns}
          </DataTable>
          <CustomPaginator
            currentPageRef={currentPageRef}
            dropdownOptions={dropdownOptions}
            lazyParams={lazyParams}
            currentPage={currentPage}
            pageCount={pageCount}
            totalPageCount={totalPageCount}
            totalRecordCount={totalCertificateCount}
            onChangePage={onChangePage}
          ></CustomPaginator>
        </div>
      </div>
    </div>
  );
};

export default CertificateProductListGrid;
