import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useState, useRef } from "react";
import "./SelectRefiner.scss";

const SelectRefiner = (props) => {
  const [isShow, setIsShow] = useState(false);
  const [singleSelectValue, setSingleSelectValue] = useState(null);
  const [multiSelectValue, setMultiSelectValue] = useState(null);
  const [rows, setRows] = useState(20);
  const clearValue = useRef(false);

  const {
    refinerLabel,
    options,
    onFilter,
    isFilterOn,
    isMultiSelect,
    addSelectedToYourFilters,
    placeHolder,
    isRefinerLoading = false,
    showMore = false,
    statusListRefiners = null,
    clearSelectedValues = false,
  } = props;

  useEffect(() => {
    setRows(20);
  }, [props.options]);

  useEffect(() => {
    setMultiSelectValue(
      statusListRefiners?.map((i) => {
        return {
          name: i.DisplayText,
          code: i.Value,
        };
      })
    );
  }, [statusListRefiners]);

  useEffect(() => {
    clearValue.current = clearSelectedValues
    if (clearValue.current) setClearSelectedValues();

    clearValue.current = false;
  }, [clearSelectedValues]);


  const refinerPlaceHolderTemplate = (option) => {
    return (
      <div className="refiner-dropdown-placeholder">
        {placeHolder ? placeHolder : "-- Click to type below --"}
      </div>
    );
  };

  const onChangeSelected = (e, isMultiSelect) => {
    isMultiSelect
      ? setMultiSelectValue(e.value)
      : setSingleSelectValue(e.value);
  };

  const onHide = (isMultiSelect) => {
    isMultiSelect
      ? addSelectedToYourFilters(multiSelectValue)
      : addSelectedToYourFilters(singleSelectValue);
  };

  const onShow = (isMultiSelect) => {
    if (refinerLabel !== "Certificate Status") {
      setClearSelectedValues();
    }
  };

  const onShowMore = () => {
    setRows((curr) => curr + 20);
  };

  const showMoreTemplate = () => {
    return (
      <div className="refiners__show-more">
        <button onClick={onShowMore}>Show more</button>
      </div>
    );
  };

  const setClearSelectedValues = () => {
    isMultiSelect ? setMultiSelectValue(null) : setSingleSelectValue(null);
  }


  return (
    <div className="ul-card -app">
      <div
        className="ul-card__item -bordered ul-card__head cursor-pointer s-refiner-height s-container-text"
        onClick={() => setIsShow((prevState) => !prevState)}
      >
        <span className="font-size-14 font-weight-semi-bold">
          {refinerLabel}
        </span>
        <span className="float-right">
          <FontAwesomeIcon icon={isShow ? faCaretDown : faCaretRight} />
        </span>
      </div>

      {isShow && (
        <div className="ul-card__item ul-card__body s-select-container">
          {isMultiSelect && (
            <MultiSelect
              panelFooterTemplate={
                showMore && rows < options.length && showMoreTemplate
              }
              onHide={() => onHide(true)}
              onShow={() => onShow(true)}
              resetFilterOnHide
              value={multiSelectValue}
              options={showMore ? options.slice(0, rows) : options}
              onChange={(s) => onChangeSelected(s, true)}
              optionLabel="name"
              placeholder={
                placeHolder ? placeHolder : "-- Click to type below --"
              }
              filter={isFilterOn}
              onFilter={onFilter}
              selectedItemTemplate={refinerPlaceHolderTemplate}
              className="w-100"
              emptyFilterMessage={isRefinerLoading ? "Loading..." : ""}
            />
          )}

          {!isMultiSelect && (
            <Dropdown
              onHide={() => onHide(false)}
              onShow={() => onShow(false)}
              resetFilterOnHide
              value={singleSelectValue}
              options={options}
              onChange={(s) => onChangeSelected(s, false)}
              optionLabel="name"
              placeholder={
                placeHolder ? placeHolder : "-- Click to type below --"
              }
              filter={isFilterOn}
              onFilter={onFilter}
              selectedItemTemplate={refinerPlaceHolderTemplate}
              className="w-100"
              emptyFilterMessage={isRefinerLoading ? "Loading..." : ""}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SelectRefiner;
