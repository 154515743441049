import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { useEffect, useRef, useState } from "react";
import DocumentKnowledgeService from "../../../services/DocumentKnowledgeService";
import { DocumentKnowledgeRequestModel } from "../../../shared/service-models/DocumentKnowledgeRequestModel.model";
import classes from "./DocKnowledgeButtons.module.scss";
import { saveAs } from "file-saver";
import { classNames } from "primereact/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCommonDocs,
  updateCountrySpecifics,
  updateDeliveryPaths,
  updateIsLoadingCommonDocsData,
  updateIsLoadingCountrySpecificData,
  updateIsLoadingDelPathData,
  updateIsLoadingLocalRepListData,
  updateIsLoadingTestSampleData,
  updateLocalReps,
  updateTestSamples,
} from "./../../../features/dkList/dkListSlice";
import { values } from "./../../../features/dkList/dkListSlice";
import { DataTableSortProps } from "./../DocumentKnowledge";
interface SortingDetails {
  devPath: {
    column: string;
    sort: "asc";
  };
  commonDoc: {
    column: string;
    sort: "asc";
  };
  countrySpec: {
    column: string;
    sort: "asc";
  };
  testSample: {
    column: string;
    sort: "asc";
  };
  localRep: {
    column: string;
    sort: "asc";
  };
}

interface DocKnowledgeButtonsProps {
  isJapaneseSelected: boolean;
  requestModel: DocumentKnowledgeRequestModel;
  sortDetails: DataTableSortProps;
}

interface ExportModel {
  id: string;
  value: string;
  selected: boolean;
}
const DocKnowledgeButtons = (props: DocKnowledgeButtonsProps) => {
  const { isJapaneseSelected, sortDetails, requestModel } = props;
  const op = useRef<OverlayPanel>(null);
  const [selectedExportList, setSelectedExportList] = useState<ExportModel[]>();
  const [exportParameter, setExportParameter] = useState({});
  const listValue = useSelector(values);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const exportList: ExportModel[] = [
      {
        id: "isDeliveryPathSelected",
        value: "Delivery Path Information",
        selected: true,
      },
      {
        id: "isCommonDocumentSelected",
        value: "Common Documents",
        selected: true,
      },
      {
        id: "isCountrySpecificSelected",
        value: "Country Specific Documents",
        selected: true,
      },
      {
        id: "isTestSampleListSelected",
        value: "Test Sample List",
        selected: true,
      },
      {
        id: "isLocalRepListSelected",
        value: "Local Rep List",
        selected: true,
      },
      {
        id: "isGenInfoSelected",
        value: "General Information",
        selected: false,
      },
      {
        id: "includeInternalRemarks",
        value: "Include Internal Remarks",
        selected: false,
      },
    ];

    setSelectedExportList(exportList);
  }, []);

  const getList = async (): Promise<void> => {
    const {
      schemeSelected,
      devPathSelected,
      applicationTypeSelected,
      technologySelected,
    } = requestModel;

    const data = {
      schemeSelected,
      devPathSelected,
      applicationTypeSelected,
      technologySelected,
    };

    setExportParameter(data);
    setIsLoading(true);

    const serviceFunctions = [
      DocumentKnowledgeService.getCommonDocsDataList,
      DocumentKnowledgeService.getCountrySpecificDataList,
      DocumentKnowledgeService.getTestSampleDataList,
      DocumentKnowledgeService.getLocalRepDataList,
      DocumentKnowledgeService.getDeliveryPathInfoDataList,
    ];

    dispatch(updateIsLoadingDelPathData(true));
    dispatch(updateIsLoadingCommonDocsData(true));
    dispatch(updateIsLoadingCountrySpecificData(true));
    dispatch(updateIsLoadingLocalRepListData(true));
    dispatch(updateIsLoadingTestSampleData(true));

    const promises = serviceFunctions.map((serviceFunction) =>
      serviceFunction(requestModel)
    );

    const results: PromiseSettledResult<any>[] = await Promise.allSettled(
      promises
    );

    dispatch(updateIsLoadingDelPathData(false));
    dispatch(updateIsLoadingCommonDocsData(false));
    dispatch(updateIsLoadingCountrySpecificData(false));
    dispatch(updateIsLoadingLocalRepListData(false));
    dispatch(updateIsLoadingTestSampleData(false));

    const resolvedResults = results
      .map((result: any) => result.value || []);

    const [
      commonDocsData,
      countrySpecificData,
      testSampleData,
      localRepData,
      deliveryPathInfoData,
    ] = resolvedResults;

    const actions = [
      updateCommonDocs(commonDocsData),
      updateCountrySpecifics(countrySpecificData),
      updateTestSamples(testSampleData),
      updateLocalReps(localRepData),
      updateDeliveryPaths(deliveryPathInfoData),
    ];

    actions.forEach((action) => dispatch(action));
    setIsLoading(false);
  };

  const handleExportChange = ({ value, checked }: CheckboxChangeParams) => {
    const _selectedExportList = [
      ...(selectedExportList as ExportModel[]).map((obj) =>
        obj.id === value.id ? { ...obj, selected: checked } : obj
      ),
    ];

    setSelectedExportList(_selectedExportList);
  };

  const generateExport = () => {
    const data: any = exportParameter;
    data["devPathSort"] = sortDetails.devPath;
    data["commonDocSort"] = sortDetails.commonDoc;
    data["countrySpecSort"] = sortDetails.countrySpec;
    data["testSampleSort"] = sortDetails.testSample;
    data["localRepSort"] = sortDetails.localRep;
    data["isJapanese"] = isJapaneseSelected;

    selectedExportList?.forEach((e) => {
      data[e.id] = e.selected;
    });

    const newData = changeSortProperty(data);

    DocumentKnowledgeService.generateExport(newData).then(function (response) {
      if (response) {
        const current = new Date();
        const fileName = `DocumentKnowledge_Export_${(
          "0" +
          (current.getMonth() + 1)
        ).slice(-2)}${("0" + current.getDate()).slice(
          -2
        )}${current.getFullYear()}${current.getHours()}${current.getMinutes()}${current.getSeconds()}.zip`;
        saveAs(response, fileName);
      }
    });
  };

  function changeSortProperty(data: any) {
    const newData = { ...data };

    const propertiesToChange = [
      "devPathSort",
      "commonDocSort",
      "countrySpecSort",
      "testSampleSort",
      "localRepSort",
    ];

    for (let property of propertiesToChange) {
      switch (newData[property].sort) {
        case 1:
          newData[property].sort = "asc";
          break;
        case -1:
          newData[property].sort = "desc";
          break;
        case 0:
        default:
          newData[property].sort = "";
          break;
      }
    }

    return newData;
  }

  const renderExportBody = () => {
    return (
      <div className="card flex justify-content-center">
        <div className="flex flex-column gap-3">
          {selectedExportList?.map((e, index) => {
            return index === selectedExportList.length - 1 ? (
              <React.Fragment key={index}>
                <hr className="ul-dropdown__separator" />
                <div
                  key={e.id}
                  className="flex align-items-center ul-dropdown__item"
                >
                  <Checkbox
                    inputId={e.id}
                    value={e}
                    onChange={handleExportChange}
                    checked={selectedExportList.some(
                      (item) => item.id === e.id && item.selected
                    )}
                  />
                  <label htmlFor={e.id} className={classes["label"]}>
                    {e.value}
                  </label>
                </div>
              </React.Fragment>
            ) : (
              <div
                key={e.id}
                className="flex align-items-center ul-dropdown__item"
              >
                <Checkbox
                  inputId={e.id}
                  value={e}
                  onChange={handleExportChange}
                  checked={selectedExportList.some(
                    (item) => item.id === e.id && item.selected
                  )}
                />
                <label htmlFor={e.id} className={classes["label"]}>
                  {e.value}
                </label>
              </div>
            );
          })}
        </div>
        <div className={classes["generate-container"]}>
          <Button
            className={`${classes["generate"]} ul-button -app-primary`}
            label="EXPORT"
            onClick={generateExport}
          />
        </div>
      </div>
    );
  };

  const isDisabled = () => {
    return (
      requestModel.schemeSelected.length === 0 ||
      (JSON.parse(requestModel.schemeSelected) as Array<any>).length === 0
    );
  };

  const isExportDisabled = () => {
    return (
      listValue.commonDocs.length === 0 &&
      listValue.countrySpecifics.length === 0 &&
      listValue.devPaths.length === 0 &&
      listValue.localReps.length === 0 &&
      listValue.testSamples.length === 0
    );
  };

  return (
    <div className={classes["button-container"]}>
      <div className="ul-form__control">
        <Button
          className={`${classes["apply"]} ul-button -app-primary`}
          label="APPLY"
          onClick={getList}
          disabled={isDisabled() || isLoading}
        />
      </div>
      <div className="ul-form__control">
        <Button
          className={`${classes["export"]} ul-button -app-secondary`}
          label="EXPORT"
          onClick={(e) => op.current!.toggle(e)}
          disabled={isExportDisabled() || isLoading}
        />
        <OverlayPanel ref={op}>{renderExportBody()}</OverlayPanel>
      </div>
    </div>
  );
};

export default DocKnowledgeButtons;
