import * as yup from "yup";

function validateProductTypes() {
  return this.test({
    name: "validateProductTypes",
    exclusive: true,
    message:
      "Selected Product Type does not match the Base Model No. and Model Variants",
    test: function (value, schema) {
      const gpiProductType =
        schema?.parent || schema?.options?.context?.productType;

      const modelVariantProductTypes =
        gpiProductType?.modelVariants?.map((x) => x.productType) || [];

      let modelProductTypes = [...modelVariantProductTypes];

      if (gpiProductType?.baseModel) {
        modelProductTypes.push(gpiProductType?.baseModel.productType);
      }

      return (
        modelProductTypes.length === 0 ||
        (modelProductTypes.filter((x) => x).length === 0 && !value?.length) ||
        modelProductTypes
          .filter((x) => x)
          .every((x) => value?.some((y) => y.hierarchyValue === x))
      );
    },
  });
}

yup.addMethod(yup.array, "validateProductTypes", validateProductTypes);

const productTypeSchema = yup.object({
  productTypes: yup.array().nullable().required().validateProductTypes(),
  baseModel: yup
    .object()
    .nullable()
    .required()
    .when("modelVariants", {
      is: (modelVariants) => modelVariants?.length > 0,
      then: yup.object().nullable().required("Please add a Base Model."),
    }),
});

const voltageDetailsSchema = yup.object({});

const radioDetailsSchema = yup.object({
  otherTechnology: yup.string(),
  otherTechnologyType: yup.string(),
  otherEquipmentRadioType: yup
    .string()
    .when("equipmentRadioTypes", {
      is: (equipmentRadioTypes) => { 
        return equipmentRadioTypes?.some(i => i.equipmentRadioType === "Other");
      },
      then: yup.string().required()
  }),
});

export default yup.object({
  productType: productTypeSchema,
  voltageDetails: voltageDetailsSchema,
  radioDetails: radioDetailsSchema,
});

export { productTypeSchema, radioDetailsSchema, voltageDetailsSchema };
