import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    toastList: [],
  },
};

export const toastrSlice = createSlice({
  name: "toastr",
  initialState,
  reducers: {
    addToast: (state, action) => {
      state.value.toastList.push(action.payload);
    },
    dismissToast: (state, action) => {
      const toast = state.value.toastList.find(
        (item) => item.id === action.payload.id
      );
      if (toast) {
        toast.dismissed = true;
      }
    },
    removeToast: (state, action) => {
      const index = state.value.toastList.indexOf(action.payload);
      state.value.toastList.splice(index, 1);
    },
    clearToast: (state) => {
      state.value.toastList = [];
    },
  },
});

export const {
  addToast,
  clearToast,
  dismissToast,
  initializeToast,
  removeToast,
} = toastrSlice.actions;

export const selectValue = (state) => state.toastr.value;
export default toastrSlice.reducer;
