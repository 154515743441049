import classes from "./UnsupportedFlexTemplateModal.module.scss";
import { Button } from "primereact/button";
import ConfirmationModalV2 from "../../../shared/confirmation-modal-v2/ConfirmationModalV2";
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import { FlexUnsupportedTemplateListModel } from "../../../shared/models/FlexProjectTemplateTaskDetail.model";

interface UnsupportedFlexTemplateModalProps {
  visible: boolean;
  onConfirm: (resp: UnsupportedFlexTemplateDataProps | null) => void;
  onCancel: () => void;
  unsupportedOrderLines: FlexUnsupportedTemplateListModel[];
  dataProp: UnsupportedFlexTemplateDataProps | null;
}

export interface UnsupportedFlexTemplateDataProps {
  type: "isCreateE2EProject" | "isAddToGMAWBProject";
  isProceedBtnDisabled: boolean;
  companyName?: string;
  hasExistingProjects?: boolean;
}

const UnsupportedFlexTemplateModal = (
  props: UnsupportedFlexTemplateModalProps
) => {
  const modalHeader = "Order Lines using Unsupported FLEX Template";

  const colTemplate = (data: any, opt: ColumnBodyOptions) => (
    <div
      className={`${classes["col-text-div"]}
        } ul-grid__column`}
    >
      {data[opt.field]}
    </div>
  );

  const table = (
    <DataTable
      value={props.unsupportedOrderLines}
      className={classes["adjustments-table"]}
      size="small"
      showGridlines={true}
    >
      <Column
        header="Project Number"
        field="projectNumber"
        body={colTemplate}
        className={`${classes["table-col"]} ${classes["col-projectNumber"]}`}
      />
      <Column
        header="Order Line Number"
        field="orderLineNumber"
        body={colTemplate}
        className={`${classes["table-col"]} ${classes["col-orderLineNumber"]}`}
      />
      <Column
        header="FLEX Template"
        field="flexTemplate"
        body={colTemplate}
        className={`${classes["table-col"]} ${classes["col-flexTemplate"]}`}
      />
    </DataTable>
  );

  const renderTableHeader = () => {
    return (
      <>
        <p className={`${classes["modal-body-text"]}`}>
          GMAWB tool has identified the following Order Line(s) are using a FLEX
          Template that is currently unsupported by the system and will not be
          included in the creation of your GMAWB Project:
        </p>
      </>
    );
  };
  const renderTableFooter = () => {
    return (
      <>
        <p className={`${classes["modal-body-text"]}`}>
          Should you wish to use this for creating/adding to a GMAWB Project,
          please update the FLEX Template in the FLEX tool.
        </p>
      </>
    );
  };
  const bodyTemplate = (
    <>
      {renderTableHeader()}
      {table}
      {renderTableFooter()}
    </>
  );

  const footerTemplate = (
    <>
      <Button
        label={"CANCEL"}
        onClick={props.onCancel}
        className={classes["btn-cancel"]}
      />

      <Button
        label={"PROCEED"}
        onClick={() => props.onConfirm(props.dataProp)}
        disabled={props.dataProp?.isProceedBtnDisabled}
        className={classes["btn-proceed"]}
      />
    </>
  );

  return (
    <ConfirmationModalV2
      bodyClassName={`${classes["modal-body"]}`}
      style={{ width: "50vw" }}
      visible={props.visible}
      onClose={props.onCancel}
      headerDisplay={modalHeader}
      customFooter={footerTemplate}
      confirmationMessageHTML={bodyTemplate}
    ></ConfirmationModalV2>
  );
};

export default UnsupportedFlexTemplateModal;
