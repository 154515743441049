import { uniqBy } from "lodash";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  customerInformationInitialState,
  selectExistingCertDetails,
  selectGPICustomerInputValidationResult,
  selectGeneralProductInformation,
  selectIsReadOnly,
  selectProductType,
  selectULFileNumber,
  updateBaseModel,
  updateExistingCertDetails,
  updateFactorySiteInformation,
  updateGPICustomerInputValidationResult,
  updateIsLoading,
  updateIsModelsAutoPopulatedByCertificateSelection,
  updateIsSaveDisabled,
  updateIsShowValidationBanner,
  updateKeptCustomerDataDiscrepancies,
  updateManufacturerFromCertificate,
  updateManufacturerInformation,
  updateModelVariants,
  updateProductTypeProductTypes,
  updateULFileNumber,
  updateValidationBannerStatusType,
  updateULFileNumberHasLoadedInitially,
  selectWorkflowPackageId,
} from "../../../../features/generalProductInformation/generalProductInformationSlice";
import CertificateListService from "../../../../services/CertificateListService";
import GeneralProductInformationService from "../../../../services/GeneralProductInformationService";
import ProductTypeService from "../../../../services/ProductTypeService";
import {
  bothFileNumberExistErrorMessage,
  certificateNumberHelperText,
  certificateSchemeHelperText,
  existingFileNumberHelpText,
  invalidExistingCertificationMessage,
  notExistingInWorkBenchErrorMessage,
  otherGmaCertErrorMessage,
  sectionNumberHelperText,
  uLCBCertErrorMessage,
  ulcbCertificateNumberHelperText,
  volumeNumberHelpText,
} from "../../../../utils/constants/gpi-form-constants";
import FormControl from "../../form/form-control/FormControl";
import { getGPIFieldErrorMessage } from "../../helpers";
import { ulFileNumberSchema } from "../company-details-schema";
import FACTORY_SITE_INFO_SOURCE from "../factory-site-information/FACTORY_SITE_INFO_SOURCE";
import classes from "./ULFileNumber.module.scss";
import MismatchConfirmationDialog from "./mismatch-confirmation-dialog/MismatchConfirmationDialog";
import { mapBodyData } from "../../GeneralProductInformation";

const ULFileNumber = ({ disableIntegration, errorMessageTemplate }) => {
  const dispatch = useDispatch();
  const ulFileNumberValues = useSelector(selectULFileNumber);
  const blankOptions = [{ label: "", value: [], isBlank: true }];
  const [shouldAutoLoadFileNumberOptions, setShouldAutoLoadFileNumberOptions] =
    useState(true);
  const [schemesList, setSchemesList] = useState([]);
  const [ulFileNumberOptions, setUlFileNumberOptions] = useState({
    options: blankOptions,
    loading: false,
  });
  const isReadOnly = useSelector(selectIsReadOnly);
  const gPICustomerInputValidationResultValues = useSelector(
    selectGPICustomerInputValidationResult
  );
  const productTypeValues = useSelector(selectProductType);
  let productTypeOptions = useRef();
  const [
    certificationDetailsErrorMessage,
    setCertificationDetailsErrorMessage,
  ] = useState("");

  const [isVisible, setIsVisible] = useState(true);
  const [hasInvalidExistingCertification, setHasInvalidExistingCertification] =
    useState(ulFileNumberValues?.hasInvalidExistingCertification ?? false);
  const gpiValues = useSelector(selectGeneralProductInformation);
  const [
    isMismatchConfirmationDialogVisible,
    setIsMismatchConfirmationDialogVisible,
  ] = useState(false);
  const [hasManufacturerValues, setHasManufacturerValues] = useState(false);
  const [hasFactoryValues, setHasFactoryValues] = useState(false);
  const [hasModelValues, setHasModelValues] = useState(false);
  const existingCertificateDetails = useSelector(selectExistingCertDetails);
  const workflowPackageId = useSelector(selectWorkflowPackageId);

  useEffect(() => {
    if (productTypeValues.productTypeOptions?.length > 0) {
      productTypeOptions.current = [].concat.apply(
        [],
        productTypeValues.productTypeOptions?.map((p) => p.items) || []
      );
    }
  }, [productTypeValues.productTypeOptions]);

  const customErrors = gPICustomerInputValidationResultValues?.errors?.filter(
    (e) => e.entity === "Existing Certification"
  );

  const getSchemes = async () => {
    const result = await CertificateListService.getCertificateScheme();

    const schemes = result.map((scheme) => {
      return {
        label: scheme.hierarchyValue,
        value: {
          id: scheme.hierarchyId,
          value: scheme.hierarchyValue,
          selected: false,
        },
      };
    });

    setSchemesList(schemes);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (disableIntegration) return;

    if (!schemesList?.length) getSchemes();

    // Auto populate ul file number/volume number options in viewing.
    if (
      ulFileNumberValues.hasExistingULFileNumber &&
      ulFileNumberValues.fileNumber &&
      shouldAutoLoadFileNumberOptions
    ) {
      setShouldAutoLoadFileNumberOptions(false);

      var volumeNumbers = await handleULFileNumberFilter({
        filter: ulFileNumberValues.fileNumber,
      });

      var sectionNumbers = await handleVolumeChange(
        ulFileNumberValues,
        ulFileNumberValues.volumeNumber
      );

      dispatch(
        updateULFileNumber({
          ...ulFileNumberValues,
          volumeNumberList: volumeNumbers,
          sectionNumberList: sectionNumbers,
        })
      );
    }
  }, [ulFileNumberValues]);

  useEffect(() => {
    dispatch(
      updateULFileNumber({
        ...ulFileNumberValues,
        hasInvalidExistingCertification,
      })
    );
  }, [hasInvalidExistingCertification]);

  const handleULFileNumberError = async (oldValueParam) => {
    let errorMessage = "";

    const handleExistingUlFileNumber = async () => {
      const fileNumberModel = {
        fileNumber: ulFileNumberValues?.fileNumber?.trim(),
        volume: ulFileNumberValues?.volumeNumber?.trim() ?? "",
        section: ulFileNumberValues?.sectionNumber?.trim() ?? "",
      };

      const { data, isSuccess } = await fetchFileNumberDetails(fileNumberModel);

      if (
        isSuccess &&
        (data?.assets.length ||
          data?.factories.length ||
          data?.manufacturers.length)
      ) {
        errorMessage = bothFileNumberExistErrorMessage;
      } else {
        errorMessage = notExistingInWorkBenchErrorMessage;
        setHasInvalidExistingCertification(true);
        dispatch(
          updateULFileNumber({
            ...ulFileNumberValues,
            hasInvalidExistingCertification: true,
          })
        );
      }
      setCertificationDetailsErrorMessage(errorMessageTemplate);
      setIsVisible(!!errorMessage);
    };

    const handleCertificate = async () => {
      if (
        !ulFileNumberValues.certificateScheme &&
        ulFileNumberValues.isOtherGMACertificate
      ) {
        setIsVisible(false);
        setCertificationDetailsErrorMessage("");
      }

      let refiners = [];

      if (ulFileNumberValues.isULCBCertificateNumber) {
        refiners = [
          {
            Id: "certificateType",
            Value: "IECEE CB Scheme",
            DisplayText: "IECEE CB Scheme",
          },
        ];
      } else if (ulFileNumberValues.isOtherGMACertificate) {
        refiners = [
          {
            Id: "certificateType",
            Value: ulFileNumberValues.certificateScheme,
            DisplayText: ulFileNumberValues.certificateScheme,
          },
        ];
      }

      dispatch(updateIsLoading(true));

      const result = await CertificateListService.getCertificateListData({
        GlobalSearch: ulFileNumberValues.certificateNumber?.trim(),
        PageCount: 10,
        CurrentPage: 1,
        CustomFilters: [],
        Refiners: refiners,
      });

      if (
        result &&
        result.certificate &&
        Array.isArray(result.certificate) &&
        result.certificate.length > 0
      ) {
        errorMessage = ulFileNumberValues.isULCBCertificateNumber
          ? uLCBCertErrorMessage
          : otherGmaCertErrorMessage;

        if (
          !ulFileNumberValues.certificateNumber &&
          ulFileNumberValues.isULCBCertificateNumber
        )
          setHasInvalidExistingCertification(true);
      } else {
        errorMessage = ulFileNumberValues.isULCBCertificateNumber
          ? uLCBCertErrorMessage
          : otherGmaCertErrorMessage;
        setHasInvalidExistingCertification(true);
      }
      setIsVisible(true);
      setCertificationDetailsErrorMessage(errorMessageTemplate);
      dispatch(updateIsLoading(false));
    };

    if (ulFileNumberValues.hasExistingULFileNumber) {
      await handleExistingUlFileNumber();
    } else if (
      ulFileNumberValues.isULCBCertificateNumber ||
      ulFileNumberValues.isOtherGMACertificate
    ) {
      await handleCertificate();
    }
  };

  useEffect(() => {
    async function handleExistingCertificateChange() {
      if (
        ulFileNumberValues.isCustomerInput &&
        ulFileNumberValues.hasLoadedInitially &&
        !disableIntegration
      ) {
        await handleULFileNumberError(
          JSON.parse(ulFileNumberValues?.uLFileNumberOldValue)
        );
        dispatch(updateULFileNumberHasLoadedInitially(false));
      }
    }
    handleExistingCertificateChange();
  }, [ulFileNumberValues.hasLoadedInitially]);

  useEffect(() => {
    if (
      !ulFileNumberValues.hasLoadedInitially ||
      !ulFileNumberValues.isCustomerInput
    ) {
      setCertificationDetailsErrorMessage(errorMessageTemplate);
    }
  }, [
    ulFileNumberValues.hasLoadedInitially,
    ulFileNumberValues.isCustomerInput,
  ]);

  const handleCheckboxChange = async (field, value) => {
    setHasInvalidExistingCertification(false);
    dispatch(
      updateULFileNumber({
        ...ulFileNumberValues,
        hasExistingULFileNumber: false,
        isULCBCertificateNumber: false,
        isOtherGMACertificate: false,
        fileNumber: "",
        volumeNumber: null,
        volumeNumberList: [],
        sectionNumber: null,
        sectionNumberList: [],
        certificateScheme: "",
        certificateNumber: "",
        certificateId: null,
        shouldCompareCertificationDetails: false,
        isCustomerInput: false,
        [field]: value,
      })
    );

    setUlFileNumberOptions({
      options: blankOptions,
      loading: false,
    });
  };

  const updateCustomerErrors = () => {
    dispatch(
      updateGPICustomerInputValidationResult(
        gPICustomerInputValidationResultValues?.errors?.filter(
          (e) => e.entity !== "Existing Certification"
        )
      )
    );
  };

  const handleChange = async (field, value) => {
    if (field === "volumeNumber" && !disableIntegration) {
      dispatch(
        updateULFileNumber({
          ...ulFileNumberValues,
          sectionNumber: null,
          sectionNumberList: [],
          shouldCompareCertificationDetails: false,
          [field]: value,
        })
      );
    } else if (field === "certificateNumber" && !disableIntegration) {
      dispatch(
        updateULFileNumber({
          ...ulFileNumberValues,
          certificateNumber: value.certificateNumber,
          certificateId: value.certificateId,
          shouldCompareCertificationDetails: false,
          isCustomerInput: false,
        })
      );
    } else if (field === "sectionNumber" && !disableIntegration) {
      dispatch(
        updateULFileNumber({
          ...ulFileNumberValues,
          [field]: value,
          shouldCompareCertificationDetails: false,
          isCustomerInput: false,
        })
      );
    } else {
      dispatch(
        updateULFileNumber({
          ...ulFileNumberValues,
          [field]: value,
          shouldCompareCertificationDetails: false,
        })
      );
    }

    updateCustomerErrors();
  };

  const handleULFileNumberFilter = async (e) => {
    const { filter } = e;
    let fileNumberModel = {
      fileNumber: filter,
      volume: null,
      section: null,
    };

    const { data, isSuccess } = await ProductTypeService.getFileNumberVolumes(
      fileNumberModel
    );

    if (isSuccess && data.length > 0) {
      const options = [
        {
          label: filter,
          value: {
            fileNumber: filter,
            volumeNumbers: data,
            sectionNumbers: [],
          },
        },
      ];

      setUlFileNumberOptions({
        options,
        loading: false,
      });

      return data;
    } else {
      setUlFileNumberOptions({
        ...ulFileNumberOptions,
        loading: false,
      });
    }
  };

  const handleULFileNumberChange = async (e) => {
    setShouldAutoLoadFileNumberOptions(false);

    if (!disableIntegration) {
      const { value } = e;
      dispatch(
        updateULFileNumber({
          ...ulFileNumberValues,
          sectionNumber: null,
          sectionNumberList: [],
          volumeNumber: null,
          volumeNumberList: value.volumeNumbers,
          fileNumber: value.fileNumber,
          shouldCompareCertificationDetails: false,
        })
      );

      updateCustomerErrors();
    } else {
      const value = e.target.value;
      dispatch(
        updateULFileNumber({
          ...ulFileNumberValues,
          fileNumber: value,
          shouldCompareCertificationDetails: false,
        })
      );
    }

    if (!isReadOnly) {
      dispatch(updateIsSaveDisabled(false));
    }
  };

  const handleVolumeChange = async (
    fileNumberValues,
    value,
    clearSectionNumber
  ) => {
    let fileNumberModel = {
      fileNumber: fileNumberValues.fileNumber,
      volume: value,
    };

    const { data, isSuccess } = await ProductTypeService.getFileNumberSections(
      fileNumberModel
    );

    if (isSuccess && data.length > 0) {
      dispatch(
        updateULFileNumber({
          ...fileNumberValues,
          volumeNumber: value,
          sectionNumber: clearSectionNumber
            ? null
            : fileNumberValues.sectionNumber,
          sectionNumberList: data,
        })
      );
    }

    if (!isReadOnly) {
      dispatch(updateIsSaveDisabled(false));
    }

    return data;
  };

  const fetchFileNumberDetails = async (fileNumberModel) => {
    dispatch(updateIsLoading(true));

    const result = await ProductTypeService.getFileNumberDetails(
      fileNumberModel
    );
    dispatch(updateIsLoading(false));

    return result;
  };

  const handleSectionChange = async (fileNumberValues, value) => {
    if (!fileNumberValues || !value) return;

    const fileNumberModel = {
      fileNumber: fileNumberValues.fileNumber,
      volume: fileNumberValues.volumeNumber,
      section: value,
    };

    const { data, isSuccess } = await fetchFileNumberDetails(fileNumberModel);

    if (isSuccess && data) {
      dispatch(updateExistingCertDetails(data));

      if (gpiValues.hasFinalizedByCustomer) {
        setTimeout(() => {
          dispatch(
            updateULFileNumber({
              ...fileNumberValues,
              sectionNumber: value,
              shouldCompareCertificationDetails: true,
              isCustomerInput: false,
              hasInvalidExistingCertification: false
            })
          );
          dispatch(updateKeptCustomerDataDiscrepancies([]));
        }, 50);
      } else {
        handleMismatchHandlerOnly(data);
      }
      dispatch(updateIsSaveDisabled(false));
    }

    setHasInvalidExistingCertification(false);
    setIsVisible(false);
  };

  const handleULCBCertificateNumberFilter = async (e) => {
    const { filter } = e;
    const refiner = [
      {
        Id: "certificateType",
        Value: "IECEE CB Scheme",
        DisplayText: "IECEE CB Scheme",
      },
    ];

    await getCertificateListData(filter, refiner);
  };

  const handlerOtherGMACertificateSchemeChange = async (e) => {
    if (!disableIntegration) {
      const { value } = e;
      setUlFileNumberOptions({
        ...ulFileNumberOptions,
        options: blankOptions,
      });

      dispatch(
        updateULFileNumber({
          ...ulFileNumberValues,
          certificateNumber: null,
          certificateScheme: value.value,
          certificateId: null,
          shouldCompareCertificationDetails: false,
        })
      );

      updateCustomerErrors();
    } else {
      const value = e.target.value;
      handleChange("certificateScheme", value);
    }
  };

  const handleOtherGMACertificateNumberFilter = async (fileNumberValues, e) => {
    const { filter } = e;
    const refiner = [
      {
        Id: "certificateType",
        Value: fileNumberValues.certificateScheme,
        DisplayText: fileNumberValues.certificateScheme,
      },
    ];

    await getCertificateListData(filter, refiner);
  };

  const handleCertificateNumberChange = async (fileNumberValues, e) => {
    const { value } = e;

    await getCertificateDetail(fileNumberValues, value);

    setHasInvalidExistingCertification(false);
    setIsVisible(false);
  };

  const handleVolumeNumberChange = ({ originalEvent, value }) => {
    if (!originalEvent && !disableIntegration) return;
    setShouldAutoLoadFileNumberOptions(false);
    handleChange("volumeNumber", value);
    if (!disableIntegration)
      handleVolumeChange(ulFileNumberValues, value, true);
  };

  const handleSectionNumberChange = ({ originalEvent, value }) => {
    if (!originalEvent && !disableIntegration) return;
    setShouldAutoLoadFileNumberOptions(false);
    handleChange("sectionNumber", value);

    if (!disableIntegration) handleSectionChange(ulFileNumberValues, value);
  };

  const handleULCBCertificateNumberChange = (e) => {
    handleChange("certificateNumber", e.value);

    if (!disableIntegration)
      handleCertificateNumberChange(ulFileNumberValues, e);
  };

  const handleOtherCertificateNumberChange = (e) => {
    handleChange("certificateNumber", e.value);

    if (!disableIntegration)
      handleCertificateNumberChange(ulFileNumberValues, e);
  };

  const getCertificateListData = async function (
    globalSearchParam,
    refinersParam
  ) {
    const result = await CertificateListService.getCertificateListData({
      GlobalSearch: globalSearchParam,
      PageCount: 10,
      CurrentPage: 1,
      CustomFilters: [],
      Refiners: refinersParam,
    });

    if (
      result &&
      result.certificate &&
      Array.isArray(result.certificate) &&
      result.certificate.length > 0
    ) {
      const options = result.certificate.map((a) => {
        return {
          label: a.certificateName,
          value: {
            certificateId: a.certificateId,
            certificateScheme: a.certificateType,
            certificateNumber: a.certificateName,
          },
        };
      });

      setUlFileNumberOptions({
        options,
        loading: false,
      });
    } else {
      setUlFileNumberOptions({
        ...ulFileNumberOptions,
        loading: false,
      });
    }
  };

  const fetchCertificateDetails = async (certificateModel) => {
    dispatch(updateIsLoading(true));

    const result = await ProductTypeService.getCertificateDetails(
      certificateModel
    );

    dispatch(updateIsLoading(false));

    return result;
  };

  const getCertificateDetail = async function (fileNumberValues, value) {
    if (!value.certificateId) return;

    const certificateModel = {
      certificateId: value.certificateId,
    };

    const { data, isSuccess } = await fetchCertificateDetails(certificateModel);

    if (isSuccess && data) {
      dispatch(updateExistingCertDetails(data));

      if (gpiValues.hasFinalizedByCustomer) {
        setTimeout(() => {
          dispatch(
            updateULFileNumber({
              ...fileNumberValues,
              certificateId: value.certificateId,
              certificateScheme: value.certificateScheme,
              certificateNumber: value.certificateNumber,
              shouldCompareCertificationDetails: true,
              isCustomerInput: false,
              hasInvalidExistingCertification: false
            })
          );
          dispatch(updateKeptCustomerDataDiscrepancies([]));
        }, 50);
      } else {
        handleMismatchHandlerOnly(data);
      }
      dispatch(updateIsSaveDisabled(false));
    }
  };

  const getCustomErrorMessage = (field) => {
    if (hasInvalidExistingCertification)
      return invalidExistingCertificationMessage;

    return getGPIFieldErrorMessage(customErrors, field);
  };

  const hasFieldValues = (gpiSectionValues) => {
    if (!gpiSectionValues) return;

    let isEmpty = true;

    if (typeof gpiSectionValues == "object") {
      if (gpiSectionValues?.length > 0) {
        for (let i = 0; i < gpiSectionValues?.length; i++) {
          const currentValue = gpiSectionValues[i];

          for (let key in currentValue) {
            if (
              currentValue.hasOwnProperty(key) &&
              key !== "source" &&
              !key.toLowerCase().includes("id") &&
              typeof currentValue[key] !== "boolean" &&
              typeof currentValue[key] !== "object"
            ) {
              isEmpty = currentValue[key] == null || currentValue[key] === "";
            }
            if (!isEmpty) {
              return true;
            }
          }
        }
      } else {
        for (let key in gpiSectionValues) {
          if (
            gpiSectionValues.hasOwnProperty(key) &&
            key !== "source" &&
            !key.toLowerCase().includes("id") &&
            typeof gpiSectionValues[key] !== "boolean" &&
            typeof gpiSectionValues[key] !== "object"
          ) {
            isEmpty =
              gpiSectionValues[key] == null || gpiSectionValues[key] === "";
          }
          if (!isEmpty) {
            return true;
          }
        }
      }
    }

    return !isEmpty;
  };

  const handleMismatchHandlerOnly = (data) => {
    const hasManufacturer = hasFieldValues(
      gpiValues.companyDetails.manufacturerInformation
    );
    const hasFactory = hasFieldValues(
      gpiValues.companyDetails.factorySiteInformation.factorySites
    );
    const hasModels =
      hasFieldValues(gpiValues.productDetails.productType.baseModel) ||
      hasFieldValues(gpiValues.productDetails.productType.modelVariants);

    setHasManufacturerValues(hasManufacturer);
    setHasFactoryValues(hasFactory);
    setHasModelValues(hasModels);

    if (hasManufacturer || hasFactory || hasModels) {
      setIsMismatchConfirmationDialogVisible(true);
    } else {
      handleReplaceGPIValues(data);
    }
  };

  const handleReplaceGPIValues = (certData) => {
    dispatch(updateIsLoading(true));

    const certDetails = certData ?? existingCertificateDetails;

    const manufacturer = getManufacturerFromCertificateDetails(
      certDetails,
      gpiValues.companyDetails.manufacturerInformation
    );
    dispatch(updateManufacturerInformation(manufacturer));
    dispatch(updateManufacturerFromCertificate(manufacturer));

    const factories = getFactoriesFromCertificateDetails(
      certDetails,
      gpiValues.companyDetails.factorySiteInformation.factorySites
    );

    const factoryDetails = {
      factoriesCount: certDetails?.factories.length,
      hasFetchedCertDetails: true,
      factorySites: factories,
    };

    dispatch(updateFactorySiteInformation(factoryDetails));

    const newBaseModel = getBaseModelFromCertificateDetails(certDetails);

    dispatch(updateBaseModel(newBaseModel));

    const newModelVariants =
      getModelVariantsFromCertificateDetails(certDetails);
    dispatch(updateModelVariants(newModelVariants));

    let newProductTypes = certDetails.assets.map((x) => ({
      hierarchyValue: x.productType,
      hierarchyId: x.hierarchyId,
    }));

    newProductTypes = uniqBy(newProductTypes, "hierarchyValue");

    dispatch(updateProductTypeProductTypes(newProductTypes));
    dispatch(updateIsModelsAutoPopulatedByCertificateSelection(true));

    const updatedGpiValues = {
      ...gpiValues,
      companyDetails: {
        ...gpiValues.companyDetails,
        manufacturerInformation: manufacturer,
        factorySiteInformation: factoryDetails,
      },
      productDetails: {
        ...gpiValues.productDetails,
        productType: {
          ...gpiValues.productDetails.productType,
          baseModel: newBaseModel,
          modelVariants: newModelVariants,
          productTypes: newProductTypes,
        },
      },
    };
    validateFormAfterLoadingExistingCertValues(updatedGpiValues);

    setIsMismatchConfirmationDialogVisible(false);
    dispatch(updateIsLoading(false));
  };

  const validateFormAfterLoadingExistingCertValues = async (
    generalProductInfo
  ) => {
    dispatch(updateIsShowValidationBanner(true));
    dispatch(updateValidationBannerStatusType("info"));

    const gpiToValidate = mapBodyData(generalProductInfo, workflowPackageId);

    const result = workflowPackageId
      ? await GeneralProductInformationService.getExistingOrGenerateNewGPICustomerInputValidationResult(
          gpiToValidate
        )
      : await GeneralProductInformationService.getCustomerCompanyDetailsInputValidationResult(
          gpiToValidate
        );

    if (!result) {
      dispatch(updateValidationBannerStatusType("warning"));
      return;
    }

    if (!result.isSuccess) {
      dispatch(updateValidationBannerStatusType("warning"));
      return;
    }
  };

  return (
    <div className="form-section">
      <div className="form-section-header">
        <h3 className="form-section-title">
          Existing Certifications
          <small>
            ( If the product has existing certifications issued or facilitated
            by UL that you would like to reference, please provide the requested
            details. Please provide one of the following, otherwise leave blank.
            )
          </small>
        </h3>
      </div>

      {ulFileNumberValues.isCustomerInput &&
        isVisible &&
        !disableIntegration && (
          <div className={classes["existing-cert-error"]}>
            {certificationDetailsErrorMessage}
          </div>
        )}

      <div className="form-section-content">
        <div className={classes["section-title"]}>
          <h4>If available, search by:</h4>
        </div>
        <div className="question-w-checkbox-container">
          <Checkbox
            inputId="cbULFileNumber"
            checked={ulFileNumberValues.hasExistingULFileNumber}
            onChange={({ checked }) =>
              handleCheckboxChange("hasExistingULFileNumber", checked)
            }
            disabled={isReadOnly}
          />
          <label htmlFor="cbULFileNumber">UL File Number / Volume</label>
        </div>

        <div className="question-w-checkbox-container">
          <Checkbox
            inputId="cbULCertificateNumber"
            checked={ulFileNumberValues.isULCBCertificateNumber}
            onChange={({ checked }) =>
              handleCheckboxChange("isULCBCertificateNumber", checked)
            }
            disabled={isReadOnly}
          />
          <label htmlFor="cbULCertificateNumber">
            UL CB Certificate Number
          </label>
        </div>

        <div className="question-w-checkbox-container">
          <Checkbox
            inputId="cbGMACertificate"
            checked={ulFileNumberValues.isOtherGMACertificate}
            onChange={({ checked }) =>
              handleCheckboxChange("isOtherGMACertificate", checked)
            }
            disabled={isReadOnly}
          />
          <label htmlFor="cbGMACertificate">Other GMA Certificate</label>
        </div>

        {ulFileNumberValues.hasExistingULFileNumber && (
          <>
            <FormControl
              label="Existing File Number"
              controlClassName={
                disableIntegration ? "disable-dropdown-trigger" : ""
              }
              customErrorMessage={getCustomErrorMessage("UL File Number")}
              rootObject={ulFileNumberValues}
              field={"fileNumber"}
              schema={ulFileNumberSchema}
              control={
                !disableIntegration ? (
                  <Dropdown
                    options={ulFileNumberOptions.options}
                    emptyFilterMessage={
                      ulFileNumberOptions.loading
                        ? "Loading..."
                        : "No results found"
                    }
                    onFilter={(e) => {
                      setUlFileNumberOptions({
                        ...ulFileNumberOptions,
                        loading: true,
                      });

                      handleULFileNumberFilter(e);
                    }}
                    onChange={(e) => handleULFileNumberChange(e)}
                    editable={true}
                    filter
                    resetFilterOnHide
                    readOnly={!disableIntegration}
                  />
                ) : (
                  <InputText
                    onChange={(e) => handleULFileNumberChange(e)}
                    editable
                  />
                )
              }
              helpText={existingFileNumberHelpText}
            />

            <div className="d-flex" style={{ gap: "30px" }}>
              <div className="col-6">
                <FormControl
                  label="Volume Number"
                  controlClassName={
                    disableIntegration ? "disable-dropdown-trigger" : ""
                  }
                  rootObject={ulFileNumberValues}
                  field={"volumeNumber"}
                  schema={ulFileNumberSchema}
                  customErrorMessage={getCustomErrorMessage("Volume Number")}
                  control={
                    !disableIntegration ? (
                      <Dropdown
                        options={ulFileNumberValues.volumeNumberList}
                        optionLabel="name"
                        onChange={handleVolumeNumberChange}
                        disabled={
                          (!ulFileNumberValues.fileNumber &&
                            !disableIntegration) ||
                          isReadOnly
                        }
                        editable={true}
                        readOnly={!disableIntegration}
                        filter={!disableIntegration}
                      />
                    ) : (
                      <InputText
                        onChange={(e) => handleVolumeNumberChange(e.target)}
                        editable
                      />
                    )
                  }
                  helpText={volumeNumberHelpText}
                />
              </div>

              <div className="col-6">
                <FormControl
                  label="Section Number"
                  controlClassName={
                    disableIntegration ? "disable-dropdown-trigger" : ""
                  }
                  rootObject={ulFileNumberValues}
                  field={"sectionNumber"}
                  schema={ulFileNumberSchema}
                  customErrorMessage={getCustomErrorMessage("Section Number")}
                  control={
                    !disableIntegration ? (
                      <Dropdown
                        options={ulFileNumberValues.sectionNumberList}
                        optionLabel="name"
                        onChange={handleSectionNumberChange}
                        disabled={
                          (!ulFileNumberValues.volumeNumber &&
                            !disableIntegration) ||
                          isReadOnly
                        }
                        editable={true}
                        readOnly={!disableIntegration}
                        filter={!disableIntegration}
                      />
                    ) : (
                      <InputText
                        onChange={(e) => handleSectionNumberChange(e.target)}
                        editable
                      />
                    )
                  }
                  helpText={sectionNumberHelperText}
                />
              </div>
            </div>

            <div className={classes["help-details"]}>
              <h4 className={classes["title"]}>Help Details</h4>
              <div>
                UL File Numbers contain a File Number that typically starts with
                "E" followed by 5 to 6 digits and a Volume that is either a
                number or starts with "X" followed by a number and Section
                Number.
              </div>

              <div>
                <div>For example: </div>
                <div>E123456-A123-CB-1</div>
              </div>

              <div>
                <div>File Number = E123456</div>
                <div>Volume number = X11</div>
                <div>Section Number = 1</div>
              </div>

              <div>
                <div>File Number = MH24680</div>
                <div>Volume number = 1</div>
                <div>Section Number = 2</div>
              </div>
            </div>
          </>
        )}

        {ulFileNumberValues.isULCBCertificateNumber && (
          <>
            <FormControl
              label="UL CB Certificate Number"
              rootObject={ulFileNumberValues}
              field={"certificateNumber"}
              schema={ulFileNumberSchema}
              customErrorMessage={getCustomErrorMessage(
                "UL CB Certificate Number"
              )}
              controlClassName={
                disableIntegration ? "disable-dropdown-trigger" : ""
              }
              control={
                !disableIntegration ? (
                  <Dropdown
                    options={ulFileNumberOptions.options}
                    emptyFilterMessage={
                      ulFileNumberOptions.loading
                        ? "Loading..."
                        : "No results found"
                    }
                    onFilter={(e) => {
                      setUlFileNumberOptions({
                        ...ulFileNumberOptions,
                        loading: true,
                      });

                      handleULCBCertificateNumberFilter(e);
                    }}
                    onChange={handleULCBCertificateNumberChange}
                    editable
                    filter
                    resetFilterOnHide
                    readOnly={!disableIntegration}
                  />
                ) : (
                  <InputText
                    onChange={(e) =>
                      handleULCBCertificateNumberChange(e.target)
                    }
                    editable
                  />
                )
              }
              helpText={ulcbCertificateNumberHelperText}
            />
          </>
        )}

        {ulFileNumberValues.isOtherGMACertificate && (
          <>
            <div className="d-flex" style={{ gap: "30px" }}>
              <div className="col-6">
                <FormControl
                  label="Certificate Scheme"
                  rootObject={ulFileNumberValues}
                  field={"certificateScheme"}
                  schema={ulFileNumberSchema}
                  customErrorMessage={getCustomErrorMessage(
                    "Certificate Scheme"
                  )}
                  controlClassName={
                    disableIntegration ? "disable-dropdown-trigger" : ""
                  }
                  control={
                    !disableIntegration ? (
                      <Dropdown
                        options={schemesList}
                        emptyFilterMessage="No results found"
                        onChange={(e) =>
                          handlerOtherGMACertificateSchemeChange(e)
                        }
                        editable
                        filter
                        resetFilterOnHide
                        readOnly={!disableIntegration}
                      />
                    ) : (
                      <InputText
                        onChange={(e) =>
                          handlerOtherGMACertificateSchemeChange(e)
                        }
                        editable
                      />
                    )
                  }
                  helpText={certificateSchemeHelperText}
                />
              </div>

              <div className="col-6">
                <FormControl
                  label="Certificate Number"
                  customErrorMessage={getCustomErrorMessage(
                    "Certificate Number"
                  )}
                  rootObject={ulFileNumberValues}
                  field={"certificateNumber"}
                  schema={ulFileNumberSchema}
                  controlClassName={
                    disableIntegration ? "disable-dropdown-trigger" : ""
                  }
                  control={
                    !disableIntegration ? (
                      <Dropdown
                        options={ulFileNumberOptions.options}
                        emptyFilterMessage={
                          ulFileNumberOptions.loading
                            ? "Loading..."
                            : "No results found"
                        }
                        onFilter={(e) => {
                          setUlFileNumberOptions({
                            ...ulFileNumberOptions,
                            loading: true,
                          });

                          handleOtherGMACertificateNumberFilter(
                            ulFileNumberValues,
                            e
                          );
                        }}
                        onChange={handleOtherCertificateNumberChange}
                        editable
                        filter
                        resetFilterOnHide
                        disabled={
                          (!ulFileNumberValues.certificateScheme &&
                            !disableIntegration) ||
                          isReadOnly
                        }
                        readOnly={!disableIntegration}
                      />
                    ) : (
                      <InputText
                        onChange={(e) =>
                          handleOtherCertificateNumberChange(e.target)
                        }
                        editable
                      />
                    )
                  }
                  helpText={certificateNumberHelperText}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <MismatchConfirmationDialog
        visible={isMismatchConfirmationDialogVisible}
        onHide={() => setIsMismatchConfirmationDialogVisible(false)}
        onConfirm={handleReplaceGPIValues}
        isReplaceManufacturer={hasManufacturerValues}
        isReplaceFactories={hasFactoryValues}
        isReplaceModels={hasModelValues}
      />
    </div>
  );
};

export default ULFileNumber;

const mapCompanyDetails = (
  { partySiteNumber, partyName: companyName, locationReference },
  contactInformation,
  isFromCertificate = true
) => {
  const {
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    city,
    state,
    province,
    countryName: country,
    postalCode: zipCode,
  } = locationReference;

  const streetAddress = [addressLine1, addressLine2, addressLine3, addressLine4]
    .filter((a) => a != "")
    .join(", ");

  return {
    partySiteNumber,
    companyName,
    streetAddress,
    city,
    stateOrProvince: state + province,
    zipCode,
    country,
    contactPerson: contactInformation?.contactPerson || "",
    title: contactInformation?.title || "",
    phone: contactInformation?.phone || "",
    email: contactInformation?.email || "",
    fax: contactInformation?.fax || "",
    website: contactInformation?.website || "",
    isFromCertDetails: isFromCertificate,
  };
};

const manufacturerInitialState = {
  ...customerInformationInitialState,
  isSameAsApplicant: false,
};

export const getManufacturerFromCertificateDetails = (
  certificateDetails,
  manufacturerInformation
) => {
  if (!certificateDetails || !certificateDetails.manufacturers) return null;

  const manufacturers = certificateDetails.manufacturers.map((x) => {
    return {
      ...manufacturerInitialState,
      ...mapCompanyDetails(x, manufacturerInformation, true),
    };
  });

  return manufacturers[0];
};

const factorySiteInitialState = {
  ...customerInformationInitialState,
  source: FACTORY_SITE_INFO_SOURCE.MANUAL_INPUT,
  isFromCertDetails: true,
};

export const getFactoriesFromCertificateDetails = (
  certificateDetails,
  factories
) => {
  if (!certificateDetails) return null;

  if (certificateDetails.factories.length == 0)
    return [factorySiteInitialState];

  return certificateDetails.factories.map((x, index) => {
    let factoryDetails;
    if (factories.length - 1 >= index) {
      factoryDetails = factories[index];
    }
    return {
      ...factorySiteInitialState,
      ...mapCompanyDetails(x, factoryDetails, true),
      isSelected: true,
      isFromCertDetails: true,
      source: FACTORY_SITE_INFO_SOURCE.UL_FILE_NUMBER_INPUT,
    };
  });
};

export const getBaseModelFromCertificateDetails = (certificateDetails) => {
  return { ...certificateDetails?.assets[0] };
};

export const getModelVariantsFromCertificateDetails = (certificateDetails) => {
  return certificateDetails?.assets?.filter((a, i) => i > 0);
};
