import React, { useRef } from "react";
import CustomFilter from "../../shared/custom-filter/CustomFilter";
import CertificateProductListRefiners from "./certificate-product-list-refiners/CertificateProductListRefiners";
import CertificateProductListGrid from "./certificate-product-list/certificate-product-list-grid/CertificateProductListGrid";
import { useEffect } from "react";
import { useState } from "react";
import CertificateListService from "../../services/CertificateListService";
import certificateProductListGridColumns from "./data/cert-prod-list-grid-columns";
import { Toast } from "primereact/toast";

import "./CertificateProductListView.scss";
import CertificateProductDetails from "./certificate-product-list/certificate-product-details/CertificateProductDetails";
import useClickedOutside from "../../hooks/useClickedOutside";
import certProdListCustomFilterColumns from "./data/cert-prod-list-custom-filter-columns";
import certProdListGridSubColumns from "./data/cert-prod-list-grid-sub-columns";
import certProdListRefinerColumns from "../../shared/refiners/data/cert-prod-list-refiner-columns";

const CertificateProductListView = () => {
  const toast = useRef(null);
  const [certProdListData, setCertProdListData] = useState([]);
  const [certProdListColumns, setCertProdListColumns] = useState(
    certificateProductListGridColumns
  );
  const [certProdListFrozenCount, setCertProdListFrozenCount] = useState(8);
  const [refinerColumns, setRefinerColumns] = useState(certProdListRefinerColumns);

  const [totalRowsCount, setTotalRowsCount] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageCount, setPageCount] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [customFilters, setCustomFilters] = useState([]);
  const [refiners, setRefiners] = useState([]);
  const [hasRefiners, setHasRefiners] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 1,
    last: 100,
    rows: 100,
    page: 1,
    isFirstPageLinkDisabled: false,
    isPrevPageLinkDisabled: false,
    isNextPageLinkDisabled: false,
    isLastPageLinkDisabled: false,
  });

  const [globalSearchText, setGlobalSearchText] = useState("");
  const [favoriteName, setFavoriteName] = useState("");
  const [favoriteList, setFavoriteList] = useState([]);

  const [showCertificateProductDetails, setShowCertificateProductDetails] =
    useState(false);
  const { ref, isClickedOutSide, setIsClickedOutSide } = useClickedOutside();
  const globalSearchTextRef = useRef();
  const currentPageRef = useRef();

  useEffect(() => {
    getDefaultSettings();
  }, []);

  const getDefaultProdListData = () => {
    getCertProdListData(
      globalSearchText,
      pageCount,
      currentPage,
      customFilters,
      refiners
    );
  };

  const getDefaultSettings = () => {
    getFavorites();
  };

  const getFavorites = async function (favoriteId = 0) {
    let favorites = await CertificateListService.getFavorites(5);
    if (!favorites) {
      favorites = []; 
    }
    const defaultFavorite =
      favoriteId > 0
        ? favorites.find((f) => f.savedViewFavoriteId === favoriteId)
        : favorites.find((f) => f.isDefault);

    if (defaultFavorite) {
      const settings = JSON.parse(defaultFavorite.settings);
      const frozenCount = settings.certListColumns.filter(
        (f) => f.isFrozen
      ).length;
      const params = lazyParams;
      params.rows = settings.pageCount;

      getCertProdListData(
        settings.globalSearchText,
        settings.pageCount,
        currentPage,
        settings.customFilters,
        settings.refiners,
        params
      );

      setCertProdListColumns(settings.certListColumns);
      setCertProdListFrozenCount(frozenCount);
      setGlobalSearchText(settings.globalSearchText);
      setPageCount(settings.pageCount);
      setCustomFilters(settings.customFilters);
      setRefiners(settings.refiners);
      setFavoriteName(defaultFavorite.description);
      globalSearchTextRef.current.value = settings.globalSearchText;
    } else {
      onClearFilters();
    }

    setFavoriteList(favorites);
  };

  const onSaveFavorite = (description, isDefault) => {
    const settings = {};
    settings.certListColumns = certProdListColumns;
    settings.globalSearchText = globalSearchText;
    settings.customFilters = customFilters;
    settings.refiners = refiners;
    settings.pageCount = pageCount;

    const data = {};
    data.description = description;
    data.isDefault = isDefault;
    data.savedViewTypeId = 5;
    data.settings = JSON.stringify(settings);

    saveFavorites(data);
  };

  const onEditFavorite = (id, description, isDefault) => {
    const data = favoriteList.find((f) => f.savedViewFavoriteId === id);
    data.description = description;
    data.isDefault = isDefault;

    saveFavorites(data, "udpated");
  };

  const onDeleteFavorite = (id) => {
    const data = favoriteList.find((f) => f.savedViewFavoriteId === id);
    data.isDeleted = true;

    saveFavorites(data, "deleted");
  };

  const saveFavorites = async function (data, transaction = "saved") {
    const result = await CertificateListService.saveFavorites(data);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: `Favorite has been successfully ${transaction}`,
      life: 3000,
    });

    getFavorites(result);
  };

  const onClickFavorites = (id) => {
    getFavorites(id);
  };

  const onClearFilters = () => {
    const params = lazyParams;
    params.first = 1;
    params.rows = 100;
    params.page = 1;
    params.last = 100;

    getCertProdListData("", 100, 1, [], [], params);

    setGlobalSearchText("");
    setPageCount(100);
    setCurrentPage(1);
    setCustomFilters([]);
    setRefiners([]);
    setFavoriteName("");
    currentPageRef.current.value = "1";
    globalSearchTextRef.current.value = "";
  };

  const getCertProdListData = async function (
    globalSearchParam,
    pageCountParam,
    currentPageParam,
    customFiltersParam,
    refinersParam,
    lazyParam = lazyParams
  ) {
    setLoading(true);
    const result = await CertificateListService.getIpProductListData({
      GlobalSearch: globalSearchParam,
      PageCount: pageCountParam,
      CurrentPage: currentPageParam,
      CustomFilters: customFiltersParam,
      Refiners: refinersParam,
    });

    if (
      result &&
      result.asset &&
      Array.isArray(result.asset) &&
      result.asset.length > 0
    ) {
      const totalPage = Math.ceil(result.totalCount / pageCountParam);
      setTotalPageCount(totalPage);
      setCertProdListData(result.asset);
      setTotalRowsCount(result.totalCount);

      lazyParam.last = pageCountParam * currentPageParam;
      if (lazyParam.last > parseInt(result.totalCount)) {
        lazyParam.last = parseInt(result.totalCount);
      }

      pageChange(lazyParam, totalPage);
    } else {
      setTotalPageCount(0);
      setCertProdListData(result.asset);
      setTotalRowsCount(0);

      if (currentPageParam > 1) {
        lazyParam.first = 1;
        lazyParam.last = lazyParam.rows;
        lazyParam.page = 1;

        getCertProdListData(
          globalSearchParam,
          pageCountParam,
          1,
          customFiltersParam,
          refinersParam,
          lazyParam
        );
      } else {
        lazyParam.first = 1;
        lazyParam.last = 100;
        lazyParam.rows = 100;
        lazyParam.page = 1;

        pageChange(lazyParam);
      }
    }

    if (refinersParam.length > 0) {
      setHasRefiners(true);
    }
    else {
      setHasRefiners(false);
    }

    setLoading(false);
  };

  const replaceFilterBySelected = (value, refinerId) => {
    setRefiners((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState = newState.filter((i) => i.Id !== refinerId);

      newState.push({
        Id: refinerId,
        Value: value.name,
        DisplayText: value.name,
      });

      getCertProdListData(
        globalSearchText,
        pageCount,
        currentPage,
        customFilters,
        newState
      );

      return newState;
    });
  };

  const addSelectedToYourFilters = (value, refinerId) => {
    if (value && Array.isArray(value) && value.length > 0) {
      setRefiners((prevState) => {
        let newState = JSON.parse(JSON.stringify(prevState));
        value.forEach((item) => {
          var existingRefiner = prevState.find(
            (i) => i.Value === item.name && i.Id === refinerId
          );
          if (!existingRefiner) {
            newState.push({
              Id: refinerId,
              Value: item.name,
              DisplayText: item.name,
            });
          }
        });

        getCertProdListData(
          globalSearchText,
          pageCount,
          currentPage,
          customFilters,
          newState
        );
        return newState;
      });
    } else if (value && value.name && value.code) {
      setRefiners((prevState) => {
        let newState = [];
        let existingRefiner = null;

        if (prevState) {
          newState = JSON.parse(JSON.stringify(prevState));
          existingRefiner = prevState.find(
            (i) => i.Value === value.name && i.Id === refinerId
          );
        }
        if (!existingRefiner) {
          newState.push({
            Id: refinerId,
            Value: value.name,
            DisplayText: value.name,
          });
        }

        getCertProdListData(
          globalSearchText,
          pageCount,
          currentPage,
          customFilters,
          newState
        );
        return newState;
      });
    } else if (typeof value === "string") {
      setRefiners((prevState) => {
        let newState = JSON.parse(JSON.stringify(prevState));
        newState = newState.filter(i => !(i.Value === value && i.Id === refinerId));
        newState.push({
          Id: refinerId,
          Value: value,
          DisplayText: value,
        });

        getCertProdListData(
          globalSearchText,
          pageCount,
          currentPage,
          customFilters,
          newState
        );
        return newState;
      });
    }
  };

  const onChangeDatePickerRefiner = (value, refinerId) => {
    if (value && value.Value && value.Condition) {
      setRefiners((prevState) => {
        var newState = JSON.parse(JSON.stringify(prevState));
        newState = newState.filter(
          (i) => !(i.Condition === value.Condition && i.Id === refinerId)
        );

        newState.push({
          Id: refinerId,
          Value: value.Value,
          Condition: value.Condition,
          DisplayText: value.DisplayText,
        });

        getCertProdListData(
          globalSearchText,
          pageCount,
          currentPage,
          customFilters,
          newState
        );
        return newState;
      });
    }
  };

  const onClickRemoveRefinerGroup = (refinerId) => {
    setRefiners((prevState) => {
      const stateTemp = prevState.filter((i) => i.Id !== refinerId);

      getCertProdListData(
        globalSearchText,
        pageCount,
        currentPage,
        customFilters,
        stateTemp
      );
      return stateTemp;
    });
  };

  const onClickRemoveRefiner = (item, isCompareValue = true) => {
    setRefiners((prevState) => {
      let stateTemp;

      if (isCompareValue) {
        stateTemp = prevState.filter(
          (i) => !(i.Value === item.Value && i.Id === item.Id)
        );
      } else {
        stateTemp = prevState.filter(
          (i) => !(i.Condition === item.Condition && i.Id === item.Id)
        );
      }

      getCertProdListData(
        globalSearchText,
        pageCount,
        currentPage,
        customFilters,
        stateTemp
      );
      return stateTemp;
    });
  };

  const onClickGlobalSearch = (searchText) => {
    getCertProdListData(
      searchText,
      pageCount,
      currentPage,
      customFilters,
      refiners
    );

    setGlobalSearchText(searchText);
  };

  const onClickStatusFilter = (statusFilter) => {
    setRefiners([]);

    if (statusFilter) {
      getCertProdListData(
        globalSearchText,
        pageCount,
        currentPage,
        customFilters,
        [
          {
            Id: "status",
            Value: statusFilter,
          },
        ]
      );
    } else {
      getCertProdListData(
        globalSearchText,
        pageCount,
        currentPage,
        customFilters,
        refiners.filter((i) => i.Id !== "status")
      );
    }
  };

  const onChangePage = (params) => {
    params.first = (params.page - 1) * params.rows + 1;
    const last = params.page * params.rows;
    params.last = last > totalRowsCount ? totalRowsCount : last;

    getCertProdListData(
      globalSearchText,
      params.rows,
      params.page,
      customFilters,
      refiners,
      params
    );
  };

  const pageChange = (params, totalPages = 0) => {
    if (params.page > 1) {
      params.isFirstPageLinkDisabled = false;
      params.isPrevPageLinkDisabled = false;
    } else {
      params.isFirstPageLinkDisabled = true;
      params.isPrevPageLinkDisabled = true;
    }

    if (params.page < totalPages) {
      params.isLastPageLinkDisabled = false;
      params.isNextPageLinkDisabled = false;
    } else {
      params.isLastPageLinkDisabled = true;
      params.isNextPageLinkDisabled = true;
    }

    setPageCount(params.rows);
    setCurrentPage(params.page);
    setLazyParams(params);
    currentPageRef.current.value = params.page;
  };

  const onClickClearYourFilters = () => {
    setRefiners([]);
    getCertProdListData(
      globalSearchText,
      pageCount,
      currentPage,
      customFilters,
      []
    );
  };

  const onClickSetCustomFilters = () => {
    if (
      customFilters &&
      Array.isArray(customFilters) &&
      customFilters.length > 0
    ) {
      getDefaultProdListData();
    }
  };

  const onClickClearCustomFilters = () => {
    setCustomFilters([]);
    getCertProdListData(globalSearchText, pageCount, currentPage, [], refiners);
  };

  return (
    <>
      <div className="ul-grid__row cert-list-view-container">
        <div className="ul-grid__column -x20 -offset-10-desktop -x100-mobile cert-search-and-refiners-area">
          <CustomFilter
            globalSearchTextRef={globalSearchTextRef}
            columns={certProdListCustomFilterColumns}
            conditions={["Contains", "Equals"]}
            customFilters={customFilters}
            setCustomFilters={setCustomFilters}
            onClickSetCustomFilters={onClickSetCustomFilters}
            onClickClearCustomFilters={onClickClearCustomFilters}
            refiners={refiners}
            refinerColumns={refinerColumns}
            hasRefiners={hasRefiners}
            onClickRemoveRefiner={onClickRemoveRefiner}
            onClickRemoveRefinerGroup={onClickRemoveRefinerGroup}
            onClickGlobalSearch={onClickGlobalSearch}
            onClickClearYourFilters={onClickClearYourFilters}
          />
          <CertificateProductListRefiners
            refiners={refiners}
            setRefiners={setRefiners}
            onClickRemoveRefiner={onClickRemoveRefiner}
            onClickRemoveRefinerGroup={onClickRemoveRefinerGroup}
            addSelectedToYourFilters={addSelectedToYourFilters}
            replaceFilterBySelected={replaceFilterBySelected}
            onChangeDatePickerRefiner={onChangeDatePickerRefiner}
          />
        </div>

        <div className="ul-grid__column -x80 -x100-mobile cert-prod-list-grid-area">
          <CertificateProductListGrid
            loading={loading}
            currentPageRef={currentPageRef}
            certListData={certProdListData}
            columns={certProdListColumns}
            subColumns={certProdListGridSubColumns}
            frozenColCount={certProdListFrozenCount}
            totalCertificateCount={totalRowsCount}
            totalPageCount={totalPageCount}
            pageCount={pageCount}
            currentPage={currentPage}
            lazyParams={lazyParams}
            favoriteName={favoriteName}
            favoriteList={favoriteList}
            onClickStatusFilter={onClickStatusFilter}
            onChangePage={onChangePage}
            setCertListColumns={setCertProdListColumns}
            setCertFrozenCount={setCertProdListFrozenCount}
            setShowCertificateProductDetails={setShowCertificateProductDetails}
            setIsClickedOutSide={setIsClickedOutSide}
            onSaveFavorite={onSaveFavorite}
            onEditFavorite={onEditFavorite}
            onDeleteFavorite={onDeleteFavorite}
            onClickFavorites={onClickFavorites}
            onClearFilters={onClearFilters}
          />
        </div>

        {showCertificateProductDetails && !isClickedOutSide && (
          <div ref={ref}>
            <CertificateProductDetails
              setIsClickedOutSide={setIsClickedOutSide}
            />
          </div>
        )}
      </div>

      <Toast ref={toast} />
    </>
  );
};
export default CertificateProductListView;
