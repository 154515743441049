export const GMATCTaskName = "GMA TC_Processing (non-UL CO)";

export const CORTaskName = "Certification Office Review";

export const GMATCMilestoneName = "UL: GMA Transaction Center";

export const isGmaTcTask = (taskName: string) => {
  return taskName.toLocaleLowerCase() == GMATCTaskName.toLocaleLowerCase();
};
export const isGmaTcMilestone = (milestone: string) => {
  return (
    milestone.toLocaleLowerCase() == GMATCMilestoneName.toLocaleLowerCase()
  );
};
