import { Checkbox } from "primereact/checkbox";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  customerInformationInitialState,
  selectApplicantInformation,
  selectExistingCertDetails,
  selectGPICustomerInputValidationResult,
  selectIsReadOnly,
  selectManufacturerFromCertificate,
  selectManufacturerInformation,
  selectULFileNumber,
  updateIsSaveDisabled,
  updateManufacturerInformation,
  updateManufacturerIsSameAsApplicant,
} from "../../../../features/generalProductInformation/generalProductInformationSlice";
import CustomerInformation from "../shared/CustomerInformation/CustomerInformation";
import { getManufacturerFromCertificateDetails } from "../ul-file-number/ULFileNumber";

const ManufacturerInformation = ({ disableIntegration }) => {
  const dispatch = useDispatch();
  const manufacturerInformationValues = useSelector(
    selectManufacturerInformation
  );
  const applicantInformationValues = useSelector(selectApplicantInformation);
  const isReadOnly = useSelector(selectIsReadOnly);
  const ulFileNumberValues = useSelector(selectULFileNumber);
  const manufacturerFromCertificate = useSelector(
    selectManufacturerFromCertificate
  );

  const gPICustomerInputValidationResultValues = useSelector(
    selectGPICustomerInputValidationResult
  );

  const existingCertDetails = useSelector(selectExistingCertDetails);

  useEffect(() => {
    if (manufacturerInformationValues.isSameAsApplicant) {
      dispatch(
        updateManufacturerInformation({
          ...manufacturerInformationValues,
          ...getApplicantInformationValuesCopy(),
        })
      );
    }
  }, [applicantInformationValues]);

  const getApplicantInformationValuesCopy = () => {
    const applicantInformation = { ...applicantInformationValues };

    // Don't copy several properties enlisted.
    const {
      id,
      contactId,
      gPIApplicantInfoId,
      applicantInfo,
      isSent,
      companyId,
      ...rest
    } = applicantInformation;

    return rest;
  };

  const customErrors = gPICustomerInputValidationResultValues?.errors?.filter(
    (e) => e.entity === "Manufacturer"
  );

  const handleChangeIsSameAsApplicant = (checked) => {
    if (checked) {
      dispatch(
        updateManufacturerInformation({
          ...getApplicantInformationValuesCopy(),
          companyId: manufacturerInformationValues.companyId,
          isSameAsApplicant: true,
        })
      );
      dispatch(updateIsSaveDisabled(false));
    } else {
      if (
        ulFileNumberValues.hasExistingULFileNumber ||
        ulFileNumberValues.isULCBCertificateNumber ||
        ulFileNumberValues.isOtherGMACertificate
      ) {
        dispatch(updateManufacturerInformation(manufacturerFromCertificate));
      } else {
        dispatch(
          updateManufacturerInformation({
            ...manufacturerInformationValues,
            ...customerInformationInitialState,
            isSameAsApplicant: false,
          })
        );
      }
    }
  };

  const handleChangeCustomerInformation = (customerInformation) => {
    dispatch(
      updateManufacturerInformation({
        ...manufacturerInformationValues,
        ...customerInformation,
      })
    );
  };

  const handleDataDiscrepancyReplace = () => {
    dispatch(updateManufacturerIsSameAsApplicant(false));
  };

  return (
    <div className="form-section">
      <div className="form-section-header">
        <h3 className="form-section-title">
          Manufacturer Information
          <small>
            ( Company that has full responsibility for continued compliance of
            the product and undertakes all compliance obligations of the product
            certification. Typically Brand Owner. )
          </small>
        </h3>
      </div>

      <div className="form-section-content">
        <div className="question-w-checkbox-container">
          <Checkbox
            checked={manufacturerInformationValues.isSameAsApplicant}
            onChange={({ checked }) => handleChangeIsSameAsApplicant(checked)}
            inputId="cbIsSameAsApplicant"
            disabled={isReadOnly}
          />
          <label htmlFor="cbIsSameAsApplicant">
            Manufacturer same as Applicant
          </label>
        </div>

        <CustomerInformation
          onDataDiscrepancyReplace={handleDataDiscrepancyReplace}
          disableIntegration={disableIntegration}
          onChange={handleChangeCustomerInformation}
          customerInformation={manufacturerInformationValues}
          mainCustomerInfoDisabled={
            manufacturerInformationValues.isSameAsApplicant
          }
          customErrors={customErrors}
          discrepancyCustomerInformation={getManufacturerFromCertificateDetails(
            existingCertDetails,
            manufacturerInformationValues
          )}
          entity={"Manufacturer"}
          section="manufacturer"
        />
      </div>
    </div>
  );
};

export default ManufacturerInformation;
