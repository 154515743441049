import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tooltip } from "primereact/tooltip";
import { useRef, useState } from "react";
import useToastr from "../../../../../hooks/useToastr";
import ConfirmationModalV2 from "../../../../../shared/confirmation-modal-v2/ConfirmationModalV2";
import { TestReportMatchedFoundFileModel } from "../../../../../shared/models/TestReportMatchedFoundFile.model";
import { copyText } from "../../../../../utils/helpers/clipboard.helpers";
import classes from "./TestReportMatchedFoundPopup.module.scss";
import { saveAs } from "file-saver";
import { CertificateDocumentStatus } from "../../../../../enums/CertificateDocumentStatus";
import {
  ecmTestReportDuplicateModalTitle,
  ecmTestReportFoundInfo,
} from "../../../../../utils/constants/ecm.constants";

interface TestReportMatchedFoundPopupProps {
  visible: boolean;
  onClose: () => void;
  onProceedWithDuplicate: () => void;
  onProceedWithoutDuplicate: () => void;
  rows: TestReportMatchedFoundFileModel[] | undefined;
}

const TestReportMatchedFoundPopup = (
  props: TestReportMatchedFoundPopupProps
) => {
  const [selectedRow, setSelectedRow] =
    useState<TestReportMatchedFoundFileModel | null>(null);
  const { showInfo } = useToastr();
  const [tooltipEcmNodeId, setTooltipEcmNodeId] = useState("Click to Copy");

  const op = useRef<OverlayPanel>(null);

  const associatedCertificateNumberTemplate = (
    rowData: TestReportMatchedFoundFileModel
  ) => {
    if (!rowData) return <></>;

    if (!rowData.associatedCertificates) return <></>;

    const certificateNumbers = rowData.associatedCertificates
      .map((certificate) => certificate.certificateNumber)
      .join(", ");
    return (
      <div className={classes["certificate-numbers-container"]}>
        {rowData.associatedCertificates.length > 0 ? (
          <>
            <label>{certificateNumbers}</label>
            <Button
              onClick={(e) => {
                setSelectedRow(rowData);
                op.current?.toggle(e);
              }}
              className={`${classes["btn-certificate-numbers-info"]}`}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Button>
          </>
        ) : (
          <label>
            <i>No associated certificate for this Test Report.</i>
          </label>
        )}
      </div>
    );
  };

  const fileNameTemplate = (rowData: TestReportMatchedFoundFileModel) => {
    const ecmDocumentDetailsLink = `${process.env.REACT_APP_ECM_BASE_URL}/otcs/llisapi.dll/app/nodes/${rowData.ecmNodeId}/metadata/navigator`;

    return (
      <a
        className={classes["file-name-ecm-link"]}
        href={ecmDocumentDetailsLink}
        target="blank"
      >
        {rowData.name}
      </a>
    );
  };

  const ecmTicNodeIdTemplate = (rowData: TestReportMatchedFoundFileModel) => {
    if (!rowData) return <></>;

    return (
      <>
        {rowData.ecmNodeId && (
          <div className="tooltip-ecmfilenode-target">
            <a
              onClick={() => {
                copyText(rowData.ecmNodeId);
                setTooltipEcmNodeId("Copied!");
              }}
              onMouseOver={() => setTooltipEcmNodeId("Click to Copy")}
            >
              {rowData.ecmNodeId}
            </a>

            <Tooltip
              target=".tooltip-ecmfilenode-target"
              className="custom-tooltip"
              position="right"
            >
              <div>{tooltipEcmNodeId}</div>
            </Tooltip>
          </div>
        )}
      </>
    );
  };

  const bodyTemplate = (
    <>
      <div className={classes["description"]}>
        {ecmTestReportDuplicateModalTitle}
      </div>

      <DataTable
        value={props.rows}
        scrollable={true}
        scrollHeight="330px"
        style={{ maxWidth: "100%", width: "100%" }}
      >
        <Column body={fileNameTemplate} header="Document File Name" />
        <Column body={ecmTicNodeIdTemplate} header="ECM TIC Node ID" />

        <Column
          style={{ width: "300px" }}
          body={associatedCertificateNumberTemplate}
          header="Associated Certificate Number"
        />
      </DataTable>
    </>
  );

  const handleClose = () => {
    showToastNotification();
    props.onClose();
  };

  const showToastNotification = () => {
    showInfo("Test Report Match Found", ecmTestReportFoundInfo);
  };

  return (
    <>
      <ConfirmationModalV2
        buttonNoDisplay="No"
        buttonYesDisplay="Yes"
        className={classes["dialog"]}
        confirmationMessageHTML={bodyTemplate}
        headerDisplay="Test Report Match Found"
        onNegative={() => {
          showToastNotification();

          props.onProceedWithoutDuplicate();
        }}
        onPositive={props.onProceedWithDuplicate}
        onClose={handleClose}
        style={{ width: "70vw" }}
        visible={props.visible}
      />

      <OverlayPanel
        className={classes["associated-certificate-numbers--panel"]}
        ref={op}
      >
        <div className={classes["overlay-panel--content"]}>
          <div className={classes["associated-certificate-number--title"]}>
            Associated Certificate Number
          </div>

          <div className={classes["associated-certificate-number--list"]}>
            {selectedRow?.associatedCertificates.map((certificate) => (
              <div key={certificate.certificateNumber}>
                {certificate.certificateNumber}
              </div>
            ))}
          </div>
        </div>
      </OverlayPanel>
    </>
  );
};

export default TestReportMatchedFoundPopup;
