import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrencies,
  updateCurrencies,
} from "../features/projectManagementPmr/projectManagementPmrSlice";
import CurrencyService from "../services/CurrencyService";
import { useState } from "react";

export default function usePmrCurrency() {
  const currencies = useSelector(selectCurrencies);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const loadCurrencies = async () => {
    if (currencies.length > 0) return;

    try {
      setIsFetching(true);
      const response = await CurrencyService.getAllCurrency();
      if (!response?.isSuccess) {
        console.warn(response.message);
        return;
      }

      dispatch(updateCurrencies(response.data));
    } catch (err) {
      console.warn(err);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    loadCurrencies,
    isFetching,
  };
}
