import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  copyPartyInformation: false,
  value: {
    selectedProjectDetails: null,
    lineDetails: [],
    orderLineNumbers: [],
    selectedOrderLineNumber: null,
  },
  certDetails: {
    formValues: null,
    flatAttributeList: null,
    standardOptions: null,
    standardValue: null,
    standardSearch: null,
    productCategoryOptions: null,
    productCategoryStandards: null,
  },
  productDetails: {
    modelList: null,
    remarks: null,
    attachments: null,
  },
  modelDetails: {
    partySiteNumber: null,
    productType: null,
    isDisable: false,
  },
  modelList: [],
  modelsProductStandardsUpdates: [],
  certificateNumber: null,
  partySiteNumber: null,
  autoTimestampTask: [],
  isCertNumberOnMaxlength: false,
  certificateScheme: null,
  hasExistingModels: false,
  productOwner: null,
  hasEmptyProductStandardRow: true,
  hasCertificateProductStandard: true,
};

export const createCertificateSlice = createSlice({
  name: "createCertificate",
  initialState,
  reducers: {
    updateCopyPartyInformation: (state, action) => {
      state.copyPartyInformation = action.payload;
    },
    updateSelectedProjectDetails: (state, action) => {
      state.value.selectedProjectDetails = action.payload;
    },
    updateLineDetails: (state, action) => {
      state.value.lineDetails = action.payload;
    },
    updateOrderLineNumbers: (state, action) => {
      state.value.orderLineNumbers = action.payload;
    },
    updateSelectedOrderLineNumber: (state, action) => {
      state.value.selectedOrderLineNumber = action.payload;
    },
    updateCertDetailsFormValues: (state, action) => {
      state.certDetails.formValues = action.payload;
    },
    updateCertDetailsFlatAttrList: (state, action) => {
      state.certDetails.flatAttributeList = action.payload;
    },
    updateProdDetailsModelList: (state, action) => {
      state.productDetails.modelList = action.payload;
    },
    updateProdDetailsRemarks: (state, action) => {
      state.productDetails.remarks = action.payload;
    },
    updateProdDetailsAttachments: (state, action) => {
      state.productDetails.attachments = action.payload;
    },
    updateProductCategoryOptions: (state, action) => {
      state.certDetails.productCategoryOptions = action.payload;
    },
    updateStandardValues: (state, action) => {
      state.certDetails.standardValue = action.payload;
    },
    updateStandardSearch: (state, action) => {
      state.certDetails.standardSearch = action.payload;
    },
    updateStandardOptions: (state, action) => {
      state.certDetails.standardOptions = action.payload;
    },
    updateProductCategoryStandards: (state, action) => {
      state.certDetails.productCategoryStandards = action.payload;
    },
    updateModelDetails: (state, action) => {
      state.modelDetails = action.payload;
    },
    updateCertificateNumber: (state, action) => {
      state.certificateNumber = action.payload;
    },
    updatePartySiteNumber: (state, action) => {
      state.partySiteNumber = action.payload;
    },
    updateAutoTimestampTask: (state, action) => {
      state.autoTimestampTask = action.payload;
    },
    updateIsCertNumberOnMaxlength: (state, action) => {
      state.isCertNumberOnMaxlength = action.payload;
    },
    updateCertificateScheme: (state, action) => {
      state.certificateScheme = action.payload;
    },
    updateModelList: (state, action) => {
      state.modelList = action.payload;
    },
    patchModelListItem: (state, action) => {
      state.modelList = state.modelList.map((model) => ({
        ...model,
        ...(model.assetId === action.payload.assetId
          ? action.payload.patch
          : {}),
      }));
    },
    updateHasExistingModels: (state, action) => {
      state.hasExistingModels = action.payload;
    },
    updateProductOwner: (state, action) => {
      state.productOwner = action.payload;
    },
    appendProductStandards: (state, action) => {
      const newProductStandards = state.certDetails.formValues.standard || [];

      action.payload?.forEach((code) => {
        if (!newProductStandards.includes(code)) newProductStandards.push(code);
      });

      state.certDetails.formValues = {
        ...state.certDetails.formValues,
        standard: [...new Set(newProductStandards)],
      };
    },
    setModelsProductStandardsUpdates: (state, action) => {
      state.modelsProductStandardsUpdates = action.payload;
    },
    updateHasEmptyProductStandardRow: (state, action) => {
      state.hasEmptyProductStandardRow = action.payload;
    },
    updateHasCertificateProductStandard: (state, action) => {
      state.hasCertificateProductStandard = action.payload;
    },
  },
});

export const {
  updateCopyPartyInformation,
  updateSelectedProjectDetails,
  updateLineDetails,
  updateOrderLineNumbers,
  updateSelectedOrderLineNumber,
  updateCertDetailsFormValues,
  updateCertDetailsFlatAttrList,
  updateProdDetailsModelList,
  updateProdDetailsRemarks,
  updateProdDetailsAttachments,
  updateProductCategoryOptions,
  updateStandardValues,
  updateStandardSearch,
  updateStandardOptions,
  updateModelDetails,
  updateCertificateNumber,
  updatePartySiteNumber,
  updateAutoTimestampTask,
  updateIsCertNumberOnMaxlength,
  updateCertificateScheme,
  updateModelList,
  updateHasExistingModels,
  updateProductOwner,
  appendProductStandards,
  setModelsProductStandardsUpdates,
  updateHasEmptyProductStandardRow,
  updateProductCategoryStandards,
  updateHasCertificateProductStandard
} = createCertificateSlice.actions;

export const selectCopyPartyInformation = (state) =>
  state.createCertificate.copyPartyInformation;
export const selectValues = (state) => state.createCertificate.value;
export const selectCertDetailValues = (state) =>
  state.createCertificate.certDetails;
export const selectProdDetailValues = (state) =>
  state.createCertificate.productDetails;
export const selectProdDetailAttachmentValues = (state) =>
  state.createCertificate.productDetails?.attachments;
export const selectModelDetailValues = (state) =>
  state.createCertificate.modelDetails;
export const selectCertificateNumber = (state) =>
  state.createCertificate.certificateNumber;
export const selectPartySiteNumber = (state) =>
  state.createCertificate.partySiteNumber;
export const selectOwnerReference = (state) =>
  state.createCertificate.certDetails?.formValues?.ownerReference;
  export const selectCertDetailFormValues = (state) =>
  state.createCertificate.certDetails?.formValues;
export const selectAutotimestampTask = (state) =>
  state.createCertificate.autoTimestampTask;
export const selectIsCertNumberOnMaxlength = (state) =>
  state.createCertificate.isCertNumberOnMaxlength;
export const selectCertificateScheme = (state) =>
  state.createCertificate.certificateScheme;
export const selectModelList = (state) => state.createCertificate.modelList;
export const selectHasExistingModels = (state) =>
  state.createCertificate.hasExistingModels;
export const selectProductOwner = (state) =>
  state.createCertificate.productOwner;
export const selectModelsProductStandardsUpdates = (state) =>
  state.createCertificate.modelsProductStandardsUpdates;
export const selectStandardValue = (state) =>
  state.createCertificate.certDetails.standardValue;
export const selectStandardSearch = (state) =>
  state.createCertificate.certDetails.standardSearch;
export const selectStandardOptions = (state) =>
  state.createCertificate.certDetails.standardOptions;
export const selectProductCategoryStandards = (state) =>
  state.createCertificate.certDetails.productCategoryStandards;
export const selectHasEmptyProductStandardRow = (state) =>
  state.createCertificate.hasEmptyProductStandardRow;
export const selectHasCertificateProductStandard = (state) =>
  state.createCertificate.hasCertificateProductStandard;

export default createCertificateSlice.reducer;
