import * as yup from "yup";

const customerInformationSchema = yup.object({
  partySiteNumber: yup.string(),
  companyName: yup.string().required(),
  streetAddress: yup.string().required(),
  city: yup.string().required(),
  country: yup.string().required(),
  zipCode: yup.string().required(),
  stateOrProvince: yup.string().required(),
  contactPerson: yup.string().required(),
  phone: yup.string().required(),
  title: yup.string().nullable().required(),
  email: yup.string().email("Please provide a valid email address").required(),
  website: yup
    .string()
    .nullable()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|^$/,
      "Please provide a valid website address or link"
    ),
  directorName: yup.string().nullable(),
  fax: yup.string().nullable(),
});

export default customerInformationSchema;
