import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import ProductTypeService from "../../../services/ProductTypeService";
import classes from "./CustomerNameLookup.module.scss";
import "./CustomerNameLookup.scss";

const CustomerNameLookup = (props) => {
  const [partySiteNumberInput, setPartySiteNumberInput] = useState("");
  const [customerNameInput, setCustomerNameInput] = useState("");
  const [oracleAccountNumberInput, setOracleAccountNumberInput] = useState("");

  const [currentRowSelected, setCurrentRowSelected] = useState("");
  const { onCurrentRowSelected, tableHeight, containerHeight } = props;

  const [isLoadingCustomerList, setIsLoadingCustomerList] = useState(false);
  const [customerLookupList, setCustomerLookupList] = useState([]);
  const [firstRow, setFirstRow] = useState(0);
  const [rows, setRows] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);

  const handleCurrentRowSelected = (rowValue) => {
    setCurrentRowSelected(rowValue);
    onCurrentRowSelected && onCurrentRowSelected(rowValue);
  };

  const onChangeCustomerName = (e) => {
    setCustomerNameInput(e.target.value);
  };

  const onChangePartySiteNumber = (e) => {
    setPartySiteNumberInput(e.target.value);
  };

  const onChangeOracleAccountNo = (e) => {
    setOracleAccountNumberInput(e.target.value);
  };

  const onCustomPage = (event) => {
    setFirstRow(event.first);
    setRows(event.rows);
    setCurrentPage(event.page + 1);
  };

  const handleSearchInputEnter = (e) => {
    if (e.key === "Enter") searchCustomer();
  };

  const searchCustomer = async () => {
    setIsLoadingCustomerList(true);
    const result = await ProductTypeService.getCompanyList(
      customerNameInput.trim(),
      partySiteNumberInput.trim(),
      oracleAccountNumberInput.trim()
    );

    let filtered = result.data
      ? result.data.filter((p) => p.accountNumber || p.partyNumber)
      : [];

    setCustomerLookupList(filtered);

    setCurrentPage(filtered.length == 0 ? 0 : 1);

    setIsLoadingCustomerList(false);
  };

  const clearAllInputs = () => {
    setPartySiteNumberInput("");
    setCustomerNameInput("");
    setOracleAccountNumberInput("");
    handleCurrentRowSelected("");
    setCustomerLookupList([]);
    setCurrentPage(0);
  };

  const columns = [
    {
      id: "partyName",
      value: "Customer Name",
      width: "30%",
    },
    {
      id: "partySiteNumber",
      value: "Party Site Number",
      width: "180px",
    },
    {
      id: "accountNumber",
      value: "Oracle Account Number",
      width: "225px",
    },
    {
      id: "customerDetails",
      value: "Customer Details",
      width: "calc(70% - 405px)",
    },
  ];

  const dynamicColumns = columns.map((col, i) => {
    return (
      <Column
        key={col.id}
        columnKey={col.id}
        field={col.id}
        header={col.value}
        style={{ width: col.width }}
      />
    );
  });

  const paginatorLeft = () => {
    const dropdownOptions = [
      { label: "20", value: 20 },
      { label: "50", value: 50 },
      { label: "100", value: 100 },
      { label: "200", value: 200 },
    ];

    const allRows = customerLookupList.length;

    // if current page is set to blank and list is not empty, display 1.
    var currentPage2 =
      !currentPage && customerLookupList.length > 0 ? 1 : currentPage;

    const firstRow = (currentPage2 - 1) * rows + 1;
    const lastRow =
      currentPage2 * rows > customerLookupList.length
        ? customerLookupList.length
        : currentPage2 * rows;
    let pageDetailsText = "";

    if (allRows === 0) pageDetailsText = "0 to 0 of 0.";
    else pageDetailsText = firstRow + " to " + lastRow + " of " + allRows + ".";

    return (
      <div className="d-flex" style={{ alignItems: "center" }}>
        <span
          className="mx-1"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          Items per page:
        </span>
        <Dropdown
          value={rows}
          options={dropdownOptions}
          onChange={({ value }) => setRows(value)}
        />
        <label style={{ marginLeft: "10px" }}>{pageDetailsText}</label>
      </div>
    );
  };

  const getTotalPages = (totalRecords, rows) => {
    return Math.ceil(totalRecords / rows);
  };

  const paginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink",
    CurrentPageReport: (options) => {
      return (
        <span
          className="mx-3"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          <InputText
            disabled={customerLookupList.length == 0}
            style={{ width: "55px", textAlign: "center", marginRight: "10px" }}
            value={currentPage}
            onKeyDown={(e) => handlePageInputKeyEvents(e, options)}
            onKeyUp={(e) => handlePageInputKeyEvents(e, options)}
            onChange={(e) => handlePageInputKeyEvents(e, options)}
          />
          of {getTotalPages(options.totalRecords, rows)}
        </span>
      );
    },
  };

  const handlePageInputKeyEvents = (e, options) => {
    const isValidValue =
      e.target.value > 0 && e.target.value <= options.totalPages;
    const validKeyCodes = [46, 8]; // delete, backspace

    if (!isValidValue && !validKeyCodes.includes(e.keyCode) && e.target.value) {
      e.preventDefault();
      return;
    }

    setCurrentPage(e.target.value);
    const first = e.target.value ? options.rows * (e.target.value - 1) : 0;
    setFirstRow(first);
  };

  return (
    <div
      style={{ height: containerHeight || "unset" }}
      className={classes["customer-name-lookup-container"]}
    >
      <div>Please enter your search criteria below.</div>
      <br />
      <div className={`ul-grid__row`} style={{ marginLeft: 0, width: "100%" }}>
        <div className="ul-grid__column -x25 d-flex p-0">
          <div className="w-90">
            <div>Customer Name</div>
            <div>
              <InputText
                onKeyDown={handleSearchInputEnter}
                className="w-100"
                value={customerNameInput}
                onChange={onChangeCustomerName}
                disabled={
                  partySiteNumberInput !== "" || oracleAccountNumberInput !== ""
                }
              />
            </div>
          </div>
          <div className="text-center w-10">
            <div>&nbsp;</div>
            <div style={{ marginTop: "10px" }}>OR</div>
          </div>
        </div>

        <div className="ul-grid__column -x25 d-flex p-0">
          <div className="w-90">
            <div>Party Site Number</div>
            <div>
              <InputText
                className="w-100"
                value={partySiteNumberInput}
                onKeyDown={handleSearchInputEnter}
                onChange={onChangePartySiteNumber}
                disabled={
                  customerNameInput !== "" || oracleAccountNumberInput !== ""
                }
              />
            </div>
          </div>
          <div className="text-center w-10">
            <div>&nbsp;</div>
            <div style={{ marginTop: "10px" }}>OR</div>
          </div>
        </div>

        <div className="ul-grid__column -x30 d-flex p-0">
          <div className="w-90">
            <div>Oracle Account Number</div>
            <div>
              <InputText
                onKeyDown={handleSearchInputEnter}
                className="w-100"
                disabled={
                  customerNameInput !== "" || partySiteNumberInput !== ""
                }
                value={oracleAccountNumberInput}
                onChange={onChangeOracleAccountNo}
              />
            </div>
          </div>
        </div>

        <div className={`ul-grid__column -x15 ${classes["search-btns-div"]}`}>
          <button
            onClick={clearAllInputs}
            disabled={
              (customerNameInput === "" &&
                partySiteNumberInput === "" &&
                oracleAccountNumberInput === "") ||
              isLoadingCustomerList
            }
            className={`ul-button -app-secondary -medium float-left clear-btn ${classes["clear-btn"]}`}
          >
            CLEAR
          </button>
          <button
            className={`ul-button -medium float-left ${classes["search-btn"]}`}
            disabled={
              (customerNameInput === "" &&
                partySiteNumberInput === "" &&
                oracleAccountNumberInput === "") ||
              isLoadingCustomerList
            }
            onClick={searchCustomer}
          >
            SEARCH
          </button>
        </div>
      </div>

      <br />
      <div
        className={classes["table-container"]}
        style={{ height: tableHeight }}
      >
        <DataTable
          value={customerLookupList}
          paginator
          paginatorTemplate={paginatorTemplate}
          paginatorLeft={paginatorLeft}
          first={firstRow}
          rows={rows}
          onPage={onCustomPage}
          responsiveLayout="scroll"
          scrollable
          loading={isLoadingCustomerList}
          removableSort
          scrollDirection="both"
          columnResizeMode="expand"
          dataKey="partySiteNumber"
          selectionMode="single"
          selection={currentRowSelected}
          onSelectionChange={(e) => handleCurrentRowSelected(e.value)}
        >
          {dynamicColumns}
        </DataTable>
      </div>
    </div>
  );
};

export default CustomerNameLookup;
