export interface IProjectManagementViewByOption {
  name: string;
  code: number;
}
export const projectManagementViewByOptions: IProjectManagementViewByOption[] =
  [
    // {
    //   code: 1,
    //   name: `Applicant`,
    // },
    {
      code: 2,
      name: `GMAWB Project`,
    },
    // {
    //   code: 3,
    //   name: `Project/Order Number`,
    // },
  ];

export const refinerRequestName = "project-template-request";
