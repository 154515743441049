import React, { useState } from "react";

// Example:
// const components = [
//     {
//       headerName: "Details",
//       components: (
//         <>
//           <Details />
//           <LinkedProducts />
//           <Remarks />
//           <Attachments />
//         </>
//       ),
//     },
//     { headerName: "Revision History", components: <RevisionHistory /> },
//   ];
const ULTabs = ({ components, containerClassName, contentClassName }) => {
  const [tab, setTab] = useState(0);
  return (
    <div className={`ul-tabs__container ${containerClassName}`}>
      <div className="ul-tabs -boxed -medium">
        {components.map((c, i) => (
          <div
            key={c.headerName}
            className={`ul-tabs__item ${tab === i ? "-active" : ""}`}
            onClick={() => setTab(i)}
          >
            <span>{c.headerName}</span>
          </div>
        ))}
      </div>
      <div className={`ul-tabs__content ${contentClassName}`}>
        {components[tab].components}
      </div>
    </div>
  );
};

export default ULTabs;
