import clsx from "clsx";
import classes from "./ProjectTaskStatusTemplate.module.scss";
import ProjectTaskStatus from "../../enums/project-task-status";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
interface ProjectTaskStatusTemplateProps {
  status: ProjectTaskStatus;
  tooltip?: string;
  isLoading?: boolean;
}
const ProjectTaskStatusTemplate = (props: ProjectTaskStatusTemplateProps) => {
  const getDisplay = () => {
    switch (props.status) {
      case ProjectTaskStatus.NotStarted:
      case ProjectTaskStatus.AwaitingAssignment:
      case ProjectTaskStatus.NotScheduled:
        return "Not Scheduled";
      case ProjectTaskStatus.InProgress:
        return "In Progress";
      case ProjectTaskStatus.OnHold:
        return "On Hold";
      case ProjectTaskStatus.Cancelled:
        return "Cancelled";
      case ProjectTaskStatus.Completed:
        return "Completed";
      default:
        return "Not defined";
    }
  };

  const getClass = () => {
    switch (props.status) {
      case ProjectTaskStatus.NotStarted:
      case ProjectTaskStatus.AwaitingAssignment:
      case ProjectTaskStatus.NotScheduled:
        return classes["notscheduled"];
      case ProjectTaskStatus.InProgress:
        return classes["inprogress"];
      case ProjectTaskStatus.OnHold:
        return classes["onhold"];
      case ProjectTaskStatus.Cancelled:
        return classes["cancelled"];
      case ProjectTaskStatus.Completed:
        return classes["completed"];
      default:
        return "disabled";
    }
  };

  const loadingIndicator = (
    <div className="text-center">
      <FontAwesomeIcon icon={faSpinner} spin={true} />
    </div>
  );

  if (props.isLoading) return loadingIndicator;

  return (
    <span
      title={props.tooltip}
      className={clsx(classes["custom-badge"], getClass())}
    >
      {getDisplay()}
    </span>
  );
};
export default ProjectTaskStatusTemplate;
