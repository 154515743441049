export const sortList = (list, sortField, sortOrder) => {
  if (list.length == 0) return;

  if (!sortField || !sortOrder) return list;
  let sortedData = [];

  const isFieldNumerical = () =>
    list.every((x) => x[sortField]?.length == 0 || !isNaN(x[sortField]));

  const isFieldDate = () =>
    list.every(
      (x) =>
        !x[sortField] ||
        (new Date(x[sortField]) != "Invalid Date" && isNaN(x[sortField]))
    );

  if (isFieldNumerical()) {
    sortedData = [...list].sort((a, b) => {
      const toNum = (value) => {
        return isNaN(value) ? 0 : parseInt(value);
      };

      const x = toNum(a[sortField]);
      const y = toNum(b[sortField]);

      return sortOrder == 1 ? x - y : y - x;
    });
  } else if (isFieldDate()) {
    sortedData = [...list].sort((a, b) => {
      const toDate = (value) => {
        return new Date(value) == "Invalid Date"
          ? new Date(0)
          : new Date(value);
      };

      const x = toDate(a[sortField]);
      const y = toDate(b[sortField]);

      return sortOrder == 1 ? x - y : y - x;
    });
  } else {
    sortedData = [...list].sort((a, b) => {
      return sortOrder == 1
        ? a[sortField]?.localeCompare(b[sortField])
        : b[sortField]?.localeCompare(a[sortField]);
    });
  }

  return sortedData;
};

export const getCsvFormatWithoutQuotes = (param) => {
  let arrayStringified = JSON.stringify(param);
  let bracketsRemoved = arrayStringified.substring(
    1,
    arrayStringified.length - 1
  );
  return bracketsRemoved.split('"').join("").split("'").join("");
};

function objectsAreEqual(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function moveItem(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

export const distinctArrayObject = (list) => {
  if (!list) return list;

  const distinctList = list.filter((value, index) => {
    for (let i = 0; i < index; i++) {
      if (objectsAreEqual(value, list[i])) {
        return false;
      }
    }

    return true;
  });

  return distinctList;
};
