import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import classes from "./CDPCloseModal.module.scss";

export interface CDPCloseModalProps {
  showCdpCloseModal: boolean;
  setShowCloseModal: Function;
  isSaveDisable: boolean;
  handleCreateProject: Function;
  closeCPDModal(isFromCancel: boolean): void;
  hasBlankDetails: boolean;
  hasMissingTechnology: boolean;
}

const CDPCloseModal = (props: CDPCloseModalProps) => {
  const confirmationHeader = () => {
    return "Unsaved Changes Confirmation";
  };

  const onClickNoButton = () => {
    props.setShowCloseModal(false);
  };

  const onClickYesButton = () => {
    props.setShowCloseModal(false);
    props.closeCPDModal(true);
  };

  const closeFooterTemplate = () => {
    return (
      <>
        <hr />
        <div className="ul-grid__column">
          <div className="ul-grid__row">
            <div className="ul-grid__column">
              <Button
                className={classes["yes-button"]}
                onClick={() => onClickYesButton()}
              >
                YES
              </Button>
            </div>
            <div className="ul-grid__column">
              <Button
                className={classes["no-button"]}
                onClick={() => onClickNoButton()}
              >
                NO
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const cdpCloseModalTemplate = () => {
    return (
      <>
        <hr />
        <div className={`ul-grid__column ${classes["content"]}`}>
          <div className="ul-grid__column">
            <p className="p-mb-5">
              You have updated information that has not been saved.
              <br /> Any unsaved changes will be lost.
            </p>
            <p>Would you like to continue?</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <Dialog
      id="modalDialog"
      header={confirmationHeader}
      style={{ width: "700px" }}
      visible={props.showCdpCloseModal}
      onHide={() => props.setShowCloseModal(false)}
      footer={closeFooterTemplate}
      closeOnEscape
      className={`${classes["dialog-close-button"]}`}
    >
      {cdpCloseModalTemplate()}
    </Dialog>
  );
};

export default CDPCloseModal;
