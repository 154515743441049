export const existingFileNumberHelpText =
  "Input E-File # here: For example E123456 as shown on your existing UL report.";

export const volumeNumberHelpText = "Select the applicable Volume Number";

export const sectionNumberHelperText = "Select the applicable Section Number";

export const ulcbCertificateNumberHelperText =
  "Enter the UL Issued CB Certificate Number (Example: CA-11111, DK-11111, JP-11111, US-11111)";

export const certificateSchemeHelperText =
  "Select an applicable Scheme associated with the product. The certification scheme selected should be either issued by UL (i.e. Japan - PSE Mark, Japan - JRL) or the UL Global Market Access team facilitated the issuance of the certificate via our GMA Services (i.e. China - CCC, China - SRRC).";

export const certificateNumberHelperText =
  "Enter in the Global Market Access (GMA) Scheme Name and Certificate Number for a UL issued or facilitated certification (Example: UL-Argentina S Mark Certificate 22AR11111.1, Argentina ENACOM Certificate C-11111)";

export const customerChangeUpdateMsg =
  "The Customer has made updates to this field that does not align with the original project details and/or with the Existing Certification details.";

export const customerChangeConfirmationMsg =
  "Please confirm these changes with the Customer. Select or click if you want to:";

export const customerChangeReplaceMsg =
  "REPLACE (this will overwrite customer data)";

export const customerChangeKeepMsg = "KEEP (this will keep the customer data)";

export const defaultValuesUpdateMsg =
  "Initial/Default values has been updated due to updating of Existing Certification details.";

export const defaultValuesConfirmationMsg =
  "Please confirm if these changes. Select or click if you want to:";

export const defaultValuesReplaceMsg =
  "REPLACE (this will overwrite initial/default data)";

export const defaultValuesKeepMsg = "KEEP (this will keep the retain the data)";

export const notExistingInWorkBenchErrorMessage =
  "The information entered by the customer is not a valid certificate number.  Please confirm with the customer or replace with the original (previous) information.";

export const bothFileNumberExistErrorMessage =
  '"UL File Number / Volume modified by the Customer" Re-enter File Number to replace all Manufacturer, Factory and Model as needed.';

export const ulcbCertificateErrorMessage =
  '"UL CB Certificate Number modified by the Customer" Re-enter Certificate Number to replace all Manufacturer, Factory and Model as needed.';

export const otherGmaCerticateErrorMessage =
  '"Other GMA Certificate modified by the Customer" Re-enter Certificate Number to replace all Manufacturer, Factory and Model as needed.';

export const defaultErrorMessage =
  "Data provided above does not align with the WB value. Please confirm with the Customer if the data is correct.";

export const fileNumberErrorMessage = 
"The Customer has modified the UL File Number / Volume details. To sync with Workbench, please reenter the UL File Number / Volume. This sync may impact the Manufacturer, Factory and/or Model details entered by the customer."
  
export const uLCBCertErrorMessage =
"The Customer has modified the UL CB Certificate Number details. To sync with Workbench, please reenter the UL CB Certificate Number. This sync may impact the Manufacturer, Factory and/or Model details entered by the customer."

export const otherGmaCertErrorMessage = 
"The Customer has modified the Other GMA Certificate details. To sync with Workbench, please reenter the Other GMA Certificate. This sync may impact the Manufacturer, Factory and/or Model details entered by the customer.";

export const existingCertificateNoteMessage = "NOTE: Any discrepancies between the Workbench Certification Record and Customer entered data will be noted after the sync has completed.";

export const invalidExistingCertificationMessage = "Existing Certification Data is incorrect";

