import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCaretDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import classes from "./Attachments.module.scss";

const initialFiles = [
  {
    id: 1,
    display: "Certificate",
    attachments: [
      {
        id: 111,
        fileName: "UWP-D Certificate.docx",
        link: "google.com",
      },
      {
        id: 222,
        fileName: "Test-D Certificate.docx",
        link: "google.com",
      },
    ],
    isExpand: false,
  },
  {
    id: 2,
    display: "Test Reports",
    attachments: [
      {
        id: 333,
        fileName: "Test1.docx",
        link: "google.com",
      },
    ],
    isExpand: false,
  },
  {
    id: 3,
    display: "Others",
    attachments: [],
    isExpand: false,
  },
];

const Attachments = () => {
  const [files, setFiles] = useState(initialFiles);

  const onExpand = (item) => {
    item.isExpand = !item.isExpand;
    const updatedFiles = files.map((f) => (f.id === item.id ? item : f));
    setFiles(updatedFiles);
  };

  return (
    <div className="pt-10">
      <div>Attachments</div>
      {files.map((f) => (
        <div key={f.id}>
          <div onClick={() => onExpand(f)} className={classes["container"]}>
            <span>{f.display}</span>
            <div>
              <FontAwesomeIcon icon={f.isExpand ? faCaretDown : faCaretRight} />
            </div>
          </div>
          {f.isExpand && f.attachments.length === 0 && <span>No Files</span>}
          {f.isExpand &&
            f.attachments.map((a) => (
              <div key={a.id} className={classes["files"]}>
                <a href={a.link} className={classes["link"]}>
                  {a.fileName}
                </a>
                <FontAwesomeIcon icon={faTrash} />
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default Attachments;
