import React from "react";
import "./Toast.scss";
import { selectValue, dismissToast } from "../features/toastr/toastrSlice";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

const Toast = () => {
  const toastrValues = useSelector(selectValue);
  const dispatch = useDispatch();
  const onClickDismissToast = (toast) => {
    dispatch(dismissToast(toast));
  };

  const getIcon = (toast) => {
    if (toast.type === "danger") return "error";
    if (toast.type === "success") return "check_circle";
    if (toast.type === "warning") return "warning";
    if (toast.type === "info") return "info";

    return "";
  };
  const createMarkup = (content) => {
    return { __html: content };
  };
  return (
    <div className="ul-toast-container">
      {toastrValues.toastList?.map((toast, i) => (
        <CSSTransition
          key={i}
          in={!toast.dismissed}
          appear={!toast.dismissed}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <div key={i} className={`ul-alert -${toast.type}`}>
            <i className="ul-alert__icon material-icons">{getIcon(toast)}</i>

            <div className="ul-alert__body">
              <p className="ul-alert__title">{toast.title}</p>
              {toast.messageTemplate ? (
                toast.messageTemplate()
              ) : toast.message ? (
                <p
                  className="ul-alert__message"
                  dangerouslySetInnerHTML={createMarkup(toast.message)}
                ></p>
              ) : (
                <></>
              )}
            </div>

            <span
              className="ul-alert__close material-icons"
              data-tooltip="Dismiss"
              data-tooltip-position="left"
              disabled={toast.dismissed}
              onClick={() => onClickDismissToast(toast)}
            >
              close
            </span>
          </div>
        </CSSTransition>
      ))}
    </div>
  );
};
export default Toast;
