import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import classes from "./Actions.module.scss";
import WithdrawCertificateModal from "../../../../../shared/withdraw-certificate-modal/WithdrawCertificateModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import useToastr from "../../../../../hooks/useToastr";
import { useDispatch } from "react-redux";
import CertificateListService from "../../../../../services/CertificateListService";
import ProjectDetailsService from "../../../../../services/ProjectDetailsService";
import {
  updateProdDetailsAttachments,
  updateProdDetailsModelList,
  selectProdDetailValues,
  updateSelectedProjectDetails,
  updateModelList,
  updateCertificateScheme,
} from "../../../../../features/createCertificate/createCertificateSlice";
import AddNewCertificate from "../../add-new-certificate/AddNewCertificate";
import {
  reloadCertificateDetails,
  reloadCertificateList,
  updateIsLoadingCertificateList,
} from "../../../../../features/certificateList/certificateListSlice";
import CopyCertificateModal from "../../../../../shared/copy-certificate-modal/CopyCertificateModal";
import AttachmentsDialog from "../Attachments/AttachmentsDialog";
import { flexProjectContactRole } from "../../../../../utils/constants/flex-project-contact-role.constants";
import CertificateDetailsAttachmentsDialog from "../certificate-details-attachments-dialog/CertificateDetailsAttachmentsDialog";
import FeatureFlagService from "../../../../../services/FeatureFlagService";
import { ConfirmDialog } from "primereact/confirmdialog";
import { CertificateDocumentType } from "../../../../../enums/CertificateDocumentType";
import { CertificateDocumentStatus } from "../../../../../enums/CertificateDocumentStatus";
import { useTestReportValidator } from "../../add-new-certificate/test-report-matched-found-popup/useTestReportValidator";
import TestReportMatchedFoundPopup from "../../add-new-certificate/test-report-matched-found-popup/TestReportMatchedFoundPopup";
import {
  ecmUnavailableHeader,
  ecmUnavailableMessage,
} from "../../../../../utils/constants/ecm.constants";
const Actions = ({
  details,
  setDetails,
  setAttachments,
  setShowCertificateDetails,
  isReadOnly,
  onDeleteCertificate,
  inputAttachments,
  certificateDetailFromE2E,
  hasPendingTestReportFailedStatus,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { showSuccess, showError, showWarning } = useToastr();
  const [isDisable, setIsDisable] = useState(false);
  const [displayAddNewCertModal, setDisplayAddNewCertModal] = useState(false);
  const [isModifyCert, setIsModifyCert] = useState(false);
  const [isOpenCopy, setIsOpenCopy] = useState(false);
  const [isOpenAttachment, setIsOpenAttachment] = useState(false);
  const [revisionNumber, setRevisionNumber] = useState("");
  const [hasRevisionNumber, setHasRevisionNumber] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const productDetailsValues = useSelector(selectProdDetailValues);
  const [isConfirmDialogShow, setIsConfirmDialogShow] = useState(false);
  const [testReportValidationOutput, setTestReportValidationOutput] =
    useState(null);

  const testReportsValidatorHook = useTestReportValidator();

  const dialogFuncMap = {
    displayAddNewCertModal: setDisplayAddNewCertModal,
  };

  const onEdit = async () => {
    setModalHeader("Edit Certificate");
    dispatch(updateCertificateScheme(details.certificateScheme));
    setIsModifyCert(false);
    await populateModels();
    populateUploads();
    dialogFuncMap["displayAddNewCertModal"](true);
  };

  const onModify = async () => {
    if (hasPendingTestReportFailedStatus) return;
    setModalHeader("Modify Certificate");
    setIsModifyCert(true);
    dispatch(updateCertificateScheme(details.certificateScheme));
    await populateModels();
    populateUploads();
    dialogFuncMap["displayAddNewCertModal"](true);
  };

  const onOpenWithdrawModal = async () => {
    dispatch(updateIsLoadingCertificateList(true));
    const result = await CertificateListService.getCertificateAttributes(
      details.certificateScheme,
      ""
    );
    dispatch(updateIsLoadingCertificateList(false));

    if (result[0]) {
      const certificateAttributes = result[0]["cert_attributes"];

      if (certificateAttributes) {
        const revision = certificateAttributes.find(
          (a) => a.attribute_name === "revisionNumber"
        );

        if (revision) {
          setHasRevisionNumber(true);
        } else {
          setHasRevisionNumber(false);
        }
      }
    }
    setRevisionNumber(details.revisionNumber);
    setIsOpen(true);
  };

  const onOpenCopy = () => {
    setIsOpenCopy(true);
  };

  const onAttach = async () => {
    dispatch(updateCertificateScheme(details.certificateScheme));

    await populateModels();
    await populateUploads();

    setIsOpenAttachment(true);
  };

  const proceedActivationWithDuplicate = async () => {
    testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
    await activateCertificate();
  };

  const onCancelActivation = async () => {
    testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
    setIsDisable(false);
  };

  const validateTestReport = async () => {
    if (
      !FeatureFlagService.getECMFeatureFlag() &&
      !FeatureFlagService.getLinkTestReportToMultipleCertificateFlag()
    )
      return;

    const testReportsAttachments = inputAttachments
      .filter(
        (attachment) =>
          attachment.documentType === CertificateDocumentType.TestReport &&
          (attachment.status ===
            CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic ||
            attachment.status === CertificateDocumentStatus.TestReportDuplicate)
      )
      .map((file) => file.originalFileName);

    const baseModel = details.linkedProducts[0].modelName;
    const certificateType = details.certificateScheme;
    const ownerReference = details.editData.find(
      (psn) => psn.attribute_name === "ownerReference"
    )?.value[0];

    if (!testReportsAttachments) return;

    const validationResult = await testReportsValidatorHook.validateTestReports(
      testReportsAttachments,
      ownerReference,
      certificateType,
      baseModel
    );

    if (validationResult.hasDuplicate) {
      testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(true);
      setTestReportValidationOutput(validationResult.matchedTestReports);
    } else {
      await activateCertificate();
    }
  };

  const onActivate = async (disabled) => {
    if (disabled) return;

    setIsDisable(true);

    if (details.certificateStatus != "Under Revision") return;

    if (
      !FeatureFlagService.getECMFeatureFlag() &&
      !FeatureFlagService.getLinkTestReportToMultipleCertificateFlag()
    ) {
      await activateCertificate();
      return;
    }

    const certDetailsResp =
      await CertificateListService.getCertificateDetailsForActivation(
        details.certificateId
      );

    if (!certDetailsResp.isSuccess) return;

    let certDetails = certDetailsResp.data;

    if (!certDetails || certDetails.certificateStatus != "Under Revision")
      return;

    if (certDetails.hasValidAttachments && certDetails.hasBaseModel) {
      setIsConfirmDialogShow(true);
    } else {
      await activateCertificate();
    }
  };

  const activateCertificate = async () => {
    const response = await CertificateListService.activateCertificate({
      certificateId: details.certificateId,
      projectNumber: details.projectNumber,
    });

    if (response.isSuccess) {
      if (FeatureFlagService.getECMFeatureFlag()) {
        await processCertificateDetailsUpdate();
      } else {
        showSuccess("Success!", "Certificate activated successfully");
        dispatch(reloadCertificateList(true));
        dispatch(reloadCertificateDetails(true));
      }
    } else {
      showError("Error!", response.message);
    }

    setIsDisable(false);
  };

  const processCertificateDetailsUpdate = async () => {
    const result =
      await CertificateListService.updateActivatedCertificateDetails(
        details.certificateId
      );
    showSuccess("Success!", "Certificate activated successfully");
    dispatch(reloadCertificateList(true));
    dispatch(reloadCertificateDetails(true));

    if (!result.isSuccess) {
      showWarning(ecmUnavailableHeader, ecmUnavailableMessage);
    }
  };

  const populateModels = async () => {
    let prevDetails = JSON.parse(JSON.stringify(details));
    if (FeatureFlagService.getECMFeatureFlag()) {
      if (certificateDetailFromE2E?.baseModel) {
        prevDetails.assets.forEach((asset) => {
          if (asset.modelName == certificateDetailFromE2E.baseModel) {
            asset.isBaseModel = true;
            return;
          }
        });
        setDetails(prevDetails);
      }
    }
    dispatch(updateProdDetailsModelList(prevDetails.assets));
    dispatch(updateModelList(prevDetails.assets));
    await loadProjectDetails();
  };

  const populateUploads = () => {
    if (FeatureFlagService.getECMFeatureFlag()) return;
    if (!details.localData?.attachments) return;

    const attachmentsMapper = (a) => ({
      fileResult: "",
      id: a.id,
      isDeleting: false,
      isRemovedFromDb: false,
      isRemovedFromUI: false,
      isUploaded: true,
      isUploading: false,
      name: a.fileName,
      uploadPercent: 100,
      isValid: true,
    });

    const certificateAttachments = details.localData?.attachments
      .filter((a) => a.attachmentType === "Certificate")
      .map(attachmentsMapper);

    const testReportAttachments = details.localData?.attachments
      .filter((a) => a.attachmentType === "Test Reports")
      .map(attachmentsMapper);

    const othersAttachments = details.localData?.attachments
      .filter((a) => a.attachmentType === "Others")
      .map(attachmentsMapper);

    const attachments = [
      certificateAttachments,
      testReportAttachments,
      othersAttachments,
    ];

    dispatch(updateProdDetailsAttachments(attachments));
  };

  const loadProjectDetails = async () => {
    let tempLineDetails = [];
    const data = await ProjectDetailsService.getProjectDetail(
      details.projectNumber
    );

    data.value.forEach((d) => {
      //Filter - Get Child Lines
      const lineDatas = d.projectServiceLines.filter(
        (l) => l.lineNumber.split(".").length === 3
      );

      if (lineDatas.length === 0) {
        return;
      }

      for (var i = 0; i < lineDatas.length; i++) {
        const projectContacts = getProjectContact(d.projectContacts);

        lineDatas[i].partySiteNumber = projectContacts.partySiteNumber;
        lineDatas[i].companyId = d.companyId;
        lineDatas[i].address = projectContacts.address;
        lineDatas[i].city = projectContacts.city;
        lineDatas[i].state = projectContacts.state;
        lineDatas[i].province = projectContacts.province;
        lineDatas[i].country = projectContacts.country;
        lineDatas[i].postalcode = projectContacts.postalCode;
        lineDatas[i].partysite = projectContacts.partySiteNumber;
        lineDatas[i].customer = d.companyName;
        lineDatas[i].poc = projectContacts.country;
        lineDatas[i].handlerName = d.projectHandler;
        lineDatas[i].projectFlexId = d.projectId;
        lineDatas[i].projectNumber = d.projectNumber;
        lineDatas[i].orderNumber = d.orderNumber;
        lineDatas[i].quoteNo = d.quoteNo;
        lineDatas[i].projectName = d.name;
      }

      tempLineDetails = tempLineDetails.concat(lineDatas);
    });
    dispatch(updateSelectedProjectDetails(tempLineDetails[0]));
  };

  const modify = (
    <div
      className={`${classes.action} ${
        isDisable || hasPendingTestReportFailedStatus
          ? classes.disable
          : classes.active
      }   ${isDisable || hasPendingTestReportFailedStatus ? "disable" : ""}`}
      onClick={onModify}
      title={
        hasPendingTestReportFailedStatus
          ? "Please continue processing the linking of file before modifying this certificate."
          : ""
      }
    >
      <span className={`material-icons ${classes.icon}`}>auto_fix_normal</span>
      Modify
    </div>
  );

  const copy = (
    <div
      className={`${classes.action} ${
        isDisable ? classes.disable : classes.active
      } ${isDisable ? "disable" : ""}`}
      onClick={onOpenCopy}
    >
      <span className={`material-icons ${classes.icon}`}>file_copy</span>
      Create Copy
    </div>
  );

  const withdraw = (
    <div
      className={`${classes.action} ${
        isDisable ? classes.disable : classes.active
      }${isDisable ? "disable" : ""}`}
      onClick={onOpenWithdrawModal}
    >
      <span className={`material-icons ${classes.icon}`}>archive</span>
      Withdraw
    </div>
  );

  const edit = (
    <div
      className={`${classes.action} ${
        isDisable ? classes.disable : classes.active
      }${isDisable ? "disable" : ""}`}
      onClick={onEdit}
    >
      <FontAwesomeIcon
        icon={faPenToSquare}
        className={`material-icons ${classes.icon}`}
      />
      Edit
    </div>
  );

  const activate = (
    <div
      className={`${classes.action} ${
        isDisable ? classes.disable : classes.active
      }${isDisable ? "disable" : ""} `}
      onClick={() => onActivate(isDisable)}
    >
      <span className={`material-icons ${classes.icon}`}>check_circle</span>
      Activate
    </div>
  );

  const attach = (
    <div
      className={`${classes.action} ${
        isDisable ? classes.disable : classes.active
      }${isDisable ? "disable" : ""}`}
      onClick={onAttach}
    >
      <span className={`material-icons ${classes.icon}`}>attach_file</span>
      Attach
    </div>
  );

  const deleteIPCertificate = (
    <div
      className={`${classes.action} ${
        isDisable ? classes.disable : classes.active
      }${isDisable ? "disable" : ""}`}
      onClick={() => onDeleteCertificate(details)}
    >
      <span className={`material-icons ${classes.icon}`}>delete</span>
      Delete
    </div>
  );

  const handleCloseAttachmentsDialog = () => {
    if (FeatureFlagService.getECMFeatureFlag()) {
      setIsOpenAttachment(false);

      dispatch(updateSelectedProjectDetails(null));
      return;
    }

    let attachments = [];

    if (productDetailsValues.attachments) {
      productDetailsValues.attachments.forEach((group, groupIndex) => {
        group
          .filter((x) => x.isValid)
          .forEach((file) => {
            let newFile = JSON.parse(JSON.stringify(file));
            newFile["group_index"] = groupIndex;
            attachments.push(newFile);
          });
      });
    }

    CertificateListService.updateAttachments({
      attachments,
      certificateId: details.certificateId,
    });

    attachments = attachments.map((a) => ({
      ...a,
      attachmentType:
        a.group_index == 0
          ? "Certificate"
          : a.group_index == 1
          ? "Test Reports"
          : "Others",
      fileName: a.name,
    }));

    setIsOpenAttachment(false);
    setDetails({
      ...details,
      localData: { ...details.localData, attachments },
    });
  };

  const getProjectContact = (projectContacts) => {
    const soldTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.SoldTo
    );
    if (soldTo?.partySiteNumber) return soldTo;

    const shipTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.ShipTo
    );
    if (shipTo?.partySiteNumber) return shipTo;

    const customer = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.Customer
    );
    if (customer?.partySiteNumber) return customer;

    const billTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.BillTo
    );
    if (billTo?.partySiteNumber) return billTo;
  };

  const confirmDialogBody = () => {
    return (
      <>
        <div className="custom-confirm-dialog-body">
          <div className="custom-space-below">
            File(s) will be saved to ECM TIC and cannot be deleted.
          </div>
          <div>Would you like to Continue?</div>
        </div>
      </>
    );
  };

  const confirmFooter = () => {
    return (
      <>
        <div className="custom-confirm-dialog-footer">
          <button
            className="ul-button -app-primary -medium custom-confirm-dialog-accept-btn"
            onClick={acceptConfirmDialog}
          >
            YES
          </button>
          <button
            className="ul-button -medium default-cancel-button custom-confirm-dialog-reject-btn"
            onClick={rejectConfirmDialog}
          >
            NO
          </button>
        </div>
      </>
    );
  };

  const acceptConfirmDialog = async () => {
    setIsConfirmDialogShow(false);
    await validateTestReport();
  };

  const rejectConfirmDialog = () => {
    setIsConfirmDialogShow(false);
    setIsDisable(false);
  };

  return (
    <>
      <AddNewCertificate
        dialogFuncMap={dialogFuncMap}
        displayAddNewCertModal={displayAddNewCertModal}
        isEditCert={true}
        certificateId={details.certificateId}
        certificateScheme={details?.certificateScheme}
        hierarchyId={details.hierarchyId}
        revisionNumber={details.revisionNumber}
        editData={details.editData}
        localData={details.localData}
        certificateStatus={details.certificateStatus}
        hasRevisions={details.revisionHistory.length > 0}
        setShowCertificateDetails={setShowCertificateDetails}
        isModifyCert={isModifyCert}
        setIsModifyCert={setIsModifyCert}
        customModalHeader={modalHeader}
        inputAttachments={inputAttachments}
        setAttachments={setAttachments}
      />
      <WithdrawCertificateModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        certificateId={details.certificateId}
        hasRevisionNumber={hasRevisionNumber}
        revisionNumber={revisionNumber}
      />
      <CopyCertificateModal
        isOpen={isOpenCopy}
        setIsOpen={setIsOpenCopy}
        details={details}
        setShowCertificateDetails={setShowCertificateDetails}
      />
      {FeatureFlagService.getECMFeatureFlag() && (
        <CertificateDetailsAttachmentsDialog
          certificateId={details.certificateId}
          setAttachments={setAttachments}
          isOpen={isOpenAttachment}
          onClose={handleCloseAttachmentsDialog}
          details={details}
          inputAttachments={inputAttachments}
          certificateDetailFromE2E={certificateDetailFromE2E}
        />
      )}
      {!FeatureFlagService.getECMFeatureFlag() && (
        <AttachmentsDialog
          certificateId={details.certificateId}
          isOpen={isOpenAttachment}
          onClose={handleCloseAttachmentsDialog}
          details={details}
        />
      )}
      <div className={classes.container}>
        {details?.certificateStatus === "Active" && (
          <>
            {modify}
            {copy}
            {withdraw}
            {attach}
          </>
        )}
        {details?.certificateStatus === "Under Revision" && (
          <>
            {edit}
            {activate}
            {copy}
            {!isReadOnly && deleteIPCertificate}
            {attach}
          </>
        )}
        {(details?.certificateStatus === "Withdrawn" ||
          details?.certificateStatus === "Obsolete") &&
          copy}
      </div>
      <hr className={classes.line} />
      <ConfirmDialog
        style={{ width: "520px" }}
        className="custom-confirm-dialog"
        visible={isConfirmDialogShow}
        header="Save and Activate Confirmation"
        message={confirmDialogBody}
        footer={confirmFooter}
        onHide={() => rejectConfirmDialog()}
      />

      <TestReportMatchedFoundPopup
        visible={testReportsValidatorHook.testReportMatchedFoundPopupVisible}
        onProceedWithoutDuplicate={onCancelActivation}
        onProceedWithDuplicate={() => {
          proceedActivationWithDuplicate();
        }}
        onClose={onCancelActivation}
        rows={testReportValidationOutput}
      />
    </>
  );
};

export default Actions;
