import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import ProjectStatus from "../../enums/project-status";
import classes from "./ProjectStatusTemplate.module.scss";
interface ProjectStatusTemplateProps {
  status: ProjectStatus;
  tooltip?: string;
  isLoading?: boolean;
}
const ProjectStatusTemplate = (props: ProjectStatusTemplateProps) => {
  const getDisplay = () => {
    switch (props.status) {
      case ProjectStatus.NotScheduled:
        return "Not Scheduled";
      case ProjectStatus.InProgress:
        return "In Progress";
      case ProjectStatus.OnHold:
        return "On Hold";
      case ProjectStatus.Cancelled:
        return "Cancelled";
      case ProjectStatus.Completed:
        return "Completed";
      default:
        return "Not defined";
    }
  };

  const getClass = () => {
    switch (props.status) {
      case ProjectStatus.NotScheduled:
        return classes["notscheduled"];
      case ProjectStatus.InProgress:
        return classes["inprogress"];
      case ProjectStatus.OnHold:
        return classes["onhold"];
      case ProjectStatus.Cancelled:
        return classes["cancelled"];
      case ProjectStatus.Completed:
        return classes["completed"];
      default:
        return "disabled";
    }
  };

  const loadingIndicator = (
    <div className="text-center">
      <FontAwesomeIcon icon={faSpinner} spin={true} />
    </div>
  );

  if (props.isLoading) return loadingIndicator;

  return (
    <span
      title={props.tooltip}
      className={clsx(classes["custom-badge"], getClass())}
    >
      {getDisplay()}
    </span>
  );
};
export default ProjectStatusTemplate;
