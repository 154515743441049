import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import classes from "./CustomDatePicker.module.scss";

const years = [];
for (var i = new Date().getFullYear() + 20; i >= 2000; i--) {
  years.push(i);
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const calendarContainer = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

const customHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div className={classes["navigation-container"]}>
    <button
      className={
        classes["btn-navigation"] + ` ${classes["btn-navigation-prev"]}`
      }
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
    >
      <FontAwesomeIcon color="#4D4D4D" fontSize={"16px"} icon={faAngleLeft} />
    </button>

    <select
      className={classes["month-dropdown"]}
      value={months[date.getMonth()]}
      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
    >
      {months.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <select
      className={classes["year-dropdown"]}
      value={date.getFullYear()}
      onChange={({ target: { value } }) => changeYear(value)}
    >
      {years.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <button
      className={
        classes["btn-navigation"] + ` ${classes["btn-navigation-next"]}`
      }
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
    >
      <FontAwesomeIcon fontSize={"16px"} color="#4D4D4D" icon={faAngleRight} />
    </button>
  </div>
);

const CustomDatePicker = (props) => {
  return (
    <DatePicker
      calendarContainer={calendarContainer}
      calendarClassName={classes["calendar-container"]}
      formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
      renderCustomHeader={customHeader}
      {...props}
    />
  );
};

export default CustomDatePicker;
