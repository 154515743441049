import { useEffect, useState } from "react";
import Details from "./Details/Details";
import RevisionHistory from "./RevisionHistory/RevisionHistory";
import LinkedProducts from "./LinkedProducts/LinkedProducts";
import "./CertificateDetails.scss";
import Header from "./Header/Header";
import Remarks from "./Remarks/Remarks";
import Attachments from "./Attachments/Attachments";
import CloseIcon from "../../../../shared/close-icon/CloseIcon";
import ULTabs from "../../../../shared/ul-tabs/ULTabs";
import CertificateListService from "../../../../services/CertificateListService";
import { useSelector } from "react-redux";
import {
  reloadCertificateDetails,
  selectCertificateList,
} from "../../../../features/certificateList/certificateListSlice";
import { updateProdDetailsAttachments } from "../../../../features/createCertificate/createCertificateSlice";
import Actions from "./Actions/Actions";
import { Sidebar } from "primereact/sidebar";
import { useDispatch } from "react-redux";
import classes from "./CertificateDetails.module.scss";
import FeatureFlagService from "../../../../services/FeatureFlagService";
import CertificateDetailsAttachments from "./certificate-details-attachments/CertificateDetailsAttachments";
import { CertificateDocumentStatus } from "../../../../enums/CertificateDocumentStatus";

const CertificateDetails = ({
  showCertificateDetails,
  setShowCertificateDetails,
  isReadOnly,
  onDeleteCertificate,
}) => {
  const dispatch = useDispatch();
  const { selectedCertificateId, isReloadDetails } = useSelector(
    selectCertificateList
  );
  const [details, setDetails] = useState(null);
  const [certificateDetailFromE2E, setCertificateDetailFromE2E] =
    useState(null);
  const [attachments, setAttachments] = useState(null);

  const [isFetchingCertificateDetails, setIsFetchingCertificateDetails] =
    useState(false);

  const onClose = () => {
    dispatch(updateProdDetailsAttachments(null));

    setShowCertificateDetails(false);
  };

  const [
    hasPendingTestReportFailedStatus,
    setHasPendingTestReportFailedStatus,
  ] = useState(true);

  const getAttachments = async (uniqueCertificateId) => {
    if (!uniqueCertificateId) return;
    const attachments =
      await CertificateListService.getCertificateDocumentsByUniqueCertificateId(
        uniqueCertificateId
      );

    if (attachments?.data) {
      let hasPendingTestReportFailedStatus = false;
      attachments.data.forEach((attachment) => {
        if (
          attachment.status ==
          CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
        ) {
          hasPendingTestReportFailedStatus = true;
          return;
        }
      });
      setHasPendingTestReportFailedStatus(hasPendingTestReportFailedStatus);
    }

    setAttachments(attachments?.data);
  };

  const closeIcon = <CloseIcon onCloseEvent={onClose} />;

  const components = [
    {
      headerName: "Details",
      components: (
        <>
          <Actions
            details={details}
            setDetails={setDetails}
            getAttachments={getAttachments}
            setAttachments={setAttachments}
            onDeleteCertificate={onDeleteCertificate}
            setShowCertificateDetails={setShowCertificateDetails}
            isReadOnly={isReadOnly}
            inputAttachments={attachments}
            certificateDetailFromE2E={certificateDetailFromE2E}
            hasPendingTestReportFailedStatus={hasPendingTestReportFailedStatus}
          />
          <Details details={details} />
          <LinkedProducts products={details?.linkedProducts} />
          {FeatureFlagService.getECMFeatureFlag() && (
            <CertificateDetailsAttachments
              attachments={attachments}
              setAttachments={setAttachments}
              fetchAttachment={getAttachments}
              certificateDetailFromE2E={certificateDetailFromE2E}
              details={details}
            />
          )}
          {!FeatureFlagService.getECMFeatureFlag() && (
            <Attachments attachments={details?.localData?.attachments} />
          )}
        </>
      ),
    },
    {
      headerName: "Revision History",
      components: <RevisionHistory revisions={details?.revisionHistory} />,
    },
  ];

  const getDetails = async (selectedCertificateId) => {
    setIsFetchingCertificateDetails(true);
    const details = await CertificateListService.getCertificateDetails(
      selectedCertificateId
    );

    if (FeatureFlagService.getECMFeatureFlag()) {
      const certificateDetailFromE2E =
        await CertificateListService.getCertificateDetailByWorkbenchCertificateId(
          selectedCertificateId
        );
      setCertificateDetailFromE2E(certificateDetailFromE2E?.data);
      getAttachments(details.uniqueCertificateId);
    }

    details.certificateId = selectedCertificateId;
    details.localData = details.localData && JSON.parse(details.localData)[0];
    ifECMClearTheOldAttachmentImplementation(details.localData);
    setDetails(details);
    setIsFetchingCertificateDetails(false);
  };
  const ifECMClearTheOldAttachmentImplementation = (data) => {
    if (FeatureFlagService.getECMFeatureFlag() && data && data.attachments)
      data.attachments = null;
  };

  useEffect(() => {
    if (selectedCertificateId) {
      getDetails(selectedCertificateId);
      dispatch(reloadCertificateDetails(false));
    }
  }, [selectedCertificateId]);

  useEffect(() => {
    if (isReloadDetails) {
      getDetails(selectedCertificateId);
      dispatch(reloadCertificateDetails(false));
    }
  }, [isReloadDetails]);

  return (
    <Sidebar
      visible={showCertificateDetails}
      position="right"
      dismissable={true}
      className="details-font certificate-details-layout"
      onHide={onClose}
      showCloseIcon={false}
    >
      {isFetchingCertificateDetails && (
        <div className="loading-container">
          <div>Retrieving certificate details......</div>
          <div>
            <svg className="ul-progressCircle -medium">
              <circle
                className="ul-progressCircle__outer"
                cx="50%"
                cy="50%"
                r="35%"
              />
              <circle
                className="ul-progressCircle__inner"
                cx="50%"
                cy="50%"
                r="35%"
              />
            </svg>
          </div>
        </div>
      )}
      {!isFetchingCertificateDetails && (
        <>
          <Header closeIcon={closeIcon} details={details} />
          <ULTabs
            containerClassName={`${classes["ul-tab-container"]}`}
            contentClassName={`${classes["ul-tab-content"]}`}
            components={components}
          />
        </>
      )}
    </Sidebar>
  );
};

export default CertificateDetails;
