import React from "react";
import { useSelector } from "react-redux";
import { values } from "./../../../../features/dkList/dkListSlice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DataTableSortProps } from "../../DocumentKnowledge";

interface DeliveryPathInformationProps {
  isJapanese: boolean;
  sorting: DataTableSortProps;
  setSorting: React.Dispatch<React.SetStateAction<DataTableSortProps>>;
  isCollapsed: boolean;
  collapsedTemplate: (rowData: any, field: any) => {};
}

const DeliveryPathInformation = (props: DeliveryPathInformationProps) => {
  const fieldsEnglish = {
    certificationScheme: "Certification Scheme",
    segment: "Segment",
    deliveryPath: "Delivery Path",
    applicationType: "Application Type",
    additionalInfo: "Additional Info",
    distributorEn: "Local Representative Importer / Distributor(English)",
    localRepService: "Local rep service",
    validityEn: "Certificate Validity(Year) English",
    LabelRequirementEn: "Label Requirement English",
    manualRequirementEn: "Manual Requirement English",
    publicationEn: "Web publication of approval information English",
    testReportPriorityEn: "Test Report Priority English",
    leadTimeWeeksEn: "Lead - time / Weeks English",
    applicationRemarksEn: "Application Remarks(English)",
    localTestSampleReqEn: "Local Test Sample Requirement (English)",
    factoryInspection: "Factory Inspection",
    country: "Country",
  };

  const fieldsJapanese = {
    certificationScheme: "Certification Scheme",
    segment: "Segment",
    deliveryPath: "Delivery Path",
    applicationType: "Application Type",
    additionalInfo: "Additional Info",
    distributorJpn: "Local Representative Importer / Distributor(Japanese)*",
    localRepService: "Local rep service",
    validityJpn: "Certificate Validity(Year) Japanese*",
    labelRequirementJpn: "Label Requirement Japanese*",
    manualRequirementJpn: "Manual Requirement Japanese*",
    publicationJpn: "Web publication of approval information Japanese*",
    testReportPriorityJpn: "Test Report Priority Japanese*",
    leadTimeWeeksJpn: "Lead - time / Weeks Japanese*",
    applicationRemarksJpn: "Application Remarks(Japanese)*",
    localTestSampleReqJpn: "Local Test Sample Requirement (Japanese)",
    factoryInspection: "Factory Inspection",
    country: "Country",
  };

  const value = useSelector(values);

  const onSortChange = (sortProps: any) => {
    props.setSorting((current: any) => ({
      ...current,
      devPath: {
        column: sortProps.sortField,
        sort: sortProps.sortOrder,
      },
    }));
  };

  return (
    <DataTable
      key={"devPathGrid"}
      loading={value.isLoadingDelPathData}
      className={`ul-table`}
      value={value.devPaths}
      resizableColumns
      sortMode="single"
      removableSort
      responsiveLayout="scroll"
      sortField={props.sorting.devPath.column}
      sortOrder={props.sorting.devPath.sort}
      onSort={onSortChange}
      showGridlines
      columnResizeMode="expand"
    >
      <Column field="index" header="index" hidden={true}></Column>
      {Object.entries(props.isJapanese ? fieldsJapanese : fieldsEnglish).map(
        (col) => {
          return (
            <Column
              key={`devPathGrid-${col[0]}`}
              field={col[0]}
              header={col[1]}
              sortable
              body={
                !props.isCollapsed
                  ? (e) => props.collapsedTemplate(e, col[0])
                  : null
              }
            ></Column>
          );
        }
      )}
    </DataTable>
  );
};

export default DeliveryPathInformation;
