import {
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./ExpandIcon.module.scss";

export interface IExpandIconProps {
  expanded: boolean;
  onExpand: () => void;
  onCollapse: () => void;
  className: string;
}

export default function ExpandIcon({
  className,
  expanded,
  onExpand,
  onCollapse,
}: IExpandIconProps) {
  return (
    <FontAwesomeIcon
      className={`${classes["icon"]} ${className}`}
      icon={
        expanded ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter
      }
      onClick={expanded ? onCollapse : onExpand}
    />
  );
}
