import React, { useState } from "react";
import { ProjectTemplateMappingHeaderModel } from "../../../shared/models/ProjectTemplateMappingHeader.model";
import { FlexProjectTemplateDetailModel } from "../../../shared/models/FlexProjectTemplateTaskDetail.model";
import { Dropdown } from "primereact/dropdown";
import classes from "./ProjectTemplateMappingHeader.module.scss";
import { InputText } from "primereact/inputtext";
import FormTemplateError from "../shared/component/FormTemplateError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockRotateLeft,
  faPencil,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import ProjectTemplateViewHistory from "../project-template-view-history/ProjectTemplateViewHistory";
import {
  ProjectTemplateComponentData,
  ProjectTemplateComponentErrors,
} from "../ProjectTemplateMapping";
import { ProjectTemplateMappingModes } from "../shared/enums/ProjectTemplateMappingModes";
import ConfirmationModal from "../../../shared/confirmation-modal/ConfirmationModal";
import useToastr from "../../../hooks/useToastr";
import { TemplateAssociationModel } from "../../../shared/models/TemplateAssociation.model";

interface ProjectTemplateMappingHeaderProps {
  headerData: ProjectTemplateMappingHeaderModel;
  setComponentData: React.Dispatch<
    React.SetStateAction<ProjectTemplateComponentData>
  >;
  flexTemplateList: FlexProjectTemplateDetailModel[];
  setFetchNewFlexTasks: React.Dispatch<React.SetStateAction<boolean>>;
  setComponentErrors: React.Dispatch<
    React.SetStateAction<ProjectTemplateComponentErrors | null>
  >;
  componentErrors: ProjectTemplateComponentErrors | null;
  isConfirmationModalVisible: boolean;
  FlexTemplateModalVisibility: Function;
  additionalInfoList: any[];
  associatedSchemeData: TemplateAssociationModel[];
}

const ProjectTemplateMappingHeader = (
  componentProps: ProjectTemplateMappingHeaderProps
) => {
  const { showSuccess, showError } = useToastr();

  const [showViewHistory, setShowViewHistory] = useState<boolean>(false);
  const [selectedProjectTemplateId, setSelectedProjectTemplateId] =
    useState<string>("0");

  const [selectedAdditionalInfoId, setSelectedAdditionalInfoId] =
    useState<string>("");
  const handleEditClick = () => {
    componentProps.setComponentData((current: any) => ({
      ...current,
      header: {
        ...current.header,
        templateMode: ProjectTemplateMappingModes.Edit,
      },
    }));
  };

  const handleFlexChange = (e: any) => {
    setSelectedProjectTemplateId(e.value);
    componentProps.FlexTemplateModalVisibility(true);
  };

  const handleAdditionalInfoChange = (e: any) => {
    setSelectedAdditionalInfoId(e.value);
    componentProps.setComponentData((current: any) => ({
      ...current,
      header: {
        ...current.header,
        additionalInfoId: e.value,
      },
      associatedSchemeData: [
        {
          id: null,
          schemeId: null,
          schemeName: null,
          certificateSchemeList: [],
          deliveryPathId: null,
          deliveryPathName: null,
          deliveryPathListResult: [],
          applicationTypeId: null,
          applicationTypeName: null,
          applicationTypeListResult: [],
        },
      ],
    }));
  };
  const confirmationMessage = () => {
    return (
      <div>
        <p>
          Changing the mapped FLEX Template will revert to the default mapping
          of the newly selected FLEX Template. Would you like to continue?
        </p>
      </div>
    );
  };

  const handleCancelConfirmationModalNo = () => {
    componentProps.FlexTemplateModalVisibility(false);
  };

  const handleCancelConfirmationModalYes = () => {
    const flexDetails = componentProps.flexTemplateList.find(
      (x) => x.projectTemplateId === selectedProjectTemplateId
    );

    componentProps.setComponentData((current: any) => ({
      ...current,
      header: {
        ...current.header,
        flexTemplateId: flexDetails?.projectTemplateId,
        flexTemplateName: flexDetails?.templateName,
        flexCorrelationId: flexDetails?.correlationId,
      },
    }));
    componentProps.setFetchNewFlexTasks(true);
  };

  return (
    <>
      <div className={`${classes["template-header__container"]}`}>
        <div className={`ul-grid__row ${classes["template-header__row"]}`}>
          <div className="ul-grid__column -x30">
            <div className="column-fill">
              <div className={`${classes["text-label"]}`}>
                <label>
                  GMA WB Template Name
                  <span className={`${classes["required-field"]}`}> *</span>
                </label>
              </div>
              <div className={`${classes["text-box__container"]}`}>
                <InputText
                  className={`${classes["text-box"]} ${
                    componentProps.componentErrors?.headerErrors?.templateName
                      ? `p-invalid ${classes["text-box--error"]}`
                      : ""
                  }`}
                  disabled={
                    componentProps.headerData.templateMode ===
                    ProjectTemplateMappingModes.View
                  }
                  value={componentProps.headerData.name}
                  onChange={(e) => {
                    componentProps.setComponentData((current: any) => ({
                      ...current,
                      header: { ...current.header, name: e.target.value },
                    }));
                    componentProps.setComponentErrors((current: any) => ({
                      ...current,
                      headerErrors: { templateName: null },
                    }));
                  }}
                />

                {componentProps.componentErrors?.headerErrors?.templateName && (
                  <FormTemplateError
                    errorMessage={
                      componentProps.componentErrors.headerErrors.templateName
                    }
                    visible={true}
                    icon={faClose}
                  />
                )}
              </div>
            </div>
          </div>

          <div
            className={`ul-grid__column -x30 ${
              classes["template-icon__container"]
            } ${
              componentProps.componentErrors?.headerErrors?.templateName
                ? ""
                : classes["template-icon--without-error"]
            }`}
          >
            <div
              className={`ul-grid__row ${classes["template-icon-alignment"]}`}
            >
              <span>
                GMA WB Template Version:{" "}
                {componentProps.headerData.formattedVersion}
              </span>
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                className={classes["icon"]}
                title={"View Template Version History"}
                onClick={(e) => setShowViewHistory(true)}
              />
              <FontAwesomeIcon
                icon={faPencil}
                className={
                  componentProps.headerData.templateMode ===
                  ProjectTemplateMappingModes.View
                    ? classes["icon"]
                    : classes["icon--disabled"]
                }
                title={"Click to Edit Template"}
                onClick={
                  (componentProps.headerData.templateMode ===
                    ProjectTemplateMappingModes.View) ===
                  true
                    ? () => handleEditClick()
                    : undefined
                }
              />
            </div>
          </div>

          <div
            className={`ul-grid__column -x20 ${classes["dropdown-flex__column"]}`}
          >
            <div className="column-fill">
              <div className={`${classes["text-label"]}`}>
                <label>
                  Flex Template
                  <span className={`${classes["required-field"]}`}> *</span>
                </label>
              </div>
              <div className={`${classes["dropdown-flex__container"]}`}>
                <Dropdown
                  className={`${classes["dropdown-flex"]}`}
                  optionLabel={"templateName"}
                  optionValue={"projectTemplateId"}
                  options={componentProps.flexTemplateList}
                  placeholder={
                    componentProps.flexTemplateList.length > 0
                      ? "-- Select --"
                      : "-- Loading --"
                  }
                  onChange={(e) => {
                    handleFlexChange(e);
                  }}
                  disabled={
                    componentProps.flexTemplateList.length === 0 ||
                    componentProps.headerData.templateMode ===
                      ProjectTemplateMappingModes.View
                  }
                  value={componentProps.headerData.flexTemplateId}
                />
              </div>
            </div>
          </div>
          <div
            className={`ul-grid__column -x20 ${classes["dropdown-flex__column"]}`}
          >
            <div className="column-fill">
              <div className={`${classes["text-label"]}`}>
                <label>
                  Additional Information
                  <span className={`${classes["required-field"]}`}> *</span>
                </label>
              </div>
              <div className={`${classes["dropdown-flex__container"]}`}>
                <Dropdown
                  className={`${classes["dropdown-flex"]}`}
                  optionLabel={"value"}
                  optionValue={"id"}
                  options={componentProps.additionalInfoList}
                  placeholder={
                    componentProps.additionalInfoList.length > 0
                      ? "-- Select --"
                      : "-- Loading --"
                  }
                  onChange={(e) => {
                    handleAdditionalInfoChange(e);
                  }}
                  disabled={
                    componentProps.additionalInfoList.length === 0 ||
                    componentProps.headerData.templateMode ===
                      ProjectTemplateMappingModes.View
                  }
                  value={componentProps.headerData.additionalInfoId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProjectTemplateViewHistory
        isVisible={showViewHistory}
        onCloseDialog={() => setShowViewHistory(false)}
        correlationId={componentProps.headerData.correlationId}
        target={"template-admin-body"}
      />

      <ConfirmationModal
        visible={componentProps.isConfirmationModalVisible}
        onCancel={handleCancelConfirmationModalNo}
        onConfirm={handleCancelConfirmationModalYes}
        headerDisplay={`Confirm Change of Mapped FLEX Template`}
        confirmationMessageHTML={confirmationMessage()}
        buttonNoDisplay={`No`}
        buttonYesDisplay={`Yes`}
      />
    </>
  );
};

export default ProjectTemplateMappingHeader;
