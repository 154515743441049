import { ConfirmDialog } from "primereact/confirmdialog";
import classes from "./ProjectOrderlineStatusConfirmDialog.module.scss";
import { useState } from "react";
const ProjectOrderlineStatusConfirmDialog = (props: any) => {
  const createMarkup = (content: string) => {
    return { __html: content };
  };
  const confirmDialogBody = () => {
    return (
      <>
        <div className="custom-confirm-dialog-body">
          <p dangerouslySetInnerHTML={createMarkup(props.messageText)}></p>
        </div>
      </>
    );
  };
  const confirmFooter = () => {
    return (
      <>
        <div className="custom-confirm-dialog-footer">
          <button
            className="ul-button -medium default-cancel-button custom-confirm-dialog-reject-btn"
            onClick={rejectConfirmDialog}
          >
            CANCEL
          </button>
          <button
            className="ul-button -medium custom-confirm-dialog-accept2-btn"
            onClick={() => {
              props.setIsConfirmDialogShow(false);
              props.saveOrderline();
            }}
          >
            {props.isMultipleLines ? `ORDER LINES ONLY` : `ORDER LINE ONLY`}
          </button>
          <button
            className="ul-button -app-primary -medium custom-confirm-dialog-accept-btn"
            onClick={() => {
              props.setIsConfirmDialogShow(false);
              props.saveOrderlineAndFlexProject();
            }}
          >
            {props.isMultipleLines
              ? `BOTH ORDER LINES AND FLEX PROJECTS`
              : `BOTH ORDER LINE AND FLEX PROJECT`}
          </button>
        </div>
      </>
    );
  };
  const rejectConfirmDialog = () => {
    props.setIsConfirmDialogShow(false);
  };
  return (
    <>
      {
        <ConfirmDialog
          style={{ width: "720px" }}
          className="custom-confirm-dialog"
          visible={props.isConfirmDialogShow}
          header={
            props.isMultipleLines
              ? `Confirm Status Changes`
              : `Confirm Status Change`
          }
          message={confirmDialogBody}
          footer={confirmFooter}
          onHide={() => rejectConfirmDialog()}
        />
      }
    </>
  );
};
export default ProjectOrderlineStatusConfirmDialog;
