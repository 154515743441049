import {
  IconDefinition,
  faCaretDown,
  faCaretRight,
  faCircleCheck,
  faExclamationCircle,
  faInfo,
  faInfoCircle,
  faLink,
  faLinkSlash,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { saveAs } from "file-saver";
import * as _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { CertificateDocumentStatus } from "../../../../../enums/CertificateDocumentStatus";
import { CertificateDocumentType } from "../../../../../enums/CertificateDocumentType";
import { useSignalR } from "../../../../../hooks/useSignalR";
import useToastr from "../../../../../hooks/useToastr";
import CertificateListService from "../../../../../services/CertificateListService";
import EcmIntegrationService from "../../../../../services/EcmIntegrationService";
import { appInsights } from "../../../../../services/appInsights";
import ConfirmationModal from "../../../../../shared/confirmation-modal/ConfirmationModal";
import classes from "./CertificateDetailsAttachments.module.scss";
import { useTestReportValidator } from "../../add-new-certificate/test-report-matched-found-popup/useTestReportValidator";
import TestReportMatchedFoundPopup from "../../add-new-certificate/test-report-matched-found-popup/TestReportMatchedFoundPopup";
import FeatureFlagService from "../../../../../services/FeatureFlagService";
import { CertificateEditDataModel } from "../../../../../shared/models/CertificateEditData.model";
import { IPModel } from "../../../../../shared/models/IPModel.model";
import {
  ecmUnavailableHeader,
  ecmUnavailableMessage,
} from "../../../../../utils/constants/ecm.constants";

const initialFiles: IAttachmentFiles[] = [
  {
    documentType: CertificateDocumentType.Certificate,
    display: "Certificate",
    attachments: [],
    isExpand: false,
  },
  {
    documentType: CertificateDocumentType.TestReport,
    display: "Test Reports",
    attachments: [],
    isExpand: false,
  },
  {
    documentType: CertificateDocumentType.Others,
    display: "Others",
    attachments: [],
    isExpand: false,
  },
];

const UploadToEcmInProgressText = "Uploading in progress";
const LinkedToWbAndEcmTicText = "Linked/Saved to WB & ECM TIC";
const SavedInEcmTicText = "Saved in ECM TIC";
const ReadyToLinkToWbAndEcmTicText = "Ready to Link/Save to WB & ECM TIC";
const ReadyToReLinkToWbAndEcmTicText = "Ready to Re-link/Save to WB & ECM TIC";
const UnlinkEcmBtnText = "Unlink File from WB";
const LinkToWbAndEcmBtnText = "Link File to WB and ECM TIC";
const LinkToWbBtnText = "Link File to WB";

const displayUnlinkEcmModalHeader = "Unlink File Confirmation";
const displayLinkEcmModalHeader = "Link File to WB and ECM TIC Confirmation";

const TestReportDuplicateText = "Link File to WB and ECM TIC";
const TestReportDuplicateLinkToEcmFailedHover =
  "File already exists in ECM TIC. Please continue processing the linking of file.";
const TestReportDuplicateLinkToEcmFailedText =
  "Continue Linking Existing File from ECM TIC";
interface IAttachmentFiles {
  documentType: CertificateDocumentType;
  status?: CertificateDocumentStatus;
  display: string;
  attachments: IDocumentAttachments[];
  isExpand: boolean;
}

interface IDocumentAttachments {
  id: string;
  documentType: CertificateDocumentType;
  status?: CertificateDocumentStatus;
  originalFileName: string;
  ecmFileNodeId: string;
  isDuplicate: boolean;
}
interface IEcmCertificateDocument {
  certificateDocumentId: string;
  ecmFileNodeId: string;
  isTestReportDuplicate: boolean;
  iPCertificateDetailsModel: any;
  workbenchCertificateId: string;
}

const logPrefix = `CertificateDetailsAttachments - ${uuidv4()}`;

const CertificateDetailsAttachments = ({
  attachments,
  setAttachments,
  fetchAttachment,
  certificateDetailFromE2E,
  details,
}: any) => {
  const [attachmentFiles, setFiles] = useState(initialFiles);
  const { showSuccess, showError, showWarning } = useToastr();
  const dispatch = useDispatch();
  const hubUrl = (process.env.REACT_APP_GMAE2E_FUNC_URL + "/api") as string;
  const { connection: signalRConnection } = useSignalR(hubUrl, true);
  const [showDeleteFileConfirmation, setShowDeleteFileConfirmation] =
    useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const [displayUnlinkEcmModalPrompt, setDisplaydisplayUnlinkEcmModalPrompt] =
    useState(false);

  const [displayLinkEcmModalPrompt, setDisplaydisplayLinkEcmModalPrompt] =
    useState(false);

  const [attachmentToUnlink, setAttachmentToUnlink] =
    useState<IDocumentAttachments>();

  const [attachmentsInProcess, setAttachmentsInProcess] = useState<
    IDocumentAttachments[]
  >([]);
  const [attachmentToLinkWBAndECM, setAttachmentToLinkWBAndECM] =
    useState<IDocumentAttachments>();

  const [hasCertificateBeenActive, setHasCertificateBeenActive] =
    useState(false);

  const testReportsValidatorHook = useTestReportValidator();

  const downloadFile = async (file: any) => {
    switch (file.status) {
      case CertificateDocumentStatus.LinkedToWbAndEcmTic:
      case CertificateDocumentStatus.SavedInEcmTic:
        await EcmIntegrationService.downloadEcmFile(file.ecmFileNodeId).then(
          (response) => {
            if (response) {
              saveAs(response, file.originalFileName);
            } else {
              showError(
                "Unable to Download File",
                "There is a problem while connecting to ECM Folder. Please try again later."
              );
            }
          }
        );
        break;
      case CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic:
        await CertificateListService.downloadCertificateDocument(file.id).then(
          (response) => {
            if (response) {
              saveAs(response, file.originalFileName);
            } else {
              showError("Error!", "File does not exists.");
            }
          }
        );
        break;
    }
  };

  const onExpand = (item: any) => {
    item.isExpand = !item.isExpand;
    const updatedFiles = attachmentFiles.map((attachmentFile) =>
      attachmentFile.documentType === item.documentType ? item : attachmentFile
    );
    setFiles(updatedFiles);
  };

  const getDocumentStatusIconColor = (status?: CertificateDocumentStatus) => {
    switch (status) {
      case CertificateDocumentStatus.UploadToEcmInProgress:
      case CertificateDocumentStatus.TestReportDuplicateLinkToEcmInProgress:
      case CertificateDocumentStatus.NotYetUploaded:
        return classes["uploading-icon"];
      case CertificateDocumentStatus.LinkedToWbAndEcmTic:
        return classes["legend-icon-1"];
      case CertificateDocumentStatus.SavedInEcmTic:
        return classes["legend-icon-2"];
      case CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic:
        return classes["legend-icon-3"];
      case CertificateDocumentStatus.TestReportDuplicate:
        return classes["legend-icon-3"];
      case CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed:
        return classes["legend-icon-4"];
    }
  };

  const getDocumentStatusText = (status?: CertificateDocumentStatus) => {
    switch (status) {
      case CertificateDocumentStatus.UploadToEcmInProgress:
      case CertificateDocumentStatus.NotYetUploaded:
        return UploadToEcmInProgressText;
      case CertificateDocumentStatus.LinkedToWbAndEcmTic:
        return LinkedToWbAndEcmTicText;
      case CertificateDocumentStatus.SavedInEcmTic:
        return SavedInEcmTicText;
      case CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic:
        return ReadyToLinkToWbAndEcmTicText;
      case CertificateDocumentStatus.UploadToEcmFailed:
        return ReadyToReLinkToWbAndEcmTicText;
      case CertificateDocumentStatus.TestReportDuplicate:
        return ReadyToLinkToWbAndEcmTicText;
      case CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed:
        return TestReportDuplicateLinkToEcmFailedHover;
    }
  };

  const renderLinkButtons = (file: any) => {
    if (!hasCertificateBeenActive) return <></>;
    switch (file.status) {
      case CertificateDocumentStatus.LinkedToWbAndEcmTic:
        return (
          <span
            className={`${classes["text-btn"]} ${classes["unlinkEcm"]}`}
            onClick={() => {
              setAttachmentToUnlink(file);
              setDisplaydisplayUnlinkEcmModalPrompt(true);
            }}
            title={UnlinkEcmBtnText}
          >
            <FontAwesomeIcon className={classes["icons"]} icon={faLinkSlash} />
            {UnlinkEcmBtnText}
          </span>
        );
      case CertificateDocumentStatus.UploadToEcmFailed:
        if (details?.certificateStatus === "Under Revision") return <></>;
        return (
          <>
            <span
              className={`${classes["text-btn"]} ${classes["linkToWbAndEcm"]}`}
              onClick={() => {
                handleRelinkLinkEcm(file);
              }}
              title={LinkToWbAndEcmBtnText}
            >
              <FontAwesomeIcon className={classes["icons"]} icon={faLink} />
              {LinkToWbAndEcmBtnText}
            </span>
          </>
        );
      case CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic:
        if (details?.certificateStatus === "Under Revision") return <></>;
        return (
          <>
            <span
              className={`${classes["text-btn"]} ${classes["linkToWbAndEcm"]}`}
              onClick={() => {
                setDisplaydisplayLinkEcmModalPrompt(true);
                setAttachmentToLinkWBAndECM(file);
              }}
              title={LinkToWbAndEcmBtnText}
            >
              <FontAwesomeIcon className={classes["icons"]} icon={faLink} />
              {LinkToWbAndEcmBtnText}
            </span>
          </>
        );
      case CertificateDocumentStatus.TestReportDuplicate:
        if (details?.certificateStatus === "Under Revision") return <></>;
        return (
          <>
            <span
              className={`${classes["text-btn"]} ${classes["linkToWbAndEcm"]}`}
              onClick={() => {
                setAttachmentToLinkWBAndECM(file);

                setDisplaydisplayLinkEcmModalPrompt(true);
              }}
              title={TestReportDuplicateText}
            >
              <FontAwesomeIcon className={classes["icons"]} icon={faLink} />

              {TestReportDuplicateText}
            </span>
          </>
        );
      case CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed:
        if (details?.certificateStatus === "Under Revision") return <></>;
        return (
          <>
            <span
              className={`${classes["text-btn"]} ${classes["linkToWbAndEcm"]}`}
              onClick={() => {
                setAttachmentToLinkWBAndECM(file);
                handleLinkToWb(file, true);
              }}
              title={TestReportDuplicateLinkToEcmFailedText}
            >
              <FontAwesomeIcon className={classes["icons"]} icon={faLink} />

              {TestReportDuplicateLinkToEcmFailedText}
            </span>
          </>
        );
      case CertificateDocumentStatus.SavedInEcmTic:
        return (
          <span
            className={`${classes["text-btn"]} ${classes["linkToWb"]}`}
            onClick={() => handleLinkToWb(file)}
            title={LinkToWbBtnText}
          >
            <FontAwesomeIcon className={classes["icons"]} icon={faLink} />
            {LinkToWbBtnText}
          </span>
        );
    }
  };
  const renderDeleteButtons = (file: any) => {
    switch (file.status) {
      case CertificateDocumentStatus.UploadToEcmFailed:
      case CertificateDocumentStatus.TestReportDuplicate:
      case CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic:
        return (
          <>
            <span
              className={`${classes["delete-btn"]}`}
              title="Delete Attachment"
              onClick={() => handleDeleteAttachement(file)}
            >
              <FontAwesomeIcon
                className={`${classes["icons"]}`}
                icon={faTrash}
              />
            </span>
          </>
        );
    }
  };

  const showWorkbenchTransactionFailureNotfication = () => {
    showWarning(
      "Workbench is Currently Unavailable",
      "Something went wrong while accessing Workbench. Please try and relink the Workbench document once WB is back on-line (see status bar)"
    );
  };

  const handleUnlinkEcm = async () => {
    if (!attachmentToUnlink?.ecmFileNodeId || !attachmentToUnlink?.id)
      return showError("Failed!", "Attachment has no valid id.");

    _.find(attachmentFiles, (element) => {
      let item = _.find(element.attachments, ["id", attachmentToUnlink?.id]);
      if (item) item.status = CertificateDocumentStatus.NotYetUploaded;
    });

    let payload: IEcmCertificateDocument = {
      certificateDocumentId: attachmentToUnlink?.id,
      ecmFileNodeId: attachmentToUnlink?.ecmFileNodeId,
      isTestReportDuplicate: false,
      workbenchCertificateId: details.certficiateId,
      iPCertificateDetailsModel: details,
    };

    setAttachmentToUnlink(undefined);
    setDisplaydisplayUnlinkEcmModalPrompt(false);

    await CertificateListService.unlinkEcmDocument(payload)
      .then((response) => {
        if (response?.isSuccess) {
          showSuccess(
            "Success!",
            "The document(s) has been unlinked from Workbench successfully"
          );
        } else {
          onWbLinkOrUnlinkError(response, "handleUnlinkEcm");
        }
      })
      .catch((error) => {
        onWbLinkOrUnlinkError(error, "handleUnlinkEcm");
      })
      .finally(() => {
        fetchAttachment(certificateDetailFromE2E?.workbenchUniqueCertificateId);
      });
  };

  const handleLinkEcm = async (
    proceedWithMatchingTestReportsFound: boolean = false
  ) => {
    if (!attachmentToLinkWBAndECM?.id)
      return showError("Failed!", "Attachment has no valid id.");

    setDisplaydisplayLinkEcmModalPrompt(false);

    const setAttachmentStatus = (newStatus: CertificateDocumentStatus) => {
      _.find(attachmentFiles, (element) => {
        let item = _.find(element.attachments, [
          "id",
          attachmentToLinkWBAndECM?.id,
        ]);
        if (item) item.status = newStatus;
      });
    };

    setAttachmentStatus(CertificateDocumentStatus.NotYetUploaded);

    if (
      attachmentToLinkWBAndECM.documentType ===
        CertificateDocumentType.TestReport &&
      FeatureFlagService.getLinkTestReportToMultipleCertificateFlag() &&
      !proceedWithMatchingTestReportsFound
    ) {
      const testReports = [attachmentToLinkWBAndECM.originalFileName];

      const partySiteNumber = details.editData.find(
        (data: CertificateEditDataModel) =>
          data.attribute_name === "ownerReference"
      ).value[0];
      const certificateScheme = details.certificateScheme;
      let baseModel = details.assets.find(
        (asset: IPModel) => asset.isBaseModel
      );

      if (!baseModel) {
        if (details?.assets?.length > 0) {
          baseModel = details.assets[0];
        }
      }
      const validationResult =
        await testReportsValidatorHook.validateTestReports(
          testReports,
          partySiteNumber,
          certificateScheme,
          baseModel.modelName
        );

      if (validationResult.hasDuplicate) {
        setAttachmentToLinkWBAndECM((prevAttachment) => {
          prevAttachment!.status =
            CertificateDocumentStatus.TestReportDuplicate;
          prevAttachment!.ecmFileNodeId =
            validationResult.matchedTestReports![0].ecmNodeId;

          return prevAttachment;
        });

        setAttachmentStatus(CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic);
        testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(true);
        return;
      }
    }

    let obj: any = {};
    obj.certificateDocumentId = attachmentToLinkWBAndECM!.id;
    obj.ecmFileNodeId = attachmentToLinkWBAndECM!.ecmFileNodeId;
    obj.certificateId = details.certificateId;
    obj.iPCertificateDetailsModel = details;
    const response =
      await CertificateListService.certificateAttachmentSingleLinkUploadECM(
        obj
      );

    if (response?.isSuccess) {
      showSuccess(
        "Success!",
        "Uploading of document(s) has been successfully processed. " +
          "Please refer to Attachments Section of the certificate to view the status of the document(s)."
      );

      if (proceedWithMatchingTestReportsFound) {
        setAttachmentStatus(CertificateDocumentStatus.LinkedToWbAndEcmTic);
      }
    } else {
      showWarning(ecmUnavailableHeader, ecmUnavailableMessage);

      if (proceedWithMatchingTestReportsFound) {
        setAttachmentStatus(
          CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
        );
      } else {
        setAttachmentStatus(CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic);
      }
    }
    setAttachmentToLinkWBAndECM(undefined);
  };

  const onWbLinkOrUnlinkError = (
    error: any,
    functionName: "handleLinkToWb" | "handleUnlinkEcm"
  ) => {
    appInsights?.trackTrace({
      message: `${logPrefix} - ${functionName} - ${JSON.stringify(error)}`,
      severityLevel: SeverityLevel.Error,
    });

    showWarning(ecmUnavailableHeader, ecmUnavailableMessage);
  };

  const handleLinkToWb = async (
    file: any,
    isTestReportDuplicate: boolean = false
  ) => {
    let selectedFile = file;

    let newAttachements = attachments as IDocumentAttachments[];
    let selectedDocument = newAttachements.find(
      (attachment) => attachment.id == selectedFile.id
    );
    selectedDocument!.status = CertificateDocumentStatus.UploadToEcmInProgress;

    setAttachments([...newAttachements]);

    let payload: IEcmCertificateDocument = {
      certificateDocumentId: selectedFile?.id,
      ecmFileNodeId: selectedFile?.ecmFileNodeId,
      isTestReportDuplicate: isTestReportDuplicate,
      iPCertificateDetailsModel: details,
      workbenchCertificateId: details.certificateId,
    };

    let currentAttachmentLinks = attachmentsInProcess as IDocumentAttachments[];
    currentAttachmentLinks.push(selectedFile);
    setAttachmentsInProcess([...currentAttachmentLinks]);

    await CertificateListService.linkWb(payload)
      .then((response) => {
        if (response) {
          if (response.isSuccess) {
            showSuccess(
              "Success!",
              "The document(s) has been uploaded to Workbench successfully."
            );
          } else {
            onWbLinkOrUnlinkError(response, "handleLinkToWb");
          }
        } else {
          onWbLinkOrUnlinkError(response, "handleLinkToWb");
        }
      })
      .catch((error) => onWbLinkOrUnlinkError(error, "handleLinkToWb"))
      .finally(() => {
        setAttachmentsInProcess((attachmentsInProcess) =>
          attachmentsInProcess.filter(
            (attachment) => attachment.id != payload.certificateDocumentId
          )
        );
      });
  };

  const handleRelinkLinkEcm = async (file: any) => {
    if (!file?.id) return showError("Failed!", "Attachment has no valid id.");

    let selectedFile = file;

    let newAttachements = attachments as IDocumentAttachments[];
    let selectedDocument = newAttachements.find(
      (attachment) => attachment.id == selectedFile.id
    );
    selectedDocument!.status = CertificateDocumentStatus.UploadToEcmInProgress;

    setAttachments([...newAttachements]);

    let currentAttachmentLinks = attachmentsInProcess as IDocumentAttachments[];
    currentAttachmentLinks.push(selectedFile);
    setAttachmentsInProcess([...currentAttachmentLinks]);

    let obj: any = {};
    obj.certificateDocumentId = file!.id;
    obj.ecmFileNodeId = file!.ecmFileNodeId;
    obj.certificateId = details.certificateId;
    obj.iPCertificateDetailsModel = details;

    CertificateListService.certificateAttachmentSingleLinkUploadECM(obj)
      .then((response) => {
        if (response) {
          if (response.isSuccess) {
            showSuccess(
              "Success!",
              "Uploading of document(s) has been successfully processed. " +
                "Please refer to Attachments Section of the certificate to view the status of the document(s)."
            );
          } else {
            onWbLinkOrUnlinkError(response, "handleLinkToWb");
          }
        } else {
          onWbLinkOrUnlinkError(response, "handleLinkToWb");
        }
      })
      .catch((error) =>
        showWarning(ecmUnavailableHeader, ecmUnavailableMessage)
      )
      .finally(() => {});
  };

  const handleDeleteAttachement = async (file: any) => {
    setFileToDelete(file.id);
    setShowDeleteFileConfirmation(true);
  };

  const handleDeleteFileToBlobStorage = async () => {
    CertificateListService.deleteFileFromBlob(fileToDelete)
      .then((response) => {
        if (!response?.isSuccess) {
          setShowDeleteFileConfirmation(false);
          showError("Error!", "Attachment(s) has not been deleted.");
          return;
        }

        setAttachments(
          attachments.filter((attachment: any) => attachment.id != fileToDelete)
        );
        setShowDeleteFileConfirmation(false);

        showSuccess(
          "Success!",
          "Attachment(s) has been deleted successfully from GMAWB File Storage."
        );
      })

      .catch(() => {
        setShowDeleteFileConfirmation(false);
        showError("Error!", "Attachment(s) has not been deleted");
      });
  };

  const deleteConfirmationMessage = () => {
    return (
      <span>
        The document(s) will be deleted from GMAWB File Storage.
        <br />
        <br />
        Would you like to continue?
      </span>
    );
  };

  const displayUnlinkEcmModalText = () => {
    return (
      <span>
        This change will not affect the document in ECM TIC.
        <br />
        <br />
        Are you sure you want to unlink the selected Document(s)?
      </span>
    );
  };
  const displayLinkEcmModalText = () => {
    return (
      <span>
        File(s) will be saved to ECM TIC and cannot be deleted.
        <br />
        <br />
        Would you like to continue?
      </span>
    );
  };
  const isStatusUploading = (status?: CertificateDocumentStatus): boolean => {
    return (
      status == CertificateDocumentStatus.UploadToEcmInProgress ||
      status == CertificateDocumentStatus.NotYetUploaded ||
      status == CertificateDocumentStatus.TestReportDuplicateLinkToEcmInProgress
    );
  };

  const isLinkInProcess = (fileId?: string): boolean => {
    if (attachmentsInProcess.find((attachment) => attachment.id == fileId))
      return true;

    return false;
  };

  const validateSignalRNotif = (fcnResult: any) => {
    const jsonResult = JSON.parse(fcnResult);

    if (jsonResult?.certificateDetailId == certificateDetailFromE2E?.id) {
      fetchAttachment(certificateDetailFromE2E?.workbenchUniqueCertificateId);

      if (jsonResult?.certificateDocumentIds) {
        jsonResult?.certificateDocumentIds.forEach(
          (certificateDocumentId: any) => {
            setAttachmentsInProcess((attachmentsInProcess) =>
              attachmentsInProcess.filter(
                (attachment) => attachment.id != certificateDocumentId
              )
            );
          }
        );
      }
    }
  };

  useEffect(() => {
    const withAttachments = _.map(attachmentFiles, (attachmentFile) => {
      return {
        ...attachmentFile,
        attachments: _.filter(attachments, [
          "documentType",
          attachmentFile.documentType,
        ]),
        isExpand: _.some(attachments, [
          "documentType",
          attachmentFile.documentType,
        ]),
      };
    });

    setFiles(withAttachments);
  }, [attachments]);

  useEffect(() => {
    if (signalRConnection) {
      signalRConnection.on("ecm-uploading-certificate-success", (result) => {
        validateSignalRNotif(result);
      });
      signalRConnection.on("ecm-uploading-certificate-failed", (result) => {
        validateSignalRNotif(result);
      });
    }
  }, [signalRConnection]);

  useEffect(() => {
    if (attachmentsInProcess.length == 0)
      fetchAttachment(certificateDetailFromE2E?.workbenchUniqueCertificateId);
  }, [attachmentsInProcess]);

  const getFileIcon = (itemFile: IDocumentAttachments): IconDefinition => {
    if (isStatusUploading(itemFile.status) || isLinkInProcess(itemFile.id)) {
      return faSpinner;
    }

    if (itemFile.status === CertificateDocumentStatus.UploadToEcmFailed) {
      return faExclamationCircle;
    }

    if (
      itemFile.status ===
      CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
    ) {
      return faInfoCircle;
    }

    return faCircleCheck;
  };
  useEffect(() => {
    const hasCertificateBeenActive = async () => {
      const result = await CertificateListService.getHasCertificateBeenActive(
        details?.certificateId
      );
      setHasCertificateBeenActive(result?.data);
    };

    hasCertificateBeenActive();
  }, [details]);

  return (
    <div className={`${classes["certificate-attachments__container"]}`}>
      <div className={classes["title__section"]}>Attachments</div>
      {attachmentFiles?.map((attachmentFile) => (
        <div key={attachmentFile.documentType}>
          <div className={classes["container"]}>
            <div
              className={classes["document-type"]}
              onClick={() => onExpand(attachmentFile)}
            >
              <span>{attachmentFile.display}</span>
              <FontAwesomeIcon
                className={`${classes["icons"]} ${classes["icons-ml"]}`}
                icon={attachmentFile.isExpand ? faCaretDown : faCaretRight}
              />
            </div>
          </div>

          {attachmentFile.isExpand &&
            attachmentFile.attachments.length === 0 && (
              <span className={classes["files"]}>No Files</span>
            )}

          {attachmentFile.isExpand &&
            attachmentFile.attachments.length > 0 &&
            attachmentFile.attachments.map((itemFile, index) => (
              <>
                <div
                  className={`ul-grid__row ${classes["files"]} ${
                    details?.certificateStatus !== "Under Revision"
                      ? classes["file-status-" + itemFile.status]
                      : ""
                  }`}
                >
                  <div className={`ul-grid__column ${classes["files-column"]}`}>
                    <div
                      className={`${classes["fileName"]} ${
                        classes[`fileName-status-${itemFile.status}`]
                      }`}
                    >
                      <span
                        onClick={() => downloadFile(itemFile)}
                        title={itemFile.originalFileName}
                        className={
                          isStatusUploading(itemFile.status) ||
                          isLinkInProcess(itemFile.id)
                            ? classes["text-btn"]
                            : `${classes["text-btn"]} ${classes["downloadLink"]}`
                        }
                      >
                        {itemFile.originalFileName}
                      </span>

                      {attachmentFile.documentType !==
                        CertificateDocumentType.Others && (
                        <div
                          aria-valuetext={getDocumentStatusText(
                            itemFile.status
                          )}
                          aria-label="status-indicator"
                          className={`${classes["status-indicator-container"]}`}
                        >
                          <FontAwesomeIcon
                            title={
                              itemFile.documentType !=
                              CertificateDocumentType.Others
                                ? getDocumentStatusText(itemFile.status)
                                : ``
                            }
                            icon={getFileIcon(itemFile)}
                            size={"xs"}
                            spin={
                              isStatusUploading(itemFile.status) ||
                              isLinkInProcess(itemFile.id)
                            }
                            className={`${classes["icons"]} ${
                              classes["icons-ml"]
                            }
                          ${getDocumentStatusIconColor(itemFile.status)}`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`ul-grid__column ${classes["actions-column"]}`}
                  >
                    {attachmentFile.documentType !==
                      CertificateDocumentType.Others &&
                      renderLinkButtons(itemFile)}
                  </div>
                  <div
                    className={`ul-grid__column ${classes["delete-column"]}`}
                  >
                    {renderDeleteButtons(itemFile)}
                  </div>
                </div>
              </>
            ))}
        </div>
      ))}

      <ConfirmationModal
        visible={displayUnlinkEcmModalPrompt}
        onCancel={() => {
          setAttachmentToUnlink(undefined);
          setDisplaydisplayUnlinkEcmModalPrompt(false);
        }}
        onConfirm={handleUnlinkEcm}
        headerDisplay={displayUnlinkEcmModalHeader}
        confirmationMessageHTML={displayUnlinkEcmModalText()}
        buttonNoDisplay={`No`}
        buttonYesDisplay={`Yes`}
      />

      <ConfirmationModal
        visible={showDeleteFileConfirmation}
        onCancel={() => setShowDeleteFileConfirmation(false)}
        onConfirm={() => handleDeleteFileToBlobStorage()}
        headerDisplay={"Delete File Confirmation"}
        confirmationMessageHTML={deleteConfirmationMessage()}
        buttonNoDisplay={`No`}
        buttonYesDisplay={`Yes`}
      />
      <ConfirmationModal
        visible={displayLinkEcmModalPrompt}
        onCancel={() => setDisplaydisplayLinkEcmModalPrompt(false)}
        onConfirm={() => handleLinkEcm()}
        headerDisplay={displayLinkEcmModalHeader}
        confirmationMessageHTML={displayLinkEcmModalText()}
        buttonNoDisplay={`No`}
        buttonYesDisplay={`Yes`}
      />

      <TestReportMatchedFoundPopup
        visible={testReportsValidatorHook.testReportMatchedFoundPopupVisible}
        onProceedWithoutDuplicate={() => {
          testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
        }}
        onProceedWithDuplicate={() => {
          testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
          handleLinkEcm(true);
        }}
        onClose={() => {
          testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
        }}
        rows={testReportsValidatorHook.matchedTestReports}
      />
    </div>
  );
};

export default CertificateDetailsAttachments;
