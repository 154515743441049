import React, { useEffect, useRef, useState } from "react";
import { E2EUserPaginatedModel } from "../../../../../../shared/models/E2EUserPaginatedModel";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import classes from "./ProjectAssignCoHandlerDropdown.module.scss";
import { Button } from "primereact/button";
import { createPortal, render } from "react-dom";
import { InputText } from "primereact/inputtext";
import useDebounce from "../../../../../../hooks/useDebounce";
import { ProjectUserSource } from "../ProjectAssignCoHandlerDialog";
import GMAWBUsersService from "../../../../../../services/GMAWBUsersService";
import HandlerType from "../../../enums/handler-type";

interface ProjectAssignCoHandlerDropdownProps {
  setValue: React.Dispatch<
    React.SetStateAction<E2EUserPaginatedModel | undefined>
  >;
  dropdownOptions: ProjectUserSource;
  setDropdownOptions: React.Dispatch<React.SetStateAction<ProjectUserSource>>;
  value: E2EUserPaginatedModel | undefined;
  handlerType: HandlerType;
  isGmaTcTask?: boolean;
}

interface DropdownValue {
  label: string;
  item: string;
  value: string;
  email: string;
}

const ProjectAssignCoHandlerDropdown = (
  props: ProjectAssignCoHandlerDropdownProps
) => {
  const dropdownRef = useRef<any>(null);
  const [searchCriteria, setSearchCriteria] = useState<string>(
    props.dropdownOptions.currentSearchPhrase
  );
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [forceUpdateComponent, setForceUpdateComponent] =
    useState<boolean>(false);

  useEffect(() => {
    execSearchInput();
  }, [searchCriteria]);

  const execSearchInput = useDebounce(async () => {
    if (props.dropdownOptions.currentSearchPhrase === searchCriteria) return;

    setLoadingItems(true);
    let { currentSkip, currentSearchPhrase } = props.dropdownOptions;

    currentSkip = 0;
    currentSearchPhrase = searchCriteria;

    const users = await GMAWBUsersService.getCOHandlerPaginatedUsers(
      currentSkip,
      props.dropdownOptions.currentTake,
      props.handlerType,
      props.isGmaTcTask,
      currentSearchPhrase
    );

    let userData: E2EUserPaginatedModel[] = structuredClone(users.data);

    if (props.value && props.value.displayName && props.value.normalizedEmail) {
      if (
        userData.some(
          (data) => data.normalizedEmail === props.value?.normalizedEmail
        )
      ) {
        userData = userData.filter(
          (data) => data.normalizedEmail !== props.value?.normalizedEmail
        );
      }

      userData.unshift({
        displayName: props.value.displayName,
        normalizedEmail: props.value.normalizedEmail,
      });
    }

    props.setDropdownOptions((current) => ({
      ...current,
      currentSkip: currentSkip,
      currentSearchPhrase: currentSearchPhrase,
      data: userData,
    }));

    setLoadingItems(false);
  }, 800);

  const onShowMoreClick = async () => {
    setLoadingItems(true);
    let { currentSkip, currentTake } = props.dropdownOptions;

    currentSkip += 5;
    currentTake = 5;

    const getUsersData = await GMAWBUsersService.getPaginatedUsers(
      currentSkip,
      props.dropdownOptions.currentTake,
      props.handlerType,
      props.isGmaTcTask,
      props.dropdownOptions.currentSearchPhrase
    );

    let userData: E2EUserPaginatedModel[] = structuredClone(getUsersData.data);

    if (props.value) {
      if (
        userData.some(
          (data) => data.normalizedEmail === props.value?.normalizedEmail
        )
      ) {
        userData = userData.filter(
          (data) => data.normalizedEmail !== props.value?.normalizedEmail
        );
      }
    }
    props.setDropdownOptions((current) => ({
      ...current,
      currentSkip: currentSkip,
      currentTake: currentTake,
      data: current.data.concat(getUsersData.data),
    }));

    setLoadingItems(false);
  };

  const onDropdownSelect = (e: DropdownChangeParams) => {
    const getE2EUser = props.dropdownOptions.data.find(
      (user) => user.normalizedEmail?.toLowerCase() === e.value.toLowerCase()
    )!;
    let dropdownData = structuredClone(props.dropdownOptions.data);

    if (
      dropdownData.some(
        (data) => data.normalizedEmail === getE2EUser.normalizedEmail
      )
    ) {
      dropdownData = props.dropdownOptions.data.filter(
        (data) => data.normalizedEmail !== getE2EUser.normalizedEmail
      );
    }

    dropdownData.unshift({
      displayName: getE2EUser.displayName,
      normalizedEmail: getE2EUser.normalizedEmail,
    });

    props.setDropdownOptions((current) => ({
      ...current,
      data: dropdownData,
    }));

    props.setValue(getE2EUser);
  };

  const InlineElementFooterTemplate = () => {
    return (
      <div className={`${classes["footer"]}`}>
        <Button
          className={`p-button-link ${classes["show-more-button"]}`}
          disabled={loadingItems}
          label={loadingItems ? "Loading" : "Show More"}
          onClick={(e) => {
            e.stopPropagation();
            onShowMoreClick();
          }}
        />
      </div>
    );
  };

  const InlineElementHeaderTemplate = () => {
    return (
      <div className={`${classes["header"]}`}>
        <div className={`p-input-icon-right`}>
          <InputText
            defaultValue={searchCriteria}
            onChange={(e) => setSearchCriteria(e.target.value.trim())}
            placeholder="Search"
            className={``}
            style={{ width: "100%" }}
          />
          <i className="pi pi-search" />
        </div>

        <div>
          <Button
            onClick={() => {
              dropdownRef.current?.setState({ overlayVisible: false });
            }}
            icon="pi pi-times"
            className={`p-button-rounded p-button-text p-button-plain ${classes["close-button"]}`}
          />
        </div>
      </div>
    );
  };

  const onShow = () => {
    setForceUpdateComponent(!forceUpdateComponent);
    const overlayRef = dropdownRef.current?.overlayRef.current;
    if (overlayRef) {
      const headerWrapper = document.createElement("div");
      headerWrapper.className = "p-dropdown-custom-header";

      overlayRef.appendChild(headerWrapper);

      render(<InlineElementHeaderTemplate />, headerWrapper);
    }
  };
  const valueTemplate = (option: DropdownValue) => {
    if (!option?.label) return <div>Select a CO Handler</div>;

    return <div>{option.label}</div>;
  };

  return (
    <>
      <Dropdown
        panelClassName={`${classes["project-collaborator-dropdown-panel"]}`}
        ref={dropdownRef}
        value={props.value?.normalizedEmail}
        optionDisabled={() => loadingItems}
        options={props.dropdownOptions?.data?.map((option) => {
          return {
            label: option.displayName,
            item: `${option.displayName} (${option.normalizedEmail})`,
            value: option.normalizedEmail,
            email: option.normalizedEmail,
          } as DropdownValue;
        })}
        itemTemplate={(option) => option.item}
        valueTemplate={valueTemplate}
        onChange={onDropdownSelect}
        onShow={onShow}
        placeholder="Select a CO Handler"
      />
      {dropdownRef.current?.overlayRef?.current &&
        createPortal(
          <InlineElementFooterTemplate />,
          dropdownRef.current?.overlayRef?.current
        )}
    </>
  );
};

export default ProjectAssignCoHandlerDropdown;
