import "./CertificateProductListHeader.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompressArrowsAlt, faExpandArrowsAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import BulkImport from "../bulk-import/BulkImport";
import CertificateProductListShowHide from "../certificate-product-list-show-hide/CertificateProductListShowHide";
import CertificateProductListFavorites from "../certificate-product-list-favorites/CertificateProductListFavorites";

const CertificateProductListHeader = (props) => {
  const [displayBulkImportModal, setdisplayBulkImportModal] = useState(false);
  const [position, setPosition] = useState("center");

  const dialogFuncMap = {
    displayBulkImportModal: setdisplayBulkImportModal,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const {
    columns,
    frozenColCount,
    favoriteName,
    favoriteList,
    setCertListColumns,
    setCertFrozenCount,
    onSaveFavorite,
    onEditFavorite,
    onDeleteFavorite,
    onClickFavorites,
    onClearFilters,
    expandAll,
    collapseAll,
    expandedRows
  } = props;

  return (
    <>
      <BulkImport
        dialogFuncMap={dialogFuncMap}
        displayBulkImportModal={displayBulkImportModal}
        position={position}
      />
      <div className="ul-grid__column -x100 -x100-mobile cert-list-header">
        <div className="ul-grid__column -x100 -x100-mobile buttons-div">
          <span className="float-right">
            <button
              className="ul-button -app-tertiary -medium -icon search-icon-btn"
              aria-label="Bulk Import"
              onClick={() => onClick("displayBulkImportModal")}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span> Bulk Import</span>
            </button>

            {/* <CertificateProductListFavorites
              favoriteName={favoriteName}
              favoriteList={favoriteList}
              onSaveFavorite={onSaveFavorite}
              onEditFavorite={onEditFavorite}
              onDeleteFavorite={onDeleteFavorite}
              onClickFavorites={onClickFavorites}
              onClearFilters={onClearFilters}
            /> */}

            <span >
             {!expandedRows &&(<button style={{margin:0, marginLeft: "0.5rem"}} onClick={expandAll} className="ul-button app-secondary -icon">
                <FontAwesomeIcon icon={faExpandArrowsAlt} />
              </button>)}
             {expandedRows && (<button style={{margin:0}} onClick={collapseAll} className="ul-button app-secondary -icon">
                <FontAwesomeIcon icon={faCompressArrowsAlt} />
              </button>)}
            </span>

            <CertificateProductListShowHide
              fields={columns}
              frozenColCount={frozenColCount}
              setCertListColumns={setCertListColumns}
              setCertFrozenCount={setCertFrozenCount}
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default CertificateProductListHeader;
