import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reloadCertificateList,
  selectCertificateList,
  selectIsLoadingCertificateList,
} from "../../features/certificateList/certificateListSlice";
import CertificateListService from "../../services/CertificateListService";
import CustomFilter from "../../shared/custom-filter/CustomFilter";
import useToastr from "./../../hooks/useToastr";
import CertificateListRefiners from "./certificate-list-refiners/CertificateListRefiners";
import "./CertificateListView.scss";
import classes from "./CertificateListView.module.scss";
import CertificateDetails from "./cetificate-list/certificate-details/CertificateDetails";
import CertificateListGrid from "./cetificate-list/certificate-list-grid/CertificateListGrid";
import certificateCustomFilterColumns from "./data/certificate-custom-filter-columns";
import certificateGridColumns from "./data/certificate-grid-columns";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmDialog } from "primereact/confirmdialog";
import certificateRefinerColumns from "../../shared/refiners/data/certificate-refiner-columns";
import LoadingIndicator from "../../shared/loading-indicator/LoadingIndicator";
import { ConfirmDialog } from "primereact/confirmdialog";
import FeatureFlagService from "../../services/FeatureFlagService";
import TestReportMatchedFoundPopup from "./cetificate-list/add-new-certificate/test-report-matched-found-popup/TestReportMatchedFoundPopup";
import { CertificateDocumentStatus } from "../../enums/CertificateDocumentStatus";
import { CertificateDocumentType } from "../../enums/CertificateDocumentType";
import { useTestReportValidator } from "./cetificate-list/add-new-certificate/test-report-matched-found-popup/useTestReportValidator";

import {
  ecmUnavailableHeader,
  ecmUnavailableMessage,
} from "../../utils/constants/ecm.constants";
const CertificateListView = () => {
  const isReadOnly =
    new URL(window.location.href).searchParams.get("isReadOnly") === "true";
  const toast = useRef(null);
  const dispatch = useDispatch();
  const testReportsValidatorHook = useTestReportValidator();
  const [certListData, setCertListData] = useState([]);
  const [certListColumns, setCertListColumns] = useState(
    certificateGridColumns
  );
  const [certFrozenCount, setCertFrozenCount] = useState(1);
  const [refinerColumns, setRefinerColumns] = useState(
    certificateRefinerColumns
  );
  const [activeCount, setActiveCount] = useState([]);
  const [expiringSoonCount, setExpiringSoonCount] = useState([]);
  const [withDrawnCount, setWithDrawnCount] = useState([]);
  const [underRevisionCount, setUnderRevisionCount] = useState([]);
  const [obsoleteCount, setObsoleteCount] = useState([]);
  const [totalCertificateCount, setTotalCertificateCount] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageCount, setPageCount] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [customFilters, setCustomFilters] = useState([]);
  const [refiners, setRefiners] = useState([]);
  const [statusRefiners, setStatusRefiners] = useState([]);
  const [statusListRefiners, setStatusListRefiners] = useState([]);
  const [hasRefiners, setHasRefiners] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRefinerVisible, setRefinerVisible] = useState(true);
  const [lazyParams, setLazyParams] = useState({
    first: 1,
    last: 100,
    rows: 100,
    page: 1,
    isFirstPageLinkDisabled: false,
    isPrevPageLinkDisabled: false,
    isNextPageLinkDisabled: false,
    isLastPageLinkDisabled: false,
  });
  const [globalSearchText, setGlobalSearchText] = useState("");
  const [favoriteName, setFavoriteName] = useState("");
  const [favoriteList, setFavoriteList] = useState([]);
  const [showCertificateDetails, setShowCertificateDetails] = useState(false);
  const globalSearchTextRef = useRef();
  const currentPageRef = useRef();
  const { showSuccess, showError, showWarning } = useToastr();
  const { isReloadGrid } = useSelector(selectCertificateList);
  const isLoading = useSelector(selectIsLoadingCertificateList);
  const [isConfirmDialogShow, setIsConfirmDialogShow] = useState(false);
  const [rowForActivation, setRowForActivation] = useState(null);
  const [testReportValidationOutput, setTestReportValidationOutput] =
    useState(null);
  const [clearSelectedValues, setClearSelectedValues] = useState(false);

  const getCertificateListDataFromAllStates = () => {
    getCertificateListData(
      globalSearchText,
      pageCount,
      currentPage,
      customFilters,
      refiners,
      statusRefiners
    );
  };

  const getDefaultSettings = () => {
    getFavorites();
  };

  const getFavorites = async function (favoriteId = 0) {
    let favorites = await CertificateListService.getFavorites(4);
    if (!favorites) {
      favorites = [];
    }
    const defaultFavorite =
      favoriteId > 0
        ? favorites.find((f) => f.savedViewFavoriteId === favoriteId)
        : favorites.find((f) => f.isDefault);

    if (defaultFavorite) {
      const settings = JSON.parse(defaultFavorite.settings);
      const frozenCount = settings.certListColumns.filter(
        (f) => f.isFrozen
      ).length;
      const params = lazyParams;
      params.rows = settings.pageCount;

      if (!settings.statusRefiners) {
        settings.statusRefiners = [];
      }

      getCertificateListData(
        settings.globalSearchText,
        settings.pageCount,
        currentPage,
        settings.customFilters,
        settings.refiners,
        settings.statusRefiners,
        params
      );

      setCertListColumns(settings.certListColumns);
      setCertFrozenCount(frozenCount);
      setGlobalSearchText(settings.globalSearchText);
      setPageCount(settings.pageCount);
      setCustomFilters(settings.customFilters);
      setRefiners(settings.refiners);
      setFavoriteName(defaultFavorite.description);
      globalSearchTextRef.current.value = settings.globalSearchText;
    } else {
      onClearFilters();
    }

    setFavoriteList(favorites);
  };

  const onSaveFavorite = (description, isDefault) => {
    const settings = {};
    settings.certListColumns = certListColumns;
    settings.globalSearchText = globalSearchText;
    settings.customFilters = customFilters;
    settings.refiners = refiners;
    settings.statusRefiners = statusRefiners;
    settings.pageCount = pageCount;

    const data = {};
    data.description = description;
    data.isDefault = isDefault;
    data.savedViewTypeId = 4;
    data.settings = JSON.stringify(settings);

    saveFavorites(data);
  };

  const onEditFavorite = (id, description, isDefault) => {
    const data = favoriteList.find((f) => f.savedViewFavoriteId === id);
    data.description = description;
    data.isDefault = isDefault;

    saveFavorites(data, "udpated");
  };

  const onDeleteFavorite = (id) => {
    const data = favoriteList.find((f) => f.savedViewFavoriteId === id);
    data.isDeleted = true;

    saveFavorites(data, "deleted");
  };

  const saveFavorites = async function (data, transaction = "saved") {
    const result = await CertificateListService.saveFavorites(data);

    showSuccess("Success", `Favorite has been successfully ${transaction}`);
    getFavorites(result);
  };

  const onClickFavorites = (id) => {
    getFavorites(id);
  };

  const enablerAction = () => {
    setRefinerVisible(!isRefinerVisible);
  };

  const onClearFilters = () => {
    const params = lazyParams;
    params.first = 1;
    params.rows = 100;
    params.page = 1;
    params.last = 100;

    getCertificateListData("", 100, 1, [], [], [], params);

    setGlobalSearchText("");
    setPageCount(100);
    setCurrentPage(1);
    setCustomFilters([]);
    setRefiners([]);
    setStatusRefiners([]);
    setFavoriteName("");
    currentPageRef.current.value = "1";
    globalSearchTextRef.current.value = "";
  };

  const getCertificateListData = async function (
    globalSearchParam,
    pageCountParam,
    currentPageParam,
    customFiltersParam,
    refinersParam,
    statusRefinersParam = statusRefiners,
    lazyParam = lazyParams
  ) {
    setLoading(true);

    lazyParam.first = lazyParam.first == 0 ? 1 : lazyParam.first;
    lazyParam.last = lazyParam.last == 0 ? lazyParam.rows : lazyParam.last;
    lazyParam.page = lazyParam.page == 0 ? 1 : lazyParam.page;
    currentPageParam = currentPageParam == 0 ? 1 : currentPageParam;

    let currentRefiners = refinersParam;
    if (currentRefiners.length === 0) {
      currentRefiners = statusRefinersParam;
    } else {
      setStatusRefiners([]);
    }

    const result = await CertificateListService.getCertificateListData({
      GlobalSearch: globalSearchParam,
      PageCount: pageCountParam,
      CurrentPage: currentPageParam,
      CustomFilters: customFiltersParam,
      Refiners: currentRefiners,
    });

    if (
      result &&
      result.certificate &&
      Array.isArray(result.certificate) &&
      result.certificate.length > 0
    ) {
      const totalPage = Math.ceil(result.totalCount / pageCountParam);
      setTotalPageCount(totalPage);
      setCertListData(result.certificate);
      setTotalCertificateCount(result.totalCount);

      lazyParam.last = pageCountParam * currentPageParam;
      if (lazyParam.last > parseInt(result.totalCount)) {
        lazyParam.last = parseInt(result.totalCount);
      }

      pageChange(lazyParam, totalPage);
    } else {
      setTotalPageCount(0);
      setCertListData(result.certificate);
      setTotalCertificateCount(0);

      if (currentPageParam > 1) {
        lazyParam.first = 1;
        lazyParam.last = lazyParam.rows;
        lazyParam.page = 1;

        getCertificateListData(
          globalSearchParam,
          pageCountParam,
          1,
          customFiltersParam,
          refinersParam,
          statusRefinersParam,
          lazyParam
        );
      } else {
        lazyParam.first = 0;
        lazyParam.last = 0;
        lazyParam.page = 0;

        pageChange(lazyParam);
      }
    }

    if (refinersParam.length > 0) {
      setHasRefiners(true);
    } else {
      setHasRefiners(false);
    }

    setLoading(false);
  };

  const getCertificateStatistics = async function () {
    const result = await CertificateListService.getCertificateStatistics();
    if (result) {
      setActiveCount(result.active);
      setExpiringSoonCount(result.expiringSoon);
      setWithDrawnCount(result.withdrawn);
      setUnderRevisionCount(result.underRevision);
      setObsoleteCount(result.obsolete);

      const total =
        parseInt(result.active) +
        parseInt(result.withdrawn) +
        parseInt(result.underRevision) +
        parseInt(result.obsolete);

      setTotalCertificateCount(total);
    }
  };

  const replaceFilterBySelected = (value, refinerId) => {
    if (!value || !value.name) return;

    setRefiners((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState = newState.filter((i) => i.Id !== refinerId);

      newState.push({
        Id: refinerId,
        Value: value.name,
        DisplayText: value.name,
      });

      getCertificateListData(
        globalSearchText,
        pageCount,
        currentPage,
        customFilters,
        newState
      );

      return newState;
    });
  };

  const addSelectedToYourFilters = (value, refinerId) => {
    if (refinerId === "status") {
      setRefiners((prevState) => {
        let newState = prevState.filter((i) => i.Id !== refinerId);
        value.forEach((item) => {
          newState.push({
            Id: refinerId,
            Value: item.name,
            DisplayText: item.name,
          });
        });

        getCertificateListData(
          globalSearchText,
          pageCount,
          currentPage,
          customFilters,
          newState
        );
        return newState;
      });
      return;
    }

    if (value && Array.isArray(value) && value.length > 0) {
      setRefiners((prevState) => {
        let newState = JSON.parse(JSON.stringify(prevState));
        value.forEach((item) => {
          var existingRefiner = prevState.find(
            (i) => i.Value === item.name && i.Id === refinerId
          );
          if (!existingRefiner) {
            newState.push({
              Id: refinerId,
              Value: item.name,
              DisplayText: item.name,
            });
          }
        });

        getCertificateListData(
          globalSearchText,
          pageCount,
          currentPage,
          customFilters,
          newState
        );
        return newState;
      });
    } else if (value && value.name && value.code) {
      setRefiners((prevState) => {
        let newState = [];
        let existingRefiner = null;

        if (prevState) {
          newState = JSON.parse(JSON.stringify(prevState));
          existingRefiner = prevState.find(
            (i) => i.Value === value.name && i.Id === refinerId
          );
        }
        if (!existingRefiner) {
          newState.push({
            Id: refinerId,
            Value: value.name,
            DisplayText: value.name,
          });
        }

        getCertificateListData(
          globalSearchText,
          pageCount,
          currentPage,
          customFilters,
          newState
        );
        return newState;
      });
    }
  };

  const onChangeDatePickerRefiner = (value, refinerId) => {
    if (value && value.Value && value.Condition) {
      setRefiners((prevState) => {
        var newState = JSON.parse(JSON.stringify(prevState));
        newState = newState.filter(
          (i) => !(i.Condition === value.Condition && i.Id === refinerId)
        );

        newState.push({
          Id: refinerId,
          Value: value.Value,
          Condition: value.Condition,
          DisplayText: value.DisplayText,
        });

        getCertificateListData(
          globalSearchText,
          pageCount,
          currentPage,
          customFilters,
          newState
        );
        return newState;
      });
    }
  };

  const onClickRemoveRefinerGroup = (refinerId) => {
    setRefiners((prevState) => {
      const stateTemp = prevState.filter((i) => i.Id !== refinerId);
      if (refinerId === "status") setStatusListRefiners(stateTemp);

      getCertificateListData(
        globalSearchText,
        pageCount,
        currentPage,
        customFilters,
        stateTemp
      );
      return stateTemp;
    });
  };

  const onClickRemoveRefiner = (item, isCompareFromValue = true) => {
    setRefiners((prevState) => {
      let stateTemp;

      if (isCompareFromValue) {
        stateTemp = prevState.filter(
          (i) => !(i.Value === item.Value && i.Id === item.Id)
        );
      } else {
        stateTemp = prevState.filter(
          (i) => !(i.Condition === item.Condition && i.Id === item.Id)
        );
      }

      setStatusListRefiners(stateTemp.filter((i) => i.Id === "status"));
      getCertificateListData(
        globalSearchText,
        pageCount,
        currentPage,
        customFilters,
        stateTemp
      );
      return stateTemp;
    });
  };

  const onClickGlobalSearch = (searchText) => {
    getCertificateListData(
      searchText,
      pageCount,
      currentPage,
      customFilters,
      refiners
    );

    setGlobalSearchText(searchText);
  };

  const onClickStatusFilter = (status) => {
    setRefiners([]);

    if (status) {
      const statusFilter = [
        {
          Id: "status",
          Value: status,
        },
      ];

      getCertificateListData(
        globalSearchText,
        pageCount,
        currentPage,
        customFilters,
        [],
        statusFilter
      );

      setStatusRefiners(statusFilter);
    } else {
      getCertificateListData(
        globalSearchText,
        pageCount,
        currentPage,
        customFilters,
        [],
        []
      );

      setStatusRefiners([]);
    }
  };

  const onChangePage = (params) => {
    params.first = (params.page - 1) * params.rows + 1;
    const last = params.page * params.rows;
    params.last = last > totalCertificateCount ? totalCertificateCount : last;

    getCertificateListData(
      globalSearchText,
      params.rows,
      params.page,
      customFilters,
      refiners,
      statusRefiners,
      params
    );
  };

  const pageChange = (params, totalPages = 0) => {
    if (params.page > 1) {
      params.isFirstPageLinkDisabled = false;
      params.isPrevPageLinkDisabled = false;
    } else {
      params.isFirstPageLinkDisabled = true;
      params.isPrevPageLinkDisabled = true;
    }

    if (params.page < totalPages) {
      params.isLastPageLinkDisabled = false;
      params.isNextPageLinkDisabled = false;
    } else {
      params.isLastPageLinkDisabled = true;
      params.isNextPageLinkDisabled = true;
    }

    setPageCount(params.rows);
    setCurrentPage(params.page);
    setLazyParams(params);
    currentPageRef.current.value = params.page;
  };

  const onClickClearYourFilters = () => {
    setRefiners([]);
    getCertificateListData(
      globalSearchText,
      pageCount,
      currentPage,
      customFilters,
      []
    );
  };

  const onClickSetCustomFilters = () => {
    if (
      customFilters &&
      Array.isArray(customFilters) &&
      customFilters.length > 0
    ) {
      getCertificateListDataFromAllStates();
    }
  };

  const onClickClearCustomFilters = () => {
    setCustomFilters([]);
    getCertificateListData(
      globalSearchText,
      pageCount,
      currentPage,
      [],
      refiners
    );
  };
  useEffect(() => {
    setClearSelectedValues(!refiners.length)
  }, [refiners]);

  useEffect(() => {
    getCertificateStatistics();
    getDefaultSettings();
  }, []);

  useEffect(() => {
    if (isReloadGrid) {
      getCertificateListDataFromAllStates();
      dispatch(reloadCertificateList(false));
    }
  }, [isReloadGrid]);

  const onActivateCertificate = async (row) => {
    const certificateId = row.certificateId;

    if (row.certificateStatus != "Under Revision") return;

    if (!FeatureFlagService.getECMFeatureFlag()) {
      let payload = {
        certificateId: certificateId,
        skipValidation: true,
        projectNumber: row.projectNumber,
      };
      await activateCertificate(payload);
      return;
    }

    const certDetailsResp =
      await CertificateListService.getCertificateDetailsForActivation(
        certificateId
      );

    if (!certDetailsResp.isSuccess) return;

    let certDetails = certDetailsResp.data;

    if (!certDetails || certDetails.certificateStatus != "Under Revision")
      return;

    const data = {
      certificateId: certificateId,
      skipValidation: true,
      projectNumber: certDetails.projectNumber,
    };
    setRowForActivation(data);

    if (certDetails.hasValidAttachments && certDetails.hasBaseModel) {
      setIsConfirmDialogShow(true);
    } else {
      await activateCertificate(data);
    }
  };

  const onWithdrawCertificate = (row) => {
    const data = {
      certificateId: row.certificateId,
      skipValidation: true,
      projectNumber: row.projectNumber,
    };

    withdrawCertificate(data);
  };

  const proceedActivationWithDuplicate = async () => {
    testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
    await activateCertificate(rowForActivation);
  };

  const validateTestReport = async () => {
    if (
      FeatureFlagService.getECMFeatureFlag() &&
      FeatureFlagService.getLinkTestReportToMultipleCertificateFlag()
    ) {
      const certificateDetailFromWb =
        await CertificateListService.getCertificateDetailByWorkbenchCertificateId(
          rowForActivation?.certificateId
        );

      const certificateDetailsWithDocuments =
        await CertificateListService.getCertificateDetailsWithDocuments(
          certificateDetailFromWb?.data?.id
        );

      var duplicateTestReportRows =
        certificateDetailsWithDocuments?.data?.certificateDocuments.filter(
          (attachment) =>
            attachment.documentType === CertificateDocumentType.TestReport
        );

      let ownerReference = certificateDetailFromWb.data.partySiteNumber;

      let scheme = certificateDetailFromWb.data.certificationScheme;

      let baseModel = certificateDetailFromWb.data.baseModel;

      let filesToValidate = duplicateTestReportRows.map(
        (file) => file.originalFileName
      );

      var validationResult = await testReportsValidatorHook.validateTestReports(
        filesToValidate,
        ownerReference,
        scheme,
        baseModel
      );

      if ((await validationResult).hasDuplicate > 0) {
        testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(true);
        setTestReportValidationOutput(validationResult.matchedTestReports);
      } else {
        await activateCertificate(rowForActivation);
      }
    } else {
      await activateCertificate(rowForActivation);
    }
  };

  const activateCertificate = async (data) => {
    setLoading(true);
    data = !data ? rowForActivation : data;

    const result = await CertificateListService.activateCertificate(data);
    if (result.isSuccess === true) {
      if (FeatureFlagService.getECMFeatureFlag()) {
        await processCertificateDetailsUpdate(data.certificateId);
      } else {
        showSuccess("Success", "Certificate activated successfully");
        getCertificateListDataFromAllStates();
      }
    } else {
      setLoading(false);
      showError("Error", result.message);
    }
  };

  const processCertificateDetailsUpdate = async (certificateId) => {
    const result =
      await CertificateListService.updateActivatedCertificateDetails(
        certificateId
      );
    showSuccess("Success", "Certificate activated successfully");
    getCertificateListDataFromAllStates();
    setLoading(false);
    if (!result.isSuccess) {
      showWarning(ecmUnavailableHeader, ecmUnavailableMessage);
    }
  };

  const withdrawCertificate = async function (data) {
    const result = await CertificateListService.withdrawCertificate(data);
    if (result.isSuccess === true) {
      showSuccess("Success", "Certificate withdrawn successfully");

      getCertificateListDataFromAllStates();
    } else {
      showError("Error", result.message);
    }
  };

  const handleDeleteCertificate = async (data) => {
    confirmDialog({
      message: () => {
        return (
          <>
            Are you sure you want to delete this record? This cannot be undone.
            <div className={classes["note"]}>
              (Note: If this is a modification to an existing certificate,
              deleting this draft will revert the certificate to the most recent
              Active version).
            </div>
          </>
        );
      },
      header: "Confirm Certificate Deletion",
      className: classes["delete-confirmation-dialog"],
      accept: async () => {
        setShowCertificateDetails(false);
        setLoading(true);

        const handleError = () => {
          showError(
            "Error",
            "There is an error processing your request. Please try again later."
          );
          setLoading(false);
        };

        try {
          const { isSuccess } =
            await CertificateListService.deleteIPCertificate(
              data.certificateId
            );

          if (isSuccess) {
            showSuccess("Success!", "Certificate successfully deleted.");
            dispatch(reloadCertificateList(true));
          } else handleError();
        } catch {
          handleError();
        }
      },
    });
  };

  const confirmDialogBody = () => {
    return (
      <>
        <div className="custom-confirm-dialog-body">
          <div className="custom-space-below">
            File(s) will be saved to ECM TIC and cannot be deleted.
          </div>
          <div>Would you like to Continue?</div>
        </div>
      </>
    );
  };

  const confirmFooter = () => {
    return (
      <>
        <div className="custom-confirm-dialog-footer">
          <button
            className="ul-button -app-primary -medium custom-confirm-dialog-accept-btn"
            onClick={acceptConfirmDialog}
          >
            YES
          </button>
          <button
            className="ul-button -medium default-cancel-button custom-confirm-dialog-reject-btn"
            onClick={rejectConfirmDialog}
          >
            NO
          </button>
        </div>
      </>
    );
  };

  const acceptConfirmDialog = async () => {
    setIsConfirmDialogShow(false);
    await validateTestReport();
  };

  const rejectConfirmDialog = () => {
    setIsConfirmDialogShow(false);
  };

  return (
    <>
      <LoadingIndicator isLoading={isLoading} />
      <div className="ul-grid__row cert-list-view-container">
        {isRefinerVisible ? (
          <div className="ul-grid__column -x20 -offset-10-desktop -x100-mobile cert-search-and-refiners-area">
            <CustomFilter
              globalSearchTextRef={globalSearchTextRef}
              columns={certificateCustomFilterColumns}
              conditions={["Contains", "Equal"]}
              customFilters={customFilters}
              setCustomFilters={setCustomFilters}
              onClickSetCustomFilters={onClickSetCustomFilters}
              onClickClearCustomFilters={onClickClearCustomFilters}
              refiners={refiners}
              refinerColumns={refinerColumns}
              hasRefiners={hasRefiners}
              onClickRemoveRefiner={onClickRemoveRefiner}
              onClickRemoveRefinerGroup={onClickRemoveRefinerGroup}
              onClickGlobalSearch={onClickGlobalSearch}
              onClickClearYourFilters={onClickClearYourFilters}
            />
            <CertificateListRefiners
              refiners={refiners}
              setRefiners={setRefiners}
              onClickRemoveRefiner={onClickRemoveRefiner}
              addSelectedToYourFilters={addSelectedToYourFilters}
              replaceFilterBySelected={replaceFilterBySelected}
              onChangeDatePickerRefiner={onChangeDatePickerRefiner}
              statusListRefiners={statusListRefiners}
              clearSelectedValues={clearSelectedValues}
            />
          </div>
        ) : null}

        <div
          className={
            !isRefinerVisible
              ? "cert-list-grid-area-full"
              : "cert-list-grid-area"
          }
        >
          <div className="enabler">
            <button
              title="Click here to show/hide"
              className={`${!isRefinerVisible ? "hide" : ""}`}
              onClick={enablerAction}
            >
              {isRefinerVisible ? (
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
              ) : (
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              )}
            </button>
          </div>

          <CertificateListGrid
            isReadOnly={isReadOnly}
            loading={loading}
            setLoading={setLoading}
            currentPageRef={currentPageRef}
            certListData={certListData}
            columns={certListColumns}
            setColumns={setCertListColumns}
            refiners={refiners}
            statusRefiners={statusRefiners}
            customFilters={customFilters}
            globalSearchText={globalSearchText}
            frozenColCount={certFrozenCount}
            activeCount={activeCount}
            expiringSoonCount={expiringSoonCount}
            withDrawnCount={withDrawnCount}
            underRevisionCount={underRevisionCount}
            obsoleteCount={obsoleteCount}
            totalCertificateCount={totalCertificateCount}
            totalPageCount={totalPageCount}
            pageCount={pageCount}
            currentPage={currentPage}
            lazyParams={lazyParams}
            favoriteName={favoriteName}
            favoriteList={favoriteList}
            onClickStatusFilter={onClickStatusFilter}
            onChangePage={onChangePage}
            setCertListColumns={setCertListColumns}
            setCertFrozenCount={setCertFrozenCount}
            setShowCertificateDetails={setShowCertificateDetails}
            onSaveFavorite={onSaveFavorite}
            onEditFavorite={onEditFavorite}
            onDeleteFavorite={onDeleteFavorite}
            onClickFavorites={onClickFavorites}
            onClearFilters={onClearFilters}
            onActivateCertificate={onActivateCertificate}
            onWithdrawCertificate={onWithdrawCertificate}
            onDeleteCertificate={handleDeleteCertificate}
          />
        </div>

        {showCertificateDetails && (
          <CertificateDetails
            isReadOnly={isReadOnly}
            onDeleteCertificate={handleDeleteCertificate}
            showCertificateDetails={showCertificateDetails}
            setShowCertificateDetails={setShowCertificateDetails}
          />
        )}
      </div>
      <Toast ref={toast} />
      <ConfirmDialog
        style={{ width: "520px" }}
        className="custom-confirm-dialog"
        visible={isConfirmDialogShow}
        header="Save and Activate Confirmation"
        message={confirmDialogBody}
        footer={confirmFooter}
        onHide={() => rejectConfirmDialog()}
      />

      <TestReportMatchedFoundPopup
        visible={testReportsValidatorHook.testReportMatchedFoundPopupVisible}
        onProceedWithoutDuplicate={() => {
          testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
        }}
        onProceedWithDuplicate={() => {
          proceedActivationWithDuplicate();
        }}
        onClose={() => {
          testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
        }}
        rows={testReportValidationOutput}
      />
    </>
  );
};
export default CertificateListView;
