import {
  IconDefinition,
  faAward,
  faCircleNotch,
  faClipboard,
  faCreditCard,
  faEnvelopeOpenText,
  faFileInvoiceDollar,
  faFileSignature,
  faFileUpload,
  faGlobe,
  faLaptop,
  faPaperPlane,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import classes from "./PmrDmIcons.module.scss";
import { AddNewCertMilestoneDetails } from "../../../../../shared/models/AddNewCertMilestoneDetails";
import { LocalStorageModel } from "../../../../../shared/models/SaveLocalStorageModel";
import { PmrE2eProjectLineWorkflowMapping } from "../../interfaces/pmr-e2e-project-line-workflow-mapping";
import DocumentService from "../../../../../services/DocumentService";
import {
  CORTaskName,
  GMATCTaskName,
} from "../../../../../utils/constants/flex-task-name.constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectShowFlexTasks } from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
import usePromiseCallback from "../../../../../hooks/usePromiseCallback";
import PaymentOption from "../../enums/payment-option";
import SubmitOption from "../../enums/submit-option";
import clsx from "clsx";
import { selectSelectRefreshProjectId } from "../../../../../features/projectManagement/projectManagementSlice";

interface PmrDmIconsProps {
  rowData?: any;
}

const createLoadingFontProps = (
  defaultFont: IconDefinition,
  isLoading: boolean
) => {
  return {
    icon: isLoading ? faCircleNotch : defaultFont,
    spin: isLoading,
    opacity: isLoading ? 0.5 : 1.0,
  } as FontAwesomeIconProps;
};

const ulPayment = "UL: Payment";

export default function PmrDmIcons(props: PmrDmIconsProps) {
  const isCOR =
    props.rowData?.flexTaskName?.toLowerCase() === CORTaskName.toLowerCase();
  const hasGmaTcTask: boolean = props.rowData?.hasGmaTcTask && props.rowData?.hasGmaTcTask === true;
  const [isLinkToCMLoading, setIsLinkToCMLoading] = useState<boolean>(false);
  const showFlexTask = useSelector(selectShowFlexTasks);
  const refreshProjectId = useSelector(selectSelectRefreshProjectId);

  const mappedWorkFlow = props.rowData
    ?.e2EProjectLineWorkflowMapping as PmrE2eProjectLineWorkflowMapping;
  const hasGpi =
    mappedWorkFlow &&
    mappedWorkFlow.workflowProjectMapId &&
    mappedWorkFlow.workflowPackageId;

  const paymentOption = props.rowData.paymentOption as PaymentOption;
  const submitOption = props.rowData.submitOption as SubmitOption;

  const containsMilestones = (...names: string[]) => {
    if (!showFlexTask.isOn) {
      return names.includes(props.rowData.milestone);
    }

    if ((props.rowData?.e2EProjectLineMilestones ?? []).length <= 0) return;
    return props.rowData.e2EProjectLineMilestones.some(
      (milestone: { name: string }) => names.includes(milestone.name)
    );
  };

  const createDMURL = () => {
    if (!hasGpi) return;
    const url = new URL(`${process.env.REACT_APP_GMAWB_DOCUMENT_URL}`);

    if (mappedWorkFlow?.workflowPackageId) {
      url.searchParams.append("packageId", mappedWorkFlow.workflowPackageId);
    }

    if (mappedWorkFlow?.workflowName) {
      url.searchParams.append("workflowName", mappedWorkFlow.workflowName);
    }

    return url;
  };

  const sentGIR2CToCustomer = usePromiseCallback(async () => {
    const url = createDMURL();
    if (!url) return;
    url.pathname += "/details/r2c-list";
    url.searchParams.append("workflowStateId", "2");
    url.searchParams.append("currentTab", "2");

    const payload = Object.fromEntries(url.searchParams.entries()) as any;
    const isSavedToLocalStorage = await DocumentService.saveLocalStorage(
      payload
    );

    if (isSavedToLocalStorage) {
      window.open(url, "_blank")?.focus();
    }
  });
  const goToMappingAndTranscription = usePromiseCallback(async () => {
    const url = createDMURL();
    if (!url) return;
    url.pathname += "/details/mapping-and-transcription";
    url.searchParams.append("workflowStateId", "3");
    url.searchParams.append("currentTab", "3");

    const payload = Object.fromEntries(url.searchParams.entries()) as any;
    const isSavedToLocalStorage = await DocumentService.saveLocalStorage(
      payload
    );

    if (isSavedToLocalStorage) {
      window.open(url, "_blank")?.focus();
    }
  });
  const goToUploadDocuments = usePromiseCallback(async () => {
    const url = createDMURL();
    if (!url) return;
    url.pathname += "/details/upload";
    url.searchParams.append("workflowStateId", "4");
    url.searchParams.append("currentTab", "4");

    const payload = Object.fromEntries(url.searchParams.entries()) as any;
    const isSavedToLocalStorage = await DocumentService.saveLocalStorage(
      payload
    );

    if (isSavedToLocalStorage) {
      window.open(url, "_blank")?.focus();
    }
  });
  const goToSendPackage = usePromiseCallback(async () => {
    const url = createDMURL();
    if (!url) return;
    url.pathname += "/details/send-package";
    url.searchParams.append("workflowStateId", "5");
    url.searchParams.append("currentTab", "5");

    const payload = Object.fromEntries(url.searchParams.entries()) as any;
    const isSavedToLocalStorage = await DocumentService.saveLocalStorage(
      payload
    );

    if (isSavedToLocalStorage) {
      window.open(url, "_blank")?.focus();
    }
  });

  const goToWireTransferPayment = () => {
    window
      .open(
        "https://apps.powerapps.com/play/e/default-70115954-0ccd-45f0-87bd-03b2a3587569/a/d393fd63-0e0c-4eb2-bb7c-40dffeda7d8e?tenantId=70115954-0ccd-45f0-87bd-03b2a3587569&source=portal&screenColor=RGBA%280%2c176%2c240%2c1%29&skipAppMetadata=true",
        "_blank"
      )
      ?.focus();
  };

  const goToCreditCardPayment = () => {
    window
      .open(
        "https://apps.powerapps.com/play/e/default-70115954-0ccd-45f0-87bd-03b2a3587569/a/22102652-eaea-4168-915d-993e13c5fca7?tenantId=70115954-0ccd-45f0-87bd-03b2a3587569&source=portal&screenColor=RGBA%280%2c176%2c240%2c1%29&skipAppMetadata=true",
        "_blank"
      )
      ?.focus();
  };

  const goToCertificateManagement = async () => {
    setIsLinkToCMLoading(true);

    if (
      !mappedWorkFlow ||
      !mappedWorkFlow.workflowProjectMapId ||
      !mappedWorkFlow.workflowPackageId
    )
      return;

    let model = "[]";

    if (props.rowData?.e2EProjectLineModelInfoLinkToCM) {
      model = JSON.stringify([props.rowData?.e2EProjectLineModelInfoLinkToCM]);
    }

    let orderLineNumber = props.rowData?.orderLineNumber;

    if(props.rowData?.isCpq) {
        const lineNumberIndex = orderLineNumber.indexOf("-") - 1;
        orderLineNumber = orderLineNumber.slice(0, lineNumberIndex) + ".0" + orderLineNumber.slice(lineNumberIndex);
    }

    const addNewCertMilestoneDetails: AddNewCertMilestoneDetails = {
      schemeId: props.rowData?.schemeId,
      certSchemeName: props.rowData?.schemeName,
      projectFlexId: props.rowData?.flexProjectId,
      projectNumber: props.rowData?.projectNumber,
      projectName: props.rowData?.flexProjectName,
      orderLineNumber: orderLineNumber,
      model: model,
      partySiteNumber: props.rowData?.productOwnerPartySiteNumber,
      tasks: "[]",
      isCor: isCOR,
    };

    const createSaveLocalStorageModel: LocalStorageModel = {
      packageId: mappedWorkFlow.workflowProjectMapId,
      addNewCertMilestoneDetails: JSON.stringify(addNewCertMilestoneDetails),
      isAddNewCertFromProject: true,
      psn: props.rowData?.productOwnerPartySiteNumber,
    };

    DocumentService.saveLocalStorage(createSaveLocalStorageModel)
      .then((response) => {
        if (response && response === true) {
          window
            .open(
              `${process.env.REACT_APP_CM_URL}?packageId=${createSaveLocalStorageModel.packageId}&isAddNewCertFromProject=${createSaveLocalStorageModel.isAddNewCertFromProject}`,
              "_blank"
            )
            ?.focus();
        }
        setIsLinkToCMLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const dmIconOverrideProps: React.HTMLAttributes<HTMLDivElement> = {
    className: classes["dm-icon"],
  };
  if (!hasGpi) {
    dmIconOverrideProps.title = "DM Integrations are currently processing...";
  }

  useEffect(() => {
    if (props.rowData.id === refreshProjectId) {
    }
  }, [refreshProjectId]);

  return (
    <div className={clsx(classes["dm-icons"], !hasGpi && "disabled")}>
      {containsMilestones("UL: Send GI/R2C to Customer") && (
        <div
          title="Go to Generate R2C Checklist and Forms"
          onClick={
            sentGIR2CToCustomer.isLoading ? undefined : sentGIR2CToCustomer.send
          }
          {...dmIconOverrideProps}
        >
          <FontAwesomeIcon
            {...createLoadingFontProps(
              faClipboard,
              sentGIR2CToCustomer.isLoading
            )}
          />
        </div>
      )}
      {containsMilestones("UL: Send Application Forms to Customer") && (
        <div
          title="Go to Mapping and Transcription"
          style={{
            marginRight: "-5px",
          }}
          onClick={
            goToMappingAndTranscription.isLoading
              ? undefined
              : goToMappingAndTranscription.send
          }
          {...dmIconOverrideProps}
        >
          <FontAwesomeIcon
            {...createLoadingFontProps(
              faFileSignature,
              goToMappingAndTranscription.isLoading
            )}
          />
        </div>
      )}

      {containsMilestones(
        "Customer: Send All Documents to UL",
        "Customer: Send All Samples to UL",
        "UL: Review and Prepare Application Package",
        "UL: Submit Samples to Test Lab/In-Country Testing"
      ) && (
        <div
          title="Go to Upload Documents"
          onClick={
            goToUploadDocuments.isLoading ? undefined : goToUploadDocuments.send
          }
          {...dmIconOverrideProps}
        >
          <FontAwesomeIcon
            {...createLoadingFontProps(
              faFileUpload,
              goToUploadDocuments.isLoading
            )}
          />
        </div>
      )}
      {containsMilestones("UL: Receive/Send Certificate to Customer") && (
        <>
          {!hasGmaTcTask && (
            <div
              title="Go to Certificate Management"
              onClick={
                isLinkToCMLoading ? undefined : goToCertificateManagement
              }
              {...dmIconOverrideProps}
            >
              <FontAwesomeIcon
                {...createLoadingFontProps(faAward, isLinkToCMLoading)}
              />
            </div>
          )}
        </>
      )}

      {containsMilestones("UL: GMA Transaction Center") && (
        <>
          {hasGmaTcTask && (
            <div
              title="Go to Certificate Management"
              onClick={
                isLinkToCMLoading ? undefined : goToCertificateManagement
              }
              {...dmIconOverrideProps}
            >
              <FontAwesomeIcon
                {...createLoadingFontProps(faAward, isLinkToCMLoading)}
              />
            </div>
          )}
        </>
      )}
      {containsMilestones("UL: Submit to Authority/Authority Review") && (
        <>
          <div
            title="Go to Send Package"
            onClick={
              goToSendPackage.isLoading ? undefined : goToSendPackage.send
            }
            {...dmIconOverrideProps}
          >
            <FontAwesomeIcon
              {...createLoadingFontProps(
                faPaperPlane,
                goToSendPackage.isLoading
              )}
            />
          </div>
          {submitOption === SubmitOption.AuthorityReviewFlex && (
            <div
              title="Assign to UL CO Mailbox"
              onClick={() => {
                props.rowData.openAssignCoHandlerDialog(
                  props.rowData,
                  props.rowData.milestone ? true : false,
                  false
                );
              }}
              {...dmIconOverrideProps}
            >
              <FontAwesomeIcon icon={faUserPlus} />
            </div>
          )}

          {submitOption === SubmitOption.AuthorityReviewEmail && (
            <div title="EMAIL" {...dmIconOverrideProps}>
              <FontAwesomeIcon icon={faLaptop} />
            </div>
          )}

          {submitOption === SubmitOption.AuthorityReviewOnline && (
            <div title="ONLINE" {...dmIconOverrideProps}>
              <FontAwesomeIcon icon={faGlobe} />
            </div>
          )}

          {submitOption === SubmitOption.AuthorityReviewPost && (
            <div title="POST" {...dmIconOverrideProps}>
              <FontAwesomeIcon icon={faEnvelopeOpenText} />
            </div>
          )}
        </>
      )}

      {containsMilestones("UL: Payment") && (
        <>
          {paymentOption === PaymentOption.CreditCard && (
            <div
              title="UL: Payment – Credit Card"
              onClick={goToCreditCardPayment}
              {...dmIconOverrideProps}
            >
              <FontAwesomeIcon icon={faCreditCard} />
            </div>
          )}

          {paymentOption === PaymentOption.WireTransfer && (
            <div
              title="UL: Payment – Wire Transfer"
              onClick={goToWireTransferPayment}
              {...dmIconOverrideProps}
            >
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
