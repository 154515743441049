import { useEffect, useState } from "react";

const usePrimeReactDataTable = (dataTableRef: any, columns: any) => {
  const [databaseKey, setDatabaseKey] = useState(1);

  // HACK: Workaround for incorrect left position computation of primereact datatable
  // when arranging frozen columns.
  useEffect(() => {
    setTimeout(() => {
      var frozenColumns = document.querySelectorAll("th.p-frozen-column");
      var currentLeft = 0;

      for (let x = 0; x < frozenColumns.length; x++) {
        const frozenCol: any = frozenColumns[x];
        frozenCol.style.left = currentLeft + "px";
        currentLeft += frozenCol.offsetWidth;
      }

      setTimeout(() => {
        for (let x = 0; x < frozenColumns.length; x++) {
          dataTableRef.current?.resetColumnOrder();
        }
      });
    });
  }, [columns]);

  // HACK: Creates a function that destroys and recreates the Datatable on resize 
  // event to fix the issue on resizing frozen columns after resizing non-frozen ones.
  const fixedColumnResizedFunction = (onColumnResize: (e: any) => void) => {
    return (e: any) => {
      onColumnResize(e);

      setTimeout(() => {
        setDatabaseKey(Date.now());
      });
    };
  };

  return {
    fixedColumnResizedFunction,
    databaseKey,
  };
};

export default usePrimeReactDataTable;
