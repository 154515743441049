import { faClose, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "primereact/skeleton";
import { ArrowContainer, Popover } from "react-tiny-popover";
import classes from "../../shared/model-search/ip-model-details-popover/IpModelDetailsPopover.module.scss";

const GpiListModelColumnPopOver = ({
  isWindowOpen,
  loading,
  baseModel,
  modelVariants,
  model,
  onOpenWindow,
  onCloseWindow,
  onClickOutside,
}) => {
  return (
    <Popover
      isOpen={isWindowOpen}
      positions={["right"]}
      onClickOutside={onClickOutside}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"white"}
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className={classes["container"]}>
            <div className={classes["close-icon-container"]}>
              <FontAwesomeIcon
                onClick={onCloseWindow}
                icon={faClose}
                className={classes["close-icon"]}
              />
            </div>
            <div className={classes["row"]}>
              <div className={classes["label"]}>Base Model No:</div>
              <div className={classes["value"]}>
                <div>{baseModel ? baseModel : " - "}</div>
              </div>
            </div>
            <br />
            <div className={classes["row"]}>
              <div className={classes["label"]}>Model Variants:</div>
              <div className={classes["value"]}>
                <div>
                  {modelVariants.length > 0
                    ? modelVariants[0].modelVariant.modelName
                    : " - "}
                </div>
              </div>
            </div>
            {modelVariants.map((item, index) => {
              if (index !== 0) {
                return (
                  <div className={classes["row"]}>
                    <div className={classes["label"]}></div>
                    <div className={classes["value"]}>
                      <div>{item.modelVariant.modelName}</div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </ArrowContainer>
      )}
    >
      <div>
        <FontAwesomeIcon
          onClick={(e) =>
            isWindowOpen ? onCloseWindow(e) : onOpenWindow(e, model)
          }
          icon={faInfoCircle}
        />
      </div>
    </Popover>
  );
};

export default GpiListModelColumnPopOver;
