// authProvider.js
import { MsalAuthProvider, LoginType } from "react-aad-msal";

// Msal Configurations
const config = {
  auth: {
    authority:
      "https://login.microsoftonline.com/70115954-0ccd-45f0-87bd-03b2a3587569",
    clientId: "2adf549b-0b1b-4a2f-99cb-c1be3e3ce4fb",
    redirectUri: process.env.REACT_APP_APP_WEB_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [".default"],
};

// Options
const options = {
  loginType: LoginType.Popup,
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
