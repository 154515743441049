import React, { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initialRadioState,
  selectGPIFormConfig,
  selectIsReadOnly,
  selectRadioDetails,
  updateIsSaveDisabled,
  updateRadioDetails,
} from "../../../../features/generalProductInformation/generalProductInformationSlice";
import useToastr from "../../../../hooks/useToastr";
import Checkbox from "../../form/checkbox/Checkbox";
import CollapsibleForms from "../../form/collapsible-forms/CollapsibleForms";
import FormControl from "../../form/form-control/FormControl";
import { radioDetailsSchema } from "../product-details-schema";
import RadioTechnologyDialog from "../voltage-details/radio-technology-dialog.jsx/RadioTechnologyDialog";
import classes from "./RadioDetails.module.scss";
import RadioTechnologyTreeSelect from "../../form/radio-technology-tree-select/RadioTechnologyTreeSelect";

const RadioDetails = () => {
  const dispatch = useDispatch();
  const radioDetailsValues = useSelector(selectRadioDetails);
  const [radioCount, setRadioCount] = useState();
  const { showSuccess } = useToastr();
  const [radioTechnologyDialogVisible, setRadioTechnologyDialogVisible] =
    useState(false);
  const [selectedRadio, setSelectedRadio] = useState();
  const isReadOnly = useSelector(selectIsReadOnly);
  const gPIFormConfig = useSelector(selectGPIFormConfig);

  const equipmentRadioTypes = [
    "Receiver",
    "Transceiver",
    "Transmitter",
    "Other",
  ];

  const modulationTypesMapper = [
    {
      radioTechnology: "802.11 bg",
      inputType: "multiselect",
      getModulationTypeOptions: (radioTechnologyTypes) => {
        const modulationTypes = [];

        if (radioTechnologyTypes?.some((r) => r.radioTechnologyType === "b"))
          modulationTypes.push("DSSS - CCK BPSK", "DSSS -DQPSK");

        if (
          radioTechnologyTypes.some((r) =>
            ["a", "b", "g", "n (Wi-Fi 4)", "ac (Wi-Fi 5)", "Other"].includes(
              r.radioTechnologyType
            )
          )
        )
          modulationTypes.push(
            "OFDM - BPSK",
            "OFDM - QPSK",
            "OFDM - 16QAM",
            "OFDM - 64QAM",
            "OFDM - 256QAM"
          );

        if (modulationTypes.length > 0) modulationTypes.push("Other");

        return modulationTypes;
      },
    },
    {
      radioTechnology: "BLE (Bluetooth Low Energy)",
      inputType: "multiselect",
      default: "GFSK",
      getModulationTypeOptions: () => ["GFSK", "Other"],
    },
    {
      radioTechnology: "BT (Bluetooth)",
      inputType: "multiselect",
      default: "GSK",
      getModulationTypeOptions: () => ["GSK", "π/4-DQPSK", "8DPSK", "Other"],
    },
    {
      radioTechnology: "Cellular",
      inputType: "text",
    },
    {
      radioTechnology: "NFC",
      inputType: "multiselect",
      default: "ASK",
      getModulationTypeOptions: () => ["ASK", "Other"],
    },
    {
      radioTechnology: "RFID",
      inputType: "multiselect",
      default: "OOK",
      getModulationTypeOptions: () => ["OOK", "ASK", "Other"],
    },
    {
      radioTechnology: "SRD (Short Range Device)/Mifare (13.56 MHz)",
      inputType: "text",
    },
    {
      radioTechnology: "WPT (Wireless Power Transfer)",
      inputType: "text",
    },
    {
      radioTechnology: "Zigbee 802.15.4",
      inputType: "multiselect",
      default: "OOK",
      getModulationTypeOptions: () => ["OOK", "Other"],
    },
    {
      radioTechnology: "Other",
      inputType: "text",
    },
  ];

  const equipmentRadioTypeOptions = equipmentRadioTypes.map((e) => ({
    equipmentRadioType: e,
  }));

  const handleChange = (field, value) => {
    dispatch(
      updateRadioDetails({
        ...radioDetailsValues,
        radioCount:
          field === "radios" ? value.length : radioDetailsValues.radios.length,
        [field]: value,
      })
    );
    dispatch(updateIsSaveDisabled(false));
  };

  const handleChangeEquipmentRadioType = (e) => {
    dispatch(
      updateRadioDetails({
        ...radioDetailsValues,
        equipmentRadioTypes: e.value,
        otherEquipmentRadioType: e.value?.some(
          (o) => o.equipmentRadioType === "Other"
        )
          ? radioDetailsValues.otherEquipmentRadioType
          : "",
      })
    );
    dispatch(updateIsSaveDisabled(false));
  };

  const handleChangeOtherModulationType = (e, radioIndex) => {
    handleChangeRadioField("otherModulationType", radioIndex, e.target.value);
    dispatch(updateIsSaveDisabled(false));
  };

  const getValueAndSchema = (field) => {
    return {
      schema: radioDetailsSchema.fields[field],
      value: radioDetailsValues[field],
    };
  };

  const selectedEquipmentRadioTypesTemplate = (option) => {
    return option?.equipmentRadioType ? (
      <label>{option.equipmentRadioType}</label>
    ) : null;
  };

  const handleChangeRadioField = (field, index, value) => {
    const newRadios = radioDetailsValues.radios.map((radio, i) =>
      i === index ? { ...radio, [field]: value } : radio
    );

    handleChange("radios", newRadios);
  };

  const handleChangeRadioFields = (index, newValueObjects) => {
    const newRadios = radioDetailsValues.radios.map((radio, i) =>
      i === index ? { ...radio, ...newValueObjects } : radio
    );

    handleChange("radios", newRadios);
  };

  const handleAddRadio = (addCount) => {
    const newRadios = [...radioDetailsValues.radios];

    for (let x = 0; x < addCount; x++) {
      newRadios.push(initialRadioState);
    }

    handleChange("radios", newRadios);
  };

  const handleRemoveRadios = (deleteCount) => {
    const newRadios = [...radioDetailsValues.radios];
    newRadios.splice(newRadios.length - deleteCount, deleteCount);

    handleChange("radios", newRadios);

    showSuccess("Success", `Radio(s) removed successfully.`);
  };

  const handleDeleteRadio = (index) => {
    const newRadios = [...radioDetailsValues.radios];
    newRadios.splice(index, 1);

    handleChange("radios", newRadios);

    showSuccess("Success", `Radio(s) removed successfully.`);
  };

  const handleApplyRadioTechnology = (value, index) => {
    setRadioTechnologyDialogVisible(false);

    // Set default checked modulation type depending on selected radio technology.
    const defaultModulationTypes = [];

    if (value.radioTechnology) {
      const modulationTypesMap = modulationTypesMapper.find(
        (m) => m.radioTechnology === value.radioTechnology
      );

      if (modulationTypesMap?.default)
        defaultModulationTypes.push({
          modulationType: modulationTypesMap.default,
        });
    }

    const newRadioTechnologyValues = {
      ...value,
      modulationTypes: defaultModulationTypes,
      otherModulationType: "",
      radioTechnologyTypes: value.radioTechnologyTypes?.map((r) => ({
        radioTechnologyType: r,
      })),
    };

    const newRadios = radioDetailsValues.radios.map((radio, i) =>
      i === index ? { ...radio, ...newRadioTechnologyValues } : radio
    );

    handleChange("radios", newRadios);
  };

  const handleSelectModulationType = (modulationType, radio, radioIndex) => {
    const selectedModulationTypes = radio.modulationTypes;

    // If radio technology is already selected, unselect.
    if (
      selectedModulationTypes?.some((m) => m.modulationType === modulationType)
    ) {
      const newModulationTypes = selectedModulationTypes.filter(
        (m) => m.modulationType !== modulationType
      );

      handleChangeRadioFields(radioIndex, {
        modulationTypes: newModulationTypes,

        // If other option is unselected, clear the "Other" input.
        otherModulationType:
          modulationType === "Other" ? "" : radio.otherModulationType,
      });
      return;
    }

    handleChangeRadioField("modulationTypes", radioIndex, [
      ...(selectedModulationTypes || []),
      {
        modulationType,
      },
    ]);
  };

  const getModulationTypeInput = (radio, radioIndex) => {
    const modulationTypesMap = modulationTypesMapper.find(
      (m) => m.radioTechnology === radio.radioTechnology
    );

    const modulationTypeOptions = modulationTypesMap?.getModulationTypeOptions
      ? modulationTypesMap.getModulationTypeOptions(radio.radioTechnologyTypes)
      : [];

    return (
      <div className={classes["container"]}>
        {modulationTypesMap?.inputType === "multiselect" && (
          <>
            <h5 style={{ fontSize: "1rem" }}>Type of Modulation</h5>
            <div className={classes["modulation-type-options-container"]}>
              {modulationTypeOptions.map((m) => {
                const inputId =
                  modulationTypesMap.radioTechnology + m + radioIndex;

                return (
                  <div key={m} className={classes["modulation-type-field"]}>
                    <Checkbox
                      checked={radioDetailsValues.radios[
                        radioIndex
                      ].modulationTypes?.some((_m) => _m.modulationType === m)}
                      containerClassName={classes["checkbox-container"]}
                      onChange={() =>
                        handleSelectModulationType(m, radio, radioIndex)
                      }
                      inputId={inputId}
                      label={m + (m === "Other" ? " (Please specify)" : "")}
                    />

                    {m === "Other" && (
                      <InputText
                        value={
                          radioDetailsValues.radios[radioIndex]
                            .otherModulationType
                        }
                        onChange={(e) =>
                          handleChangeOtherModulationType(e, radioIndex)
                        }
                        disabled={
                          !radioDetailsValues.radios[
                            radioIndex
                          ].modulationTypes?.some(
                            (m) => m.modulationType === "Other"
                          )
                        }
                        className={classes["other-modulation-type"]}
                      />
                    )}
                  </div>
                );
              })}
            </div>

            <label className={classes["modulation-options-help-text"]}>
              Please select all radio modulation types that apply.
            </label>
          </>
        )}

        {(modulationTypesMap?.inputType === "text" ||
          !radio.radioTechnology) && (
            <FormControl
              label="Type of Modulation"
              value={radio.otherModulationType}
              control={
                <InputText
                  disabled={!radio.radioTechnology}
                  onChange={(e) =>
                    handleChangeRadioField(
                      "otherModulationType",
                      radioIndex,
                      e.target.value
                    )
                  }
                />
              }
              controlClassName="otherModulationType"
              helpText={"Please specify all radio modulation types that apply."}
            />
          )}
      </div>
    );
  };

  const radioFormTemplate = (radio, index) => {
    return (
      <>
        <div className={classes["radio-technology-field"]}>
          <h5>Radio Technology</h5>
          <div>
            <RadioTechnologyTreeSelect
              data={radio}
              handleTreeSelectChange={(e) => {
                handleApplyRadioTechnology(e, index);
              }}
              disabled={isReadOnly}
              controlClassName="radioTechnology"
            />
          </div>
        </div>

        <FormControl
          label="Frequency Band"
          value={radio["frequencyBand"]}
          control={
            <InputText
              onChange={(e) =>
                handleChangeRadioField("frequencyBand", index, e.target.value)
              }
            />
          }
          controlClassName="frequencyBand"
          helpText="Please specify the radio frequency bands. ex: 2400-2483.5MHz, 13.553-13.567MHz etc."
        />

        <FormControl
          label="Frequency of Operation"
          value={radio["operationFrequency"]}
          control={
            <InputText
              onChange={(e) =>
                handleChangeRadioField(
                  "operationFrequency",
                  index,
                  e.target.value
                )
              }
            />
          }
          controlClassName="operationFrequency"
          helpText="Please specify the radio frequency of operation. ex: 2412-2472MHz, 13.56MHz etc."
        />

        <FormControl
          label="Antenna Gain"
          value={radio["antennaGain"]}
          control={
            <InputText
              onChange={(e) =>
                handleChangeRadioField("antennaGain", index, e.target.value)
              }
            />
          }
          controlClassName="antennaGain"
          helpText="Please specify the radio antenna gain. ex: -0.5dBi etc."
        />

        {getModulationTypeInput(radio, index)}

        <FormControl
          label="Emission Designation (ITU Code)"
          value={radio["emissionDesignation"]}
          control={
            <InputText
              onChange={(e) =>
                handleChangeRadioField(
                  "emissionDesignation",
                  index,
                  e.target.value
                )
              }
            />
          }
          controlClassName="emissionDesignation"
          helpTextTemplate={() => {
            return (
              <div style={{ marginTop: "6px" }}>
                Please specify the radio ITU Code. For additional information
                see:{" "}
                <a
                  target="_blank"
                  href="https://www.itu.int/en/ITU-R/terrestrial/workshops/wrs12/Miscellaneous/Appendix1.pdf"
                  rel="noreferrer"
                >
                  https://www.itu.int/en/ITU-R/terrestrial/workshops/wrs12/Miscellaneous/Appendix1.pdf
                </a>
                <div style={{ marginTop: "10px" }}>
                  NOTE: If the module has Canada ISED approval, this can be
                  found on the ISED License. Search the license details using
                  the certificate number on the following website:{" "}
                  <a
                    target="_blank"
                    href="https://sms-sgs.ic.gc.ca/equipmentSearch/searchRadioEquipments?execution=e1s1&lang=en"
                    rel="noreferrer"
                  >
                    https://sms-sgs.ic.gc.ca/equipmentSearch/searchRadioEquipments?execution=e1s1&lang=en
                  </a>{" "}
                  ex: ASK, FSK etc.
                </div>
              </div>
            );
          }}
        />

        <FormControl
          label="Transmit Power or Power Range"
          value={radio["transmissionPowerOrPowerRange"]}
          control={
            <InputText
              onChange={(e) =>
                handleChangeRadioField(
                  "transmissionPowerOrPowerRange",
                  index,
                  e.target.value
                )
              }
            />
          }
          controlClassName="transmissionPowerOrPowerRange"
          helpText="Please specify the radio transmit power range. W, mW, dBm, dBmeirp, dBuV/m at 10m, dBuA/m at 300m,,,,"
        />

        <FormControl
          label="Bandwidth"
          value={radio["bandwidth"]}
          control={
            <InputText
              onChange={(e) =>
                handleChangeRadioField("bandwidth", index, e.target.value)
              }
            />
          }
          controlClassName="bandwidth"
          helpText="Please specify the radio bandwidth. ex: WLAN: 20MHz, 40MHz, 80MHz, Bluetooth: Hopping off 1MHz / Hopping on 79MHz"
        />

        <FormControl
          label="Channel Spacing"
          value={radio["channelSpacing"]}
          control={
            <InputText
              onChange={(e) =>
                handleChangeRadioField("channelSpacing", index, e.target.value)
              }
            />
          }
          controlClassName="channelSpacing"
          helpText="Please specify the radio channel spacing. ex: 1MHz etc."
        />
      </>
    );
  };

  return (
    <div className="form-section">
      <div className="form-section-header">
        <h3 className="form-section-title">
          Radio Details
          <small>
            ( All information below should be identical on all documentation.
            Ex: the product label, manual and test reports )
          </small>
        </h3>
      </div>

      <div className="form-section-content">
        <div className={classes["equipment-radio-type-container"]}>
          <div>
            <FormControl
              label="Equipment Radio Type"
              helpText="Please select the most appropriate."
              value={radioDetailsValues.equipmentRadioTypes}
              controlClassName={`${classes["equipment-radio-type-container-select"]} equipmentRadioType`}
              control={
                <MultiSelect
                  display="chip"
                  removeIcon={"pi pi-times"}
                  placeholder="-- Select --"
                  options={equipmentRadioTypeOptions}
                  optionLabel="equipmentRadioType"
                  onChange={handleChangeEquipmentRadioType}
                />
              }
            />
          </div>

          {radioDetailsValues.equipmentRadioTypes?.some(
            (o) => o.equipmentRadioType === "Other"
          ) && (
              <div className={classes["equipment-radio-type-other-container"]}>
                <FormControl
                  {...getValueAndSchema("otherEquipmentRadioType")}
                  control={
                    <InputText
                      onChange={(e) =>
                        handleChange("otherEquipmentRadioType", e.target.value)
                      }
                    />
                  }
                  controlClassName={`otherEquipmentRadioType`}
                  helpText="Please specify “Other” Equipment Radio Type"
                />
              </div>
            )}
        </div>

        <FormControl
          {...getValueAndSchema("radioSpecificationComment")}
          control={
            <InputText
              onChange={(e) =>
                handleChange("radioSpecificationComment", e.target.value)
              }
            />
          }
          label="Radio Specification Comment"
          controlClassName={`radioSpecificationComment`}
          helpText="Please specify all radio technologies utilized in the end product. ex: WLAN, Bluetooth (Ver 4.1), 13.56MHz RFID, Keyless transmitter, Telemeter."
        />

        <div
          style={{ marginLeft: "-12%", marginRight: "-12%", marginTop: "40px" }}
        >
          <CollapsibleForms
            items={radioDetailsValues.radios}
            itemsCount={radioDetailsValues.radioCount}
            onAddItems={handleAddRadio}
            onRemoveItems={handleRemoveRadios}
            onDeleteItem={handleDeleteRadio}
            onSetItemsCount={setRadioCount}
            formTitleTemplate={(item, index) => "Radio " + (index + 1)}
            formTemplate={radioFormTemplate}
            showNumberOfItemsInput={true}
            deletable={radioDetailsValues.radios?.length > 1}
            numberOfItemsInputLabel="If there is more than one radio, enter # of radios that will be incorporated into the product."
            confirmDeleteHeaderLabel="Delete Radio"
            confirmDeleteMultipleMessageTemplate={(count) =>
              `Are you sure you want to remove the ${count} most recent radio(s)?`
            }
            confirmDeleteSingleMessageTemplate={() =>
              "Are you sure you want to delete this radio?"
            }
            maxNumberOfItemsAllowed={gPIFormConfig?.maxNumberOfRadioDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default RadioDetails;
