import { Fragment, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import routeConfig from "../config/routeConfig";
import "./AuthorizedContent.scss";

const renderRoutes = (routes) => {
  return routes.map((route, index) =>
    route.routes?.length > 0 ? (
      <Fragment>
        <Route
          key={route.key}
          exact
          path={route.path}
          component={route.component}
        />
        {renderRoutes(route.routes)}
      </Fragment>
    ) : (
      <Route
        key={route.key}
        exact
        path={route.path}
        component={route.component}
      />
    )
  );
};

const AuthorizedContent = () => {
  const defaultRoute = routeConfig.find((route) => route.default);
  const history = useHistory();

  // If user requested specific path before authentication and is 
  // redirected to the different page,
  // redirect the user to that first requested path.
  const redirectUserToRequestedPath = () => {
    if (!localStorage.getItem("requested-path")) return;

    const requestedPath = localStorage.getItem("requested-path");
    localStorage.removeItem("requested-path");

    if (requestedPath !== window.location.pathname) history.push(requestedPath);
  };

  useEffect(() => {
    redirectUserToRequestedPath();
  }, []);

  const tableContent = (
    <Fragment>
      <div className="ul-app -fullheight">
        <div className="ul-app__main">
          <div className="ul-app__body">
            <Switch>
              {renderRoutes(routeConfig)}
              {defaultRoute && (
                <Route path="/" component={defaultRoute.component} />
              )}
              <Route path="*">Not Founds</Route>
            </Switch>
          </div>
        </div>
      </div>
    </Fragment>
  );

  return tableContent;
};

export default AuthorizedContent;
