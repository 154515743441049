import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import classes from "./ProjectOrderlineMismatchECDDialog.module.scss";
import { useEffect, useState } from "react";
import ConfirmationModalV2 from "../../../../../shared/confirmation-modal-v2/ConfirmationModalV2";

interface ProjectOrderlineMismatchECDDialogProps {
  onClose: () => void;
  visible: boolean;
  data: MismatchECDDataModel | null;
}

export interface MismatchECDDataModel {
  projectNumber?: string;
  orderLineNumber?: string;
  orderLineECD?: string | null;
  previousECD?: string | null;
}

export const ProjectOrderlineMismatchECDDialog = (
  props: ProjectOrderlineMismatchECDDialogProps
) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const modalHeader = "Updated Estimated Completion Date (ECD)";

  const colTemplate = (data: any, opt: ColumnBodyOptions) => (
    <div
      className={`${classes["col-text-div"]}
        } ul-grid__column`}
    >
      {data[opt.field]}
    </div>
  );

  const table = (
    <DataTable
      value={[
        {
          projectNumber: props.data?.projectNumber,
          orderLineNumber: props.data?.orderLineNumber,
          previousECD: props.data?.previousECD,
          orderLineECD: props.data?.orderLineECD,
        },
      ]}
      className={classes["adjustments-table"]}
      size="small"
      showGridlines={true}
    >
      <Column
        header="Project Number"
        field="projectNumber"
        body={colTemplate}
        className={`${classes["table-col"]} ${classes["col-projectNumber"]}`}
      />
      <Column
        header="Order Line Number"
        field="orderLineNumber"
        body={colTemplate}
        className={`${classes["table-col"]} ${classes["col-orderLineNumber"]}`}
      />
      <Column
        header="Previous ECD"
        field="previousECD"
        body={colTemplate}
        className={`${classes["table-col"]} ${classes["col-previousECD"]}`}
      />
      <Column
        header="Updated ECD"
        field="orderLineECD"
        body={colTemplate}
        className={`${classes["table-col"]} ${classes["col-orderLineECD"]}`}
      />
    </DataTable>
  );

  const footerTemplate = (
    <>
      <Button onClick={() => onClose()} className={classes["btn-ok"]}>
        OK
      </Button>
    </>
  );

  const renderTableHeader = () => {
    return (
      <>The Order Line ECD has been updated and its schedule/s recalibrated.</>
    );
  };

  const bodyTemplate = (
    <>
      {renderTableHeader()}
      {table}
    </>
  );

  const onClose = () => props.onClose();

  useEffect(() => {
    setIsModalVisible(props.visible);
  }, [props.visible]);

  return (
    <ConfirmationModalV2
      bodyClassName={classes["modal-body"]}
      style={{ width: "40vw" }}
      visible={isModalVisible}
      onClose={onClose}
      headerDisplay={modalHeader}
      customFooter={footerTemplate}
      confirmationMessageHTML={bodyTemplate}
    ></ConfirmationModalV2>
  );
};

export default ProjectOrderlineMismatchECDDialog;
