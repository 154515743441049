import "./ProjectOrderDetails.scss";
import { AutoComplete } from "primereact/autocomplete";
import { useEffect, useState } from "react";
import ProjectDetailsService from "../../../../../services/ProjectDetailsService";
import { Dropdown } from "primereact/dropdown";
import {
  updateSelectedProjectDetails,
  updateLineDetails,
  updateOrderLineNumbers,
  updateSelectedOrderLineNumber,
  updateModelDetails,
  updatePartySiteNumber,
  selectCertDetailValues,
  updateProdDetailsModelList,
  updateCertificateScheme,
  updateModelList,
} from "../../../../../features/createCertificate/createCertificateSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectValues } from "../../../../../features/createCertificate/createCertificateSlice";
import useToastr from "../../../../../hooks/useToastr";
import { flexProjectContactRole } from "../../../../../utils/constants/flex-project-contact-role.constants";
import CertificateListService from "../../../../../services/CertificateListService";

const ProjectOrderDetails = (props) => {
  const {
    certificateId,
    certificateScheme,
    localData,
    isEditCert,
    isModifyCert,
    setIsDisableContinue,
  } = props;

  const dispatch = useDispatch();
  const value = useSelector(selectValues);

  const [projects, setProjects] = useState(null);
  const [selectedProject, setSelectedProject] = useState(
    value.selectedProjectDetails?.projectNumber
  );
  const [orders, setOrders] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(
    value.selectedProjectDetails?.orderNumber
  );
  const [lineDetails, setLineDetails] = useState(value.lineDetails);
  const [orderLineNumbers, setOrderLineNumbers] = useState(
    value.orderLineNumbers
  );
  const [selectedOrderLineNumber, setSelectedOrderLineNumber] = useState(
    value.selectedOrderLineNumber
  );
  const [isFetchingProject, setIsFetchingProject] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(
    value.selectedProjectDetails
  );
  const [errors, setErrors] = useState([]);
  const [isInitial, setIsInitial] = useState(true);
  const { showWarning } = useToastr();

  const getProjectSuggestions = async (event) => {
    const value = event.query.trim();
    const projectNumbers = await ProjectDetailsService.getProjectSuggestions(
      value
    );
    setProjects(projectNumbers);
  };

  const getOrderSuggestions = async (event) => {
    const value = event.query.trim();
    const orderNumbers = await ProjectDetailsService.getOrderSuggestions(value);
    setOrders(orderNumbers);
  };

  const processProjectDetail = (item, data) => {
    let tempLineDetails = [];
    data.value.forEach((d) => {
      //Filter - Get Child Lines
      const lineDatas = d.projectServiceLines.filter(
        (l) => l.lineNumber.split(".").length === 3
      );

      if (lineDatas.length === 0) {
        return;
      }

      for (var i = 0; i < lineDatas.length; i++) {
        const projectContacts = getProjectContact(d.projectContacts);

        lineDatas[i].partySiteNumber = projectContacts.partySiteNumber;
        lineDatas[i].companyId = d.companyId;
        lineDatas[i].address = projectContacts.address;
        lineDatas[i].city = projectContacts.city;
        lineDatas[i].state = projectContacts.state;
        lineDatas[i].province = projectContacts.province;
        lineDatas[i].country = projectContacts.country;
        lineDatas[i].postalcode = projectContacts.postalCode;
        lineDatas[i].partysite = projectContacts.partySiteNumber;
        lineDatas[i].serviceLine = item.service_Line;
        lineDatas[i].customer = d.companyName;
        lineDatas[i].poc = projectContacts.country;
        lineDatas[i].handlerName = d.projectHandler;
        lineDatas[i].fulfillmentLocation = item.preferred_Fulfillment_Location;
        lineDatas[i].projectFlexId = d.projectId;
        lineDatas[i].projectNumber = d.projectNumber;
        lineDatas[i].orderNumber = d.orderNumber;
        lineDatas[i].quoteNo = d.quoteNo;
        lineDatas[i].projectName = d.name;
      }

      tempLineDetails = tempLineDetails.concat(lineDatas);
    });

    return tempLineDetails.filter((l) => l != null);
  };

  const onSuggestionSelect = async (event) => {
    const item = event.value;
    if (!item) return;

    setIsDisableContinue(true);
    setIsFetchingProject(true);

    dispatch(updateSelectedProjectDetails(null));
    dispatch(updateLineDetails([]));
    dispatch(updateOrderLineNumbers([]));
    dispatch(updateSelectedOrderLineNumber(null));
    dispatch(updateModelDetails(null));
    dispatch(updatePartySiteNumber(null));

    const data = await ProjectDetailsService.getProjectDetail(
      item.project_Number
    );

    const tempLineDetails = processProjectDetail(item, data);

    await setAndDispatchTemplateLineDetails(
      item.project_Number,
      item.order_Number,
      tempLineDetails
    );
  };

  const setAndDispatchTemplateLineDetails = async (
    projectNumber,
    orderNumber,
    tempLineDetails
  ) => {
    if (!tempLineDetails || tempLineDetails.length === 0) {
      showWarning("Warning!", "There are no available lines for this project.");

      dispatch(updateSelectedProjectDetails({ projectNumber }));
      dispatch(updateOrderLineNumbers(null));
      setIsFetchingProject(false);
      setIsDisableContinue(false);
      return;
    }

    const lineNumbers = tempLineDetails.map((d) => d.lineNumber);
    const sortedLineNumbers = lineNumbers
      .map((a) =>
        a
          .split(".")
          .map((n) => +n + 100000)
          .join(".")
      )
      .sort()
      .map((a) =>
        a
          .split(".")
          .map((n) => +n - 100000)
          .join(".")
      );

    const mappedLines = sortedLineNumbers.map((d) => {
      var details = tempLineDetails.find((l) => l.lineNumber === d);
      return {
        name:
          (details?.isCpq ? details?.parentLineNumber : d) +
          " - " +
          details?.projectName,
        value: d,
      };
    });

    const modelDetails = {
      partySiteNumber: tempLineDetails[0]?.partySiteNumber,
      productType: null,
      isDisable: false,
    };

    const initialSelectedDetails = tempLineDetails.find(
      (l) => l.lineNumber === mappedLines[0].value
    );

    await setWorkflowDetails(initialSelectedDetails);

    if (initialSelectedDetails.projectId === localData?.project_id) {
      setSelectedOrderLineNumber(localData.line_number);
      dispatch(updateSelectedOrderLineNumber(localData.line_number));
    } else {
      setSelectedOrderLineNumber(mappedLines[0].value);
      dispatch(updateSelectedOrderLineNumber(mappedLines[0].value));
    }

    setSelectedProject(projectNumber);
    setSelectedOrder(orderNumber);
    setLineDetails(tempLineDetails);
    setOrderLineNumbers(mappedLines);
    setSelectedDetails(initialSelectedDetails);
    getUserDetails(tempLineDetails[0].handlerName);
    setIsFetchingProject(false);
    setIsDisableContinue(false);

    dispatch(updateLineDetails(tempLineDetails));
    dispatch(updateOrderLineNumbers(mappedLines));
    dispatch(updateSelectedProjectDetails(initialSelectedDetails));
    dispatch(updatePartySiteNumber(tempLineDetails[0]?.partySiteNumber));
    dispatch(updateModelDetails(modelDetails));
  };

  const setWorkflowDetails = async (projectDetails) => {
    const workflowDetailResult =
      await ProjectDetailsService.getProjectWorkflowDetail(
        projectDetails.projectId,
        projectDetails.lineNumber
      );

    if (workflowDetailResult.data?.length > 0) {
      const workflowDetails = JSON.parse(workflowDetailResult.data);

      if (workflowDetails.isCompleted === false) {
        if (!(isEditCert || isModifyCert)) {
          dispatch(updateCertificateScheme(workflowDetails.certificate_scheme));
          dispatch(updateModelList([]));
          await remapModel(workflowDetails.models);
        }
      } else {
        dispatch(updateCertificateScheme(null));
        dispatch(updateModelList([]));
      }
    } else {
      if (!(isEditCert || isModifyCert)) {
        dispatch(updateCertificateScheme(certificateScheme));
        dispatch(updateModelList([]));
      }
    }
  };

  const remapModel = async (model) => {
    const remapModels = await CertificateListService.remapModel({
      models: model,
    });

    if (!remapModels) return;

    dispatch(updateProdDetailsModelList(remapModels));
    dispatch(updateModelList(remapModels));
  };

  const onOrderLineChange = async (event) => {
    setIsDisableContinue(true);
    setIsFetchingProject(true);
    const lineNumber = event.value;
    setSelectedOrderLineNumber(lineNumber);
    const selectedDetails = lineDetails.find(
      (l) => l.lineNumber === lineNumber
    );

    const modelDetails = {
      partySiteNumber: selectedDetails.partySiteNumber,
      productType: null,
      isDisable: false,
    };

    await setWorkflowDetails(selectedDetails);

    getUserDetails(selectedDetails.handlerName);
    setSelectedDetails(selectedDetails);
    dispatch(updateSelectedProjectDetails(selectedDetails));
    dispatch(updateSelectedOrderLineNumber(lineNumber));
    dispatch(updatePartySiteNumber(selectedDetails.partySiteNumber));
    dispatch(updateModelDetails(modelDetails));
    setIsFetchingProject(false);
    setIsDisableContinue(false);
  };

  const getUserDetails = async (handlerName) => {
    const response = await ProjectDetailsService.getUserDetails(handlerName);
    if (!response) return;

    const handlerCountry = response.split("-")[0];
    const handlerLocation = response.split("-")[1];
    setSelectedDetails((prevState) => {
      return {
        ...prevState,
        handlerCountry: handlerCountry,
        handlerLocation: handlerLocation,
      };
    });
  };

  const checkField = (value, field) => {
    if (!value || value === "") {
      setErrors((prevState) => [...prevState, field]);
    } else {
      setErrors((prevState) => [...prevState.filter((p) => p !== field)]);
    }
  };

  const getProjectContact = (projectContacts) => {
    const soldTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.SoldTo
    );
    if (soldTo?.partySiteNumber) return soldTo;

    const shipTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.ShipTo
    );
    if (shipTo?.partySiteNumber) return shipTo;

    const customer = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.Customer
    );
    if (customer?.partySiteNumber) return customer;

    const billTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.BillTo
    );
    if (billTo?.partySiteNumber) return billTo;
  };

  const clearState = () => {
    setSelectedProject(null);
    setSelectedOrder(null);
    setLineDetails(null);
    setOrderLineNumbers(null);
    setSelectedOrderLineNumber(null);
    setSelectedDetails(null);
    getUserDetails(null);
    dispatch(updateSelectedProjectDetails(null));
    dispatch(updateLineDetails([]));
    dispatch(updateOrderLineNumbers([]));
    dispatch(updateSelectedOrderLineNumber(null));
    dispatch(updateModelDetails(null));
    dispatch(updatePartySiteNumber(null));
  };

  const processProjectDetails = () => {
    setIsDisableContinue(true);
    setIsFetchingProject(true);
    if (value.selectedProjectDetails) {
      getUserDetails(value.selectedProjectDetails?.handlerName);
      if (value.selectedProjectDetails.projectNumber) {
        if (value.selectedOrderLineNumber === null) {
          const fetchData = async () => {
            const data = await ProjectDetailsService.getProjectDetail(
              value.selectedProjectDetails.projectNumber
            );
            const tempLineDetails = processProjectDetail({}, data);
            if (!tempLineDetails || tempLineDetails.length === 0) {
              showWarning(
                "Warning!",
                "There are no child lines for this project."
              );

              setIsFetchingProject(false);
              setIsDisableContinue(false);
              return;
            }
            const lineNumbers = tempLineDetails.map((d) => d.lineNumber);
            const sortedLineNumbers = lineNumbers
              .map((a) =>
                a
                  .split(".")
                  .map((n) => +n + 100000)
                  .join(".")
              )
              .sort()
              .map((a) =>
                a
                  .split(".")
                  .map((n) => +n - 100000)
                  .join(".")
              );

            const mappedLines = sortedLineNumbers.map((d) => {
              return {
                name:
                  d +
                  " - " +
                  tempLineDetails.find((l) => l.lineNumber === d)?.projectName,
                value: d,
              };
            });

            if (
              tempLineDetails?.find((x) => x.projectId == localData?.project_id)
            ) {
              setSelectedOrderLineNumber(localData.line_number);
              dispatch(updateSelectedOrderLineNumber(localData.line_number));
            } else {
              setSelectedOrderLineNumber(mappedLines[0].value);
              dispatch(updateSelectedOrderLineNumber(mappedLines[0].value));
            }

            setLineDetails(tempLineDetails);
            setOrderLineNumbers(mappedLines);
            dispatch(updateLineDetails(tempLineDetails));
            dispatch(updateOrderLineNumbers(mappedLines));
            setIsFetchingProject(false);
            setIsDisableContinue(false);
          };

          fetchData();
        } else {
          setIsFetchingProject(false);
          setIsDisableContinue(false);
        }
      } else {
        setIsFetchingProject(false);
        setIsDisableContinue(false);
      }
    } else {
      const fetchData = async () => {
        const responseData =
          await CertificateListService.getCertificateProjectHistory(
            certificateId
          );

        let projectNumber = "";
        if (responseData.data?.hasProjectHistory) {
          projectNumber = responseData.data.hasProjectHistory[0].projectNumber;
        }

        const data = await ProjectDetailsService.getProjectDetail(
          projectNumber
        );
        if (data.value.length > 0) {
          const tempLineDetails = processProjectDetail({}, data);

          setAndDispatchTemplateLineDetails(
            projectNumber,
            null,
            tempLineDetails
          );
        } else {
          setIsFetchingProject(false);
          setIsDisableContinue(false);
        }
      };

      fetchData();
    }
  };

  useEffect(() => {
    setSelectedProject(value.selectedProjectDetails?.projectNumber);
    setSelectedOrder(value.selectedProjectDetails?.orderNumber);
    setLineDetails(value.lineDetails);
    setOrderLineNumbers(value.orderLineNumbers);
    setSelectedOrderLineNumber(value.selectedOrderLineNumber);
    setSelectedDetails(value.selectedProjectDetails);

    if (isInitial && (isEditCert || isModifyCert)) {
      processProjectDetails();
      setIsInitial(false);
    }
  }, [value]);

  return (
    <div className="project-order-details-container">
      {isFetchingProject && (
        <div className="loading-cert-attr-div">
          <div>
            <div className="fetching-attr-load-label">
              Retrieving project details......
            </div>
            <div>
              <svg className="ul-progressCircle -medium">
                <circle
                  className="ul-progressCircle__outer"
                  cx="50%"
                  cy="50%"
                  r="35%"
                />
                <circle
                  className="ul-progressCircle__inner"
                  cx="50%"
                  cy="50%"
                  r="35%"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
      {!isFetchingProject && (
        <>
          <div className="add-helper pb-10">
            Enter project details below{" "}
            <span className="add-error">(* = Required field)</span>
          </div>
          <div className="field-row">
            <label htmlFor="projectNumber" className="w-15">
              <span className="add-error">*</span>Project Number
            </label>
            <div className="w-35 plr-10">
              <AutoComplete
                inputId="projectNumber"
                field="project_Number"
                value={selectedProject}
                suggestions={projects}
                completeMethod={getProjectSuggestions}
                onChange={(e) => setSelectedProject(e.value)}
                onSelect={onSuggestionSelect}
                className="ul-input-style"
                placeholder="Type at least 3 characters"
                onBlur={() => checkField(selectedProject, "project")}
                minLength={3}
                onClear={clearState}
              />
              {errors.includes("project") && (
                <span className="add-error add-helper">
                  Project Number is required
                </span>
              )}
            </div>
            <label htmlFor="orderNumber" className="w-15">
              <span className="add-error">*</span>Order Number
            </label>
            <div className="w-35 plr-10">
              <AutoComplete
                inputId="orderNumber"
                field="order_Number"
                value={selectedOrder}
                suggestions={orders}
                completeMethod={getOrderSuggestions}
                onChange={(e) => setSelectedOrder(e.value)}
                onSelect={onSuggestionSelect}
                className="ul-input-style"
                placeholder="Type at least 3 characters"
                minLength={3}
                onClear={clearState}
              />
            </div>
          </div>
          <div className="field-row">
            <label htmlFor="orderLineNumber" className="w-15">
              <span className="add-error">*</span>Order Line Number
            </label>
            <div className="w-85 plr-10">
              <Dropdown
                value={selectedOrderLineNumber}
                options={orderLineNumbers}
                onChange={onOrderLineChange}
                optionLabel="name"
                className="ul-select-style"
              />
            </div>
          </div>
          <div className="field-row">
            <label htmlFor="projectName" className="w-15">
              Project Name
            </label>
            <div className="w-85 plr-10">
              <input
                type="text"
                id="projectName"
                className="ul-textbox"
                value={selectedDetails?.projectName || ""}
                disabled
              />
            </div>
          </div>
          <div className="field-row">
            <label htmlFor="partySiteNumber" className="w-15">
              Party Site Number
            </label>
            <div className="w-35 plr-10">
              <input
                type="text"
                id="partySiteNumber"
                className="ul-textbox"
                value={selectedDetails?.partySiteNumber || ""}
                disabled
              />
            </div>
            <label htmlFor="serviceLine" className="w-15">
              Service Line
            </label>
            <div className="w-35 plr-10">
              <input
                type="text"
                id="serviceLine"
                className="ul-textbox"
                value={selectedDetails?.serviceLine || ""}
                disabled
              />
            </div>
          </div>
          <div className="field-row">
            <label htmlFor="serviceDetail" className="w-15">
              Service Detail
            </label>
            <div className="w-35 plr-10">
              <input
                type="text"
                id="serviceDetail"
                className="ul-textbox"
                value={selectedDetails?.detailedServiceDescription || ""}
                disabled
              />
            </div>
            <label htmlFor="serviceProgram" className="w-15">
              Service Program
            </label>
            <div className="w-35 plr-10">
              <input
                type="text"
                id="serviceProgram"
                className="ul-textbox"
                value={selectedDetails?.serviceProgramDescription || ""}
                disabled
              />
            </div>
          </div>
          <div className="field-row">
            <label htmlFor="applicant" className="w-15">
              Applicant (Sold To)
            </label>
            <div className="w-35 plr-10">
              <input
                type="text"
                id="applicant"
                className="ul-textbox"
                value={selectedDetails?.customer || ""}
                disabled
              />
            </div>
            <label htmlFor="pocCountry" className="w-15">
              PoC Country
            </label>
            <div className="w-35 plr-10">
              <input
                type="text"
                id="pocCountry"
                className="ul-textbox"
                value={selectedDetails?.poc || ""}
                disabled
              />
            </div>
          </div>
          <div className="field-row">
            <label htmlFor="productOwner" className="w-15">
              Product Owner (Ship To)
            </label>
            <div className="w-35 plr-10">
              <input
                type="text"
                id="productOwner"
                className="ul-textbox"
                value={selectedDetails?.customer || ""}
                disabled
              />
            </div>
            <label htmlFor="handlerLocation" className="w-15">
              Handler Location
            </label>
            <div className="w-35 plr-10">
              <input
                type="text"
                id="handlerLocation"
                className="ul-textbox"
                value={selectedDetails?.handlerLocation || ""}
                disabled
              />
            </div>
          </div>
          <div className="field-row">
            <label htmlFor="handlerEmail" className="w-15">
              Handler Email
            </label>
            <div className="w-35 plr-10">
              <input
                type="text"
                id="handlerEmail"
                className="ul-textbox"
                value={selectedDetails?.handlerName || ""}
                disabled
              />
            </div>
            <label htmlFor="handlerCountry" className="w-15">
              Handler Country
            </label>
            <div className="w-35 plr-10">
              <input
                type="text"
                id="handlerCountry"
                className="ul-textbox"
                value={selectedDetails?.handlerCountry || ""}
                disabled
              />
            </div>
          </div>
          <div className="field-row">
            <label className="w-15">&nbsp;</label>
            <div className="w-35 plr-10">&nbsp;</div>
            <label htmlFor="location" className="w-15">
              Preferred Fulfillment Location
            </label>
            <div className="w-35 plr-10">
              <input
                type="text"
                id="location"
                className="ul-textbox"
                value={selectedDetails?.fulfillmentLocation || ""}
                disabled
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectOrderDetails;
