import { uniqBy } from "lodash";
import { Chips } from "primereact/chips";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploader, {
  FILE_STATUS,
} from "../../../../components/file-uploader/FileUploader";
import {
  addKeptCustomerDataDiscrepancies,
  selectApplicantInformation,
  selectExistingCertDetails,
  selectGPICustomerInputValidationResult,
  selectGeneralProductInformation,
  selectIsReadOnly,
  selectKeptCustomerDataDiscrepancies,
  selectProductDetails,
  selectProductType,
  selectShouldCompareExistingCertDetails,
  selectULFileNumber,
  updateGPICustomerInputValidationResult,
  updateIsSaveDisabled,
  updateProductType,
  updateProductTypeProductTypes,
} from "../../../../features/generalProductInformation/generalProductInformationSlice";
import GeneralProductInformationService from "../../../../services/GeneralProductInformationService";
import ModelSearch from "../../../../shared/model-search/ModelSearch";
import {
  customerChangeConfirmationMsg,
  customerChangeKeepMsg,
  customerChangeReplaceMsg,
  customerChangeUpdateMsg,
  defaultValuesConfirmationMsg,
  defaultValuesKeepMsg,
  defaultValuesReplaceMsg,
  defaultValuesUpdateMsg,
} from "../../../../utils/constants/gpi-form-constants";
import {
  getBaseModelFromCertificateDetails,
  getModelVariantsFromCertificateDetails,
} from "../../company-details/ul-file-number/ULFileNumber";
import FormControl from "../../form/form-control/FormControl";
import { productTypeSchema } from "../product-details-schema";
import classes from "./ProductType.module.scss";

import { v4 as uuidv4 } from "uuid";
import { appInsights } from "../../../../services/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
const ProductType = ({ disableIntegration }) => {
  const logPrefix = `ProductType - ${uuidv4()}`;
  const dispatch = useDispatch();
  const productTypeValues = useSelector(selectProductType);
  const productDetailsValues = useSelector(selectProductDetails);
  const isReadOnly = useSelector(selectIsReadOnly);
  const [modelSearchDialogVisible, setModelSearchDialogVisible] =
    useState(false);

  const [flattenedProductTypes, setFlattenedProductTypes] = useState([]);
  const gPICustomerInputValidationResultValues = useSelector(
    selectGPICustomerInputValidationResult
  );

  useEffect(() => {
    appInsights.trackTrace({
      message: `${logPrefix} - useEffect-productTypeValues - length = ${productTypeValues.length}`,
      severityLevel: SeverityLevel.Information,
    });

    setFlattenedProductTypes(
      [].concat.apply(
        [],
        productTypeValues.productTypeOptions?.map((p) => p.items) || []
      )
    );
  }, [productTypeValues]);

  const applicantInformationValues = useSelector(selectApplicantInformation);

  const keptCustomerDataDiscrepancies = useSelector(
    selectKeptCustomerDataDiscrepancies
  );

  const shouldCompareCertificationDetails = useSelector(
    selectShouldCompareExistingCertDetails
  );

  const customErrors = gPICustomerInputValidationResultValues?.errors?.filter(
    (e) => e.entity === "Models"
  );

  const existingCertDetails = useSelector(selectExistingCertDetails);
  const ulFileNumberValues = useSelector(selectULFileNumber);
  const gpiValues = useSelector(selectGeneralProductInformation);

  const handleChange = (field, value) => {
    dispatch(
      updateProductType({
        ...productTypeValues,
        [field]: value,
      })
    );
    dispatch(updateIsSaveDisabled(false));
  };

  const getValueAndSchema = (field) => {
    return {
      schema: productTypeSchema.fields[field],
      value: productTypeValues[field],
    };
  };

  const handleSelectModel = () => {
    setModelSearchDialogVisible(true);
  };

  const handleApplyModel = (newModels) => {
    setModelSearchDialogVisible(false);

    const newBaseModel = newModels[0];
    const newModelVariants = newModels.filter((m, i) => i > 0);
    const newProductTypes = getModelsProductTypes(
      newBaseModel,
      newModelVariants
    );

    dispatch(
      updateProductType({
        ...productTypeValues,
        baseModel: newBaseModel,
        modelVariants: newModelVariants,
        productTypes: newProductTypes,
      })
    );

    dispatch(updateIsSaveDisabled(false));
  };

  const handleFilenameClick = async (file) => {
    const signedUrl =
      await GeneralProductInformationService.getAttachmentSignedUrl(
        file.filepath
      );

    window.location.href = signedUrl.data;
  };

  const handleFileUploadBrandOrTrademark = async (files) => {
    if (!files?.length) return;

    const oldBrandOrTrademarkAttachments =
      productTypeValues.brandOrTrademarkAttachments ?? [];

    const addBrandOrTrademarkAttachments = files.map((f) => ({
      name: f.name,
      status: f.status,
      errorMessage: f.errorMessage,
    }));

    dispatch(
      updateProductType({
        ...productTypeValues,
        brandOrTrademarkAttachments: [
          ...oldBrandOrTrademarkAttachments,
          ...addBrandOrTrademarkAttachments.map((a) => ({ ...a })),
        ],
      })
    );

    files.forEach((file, i) => {
      if (file.status === FILE_STATUS.FOR_UPLOAD) {
        const brandOrTrademarkAttachmentReference =
          addBrandOrTrademarkAttachments[i];
        const formData = new FormData();
        formData.append("file", file, file.name);

        const updateBrandOrTrademarkAttachments = () => {
          dispatch(
            updateProductType({
              ...productTypeValues,
              brandOrTrademarkAttachments: [
                ...oldBrandOrTrademarkAttachments,
                ...addBrandOrTrademarkAttachments.map((a) => ({ ...a })),
              ],
            })
          );

          dispatch(updateIsSaveDisabled(false));
        };

        const onUploadProgress = (res) => {
          const { loaded, total } = res;
          const percent = Math.floor((loaded * 100) / total);

          if (percent < 100) {
            brandOrTrademarkAttachmentReference.uploadProgress = percent;
            updateBrandOrTrademarkAttachments();
          }
        };

        GeneralProductInformationService.addAttachment(
          formData,
          onUploadProgress
        ).then((result) => {
          if (result?.isSuccess) {
            brandOrTrademarkAttachmentReference.status = FILE_STATUS.SUCCESS;
            brandOrTrademarkAttachmentReference.attachmentId = result.data.id;
            brandOrTrademarkAttachmentReference.filepath = result.data.filepath;
          } else {
            brandOrTrademarkAttachmentReference.status = FILE_STATUS.ERROR;
            brandOrTrademarkAttachmentReference.errorMessage =
              "There is an error occured while uploading your file.";
          }

          updateBrandOrTrademarkAttachments();
        });
      }
    });
  };

  const handleDeleteFileBrandOrTrademark = async (file, index) => {
    const newBrandOrTrademarkFiles =
      productTypeValues.brandOrTrademarkAttachments.filter(
        (b, i) => i !== index
      );

    dispatch(
      updateProductType({
        ...productTypeValues,
        brandOrTrademarkAttachments: newBrandOrTrademarkFiles,
      })
    );

    if (file.attachmentId && !file.isCopy) {
      await GeneralProductInformationService.deleteBrandOrTrademarkAttachment(
        file.attachmentId
      );
    }

    dispatch(updateIsSaveDisabled(false));
  };

  const getSelectedModels = () => {
    let selectedModels = [];

    if (productTypeValues.baseModel) {
      selectedModels.push(productTypeValues.baseModel);
    }

    return selectedModels.concat(productTypeValues.modelVariants || []);
  };

  const selectedProductType = useMemo(
    () =>
      flattenedProductTypes.find(
        (f) => f.hierarchyId === productTypeValues.productTypeId
      )?.hierarchyValue,
    [productTypeValues.productTypeOptions, productTypeValues.productTypeId]
  );

  const handleRemoveBaseModel = (e) => {
    handleChange("baseModel", null);

    const currentBaseModelProductType = productTypeValues.baseModel.productType;
    const isBaseModelProductTypeExistingOnModelVariants =
      productTypeValues.modelVariants?.some(
        (x) => x.productType === currentBaseModelProductType
      );

    if (!isBaseModelProductTypeExistingOnModelVariants) {
      const newProductTypes = productTypeValues.productTypes?.filter(
        (x) => x.hierarchyValue !== currentBaseModelProductType
      );
      dispatch(
        updateProductType({
          ...productTypeValues,
          baseModel: null,
          productTypes: newProductTypes,
        })
      );
      return;
    }

    dispatch(updateIsSaveDisabled(false));
  };

  const handleRemoveModelVariant = (e) => {
    dispatch(updateIsSaveDisabled(false));

    const modelVariantToRemove = productTypeValues.modelVariants.find(
      (m) => m.modelName === e.value[0].modelName
    );

    const newModelVariants = [
      ...productTypeValues.modelVariants.filter(
        (m) => m !== modelVariantToRemove
      ),
    ];

    const hasOtherModelVariantsSameProductType = newModelVariants.some(
      (x) => x.productType === modelVariantToRemove.productType
    );

    const isBaseModelSameProductType =
      productTypeValues?.baseModel?.productType ===
      modelVariantToRemove.productType;

    if (!hasOtherModelVariantsSameProductType && !isBaseModelSameProductType) {
      const newProductTypes = productTypeValues.productTypes?.filter(
        (x) => x.hierarchyValue !== modelVariantToRemove.productType
      );

      dispatch(
        updateProductType({
          ...productTypeValues,
          modelVariants: newModelVariants,
          productTypes: newProductTypes,
        })
      );

      return;
    }

    handleChange("modelVariants", newModelVariants);
  };

  const handleProductTypesChange = (productTypes) => {
    const newProductTypes = flattenedProductTypes.filter((p) =>
      productTypes.includes(p.hierarchyValue)
    );

    dispatch(
      updateProductType({
        ...productTypeValues,
        productTypes: newProductTypes,
      })
    );

    dispatch(updateIsSaveDisabled(false));
  };

  const handleBaseModelChange = (e) => {
    if (e.target.value) {
      const newBaseModel = {
        modelName: e.target.value,
      };

      const newProductTypes = getModelsProductTypes(
        newBaseModel,
        productTypeValues.modelVariants
      );

      dispatch(
        updateProductType({
          ...productTypeValues,
          baseModel: newBaseModel,
          productTypes: newProductTypes,
        })
      );

      return;
    }

    handleChange("baseModel", null);
  };

  const handleAddModelVariant = (e) => {
    dispatch(
      updateProductType({
        ...productTypeValues,
        modelVariants: [
          ...productTypeValues.modelVariants,
          { modelName: e.value },
        ],
      })
    );
  };

  const handleBlurModelVariant = (e) => {
    const value = e.target.value;

    // Auto entry for model variant if input is not blank,
    // just in case the user has forgotten to trigger the chip by comma on leave.
    if (value) handleAddModelVariant({ value });

    // Clear the input.
    e.target.value = "";
  };

  const getModelVariantNames = (modelVariants) => {
    return modelVariants
      ?.map((x) => x.modelVariant?.modelName || x.modelName)
      .sort()
      .join(", ");
  };

  const handleModelVariantsReplace = (newModelVariants) => {
    dispatch(
      updateProductType({
        ...productTypeValues,
        modelVariants: newModelVariants,
        productTypes: getModelsProductTypes(
          productTypeValues.baseModel,
          newModelVariants
        ),
      })
    );

    dispatch(
      updateGPICustomerInputValidationResult(
        gPICustomerInputValidationResultValues?.errors?.filter(
          (e) => e.field !== "Model Variants"
        )
      )
    );

    dispatch(updateIsSaveDisabled(false));
  };

  const handleBaseModelReplace = (newBaseModel) => {
    dispatch(
      updateProductType({
        ...productTypeValues,
        baseModel: newBaseModel,
        productTypes: getModelsProductTypes(
          newBaseModel,
          productTypeValues.modelVariants
        ),
      })
    );

    dispatch(
      updateGPICustomerInputValidationResult(
        gPICustomerInputValidationResultValues?.errors?.filter(
          (e) => e.field !== "Base Model"
        )
      )
    );

    dispatch(updateIsSaveDisabled(false));
  };

  const isProductTypeMatched = (productType) => {
    return getSelectedModels().some((x) => x.productType === productType);
  };

  const selectedProductTypeTemplate = (productType) => {
    if (productType === undefined) return;

    return (
      <div className={classes["custom-chip"]}>
        {productType}

        {!isProductTypeMatched(productType) && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleProductTypeRemove(productType);
            }}
          >
            <span className="p-chips-token-icon pi pi-times"></span>
          </button>
        )}
      </div>
    );
  };

  const handleProductTypeRemove = (productType) => {
    const newProductTypes = productTypeValues.productTypes.filter(
      (x) => x.hierarchyValue !== productType
    );

    dispatch(updateProductTypeProductTypes(newProductTypes));
  };

  const shouldDisplayModelNotExisting = (model) =>
    !disableIntegration && model?.modelName && !model.assetId;

  const hasCustomerModelsInput =
    shouldDisplayModelNotExisting(productTypeValues.baseModel) ||
    productTypeValues?.modelVariants?.some((x) =>
      shouldDisplayModelNotExisting(x)
    );

  const modelVariantChipItemTemplate = (model) => {
    return (
      <span
        className={`${shouldDisplayModelNotExisting(model) ? classes["model-name--red"] : ""
          }`}
      >
        {model.modelName}
      </span>
    );
  };

  const handleKeyDown = (e) => {
    if (e.originalEvent?.type != "paste") return

    let clipBoardText = e.originalEvent?.clipboardData?.getData('text');
    if (clipBoardText) handleAddModelVariant({ value: clipBoardText })
  }

  const getBaseModelControlClassName = () => {
    let result = classes["chips"];

    if (!disableIntegration) {
      result += " " + classes["manual-input-disabled"];

      if (shouldDisplayModelNotExisting(productTypeValues.baseModel)) {
        result += " " + classes["model-name--red"];
      }
    }

    return result;
  };

  const getBaseModelDiscrepancyOptions = () => {
    if (!gpiValues.hasFinalizedByCustomer) return;

    return {
      wbValue:
        getBaseModelFromCertificateDetails(existingCertDetails)?.modelName,
      currentValue: productTypeValues.baseModel?.modelName,
      onReplace: () =>
        handleBaseModelReplace(
          getBaseModelFromCertificateDetails(existingCertDetails)
        ),
      onKeep: () => {
        dispatch(
          addKeptCustomerDataDiscrepancies({
            entity: "ProductType",
            field: "baseModel",
          })
        );
      },
      isVisible:
        shouldCompareCertificationDetails &&
        !keptCustomerDataDiscrepancies.some((x) => x.field === "baseModel"),
      updateMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeUpdateMsg
        : defaultValuesUpdateMsg,
      confirmationMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeConfirmationMsg
        : defaultValuesConfirmationMsg,
      replaceMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeReplaceMsg
        : defaultValuesReplaceMsg,
      keepMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeKeepMsg
        : defaultValuesKeepMsg,
      isFromExistingCertificate: true,
    };
  };

  const getModelVariantsDiscrepancyOptions = () => {
    if (!gpiValues.hasFinalizedByCustomer) return;

    return {
      wbValue: getModelVariantNames(
        getModelVariantsFromCertificateDetails(existingCertDetails)
      ),
      currentValue: getModelVariantNames(productTypeValues.modelVariants),
      onReplace: () =>
        handleModelVariantsReplace(
          getModelVariantsFromCertificateDetails(existingCertDetails)
        ),
      onKeep: () => {
        dispatch(
          addKeptCustomerDataDiscrepancies({
            entity: "ProductType",
            field: "modelVariants",
          })
        );
      },
      isVisible:
        shouldCompareCertificationDetails &&
        !keptCustomerDataDiscrepancies.some((x) => x.field === "modelVariants"),
      updateMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeUpdateMsg
        : defaultValuesUpdateMsg,
      confirmationMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeConfirmationMsg
        : defaultValuesConfirmationMsg,
      replaceMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeReplaceMsg
        : defaultValuesReplaceMsg,
      keepMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeKeepMsg
        : defaultValuesKeepMsg,
      isFromExistingCertificate: true,
    };
  };

  const getModelsProductTypes = (baseModel, modelVariants) => {
    let result = [];

    result.push({
      hierarchyValue: baseModel?.productType,
      hierarchyId: baseModel?.hierarchyId,
    });

    if (modelVariants?.length) {
      const modelVariantsProductTypes = modelVariants.map((x) => ({
        hierarchyValue: x.productType,
        hierarchyId: x.hierarchyId,
      }));

      result = [...result, ...modelVariantsProductTypes];
    }

    return uniqBy(result, "hierarchyValue").filter((x) => x.hierarchyValue);
  };

  const validateModels = (field) => {
    const { modelVariants, baseModel } = productTypeValues;
    const regexPattern = /(.+?) does not exist/;
    const errorMessage = customErrors?.filter(
      (c) => c.field === field && c.errorMessage.includes("does not exist")
    )[0]?.errorMessage;

    const match = errorMessage?.match(regexPattern);
    let resultArray = [];

    let replacedString = errorMessage;

    if (match && match.length > 1) {
      const wordsBeforeNotExist = match[1];
      resultArray = wordsBeforeNotExist.split(",").map((word) => word.trim());

      if (field === "Base Model") {
        resultArray = resultArray.filter((element) => {
          return baseModel?.modelName === element;
        });
      } else {
        resultArray = resultArray.filter((element) => {
          return modelVariants.some((model) => model?.modelName === element);
        });
      }

      replacedString = replacedString.replace(
        wordsBeforeNotExist,
        resultArray.join(", ")
      );
    }

    const errors = gPICustomerInputValidationResultValues?.errors;

    let modelVariantDoesNotExistError = errors
      ?.filter((e) => e.field === field)
      .find((e) => e.errorMessage.includes("does not exist"));

    modelVariantDoesNotExistError = Object.assign(
      {},
      modelVariantDoesNotExistError,
      { errorMessage: replacedString }
    );

    let newErrors = errors?.filter(
      (err) =>
        !(err.field === field && err.errorMessage.includes("does not exist"))
    );

    if (resultArray.length !== 0) {
      newErrors.unshift(modelVariantDoesNotExistError);
    }

    let gPiCustomerValidation = gPICustomerInputValidationResultValues;

    gPiCustomerValidation = Object.assign({}, gPiCustomerValidation, {
      errors: newErrors,
    });

    return gPiCustomerValidation?.errors?.filter((c) => c.field === field);
  };

  const getModelCustomErrorMessages = (field) => {
    const validationErrors = validateModels(field);

    return validationErrors
      ?.map((c) => c.errorMessage)
      ?.filter((v, i, a) => a.indexOf(v) === i)
      ?.map((x) => `<div>${x}</div>`)
      .join("");
  };

  return (
    <div className={`form-section ${classes["container"]}`}>
      <div className="form-section-header">
        <h3 className="form-section-title">
          All information below should be identical on all documentation. Ex:
          the product label, manual and test reports.
        </h3>
      </div>

      <div className="form-section-content">
        <ModelSearch
          preSelectedModels={getSelectedModels()}
          onClose={() => {
            setModelSearchDialogVisible(false);
          }}
          visible={modelSearchDialogVisible}
          hideApplyButton={true}
          partySiteNumber={applicantInformationValues.partySiteNumber}
          productType={selectedProductType}
          productTypes={flattenedProductTypes}
          disableProductType={selectedProductType !== undefined}
          shouldPopulateWorkbenchModelForm={false}
          onSelectedModelsApply={handleApplyModel}
        />

        {!disableIntegration && (
          <div className={classes["select-model-container"]}>
            <label>Models</label>
            <button
              disabled={isReadOnly}
              className={`ul-button -app-primary ${classes["btn-select-model"]}`}
              onClick={() => handleSelectModel()}
            >
              SELECT MODEL
            </button>
          </div>
        )}

        {hasCustomerModelsInput && !disableIntegration && (
          <div className={classes["customer-input-models-error-message"]}>
            <div>
              The Customer has modified the Model details, Click:{" "}
              <button
                onClick={handleSelectModel}
                className={classes["btn-model-search"]}
              >
                Model Search
              </button>{" "}
              to sync with Workbench to determine if the model exists in
              Workbench or needs to be created.
            </div>
            <ul>
              <li>
                If the model does exist, select the applicable model(s) and
                select the “APPLY AND CLOSE” button.
              </li>
              <li>
                If the model search does not result in model(s) records in
                Workbench, the model number(s) will appear in “red” text. Please
                select "<u>CREATE NEW MODEL IN WORKBENCH</u>" in the Model
                search.
              </li>
            </ul>
          </div>
        )}

        <FormControl
          label="Base Model No"
          dataDiscrepancyOptions={getBaseModelDiscrepancyOptions()}
          makeContainerFocusable={true}
          customErrorMessage={
            productTypeValues["baseModel"] !== null
              ? getModelCustomErrorMessages("Base Model")
              : ""
          }
          value={
            productTypeValues["baseModel"]?.modelName
              ? [productTypeValues["baseModel"]?.modelName]
              : []
          }
          schema={productTypeSchema.fields.baseModel}
          schemaValue={productTypeValues.baseModel}
          control={
            disableIntegration ? (
              <InputText
                onChange={handleBaseModelChange}
                disabled={isReadOnly}
              />
            ) : (
              <Chips
                disabled={isReadOnly}
                onRemove={handleRemoveBaseModel}
                separator=","
              />
            )
          }
          controlClassName={`${getBaseModelControlClassName()} baseModel`}
          helpText="Please enter the base application model number of the product to be certified."
          appendDiscrepancy={true}
        />

        <FormControl
          label="Model Variants"
          dataDiscrepancyOptions={getModelVariantsDiscrepancyOptions()}
          customErrorMessage={
            productTypeValues["modelVariants"]?.length > 0
              ? getModelCustomErrorMessages("Model Variants")
              : ""
          }
          schema={productTypeSchema["modelVariants"]}
          value={productTypeValues["modelVariants"]}
          controlClassName={`${classes["chips"]} ${!disableIntegration ? classes["manual-input-disabled"] : ""} modelVariants`}
          handle
          control={
            <Chips
              itemTemplate={modelVariantChipItemTemplate}
              disabled={isReadOnly}
              onRemove={handleRemoveModelVariant}
              onChange={handleKeyDown}
              onAdd={handleAddModelVariant}
              separator=","
              onBlur={handleBlurModelVariant}
            />
          }
          helpText={
            disableIntegration
              ? "Please separate models with comma (i.e. MN-001, MN002, MN003)"
              : "If more than one model, insert the variant model numbers here."
          }
          appendDiscrepancy={true}
        />

        {!disableIntegration && (
          <FormControl
            schemaContext={productDetailsValues}
            schemaValue={productTypeValues.productTypes}
            forceShowError={true}
            hideHelpTextOnError={true}
            label="Product Type"
            value={productTypeValues.productTypes
              ?.map((x) => x.hierarchyValue)
              ?.filter((x) => !!x)
              .sort()}
            schema={productTypeSchema.fields.productTypes}
            controlClassName={`${classes["product-types__multi-select"]} productType`}
            control={
              <MultiSelect
                optionDisabled={(productType) =>
                  isProductTypeMatched(productType.hierarchyValue)
                }
                selectedItemTemplate={selectedProductTypeTemplate}
                panelClassName={classes["product-types__multi-select__panel"]}
                placeholder={" "}
                optionValue="hierarchyValue"
                optionLabel="hierarchyValue"
                optionGroupLabel="label"
                optionGroupChildren="items"
                options={productTypeValues.productTypeOptions}
                emptyMessage="Loading..."
                filter
                onChange={(e) => handleProductTypesChange(e.target.value)}
                disabled={true}
                style={{ "min-height": "44px" }}
                dropdownIcon={null}
              />
            }
          />
        )}

        <FormControl
          label="Product Name"
          {...getValueAndSchema("productName")}
          control={
            <InputText
              onChange={(e) => handleChange("productName", e.target.value)}
              disabled={isReadOnly}
            />
          }
          controlClassName={`productName`}
          helpText="Please enter the product name (i.e. Laptop, Key fob, Battery Pack)."
        />

        <FormControl
          label="Marketing Name"
          {...getValueAndSchema("marketingName")}
          control={
            <InputText
              onChange={(e) => handleChange("marketingName", e.target.value)}
              disabled={isReadOnly}
            />
          }
          controlClassName={`marketingName`}
          helpText="Please enter the marketing name of the product. If there is no marketing name, please leave blank."
        />
        <div className="d-flex" style={{ gap: "30px" }}>
          <div className="col-6">
            <FormControl
              label="Brand or Trademark"
              helpText="Please enter the company brand or trademark name of the company. If there is no brand or trademark, please put N/A or leave it blank."
              {...getValueAndSchema("brandOrTrademark")}
              control={
                <InputText
                  onChange={(e) =>
                    handleChange("brandOrTrademark", e.target.value)
                  }
                  disabled={isReadOnly}
                />
              }
              controlClassName={`brandOrTrademark`}
            />
          </div>
          <div className="col-6">
            <FormControl
              labelTemplate={() => (
                <div style={{ visibility: "hidden" }}>Hidden</div>
              )}
              control={
                <FileUploader
                  multiple
                  onFileNameClick={handleFilenameClick}
                  files={productTypeValues.brandOrTrademarkAttachments}
                  maxSize={100}
                  fileTypes={["JPG", "PNG", "PDF", "JPEG", "GIF", "BMP"]}
                  onChange={handleFileUploadBrandOrTrademark}
                  onDelete={handleDeleteFileBrandOrTrademark}
                  name="file"
                  disabled={isReadOnly}
                  controlClassName="brandOrTrademarkAttachments"
                />
              }
              helpTextTemplate={() => (
                <>
                  <div>Upload company brand or trademark if available.</div>
                  <div style={{ marginTop: "3px" }}>
                    Maximum upload file size: 100MB. Allowable file types: .bmp,
                    .jpeg, .jpg, .png, .gif, .pdf
                  </div>
                </>
              )}
            />
          </div>
        </div>

        <FormControl
          label="Product Description"
          {...getValueAndSchema("productDescription")}
          control={
            <InputText
              onChange={(e) =>
                handleChange("productDescription", e.target.value)
              }
              disabled={isReadOnly}
            />
          }
          controlClassName={`productDescription`}
          helpText="Provide a description of purpose/use of this device."
        />
      </div>
    </div>
  );
};

export default ProductType;
