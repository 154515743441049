import { useState } from "react";

export default function usePromiseCallback<T>(
  callback: (args?: any) => Promise<T>
) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<T>();

  function send() {
    setIsLoading(true);
    callback(...arguments)
      .catch(setError)
      .then((response) => {
        if (response) {
          setData(response);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return { send, data, isLoading, error };
}
