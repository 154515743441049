import { Accordion, AccordionTab } from "primereact/accordion";
import Details from "../Details/Details";
import LinkedProducts from "../LinkedProducts/LinkedProducts";
import classes from "./RevisionHistory.module.scss";

const RevisionHistory = ({ revisions }) => {
  return (
    <div>
      <Accordion activeIndex={0} className={classes.container}>
        {revisions?.map((r, i) => (
          <AccordionTab
            key={i}
            header={`${r.certificateStatus} | ${r.issueDate} | ${r.completedBy} | ${r.completedOn}`}
          >
            <Details details={r} isRevision={true} />
            <LinkedProducts products={r.linkedProducts} />
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  );
};

export default RevisionHistory;
