import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useState } from "react";
import classes from "./Refiners.module.scss";

const Refiners = ({
  refiners,
  selectedRefiners,
  onFilterRefiner,
  onSelectedRefinerChange,
  onRemoveSelectedRefiner,
  onRemoveSelectedRefinerFilter,
  onRemoveSelectedRefiners,
  showCustomSearch = false,
  customSearchText = "",
  onCustomSearchTextChange = (string) => {},
  onCustomSearch = (string) => {},
  disabledRefiners = {},
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [expandedRefinerFields, setExpandedRefinerFields] = useState([]);
  const [collapsedSelectedRefinerFields, setCollapsedSelectedRefinerFields] =
    useState([]);

  const refinerPlaceHolderTemplate = () => {
    return (
      <div className={classes["refiner-dropdown-placeholder"]}>
        -- Click to type below --
      </div>
    );
  };

  const handleRefinerExpand = (field) => {
    if (isRefinerExpanded(field))
      setExpandedRefinerFields((currIndices) =>
        currIndices.filter((c) => c !== field)
      );
    else setExpandedRefinerFields((currIndices) => [...currIndices, field]);
  };

  const handleSelectedRefinerCollapse = (field) => {
    if (isSelectedRefinerCollapsed(field))
      setCollapsedSelectedRefinerFields((currIndices) =>
        currIndices.filter((c) => c !== field)
      );
    else
      setCollapsedSelectedRefinerFields((currIndices) => [
        ...currIndices,
        field,
      ]);
  };

  const isRefinerExpanded = (field) => expandedRefinerFields.includes(field);

  const isSelectedRefinerCollapsed = (field) =>
    collapsedSelectedRefinerFields.includes(field);

  const renderCheckboxes = (r) => (
    <div className={classes["checkboxes-container"]}>
      {!r.hideSelectAll && (
        <div key="All" className={classes["field-checkbox"]}>
          <Checkbox
            inputId="All"
            name="status"
            value="All"
            key="All"
            onChange={(e) => {
              onSelectedRefinerChange(r, e.checked ? r.options : []);
            }}
            checked={r.options.length === selectedRefiners[r.field]?.length}
          />
          <label htmlFor="All"> Select All</label>
        </div>
      )}

      {r.options.map((option) => {
        return (
          <div key={option.label} className={classes["field-checkbox"]}>
            <Checkbox
              inputId={option.label}
              name="status"
              key={option.label}
              value={option}
              onChange={(e) => {
                onSelectedRefinerChange(
                  r,
                  e.checked
                    ? selectedRefiners[r.field]
                      ? [...selectedRefiners[r.field], e.value]
                      : [e.value]
                    : selectedRefiners[r.field]
                    ? selectedRefiners[r.field].filter(
                        (x) => x.label !== e.value.label
                      )
                    : []
                );
              }}
              checked={selectedRefiners[r.field]?.some(
                (item) => item.label === option.label
              )}
            />
            <label htmlFor={option.label}>{option.label}</label>
          </div>
        );
      })}

      {r.displayShowMore && r.options?.length > 0 && (
        <div className={classes["show-more"]}>
          <button disabled={r.isLoading} onClick={r.onShowMore}>
            Show more
          </button>
        </div>
      )}
    </div>
  );

  const renderMultiSelect = (r) => (
    <MultiSelect
      selectedItemTemplate={refinerPlaceHolderTemplate}
      onChange={(e) => onSelectedRefinerChange(r, e.value)}
      style={{ width: "100%" }}
      filterFunction={() => true}
      emptyFilterMessage={r.loading ? "Loading ..." : "No results found"}
      value={selectedRefiners[r.field]}
      options={
        r.loading
          ? []
          : r.options.map((i) =>
              r.field === disabledRefiners?.field &&
              disabledRefiners?.value?.find((d) => d.label === i.label)
                ? (i = { ...i, disabled: true })
                : i
            )
      }
      onFilter={(e) => onFilterRefiner(r, e.filter)}
      filter
      placeholder="-- Click to type below --"
      resetFilterOnHide
    />
  );

  const numericTypes = [
    {
      formLabel: "Greater Than",
      type: "GreaterThan",
    },
    {
      formLabel: "Less Than",
      type: "LessThan",
    },
    {
      formLabel: "Equal To",
      type: "EqualTo",
    },
  ];

  const renderNumeric = (r) => {
    const handleChange = (value, type) => {
      onSelectedRefinerChange(r, value, type);
    };

    const numberInputs = numericTypes.map(({ type, formLabel }) => (
      <div key={type} className={classes["numeric-refiner"]}>
        <div className={classes["numeric-refiner__label"]}>{formLabel}</div>
        <InputNumber
          value={selectedRefiners[r.field]?.[type]?.replaceAll(",", "")}
          onBlur={(e) => handleChange(e.target.value, type)}
          className={classes["numeric-refiner__input"]}
        />
      </div>
    ));

    return <div className={classes["numeric-refiners"]}>{numberInputs}</div>;
  };

  const renderCalendar = (r) => {
    const handleChange = (value, type) => {
      onSelectedRefinerChange(r, value, type);
    };

    const isDateDisabled = (type) => {
      const refiners = selectedRefiners[r.field];
      const { DateOn, OnOrBefore, OnOrAfter, DateFrom, DateTo } =
        refiners || {};

      switch (type) {
        case "DateOn":
          return !!OnOrBefore || !!OnOrAfter || !!DateTo || !!DateFrom;
        case "OnOrBefore":
          return !!DateOn || !!OnOrAfter || !!DateTo || !!DateFrom;
        case "OnOrAfter":
          return !!DateOn || !!OnOrBefore || !!DateTo || !!DateFrom;
        case "DateFrom":
        case "DateTo":
          return !!DateOn || !!OnOrBefore || !!OnOrAfter;
        default:
          return false;
      }
    };

    return (
      <>
        {r.displayPastDue && (
          <div className={classes["calendar-refiner__past-due"]}>
            <Checkbox
              onChange={(e) => handleChange(e.checked, "PastDue")}
              checked={selectedRefiners[r.field]?.PastDue}
              inputId="past-due"
              name="past-due"
            />
            <label htmlFor="past-due">Past Due</label>
          </div>
        )}

        <div className={`ul-grid__row -wrap ${classes["calendars-container"]}`}>
          {dateTypes
            .filter((d) => d.type !== "PastDue")
            .map(({ formLabel, type }) => (
              <div
                key={`${r.field}-${type}`}
                className="ul-grid__column -offset-10-desktop -x100-mobile"
              >
                <label htmlFor={type} className="ul-grid__column">
                  {formLabel}
                </label>
                <Calendar
                  disabled={isDateDisabled(type)}
                  id={type}
                  value={
                    selectedRefiners[r.field]?.[type]
                      ? new Date(selectedRefiners[r.field]?.[type])
                      : null
                  }
                  onChange={(e) => handleChange(e.value, type)}
                  showIcon
                  maxDate={
                    type === "DateFrom"
                      ? new Date(selectedRefiners[r.field]?.DateTo)
                      : null
                  }
                  minDate={
                    type === "DateTo"
                      ? new Date(selectedRefiners[r.field]?.DateFrom)
                      : null
                  }
                  readOnlyInput
                  monthNavigator
                  yearNavigator
                  yearRange="2020:3000"
                />
              </div>
            ))}
        </div>
      </>
    );
  };

  const dateTypes = [
    {
      type: "PastDue",
      label: "Past Due",
    },
    {
      type: "DateOn",
      label: "Date On",
      formLabel: "Date On",
    },
    {
      type: "OnOrBefore",
      label: "On or Before",
      formLabel: "On or Before",
    },
    {
      type: "OnOrAfter",
      label: "On or After",
      formLabel: "On or After",
    },
    {
      type: "DateFrom",
      label: "Date From",
      formLabel: "Date From",
    },
    {
      type: "DateTo",
      label: "Date To",
      formLabel: "Date To",
    },
  ];

  const handleCustomSearchChange = (e) => {
    if (customSearchText.length > 0 && e.target.value.length === 0)
      handleCustomSearchTextClear();
    else onCustomSearchTextChange(e.target.value);
  };

  const handleCustomSearchTextClear = () => {
    onCustomSearchTextChange("");
    onCustomSearch("");
  };

  const handleCustomSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      onCustomSearch(customSearchText);
    }
  };

  const handleCustomSearch = () => {
    onCustomSearch(customSearchText);
  };

  const hasRefinerFiltersValue = (refinerFilters) => {
    if (!refinerFilters) return null;

    if (Array.isArray(refinerFilters) && refinerFilters.length === 0)
      return false;

    if (
      typeof refinerFilters === "object" &&
      Object.values(refinerFilters).every((x) => x === null)
    )
      return false;

    return true;
  };

  useEffect(() => {
    if (disabledRefiners?.field) {
      setExpandedRefinerFields((currIndices) =>
        currIndices.filter((c) => c !== disabledRefiners.field)
      );
    }
    return;
  }, [disabledRefiners]);

  return (
    <div
      className={`${classes["container"]} ${
        isExpanded ? classes["expanded"] : ""
      }`}
    >
      <button
        title="Click here to show/hide"
        onClick={() => setIsExpanded(!isExpanded)}
        className={classes["btn-expand"]}
      >
        {isExpanded ? (
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        ) : (
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        )}
      </button>

      {isExpanded && (
        <div className={classes["body"]}>
          <div className={classes["header"]}>
            <label>Filter menu</label>
          </div>

          <div className={classes["selected-filters-container"]}>
            {showCustomSearch && (
              <div className={classes["custom-filter-container"]}>
                <div>
                  <label className={classes["custom-filter__label"]}>
                    Global Search
                  </label>

                  <div className="ul-form__controlGroup">
                    <p
                      class={`ul-form__control ${classes["custom-filter__input-container"]}`}
                    >
                      <input
                        onKeyDown={handleCustomSearchKeyPress}
                        type="text"
                        placeholder="Search"
                        value={customSearchText}
                        onChange={handleCustomSearchChange}
                        className={`ul-form__controlGroupItem ul-textbox ${classes["custom-filter__input"]}`}
                      />
                      {customSearchText.length > 0 && (
                        <FontAwesomeIcon
                          icon={faXmark}
                          onClick={handleCustomSearchTextClear}
                          className={`ul-form__controlIcon ${classes["custom-filter__clear-icon"]}`}
                        />
                      )}
                    </p>
                    <button
                      onClick={handleCustomSearch}
                      className={`ul-button -app-tertiary -medium -icon ${classes["custom-filter__btn-search"]}`}
                      aria-label="add"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>
                </div>
              </div>
            )}

            <label className={classes["your-filters__label"]}>
              Your filters
            </label>

            <div className={classes["selected-filters"]}>
              {refiners.map((r) => {
                const refinerFilters = selectedRefiners[r.field];

                if (!hasRefinerFiltersValue(refinerFilters)) return null;

                const isArray = Array.isArray(refinerFilters);
                const label = r.label;
                const isCollapsed = isSelectedRefinerCollapsed(r.field);
                const onCollapse = () => handleSelectedRefinerCollapse(r.field);
                const onRemove = (type) => onRemoveSelectedRefiner(r, type);
                const types = [...numericTypes, ...dateTypes];

                return (
                  <div className={classes["refiner-filters"]} key={r.field}>
                    <div className={classes["header"]}>
                      <div onClick={onCollapse} className={classes["left"]}>
                        <i className="material-icons">
                          {isCollapsed ? "arrow_right" : "arrow_drop_down"}
                        </i>
                        <label>{label}</label>
                      </div>
                      <button
                        className={`ul-button ${classes["btn-remove-refiner-filters"]}`}
                        disabled={disabledRefiners?.field === r.field}
                        onClick={() => onRemove()}
                      >
                        <i className="material-icons">minimize</i>
                      </button>
                    </div>
                    {!isCollapsed && (
                      <div className={classes["body"]}>
                        {isArray
                          ? refinerFilters.map((v) => (
                              <div
                                className={classes["selected-filter"]}
                                key={v.label}
                              >
                                <label>{v.label}</label>
                                <button
                                  onClick={() =>
                                    onRemoveSelectedRefinerFilter(r, v)
                                  }
                                  disabled={
                                    disabledRefiners?.field === r.field &&
                                    disabledRefiners?.value?.find(
                                      (a) => a.label === v.label
                                    )
                                  }
                                  className={`ul-button ${classes["btn-remove-filter"]}`}
                                >
                                  <i className="material-icons">close</i>
                                </button>
                              </div>
                            ))
                          : Object.keys(refinerFilters).map((k) => {
                              const val = refinerFilters[k];

                              if (val === undefined || val === null)
                                return null;

                              const label = types
                                .filter((t) => t.type === k)
                                .map((t) => t.label || t.formLabel)[0];

                              return (
                                <div
                                  className={classes["selected-filter"]}
                                  key={k + val}
                                >
                                  {["Date From", "Date To"].includes(label) ? (
                                    <label>
                                      <div className="ul-grid__column">
                                        <div className="ul-grid__row -wrap">
                                          <span style={{ width: "80px" }}>
                                            {label}
                                          </span>
                                          &nbsp;
                                          {val}
                                        </div>
                                      </div>
                                    </label>
                                  ) : (
                                    <label>
                                      {label} {val}
                                    </label>
                                  )}
                                  <button
                                    onClick={() => onRemove(k)}
                                    className={`ul-button ${classes["btn-remove-filter"]}`}
                                  >
                                    <i className="material-icons">close</i>
                                  </button>
                                </div>
                              );
                            })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className={classes["btn-clear-container"]}>
              <button
                disabled={
                  !refiners?.length ||
                  (disabledRefiners?.field &&
                    Object.keys(selectedRefiners).length === 1)
                }
                className={`ul-button -app-tertiary -medium`}
                onClick={onRemoveSelectedRefiners}
              >
                CLEAR
              </button>
            </div>
          </div>

          <div className={classes["refiners-container"]}>
            <div className={classes["helper-text-container"]}>
              <label>Select a filter below to refine your search</label>
            </div>
            <div className={classes["filters-container"]}>
              {refiners?.map((r, i) => {
                return (
                  <div
                    key={r.field}
                    className={`${
                      classes[
                        (isRefinerExpanded(r.field) ? "expanded" : "collapsed",
                        r.field === disabledRefiners?.field ? "read-only" : "")
                      ]
                    } ${classes["field"]}`}
                  >
                    <div
                      onClick={() => handleRefinerExpand(r.field)}
                      className={classes["field-header"]}
                    >
                      <div className={classes["label"]}>{r.label}</div>

                      <i className="material-icons">
                        {isRefinerExpanded(r.field)
                          ? "arrow_drop_down"
                          : "arrow_right"}
                      </i>
                    </div>

                    {isRefinerExpanded(r.field) && (
                      <>
                        <div className={classes["field-control"]}>
                          {!r.element && renderMultiSelect(r)}
                          {r.element === "Calendar" && renderCalendar(r)}
                          {r.element === "Checkbox" && renderCheckboxes(r)}
                          {r.element === "Numeric" && renderNumeric(r)}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Refiners;
