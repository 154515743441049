/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PageTitle from "../../components/PageTitle";

const CertificationChecklist = () => {

  const checkList = [
    {
      id: 1,
      origOrder: 1,
      certScheme: "Delivery - ARPC",
      segment: 1,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "3",
      isChecked: false
    },
    {
      id: 2,
      origOrder: 2,
      certScheme: "Angola - INACOM",
      segment: 15,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 3,
      origOrder: 3,
      certScheme: "Algeria - INACOM",
      segment: 14,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 4,
      origOrder: 4,
      certScheme: "Algeria - INACOM",
      segment: 6,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 5,
      origOrder: 5,
      certScheme: "Algeria - INACOM",
      segment: 8,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 6,
      origOrder: 6,
      certScheme: "Algeria - INACOM",
      segment: 2,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 7,
      origOrder: 7,
      certScheme: "Algeria - INACOM",
      segment: 9,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 8,
      origOrder: 8,
      certScheme: "Algeria - INACOM",
      segment: 3,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 9,
      origOrder: 9,
      certScheme: "Algeria - INACOM",
      segment: 10,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 10,
      origOrder: 10,
      certScheme: "Algeria - INACOM",
      segment: 4,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 11,
      origOrder: 11,
      certScheme: "Algeria - INACOM",
      segment: 3,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 12,
      origOrder: 12,
      certScheme: "Algeria - INACOM",
      segment: 7,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 13,
      origOrder: 13,
      certScheme: "Algeria - INACOM",
      segment: 5,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 14,
      origOrder: 14,
      certScheme: "Algeria - INACOM",
      segment: 13,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    },
    {
      id: 15,
      origOrder: 15,
      certScheme: "Algeria - INACOM",
      segment: 12,
      deliveryPath: "Approve It",
      applicationType: "New",
      additionalInfo: "",
      localRepresentativeImporter: "N",
      localRepresentativeService: "N",
      certValidity: "10",
      isChecked: false
    }
  ];

  return (
    <div>
      <PageTitle title="Certification Checklist"></PageTitle>
      <div className="ul-tabs__container">
        <div className="ul-tabs -boxed -medium">
          <div className="ul-tabs__item -active">
            <a href="#">Delivery path information</a>
          </div>
          <div className="ul-tabs__item">
            <a href="#">Common documents</a>
          </div>
          <div className="ul-tabs__item">
            <a href="#">Country specific documents</a>
          </div>
          <div className="ul-tabs__item">
            <a href="#">Test sample list</a>
          </div>
          <div className="ul-tabs__item">
            <a href="#">Local rep list</a>
          </div>
        </div>

        <div className="ul-tabs__content">
          <div className="ul-datasetToolbox">
            <div className="ul-datasetToolbox__group">
              <div className="ul-datasetToolbox__item">
                <a href="#">
                  <i className="ul-icon material-icons">file_download</i>
                  Export
                </a>
              </div>

              <div className="ul-datasetToolbox__item">
                <a href="#">
                  <i className="ul-icon material-icons">email</i>
                  Email
                </a>
              </div>
            </div>

            <div className="ul-datasetToolbox__group -search">
              <div className="ul-datasetToolbox__item">
                <i className="ul-icon material-icons">search</i>
                <input
                  type="text"
                  className="ul-textbox -small"
                  placeholder="Find by certification scheme or delivery path"
                />
              </div>
            </div>

            <div className="ul-datasetToolbox__group">
              <div className="ul-datasetToolbox__item">
                <a href="#">
                  <i className="ul-icon material-icons">sort</i>
                  Sort
                </a>
              </div>
            </div>

            <div className="ul-datasetToolbox__group">
              <div className="ul-datasetToolbox__item">
                <a href="#">
                  <i className="ul-icon material-icons">filter_list</i>
                  Filter
                </a>
              </div>
            </div>

            <div className="ul-datasetToolbox__item">
              <a href="#">
                <i className="ul-icon material-icons">more_vert</i>
              </a>
            </div>
          </div>
          <table className="ul-table">
            <thead>
              <tr>
                <th className="ul-table__rowCheckbox">
                  <input
                    type="checkbox"
                    id="table-checkbox0"
                    className="ul-checkbox"
                    checked={true}
                    readOnly
                    indeterminate="true"
                  />
                  <label htmlFor="table-checkbox0"></label>
                </th>
                <th>Certification scheme</th>
                <th>Segment</th>
                <th>Delivery path</th>
                <th>Application type</th>
                <th>Additional info</th>
                <th>Local representative</th>
                <th>Local rep service</th>
                <th>Certificate validity (years)</th>
              </tr>
            </thead>

            <tbody>
              {checkList.map((item) => (
                <tr key={item.id}>
                  <td className="ul-table__rowCheckbox">
                    <input
                      type="checkbox"
                      className="ul-checkbox"
                      id={`table-checkbox-${item.id}`}
                    />
                    <label htmlFor={`table-checkbox-${item.id}`}></label>
                  </td>
                  <td>{item.certScheme}</td>
                  <td>{item.segment}</td>
                  <td>{item.deliveryPath}</td>
                  <td>{item.applicationType}</td>
                  <td>{item.additionalInfo}</td>
                  <td>{item.localRepresentativeImporter}</td>
                  <td>{item.localRepresentativeService}</td>
                  <td>{item.certValidity}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="ul-pagination">
            <div className="ul-pagination__itemsPerPage">
              <label htmlFor="itemsPerPage">Items per page</label>

              <select
                className="ul-select ul-pagination__select -records"
                id="itemsPerPage"
              >
                <option value="1">20</option>
                <option value="2">40</option>
                <option value="3">60</option>
                <option value="4">80</option>
              </select>
            </div>

            <div className="ul-pagination__stats">1-20 of 120</div>

            <div className="ul-pagination__pager">
              <button className="ul-button -app-secondary -small" disabled>
                <i className="material-icons ul-button__icon">first_page</i>
              </button>

              <button className="ul-button -app-secondary -small" disabled>
                <i className="material-icons">navigate_before</i>
              </button>

              <input
                type="number"
                aria-label="page-number"
                className="ul-textbox ul-pagination__jumpTo"
                defaultValue="1"
                min="1"
              />

              <span className="ul-pagination__pagerTotal">of 22 pages</span>

              <button className="ul-button -app-secondary -small">
                <i className="material-icons ul-button__icon">navigate_next</i>
              </button>

              <button className="ul-button -app-secondary -small">
                <i className="material-icons">last_page</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificationChecklist;
