import {
  Panel,
  PanelHeaderTemplateOptions,
  PanelProps,
} from "primereact/panel";
import classes from "./PmrRefiners.module.scss";
import clsx from "clsx";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import ULSearch from "../../../../../shared/ul-search/ULSearch";
import { ScrollPanel } from "primereact/scrollpanel";
import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  PropsWithRef,
  forwardRef,
} from "react";
import { useFormikContext } from "formik";
import { cleanEmpty } from "../../../../../utils/helpers/object.helpers";

const panelHeaderTemplate = (header: PanelHeaderTemplateOptions) => {
  return (
    <div className="p-panel-header">
      {header.iconsElement}
      {header.titleElement}
      {header.collapsed ? (
        <i className="material-icons">arrow_right</i>
      ) : (
        <i className="material-icons">arrow_drop_down</i>
      )}
      <i className={clsx("material-icons", classes["disabled-icon"])}>
        arrow_right
      </i>
    </div>
  );
};

//#region Selected Refiners
interface SelectedRefinersProps {
  reactRefiners: React.ReactChild[];
  formValues: any;
  parentName?: string;
  onMinimizeClick?: (formName: string) => void;
  onCloseClick?: (formName: string, value?: any) => void;
}

const RefinerItemTemplate = ({
  caption,
  onCloseClick,
}: {
  caption: string;
  onCloseClick: () => void;
}) => {
  return (
    <div className={classes["refiner-item-template"]}>
      <div className={classes["refiner-caption"]} title={caption}>
        {caption}
      </div>
      <div style={{ flexGrow: 1 }} />
      <i className="material-icons" onClick={onCloseClick}>
        close
      </i>
    </div>
  );
};

const SelectedRefiners = ({
  reactRefiners,
  formValues,
  parentName,
  onMinimizeClick,
  onCloseClick,
}: SelectedRefinersProps) => {
  const refinerTemplate = (
    refiner: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
    key: any
  ) => {
    const {
      label,
      name,
      children,
      container,
      selectedRefinerTemplate,
      selectedRefinerSort,
    } = refiner.props as PmrRefinerProps;
    const formValue = JSON.parse(JSON.stringify(formValues[name ?? ""]));
    let renderChild = <></>;
    const formName = parentName ? [parentName, name].join(".") : name ?? "";

    if (container && children.length > 0) {
      renderChild = (
        <SelectedRefiners
          reactRefiners={children}
          formValues={formValue}
          parentName={formName}
          onMinimizeClick={onMinimizeClick}
          onCloseClick={onCloseClick}
        />
      );
    } else if (Array.isArray(formValue)) {
      if (selectedRefinerSort) formValue.sort(selectedRefinerSort);
      renderChild = (
        <>
          {formValue.map((item, key) => {
            const displayValue = selectedRefinerTemplate
              ? selectedRefinerTemplate(item)
              : item;
            return (
              <RefinerItemTemplate
                key={key}
                caption={displayValue}
                onCloseClick={() => {
                  if (onCloseClick) {
                    onCloseClick(formName, item);
                  }
                }}
              />
            );
          })}
        </>
      );
    } else if (typeof formValue === "object") {
      renderChild = (
        <>
          {Object.entries(formValue).map((entry, key) => {
            const [pKey, value] = entry;
            const displayValue = selectedRefinerTemplate
              ? selectedRefinerTemplate(entry)
              : `${pKey} ${value}`;

            return (
              <RefinerItemTemplate
                key={key}
                caption={displayValue}
                onCloseClick={() => {
                  if (onCloseClick) {
                    onCloseClick(`${formName}.${pKey}`);
                  }
                }}
              />
            );
          })}
        </>
      );
    } else {
      const displayValue = selectedRefinerTemplate
        ? selectedRefinerTemplate(formValue)
        : formValue;
      renderChild = (
        <RefinerItemTemplate
          caption={displayValue}
          onCloseClick={() => {
            if (onCloseClick) {
              onCloseClick(formName);
            }
          }}
        />
      );
    }
    return (
      <Panel
        header={label}
        key={key}
        toggleable
        icons={
          !container && (
            <i
              className={clsx("pi pi-minus", classes["color-red"])}
              style={{ cursor: "pointer", zIndex: 10, position: "relative" }}
              onClick={() => {
                if (onMinimizeClick) onMinimizeClick(formName);
              }}
            />
          )
        }
        className={clsx(
          classes["custom-panel"],
          classes["custom-tree-panel"],
          !container && classes["custom-tree-content"]
        )}
        headerTemplate={panelHeaderTemplate}
      >
        {renderChild}
      </Panel>
    );
  };
  return (
    <>
      {(
        reactRefiners.filter((refiner) => {
          if (!refiner) return;
          if (typeof refiner === "string" || typeof refiner === "number")
            return false;
          if (!refiner.props.name) return false;
          if (!Object.hasOwn(formValues, refiner.props.name)) return false;

          return true;
        }) as React.ReactElement<
          any,
          string | React.JSXElementConstructor<any>
        >[]
      ).map(refinerTemplate)}
    </>
  );
};
//#endregion

//#region Refiner
/**
 * Use when refiners are grouped into component.
 */
export interface PmrRefinerGroup {
  /**
   * Required to use forwardRef to custom components
   */
  refinerGroup: boolean;
}
interface PmrRefinerProps {
  label: string;
  disabled?: boolean;
  children?: any;
  container?: boolean;
  name?: string;
  hidden?: boolean;
  selectedRefinerTemplate?: (value: any) => any;
  /**
   * Applicable only for refiners with array values
   */
  selectedRefinerSort?: (firstItem: any, secondItem: any) => number;
}
export function PmrRefiner({
  label,
  disabled,
  children,
  container: isHeader,
  hidden,
}: PmrRefinerProps) {
  const defaultPanelProps: PanelProps = {
    className: clsx(
      disabled && classes["disabled"],
      isHeader && classes["refiner-header"],
      hidden && classes["hidden"]
    ),
    toggleable: true,
    collapsed: !isHeader,
  };
  return (
    <>
      <Panel
        {...defaultPanelProps}
        header={label}
        headerTemplate={panelHeaderTemplate}
      >
        {children}
      </Panel>
    </>
  );
}
//#endregion

interface GlobalSearchProps {
  onSubmit?: (value: string) => void;
  name?: string;
  value?: string;
}

export interface PmrRefinersProps extends PropsWithRef<any> {
  children:
    | ReactElement<PmrRefinerProps>
    | Array<ReactElement<PmrRefinerProps>>;
  onClear?: () => void;
  onSubmit?: (value: any) => void;
  globalSearch?: GlobalSearchProps;
}

type ReactChildArray = ReturnType<typeof React.Children.toArray>;
function flattenChildren(children: React.ReactNode) {
  const childrenArray = React.Children.toArray(children);
  return childrenArray.reduce((flatChildren: ReactChildArray, child) => {
    const reactEl = child as JSX.Element;

    if (reactEl.props.refinerGroup && (reactEl as any).ref?.current) {
      const elEntries = Object.entries((reactEl as any).ref.current);
      const [_, elProps] = elEntries.find(([key]) =>
        key.startsWith("__reactProps$")
      ) as any;

      return flatChildren.concat(elProps.children);
    } else if (reactEl.type !== PmrRefiner && !reactEl.props.container) {
      return flatChildren.concat(reactEl.props.children);
    }

    flatChildren.push(child);
    return flatChildren;
  }, []);
}

/**
 * This component should be use with Formik component only.
 */
function PmrRefiners(
  { children, onClear, onSubmit, globalSearch }: PmrRefinersProps,
  ref: any
) {
  const { values, resetForm, getFieldMeta, setFieldValue, initialValues } =
    useFormikContext();
  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRefinerValues, setSelectedRefinerValues] = useState({});
  const reactChildren = flattenChildren(children) as React.ReactChild[];

  const handleOnClear = () => {
    if (onClear) onClear();
    if (globalSearch?.name) {
      resetForm({
        values: {
          ...(initialValues as any),
          [globalSearch.name]: (values as any)[globalSearch.name],
        },
      });
    } else {
      resetForm();
    }
  };

  const handleMinimizeClick = (formName: string) => {
    setFieldValue(formName, getFieldMeta(formName).initialValue);
  };

  const handleCloseClick = (formName: string, value?: any) => {
    const fieldMeta = getFieldMeta(formName);
    let finalValue: any = "";

    if (Array.isArray(fieldMeta.value)) {
      finalValue = fieldMeta.value.filter(
        (item) => !JSON.stringify(item).includes(JSON.stringify(value))
      );
    } else {
      finalValue = fieldMeta.initialValue;
    }

    setFieldValue(formName, finalValue);
  };

  useEffect(() => {
    setFormValues(cleanEmpty(structuredClone(values)));
  }, [values]);

  useEffect(() => {
    if (!isSubmitting) return;

    setIsSubmitting(false);
    if (onSubmit) onSubmit({ ...(formValues as any) });
  }, [isSubmitting]);

  useEffect(() => {
    let finalSelectedRefinerValues = formValues;
    if (globalSearch?.name) {
      const { [globalSearch.name]: _, ...finalValue } = formValues as any;
      finalSelectedRefinerValues = finalValue;
    }

    setSelectedRefinerValues(finalSelectedRefinerValues);
    if (onSubmit) onSubmit(formValues);
  }, [formValues]);

  return (
    <div className={classes["body"]}>
      <div style={{ height: "40vh", overflowY: "scroll" }}>
        <Panel
          header="Filter Menu"
          className={classes["selected-refiner-wrapper"]}
        >
          <ULSearch
            label="Search"
            placeholder="enter keywords..."
            value={globalSearch?.value}
            onSubmit={(value) => {
              if (globalSearch?.name) {
                setFieldValue(globalSearch.name, value);
                setIsSubmitting(true);
              }
              if (globalSearch && globalSearch.onSubmit)
                globalSearch.onSubmit(value);
            }}
          />
        </Panel>
        <Divider style={{ margin: "1rem 0" }} />

        <div className={classes["your-filters"]}>
          <label className="font-weight-bold">Your Filters</label>
          <SelectedRefiners
            formValues={selectedRefinerValues}
            reactRefiners={reactChildren}
            onMinimizeClick={handleMinimizeClick}
            onCloseClick={handleCloseClick}
          />
          <div className="text-center pt-10">
            <Button
              className={clsx(
                "p-button-outlined p-button-secondary",
                classes["clear-button"]
              )}
              onClick={handleOnClear}
              disabled={Object.keys(selectedRefinerValues).length <= 0}
            >
              CLEAR
            </Button>
          </div>
        </div>
      </div>
      <Divider />
      <div style={{ height: "60vh", overflowY: "scroll" }}>
        <div className={clsx(classes["helper-text"])}>
          <label>Select a filter below to refine your search</label>
        </div>
        <div className={classes["custom-panel"]} ref={ref}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef(PmrRefiners);
