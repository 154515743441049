import { faClose, faFileImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgressBar } from "primereact/progressbar";
import { FileUploader as DragDropFilesUploader } from "react-drag-drop-files";
import classes from "./FileUploader.module.scss";

const FILE_STATUS = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  UPLOADING: "UPLOADING",
  FOR_UPLOAD: "FOR_UPLOAD",
};

const FileUploader = ({
  files,
  maxSize, // in MiB
  fileTypes,
  onDelete,
  onChange,
  onFileNameClick,
  controlClassName,
  ...otherProps
}) => {
  const getFileUploadMessage = (file) => {
    if (file.status === FILE_STATUS.SUCCESS)
      return "File successfully uploaded.";
    else if (file.status === FILE_STATUS.UPLOADING) return "Uploading file...";
    else if (file.status === FILE_STATUS.ERROR)
      return file.errorMessage ?? "Uploading failed.";
  };

  const getUploadProgress = (file) => {
    if (file.status === FILE_STATUS.SUCCESS) return 100;
    return file.uploadProgress;
  };

  const handleDeleteFile = (file, fileIndex) => {
    onDelete && onDelete(file, fileIndex);
  };

  const bytesToMib = (bytes) => {
    return bytes / 1024 / 1024;
  };

  const handleChange = (files) => {
    if (!files?.length) return;
    let result = [];

    if (!files.length) result = [files];
    else result = [...files];

    for (let x = 0; x < result.length; x++) {
      const file = result[x];

      if (!isFileSizeValid(file))
        file.errorMessage = `${file.name} failed to attach, file exceeds the size ${maxSize} MB.`;
      else if (!isFileTypeValid(file))
        file.errorMessage = "File type not allowed.";
      else if (isFileAlreadyExists(file))
        file.errorMessage = "Duplicate file already exists.";

      if (file.errorMessage) file.status = FILE_STATUS.ERROR;
      else file.status = FILE_STATUS.FOR_UPLOAD;
    }

    onChange(result);
  };

  const isFileSizeValid = (file) => {
    return !maxSize || bytesToMib(file.size) < maxSize;
  };

  const isFileTypeValid = (file) => {
    if (!fileTypes?.length) return true;

    var fileType = file.name.split(".").pop().toUpperCase();

    return fileTypes.some((x) => x.toUpperCase() === fileType);
  };

  const isFileAlreadyExists = (file) => {
    return files?.some((f) => f.name === file.name);
  };

  const handleFilenameClick = (file) => {
    onFileNameClick && onFileNameClick(file);
  };

  return (
    <>
      <DragDropFilesUploader
        fileOrFiles={[]}
        handleChange={handleChange}
        {...otherProps}
        children={
          <div>
            <div>
              <button
                className={`ul-button -app-secondary ${classes["file-upload-button"]}`}
              >
                SELECT FILES
              </button>
            </div>
            <div className={classes["drag-area"]}>Drop file here to upload</div>
          </div>
        }
        classes={`${classes["file-upload-container"]} ${controlClassName}`}
      />
      <div className={classes["uploaded-files-container"]}>
        {files &&
          files.map((file, i) => (
            <div
              key={file.name}
              className={`${classes["uploaded-file"]} ${classes[file.status?.toLowerCase()]
                }`}
            >
              <div className={classes["file-details"]}>
                <div className={classes["file-icon-container"]}>
                  <FontAwesomeIcon size="xl" icon={faFileImage} />
                </div>
                <div className={classes["filename-and-message-container"]}>
                  <button
                    onClick={() => handleFilenameClick(file)}
                    className={`${classes["filename"]} ${classes[file.status?.toLowerCase()]
                      }`}
                    disabled={file.status !== "SUCCESS"}
                  >
                    {file.name}
                  </button>
                  <div className={classes["message"]}>
                    {getFileUploadMessage(file)}
                  </div>
                </div>

                {file.status !== FILE_STATUS.FOR_UPLOAD &&
                  file.status !== FILE_STATUS.UPLOADING &&
                  !otherProps.disabled && (
                    <div className={classes["close-icon-container"]}>
                      <FontAwesomeIcon
                        className={classes["close-icon"]}
                        icon={faClose}
                        onClick={() => handleDeleteFile(file, i)}
                      />
                    </div>
                  )}
              </div>
              {file.status !== FILE_STATUS.ERROR && (
                <ProgressBar
                  className={classes["progress-bar"]}
                  showValue={false}
                  color="#00518A"
                  value={getUploadProgress(file)}
                ></ProgressBar>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export default FileUploader;
export { FILE_STATUS };
