import { useFormikContext } from "formik";
import { CheckboxChangeParams } from "primereact/checkbox";

export default function useFormikHelper() {
  const { handleChange, getFieldHelpers, getFieldMeta } =
    useFormikContext<any>();

  const handleZeroEnumChange = (event: CheckboxChangeParams) => {
    if (event.value !== 0) {
      handleChange(event);
      return;
    }

    const fieldName = event.target.name;
    const field = getFieldHelpers(fieldName);
    const fieldMeta = getFieldMeta(fieldName);

    const arrValue = structuredClone((fieldMeta?.value as any[]) ?? []);
    arrValue.push(event.value);

    const setValue = new Set(arrValue);

    field.setValue(
      [...setValue].filter((item) => {
        if (event.checked) return true;
        return item !== event.value;
      })
    );
  };

  return { handleZeroEnumChange };
}
