import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import classes from "./GpiFormNameModal.module.scss";
import GeneralProductInformationService from "../../../services/GeneralProductInformationService";
import { Button } from "primereact/button";

export const duplicateFormNameErrorMessage =
  "GPI Form name already exists. Please enter a different GPI Form name.";

export const specialCharErrorMessage = String.raw`GPI Form name cannot contain the following 
  characters:  \ / : * ? " < > |. Please enter a different GPI Form name.`;

const GpiFormNameModal = ({
  visible,
  formName,
  currentFormName,
  onChangeFormName,
  onCancel,
  onHide,
  onSave,
  isSaving,
  checkDuplicateFormName = true,
  error,
  label,
  highlightFormNameInputOnShow = false,
}) => {
  const gpiFormNameInputRef = useRef();
  const [_error, _setError] = useState("");
  const [duplicateFormNames, setDuplicateFormNames] = useState([]);

  const [loading, setLoading] = useState(false);
  const [resetError, setResetError] = useState(false);

  const header = () => {
    return (
      <>
        <h4 className={classes["modal-header"]}>
          {currentFormName ? "Edit GPI Form name" : "Save GPI Form"}
        </h4>
        <hr className={classes["modal-header-hr"]} />
      </>
    );
  };

  const handleSave = async () => {
    if (_error) return;
    setResetError(false);

    // If nothing has changed, just hide the form.
    if (currentFormName === formName) {
      onHide();
      return;
    }

    if (checkDuplicateFormName) {
      setLoading(true);
      var gpiFormNameExists =
        await GeneralProductInformationService.gpiFormNameExists(formName);
      setLoading(false);

      if (gpiFormNameExists) {
        _setError(duplicateFormNameErrorMessage);

        setDuplicateFormNames([...duplicateFormNames, formName]);
        return;
      }
    }

    var restrictedChars = String.raw`\-/-:-*-?-"-<->-|`; //special chars \/:*?"<>|
    var split = restrictedChars.split("-");
    for (var i = 0; i < split.length; i++) {
      if (formName.includes(split[i]) !== false) {
        _setError(specialCharErrorMessage);
        return;
      }
    }

    onSave(formName);
  };

  const handleChangeFormName = (e) => {
    const formName = e.target.value;
    onChangeFormName(e.target.value);

    if (!duplicateFormNames.includes(formName)) _setError("");
    else _setError(duplicateFormNameErrorMessage);

    if (formName.trim().length === 0) _setError("Please enter a GPI Form name");

    setResetError(false);
  };

  const resetErrors = (e) => {
    setResetError(true);
    _setError("");
  };

  const onKeyPressHandler = (e) => {
    var charCode = !e.charCode ? e.which : e.charCode;
    var restrictedChars = [34, 42, 47, 58, 60, 62, 63, 92, 124]; //special chars \/:*?"<>
    if (restrictedChars.indexOf(charCode) > -1) {
      const timer = setTimeout(() => {
        _setError(specialCharErrorMessage);
      }, 100);
      return () => clearTimeout(timer);
    }
  };

  const handleModalShow = (e) => {
    if (highlightFormNameInputOnShow) {
      gpiFormNameInputRef.current.focus();
      gpiFormNameInputRef.current.select();
    }
  };

  const footer = () => {
    return (
      <>
        <hr className={classes["modal-footer-hr"]} />
        <div className={`text-center ${classes["footer-content"]}`}>
          <Button
            loading={loading || isSaving}
            disabled={loading || isSaving || !formName}
            onClick={handleSave}
            className={`ul-button -app-primary ${classes["btn-save"]}`}
          >
            {currentFormName ? "UPDATE" : "SAVE"}
          </Button>

          <button
            onClick={() => {
              onCancel();
              resetErrors();
            }}
            className={`ul-button ${classes["btn-cancel"]}`}
          >
            CANCEL
          </button>
        </div>
      </>
    );
  };

  return (
    <Dialog
      closable={true}
      draggable={false}
      resizable={false}
      visible={visible}
      header={header}
      footer={footer}
      onHide={onHide}
      onShow={handleModalShow}
      className={classes["container"]}
      style={{ width: "620px" }}
    >
      <div className={classes["modal-content"]}>
        <label>
          {label ||
            "Please enter GPI Form name or you may use this default name:"}
        </label>

        <InputText
          autoFocus={true}
          ref={gpiFormNameInputRef}
          value={formName}
          onChange={handleChangeFormName}
          className={`${classes["form-name-input"]} ${
            !resetError && (_error || error) ? classes["error"] : ""
          } ul-textbox`}
          onKeyPress={(e) => onKeyPressHandler(e)}
        />

        {!resetError && (_error || error) && (
          <label className={classes["error-message"]}>{_error || error}</label>
        )}
      </div>
    </Dialog>
  );
};

export default GpiFormNameModal;
