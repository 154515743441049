import React, { MouseEventHandler, SetStateAction } from "react";
import classes from "./ProjectDetailsFlexList.module.scss";
import { FlexProjectItem } from "../ProjectDetails";
import ProjectDetailsFlexListItem from "./project-details-flex-item/ProjectDetailsFlexListItem";

export interface ProjectDetailsFlexListProps {
  items?: FlexProjectItem[];
  setFlexProjects: React.Dispatch<SetStateAction<FlexProjectItem[]>>;
}

const ProjectDetailsFlexList = (props: ProjectDetailsFlexListProps) => {

  const onItemClick = (item: FlexProjectItem) => {
    
    const copyItems = structuredClone(props.items);
    copyItems?.map((copyItem) => {
      copyItem.isActive = false;

      if(copyItem.id === item.id){
          copyItem.isActive = true;
      }
      
      return copyItem;
    })

    
    props.setFlexProjects(copyItems ?? []);

  }

  return (
    <div className={`${classes["projectDetailsFlexList__container"]}`}>
      <span className={`${classes["list-counter"]}`}>
        Total of {props.items?.length} FLEX Project(s)
      </span>
      <div className={`${classes["list-container"]}`}>
        {props.items?.map((item, index) => {
          return <ProjectDetailsFlexListItem key={`${item.name}-${index}`} item={item} onClick={(e) => onItemClick(item)}  />
        })}
      </div>
    </div>
  );
};

export default ProjectDetailsFlexList;
