import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, ReactNode } from "react";
import classes from "./FormTemplateError.module.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core/index";

export interface FormTemplateErrorProps {
  errorMessage: string | ReactNode;
  visible: boolean;
  icon: IconDefinition;
}

const FormTemplateError = (componentProps: FormTemplateErrorProps) => {
  if (!componentProps.visible) {
    return null;
  }

  return (
    <div className={classes["error-message"]}>
      <small className="p-error">
        <FontAwesomeIcon icon={componentProps.icon} />{" "}
        <span className={classes["error-text"]}>
          {componentProps.errorMessage}
        </span>
      </small>
    </div>
  );
};

export default FormTemplateError;
