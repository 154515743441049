const allowedMaxFileSize = 50;
const allowedFileExtensions = [
  "JPG",
  "JPEG",
  "DOC",
  "DOCX",
  "BMP",
  "GIF",
  "PDF",
  "PNG",
];
export const validFileSize = (
  fileSize: number = 0,
  allowedFileSize: number = allowedMaxFileSize
): boolean => {
  let actualSize = fileSize / 1024 / 1024;
  return actualSize <= allowedFileSize;
};

export const validFileType = (fileName: string): boolean => {
  if (!fileName) return false;
  const fileExtension = fileName.split(".").pop()?.toUpperCase() || "";
  const isFileTypeValid = allowedFileExtensions.includes(fileExtension);
  return isFileTypeValid;
};

export const validFileName = (fileName: string): boolean => { 
  if (!fileName) return false;
  //only accepts alphanumeric, international languages and  the following special characters: - _ = + [ ] { } ` ~ ; ' , . ! @ # $ % ^ & * ( )
  const regexPattern = /^[\p{L}\p{N}\-_=+\[\]{}`~;',.!@#$%^&*() ]*$/u;
  return regexPattern.test(fileName);
}