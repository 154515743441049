import { useEffect, useState } from "react";
import CloseIcon from "../close-icon/CloseIcon";
import { Dialog } from "primereact/dialog";
import {
  selectAutotimestampTask,
  updateAutoTimestampTask,
} from "../../features/createCertificate/createCertificateSlice";
import { useDispatch, useSelector } from "react-redux";
import ProjectManagementService from "../../services/ProjectManagementService";
import useToastr from "../../hooks/useToastr";

const AutoTimestampModal = (props) => {
  const {
    isOpen,
    setIsOpen,
    message = "Do you want to mark the following as Complete?",
  } = props;
  const [isTimestamping, setIsTimestamping] = useState(false);
  const [displayTask, setDisplayTask] = useState([]);
  const { showSuccess, showError } = useToastr();
  const dispatch = useDispatch();

  const tasks = useSelector(selectAutotimestampTask);

  const onCloseModal = () => {
    setIsOpen(false);
    dispatch(updateAutoTimestampTask([]));
  };

  const onTimestamp = async () => {
    setIsTimestamping(true);
    const taskWithoutTimestamp = tasks.filter((x) => x.actualFinish === null);
    const data = {
      tasks: JSON.stringify(taskWithoutTimestamp),
      isFlexWriteback: true,
    };
    const response = await ProjectManagementService.autotimestamp(data);

    if (response) {
      showSuccess("Success!", "Timestamp successfully saved");
    } else {
      showError("Error!", "Timestamp failed");
    }
    setIsTimestamping(false);
    onCloseModal();
  };

  useEffect(() => {
    const display = tasks.map((t) => t.taskName);
    setDisplayTask(display);
  }, [tasks]);

  const renderFooter = () => {
    return (
      <>
        <hr className="modal-footer-hr" />
        <div className="footer-center-button">
          <button
            className="ul-button -app-primary -medium default-save-button"
            disabled={isTimestamping}
            onClick={onTimestamp}
          >
            <span>YES</span>
          </button>
          <button
            className="ul-button -medium default-cancel-button"
            onClick={onCloseModal}
            disabled={isTimestamping}
          >
            <span>NO</span>
          </button>
        </div>
      </>
    );
  };

  return (
    <Dialog
      header="Timestamp Confirmation"
      visible={isOpen}
      style={{ width: "30vw" }}
      footer={renderFooter()}
      icons={<CloseIcon onCloseEvent={onCloseModal} />}
      closable={false}
      className="autotimestamp-modal"
    >
      <hr className="modal-header-hr" />
      {isTimestamping && (
        <div className="center-loading">
          <div>Timestamping......</div>
          <div>
            <svg className="ul-progressCircle -medium">
              <circle
                className="ul-progressCircle__outer"
                cx="50%"
                cy="50%"
                r="35%"
              />
              <circle
                className="ul-progressCircle__inner"
                cx="50%"
                cy="50%"
                r="35%"
              />
            </svg>
          </div>
        </div>
      )}
      {!isTimestamping && (
        <>
          <div className="pt-10">{message}</div>
          {displayTask.map((task) => (
            <div className="pl-10">&#9679; {task}</div>
          ))}
        </>
      )}
    </Dialog>
  );
};

export default AutoTimestampModal;
