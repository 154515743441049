import debounce from "lodash.debounce";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { Tooltip } from "primereact/tooltip";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCertDetailValues,
  selectCopyPartyInformation,
  selectModelDetailValues,
  selectValues,
  selectProdDetailValues,
  selectStandardValue,
  selectStandardOptions,
  selectModelList,
  updateCertDetailsFormValues,
  updateIsCertNumberOnMaxlength,
  updateStandardOptions,
  updateHasExistingModels,
  updateProdDetailsModelList,
  updateProductOwner,
  updateStandardValues,
  updateModelList,
  updateProductCategoryStandards,
  updateHasCertificateProductStandard
} from "../../../../../../features/createCertificate/createCertificateSlice";
import CertificateListService from "../../../../../../services/CertificateListService";
import ControlledInput from "../../../../../../shared/controlled-input/ControlledInput";
import CustomDatePicker from "../../../../../../shared/custom-date-picker/CustomDatePicker";
import "./DynamicFieldMultiple.scss";
import { appInsights } from "../../../../../../services/appInsights";
import { v4 as uuidv4 } from "uuid";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { uniqBy } from "lodash";
import { parseDate } from "../../../../../../utils/helpers/date.helpers";
const DynamicFieldMultiple = (props) => {
  const {
    field,
    showMultiSelectValuesTooltip,
    formValues,
    attributeFlatList,
    isRequired,
    setFormValidityMessages,
    setFormValues,
    fieldIndex,
    rowIndex,
    fieldType,
    selectedScheme,
    setIsShowNoStandard,
    isEditCert,
    isModifyCert,
    hasRevisions,
    addDynamicField,
    certificateDetails,
    setDropdownRef,
    setProductOwnerFilter,
    isFieldDisabled,
  } = props;

  const logPrefix = `DynamicFieldMultiple - ${uuidv4()}`;
  const attributeNames = {
    STANDARD: "standard",
  };

  const [dropdownWithSearch, setDropdownWithSearch] = useState([]);
  const [dropdownWithSearchStandard, setDropdownWithSearchStandard] = useState(
    []
  );
  const [origDropdownWithSearch, setOrigDropdownWithSearch] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [dynamicFieldValue, setDynamicFieldValue] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [filterTextValue, setFilterTextValue] = useState("");
  const [
    isIpAttributesDropdownListLoading,
    setisIpAttributesDropdownListLoading,
  ] = useState(false);
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const projectDetailsValues = useSelector(selectValues);
  const modelDetailsValues = useSelector(selectModelDetailValues);
  const certDetailsValues = useSelector(selectCertDetailValues);
  const copyPartyInformation = useSelector(selectCopyPartyInformation);
  const productDetails = useSelector(selectProdDetailValues);
  const standardValue = useSelector(selectStandardValue);
  const standardOptions = useSelector(selectStandardOptions);
  const modelList = useSelector(selectModelList);

  const projectDetailsValuesPsn =
    projectDetailsValues.selectedProjectDetails &&
    projectDetailsValues.selectedProjectDetails?.partySiteNumber
      ? projectDetailsValues.selectedProjectDetails?.partySiteNumber
      : "";

  const modelDetailsValuesPsn = modelDetailsValues?.partySiteNumber
    ? modelDetailsValues?.partySiteNumber
    : null;

  useEffect(() => {
    if (field.attribute_name === "ownerReference") setDropdownRef(dropdownRef);
  });

  useEffect(() => {
    if (isFormValuesObjectInValid(formValues)) return;

    let newValue = "";
    if (fieldType === "list" && !field.is_allow_search) {
      newValue = formValues[field.attribute_name][fieldIndex]
        ? formValues[field.attribute_name][fieldIndex]
        : "";
    } else if (fieldType === "list" && field.is_allow_search) {
      newValue = formValues[field.attribute_name][fieldIndex]
        ? formValues[field.attribute_name][fieldIndex]
        : "";
    } else if (fieldType === "table") {
      newValue = formValues[field.attribute_name][rowIndex]
        ? formValues[field.attribute_name][rowIndex]
        : "";
    } else if (fieldType === "single" || fieldType === "selectmultiple") {
      newValue = formValues[field.attribute_name]
        ? formValues[field.attribute_name]
        : "";
    }

    setDynamicFieldValue(newValue);

    if (field.data_type === "date") {
      const parsedDate = parseDate(newValue);
      setSelectedDate(parsedDate ? parsedDate : null);
    }
  }, [
    fieldType,
    formValues,
    fieldIndex,
    field.attribute_name,
    field.data_type,
    rowIndex,
    JSON.stringify(formValues[field.attribute_name]),
  ]);

  useEffect(async () => {
    if (isFormValuesObjectInValid(formValues)) return;

    if (
      !certDetailsValues.standardOptions &&
      field.attribute_name === attributeNames.STANDARD
    ) {
      const prodCategory = formValues["certificationProductType"];
      const standards = formValues[attributeNames.STANDARD];
      if (
        field.attribute_name === attributeNames.STANDARD &&
        attributeFlatList &&
        prodCategory
      ) {
        (isEditCert || isModifyCert) &&
          (await handleStandardDetails(standards, prodCategory));
        await handleGetStandardList(prodCategory, attributeNames.STANDARD);
      }
    } else if (
      certDetailsValues.standardOptions &&
      field.attribute_name === attributeNames.STANDARD &&
      dropdownWithSearchStandard.length === 0
    ) {
      dispatch(updateStandardOptions(certDetailsValues.standardOptions));
      setDropdownWithSearchStandard(certDetailsValues.standardOptions);
    }
  }, [dispatch, formValues]);

  useEffect(async () => {
    if (isFormValuesObjectInValid(formValues)) return;
    let search = selectedScheme;
    if (
      field.attribute_name === "certificationProductType" &&
      search &&
      (!dropdownWithSearch || dropdownWithSearch.length === 0) &&
      !filterTextValue
    ) {
      await CertificateListService.getIpAttributesDropdownList(
        field.attribute_name,
        search
      ).then((result) => {
        setDropdownWithSearch(result);
        if (formValues[field.attribute_name]) {
          setDynamicFieldValue(formValues[field.attribute_name]);
        }
      });
    }
  }, [
    field.attribute_name,
    selectedScheme,
    filterTextValue,
    formValues,
    dropdownWithSearch,
    setIsShowNoStandard,
  ]);

  useEffect(() => {
    setisIpAttributesDropdownListLoading(false);
  }, [dropdownWithSearch]);

  useEffect(() => {
    if (
      !certDetailsValues.standardOptions &&
      field.attribute_name === attributeNames.STANDARD
    ) {
      setDropdownWithSearch(certDetailsValues.standardOptions);
    }
  }, [certDetailsValues.standardOptions]);

  const isCopyingPartyInformation =
    copyPartyInformation && field.type_identity === "B_PARTY TYPE";

  useEffect(async () => {
    if (
      (!dropdownWithSearch || dropdownWithSearch.length === 0) &&
      !filterTextValue
    ) {
      if (
        field.is_allow_search &&
        (modelDetailsValuesPsn ||
          projectDetailsValuesPsn ||
          isCopyingPartyInformation)
      ) {
        let defaultSelected;
        if ((isEditCert || isCopyingPartyInformation) && dynamicFieldValue) {
          defaultSelected = dynamicFieldValue;
        }

        if (
          !isEditCert &&
          !isCopyingPartyInformation &&
          !addDynamicField &&
          (modelDetailsValuesPsn || projectDetailsValuesPsn)
        ) {
          defaultSelected =
            field.attribute_name === "ownerReference"
              ? modelDetailsValuesPsn ?? projectDetailsValuesPsn
              : projectDetailsValuesPsn;
        }

        if (!defaultSelected) return;

        await CertificateListService.getIpAttributesDropdownList(
          field.attribute_name,
          defaultSelected
        ).then((result) => {
          if (result && result.length > 0) {
            setDropdownWithSearch(result);

            if (!filterTextValue && !origDropdownWithSearch) {
              setOrigDropdownWithSearch(result);
            }

            if (
              field.attribute_name === "ownerReference" ||
              field.attribute_name === "applicant"
            ) {
              setDynamicFieldValue(defaultSelected);
              setFormValues((prevState) => {
                const newState = {
                  ...prevState,
                  [field.attribute_name]: defaultSelected,
                };

                dispatch(updateCertDetailsFormValues(newState));
                return newState;
              });

              setFormValidityMessages((prevState) => {
                let newState = JSON.parse(JSON.stringify(prevState));
                newState[field.attribute_name] = "";
                return newState;
              });
            }
          } else {
            appInsights?.trackTrace({
              message: `${logPrefix} - CertificateListService.getIpAttributesDropdownList - 'No data with given parameter ${field.attribute_name} / ${defaultSelected}'}`,
              severityLevel: SeverityLevel.Error,
            });
          }
        });
      }
      //If no partysitenumber, get the value from cerfiticate details from cert attribute api level
      //we will get the values from cert edit level
      //only with 4 properties mentioned below
      else {
        if (
          field.attribute_name === "ownerReference" ||
          field.attribute_name === "applicant" ||
          field.attribute_name === "localRepresentative" ||
          field.attribute_name === "brandOwner"
        ) {
          const defaultSelected =
            certDetailsValues.formValues[field.attribute_name];

          await CertificateListService.getIpAttributesDropdownList(
            field.attribute_name,
            defaultSelected
          ).then((result) => {
            if (result && result.length > 0) {
              setDropdownWithSearch(result);

              if (!filterTextValue && !origDropdownWithSearch) {
                setOrigDropdownWithSearch(result);
              }
              setDynamicFieldValue(defaultSelected);
              setFormValues((prevState) => {
                const newState = {
                  ...prevState,
                  [field.attribute_name]: defaultSelected,
                };

                dispatch(updateCertDetailsFormValues(newState));
                return newState;
              });

              setFormValidityMessages((prevState) => {
                let newState = JSON.parse(JSON.stringify(prevState));
                newState[field.attribute_name] = "";
                return newState;
              });
            } else {
              appInsights?.trackTrace({
                message: `${logPrefix} - CertificateListService.getIpAttributesDropdownList - 'No data with given parameter ${field.attribute_name} / ${defaultSelected}'}`,
                severityLevel: SeverityLevel.Error,
              });
            }
          });
        }

        // const certificateAttribute = certDetailsValues.attributeFlatList(
        //   (x) => x.attribute_name == field.attribute_name
        // );

        // if (!certificateAttribute) return;
      }
    }
  }, [
    field.attribute_name,
    field.is_allow_search,
    filterTextValue,
    origDropdownWithSearch,
    setFormValues,
    dropdownWithSearch,
    setFormValidityMessages,
    modelDetailsValues,
    projectDetailsValues,
    dynamicFieldValue,
    isEditCert,
    dispatch,
  ]);

  useEffect(async () => {
    if (
      (!dropdownWithSearch || dropdownWithSearch.length === 0) &&
      !filterTextValue
    ) {
      if (
        field.is_allow_search &&
        (modelDetailsValuesPsn ||
          projectDetailsValuesPsn ||
          isCopyingPartyInformation)
      ) {
        let defaultSelected;
        if (
          addDynamicField &&
          (projectDetailsValuesPsn || modelDetailsValuesPsn)
        ) {
          defaultSelected =
            field.attribute_name === "ownerReference"
              ? modelDetailsValuesPsn ?? projectDetailsValuesPsn
              : projectDetailsValuesPsn;
        }

        if (!defaultSelected) return;

        await CertificateListService.getIpAttributesDropdownList(
          field.attribute_name,
          defaultSelected
        ).then((result) => {
          if (result && result.length > 0) {
            setDropdownWithSearch(result);

            if (!filterTextValue && !origDropdownWithSearch) {
              setOrigDropdownWithSearch(result);
            }

            if (
              field.attribute_name === "ownerReference" ||
              field.attribute_name === "applicant"
            ) {
              setDynamicFieldValue(defaultSelected);
              setFormValues((prevState) => {
                const newState = {
                  ...prevState,
                  [field.attribute_name]: defaultSelected,
                };

                dispatch(updateCertDetailsFormValues(newState));
                return newState;
              });

              setFormValidityMessages((prevState) => {
                let newState = JSON.parse(JSON.stringify(prevState));
                newState[field.attribute_name] = "";
                return newState;
              });
            }
          } else {
            appInsights?.trackTrace({
              message: `${logPrefix} - CertificateListService.getIpAttributesDropdownList - 'No data with given parameter ${field.attribute_name} / ${defaultSelected}'}`,
              severityLevel: SeverityLevel.Error,
            });
          }
        });
      }
    }
  }, [addDynamicField]);

  useEffect(() => {
    const { formValues } = certDetailsValues;
    if (field.attribute_name === "ownerReference") {
      setFormValues(formValues);
    }
  }, [certDetailsValues.formValues.ownerReference]);

  const onFilterDropdownWithSearch = async function (e) {
    setFilterTextValue(e.filter);

    if (field.attribute_name === "ownerReference")
      setProductOwnerFilter(e.filter);

    if (e.filter) {
      await CertificateListService.getIpAttributesDropdownList(
        field.attribute_name,
        e.filter
      ).then((result) => {
        if (result) {
          if (
            result.length === 0 &&
            field.attribute_name !== "ownerReference"
          ) {
            setFieldValueToBlank();
          }

          result = result.length === 0 ? [" "] : result;
          setDropdownWithSearch(result);
        }
      });
    } else {
      setDropdownWithSearch(origDropdownWithSearch);
      if (field.attribute_name !== "ownerReference") setFieldValueToBlank();
    }
  };

  const handleStandardDetails = async (standardIds, prodCategory) => {
    standardIds.forEach((standardId) => {
      CertificateListService.getIpStandardDetails(
        attributeNames.STANDARD,
        standardId
      ).then((result) => {
        if (result?.length > 0) {
          setDropdownWithSearchStandard((prevState) => {
            if (!prevState?.some((state) => state.code === result[0].code)) {
              const newState = prevState ? prevState.concat(result[0]) : result;
              dispatch(updateStandardOptions(newState));
              dispatch(updateProductCategoryStandards(newState));
              return newState;
            }

            return prevState;
          });
        } else {
          setIsDisabled(true);

          if (prodCategory) {
            setIsShowNoStandard(true);
          }
        }
      });

      setIsDisabled(false);
    });

    dispatch(updateStandardValues(standardIds));
  };

  const handleProductCategoryChange = async (prodCategory) => {
    CertificateListService.getIpAttributesDropdownList(
      attributeNames.STANDARD,
      prodCategory
    ).then((result) => {
      if (!result || result?.length === 0) {
        setIsDisabled(true);
        if (prodCategory) {
          setIsShowNoStandard(true);
        }
        dispatch(updateHasCertificateProductStandard(false))
        return;
      }

      if (isEditCert || isModifyCert) {
        dispatch(updateProductCategoryStandards(result));
        dispatch(updateHasCertificateProductStandard(true))

        const mutableModelList = modelList;
        const newModelList = JSON.parse(JSON.stringify(mutableModelList));
        newModelList.forEach((item) => {
          item.productStandards = [];
        });

        if (standardOptions?.length > 0) {
          const newStandardOptions = standardOptions.filter((standard) =>
            standardValue.includes(standard.code)
          );

          dispatch(updateModelList(newModelList));
          dispatch(
            updateStandardOptions(
              uniqBy(
                [...newStandardOptions, ...result],
                (option) => option.code
              )
            )
          );

          return;
        }

        dispatch(updateModelList(newModelList));
        dispatch(updateStandardOptions(result));
      }

      setIsDisabled(false);
    });
  };

  const handleGetStandardList = async (prodCategory, fieldname) => {
    CertificateListService.getIpAttributesDropdownList(
      fieldname,
      prodCategory
    ).then((result) => {
      if (!result || result?.length === 0) {
        setIsDisabled(true);
        if (prodCategory) {
          setIsShowNoStandard(true);
        }
        return;
      }

      if (isEditCert || isModifyCert) {
        setDropdownWithSearchStandard((prevState) => {
          let newState = [];

          if (prevState?.length > 0) {
            newState = uniqBy(
              [...prevState, ...result],
              (option) => option.code
            );
          } else {
            newState = result;
          }

          dispatch(updateStandardOptions(newState));
          dispatch(updateProductCategoryStandards(newState));
          return newState;
        });

        dispatch(updateStandardValues(formValues[fieldname]));
      } else {
        dispatch(updateStandardOptions(result));
      }

      setIsDisabled(false);
    });
  };

  const setFieldValueToBlank = () => {
    setFormValues((prevState) => {
      const newState = getNewFormValueByInput(prevState, "");
      checkFieldValidity(newState);

      dispatch(updateCertDetailsFormValues(newState));
      return newState;
    });
  };

  const getRequiredValidityMessage = (
    newFormValuesState,
    newFormValidityMessagesState
  ) => {
    let validityMessage = "";
    if (isRequired) {
      switch (fieldType) {
        case "list":
        case "table":
          const noDataItem = newFormValuesState[field.attribute_name]
            ? newFormValuesState[field.attribute_name].filter(
                (item) =>
                  !item ||
                  (typeof item === "string" && item.trim().length === 0)
              )
            : null;
          if (noDataItem && noDataItem.length > 0) {
            validityMessage = field.display_name + " is required.";
          }
          break;
        case "selectmultiple":
          if (
            newFormValuesState[field.attribute_name] &&
            newFormValuesState[field.attribute_name].length === 0
          ) {
            validityMessage = field.attribute_name + " is required.";
          }
          break;
        default:
          if (
            !newFormValuesState[field.attribute_name] ||
            (typeof newFormValuesState[field.attribute_name] === "string" &&
              newFormValuesState[field.attribute_name].trim().length === 0)
          ) {
            validityMessage = field.attribute_name + " is required.";
          }
          break;
      }
    }

    newFormValidityMessagesState[field.attribute_name] = validityMessage;

    if (hasEnteredNotApplicable(newFormValuesState["certificateName"])) {
      newFormValidityMessagesState["certificateName"] =
        enteredNotApplicableErrorMessage;
    }

    return newFormValidityMessagesState;
  };

  const getDateValidityMessage = (
    newFormValuesState,
    newFormValidityMessagesState
  ) => {
    if (field.data_type === "date" && field.attribute_name) {
      if (
        field.attribute_name === "issueDate" ||
        field.attribute_name === "expiryDate"
      ) {
        let expiryDateValue = newFormValuesState["expiryDate"];
        let issueDateValue = newFormValuesState["issueDate"];

        let expiryDate = new Date(expiryDateValue);
        let issueDate = new Date(issueDateValue);

        if (
          expiryDateValue &&
          issueDateValue &&
          issueDate.getTime() > expiryDate.getTime()
        ) {
          newFormValidityMessagesState["issueDate"] = "";
          newFormValidityMessagesState["expiryDate"] =
            "Expiry Date must be greater than or equal to Issue Date";
        }
      }
    }
    return newFormValidityMessagesState;
  };

  const checkFieldValidity = (newFormValuesState) => {
    setFormValidityMessages((prevFormValidityMessagesState) => {
      let newFormValidityMessagesState = JSON.parse(
        JSON.stringify(prevFormValidityMessagesState)
      );
      newFormValidityMessagesState = getRequiredValidityMessage(
        newFormValuesState,
        newFormValidityMessagesState
      );
      newFormValidityMessagesState = getDateValidityMessage(
        newFormValuesState,
        newFormValidityMessagesState
      );

      return newFormValidityMessagesState;
    });
  };

  const onChangeDate = (date) => {
    setSelectedDate(date);

    setFormValues((prevFieldState) => {
      let newState = getNewFormValueByInput(prevFieldState, date);
      checkFieldValidity(newState);

      dispatch(updateCertDetailsFormValues(newState));
      return newState;
    });
  };

  const onChangeDynamicInput = (e) => {
    if (
      field.attribute_name === "certificationProductType" &&
      !isModifyCert &&
      !isEditCert
    ) {
      dispatch(updateStandardOptions(null));
      dispatch(updateStandardValues(null));
    }

    if (
      field.attribute_name === "certificationProductType" &&
      (isModifyCert || isEditCert)
    ) {
      handleProductCategoryChange(e.target.value);
    }

    if (field.attribute_name === "standard" && (!isModifyCert || !isEditCert)) {
      dispatch(updateStandardValues(e.target.value));
    }

    if (field.attribute_name === "certificateName") {
      dispatch(updateIsCertNumberOnMaxlength(e.target.value.length >= 50));
    }

    if (field.attribute_name === "ownerReference") {
      const { modelList } = productDetails;

      if (modelList?.length > 0) {
        dispatch(updateHasExistingModels(true));
        dispatch(updateProductOwner(e.target.value));
        return;
      }

      dispatch(updateHasExistingModels(false));
      dispatch(updateProdDetailsModelList([]));
    }

    setFormValues((prevState) => {
      let fieldValue = "";

      if (
        field.list_values &&
        Array.isArray(field.list_values) &&
        field.list_values.length > 0
      ) {
        fieldValue = e.value;
      } else {
        fieldValue = e.target.value;
      }

      const newState = getNewFormValueByInput(prevState, fieldValue);
      checkFieldValidity(newState);

      dispatch(updateCertDetailsFormValues(newState));
      return newState;
    });
  };

  const getNewFormValueByInput = (prevFormValuesState, input) => {
    var newFormValuesState = JSON.parse(JSON.stringify(prevFormValuesState));
    const newInput = input;
    switch (fieldType) {
      case "list":
        if (newFormValuesState[field.attribute_name].length > 0) {
          newFormValuesState[field.attribute_name][fieldIndex] = newInput;
        } else {
          newFormValuesState[field.attribute_name] = [newInput];
        }
        break;
      case "table":
        if (newFormValuesState[field.attribute_name].length > 0) {
          newFormValuesState[field.attribute_name][rowIndex] = newInput;
        } else {
          newFormValuesState[field.attribute_name] = [newInput];
        }
        break;
      default:
        newFormValuesState[field.attribute_name] = newInput;
        break;
    }

    return newFormValuesState;
  };

  const isDisableTextField = (fieldName) => {
    if (
      fieldName === "certificateName" &&
      isEditCert &&
      (hasRevisions || isModifyCert)
    )
      return true;
    return false;
  };

  const debouncedFilterDropdownWithSearch = useCallback(
    debounce(onFilterDropdownWithSearch, 1000),
    []
  );

  const isDisableSingleSelect = (fieldName) => {
    if (fieldName === "certificateType" && isEditCert) return true;
    return false;
  };

  const isDisableSingleSelectWithSearch = (fieldName) => {
    if (fieldName === "ownerReference" && isEditCert) return true;
    return false;
  };

  const isDisableDatePicker = (fieldName) => {
    if (
      fieldName === "issueDate" &&
      isEditCert &&
      (hasRevisions || isModifyCert)
    )
      return true;
    return false;
  };

  const hasEnteredNotApplicable = (text) => {
    const notApplicableValues = ["N/A", "NA", "NOTAPPLICABLE"];

    // Remove all spaces from input
    text = text?.replace(/ /g, "");

    return notApplicableValues.includes(text?.toUpperCase());
  };

  const enteredNotApplicableErrorMessage =
    "N/A or Not Applicable are not valid values.";

  const isCertificateNameField = (field) => {
    return field.attribute_name === "certificateName";
  };

  const isFormValuesObjectInValid = (formValuesObject) => {
    return formValuesObject && Object.keys(formValuesObject).length == 0;
  };

  const constructTooltipForSelectedValues = (
    options,
    dynamicFieldValue,
    optionLabel,
    optionValue
  ) => {
    return (
      options
        ?.filter((option) => dynamicFieldValue?.includes(option[optionValue]))
        ?.map((option) => option[optionLabel])
        ?.join(" \n") || ""
    );
  };

  return (
    <>
      {field.list_values &&
        Array.isArray(field.list_values) &&
        field.list_values.length > 0 && (
          <>
            {(fieldType === "list" || fieldType === "table") && (
              <Dropdown
                value={dynamicFieldValue}
                options={field.list_values}
                onChange={onChangeDynamicInput}
                tooltip={field.help_text}
                tooltipOptions={{ position: "bottom" }}
              />
            )}
            {fieldType === "single" && (
              <Dropdown
                value={dynamicFieldValue}
                options={field.list_values}
                onChange={onChangeDynamicInput}
                tooltip={field.help_text}
                tooltipOptions={{ position: "bottom" }}
                disabled={isDisableSingleSelect(field.attribute_name)}
              />
            )}
            {fieldType === "selectmultiple" && (
              <MultiSelect
                className="dynamic-field-multiselect"
                disabled={isDisabled}
                options={field.list_values}
                value={dynamicFieldValue}
                onChange={onChangeDynamicInput}
                tooltip={field.help_text}
                tooltipOptions={{ position: "bottom" }}
              />
            )}
          </>
        )}

      {field.list_values.length === 0 && (
        <>
          {field.data_type.toLowerCase() === "date" && (
            <div className="dynamic-field-date-div">
              <Tooltip
                target={".dynamic-field-date-picker-" + field.id}
                content={field.help_text}
                position="bottom"
              />

              <CustomDatePicker
                className={"dynamic-field-date-picker-" + field.id}
                selected={selectedDate}
                onChange={(date) => onChangeDate(date)}
                disabled={isDisableDatePicker(field.attribute_name)}
              />
            </div>
          )}
          {field.data_type.toLowerCase() === "text" && (
            <>
              {fieldType === "list" && field.is_allow_search && (
                <Dropdown
                  emptyFilterMessage={
                    isIpAttributesDropdownListLoading
                      ? "Loading..."
                      : "No results found"
                  }
                  panelClassName="dynamic-field-dropdown-panel"
                  value={dynamicFieldValue}
                  options={dropdownWithSearch ? dropdownWithSearch : []}
                  optionLabel="name"
                  optionValue="code"
                  filter
                  panelStyle={{ width: "200px" }}
                  onFilter={(e) => {
                    setisIpAttributesDropdownListLoading(true);
                    debouncedFilterDropdownWithSearch(e);
                  }}
                  onChange={onChangeDynamicInput}
                  tooltip={field.help_text}
                  tooltipOptions={{ position: "bottom" }}
                />
              )}
              {(fieldType === "list" || fieldType === "table") &&
                !field.is_allow_search && (
                  <ControlledInput
                    value={dynamicFieldValue}
                    onChange={onChangeDynamicInput}
                    tooltip={field.help_text}
                    tooltipOptions={{ position: "bottom" }}
                    InputComponent={InputText}
                  />
                )}
              {(fieldType === "single" || fieldType === "selectmultiple") && (
                <>
                  {field.is_allow_search &&
                    field.is_select_multiple &&
                    !field.is_search &&
                    (() => {
                      return (
                        <>
                          <MultiSelect
                            className="dynamic-field-multiselect"
                            value={
                              Array.isArray(dynamicFieldValue)
                                ? dynamicFieldValue
                                : [dynamicFieldValue]
                            }
                            options={dropdownWithSearch}
                            optionLabel="name"
                            optionValue="code"
                            filter
                            onFilter={onFilterDropdownWithSearch}
                            onChange={onChangeDynamicInput}
                            tooltip={field.help_text}
                            tooltipOptions={{ position: "bottom" }}
                            disabled={isDisableSingleSelectWithSearch(
                              field.attribute_name
                            )}
                          />
                        </>
                      );
                    })()}

                  {field.is_select_multiple && !field.is_allow_search && (
                    <>
                      <span
                        title={
                          showMultiSelectValuesTooltip
                            ? constructTooltipForSelectedValues(
                                standardOptions,
                                standardValue,
                                "name",
                                "code"
                              )
                            : ""
                        }
                      >
                        <MultiSelect
                          className="dynamic-field-multiselect"
                          filter
                          disabled={isDisabled || isFieldDisabled}
                          value={standardValue}
                          options={standardOptions}
                          optionLabel="name"
                          optionValue="code"
                          onChange={onChangeDynamicInput}
                          tooltip={field.help_text}
                          tooltipOptions={{
                            position: "bottom",
                            showOnDisabled: true,
                          }}
                        />
                      </span>
                    </>
                  )}
                  {!field.is_search &&
                    !field.is_allow_search &&
                    !field.is_select_multiple && (
                      <>
                        <ControlledInput
                          value={dynamicFieldValue}
                          onChange={onChangeDynamicInput}
                          // For certificate number/certificateName field,
                          // show tooltip error message if the input entered is 'Not Applicable',
                          // otherwise show the help text message.
                          tooltip={
                            isCertificateNameField(field) &&
                            hasEnteredNotApplicable(dynamicFieldValue)
                              ? enteredNotApplicableErrorMessage
                              : field.help_text
                          }
                          tooltipOptions={{ position: "bottom" }}
                          disabled={isDisableTextField(field.attribute_name)}
                          InputComponent={InputText}
                          maxLength={isCertificateNameField(field) ? 50 : 1000}
                        />
                      </>
                    )}
                  {field.is_search && !field.is_allow_search && (
                    <>
                      <Dropdown
                        value={dynamicFieldValue}
                        options={dropdownWithSearch}
                        optionLabel="name"
                        optionValue="code"
                        onChange={onChangeDynamicInput}
                        tooltip={field.help_text}
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </>
                  )}
                  {field.is_allow_search &&
                    !field.is_search &&
                    !field.is_select_multiple && (
                      <>
                        <Dropdown
                          emptyFilterMessage={
                            isIpAttributesDropdownListLoading
                              ? "Loading..."
                              : "No results found"
                          }
                          ref={dropdownRef}
                          panelClassName="dynamic-field-dropdown-panel"
                          value={dynamicFieldValue}
                          options={dropdownWithSearch}
                          optionLabel="name"
                          optionValue="code"
                          filter
                          panelStyle={{ width: "200px" }}
                          onFilter={(e) => {
                            setisIpAttributesDropdownListLoading(true);
                            debouncedFilterDropdownWithSearch(e);
                          }}
                          onChange={onChangeDynamicInput}
                          tooltip={field.help_text}
                          tooltipOptions={{ position: "bottom" }}
                          disabled={isDisableSingleSelectWithSearch(
                            field.attribute_name
                          )}
                        />
                      </>
                    )}
                </>
              )}
            </>
          )}
          {field.data_type.toLowerCase() === "textarea" && (
            <>
              <ControlledInput
                InputComponent={InputTextarea}
                value={
                  fieldType === "list" || fieldType === "table"
                    ? formValues[field.attribute_name][fieldIndex]
                    : formValues[field.attribute_name]
                }
                onChange={onChangeDynamicInput}
                rows={1}
                cols={30}
                tooltip={field.help_text}
                tooltipOptions={{ position: "bottom" }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default DynamicFieldMultiple;
