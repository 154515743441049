import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./ProjectManagementRefiners.module.scss";
import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import SelectRefiner from "../../../shared/refiners/select-refiner/SelectRefiner";

const ProjectManagementRefiners = () => {
  return (
    <>
      <div className={`ul-card ${classes["custom-filter-section"]}`}>
        <div
          className={`ul-card__item -bordered ul-card__head ${classes["filter-menu-label"]} ${classes["filter-menu-text-container"]} `}
        >
          <span
            className={`${classes["filter-menu-text"]} font-weight-semi-bold`}
          >
            Filter Menu
          </span>
        </div>
        <div
          className={`ul-card__item ul-card__body ${classes["custom-filters-input"]}`}
        >
          <div className="font-weight-bold">Custom Filters</div>
          <div className={`${classes["search-filter-div"]}`}>
            <div className="ul-form__controlGroup">
              <div
                className={`ul-form__control ${classes["input-container-with-close"]}`}
              >
                <input
                  type="text"
                  placeholder="Search"
                  className={`ul-form__controlGroupItem ul-textbox ${classes["search-input-with-close"]}`}
                />
                <FontAwesomeIcon
                  icon={faXmark}
                  className={`ul-form__controlIcon ${classes["search-close-icon"]}`}
                />
              </div>
              <button
                className={`ul-button -app-tertiary -medium -icon ${classes["search-icon-btn"]}`}
                aria-label="add"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </div>
        </div>
        <hr className={`${classes["filter-hr"]}`} />
        <div>
          <div
            className={`ul-card__body font-weight-bold ${classes["your-filters-text"]}`}
          >
            Your Filters
          </div>
          <div>{/* TODO: Set selected refiners here */}</div>
          <div
            className={`${classes["c-clear-container"]}  ul-card__body d-flex justify-content-center align-content-center`}
          >
            <button
              className={`ul-button -app-tertiary -medium ${classes["filter-cancel-button"]}`}
              aria-label="add"
            >
              CLEAR
            </button>
          </div>
        </div>
      </div>
      <div className={classes["cert-refiners-section"]}>
        <div className="ul-card -app">
          <div
            className={`ul-card__item -bordered ul-card__head font-size-12 ${classes["select-a-filter-text"]}`}
          >
            Select a filter below to refine your search
          </div>
        </div>
        <div className={classes["cert-refiners-accordion"]}>
          <SelectRefiner
            refinerLabel={`Applicant (Sold To)`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
          <SelectRefiner
            refinerLabel={`Delivery Path / Agent`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
          <SelectRefiner
            refinerLabel={`Handler Name`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
          <SelectRefiner
            refinerLabel={`FLEX Handler Region`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
          <SelectRefiner
            refinerLabel={`FLEX Project Status`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
          <SelectRefiner
            refinerLabel={`Milestone`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
          <SelectRefiner
            refinerLabel={`Milestone Planned Start Date`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
          <SelectRefiner
            refinerLabel={`Milestone Status`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
          <SelectRefiner
            refinerLabel={`Model Name`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
          <SelectRefiner
            refinerLabel={`Order Booked Date`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
          <SelectRefiner
            refinerLabel={`Order Line ECD`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
          <SelectRefiner
            refinerLabel={`Order Line Status`}
            addSelectedToYourFilters={(item: any) => item}
            isFilterOn={true}
            isMultiSelect={false}
            onFilter={(item: any) => item}
            isRefinerLoading={false}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectManagementRefiners;
