import {
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { confirmDialog } from "primereact/confirmdialog";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  customerInformationInitialState,
  selectApplicantInformation,
  selectFactorySiteInformation,
  selectGPICustomerInputValidationResult,
  selectGPIFormConfig,
  selectIsReadOnly,
  selectManufacturerInformation,
  selectULFileNumber,
  updateFactorySiteInformation,
  updateGPICustomerInputValidationResult,
  updateIsSaveDisabled,
  updateMultipleFactorySiteInfoByCopySource,
} from "../../../../features/generalProductInformation/generalProductInformationSlice";
import useToastr from "../../../../hooks/useToastr";
import CustomerInformation from "../shared/CustomerInformation/CustomerInformation";
import FACTORY_SITE_INFO_SOURCE from "./FACTORY_SITE_INFO_SOURCE";
import classes from "./FactorySiteInformation.module.scss";

const FactorySiteInformation = ({ disableIntegration }) => {
  const gPICustomerInputValidationResultValues = useSelector(
    selectGPICustomerInputValidationResult
  );

  const gPIFormConfig = useSelector(selectGPIFormConfig);

  const [numberOfItemsErrorMessage, setNumberOfItemsErrorMessage] =
    useState("");

  const dispatch = useDispatch();
  const factorySiteInformationValues = useSelector(
    selectFactorySiteInformation
  );
  const applicantInformationValues = useSelector(selectApplicantInformation);
  const manufacturerInformationValues = useSelector(
    selectManufacturerInformation
  );
  const ulFileNumberValues = useSelector(selectULFileNumber);
  const isReadOnly = useSelector(selectIsReadOnly);

  const [showAllFactorySitesInformation, setShowAllFactorySitesInformation] =
    useState(true);

  const [shownFactorySiteInfoIndices, setShownFactorySiteInfoIndices] =
    useState([0]);

  const [isAllSelected, setIsAllSelected] = useState(true);
  const [isRegisterAllFactoryShow, setIsRegisterAllFactoryShow] =
    useState(false);

  const { showSuccess } = useToastr();

  const [displayFactoryHeader, setDisplayFactoryHeader] = useState([]);

  const factorySiteInfoSourceRadioButtons = [
    {
      value: FACTORY_SITE_INFO_SOURCE.SAME_AS_APPLICANT,
      label: "same as Applicant",
    },
    {
      value: FACTORY_SITE_INFO_SOURCE.SAME_AS_MANUFACTURER,
      label: "same as Manufacturer",
    },
    {
      value: FACTORY_SITE_INFO_SOURCE.MANUAL_INPUT,
      label: "manual entry",
    },
  ];

  const factorySiteInitialState = {
    ...customerInformationInitialState,
    source: FACTORY_SITE_INFO_SOURCE.MANUAL_INPUT,
    isFromCertDetails: false,
  };

  useEffect(() => {
    updateMultipleFactorySiteInfo(
      FACTORY_SITE_INFO_SOURCE.SAME_AS_APPLICANT,
      getCustomerInformationCopy(applicantInformationValues)
    );
  }, [applicantInformationValues]);

  useEffect(() => {
    updateMultipleFactorySiteInfo(
      FACTORY_SITE_INFO_SOURCE.SAME_AS_MANUFACTURER,
      getCustomerInformationCopy(manufacturerInformationValues)
    );
  }, [manufacturerInformationValues]);

  useEffect(() => {
    setIsRegisterAllFactoryShow(
      factorySiteInformationValues.factorySites.filter(
        (x) => x.isFromCertDetails
      ).length > 1
    );
  }, [factorySiteInformationValues]);

  const updateMultipleFactorySiteInfo = (source, newFactorySite) => {
    dispatch(
      updateMultipleFactorySiteInfoByCopySource({
        source,
        newFactorySite,
      })
    );
  };

  const updateFactorySiteInfoItem = (index, newValue) => {
    dispatch(
      updateFactorySiteInformation({
        ...factorySiteInformationValues,
        factorySites: factorySiteInformationValues.factorySites.map(
          (factorySiteInfo, i) => {
            return i === index
              ? { ...factorySiteInfo, ...newValue }
              : factorySiteInfo;
          }
        ),
      })
    );
  };

  const getCustomerInformationCopy = (source) => {
    const companyInformation = { ...source };

    // Don't copy several properties enlisted.
    const {
      id,
      contactId,
      gPIApplicantInfoId,
      applicantInfo,
      isSent,
      companyId,
      ...rest
    } = companyInformation;

    return rest;
  };

  const handleCheckedFactorySiteInfoSourceRadioButton = (
    factorySiteInfoIndex,
    source
  ) => {
    // Set factory site information value according to the source set.
    let newCustomerInformation = {};
    const currentCompanyId =
      factorySiteInformationValues.factorySites[factorySiteInfoIndex].companyId;

    if (source === FACTORY_SITE_INFO_SOURCE.SAME_AS_APPLICANT)
      newCustomerInformation = applicantInformationValues;
    else if (source === FACTORY_SITE_INFO_SOURCE.SAME_AS_MANUFACTURER)
      newCustomerInformation = manufacturerInformationValues;
    else if (source === FACTORY_SITE_INFO_SOURCE.MANUAL_INPUT)
      newCustomerInformation = customerInformationInitialState;

    updateFactorySiteInfoItem(factorySiteInfoIndex, {
      ...getCustomerInformationCopy(newCustomerInformation),
      source,
      currentCompanyId,
    });
    dispatch(updateIsSaveDisabled(false));
  };

  const handleChangeCustomerInformation = (
    factorySiteInfoIndex,
    customerInfo
  ) => {
    updateFactorySiteInfoItem(factorySiteInfoIndex, customerInfo);

    const concat = customerInfo.companyName + "*" + customerInfo.contactPerson;
    var headerText = factorySiteInfoIndex + "+" + concat;

    displayFactoryHeader.push(headerText);
    setDisplayFactoryHeader(displayFactoryHeader);

    dispatch(
      updateGPICustomerInputValidationResult(
        gPICustomerInputValidationResultValues?.errors?.filter(
          (e) =>
            e.entity !== "Factories" && e.entityIndex !== factorySiteInfoIndex
        )
      )
    );
  };

  const isFactorySiteDisabled = (value) => {
    return (
      value.source === FACTORY_SITE_INFO_SOURCE.SAME_AS_APPLICANT ||
      value.source === FACTORY_SITE_INFO_SOURCE.SAME_AS_MANUFACTURER
    );
  };

  const updateFactorySites = (newFactorySites, count) => {
    dispatch(
      updateFactorySiteInformation({
        ...factorySiteInformationValues,
        factorySites: newFactorySites,
        factoriesCount: count,
      })
    );
    dispatch(updateIsSaveDisabled(false));
  };

  const addNewFactorySitesByCount = (count) => {
    const newFactorySites = [...factorySiteInformationValues.factorySites];

    const newFactorySitesCount =
      count - factorySiteInformationValues.factorySites.length;

    for (let x = 0; x < newFactorySitesCount; x++)
      newFactorySites.push(factorySiteInitialState);

    updateFactorySites(newFactorySites, count);
  };

  const deleteLastFactorySitesByCount = (e, count) => {
    const newFactorySites = [...factorySiteInformationValues.factorySites];
    const deleteCount =
      factorySiteInformationValues.factorySites.length - count;

    const handleDeleteConfirm = () => {
      const newCount = newFactorySites.length - deleteCount;
      newFactorySites.splice(newCount, deleteCount);

      updateFactorySites(newFactorySites, newCount);

      showSuccess("Success", `Factory(ies) removed successfully.`);
    };

    confirmDialog({
      message: `Are you sure you want to delete the ${deleteCount} most recent factory(ies)?`,
      header: "Delete Factory",
      className: classes["delete-confirmation-dialog"],
      accept: handleDeleteConfirm,
      reject: () => {
        dispatch(
          updateFactorySiteInformation({
            ...factorySiteInformationValues,
            factoriesCount: newFactorySites.length,
          })
        );
      },
      onHide: (result) => {
        if (!result)
          dispatch(
            updateFactorySiteInformation({
              ...factorySiteInformationValues,
              factoriesCount: newFactorySites.length,
            })
          );
      },
    });
  };

  const handleChangeFactoriesCount = (e) => {
    dispatch(
      updateFactorySiteInformation({
        ...factorySiteInformationValues,
        factoriesCount: e.value,
      })
    );
  };

  const handleBlurFactoriesCount = (e) => {
    const count = parseInt(e.target.value);

    if (isNaN(count) || count < 1) {
      if (!e.value) return;
    }

    if (count > gPIFormConfig?.maxNumberOfFactories) {
      setNumberOfItemsErrorMessage(
        "Number entered exceeded maximum allowable value. Please enter value 1 up to " +
        gPIFormConfig?.maxNumberOfFactories
      );
      return;
    }

    setNumberOfItemsErrorMessage("");

    if (count > factorySiteInformationValues.factorySites.length)
      addNewFactorySitesByCount(count);

    if (count < factorySiteInformationValues.factorySites.length)
      deleteLastFactorySitesByCount(e, count);
  };

  const handleDeleteFactorySite = (index) => {
    if (factorySiteInformationValues.factorySites.length === 1) {
      const clearFactorySiteObject = [
        {
          companyId: null,
          partySiteNumber: "",
          hasSelectedPsn: false,
          hasSelectedCompanyName: false,
          companyName: "",
          streetAddress: "",
          city: "",
          country: "",
          zipCode: "",
          stateOrProvince: "",
          contactPerson: "",
          phone: "",
          title: "",
          email: "",
          website: "",
          directorName: "",
          fax: "",
          isSelected: true,
          source: "MANUAL_INPUT",
          isFromCertDetails: true,
        },
      ];

      confirmDialog({
        message: `Are you sure you want to delete this factory?`,
        header: "Delete Factory",
        className: classes["delete-confirmation-dialog"],
        accept: () => {
          dispatch(
            updateFactorySiteInformation({
              ...factorySiteInformationValues,
              factorySites: clearFactorySiteObject,
              factoriesCount: 1,
            })
          );
          dispatch(updateIsSaveDisabled(false));
          showSuccess("Success", `Factory removed successfully.`);
        },
      });
      return;
    }

    const handleDeleteConfirm = () => {
      const newFactorySites = [...factorySiteInformationValues.factorySites];
      newFactorySites.splice(index, 1);

      dispatch(
        updateFactorySiteInformation({
          ...factorySiteInformationValues,
          factorySites: newFactorySites,
          factoriesCount: newFactorySites.length,
        })
      );
      dispatch(updateIsSaveDisabled(false));
      showSuccess("Success", `Factory(ies) removed successfully.`);
    };

    confirmDialog({
      message: `Are you sure you want to delete this factory?`,
      header: "Delete Factory",
      className: classes["delete-confirmation-dialog"],
      accept: handleDeleteConfirm,
    });
  };

  const handleFactorySiteSelected = (index, value, isSelected) => {
    let customerDetail = { ...value, isSelected: isSelected };
    handleChangeCustomerInformation(index, customerDetail);

    const customerDetails = factorySiteInformationValues.factorySites.map(
      (x, i) => {
        if (i == index) {
          return { ...x, isSelected: isSelected };
        }
        return x;
      }
    );

    const hasUnchecked = customerDetails.filter((f) => !f.isSelected);

    if (hasUnchecked.length > 0) {
      setIsAllSelected(false);
    } else {
      setIsAllSelected(true);
    }
    dispatch(updateIsSaveDisabled(false));
  };

  const handleFactorySelectAll = (isSelectAll) => {
    const customerDetails = factorySiteInformationValues.factorySites.map(
      (x) => {
        return { ...x, isSelected: isSelectAll };
      }
    );

    dispatch(
      updateFactorySiteInformation({
        ...factorySiteInformationValues,
        factorySites: customerDetails,
      })
    );

    setIsAllSelected(isSelectAll);
    dispatch(updateIsSaveDisabled(false));
  };

  const handleClickFactorySiteInfoItemCollapseIcon = (factorySiteInfoIndex) => {
    const newShownFactorySiteInfoIndices = [...shownFactorySiteInfoIndices];

    const index = newShownFactorySiteInfoIndices.indexOf(factorySiteInfoIndex);
    if (index === -1) {
      newShownFactorySiteInfoIndices.push(factorySiteInfoIndex);
    } else {
      newShownFactorySiteInfoIndices.splice(index, 1);
    }

    setShownFactorySiteInfoIndices(newShownFactorySiteInfoIndices);
  };

  const handleClickFactorySitesCollapseIcon = () => {
    const expandAll = showAllFactorySitesInformation == false;

    if (expandAll) {
      const factoriesCount = factorySiteInformationValues.factorySites.length;
      const factorySitesIndices = [...Array(factoriesCount).keys()];

      setShownFactorySiteInfoIndices(factorySitesIndices);
    } else {
      setShownFactorySiteInfoIndices([]);
    }

    setShowAllFactorySitesInformation(!showAllFactorySitesInformation);
  };

  const formatHeaderText = (
    factoryIndex,
    factorySiteInfo,
    headerInfo,
    tooltip
  ) => {
    var formattedHeader = "";
    var companyName = "";
    var contactPerson = "";

    if (factorySiteInfo.source !== "") {
      companyName = factorySiteInfo.companyName;
      contactPerson = factorySiteInfo.contactPerson;
    } else {
      var headerResult = [];
      for (var i = 0; i < headerInfo.length; i++) {
        var res = headerInfo[i].split("+");
        if (parseInt(res[0]) === factoryIndex) {
          headerResult.push(res[1]);
        }
      }
      if (headerResult.length > 0) {
        var manualInput = headerResult[headerResult.length - 1];
        var splitTxt = manualInput.split("*");
        companyName = splitTxt[0];
        contactPerson = splitTxt[1];
      }
    }

    if (tooltip) {
      formattedHeader =
        "FACTORY " +
        (factoryIndex + 1) +
        " ( " +
        companyName +
        " - " +
        contactPerson +
        " )";
    } else {
      if (companyName && contactPerson) {
        formattedHeader = "( " + companyName + " - " + contactPerson + " )";
      } else {
        if (companyName) formattedHeader = "( " + companyName + " )";
        if (contactPerson) formattedHeader = "( " + contactPerson + " )";
      }
      if (formattedHeader.length > 43) {
        var truncate = formattedHeader.substring(0, 43);
        formattedHeader = truncate + "...";
      }
    }

    return formattedHeader;
  };

  const handleAddNewFactoryDetail = () => {
    const count = factorySiteInformationValues.factoriesCount + 1;
    addNewFactorySitesByCount(count);
    dispatch(updateIsSaveDisabled(false));
  };

  const getCustomErrors = (factoryIndex) => {
    const errors = gPICustomerInputValidationResultValues?.errors?.filter(
      (e) => e.entity === "Factories" && e.entityIndex === factoryIndex
    );

    return errors;
  };

  const isSourceAlreadySelected = (source) =>
    factorySiteInformationValues.factorySites.some((f) => f.source === source);

  const handleFactoryFieldsFocus = (index) => {
    if (!shownFactorySiteInfoIndices.includes(index))
      setShownFactorySiteInfoIndices((prev) => [...prev, index]);
  };

  const isDeleteEnabled = !isReadOnly;

  return (
    <div className={classes["container"]}>
      <div className="form-section">
        <div className="form-section-header">
          <h3 className="form-section-title">
            Factory / Production Site Information
            <small>
              ( Production Site = company where products are produced and
              surveillance may take place. In US Safety Scheme = Manufacturer.
              In CB Scheme = Factory )
            </small>
          </h3>
        </div>

        <div className="form-section-content">
          {(!isRegisterAllFactoryShow && (
            <>
              <div className={classes["number-of-factories"]}>
                <h4 className={classes["label"]}>
                  If there is more than one Factory/Production Site, enter # of
                  factories that will produce the product
                </h4>
                <InputNumber
                  useGrouping={false}
                  min={1}
                  onChange={handleChangeFactoriesCount}
                  onBlur={handleBlurFactoriesCount}
                  value={factorySiteInformationValues.factoriesCount}
                  inputClassName={`${classes["input"]} ul-textbox ${numberOfItemsErrorMessage ? "p-invalid" : ""
                    }`}
                  disabled={isReadOnly}
                />
              </div>

              {numberOfItemsErrorMessage && (
                <span style={{ color: "red" }}>
                  {numberOfItemsErrorMessage}
                </span>
              )}
            </>
          )) ||
            (isRegisterAllFactoryShow && (
              <div className="question-w-checkbox-container">
                <Checkbox
                  inputId="factoryIsRegisterAll"
                  checked={isAllSelected}
                  onChange={({ checked }) => handleFactorySelectAll(checked)}
                  disabled={isReadOnly}
                />
                <label htmlFor="factoryIsRegisterAll">
                  Register all factories associated with the
                  {ulFileNumberValues.hasExistingULFileNumber &&
                    " UL E File Number "}
                  {ulFileNumberValues.isULCBCertificateNumber &&
                    " UL CB Certificate Number "}
                  {ulFileNumberValues.isOtherGMACertificate &&
                    " GMA Certificate Number "}
                  in the GMA Approvals
                </label>
              </div>
            ))}

          <div className={classes["btn-collapse-all-container"]}>
            <Button
              className={`ul-button -app-secondary ${classes["btn-collapse-all"]}`}
              onClick={handleClickFactorySitesCollapseIcon}
              tooltip={
                showAllFactorySitesInformation ? "Collapse all" : "Expand all"
              }
              disabled={isReadOnly}
            >
              <FontAwesomeIcon
                icon={showAllFactorySitesInformation ? faAngleUp : faAngleDown}
                size="1x"
              />
            </Button>
          </div>

          <div className={classes["factory-sites-information-container"]}>
            {factorySiteInformationValues.factorySites.map(
              (factorySiteInfo, factorySiteInfoIndex) => (
                <div
                  key={factorySiteInfoIndex}
                  className={classes["factory-site-information"]}
                >
                  <div
                    className={classes["header"]}
                    data-tooltip={formatHeaderText(
                      factorySiteInfoIndex,
                      factorySiteInfo,
                      displayFactoryHeader,
                      true
                    )}
                    data-tooltip-position="bottom"
                  >
                    <div
                      className={
                        ulFileNumberValues.hasExistingULFileNumber &&
                          factorySiteInformationValues.factoriesCount > 1
                          ? classes["left-15"]
                          : classes["left"]
                      }
                    >
                      <Checkbox
                        className={classes["info-checkbox"]}
                        checked={factorySiteInfo.isSelected}
                        onChange={({ checked }) =>
                          handleFactorySiteSelected(
                            factorySiteInfoIndex,
                            factorySiteInfo,
                            checked
                          )
                        }
                        disabled={isReadOnly}
                      />
                      <FontAwesomeIcon
                        className={classes["collapse-icon"]}
                        icon={
                          shownFactorySiteInfoIndices.includes(
                            factorySiteInfoIndex
                          )
                            ? faAngleDown
                            : faAngleRight
                        }
                        size="1x"
                        onClick={() =>
                          handleClickFactorySiteInfoItemCollapseIcon(
                            factorySiteInfoIndex
                          )
                        }
                      />
                      <h4 className={classes["factory-site-title"]}>
                        FACTORY {factorySiteInfoIndex + 1}{" "}
                        {formatHeaderText(
                          factorySiteInfoIndex,
                          factorySiteInfo,
                          displayFactoryHeader,
                          false
                        )}
                      </h4>
                    </div>

                    <div className={classes["right"]}>
                      <div
                        className={
                          classes["factory-site-info-src-options-container"]
                        }
                      >
                        {factorySiteInfoSourceRadioButtons.map((c) => {
                          const inputId =
                            "factory-site-info-src-" +
                            factorySiteInfoIndex +
                            "-" +
                            c.value;

                          return (
                            <div
                              key={c.value}
                              className="radio-button-container"
                              onChange={(e) =>
                                handleCheckedFactorySiteInfoSourceRadioButton(
                                  factorySiteInfoIndex,
                                  c.value
                                )
                              }
                              disabled={isReadOnly}
                            >
                              {/* Normal radio button is used as prime radio button is causing unexpected effect on page's size upon checking. */}
                              <input
                                id={inputId}
                                checked={c.value === factorySiteInfo.source}
                                disabled={
                                  isReadOnly
                                    ? isReadOnly
                                    : c.value !==
                                    FACTORY_SITE_INFO_SOURCE.MANUAL_INPUT &&
                                    isSourceAlreadySelected(c.value)
                                }
                                type="radio"
                                readOnly
                                value={c.value}
                                name={
                                  "factory-site-info-src-" +
                                  factorySiteInfoIndex
                                }
                              />
                              <label htmlFor={inputId}>{c.label}</label>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className={`${classes["delete-factory-site-information"]
                          } ${!isDeleteEnabled ? classes["invisible"] : ""}`}
                      >
                        <FontAwesomeIcon
                          onClick={() =>
                            handleDeleteFactorySite(factorySiteInfoIndex)
                          }
                          className={classes["trash-icon"]}
                          icon={faTrashAlt}
                          size="1x"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${classes["content"]} ${shownFactorySiteInfoIndices.includes(factorySiteInfoIndex)
                        ? ""
                        : classes["hidden"]
                      }`}
                  >
                    <CustomerInformation
                      customErrors={getCustomErrors(factorySiteInfoIndex)}
                      disableIntegration={disableIntegration}
                      mainCustomerInfoDisabled={isFactorySiteDisabled(
                        factorySiteInfo
                      )}
                      onChange={(customerInfo) =>
                        handleChangeCustomerInformation(
                          factorySiteInfoIndex,
                          customerInfo
                        )
                      }
                      onFocus={() =>
                        handleFactoryFieldsFocus(factorySiteInfoIndex)
                      }
                      customerInformation={factorySiteInfo}
                      entity="Factory"
                      section="factory"
                      index={factorySiteInfoIndex}
                    />
                  </div>
                </div>
              )
            )}
          </div>

          <div
            className={classes["add-new-factory-site-information-container"]}
          >
            <Button
              disabled={isReadOnly}
              label="Add Factory"
              className={classes["add-new-factory-button"]}
              onClick={handleAddNewFactoryDetail}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactorySiteInformation;
