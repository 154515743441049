import ULProjectHanderDetails from "./ul-project-handler-details/ULProjectHanderDetails";
import FormContext from "../form/context/FormContext";

const ULProjectHandler = ({ showFormError, isReadOnly }) => {
  return (
    <>
      <FormContext.Provider value={{ showFormError, isReadOnly }}>
        <h2 className="step-title">UL Project Handler</h2>
        <ULProjectHanderDetails />
      </FormContext.Provider>
    </>
  );
};

export default ULProjectHandler;