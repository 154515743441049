import { OverlayPanel } from "primereact/overlaypanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import classes from "./ShowHideColumnsMultiplePrimeDataTable.module.scss";
import { ShowHideColumnsMultipleDataTableModel } from "./interface/ShowHideColumnsMultipleDataTableModel";
import ShowHideColumnsMultiplePrimeDataTableItem from "./show-hide-column-multiple-prime-datatable-item/ShowHideColumnsMultiplePrimeDataTableItem";
import { TabPanel, TabView } from "primereact/tabview";
import { GridColumnModel } from "./../../pages/project-management-pmr/data/project-grid-columns";
import _ from "lodash";
import { Column } from "primereact/column";
import { DataTable, DataTableRowReorderParams } from "primereact/datatable";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { updateShowHideColumnObject } from "../../features/projectManagementPmr/projectManagementPmrSlice";
import { PmrShowHideArrayValues } from "../../pages/project-management-pmr/shared/interfaces/pmr-show-hide-array-values";

interface ShowHideColumnsMultiplePrimeDataTableProps<T> {
  items: Array<ShowHideColumnsMultipleDataTableModel<T>>;
  updateShowHideColumnsFrozenReorder: (
    arrayFields: Array<PmrShowHideArrayValues>
  ) => void;
  updateShowHideColumnsVisibility: (arrayFields: Array<PmrShowHideArrayValues>) => void;
}

const ShowHideColumnsMultiplePrimeDataTable = (
  props: ShowHideColumnsMultiplePrimeDataTableProps<any>
) => {
  const dispatch = useDispatch();
  const op = useRef<any>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const groupedItems = _(props.items)
    .groupBy((group) => group.key)
    .map((value, key) => {
      return {
        tabName: value[0].title,
        items: value,
        isSelectAll: value.every(item => item.fields.filter(field=> field.orderNoShowHide! > 0).every(field => field.isShown))
      };
    })
    .value();

  const onFrozenColCountChange = (e: React.ChangeEvent<HTMLSelectElement>, key: any) => {
    const frozenCount = Number(e.target.value);

    props.updateShowHideColumnsFrozenReorder(
      props.items.map((item) => {
        if(item.key === key) {
          return {
            key: item.key,
            prevFieldState: item.prevFieldState,
            fields: item.fields,
            subHeader: item.subHeader,
            frozenCount: frozenCount,
          }
        }
        return {
          key: item.key,
          prevFieldState: item.prevFieldState,
          fields: item.fields,
          subHeader: item.subHeader,
          frozenCount: item.frozenColumnCount
        };
      })
    );
  };

  const onRowReorder = (e: DataTableRowReorderParams, key: unknown, subHeader?: string) => {
    const destinationIndex = e.value.find((col: GridColumnModel) => col.orderNo === e.dropIndex) as GridColumnModel;

    if(destinationIndex?.isColumnNotReordable || destinationIndex?.frozenPermanent ) {
        return;
    }
    const newColumns = [...e.value] as GridColumnModel[];
    let prevNumber = 0;
    const columns = newColumns.map((col, i) => {
      if (col.orderNoShowHide! > 0) prevNumber++;

      const orderShowHideNumber = col.orderNoShowHide! > 0 ? prevNumber : 0;

      return { ...col, orderNo: i + 1, orderNoShowHide: orderShowHideNumber };
    });

    props.updateShowHideColumnsFrozenReorder(
      props.items.map((item) => {

        let updateFields = item.fields;

        if(item.key === key && !subHeader) {
            updateFields = columns;
        }

        if(item.key === key && subHeader === item.subHeader) {
            updateFields = columns;
        } 

        return {
          key: item.key,
          prevFieldState: item.prevFieldState,
          fields: updateFields,
          subHeader: item.subHeader,
          frozenCount: item.frozenColumnCount
        };
      })
    );
  };

  const onShowColumnToggle = (key: unknown, columns: GridColumnModel[], subHeader?: string) => {

    props.updateShowHideColumnsVisibility(
      props.items.map((item) => {
        let updateFields = item.fields;

        if(item.key === key && !subHeader) {
            updateFields = columns;
        }

        if(item.key === key && subHeader === item.subHeader) {
            updateFields = columns;
        } 

        return {
          key: item.key,
          prevFieldState: item.prevFieldState,
          fields: updateFields,
          subHeader: item.subHeader,
          frozenCount: item.frozenColumnCount
        };
      })
    );
  };

  const onShowColumnToggleMultipleTable = (e: any, tabName: string) => {
  
      const { checked } = e.target;
  
      const groupedColumns = groupedItems.map((groupItem) => {
          
          if(tabName === groupItem.tabName) {
              return {
                ...groupItem,
                isSelectAll: checked,
                items: groupItem.items.map((item) => {
                    return {
                      ...item,
                      fields: item.fields.map((f: GridColumnModel) => {
                        if (checked) {
                          return {
                            ...f,
                            isShown: true,
                          };
                        }
                  
                        if (f.isFrozen || !f.canHide || f.orderNoShowHide === 0) {
                          return f;
                        }
                
                        return {
                          ...f,
                          isShown: false,
                        };
                    })
                  }
            })
          }
        }

        return {
          ...groupItem,
          isSelectAll: checked
        };
      });

      dispatch(
        updateShowHideColumnObject(
          groupedColumns.flatMap((groupedCol) => groupedCol.items)
        )
      )
  }

  return (
    <>
      <button
        title="Show/Hide Columns"
        type="button"
        className={`ul-button ${classes["show-hide-button"]}`}
        onClick={(e) => op.current.toggle(e)}
      >
        <FontAwesomeIcon icon={faBookOpen}></FontAwesomeIcon>
      </button>

      <OverlayPanel
        ref={op}
        dismissable
        showCloseIcon
        className={`${classes["show-hide-multiple-overlay-panel"]}`}
      >
          <div className={classes["title-caption"]}>
            <strong>Show/Hide Columns</strong>
          </div>

          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
        
            className={`${classes["tabview-container"]}`}

          >
            {groupedItems.map((groupItem, index) => {
              return (
                <TabPanel
                  className="Test"
                  headerClassName={clsx(
                    {[classes["tabview-custom-active"]] : index === activeIndex}
                  )}
                  header={groupItem.tabName}
                  contentClassName={
                    clsx(
                       {[classes["tabview-item"]]: groupItem.items.length > 1}
                     )
                   }
                >
                  
                        {groupItem.items.length > 1 && (
                          <DataTable
                            className={classes["detached-column-header"]}
                            showGridlines
                            size="small"
                            columnResizeMode="fit"
                          >
                            <Column
                              rowReorder
                              className={classes["drag-body"]}
                              style={{ width: "5%"}}
                            ></Column>
                            <Column
                              key="isShown"
                              className={classes["checkbox-body"]}
                              header={
                                <input
                                  type="checkbox"
                                  className={classes["checkbox"]}
                                  onChange={(e) => {onShowColumnToggleMultipleTable(e, groupItem.tabName)}}
                                  checked={groupItem.isSelectAll}
                                />
                              }
                              style={{width: "1%"}}
                            ></Column>
                            <Column
                              header={<span>Order</span>}
                              headerClassName={classes["other-headers"]}
                              style={{ width: "1%"}}
                            ></Column>
                            <Column
                              header="Column Header"
                              headerClassName={classes["other-headers"]}
                              style={{ width: "27%" }}
                            ></Column>
                          </DataTable>
                        )}

                  {groupItem.items.map((item, index) => {
                    return (
                      <div>
                        <ShowHideColumnsMultiplePrimeDataTableItem
                          key={`${item.key} - ${new Date().toTimeString()}`}
                          itemKey={item.key}
                          fields={item.fields}
                          hasShowAndFreezeSelection={
                            item.hasShowAndFreezeSelection
                          }
                          scrollable={item.subHeader ? false : true}
                          subHeader={
                            item.subHeader ? item.subHeader : undefined
                          }
                          onRowReorder={onRowReorder}
                          onShowColumnToggle={onShowColumnToggle}
                          onFrozenColumnCountChange={onFrozenColCountChange}
                          frozenColumnCount={item.frozenColumnCount}
                          maxFrozenColumnCount={item.maxFrozenColumnCount}
                          displayFrozenCountDropdown={item.displayFrozenColumnDropdown}
                        />
                      </div>
                    );
                  })}
                </TabPanel>
              );
            })}
          </TabView>
      </OverlayPanel>
    </>
  );
};

export default ShowHideColumnsMultiplePrimeDataTable;
