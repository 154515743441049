import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useRef, useState } from "react";
import classes from "./ProjectWorkQueueBaseRefiners.module.scss";
import clsx from "clsx";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import useDebounce from "../../../hooks/useDebounce";
import { isValidNumber } from "../../../utils/helpers/object.helpers";

const MultiSelectRefiner = ({
  refiner,
  disabledRefiners,
  selectedRefiners,
  ...props
}) => {
  const [filterValue, setFilterValue] = useState(null);

  const footerTemplate = () =>
    refiner.displayShowMore && (
      <div className={classes["show-more"]}>
        <button
          disabled={refiner.loading}
          onClick={() => {
            if (refiner.onShowMore) refiner.onShowMore();
          }}
        >
          {refiner.loading ? "Loading ..." : "Show More"}
        </button>
      </div>
    );

  const headerTemplate = (headerTemplate) => {
    const inputProps = {
      placeholder: refiner.filterPlaceholder,
      onChange: (e) => {
        onFilter({
          filter:
            refiner.filterType === "number" ? `${e.value}` : e.target.value,
        });
      },
      value: filterValue ?? "",
    };
    return (
      <div className="p-multiselect-header">
        <div className={classes["multiselect-wrapper"]}>
          <div className={classes["multiselect-items"]}>
            {headerTemplate.checkboxElement}
            <div className={classes["input-container"]}>
              {refiner.filterPlaceholder && (
                <div className={classes["input-hidden-label"]}>
                  {refiner.filterPlaceholder}
                </div>
              )}
              <span
                className={clsx("p-input-icon-right", classes["custom-filter"])}
              >
                <i
                  className={clsx(
                    "pi",
                    refiner.loading ? "pi-spin pi-spinner" : "pi-search"
                  )}
                />
                {refiner.filterType === "number" ? (
                  <InputNumber
                    {...inputProps}
                    useGrouping={false}
                    allowEmpty
                    value={
                      isValidNumber(filterValue) &&
                      (filterValue ?? "").trim() !== ""
                        ? filterValue
                        : null
                    }
                  />
                ) : (
                  <InputText {...inputProps} />
                )}
              </span>
            </div>
            {headerTemplate.closeElement}
          </div>
          {refiner.filterMinLength &&
            !!filterValue &&
            filterValue.length < refiner.filterMinLength && (
              <span className={classes["color-red"]}>
                Invalid. {refiner.filterMinLength} numerical chars. needed.
              </span>
            )}
        </div>
      </div>
    );
  };

  const onFilter = (e) => {
    setFilterValue(e.filter);
    // to reset options when cleared
    if (
      e.filter.length < (refiner.filterMinLength ?? 0) &&
      e.filter.length >= 1
    ) {
      return null;
    }
    if (props.onFilter) props.onFilter(e);
    return false;
  };

  const selectedItemTemplate = () => (
    <div className={classes["refiner-dropdown-placeholder"]}>-- Select --</div>
  );

  const itemTemplate = (item) => {
    return (
      <>
        <div className={clsx(classes["custom-option"])} title={item.label}>
          {item.label}
        </div>
      </>
    );
  };

  const mapRefinerOptions = (option) =>
    refiner.field === disabledRefiners?.field &&
    disabledRefiners?.value?.find(
      (disabledRefiner) => disabledRefiner.label === option.label
    )
      ? (option = {
          ...option,
          disabled: true,
        })
      : option;

  const onHide = () => {
    if (refiner.resetFilterOnHide ?? true) {
      onFilter({ filter: "" });
    }
  };

  return (
    <>
      <MultiSelect
        {...props}
        resetFilterOnHide={refiner.resetFilterOnHide ?? true}
        onHide={onHide}
        virtualScrollerOptions={{
          lazy: true,
          itemSize: 5,
          onLazyLoad: () => {},
        }}
        selectedItemTemplate={selectedItemTemplate}
        style={{ width: "100%" }}
        value={selectedRefiners[refiner.field]}
        optionValue={(selectedValue) => selectedValue} // FIX: using value in option will lose label prop. including all object when selecting fix this
        options={
          refiner.loading && !refiner.onShowMore
            ? []
            : refiner.options.map(mapRefinerOptions)
        }
        itemTemplate={itemTemplate}
        scrollHeight="250px"
        placeholder="-- Click to type below --"
        panelHeaderTemplate={headerTemplate}
        panelFooterTemplate={footerTemplate}
      />
    </>
  );
};

const numericTypes = [
  {
    formLabel: "Greater Than",
    type: "GreaterThan",
  },
  {
    formLabel: "Less Than",
    type: "LessThan",
  },
  {
    formLabel: "Equal To",
    type: "EqualTo",
  },
];

const NumericRefiner = ({ selectedRefiners, refiner, onChange }) => {
  const selectedRefiner = selectedRefiners[refiner.field];
  const [inputStates, setInputStates] = useState({
    EqualTo: selectedRefiner?.EqualTo ?? null,
    GreaterThan: selectedRefiner?.GreaterThan ?? null,
    LessThan: selectedRefiner?.LessThan ?? null,
  });

  useEffect(() => {
    numericTypes.forEach(({ type }) => {
      const numericValue = selectedRefiner?.[type];
      if (numericValue !== 0 && !numericValue) {
        setInputStates((prev) => ({
          ...prev,
          [type]: null,
        }));
      }
    });
  }, [selectedRefiners]);

  return (
    <div className={classes["numeric-refiners"]}>
      {numericTypes.map(({ type, formLabel }) => {
        const currentValue = inputStates[type];
        return (
          <div key={type} className={classes["numeric-refiner"]}>
            <div className={classes["numeric-refiner__label"]}>{formLabel}</div>
            <InputNumber
              value={
                isValidNumber(currentValue)
                  ? `${currentValue ?? ""}`.replaceAll(",", "")
                  : null
              }
              disabled={
                type === "EqualTo"
                  ? isValidNumber(inputStates.GreaterThan) ||
                    isValidNumber(inputStates.LessThan)
                  : isValidNumber(inputStates.EqualTo)
              }
              onKeyDown={(event) => {
                if (event.key == "Enter") {
                  onChange(inputStates);
                }
              }}
              onBlur={() => onChange(inputStates)}
              onChange={(event) => {
                setInputStates((prev) => ({
                  ...prev,
                  [type]: event.value,
                }));
              }}
              className={classes["numeric-refiner__input"]}
              placeholder="Enter amount..."
              maxFractionDigits={3}
            />
          </div>
        );
      })}
    </div>
  );
};

const ProjectWorkQueueBaseRefiners = ({
  refiners,
  selectedRefiners,
  onFilterRefiner,
  onSelectedRefinerChange,
  onRemoveSelectedRefiner,
  onRemoveSelectedRefinerFilter,
  onRemoveSelectedRefiners,
  showCustomSearch = false,
  customSearchText = "",
  onCustomSearchTextChange = (string) => {},
  onCustomSearch = (string) => {},
  disabledRefiners = {},
  customSearchLabel,
  customSearchPlaceholder,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [expandedRefinerFields, setExpandedRefinerFields] = useState([]);
  const [collapsedSelectedRefinerFields, setCollapsedSelectedRefinerFields] =
    useState([]);
  const calendarParent = useRef();

  const handleRefinerExpand = (field) => {
    if (isRefinerExpanded(field))
      setExpandedRefinerFields((currIndices) =>
        currIndices.filter((c) => c !== field)
      );
    else setExpandedRefinerFields((currIndices) => [...currIndices, field]);
  };

  const handleSelectedRefinerCollapse = (field) => {
    if (isSelectedRefinerCollapsed(field))
      setCollapsedSelectedRefinerFields((currIndices) =>
        currIndices.filter((c) => c !== field)
      );
    else
      setCollapsedSelectedRefinerFields((currIndices) => [
        ...currIndices,
        field,
      ]);
  };

  const isRefinerExpanded = (field) => expandedRefinerFields.includes(field);

  const isSelectedRefinerCollapsed = (field) =>
    collapsedSelectedRefinerFields.includes(field);

  const renderCheckboxes = (refiner) => (
    <div className={classes["checkboxes-container"]}>
      {!refiner.hideSelectAll && (
        <div key="All" className={classes["field-checkbox"]}>
          <Checkbox
            inputId="All"
            name="status"
            value="All"
            key="All"
            onChange={(e) => {
              onSelectedRefinerChange(
                refiner,
                e.checked ? refiner.options : []
              );
            }}
            checked={
              refiner.options.length === selectedRefiners[refiner.field]?.length
            }
          />
          <label htmlFor="All"> Select All</label>
        </div>
      )}

      {refiner.options.map((option) => {
        return (
          <div key={option.label} className={classes["field-checkbox"]}>
            <Checkbox
              inputId={option.label}
              name="status"
              key={option.label}
              value={option}
              onChange={(event) => {
                onSelectedRefinerChange(
                  refiner,
                  event.checked
                    ? selectedRefiners[refiner.field]
                      ? [...selectedRefiners[refiner.field], event.value]
                      : [event.value]
                    : selectedRefiners[refiner.field]
                    ? selectedRefiners[refiner.field].filter(
                        (item) =>
                          (item.value ?? item.label) !==
                          (event.value.value ?? event.value.label)
                      )
                    : []
                );
              }}
              checked={selectedRefiners[refiner.field]?.some(
                (item) =>
                  (item.value ?? item.label) === (option.value ?? option.label)
              )}
            />
            <label htmlFor={option.label}>{option.label}</label>
          </div>
        );
      })}

      {refiner.displayShowMore && refiner.options?.length > 0 && (
        <div className={classes["show-more"]}>
          <button disabled={refiner.isLoading} onClick={refiner.onShowMore}>
            Show more
          </button>
        </div>
      )}
    </div>
  );

  const renderCalendar = (refiner) => {
    const handleChange = (value, type) => {
      onSelectedRefinerChange(refiner, value, type);
      setMaxDate(type);
      setMinDate(type);
    };

    const isDateDisabled = (type) => {
      const refiners = selectedRefiners[refiner.field];
      const { DateOn, OnOrBefore, OnOrAfter, DateFrom, DateTo, PastDue } =
        refiners || {};

      switch (type) {
        case "DateOn":
          return (
            !!OnOrBefore || !!OnOrAfter || !!DateTo || !!DateFrom || !!PastDue
          );
        case "OnOrBefore":
          return !!DateOn || !!OnOrAfter || !!DateTo || !!DateFrom || !!PastDue;
        case "OnOrAfter":
          return (
            !!DateOn || !!OnOrBefore || !!DateTo || !!DateFrom || !!PastDue
          );
        case "DateFrom":
        case "DateTo":
          return !!DateOn || !!OnOrBefore || !!OnOrAfter || !!PastDue;
        default:
          return false;
      }
    };

    const setMaxDate = (type) => {
      if (type === "DateFrom")
        return new Date(selectedRefiners[refiner.field]?.DateTo);

      let calendarValue = selectedRefiners[refiner.field]?.[type];
      return new Date(
        new Date(calendarValue).setFullYear(
          new Date(calendarValue).getFullYear() + 10
        )
      );
    };

    const setMinDate = (type) => {
      if (type === "DateTo")
        return new Date(selectedRefiners[refiner.field]?.DateFrom);

      let calendarValue = selectedRefiners[refiner.field]?.[type];
      return new Date(
        new Date(calendarValue).setFullYear(
          new Date(calendarValue).getFullYear() - 10
        )
      );
    };

    const setYearRange = (type) => {
      let calendarValue = selectedRefiners[refiner.field]?.[type];
      const defaultMinYear = 2016;
      const defaultMaxYear = new Date().getFullYear() + 10;

      let minYear = defaultMinYear;
      let maxYear = defaultMaxYear;

      const selectedYear = new Date(calendarValue).getFullYear();

      if (type === "DateTo") {
        let dateFromValue = selectedRefiners[refiner.field]?.DateFrom;
        minYear = dateFromValue
          ? new Date(dateFromValue).getFullYear()
          : selectedYear
          ? selectedYear - 10
          : minYear;
        maxYear = selectedYear
          ? selectedYear + 10
          : dateFromValue
          ? new Date(dateFromValue).getFullYear() + 10
          : maxYear;
      } else if (type === "DateFrom") {
        let dateToValue = selectedRefiners[refiner.field]?.DateTo;
        minYear = selectedYear
          ? selectedYear - 10
          : dateToValue
          ? new Date(dateToValue).getFullYear() - 10
          : minYear;
        maxYear = selectedRefiners[refiner.field]?.DateTo
          ? new Date(dateToValue).getFullYear()
          : selectedYear
          ? selectedYear + 10
          : maxYear;
      } else {
        minYear = selectedYear ? selectedYear - 10 : minYear;
        maxYear = selectedYear ? selectedYear + 10 : maxYear;
      }

      minYear = minYear <= defaultMinYear ? defaultMinYear : minYear;

      return `${minYear}:${maxYear}`;
    };

    return (
      <>
        {refiner.displayPastDue && (
          <div className={classes["calendar-refiner__past-due"]}>
            <Checkbox
              onChange={(e) => handleChange(e.checked, "PastDue")}
              checked={selectedRefiners[refiner.field]?.PastDue}
              inputId="past-due"
              name="past-due"
            />
            <label htmlFor="past-due">Past Due</label>
          </div>
        )}

        <div className={`ul-grid__row -wrap ${classes["calendars-container"]}`}>
          {dateTypes
            .filter((d) => d.type !== "PastDue")
            .map(({ formLabel, type }) => (
              <div
                key={`${refiner.field}-${type}`}
                className="ul-grid__column -offset-10-desktop -x100-mobile"
              >
                <label htmlFor={type} className="ul-grid__column">
                  {formLabel}
                </label>
                <Calendar
                  disabled={isDateDisabled(type)}
                  id={type}
                  value={
                    selectedRefiners[refiner.field]?.[type]
                      ? new Date(selectedRefiners[refiner.field]?.[type])
                      : null
                  }
                  onChange={(event) => {
                    const inputValue = event.originalEvent.target.value;
                    if (moment(inputValue).isValid() || inputValue === "")
                      handleChange(event.value, type);
                  }}
                  showIcon
                  maxDate={setMaxDate(type)}
                  minDate={setMinDate(type)}
                  monthNavigator
                  yearNavigator
                  yearRange={setYearRange(type)}
                  placeholder="yyyy-mm-dd"
                  mask="9999-99-99"
                  dateFormat="yy-mm-dd"
                  showButtonBar
                  showOnFocus={false}
                  appendTo={calendarParent.current}
                  panelClassName={classes["refiner-calendar"]}
                />
              </div>
            ))}
        </div>
      </>
    );
  };

  const dateTypes = [
    {
      type: "PastDue",
      label: "Past Due",
    },
    {
      type: "DateOn",
      label: "Date On",
      formLabel: "Date On",
    },
    {
      type: "OnOrBefore",
      label: "On or Before",
      formLabel: "On or Before",
    },
    {
      type: "OnOrAfter",
      label: "On or After",
      formLabel: "On or After",
    },
    {
      type: "DateFrom",
      label: "Date From",
      formLabel: "Date From",
    },
    {
      type: "DateTo",
      label: "Date To",
      formLabel: "Date To",
    },
  ];

  const handleCustomSearchChange = (e) => {
    if (customSearchText.length > 0 && e.target.value.length === 0)
      handleCustomSearchTextClear();
    else onCustomSearchTextChange(e.target.value);
  };

  const handleCustomSearchTextClear = () => {
    onCustomSearchTextChange("");
    onCustomSearch("");
  };

  const handleCustomSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      onCustomSearch(customSearchText);
    }
  };

  const handleCustomSearch = () => {
    onCustomSearch(customSearchText);
  };

  const hasRefinerFiltersValue = (refinerFilters) => {
    if (!refinerFilters) return null;

    if (Array.isArray(refinerFilters) && refinerFilters.length === 0)
      return false;

    if (
      typeof refinerFilters === "object" &&
      Object.values(refinerFilters).every((x) => x === null)
    )
      return false;

    return true;
  };

  useEffect(() => {
    if (disabledRefiners?.field) {
      setExpandedRefinerFields((currIndices) =>
        currIndices.filter((c) => c !== disabledRefiners.field)
      );
    }
    return;
  }, [disabledRefiners]);

  return (
    <div
      className={clsx(classes["container"], isExpanded && classes["expanded"])}
      ref={calendarParent}
    >
      <button
        title="Click here to show/hide"
        onClick={() => setIsExpanded(!isExpanded)}
        className={classes["btn-expand"]}
      >
        {isExpanded ? (
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        ) : (
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        )}
      </button>

      {isExpanded && (
        <div className={classes["body"]}>
          <div className={classes["header"]}>
            <label>Filter Menu</label>
          </div>

          <div className={classes["selected-filters-container"]}>
            {showCustomSearch && (
              <div className={classes["custom-filter-container"]}>
                <div>
                  <label className={classes["custom-filter__label"]}>
                    {customSearchLabel}
                  </label>

                  <div className="ul-form__controlGroup">
                    <p
                      className={`ul-form__control ${classes["custom-filter__input-container"]}`}
                    >
                      <input
                        onKeyDown={handleCustomSearchKeyPress}
                        type="text"
                        placeholder={customSearchPlaceholder}
                        value={customSearchText}
                        onChange={handleCustomSearchChange}
                        className={`ul-form__controlGroupItem ul-textbox ${classes["custom-filter__input"]}`}
                      />
                      {customSearchText.length > 0 && (
                        <FontAwesomeIcon
                          icon={faXmark}
                          onClick={handleCustomSearchTextClear}
                          className={`ul-form__controlIcon ${classes["custom-filter__clear-icon"]}`}
                        />
                      )}
                    </p>
                    <button
                      onClick={handleCustomSearch}
                      className={`ul-button -app-tertiary -medium -icon ${classes["custom-filter__btn-search"]}`}
                      aria-label="add"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>
                </div>
              </div>
            )}

            <label className={classes["your-filters__label"]}>
              Your Filters
            </label>

            <div className={classes["selected-filters"]}>
              {refiners.map((r) => {
                const refinerFilters = selectedRefiners[r.field];

                if (!hasRefinerFiltersValue(refinerFilters)) return null;

                const isArray = Array.isArray(refinerFilters);
                const label = r.label;
                const isCollapsed = isSelectedRefinerCollapsed(r.field);
                const onCollapse = () => handleSelectedRefinerCollapse(r.field);
                const onRemove = (type) => onRemoveSelectedRefiner(r, type);
                const types = [...numericTypes, ...dateTypes];

                return (
                  <div className={classes["refiner-filters"]} key={r.field}>
                    <div className={classes["header"]}>
                      <div onClick={onCollapse} className={classes["left"]}>
                        <i className="material-icons">
                          {isCollapsed ? "arrow_right" : "arrow_drop_down"}
                        </i>
                        <label>{label}</label>
                      </div>
                      <button
                        className={`ul-button ${classes["btn-remove-refiner-filters"]}`}
                        disabled={disabledRefiners?.field === r.field}
                        onClick={() => onRemove()}
                      >
                        <i
                          className={clsx(
                            "material-icons",
                            classes["color-red"]
                          )}
                        >
                          minimize
                        </i>
                      </button>
                    </div>
                    {!isCollapsed && (
                      <div className={classes["body"]}>
                        {isArray
                          ? refinerFilters.map((v) => (
                              <div
                                className={classes["selected-filter"]}
                                key={v.label}
                              >
                                <label>
                                  {r.filterTemplate
                                    ? r.filterTemplate(v)
                                    : v.label}
                                </label>
                                <button
                                  onClick={() =>
                                    onRemoveSelectedRefinerFilter(r, v)
                                  }
                                  disabled={
                                    disabledRefiners?.field === r.field &&
                                    disabledRefiners?.value?.find(
                                      (a) => a.label === v.label
                                    )
                                  }
                                  className={`ul-button ${classes["btn-remove-filter"]}`}
                                >
                                  <i className="material-icons">close</i>
                                </button>
                              </div>
                            ))
                          : Object.keys(refinerFilters).map((k) => {
                              const val = refinerFilters[k];

                              if (val === undefined || val === null)
                                return null;

                              const label = types
                                .filter((t) => t.type === k)
                                .map((t) => t.label || t.formLabel)[0];

                              return (
                                <div
                                  className={classes["selected-filter"]}
                                  key={k + val}
                                >
                                  {["Date From", "Date To"].includes(label) ? (
                                    <label>
                                      <div className="ul-grid__column">
                                        <div className="ul-grid__row -wrap">
                                          <span style={{ width: "80px" }}>
                                            {label}
                                          </span>
                                          &nbsp;
                                          {val}
                                        </div>
                                      </div>
                                    </label>
                                  ) : (
                                    <label>
                                      {label} {val}
                                    </label>
                                  )}
                                  <button
                                    onClick={() => onRemove(k)}
                                    className={`ul-button ${classes["btn-remove-filter"]}`}
                                  >
                                    <i className="material-icons">close</i>
                                  </button>
                                </div>
                              );
                            })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className={classes["btn-clear-container"]}>
              <button
                disabled={
                  !refiners?.length ||
                  Object.keys(selectedRefiners).length <= 0 ||
                  (disabledRefiners?.field &&
                    Object.keys(selectedRefiners).length === 1)
                }
                className={`ul-button -app-tertiary -medium`}
                onClick={onRemoveSelectedRefiners}
              >
                CLEAR
              </button>
            </div>
          </div>

          <div className={classes["refiners-container"]}>
            <div className={classes["helper-text-container"]}>
              <label>Select a filter below to refine your search</label>
            </div>
            <div className={classes["filters-container"]}>
              {refiners?.map((refiner, i) => {
                return (
                  <div
                    key={refiner.field}
                    className={`${
                      classes[
                        (isRefinerExpanded(refiner.field)
                          ? "expanded"
                          : "collapsed",
                        refiner.field === disabledRefiners?.field
                          ? "read-only"
                          : "")
                      ]
                    } ${classes["field"]}`}
                  >
                    <div
                      onClick={() => handleRefinerExpand(refiner.field)}
                      className={classes["field-header"]}
                    >
                      <div className={classes["label"]}>{refiner.label}</div>

                      <i className="material-icons">
                        {isRefinerExpanded(refiner.field)
                          ? "arrow_drop_down"
                          : "arrow_right"}
                      </i>
                    </div>

                    {isRefinerExpanded(refiner.field) && (
                      <>
                        <div className={classes["field-control"]}>
                          {!refiner.element && (
                            <MultiSelectRefiner
                              key={refiner.field}
                              refiner={refiner}
                              selectedRefiners={selectedRefiners}
                              disabledRefiners={disabledRefiners}
                              onChange={(event) => {
                                onSelectedRefinerChange(refiner, event.value);
                              }}
                              onFilter={(event) => {
                                onFilterRefiner(refiner, event.filter);
                              }}
                            />
                          )}
                          {refiner.element === "Calendar" &&
                            renderCalendar(refiner)}
                          {refiner.element === "Checkbox" &&
                            renderCheckboxes(refiner)}
                          {refiner.element === "Numeric" && (
                            <NumericRefiner
                              refiner={refiner}
                              selectedRefiners={selectedRefiners}
                              onChange={(event) =>
                                onSelectedRefinerChange(refiner, event)
                              }
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectWorkQueueBaseRefiners;
