import { AxiosRequestConfig, CancelToken } from "axios";
import RequestFactory from "./RequestFactory";
import { GetPwqFlexProjectChildLinesResultModel } from "../models/project-work-queue/GetPwqFlexProjectChildLinesResultModel.model";
import { GetPwqRefinedProjectLinesResultModel } from "../models/project-work-queue/GetPwqRefinedProjectLinesResultModel.model";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

const ProjectWorkQueueService = {
  getProjectLines: async (params: any, cancelToken?: CancelToken) => {
    const requestConfig: AxiosRequestConfig = {
      cancelToken,
    };

    return (await RequestFactory.post(
      GMAE2EURL,
      "/api/project-work-queue/get-pwq-project-lines",
      params,
      () => {
        if (cancelToken === undefined) return undefined;

        return {
          cancelToken,
        } as any;
      }
    )) as Promise<GetPwqFlexProjectChildLinesResultModel>;
  },
  getRefinedProjectLines: async (params: any, cancelToken?: CancelToken) => {
    const requestConfig: AxiosRequestConfig = {
      cancelToken,
    };

    return (await RequestFactory.post(
      GMAE2EURL,
      "/api/project-work-queue/get-pwq-project-lines-v2",
      params,
      () => {
        if (cancelToken === undefined) return undefined;

        return {
          cancelToken,
        } as any;
      }
    )) as Promise<GetPwqRefinedProjectLinesResultModel>;
  },
};

export default ProjectWorkQueueService;
