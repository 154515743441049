import { InputText, InputTextProps } from "primereact/inputtext";
import classes from "./ULSearch.module.scss";
import { Button } from "primereact/button";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export interface ULSearchProps {
  label?: string;
  placeholder?: string;
  onSubmit?: (value: string) => void;
  value?: string;
}

export default function ULSearch(props: ULSearchProps) {
  const { label, placeholder, onSubmit, value } = props;
  const [tempValue, setTempValue] = useState("");

  const invokeSubmit = () => {
    setTempValue((prev) => {
      const finalValue = (prev ?? "").trim();

      if (onSubmit) onSubmit(finalValue);
      return finalValue;
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter") {
      invokeSubmit();
    }
  };

  const handleSubmit = () => {
    invokeSubmit();
  };

  const handleClear = () => {
    setTempValue("");
    invokeSubmit();
  };

  useEffect(() => {
    if (tempValue === "") {
      invokeSubmit();
    }
  }, [tempValue]);

  useEffect(() => {
    setTempValue(value ?? "");
  }, [value]);

  return (
    <>
      {label && <label className="p-d-block font-weight-bold">{label}</label>}
      <div className={clsx("p-inputgroup", classes["ul-search"])}>
        <InputText
          placeholder={placeholder ?? "Search"}
          onChange={(event) => setTempValue(event.target.value)}
          onKeyDown={handleKeyDown}
          value={tempValue}
        />
        {tempValue.trim() !== "" && (
          <div className={classes["clear-button"]} onClick={handleClear}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        )}
        <Button
          icon="pi pi-search"
          className="ul-bg-accent"
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}
