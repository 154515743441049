import { RefinerType } from "../enums/RefinerType";
import { GetProjectRefinersResultModel } from "../models/flex/GetProjectRefinersResultModel";
import { IGetGMAWBProjectQuery } from "../shared/models/refiner-models/get-gmawb-project";
import { IGetProjectContributorsQuery } from "../shared/models/refiner-models/get-project-contributors";
import RequestFactory from "./RequestFactory";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

export interface IGetPWQRefinersPaginationRequest {
  top: number;
  skip: number;
  search: string;
  refinerType: RefinerType;
  order: "asc" | "desc";
}

export interface IGetPWQRefinersOracleCompanyInfo {
  take: number;
  skip: number;
  partySiteNumber: string;
  accountNumber: string;
  partyName: string;
}

const ProjectRefinersService = {
  getProjectWorkQueueRefiners: async (
    parameters: any
  ): Promise<GetProjectRefinersResultModel> => {
    return await RequestFactory.post(
      GMAE2EURL,
      `/api/project-refiners/get-project-work-queue-refiners`,
      parameters
    );
  },
  getPWQRefinersPagination: async (body: IGetPWQRefinersPaginationRequest) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project-refiners/get-pwq-refiners-pagination",
      {
        ...body,
        top: body.top.toString(),
        skip: body.skip.toString(),
      }
    ),
  getPWQRefinersOracleCompanyInfo: async (
    body: IGetPWQRefinersOracleCompanyInfo
  ) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project-refiners/get-pwq-refiners-oracle-company-info",
      body
    ),
  getGMAWBProject: async (body: IGetGMAWBProjectQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project-refiners/get-gmawb-project",
      body
    ),
  getProjectContributors: async (body: IGetProjectContributorsQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project-refiners/get-project-contributors",
      body
    ),
  getProjectCreator: async (body: IGetGMAWBProjectQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project-refiners/get-project-creator",
      body
    ),
  getProjectSoldTo: async (body: IGetGMAWBProjectQuery) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project-refiners/get-project-soldto",
      body
    ),
};

export default ProjectRefinersService;
