import CertificateListView from "../pages/certificate-list-view/CertificateListView";
import CertificationChecklist from "../pages/certification-checklist/CertificationChecklist";
import DocumentKnowledge from "../pages/document-knowledge-v2/DocumentKnowledge";

import CertificateProductListView from "../pages/certificate-product-list-view/CertificateProductListView";
import CustomerNameLookupPage from "../pages/customer-name-lookup/CustomerNameLookupPage";
import GeneralProductInformationForm from "../pages/general-product-information/GeneralProductInformation";
import GeneralProductInformationList from "../pages/general-product-information/GeneralProductInformationList";
import ProjectTemplateMapping from "../pages/project-template-mapping/ProjectTemplateMapping";
import ProjectWorkQueue from "../pages/project-work-queue/ProjectWorkQueue";
import ProjectTemplateList from "../pages/template/ProjectTemplateList";
import ProjectManagement from "../pages/project-management/ProjectManagement";
import NotificationList from "../pages/notification-list/NotificationList";
import CertificatePendingDocumentList from "../pages/certificate-pending-document-list/CertificatePendingDocumentList";
import ProjectManagementPmr from "../pages/project-management-pmr/ProjectManagementPmr";
import ProjectFullView from "../pages/project-management-pmr/project-full-view/ProjectFullView";
import ProjectFullViewWithConfig from "../pages/project-management-pmr/project-full-view-with-config/ProjectFullViewWithConfig";
import { CARRY_OVER_CONFIGURATION_ON_NEW_TAB } from "../utils/constants/feature-flag.constants";
const routeConfig = [
  {
    key: "key-route-template-list-view",
    name: "Project Template List",
    icon: "playlist_add_check",
    path: "/template-list-view",
    component: ProjectTemplateList,
    default: true,
    sideMenu: true,
    routes: [],
  },

  {
    key: "key-route-template-admin-mapping",
    name: "Project Template Mapping",
    icon: "playlist_add_check",
    path: "/template-admin-mapping",
    component: ProjectTemplateMapping,
    default: false,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-route-certification-checklist",
    name: "Certification Checklist",
    icon: "playlist_add_check",
    path: "/certification-checklist",
    component: CertificationChecklist,
    default: false,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-route-project-template-mapping",
    name: "Project Template Mapping",
    icon: "playlist_add_check",
    path: "/project-template-mapping",
    component: ProjectTemplateMapping,
    default: false,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-route-document-knowledge",
    name: "Document Knowledge",
    icon: "playlist_add_check",
    path: "/document-knowledge",
    component: DocumentKnowledge,
    default: false,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-route-certificate-list",
    name: "Certificate List",
    icon: "playlist_add_check",
    path: "/certificate-list",
    component: CertificateListView,
    default: false,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-route-certificate-pending-document-list",
    name: "Certificate Pending Document List",
    icon: "playlist_add_check",
    path: "/certificate-pending-document-list",
    component: CertificatePendingDocumentList,
    default: false,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-route-certificate-product-list",
    name: "Product List",
    icon: "playlist_add_check",
    path: "/certificate-product-list",
    component: CertificateProductListView,
    default: false,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-customer-name-lookup",
    name: "Customer Name Lookup",
    icon: "search",
    path: "/customer-name-lookup",
    component: CustomerNameLookupPage,
    default: false,
    sideMenu: false,
    routes: [],
  },
  {
    key: "key-general-product-information-form",
    name: "General Product Information Form",
    icon: "search",
    path: "/general-product-information/form",
    component: GeneralProductInformationForm,
    default: false,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-general-product-information",
    name: "General Product Information Form",
    icon: "search",
    path: "/general-product-information",
    component: GeneralProductInformationList,
    default: false,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-route-project-management",
    name: "Project Management",
    icon: "playlist_add_check",
    path: "/project-management",
    component:  ProjectManagementPmr,
    default: true,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-route-project-management",
    name: "Project Management",
    icon: "playlist_add_check",
    path: "/project-management/:projectName",
    component:  CARRY_OVER_CONFIGURATION_ON_NEW_TAB ? ProjectFullViewWithConfig : ProjectFullView,
    default: true,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-route-project-management",
    name: "Project Management",
    icon: "playlist_add_check",
    path: "/project-management-old",
    component: ProjectManagement,
    default: true,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-project-work-queue",
    name: "Project Work Queue",
    icon: "search",
    path: "/project-work-queue",
    component: ProjectWorkQueue,
    default: true,
    sideMenu: true,
    routes: [],
  },
  {
    key: "key-notification-list",
    name: "Notification List",
    icon: "notification",
    path: "/notification-list",
    component: NotificationList,
    default: true,
    sideMenu: true,
    routes: [],
  },
];

export default routeConfig;
