import { Toolbar } from "primereact/toolbar";
import { Checkbox } from "primereact/checkbox";
import { Card } from "primereact/card";
import { TabMenu } from "primereact/tabmenu";
import classes from "./DocKnowledgeNavTabs.module.scss";
import React, { useState } from "react";
import CommonDocuments from "./common-documents/CommonDocuments";
import { InputSwitch } from "primereact/inputswitch";
import CountrySpecificDocs from "./country-specific-docs/CountrySpecificDocs";
import DeliveryPathInformation from "./delivery-path-information/DeliveryPathInformation";
import TestSampleList from "./test-sample-list/TestSampleList";
import LocalRepList from "./local-rep-list/LocalRepList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompressArrowsAlt,
  faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import { DataTableSortProps } from "../DocumentKnowledge";

interface DocKnowledgeNavTabsProps {
  isJapanese: boolean;
  setIsJapanese: React.Dispatch<React.SetStateAction<boolean>>;
  sorting: DataTableSortProps;
  setSorting: React.Dispatch<React.SetStateAction<DataTableSortProps>>;
}

const DocKnowledgeNavTabs = (props: DocKnowledgeNavTabsProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const [showNotUploaded, setShowNotUploaded] = useState(false);

  const tabItems = [
    { label: "Delivery Path Information" },
    { label: "Common Documents" },
    { label: "Country Specific Documents" },
    { label: "Test Sample List" },
    { label: "Local Rep List" },
  ];

  const collapsedTemplate = (rowData: any, field: any) => {
    return (
      <div className={`${classes["collapsed__container"]}`}>
        <span
          className={`${classes["collapsed__container-text"]}`}
          title={rowData[field]}
        >
          {rowData[field]}
        </span>
      </div>
    );
  };

  const onCollapseClick = () => {
    setIsCollapsed((current) => !current);
  };

  const ToolbarRight = () => {
    return (
      <div className={`${classes["nav-tab__toolbar--right"]}`}>
        <div className={`${classes["nav-tab__toolbar--right-items"]}`}>
          <Checkbox
            checked={showNotUploaded}
            onChange={(e) => setShowNotUploaded(e.checked)}
          ></Checkbox>
          <span>Only show documents not uploaded in Sharepoint</span>
        </div>

        <div className={`${classes["nav-tab__toolbar--right-items"]}`}>
          <InputSwitch
            checked={props.isJapanese}
            onChange={(e) => props.setIsJapanese(e.value)}
          />
          <span>Use Japanese Description</span>
          <FontAwesomeIcon
            className={`${classes["nav-tab__toolbar--right-items-collapse-icon"]}`}
            onClick={onCollapseClick}
            icon={
              isCollapsed === true ? faCompressArrowsAlt : faExpandArrowsAlt
            }
          />
        </div>
      </div>
    );
  };

  const tabToolbarOnChange = (props: any) => {
    setActiveTab(props.index);
  };

  const renderTabContent = (index: number, Component: any) => (
    <div style={activeTab === index ? undefined : { display: "none" }}>
      <Component
        isJapanese={props.isJapanese}
        showNotUploaded={showNotUploaded}
        sorting={props.sorting}
        setSorting={props.setSorting}
        isCollapsed={isCollapsed}
        collapsedTemplate={collapsedTemplate}
      />
    </div>
  );

  return (
    <div className={`${classes["nav-tab__container"]}`}>
      <Card className={`${classes["nav-tab__card"]}`}>
        <Toolbar
          className={`${classes["nav-tab__toolbar"]}`}
          left={
            <TabMenu
              activeIndex={activeTab}
              model={tabItems}
              onTabChange={tabToolbarOnChange}
            />
          }
          right={<ToolbarRight />}
        />
        <div className={`${classes["nav-tab__content"]}`}>
          {renderTabContent(0, DeliveryPathInformation)}
          {renderTabContent(1, CommonDocuments)}
          {renderTabContent(2, CountrySpecificDocs)}
          {renderTabContent(3, TestSampleList)}
          {renderTabContent(4, LocalRepList)}
        </div>
      </Card>
    </div>
  );
};

export default DocKnowledgeNavTabs;
