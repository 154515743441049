import {
  faCheck,
  faClose,
  faPencil,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCertDetailValues,
  selectModelDetailValues,
  selectModelList,
  selectOwnerReference,
  selectPartySiteNumber,
  setModelsProductStandardsUpdates,
  selectStandardValue,
  selectStandardOptions,
  selectProductCategoryStandards,
  updateHasExistingModels,
  updateModelDetails,
  updateModelList,
  updateProdDetailsModelList,
  updateProdDetailsRemarks,
  updateStandardOptions,
  updateStandardValues,
  updateHasEmptyProductStandardRow,
  updateHasCertificateProductStandard,
  updateCertDetailsFormValues,
} from "../../../../../features/createCertificate/createCertificateSlice";
import DocumentService from "../../../../../services/DocumentService";
import ModelSearch from "../../../../../shared/model-search/ModelSearch";
import { sortList } from "../../../../../utils/helpers/array.helpers";
import AddAttachments from "../add-attachments/AddAttachments";
import classes from "./AddNewCertProdDetails.module.scss";
import "./AddNewCertProdDetails.scss";
import CloseRowEditModeConfirmationDialog from "./close-row-edit-mode-confirmation-dialog/CloseRowEditModeConfirmationDialog";
import AddAttachmentsBulk from "../add-attachments-bulk/AddAttachmentsBulk";
import FeatureFlagService from "../../../../../services/FeatureFlagService";
import BulkSaveConfirmationDialog from "./bulk-save-confirmation-dialog/BulkSaveConfirmationDialog";
import { uniqBy, orderBy, flatten } from "lodash";

const AddNewCertProdDetails = (props) => {
  const dispatch = useDispatch();
  const certDetailsValues = useSelector(selectCertDetailValues);
  const {
    isSavingCertificate,
    isEditCert,
    isModifyCert,
    setOutputAttachmentValues,
    setFormValues,
    certificateId,
    currentStep,
    setIsModelInRowEditMode,
    setFormValidityMessages,
    inputAttachments,
    setAttachments,
    isCertificateHaveStandard,
    testReportsValidatorHook,
  } = props;
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedModelsForDeletion, setSelectedModelsForDeletion] = useState(
    []
  );

  const [remarks, setRemarks] = useState("");
  const partySiteNumberValue = useSelector(selectPartySiteNumber);
  const modelDetailValues = useSelector(selectModelDetailValues);
  const ownerReference = useSelector(selectOwnerReference);
  const models = useSelector(selectModelList);
  const standardValue = useSelector(selectStandardValue);
  const standardOptions = useSelector(selectStandardOptions);
  const standardOptionsClone = useSelector(selectProductCategoryStandards);

  const [modelSearchVisible, setModelSearchVisible] = useState(false);
  const [modelList, setModelList] = useState(models ? models : []);
  const [standardList, setStandardList] = useState([]);

  const [isBatchEditMode, setIsBatchEditMode] = useState(false);
  const batchEditRowData = {
    isEditMode: true,
    productStandards: [],
    isForBatchEdit: true,
  };

  const [
    modelsProductStandardsEditStates,
    setModelsProductStandardsEditStates,
  ] = useState([]);

  const rowsInEditMode = modelList?.filter((model) => model.isEditMode);
  const hasRowEditMode = rowsInEditMode?.length > 0 || isBatchEditMode;
  setIsModelInRowEditMode(hasRowEditMode);

  const [
    closeRowEditModeConfirmationDialogVisible,
    setCloseRowEditModeConfirmationDialogVisible,
  ] = useState(false);
  const [
    pendingCloseRowsEditModesConfirmation,
    setPendingCloseRowsEditModesConfirmation,
  ] = useState([]);
  const [
    bulkSaveConfirmationDialogVisible,
    setBulkSaveConfirmationDialogVisible,
  ] = useState(false);
  const [
    pendingBulkSaveEditModesConfirmation,
    setPendingBulkSaveEditModesConfirmation,
  ] = useState([]);

  const toast = useRef(null);
  const selectedModelsCount = selectedModels.length;

  useEffect(() => {
    const modelDetail = {};
    if (models && models.length > 0) {
      let partySiteNumber = "";

      for (let i = 0; i < models.length; i++) {
        let isExists = models[i].taxonomy.find(
          (f) => f.dataParamName === "ownerReference_PartySiteID"
        );

        if (isExists) {
          partySiteNumber = isExists.value;
          break;
        }
      }

      modelDetail.partySiteNumber = partySiteNumber;
      modelDetail.productType = models[0].productType;
      modelDetail.isDisable = true;
    } else {
      modelDetail.partySiteNumber = ownerReference;
      modelDetail.productType = null;
      modelDetail.isDisable = false;
    }

    dispatch(updateModelDetails(modelDetail));
    setModelList(models);
  }, [models, ownerReference]);

  useEffect(() => {
    if (
      (isEditCert || isModifyCert) &&
      models?.length > 0 &&
      modelList?.length > 0
    ) {
      const newModelList = modelList.map((list) => {
        const newProductStandards =
          models.find((model) => model.assetId === list.assetId)
            ?.productStandards || [];

        const newList = { ...list };
        newList.productStandards = newProductStandards;

        return newList;
      });

      setModelList(newModelList);
    }
  }, [models]);

  useEffect(() => {
    if (!(isEditCert || isModifyCert)) return;

    modelList.forEach((model) => {
      if (model.fetchedProductStandards) return;

      setModelList((previousList) =>
        previousList.map((prevModel) => ({
          ...prevModel,
          fetchedProductStandards:
            prevModel.assetId === model.assetId ||
            prevModel.fetchedProductStandards,
          loadingProductStandards:
            prevModel.assetId === model.assetId ||
            prevModel.loadingProductStandards,
        }))
      );

      DocumentService.getIpCertificateProductStandard(
        certificateId,
        model.modelName
      )
        .then((result) => {
          const certificateProductStandards =
            result?.data?.productStandards?.map((standards) => standards) || [];

          const productStandards = certificateProductStandards
            .flat(1)
            ?.filter((standard) => standard)
            ?.map((standard) => ({
              code: standard.uniqueIdentifier,
              name: standard.standardLabel,
              schemeScopeStandardId: standard.pkSchemeScopeStandardId,
              ...standard,
            }));

          setModelProductStandards(model.assetId, productStandards);
        })
        .finally(() => {
          setModelList((previousList) =>
            previousList.map((prevModel) => ({
              ...prevModel,
              loadingProductStandards:
                prevModel.assetId === model.assetId
                  ? false
                  : prevModel.loadingProductStandards,
            }))
          );
        });
    });

    const disableProductStandardSaveActivate = !modelList.some(
      (x) => x.productStandards?.length
    );

    dispatch(
      updateHasEmptyProductStandardRow(disableProductStandardSaveActivate)
    );
  }, [modelList]);

  useEffect(() => {
    if (!(isEditCert || isModifyCert)) return;

    if (modelList.length > 0 && currentStep === 3) {
      const disableProductStandardSaveActivate = !modelList?.some(
        (x) => x.productStandards?.length
      );

      dispatch(
        updateHasEmptyProductStandardRow(disableProductStandardSaveActivate)
      );
    }
  }, [currentStep]);

  useEffect(() => {
    props.onSetRowEditMode && props.onSetRowEditMode(hasRowEditMode);
  }, [hasRowEditMode]);

  useEffect(() => {
    let modelsProductStandardsUpdates = [];

    modelList?.forEach((model) => {
      const mapper = (standard) => {
        return {
          pkSchemeScopeStandardId: standard.schemeScopeStandardId,
          pkStandardId: standard.standardId,
          pkStandardLabelId: standard.standardLabelId,
          seq: standard.sequence,
        };
      };

      const toBeAdded =
        model.productStandards
          ?.filter(
            (standard) =>
              !model.originalProductStandards?.some(
                (originalStandard) => originalStandard.code === standard.code
              )
          )
          ?.map(mapper) || [];

      // Map new product standards' sequences.
      if (toBeAdded.length > 0) {
        let lastSequence =
          +orderBy(
            model.originalProductStandards?.filter(
              (standard) => standard.sequence
            ),
            (standard) => +standard.sequence,
            "desc"
          )[0]?.sequence || 0;

        toBeAdded.forEach((toBeAdd) => (toBeAdd.seq = ++lastSequence));
      }

      const toBeRemoved =
        model.originalProductStandards
          ?.filter(
            (originalStandard) =>
              !model.productStandards.some(
                (standard) => standard.code === originalStandard.code
              )
          )
          ?.map(mapper) || [];

      if (toBeAdded.length > 0 || toBeRemoved.length > 0) {
        modelsProductStandardsUpdates = [
          ...modelsProductStandardsUpdates,
          {
            assetId: [model.assetId],
            toBeAdded,
            toBeRemoved,
          },
        ];
      }

      dispatch(
        setModelsProductStandardsUpdates(
          modelsProductStandardsUpdates.length === 0
            ? null
            : modelsProductStandardsUpdates
        )
      );
    });
  }, [modelList]);

  const columns = [
    {
      id: "modelName",
      value: "Model",
      width: "25%",
      body: modelNameTemplate,
    },
    {
      id: "productType",
      value: "Product Type",
      width: "25%",
    },
    {
      id: "productStandard",
      value: "Product Standard",
      width: "27%",
      body: modelProductStandardsTemplate,
    },
    {
      id: "familySeries",
      value: "Family / Series",
      width: "25%",
    },
    {
      id: "status",
      value: "Status",
      width: "20%",
    },
  ];

  const onChangeRemarks = (event) => {
    setRemarks(event.target.value);
    dispatch(updateProdDetailsRemarks(event.target.value));
  };

  const dynamicColumns = columns
    .filter((col) => col.id !== "productStandard" || isEditCert || isModifyCert)
    .map((col) => {
      const { id, value, width, ...rest } = col;

      return (
        <Column
          key={id}
          columnKey={id}
          field={id}
          header={value}
          style={{ width }}
          {...rest}
        />
      );
    });

  const handleSelectedModelsApply = (selectedModels, isClose) => {
    dispatch(updateProdDetailsModelList(selectedModels));
    dispatch(updateHasExistingModels(false));
    dispatch(updateModelList(selectedModels));
    setModelList(selectedModels);

    if (isClose) setModelSearchVisible(false);
  };

  const onClickDeleteModelHeader = () => {
    setModelList((prevState) => {
      let newState = [];

      prevState.forEach((item) => {
        let foundSelected = selectedModels.find(
          (i) => i.assetId === item.assetId
        );
        if (!foundSelected) {
          newState.push(item);
        }
      });

      if (newState.length === 0) {
        const modelDetail = {
          partySiteNumber: partySiteNumberValue,
          productType: null,
          isDisable: false,
        };

        dispatch(updateModelDetails(modelDetail));
      }

      dispatch(updateProdDetailsModelList(newState));
      return newState;
    });
  };

  const onClickDeleteModelRow = (assetId) => {
    setModelList((prevState) => {
      const newState = prevState.filter((i) => i.assetId !== assetId);

      if (newState.length === 0) {
        const modelDetail = {
          partySiteNumber: partySiteNumberValue,
          productType: null,
          isDisable: false,
        };

        dispatch(updateModelDetails(modelDetail));
      }

      dispatch(updateProdDetailsModelList(newState));
      dispatch(updateModelList(newState));

      return newState;
    });
  };

  const setEditMode = (isEditMode, model) => {
    if (isEditMode) {
      if (
        !selectedModels.some(
          (selectedModel) => selectedModel.assetId === model.assetId
        )
      ) {
        setSelectedModels((prevList) => [...prevList, model]);
      }

      updateModelProductStandardsEditState(
        model.assetId,
        model.productStandards
      );
    }

    setModelList((prevList) =>
      prevList.map((prevModel) => ({
        ...prevModel,
        isEditMode:
          prevModel.assetId === model.assetId
            ? isEditMode
            : prevModel.isEditMode,
      }))
    );

    setStandardList(standardOptions);
    dispatch(updateStandardOptions(standardOptionsClone));
  };

  const updateModelProductStandardsEditState = (assetId, newValues) => {
    if (
      modelsProductStandardsEditStates.some(
        (model) => model.assetId === assetId
      )
    ) {
      setModelsProductStandardsEditStates((prevList) =>
        prevList.map((item) => ({
          ...item,
          productStandards:
            item.assetId === assetId ? newValues : item.productStandards,
        }))
      );

      return;
    }

    setModelsProductStandardsEditStates((prevList) => [
      ...prevList,
      { assetId, productStandards: newValues },
    ]);
  };

  const handleModelProductStandardsEditChange = (
    e,
    newProductStandardOptions,
    assetId
  ) => {
    e.originalEvent.stopPropagation();
    const newValues = newProductStandardOptions.filter((option) =>
      e.value.includes(option.code)
    );

    updateModelProductStandardsEditState(assetId, newValues);
  };

  const getModelProductStandardsEditState = (assetId) =>
    modelsProductStandardsEditStates.find((model) => model.assetId === assetId)
      ?.productStandards || [];

  const certProductStandardOptions =
    certDetailsValues.standardOptions?.map((option) => ({
      ...option,
      ...option.otherProps,
    })) || [];

  function setModelProductStandards(assetId, productStandards) {
    setModelList((previousList) =>
      previousList.map((prevModel) => ({
        ...prevModel,
        productStandards:
          prevModel.assetId === assetId
            ? productStandards
            : prevModel.productStandards,
        originalProductStandards:
          prevModel.assetId === assetId
            ? productStandards
            : prevModel.originalProductStandards,
      }))
    );
  }

  const handleProductStandardRemove = (assetId, code) => {
    const newProductStandards = getModelProductStandardsEditState(
      assetId
    ).filter((standard) => standard.code !== code);

    updateModelProductStandardsEditState(assetId, newProductStandards);
    dispatch(
      updateStandardValues(
        standardValue.filter((standard) => standard !== code)
      )
    );
  };

  const selectedProductStandardTemplate = (assetId, productStandard) => {
    let selectedItemsCount = getModelProductStandardsEditState(assetId).length;

    if (selectedItemsCount === 0) {
      return (
        <span className={classes["product-standard__placeholder"]}>
          -- Select Product Standard(s) --
        </span>
      );
    }

    return (
      <div className={classes["product-standard--selected"]}>
        <label>{productStandard?.name}</label>

        {productStandard?.name && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleProductStandardRemove(assetId, productStandard.code);
            }}
          >
            {isModelSelected(assetId) && <FontAwesomeIcon icon={faClose} />}
          </button>
        )}
      </div>
    );
  };

  function modelNameTemplate(model) {
    if (model.isForBatchEdit) {
      return (
        <div className={classes["batch-edit-row-model-name"]}>
          Note: Selected Product Standards in this row will apply <br></br>
          to all selected models.
        </div>
      );
    }

    return model.modelName;
  }

  function modelProductStandardsTemplate(model) {
    if (model.loadingProductStandards) {
      return (
        <div className={`${classes["product-standards-container--loading"]}`}>
          <FontAwesomeIcon icon={faSpinner} spin={true} />
        </div>
      );
    }

    if (model.isEditMode || isBatchEditMode) {
      const newProductStandardOptions = uniqBy(
        [...model.productStandards, ...certProductStandardOptions],
        (standard) => standard.code
      );

      const isProductStandardsSelectionDisabled = isBatchEditMode
        ? !model.isForBatchEdit
        : !isModelSelected(model.assetId);

      return (
        <div
          className={`${classes["product-standards-container"]}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MultiSelect
            disabled={isProductStandardsSelectionDisabled}
            selectedItemTemplate={(code) => {
              const selectedProductStandard = newProductStandardOptions.find(
                (standard) => standard.code === code
              );

              return selectedProductStandardTemplate(
                model.assetId,
                selectedProductStandard
              );
            }}
            onChange={(e) => {
              handleModelProductStandardsEditChange(
                e,
                newProductStandardOptions,
                model.assetId
              );
            }}
            value={getModelProductStandardsEditState(model.assetId).map(
              (standard) => standard.code
            )}
            filter={true}
            optionLabel="name"
            optionValue="code"
            options={newProductStandardOptions}
            placeholder="-- Select Product Standard(s) --"
            className={`${classes["standards-dropdown"]}`}
          />
        </div>
      );
    }

    const productStandardsTooltip = model.productStandards
      ?.map((productStandard) => productStandard.name)
      .join(" \n");

    const maxDisplay = 5;
    const sortedProductStandards =
      sortList(model.productStandards || [], "name", 1) || [];
    const topSortedProductStandards = [...sortedProductStandards].slice(
      0,
      maxDisplay
    );

    return (
      <div
        className={`${classes["product-standards-container"]}`}
        title={productStandardsTooltip}
      >
        {topSortedProductStandards.map((productStandard) => (
          <div key={productStandard.code}>{productStandard.name}</div>
        ))}
      </div>
    );
  }

  const handleEditHeaderClick = () => {
    const selectedModelsProductStandards = flatten(
      selectedModels.map((x) => x.productStandards)
    );

    const uniqueSelectedProductStandards = uniqBy(
      selectedModelsProductStandards,
      (standard) => standard.code
    );

    updateModelProductStandardsEditState(
      undefined,
      uniqueSelectedProductStandards
    );

    modelList.forEach((model) =>
      updateModelProductStandardsEditState(
        model.assetId,
        model.productStandards
      )
    );
    setIsBatchEditMode(true);

    setStandardList(standardOptions);
    dispatch(updateStandardOptions(standardOptionsClone));
  };

  const isModelProductStandardsEditable = isEditCert || isModifyCert;

  const renderModelsActionsHeader = () => {
    const isSelectedMoreThan1 = selectedModels?.length > 1;
    const isBatchActionsDisabled = !isSelectedMoreThan1 || hasRowEditMode;

    return (
      <div
        className={`${classes["actions-container"]} ${classes["actions-container--header"]}`}
      >
        {isModelProductStandardsEditable && (
          <button
            title="Edit Selected"
            disabled={isBatchActionsDisabled}
            onClick={handleEditHeaderClick}
            className={classes["action-button"]}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
        )}

        <button
          disabled={isBatchActionsDisabled}
          className={classes["action-button"]}
          onClick={onClickDeleteModelHeader}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    );
  };

  const handleBulkSaveRowsEdit = () => {
    if (modelList.length !== selectedModels.length) {
      setBulkSaveConfirmationDialogVisible(true);
      setPendingBulkSaveEditModesConfirmation(models);
      return;
    }

    handleSaveRowsEdit(selectedModels);
  };

  const handleBulkSaveConfirmationConfirm = () => {
    setBulkSaveConfirmationDialogVisible(false);
    handleSaveRowsEdit(pendingBulkSaveEditModesConfirmation);
  };

  const handleBulkSaveConfirmationNoCancel = () => {
    setBulkSaveConfirmationDialogVisible(false);
  };

  const handleCloseRowEditMode = (models) => {
    setCloseRowEditModeConfirmationDialogVisible(true);
    setPendingCloseRowsEditModesConfirmation(models);
  };

  const handleSaveRowsEdit = (models) => {
    models.forEach((model) => {
      setEditMode(false, model);

      if (!isModelSelected(model.assetId)) return;

      const newProductStandards = getModelProductStandardsEditState(
        isBatchEditMode ? undefined : model.assetId
      );

      setModelList((previousList) => {
        const newModelList = previousList.map((prevModel) => ({
          ...prevModel,
          productStandards:
            prevModel.assetId === model.assetId
              ? newProductStandards
              : prevModel.productStandards,
        }));

        const newCertificateProductStandards = props.isCertificateHaveStandard
          ? uniqBy(
              newModelList.flatMap((model) => model.productStandards || []),
              "code"
            )
          : null;

        setFormValues((prevState) => {
          const standards = props.isCertificateHaveStandard
            ? newCertificateProductStandards.map((standard) => standard.code)
            : null;

          const newState = {
            ...prevState,
            standard: standards,
          };

          dispatch(updateCertDetailsFormValues(newState));
          return newState;
        });

        setFormValidityMessages((prevState) => {
          let newState = JSON.parse(JSON.stringify(prevState));
          newState.standard = "";
          return newState;
        });

        const newCertProductStandardOptions = uniqBy(
          [
            ...certProductStandardOptions,
            ...(newCertificateProductStandards ?? []),
          ],
          (option) => option.code
        );

        dispatch(
          updateStandardValues(
            newCertificateProductStandards?.map((standard) => standard.code)
          )
        );
        dispatch(updateStandardOptions(newCertProductStandardOptions));

        return newModelList;
      });
    });

    unselectModels(models);
    setPendingBulkSaveEditModesConfirmation([]);
    setIsBatchEditMode(false);
  };

  const actionsRowTemplate = (model) => {
    const isEditModeDisabled =
      !isModelSelected(model.assetId) && !model.isForBatchEdit;

    const isSaveProductStandardsDisabled =
      model.isForBatchEdit && selectedModels.length === 0;

    return (
      <div className={classes["actions-container"]}>
        {model.isEditMode && (
          <>
            <button
              title="Save"
              onClick={(e) => onProductStandardRowEditClick(e, model, false)}
              className={`${classes["action-button"]} ${
                classes["btn-edit-mode--check"]
              } ${
                isEditModeDisabled || isSaveProductStandardsDisabled
                  ? classes["btn-edit-mode--check-disabled"]
                  : ""
              }`}
            >
              <FontAwesomeIcon size="lg" icon={faCheck} />
            </button>

            <button
              title="Cancel"
              onClick={(e) => onProductStandardRowEditClick(e, model, true)}
              className={`${classes["action-button"]} ${
                classes["btn-edit-mode--close"]
              } ${
                isEditModeDisabled
                  ? classes["btn-edit-mode--check-disabled"]
                  : ""
              }`}
            >
              <FontAwesomeIcon size="lg" icon={faClose} />
            </button>
          </>
        )}

        {!model.isEditMode &&
          isModelProductStandardsEditable &&
          !isBatchEditMode && (
            <button
              title="Edit Product Standard"
              onClick={(e) => {
                e.stopPropagation();
                setEditMode(true, model);
              }}
              disabled={selectedModels?.length > 1}
              className={classes["action-button"]}
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          )}

        {!model.isForBatchEdit && !isBatchEditMode && !model.isEditMode && (
          <button
            title="Delete Model"
            className={classes["action-button"]}
            disabled={selectedModels?.length > 1}
            onClick={() => onClickDeleteModelRow(model.assetId)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        )}
      </div>
    );
  };

  const hasModel = modelList?.length > 0;

  const onProductStandardRowEditClick = (event, model, isClose) => {
    event.stopPropagation();

    if (!isModelSelected(model.assetId) && !model.isForBatchEdit) return;

    if (isClose) {
      handleCloseRowEditMode([model]);
      return;
    }

    if (model.isForBatchEdit) {
      handleBulkSaveRowsEdit();
      return;
    }

    handleSaveRowsEdit([model]);
  };

  const handleCloseRowEditModeConfirmationConfirm = () => {
    setCloseRowEditModeConfirmationDialogVisible(false);

    pendingCloseRowsEditModesConfirmation.forEach((row) => {
      setEditMode(false, row);
    });

    dispatch(updateStandardOptions(standardList));
    unselectModels(pendingCloseRowsEditModesConfirmation);
    setIsBatchEditMode(false);
  };

  const unselectModels = (models) => {
    setSelectedModels((prevSelectedModels) =>
      prevSelectedModels.filter(
        (selectedModel) =>
          !models.map((model) => model.assetId).includes(selectedModel.assetId)
      )
    );
  };

  const handleCloseRowEditModeConfirmationNoCancel = () => {
    setCloseRowEditModeConfirmationDialogVisible(false);
  };

  const isModelSelected = (assetId) => {
    if (selectedModels.length === 0) return;

    return selectedModels.some(
      (selectedModel) => selectedModel.assetId === assetId
    );
  };

  const isModelSelectable = (model) => {
    return !model.data.isForBatchEdit;
  };

  const frozenRows = isBatchEditMode ? [batchEditRowData] : [];

  return (
    <>
      <Toast ref={toast} />
      <CloseRowEditModeConfirmationDialog
        onCancel={handleCloseRowEditModeConfirmationNoCancel}
        onConfirm={handleCloseRowEditModeConfirmationConfirm}
        visible={closeRowEditModeConfirmationDialogVisible}
      />

      <BulkSaveConfirmationDialog
        selectedModelsCount={selectedModelsCount}
        onCancel={handleBulkSaveConfirmationNoCancel}
        onConfirm={handleBulkSaveConfirmationConfirm}
        visible={bulkSaveConfirmationDialogVisible}
      />

      <ModelSearch
        visible={modelSearchVisible}
        onClose={() => setModelSearchVisible(false)}
        onSelectedModelsApply={handleSelectedModelsApply}
        partySiteNumber={
          hasModel
            ? modelList[0].taxonomy.find(
                (m) => m.dataParamName === "ownerReference_PartySiteID"
              ).value
            : modelDetailValues?.partySiteNumber
        }
        productType={
          hasModel ? modelList[0].productType : modelDetailValues?.productType
        }
        disablePsn={hasModel}
        disableProductType={hasModel}
        preSelectedModels={modelList}
        certificateId={certificateId}
      />

      {isSavingCertificate && (
        <div className="loading-cert-attr-div">
          <div>
            <div className="fetching-attr-load-label">
              Saving certificate...
            </div>
            <div>
              <svg className="ul-progressCircle -medium">
                <circle
                  className="ul-progressCircle__outer"
                  cx="50%"
                  cy="50%"
                  r="35%"
                />
                <circle
                  className="ul-progressCircle__inner"
                  cx="50%"
                  cy="50%"
                  r="35%"
                />
              </svg>
            </div>
          </div>
        </div>
      )}

      <div
        className={
          isSavingCertificate
            ? "display-none"
            : classes["add-new-cert-prod-details-modal"]
        }
      >
        <div className={classes["add-new-cert-prod-details-header"]}>
          <span>Click "Select Model" button to add models&nbsp;&nbsp;</span>
          <span>
            <button
              disabled={hasRowEditMode}
              className={`ul-button -app-tertiary -medium ${classes["select-model-btn"]}`}
              onClick={() => setModelSearchVisible(true)}
            >
              SELECT MODEL
            </button>
          </span>
        </div>

        <div>
          <div className={classes["models-table__header"]}>
            <div className={classes["new-cert-prod-dtls-col-hdr"]}>
              Current List of Models
              {selectedModelsCount > 1 &&
                ` | (${selectedModelsCount}) Models Selected`}
            </div>

            {renderModelsActionsHeader()}
          </div>

          <div
            className={`new-cert-prod-dtls-col-override ${classes["models-list-container"]}`}
          >
            <DataTable
              size="small"
              tableStyle={{ overflowY: "scroll" }}
              scrollable
              removableSort
              scrollDirection="both"
              value={modelList}
              emptyMessage="No available options"
              columnResizeMode="expand"
              responsiveLayout="scroll"
              dataKey="assetId"
              selection={selectedModels}
              isDataSelectable={isModelSelectable}
              className={classes["new-cert-prod-dtls-col-tbl"]}
              onSelectionChange={(e) => setSelectedModels(e.value)}
              rowClassName={(model) => {
                if (!model.isEditMode && hasRowEditMode && !isBatchEditMode)
                  return classes["row--disabled"];
              }}
              frozenValue={frozenRows}
            >
              <Column
                selectionMode="multiple"
                alignFrozen="left"
                frozen
                headerStyle={{
                  width: "3em",
                  pointerEvents: rowsInEditMode?.length === 1 ? "none" : "auto",
                }}
              ></Column>
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                header={<div style={{ minWidth: "98px" }}> </div>}
                style={{ width: "fit-content", paddingRight: "0.25rem" }}
                body={actionsRowTemplate}
              />
            </DataTable>
          </div>
        </div>

        <div className={`${hasRowEditMode ? classes["disabled-section"] : ""}`}>
          <div className="ul-grid__row">
            <div className="ul-grid__column">
              {FeatureFlagService.getECMFeatureFlag() && (
                <AddAttachmentsBulk
                  setAttachments={setAttachments}
                  setOutputAttachmentValues={setOutputAttachmentValues}
                  inputAttachments={inputAttachments}
                  hasAttachmentLabel={true}
                  testReportsValidatorHook={testReportsValidatorHook}
                />
              )}
              {!FeatureFlagService.getECMFeatureFlag() && <AddAttachments />}
            </div>
          </div>

          <div className="ul-grid__row">
            <div
              className={`${classes["new-cert-prod-dtls-rmrks-div"]} ul-grid__column`}
            >
              <div className={classes["new-cert-prod-dtls-col-hdr"]}>
                Remarks
              </div>
              <div className={classes["remarks-text-area-div"]}>
                <InputTextarea
                  disabled={hasRowEditMode}
                  className={classes["remarks-text-area"]}
                  value={remarks}
                  onChange={onChangeRemarks}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewCertProdDetails;
