import { useState } from "react";
import { CertificateDocumentType } from "../../../../../enums/CertificateDocumentType";
import {
  TestReportMatchedFoundFileAssociatedCertificateModel,
  TestReportMatchedFoundFileModel,
} from "../../../../../shared/models/TestReportMatchedFoundFile.model";
import CertificateListService from "../../../../../services/CertificateListService";
import { CertificateDocumentStatus } from "../../../../../enums/CertificateDocumentStatus";

interface AttachmentItem {
  id: string;
  file: {
    path: string;
  };
  name: string;
  group_index: CertificateDocumentType;
  status: number;
  isValid: boolean;
  fileResult: string;
}

interface AttachmentItem {
  id: string;
  file: {
    path: string;
  };
  name: string;
  group_index: CertificateDocumentType;
  status: number;
  isValid: boolean;
  fileResult: string;
  ecmNodeId: string | null;
  associatedCertificates: TestReportMatchedFoundFileAssociatedCertificateModel[];
}

interface TestReportsValidationResultModel {
  matchedTestReports?: TestReportMatchedFoundFileModel[];
  hasDuplicate: boolean;
}

type AttachmentsListModel = Array<Array<AttachmentItem>>;

export function useTestReportValidator() {
  const [
    testReportMatchedFoundPopupVisible,
    setTestReportMatchedFoundPopupVisible,
  ] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [matchedTestReports, setMatchedTestReports] = useState<
    TestReportMatchedFoundFileModel[]
  >([]);

  const getAttachmentsGroup = (
    attachments: AttachmentsListModel,
    fileType: CertificateDocumentType
  ) => {
    if (!attachments) return [];

    const flattenedAttachments: AttachmentItem[] = attachments.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    );

    const result = flattenedAttachments.filter(
      (attachment) =>
        (attachment.isValid || attachment.id) &&
        attachment.group_index == fileType &&
        (attachment.status ==
          CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic ||
          attachment.status == CertificateDocumentStatus.TestReportDuplicate ||
          attachment.status == CertificateDocumentStatus.NotYetUploaded)
    );

    return result;
  };

  async function validateTestReports(
    fileNames: string[],
    partySiteNumber: string,
    scheme: string,
    baseModel: string
  ): Promise<TestReportsValidationResultModel> {
    setIsValidating(true);

    const parameter = {
      scheme,
      partySiteNumber,
      baseModel,
      fileNames,
    };

    let ret: TestReportsValidationResultModel = {
      hasDuplicate: false,
      matchedTestReports: [],
    };

    await CertificateListService.getDuplicateDocuments(parameter).then(
      async (response) => {
        if (response?.isSuccess) {
          if (response.data) {
            ret.hasDuplicate = response.data.length > 0;
            ret.matchedTestReports = response.data;
          }
        }
      }
    );

    setMatchedTestReports(ret.matchedTestReports || []);
    setIsValidating(false);
    return ret;
  }

  const mapGroupedAttachmentsWithMatchedTestReports = (
    attachments: AttachmentsListModel,
    matchedTestReports: TestReportMatchedFoundFileModel[]
  ): AttachmentsListModel => {
    attachments = [...attachments];

    attachments[CertificateDocumentType.TestReport] = [
      ...attachments[CertificateDocumentType.TestReport],
    ].map((attachment) => {
      if (!attachment.isValid && !attachment.id) return attachment;

      const matchedTestReport = matchedTestReports.find(
        (file) => file.name === attachment.name
      );

      if (matchedTestReport) {
        attachment.ecmNodeId = matchedTestReport.ecmNodeId;
        attachment.status = matchedTestReport.isInEcmTic
          ? CertificateDocumentStatus.TestReportDuplicate
          : CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed;
        attachment.associatedCertificates =
          matchedTestReport.associatedCertificates;
      }

      return attachment;
    });

    return attachments;
  };

  const removeDuplicateTestReportAttachments = (
    attachments: AttachmentsListModel
  ): AttachmentsListModel => {
    var newTestReportAttachments = attachments[
      CertificateDocumentType.TestReport
    ].filter((x) => CertificateDocumentStatus.TestReportDuplicate != x.status);

    attachments[CertificateDocumentType.TestReport] = newTestReportAttachments;

    return attachments;
  };

  return {
    validateTestReports,
    testReportMatchedFoundPopupVisible,
    setTestReportMatchedFoundPopupVisible,
    mapGroupedAttachmentsWithMatchedTestReports,
    getAttachmentsGroup,
    isValidating,
    removeDuplicateTestReportAttachments,
    matchedTestReports,
  };
}
