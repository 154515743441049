import { E2EUserModel } from "../models/E2EUserModel";
import { AssignFlexHandlerModel } from "../models/flex/AssignFlexHandler.model.";
import { AssignFlexHandlerServiceBusEntry } from "../models/flex/AssignFlexHandlerStatus.model";
import { GetProjectChildLinesResultModel as GetProjectLinesResultModel } from "../models/flex/GetProjectChildLinesResult.model";
import { FlexProjectStatusOnHoldReason } from "../pages/project-management-pmr/shared/interfaces/pmr-e2e-flex-project-status-onhold-reason-model";
import { ProjectOrderlineStatusOnHoldReason } from "../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-orderline-status-model";
import {
  FlexProjectTemplateDetailModel,
  FlexProjectTemplateModel,
} from "../shared/models/FlexProjectTemplateTaskDetail.model";
import { FlexTaskCommentModel } from "../shared/models/FlexTaskDetail.model";
import { PredefinedTask } from "../shared/models/PredefinedTask.model";
import { ResultModel } from "../shared/models/service-models/Result.model";
import RequestFactory from "./RequestFactory";
import { AxiosRequestConfig, CancelToken } from "axios";

const flexIntegrationUrl = process.env.REACT_APP_GMAE2E_URL;

const FlexIntegrationService = {
  getProjectLines: async (params: any, cancelToken?: CancelToken) => {
    const requestConfig: AxiosRequestConfig = {
      cancelToken,
    };

    return (await RequestFactory.post(
      flexIntegrationUrl,
      "/api/flex-integration/get-project-lines",
      params,
      () => {
        if (cancelToken === undefined) return undefined;

        return {
          cancelToken,
        } as any;
      }
    )) as Promise<GetProjectLinesResultModel>;
  },

  assignFlexHandler: async (model: AssignFlexHandlerModel) =>
    await RequestFactory.post(
      flexIntegrationUrl,
      "/api/flex-integration/assign-flex-handler",
      model
    ),

  getProjectsLatestServiceBusEntries: async (
    projectIds: string[]
  ): Promise<ResultModel<AssignFlexHandlerServiceBusEntry[]>> =>
    await RequestFactory.post(
      flexIntegrationUrl,
      "/api/flex-integration/get-projects-latest-service-bus-entries",
      projectIds
    ),

  getProjectHandlers: async (): Promise<ResultModel<E2EUserModel[]>> =>
    await RequestFactory.get(
      flexIntegrationUrl,
      "/api/flex-integration/get-project-handlers"
    ),

  getAzureRefiners: async (
    facet: string,
    searchText: string,
    page: number,
    pageSize: number | null = null
  ): Promise<ResultModel<string>> =>
    await RequestFactory.get(
      flexIntegrationUrl,
      `/api/flex-integration/azure-get-refiners?facet=${facet}&searchText=${searchText}&page=${page}${
        pageSize ? `&pageSize=${pageSize}` : ""
      }`
    ),

  getBatchFlexTemplateDetails: async (
    flexProjectTemplateIds: string[]
  ): Promise<ResultModel<FlexProjectTemplateDetailModel[]>> =>
    await RequestFactory.post(
      flexIntegrationUrl,
      "/api/flex-integration/get-batch-flex-template-details",
      flexProjectTemplateIds
    ),
  getProjectLineOnHoldReasons: async (): Promise<
    ProjectOrderlineStatusOnHoldReason[]
  > =>
    await RequestFactory.get(
      flexIntegrationUrl,
      "/api/flex-integration/get-projectline-hold-reasons"
    ),
  getFlexProjectOnHoldReasons: async (): Promise<
    FlexProjectStatusOnHoldReason[]
  > =>
    await RequestFactory.get(
      flexIntegrationUrl,
      "/api/flex-integration/get-project-hold-reasons"
    ),
  checkIfFlexProjectIsForUpdate: async (
    model: any
  ): Promise<ResultModel<any[]>> =>
    await RequestFactory.post(
      flexIntegrationUrl,
      "/api/flex-integration/check-if-flex-project-is-for-update",
      model
    ),
  getFlexTemplateByFlexProjectIds: async (
    projectIds: string[]
  ): Promise<ResultModel<FlexProjectTemplateModel[]>> =>
    await RequestFactory.post(
      flexIntegrationUrl,
      "/api/flex-integration/get-flex-template-list",
      projectIds
    ),
  getAllPredefinedTasks: async (): Promise<ResultModel<PredefinedTask[]>> =>
    await RequestFactory.get(
      flexIntegrationUrl,
      "/api/flex-integration/get-all-predefined-tasks"
    ),
  getAllTaskComments: async (
    flexTaskId: string
  ): Promise<ResultModel<FlexTaskCommentModel[]>> =>
    await RequestFactory.get(
      flexIntegrationUrl,
      `/api/flex-integration/get-all-task-comments?flexTaskId=${flexTaskId}`
    ),
};

export default FlexIntegrationService;
