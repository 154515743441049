import { forwardRef, useEffect } from "react";
import {
  PmrRefiner,
  PmrRefinerGroup,
} from "../../shared/components/pmr-refiners/PmrRefiners";
import PmrMultiSelectRefiner, {
  onPmrMultiSelectModelChange,
  showMultiSelectRefinerByLabel,
  sortMultiSelectRefinerByLabel,
} from "../../shared/components/pmr-multiselect-refiner/PmrMultiselectRefiner";
import PmrDaterangeRefiner, {
  PmrDaterangeSelectedRefinerTemplate,
} from "../../shared/components/pmr-daterange-refiner/PmrDaterangeRefiner";
import { useFormikContext } from "formik";
import ProjectRefinersService from "../../../../services/ProjectRefinersService";
import ProjectStatus from "../../shared/enums/project-status";
import PmrCheckboxRefiner, {
  sortCheckboxExternalSrc,
} from "../../shared/components/pmr-checkbox-refiner/PmrCheckboxRefiner";
import OrderLineRefinersService from "../../../../services/OrderLineRefinersService";
import { useSelector } from "react-redux";
import { selectShowFlexTasks } from "../../../../features/projectManagementPmr/projectManagementPmrSlice";
import { authProvider } from "../../../../providers/authProvider";
import ProjectTaskStatus from "../../shared/enums/project-task-status";
import { CheckboxChangeParams } from "primereact/checkbox";
import useFormikHelper from "../../../../hooks/useFormikHelper";
import FlexProjectStatus from "../../shared/enums/flex-project-status";
import ProjectOrderlineStatus from "../../shared/enums/project-orderline-status";
import { USE_PROJECT_HANDLER_MAPPING } from "../../../../utils/constants/feature-flag.constants";

export interface OrderLineLevelRefinerProps extends PmrRefinerGroup {}

function OrderLineLevelRefiner({}: OrderLineLevelRefinerProps, ref: any) {
  const { handleChange, values, getFieldHelpers, getFieldMeta } =
    useFormikContext<any>();
  const { handleZeroEnumChange } = useFormikHelper();
  const showFlexTask = useSelector(selectShowFlexTasks);
  const userLoggedIn = authProvider.getAccount();
  const disabledField = {
    flexTaskStatuses: {
      clear: false,
      disabled: !showFlexTask.isOn,
    },
    gmawbMilestoneStatuses: {
      clear: false,
      disabled: showFlexTask.isOn,
    },
    flexTaskNames: {
      clear: true,
      disabled: !showFlexTask.isOn,
    },
    gmawbMilestoneNames: {
      clear: true,
      disabled: showFlexTask.isOn,
    },
  };

  //#region FLEX Handler Region
  const handleFlexHandlerRegionsModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await OrderLineRefinersService.getFlexHandlerRegions({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
    });

    return {
      items: result.data.records.map((item: any) => ({
        label: item.region,
      })),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region POS Countries
  const handlePOSCountriesModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await OrderLineRefinersService.getPOSCountries({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
    });

    return {
      items: result.data.records.map((item: any) => ({
        label: item.locationCountry,
      })),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region POC Countries
  const handlePOCCountriesModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await OrderLineRefinersService.getPOCCountries({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
    });

    return {
      items: result.data.records.map((item: any) => ({
        label: item.countryLocation,
        value: item.country,
      })),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region Flex Task Names
  const handleFlexTaskNamesModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await OrderLineRefinersService.getFlexTaskNames({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
      sort: "flexTaskName",
      sortBy: "asc",
    });

    return {
      items: result.data.records.map((item: any) => ({
        label: item.flexTaskName,
      })),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region Flex Projects
  const handleFlexProjectsModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await OrderLineRefinersService.getFlexProjects({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
    });

    return {
      items: result.data.records.map((item: any) => ({
        label: item.flexProjectName,
        value: item.id,
      })),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region Milestone Names
  const handleMilestoneNamesModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await OrderLineRefinersService.getMilestoneNames({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
    });

    return {
      items: result.data.records.map((item: any) => ({
        label: item.name,
        value: item.id,
      })),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region Project Numbers
  const handleProjectNumbersModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await OrderLineRefinersService.getProjectNumbers({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
    });

    return {
      items: result.data.records.map((item: any) => ({
        label: item.projectNumber,
      })),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region OrderLine Contributors
  const handleOrderLineContributorsModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await OrderLineRefinersService.getProjectContributors({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
      sort: "displayName",
      sortBy: "asc",
    });

    return {
      items: result.data.records.map((item: any) =>
        USE_PROJECT_HANDLER_MAPPING
          ? {
              label: item.displayNameWithEmail,
              value: item.username,
              displayName: item.displayName,
            }
          : {
              label: item.displayNameWithEmail,
              value: item.displayName,
            }
      ),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region OrderLine Numbers
  const handleOrderLineNumbersModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await OrderLineRefinersService.getOrderLineNumbers({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
    });

    return {
      items: result.data.records.map((item: any) => ({
        label: item.orderLineDescription,
      })),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region Order Numbers
  const handleOrderNumbersModelChange = async (
    event: onPmrMultiSelectModelChange
  ) => {
    const take = 5;
    const result = await OrderLineRefinersService.getOrderNumbers({
      skip: event.currentPage * take,
      take,
      searchString: event.filter,
    });

    return {
      items: result.data.records.map((item: any) => ({
        label: item.orderNumber,
      })),
      showMore: result.data.hasNextPage,
    };
  };
  //#endregion

  //#region Project Status
  const projectStatusOptions = [
    { label: "Completed", value: FlexProjectStatus.Completed },
    { label: "In Progress", value: FlexProjectStatus.InProgress },
    { label: "On Hold", value: FlexProjectStatus.OnHold },
  ];
  //#endregion

  //#region Task Status
  const taskStatusOptions = [
    { label: "Cancelled", value: ProjectTaskStatus.Cancelled },
    { label: "Completed", value: ProjectTaskStatus.Completed },
    { label: "In Progress", value: ProjectTaskStatus.InProgress },
    {
      label: "Not Scheduled",
      value: ProjectTaskStatus.NotScheduled,
    },
    { label: "On Hold", value: ProjectTaskStatus.OnHold },
  ];
  //#endregion

  //#region Milestone Status
  const milestoneStatusOptions = [
    { label: "Cancelled", value: ProjectStatus.Cancelled },
    { label: "Completed", value: ProjectStatus.Completed },
    { label: "In Progress", value: ProjectStatus.InProgress },
    { label: "Not Scheduled", value: ProjectStatus.NotScheduled },
    { label: "On Hold", value: ProjectStatus.OnHold },
  ];
  //#endregion

  //#region Order Line Status
  const orderLineStatusOptions = [
    { label: "Completed", value: ProjectOrderlineStatus.Completed },
    { label: "In Progress", value: ProjectOrderlineStatus.InProgress },
    { label: "On Hold", value: ProjectOrderlineStatus.OnHold },
  ];
  //#endregion

  const applyDefaultRefiners = () => {
    const milestone = {
      field: getFieldHelpers("orderLines.gmawbMilestoneStatuses"),
      meta: getFieldMeta("orderLines.gmawbMilestoneStatuses"),
    };
    const task = {
      field: getFieldHelpers("orderLines.flexTaskStatuses"),
      meta: getFieldMeta("orderLines.flexTaskStatuses"),
    };

    const milestoneValue = (milestone.meta.value ?? []) as ProjectStatus[];
    const taskValue = (task.meta.value ?? []) as ProjectTaskStatus[];

    milestone.field.setValue([]);
    task.field.setValue([]);

    if (showFlexTask.isOn && milestoneValue.length > 0) {
      task.field.setValue(
        milestoneValue.map((item) => {
          const info = milestoneStatusOptions.find(
            (option) => option.value === item
          );
          const convertedInfo = taskStatusOptions.find(
            (option) => option.label === info?.label
          );
          return convertedInfo?.value;
        })
      );
    } else if (!showFlexTask.isOn && taskValue.length > 0) {
      milestone.field.setValue(
        taskValue.map((item) => {
          const info = taskStatusOptions.find(
            (option) => option.value === item
          );
          const convertedInfo = milestoneStatusOptions.find(
            (option) => option.label === info?.label
          );
          return convertedInfo?.value;
        })
      );
    }
  };

  const clearDisableFields = () => {
    Object.entries(disabledField).forEach(([key, item]) => {
      const fieldKey = `orderLines.${key}`;
      const field = getFieldHelpers(fieldKey);
      const meta = getFieldMeta(fieldKey);
      if (item.clear) {
        field.setValue(meta.initialValue);
      }
    });
  };

  useEffect(() => {
    applyDefaultRefiners();
    clearDisableFields();
  }, [showFlexTask.isOn]);

  return (
    <span ref={ref}>
      <PmrRefiner container label="ORDER LINES" name="orderLines">
        <PmrRefiner
          label="Completion Date"
          name="completionDate"
          selectedRefinerTemplate={PmrDaterangeSelectedRefinerTemplate}
        >
          <PmrDaterangeRefiner
            name="orderLines.completionDate"
            values={values?.orderLines?.completionDate}
            onChange={handleChange}
          />
        </PmrRefiner>

        <PmrRefiner
          label="Contributors"
          name="contributors"
          selectedRefinerTemplate={(value) =>
            USE_PROJECT_HANDLER_MAPPING
              ? value.displayName +
                (userLoggedIn.userName === value.value ? " (You)" : "")
              : value.value +
                (userLoggedIn.name === value.value ? " (You)" : "")
          }
          selectedRefinerSort={sortMultiSelectRefinerByLabel}
        >
          <PmrMultiSelectRefiner
            onModelChange={handleOrderLineContributorsModelChange}
            name="orderLines.contributors"
            onChange={handleChange}
            value={values?.orderLines?.contributors}
            filterProps={{
              type: "text",
            }}
          />
        </PmrRefiner>

        <PmrRefiner
          label="Due Date"
          name="dueDate"
          selectedRefinerTemplate={PmrDaterangeSelectedRefinerTemplate}
        >
          <PmrDaterangeRefiner
            showCheckboxDate
            name="orderLines.dueDate"
            values={values?.orderLines?.dueDate}
            onChange={handleChange}
          />
        </PmrRefiner>

        <PmrRefiner
          label="FLEX Handler Region"
          name="flexHandlerRegions"
          selectedRefinerTemplate={showMultiSelectRefinerByLabel}
          selectedRefinerSort={sortMultiSelectRefinerByLabel}
        >
          <PmrMultiSelectRefiner
            onModelChange={handleFlexHandlerRegionsModelChange}
            name="orderLines.flexHandlerRegions"
            onChange={handleChange}
            value={values?.orderLines?.flexHandlerRegions}
            filterProps={{
              type: "text",
            }}
          />
        </PmrRefiner>

        <PmrRefiner
          label="FLEX Project"
          name="flexProjects"
          selectedRefinerTemplate={showMultiSelectRefinerByLabel}
          selectedRefinerSort={sortMultiSelectRefinerByLabel}
        >
          <PmrMultiSelectRefiner
            onModelChange={handleFlexProjectsModelChange}
            name="orderLines.flexProjects"
            onChange={handleChange}
            value={values?.orderLines?.flexProjects}
            filterProps={{
              type: "text",
            }}
          />
        </PmrRefiner>

        <PmrRefiner
          label="FLEX Project ECD"
          name="flexProjectECD"
          selectedRefinerTemplate={PmrDaterangeSelectedRefinerTemplate}
        >
          <PmrDaterangeRefiner
            showCheckboxDate
            name="orderLines.flexProjectECD"
            values={values?.orderLines?.flexProjectECD}
            onChange={handleChange}
          />
        </PmrRefiner>

        <PmrRefiner
          label="FLEX Project Status"
          name="flexProjectStatuses"
          selectedRefinerTemplate={(value) =>
            projectStatusOptions.find((option) => option.value === value)?.label
          }
          selectedRefinerSort={(first, second) =>
            sortCheckboxExternalSrc(first, second, projectStatusOptions)
          }
        >
          <PmrCheckboxRefiner
            items={projectStatusOptions}
            name="orderLines.flexProjectStatuses"
            value={values?.orderLines?.flexProjectStatuses}
            onChange={handleChange}
            onSelectAll={(value) =>
              getFieldHelpers("orderLines.flexProjectStatuses").setValue(value)
            }
          />
        </PmrRefiner>

        <PmrRefiner
          label="FLEX Task Name"
          name="flexTaskNames"
          disabled={disabledField.flexTaskNames.disabled}
          selectedRefinerTemplate={showMultiSelectRefinerByLabel}
          selectedRefinerSort={sortMultiSelectRefinerByLabel}
        >
          <PmrMultiSelectRefiner
            onModelChange={handleFlexTaskNamesModelChange}
            name="orderLines.flexTaskNames"
            onChange={handleChange}
            value={values?.orderLines?.flexTaskNames}
            filterProps={{
              type: "text",
            }}
          />
        </PmrRefiner>

        <PmrRefiner
          label="FLEX Task Status"
          name="flexTaskStatuses"
          disabled={disabledField.flexTaskStatuses.disabled}
          selectedRefinerTemplate={(value) =>
            taskStatusOptions.find((option) => option.value === value)?.label
          }
          selectedRefinerSort={(first, second) =>
            sortCheckboxExternalSrc(first, second, taskStatusOptions)
          }
        >
          <PmrCheckboxRefiner
            items={taskStatusOptions}
            name="orderLines.flexTaskStatuses"
            value={values?.orderLines?.flexTaskStatuses}
            onChange={handleZeroEnumChange}
            onSelectAll={(value) =>
              getFieldHelpers("orderLines.flexTaskStatuses").setValue(value)
            }
          />
        </PmrRefiner>

        <PmrRefiner
          label="GMAWB Milestone Name"
          name="gmawbMileStoneNames"
          disabled={disabledField.gmawbMilestoneNames.disabled}
          selectedRefinerTemplate={showMultiSelectRefinerByLabel}
          selectedRefinerSort={sortMultiSelectRefinerByLabel}
        >
          <PmrMultiSelectRefiner
            onModelChange={handleMilestoneNamesModelChange}
            name="orderLines.gmawbMileStoneNames"
            onChange={handleChange}
            value={values?.orderLines?.gmawbMileStoneNames}
            filterProps={{
              type: "text",
            }}
          />
        </PmrRefiner>

        <PmrRefiner
          label="GMAWB Milestone Status"
          name="gmawbMilestoneStatuses"
          disabled={disabledField.gmawbMilestoneStatuses.disabled}
          selectedRefinerTemplate={(value) =>
            milestoneStatusOptions.find((option) => option.value === value)
              ?.label
          }
          selectedRefinerSort={(first, second) =>
            sortCheckboxExternalSrc(first, second, milestoneStatusOptions)
          }
        >
          <PmrCheckboxRefiner
            items={milestoneStatusOptions}
            name="orderLines.gmawbMilestoneStatuses"
            value={values?.orderLines?.gmawbMilestoneStatuses}
            onChange={handleZeroEnumChange}
            onSelectAll={(value) =>
              getFieldHelpers("orderLines.gmawbMilestoneStatuses").setValue(
                value
              )
            }
          />
        </PmrRefiner>

        <PmrRefiner
          label="Order Booked Date"
          name="orderBookedDate"
          selectedRefinerTemplate={PmrDaterangeSelectedRefinerTemplate}
        >
          <PmrDaterangeRefiner
            name="orderLines.orderBookedDate"
            values={values?.orderLines?.orderBookedDate}
            onChange={handleChange}
          />
        </PmrRefiner>

        <PmrRefiner
          label="Order Line ECD"
          name="orderlineECD"
          selectedRefinerTemplate={PmrDaterangeSelectedRefinerTemplate}
        >
          <PmrDaterangeRefiner
            showCheckboxDate
            name="orderLines.orderlineECD"
            values={values?.orderLines?.orderlineECD}
            onChange={handleChange}
          />
        </PmrRefiner>

        <PmrRefiner
          label="Order Line Number"
          name="orderLineDescriptions"
          selectedRefinerTemplate={showMultiSelectRefinerByLabel}
          selectedRefinerSort={sortMultiSelectRefinerByLabel}
        >
          <PmrMultiSelectRefiner
            onModelChange={handleOrderLineNumbersModelChange}
            name="orderLines.orderLineDescriptions"
            onChange={handleChange}
            value={values?.orderLines?.orderLineDescriptions}
            filterProps={{
              type: "text",
            }}
          />
        </PmrRefiner>

        <PmrRefiner
          label="Order Line Status"
          name="orderlineStatuses"
          selectedRefinerTemplate={(value) =>
            orderLineStatusOptions.find((option) => option.value === value)
              ?.label
          }
          selectedRefinerSort={(first, second) =>
            sortCheckboxExternalSrc(first, second, orderLineStatusOptions)
          }
        >
          <PmrCheckboxRefiner
            items={orderLineStatusOptions}
            name="orderLines.orderlineStatuses"
            value={values?.orderLines?.orderlineStatuses}
            onChange={handleChange}
            onSelectAll={(value) =>
              getFieldHelpers("orderLines.orderlineStatuses").setValue(value)
            }
          />
        </PmrRefiner>

        <PmrRefiner
          label="Order Number"
          name="orderNumbers"
          selectedRefinerTemplate={showMultiSelectRefinerByLabel}
          selectedRefinerSort={(first, second) =>
            parseInt(first.label) - parseInt(second.label)
          }
        >
          <PmrMultiSelectRefiner
            onModelChange={handleOrderNumbersModelChange}
            name="orderLines.orderNumbers"
            onChange={handleChange}
            value={values?.orderLines?.orderNumbers}
            filterProps={{
              type: "number",
            }}
          />
        </PmrRefiner>

        <PmrRefiner
          label="PoC Country"
          name="pocCountries"
          selectedRefinerTemplate={showMultiSelectRefinerByLabel}
          selectedRefinerSort={sortMultiSelectRefinerByLabel}
        >
          <PmrMultiSelectRefiner
            onModelChange={handlePOCCountriesModelChange}
            name="orderLines.pocCountries"
            onChange={handleChange}
            value={values?.orderLines?.pocCountries}
            filterProps={{
              type: "text",
            }}
          />
        </PmrRefiner>

        <PmrRefiner
          label="Point-of-Sale"
          name="pointOfSales"
          selectedRefinerTemplate={showMultiSelectRefinerByLabel}
          selectedRefinerSort={sortMultiSelectRefinerByLabel}
        >
          <PmrMultiSelectRefiner
            onModelChange={handlePOSCountriesModelChange}
            name="orderLines.pointOfSales"
            onChange={handleChange}
            value={values?.orderLines?.pointOfSales}
            filterProps={{
              type: "text",
            }}
          />
        </PmrRefiner>

        <PmrRefiner
          label="Project Number"
          name="flexProjectNumbers"
          selectedRefinerTemplate={showMultiSelectRefinerByLabel}
          selectedRefinerSort={(first, second) =>
            parseInt(first.label) - parseInt(second.label)
          }
        >
          <PmrMultiSelectRefiner
            onModelChange={handleProjectNumbersModelChange}
            name="orderLines.flexProjectNumbers"
            onChange={handleChange}
            value={values?.orderLines?.flexProjectNumbers}
            filterProps={{
              type: "number",
            }}
          />
        </PmrRefiner>

        <PmrRefiner
          label="Start Date"
          name="startDate"
          selectedRefinerTemplate={PmrDaterangeSelectedRefinerTemplate}
        >
          <PmrDaterangeRefiner
            name="orderLines.startDate"
            values={values?.orderLines?.startDate}
            onChange={handleChange}
          />
        </PmrRefiner>
      </PmrRefiner>
    </span>
  );
}

export default forwardRef(OrderLineLevelRefiner);
