import {
  faClose,
  faExclamationCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FILE_STATUS } from "../../components/file-uploader/FileUploader";
import ValidationStatus from "../../enums/ValidationStatus";
import {
  customerInformationInitialState,
  selectApplicantInformation,
  selectCustomerLinkId,
  selectGeneralProductInformation,
  selectIsGiFormEditable,
  selectIsLoading,
  selectIsReadOnly,
  selectIsSaveDisabled,
  selectIsShowValidationBanner,
  selectProductType,
  selectValidationBannerStatusType,
  updateAgentInformation,
  updateApplicantInformation,
  updateCompanyDetails,
  updateCustomerLinkId,
  updateFactorySiteInformation,
  updateFinishedByCustomerEmail,
  updateFormName,
  updateGPICustomerInputValidationResult,
  updateGPIFormConfig,
  updateGeneralProductInfoId,
  updateHasFinalizedByCustomer,
  updateIsGiFormEditable,
  updateIsLoading,
  updateIsModelsModified,
  updateIsReadOnly,
  updateIsRegisterAllFactory,
  updateIsSaveDisabled,
  updateIsShowValidationBanner,
  updateLocalRepImporters,
  updateManufacturerInformation,
  updateProductDetails,
  updateProductTypeOptions,
  updateULFileNumber,
  updateULProjectHandleDetails,
  updateValidationBannerStatusType,
  updateWorkflowPackageId,
} from "../../features/generalProductInformation/generalProductInformationSlice";
import { useSignalR } from "../../hooks/useSignalR";
import useToastr from "../../hooks/useToastr";
import { authProvider } from "../../providers/authProvider";
import CustomerService from "../../services/CustomerService";
import GeneralProductInformationService from "../../services/GeneralProductInformationService";
import GpiAuthService from "../../services/GpiAuthService";
import ProductTypeService from "../../services/ProductTypeService";
import LoadingIndicator from "../../shared/loading-indicator/LoadingIndicator";
import classes from "./GeneralProductInformation.module.scss";
import CompanyDetails from "./company-details/CompanyDetails";
import companyDetailsObjectSchema from "./company-details/company-details-schema";
import FACTORY_SITE_INFO_SOURCE from "./company-details/factory-site-information/FACTORY_SITE_INFO_SOURCE";
import CompleteGpiFormConfirmationDialog from "./complete-gpi-form-confirmation-dialog/CompleteGpiFormConfirmationDialog";
import ConfirmEmailAddressOnGpiCompleteDialog from "./confirm-email-address-on-gpi-complete/ConfirmEmailAddressOnGpiCompleteDialog";
import FooterActions from "./footer-actions/FooterActions";
import GpiFormNameModal from "./gpi-form-name-modal/GpiFormNameModal";
import GpiValidationResultDialog from "./gpi-validation-result-dialog.jsx/GpiValidationResultDialog";
import LocalRepImporter from "./local-rep-importer/LocalRepImporter";
import representativeDetalsObjectSchema from "./local-rep-importer/local-rep-importer-schema";
import ProductDetails from "./product-details/ProductDetails";
import productDetailsSchema from "./product-details/product-details-schema";
import SplashPage from "./splash-page/SplashPage";
import StepMenus from "./step-menus/StepMenus";
import STEP_STATUS from "./step-status";
import ULProjectHandler from "./ul-project-handler/UlProjectHandler";
import ulProjectHandlerSchema from "./ul-project-handler/ul-project-handler-schema";
import * as _ from 'lodash';

export const invalidEmailAddErrorMsg =
  "Invalid email address. Please enter a valid email address.";
export const blankEmailAddErrorMsg =
  "Email address field is blank. Please enter a valid email address.";

const GeneralProductInformation = ({ isCustomerView }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const generalProductInfoId = queryParams.get("id");
  const isCopy = queryParams.get("isCopy") === "true";
  const giMappingIds = queryParams.get("giMappingIds")?.split(",") || [];
  const generalProductInformationValues = useSelector(
    selectGeneralProductInformation
  );
  const applicantInformationValues = useSelector(selectApplicantInformation);
  const productTypeValues = useSelector(selectProductType);
  const [splashPageVisible, setSplashPageVisible] = useState(
    !generalProductInfoId
  );
  const [isShowDialog, setShowDialog] = useState(false);
  const stepIndexQueryParameter = +queryParams.get("stepIndex");
  const [activeStepIndex, setActiveStepIndex] = useState(
    stepIndexQueryParameter > -1 ? stepIndexQueryParameter : 0
  );

  const [steps, setSteps] = useState([
    {
      label: "Company Details",
      status: STEP_STATUS.DEFAULT,
      schema: companyDetailsObjectSchema,
      field: "companyDetails",
    },
    {
      label: "Product Details",
      status: STEP_STATUS.DEFAULT,
      schema: productDetailsSchema,
      field: "productDetails",
    },
    {
      label: "Local Rep Importer",
      schema: representativeDetalsObjectSchema,
      status: STEP_STATUS.DEFAULT,
      field: "localRepImporterInformation",
    },
    ...(!isCustomerView
      ? [
        {
          label: "UL Project Handler",
          schema: ulProjectHandlerSchema,
          status: STEP_STATUS.DEFAULT,
          field: "ulProjectHandlerDetails",
        },
      ]
      : []),
  ]);
  const { showSuccess, showError } = useToastr();
  const isLoading = useSelector(selectIsLoading);
  const [gpiFormNameModalVisible, setGpiFormNameModalVisible] = useState(false);
  const [gpiValidationResultModalVisible, setGpiValidationResultModalVisible] =
    useState(false);
  const workflowPackageIdParam = queryParams.get("workflowPackageId");
  const shouldValidateParam = queryParams.get("shouldValidate") !== "false";
  const [savedGPIFormData, setSavedGPIFormData] = useState(null);
  const isReadOnly = useSelector(selectIsReadOnly);
  const isSaveDisabled = useSelector(selectIsSaveDisabled);
  const [
    isCompleteGpiFormConfirmationDialogVisible,
    setIsCompleteGpiFormConfirmationDialogVisible,
  ] = useState(false);
  const [
    isConfirmEmailAddressOnGpiCompleteDialogVisible,
    setIsConfirmEmailAddressOnGpiCompleteDialogVisible,
  ] = useState(false);
  const [customerEmailAddress, setCustomerEmailAddress] = useState("");
  const [isShowCustomerEmailErrorMsg, setIsShowCustomerEmailErrorMsg] =
    useState(false);
  const [customerEmailErrorMsg, setCustomerEmailErrorMsg] = useState("");
  const isGiFormEditable = useSelector(selectIsGiFormEditable);
  const linkId = useSelector(selectCustomerLinkId);
  const activeStep = steps[activeStepIndex];
  const activeStepErrorType = activeStep.hasBlankRequiredField
    ? "warning"
    : "danger";
  const [GPIFormName, setGPIFormName] = useState(null);
  const [localRepSchemesList, setLocalRepSchemesList] = useState([]);
  const isGPIFormLoaded = useRef();
  const hubUrl = process.env.REACT_APP_GMAE2E_FUNC_URL + "/api";
  const { connection: signalRConnection, error: signalRConnectionError } =
    useSignalR(hubUrl);
  const hasFetchedGeneralProductInfo = useRef();
  const [hasJoinedToValidationGroup, setHasJoinedToValidationGroup] =
    useState(null);

  const validationBannerStatusType = useSelector(
    selectValidationBannerStatusType
  );
  const showValidationBanner = useSelector(selectIsShowValidationBanner);
  const [formSaveMessage, setFormSaveMessage] = useState("");

  useEffect(() => {
    dispatch(
      updateIsShowValidationBanner(
        shouldValidateParam && !isCustomerView && generalProductInfoId != null
      )
    );

    if (signalRConnection && !isCustomerView && generalProductInfoId != null) {
      GeneralProductInformationService.joinGpiValidationGroup([
        generalProductInfoId,
      ])
        .then(() => {
          setHasJoinedToValidationGroup(true);
        })
        .catch(() => {
          setHasJoinedToValidationGroup(false);
        });

      signalRConnection.on("gpi-validation-start", (result) => {
        const fcnResult = JSON.parse(result);
        if (fcnResult.data?.generalProductInfoId == generalProductInfoId) {
          dispatch(updateIsShowValidationBanner(true));
          dispatch(updateValidationBannerStatusType("info"));
        }
      });

      signalRConnection.on("company-details-validation", (result) => {
        const fcnResult = JSON.parse(result);
        if (fcnResult.data?.generalProductInfoId == generalProductInfoId) {
          if (fcnResult.isSuccess) {
            dispatch(updateIsShowValidationBanner(false));
            showSuccess(
              "Success!",
              "GPI Form validation has been completed successfully."
            );
          } else {
            dispatch(updateValidationBannerStatusType("warning"));
          }
          dispatch(updateGPICustomerInputValidationResult(fcnResult.data));
        }
      });

      signalRConnection.on("gpi-validation", (result) => {
        const fcnResult = JSON.parse(result);
        if (fcnResult.data?.generalProductInfoId == generalProductInfoId) {
          if (fcnResult.isSuccess) {
            dispatch(updateIsShowValidationBanner(false));
            showSuccess(
              "Success!",
              "GPI Form validation has been completed successfully."
            );
          } else {
            dispatch(updateValidationBannerStatusType("warning"));
          }
          dispatch(updateGPICustomerInputValidationResult(fcnResult.data));
        }
      });
    }
  }, [signalRConnection]);

  const populateGenInfo = async () => {
    hasFetchedGeneralProductInfo.current = true;
    const getGPIResult = await getGeneralProductInfo();
    handleGPIFormName(getGPIResult);
    autoPopulateSavedGPIForm(getGPIResult);
    isGPIFormLoaded.current = true;

    if (!isCustomerView && !isCopy && shouldValidateParam) {
      if (workflowPackageIdParam) {
        await validateCustomerInputGpi(getGPIResult);
      } else {
        await validateCustomerCompanyDetailInputGpi(getGPIResult);
      }
    }

    dispatch(updateIsLoading(false));
  };

  useEffect(() => {
    if (
      (hasJoinedToValidationGroup !== null ||
        signalRConnectionError ||
        isCustomerView) &&
      generalProductInfoId &&
      !hasFetchedGeneralProductInfo.current
    ) {
      populateGenInfo();
    }
  }, [
    hasJoinedToValidationGroup,
    signalRConnection,
    signalRConnectionError,
    isCustomerView,
  ]);

  useEffect(() => {
    updateActiveStepErrorNavigatorState();
  }, [generalProductInformationValues]);

  useEffect(() => {
    loadGPIFormConfig();

    if (!isCustomerView) populateProductTypeOptions();
  }, []);

  useEffect(() => {
    if (isGPIFormLoaded?.current === true) {
      dispatch(updateIsModelsModified(true));
    }
  }, [
    productTypeValues?.baseModel?.modelName,
    productTypeValues?.modelVariants,
    dispatch,
  ]);

  useEffect(() => {
    if (generalProductInfoId) {
      dispatch(updateIsLoading(true));
    }

    if (!generalProductInfoId || isCopy) {
      const populateUlProjectHandlerFields = async () => {
        var result =
          await GeneralProductInformationService.ulProjectHandlerDetails();

        if (result?.isSuccess) {
          dispatch(
            updateULProjectHandleDetails({
              officeName: result.data.companyName
                ? result.data.companyName
                : "",
              officeAddress: result.data.streetAddress
                ? result.data.streetAddress
                : "",
              emailAddress: result.data.mail ? result.data.mail : "",
              contactNumber: result.data.mobilePhone
                ? result.data.mobilePhone
                : "",
              officeFaxNumber: result.data.faxNumber
                ? result.data.faxNumber
                : "",
              faxNumber: result.data.faxNumber ? result.data.faxNumber : "",
              telephoneNumber: result.data.mobilePhone
                ? result.data.mobilePhone
                : "",
              name: result.data.displayName ? result.data.displayName : "",
              country: result.data.country ? result.data.country : "",
            })
          );
        }
      };

      populateUlProjectHandlerFields();
    }

    if (queryParams.get("applicantPsn")) {
      retrieveApplicantInfo(queryParams.get("applicantPsn"));
    }

    if (queryParams.get("manufacturerPsn")) {
      retrieveManufacturerInfo(queryParams.get("manufacturerPsn"));
    }

    if (!isCustomerView) loadLocalRepSchemesList();
  }, []);

  useEffect(() => {
    if (isCopy) dispatch(updateIsSaveDisabled(false));

    document.addEventListener("keypress", function (e) {
      if (isReadOnly === false && e.key !== "Enter" && e.keyCode !== 32) {
        dispatch(updateIsSaveDisabled(false));
      }
    });

    document.body.addEventListener(
      "keydown",
      function (e) {
        e = e || window.event;
        var key = e.which || e.keyCode;
        var ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false;

        //Copy paste.
        if (key == 86 && ctrl) {
          dispatch(updateIsSaveDisabled(false));
        }
      },
      false
    );
  }, []);

  const populateProductTypeOptions = async () => {
    const productTypeDefaultOptions = [
      "Automotive Wireless Components",
      "AV, ITE, and AVICT Equipment",
      "Battery Packs and Chargers",
      "Inspection and Measuring Equipment",
      "Laboratory Equipment",
      "Medical Equipment",
      "Nonelectrical Wireless Consumer Products",
      "Patient Monitoring Equipment",
      "Power Supplies for AV, ITE, and AVICT Equipment",
      "Power Supplies for Medical Equipment",
      "Primary (non-rechargeable) Battery Cells",
      "Rechargeable Battery Cells",
      "Wireless Modules",
    ];

    const result = await ProductTypeService.getIpProductType();

    if (result?.isSuccess) {
      const newProductTypeOptions = [
        {
          label: "Frequently Used",
          items: result.data.filter((x) =>
            productTypeDefaultOptions.includes(x.hierarchyValue)
          ),
        },
        {
          label: "All Other Product Types",
          items: result.data.filter(
            (x) => !productTypeDefaultOptions.includes(x.hierarchyValue)
          ),
        },
      ];

      dispatch(updateProductTypeOptions(newProductTypeOptions));
    }
  };

  const loadGPIFormConfig = async () => {
    const config = await GeneralProductInformationService.getGPIFormConfig();

    dispatch(updateGPIFormConfig(config));
  };

  // We needed to divide getting GPI into multiple requests as
  // when making it into only one big request slows down the
  // operation, adjust retrieveEntities as needed.  
  const getGeneralProductInfo = async () => {
    const retrieveEntities = [
      [
        "applicantInformation",
        "agentInformation",
        "manufacturerInformation",
        "existingCertificates",
        "factorySiteInformation",
        "customerLinkInformation",
      ],
      ["productDetails", "radioDetails", "localRepImporter"],
    ];

    let result = {
      id: null,
      hasFinalizedByCustomer: false,
      hasBeenModifiedByCustomer: true,
      finishedByCustomerEmail: null,
      isReadyForTranscription: false,
      createdBy: null,
      modifiedBy: null,
      gPIFormName: null,
      workflowPackageId: null,
      gPIApplicantInfoId: null,
      gPIAgentInfoId: null,
      gPIManufacturerInfoId: null,
      gPIULFileNumberId: null,
      gPIProductTypeId: null,
      gPIVoltageDetailsId: null,
      gPIRadioDetailsId: null,
      gPIHandlerInfoId: null,
      applicantInfo: null,
      agentInfo: null,
      manufacturerInfo: null,
      gPIULFileNumber: null,
      factories: [],
      handlerInfo: null,
      gPIProductType: null,
      gPIVoltageDetails: null,
      gPIRadioDetails: null,
      localImpReps: [],
      customerGPILinkInfoCollection: []
    };

    let custLinkId = null;

    if (isCustomerView) {
      custLinkId = GpiAuthService.getGpiAuthInfo()
        ? GpiAuthService.getGpiAuthInfo().customerLinkId
        : null;
      dispatch(updateCustomerLinkId(custLinkId));
    }

    for (let entities of retrieveEntities) {

      const queryEntities = [
        "applicantInformation",
        "agentInformation",
        "manufacturerInformation",
        "factorySiteInformation",
        "customerLinkInformation",
        "productDetails",
        "radioDetails",
        "localRepImporter",
        "existingCertificates",
      ];

      const shouldRetrieveEntities = queryEntities.filter(
        (e) =>
          (!isCopy || queryParams.get(e) === "true") && entities.includes(e)
      );

      if (shouldRetrieveEntities.length === 0) return;

      const payload = {
        gpiId: generalProductInfoId,
        shouldRetrieveEntities: shouldRetrieveEntities ?? [],
        customerLinkId: custLinkId,
        isCustomerView: isCustomerView ?? false,
        isCopy: isCopy
      }

      const partialResult = await GeneralProductInformationService.getGPIDetails(
        payload);

      if (isGetGPISuccess(partialResult)) {
        result = _.mergeWith(
          {}, result, partialResult?.data,
          (a, b) => b === null ? a : undefined
        )
      }

    }

    return result;
  };

  const validateCustomerInputGpi = async (generalProductInfo) => {
    var result =
      await GeneralProductInformationService.getExistingOrGenerateNewGPICustomerInputValidationResult(
        {
          ...generalProductInfo,
          gPICustomerInputValidationParametersModel: {
            workflowPackageId: workflowPackageIdParam,
          },
        }
      );

    if (!result.isSuccess) {
      dispatch(updateValidationBannerStatusType("warning"));
    }
  };

  const validateCustomerCompanyDetailInputGpi = async (generalProductInfo) => {
    var result =
      await GeneralProductInformationService.getCustomerCompanyDetailsInputValidationResult(
        {
          ...generalProductInfo,
        }
      );

    if (!result.isSuccess) {
      dispatch(updateValidationBannerStatusType("warning"));
    }
  };

  const isGetGPISuccess = (getResult) =>
    getResult?.data != null && getResult?.isSuccess;

  const loadLocalRepSchemesList = async () => {
    const result =
      await GeneralProductInformationService.getLocalRepListScheme();

    setLocalRepSchemesList(result);
  };

  const handleHideSplashPage = () => {
    setSplashPageVisible(false);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const updateStep = (stepIndex, newStep) => {
    const newSteps = [...steps];
    const step = { ...newSteps[stepIndex], ...newStep };
    newSteps[stepIndex] = step;
    setSteps(newSteps);
  };

  const getStepValidation = async (stepIndex) => {
    const schema = steps[stepIndex].schema;
    if (!schema) return { isValid: true };

    const field = steps[stepIndex].field;

    const object = generalProductInformationValues[field];

    const result = await schema
      .validate(object, { abortEarly: false, context: object })
      .catch((err) => {
        return err;
      });

    if (result.errors) {
      return {
        isValid: false,
        result,
      };
    } else {
      return {
        isValid: true,
      };
    }
  };

  const validateStep = async (stepIndex, isUpdateStep = true) => {
    const validationResult = await getStepValidation(stepIndex);

    let stepValidationStatus;

    if (validationResult.isValid)
      stepValidationStatus = {
        status: STEP_STATUS.COMPLETED,
        hasBlankRequiredField: false,
        hasInvalidData: false,
      };
    else {
      const hasBlankRequiredField = validationResult.result?.inner?.some(
        (x) => x.type === "required"
      );
      const newStepStatus = hasBlankRequiredField
        ? STEP_STATUS.WARNING
        : STEP_STATUS.ERROR;

      stepValidationStatus = {
        status: newStepStatus,
        hasBlankRequiredField,
        hasInvalidData: !hasBlankRequiredField,
        showFormError: true,
        hasPrevElementToScroll: false,
        hasNextElementToScroll: validationResult?.result?.errors?.length >= 1,
      };
    }

    if (isUpdateStep) updateStep(stepIndex, stepValidationStatus);
    validationResult["stepValidationStatus"] = stepValidationStatus;

    const elementToScroll = document.getElementsByClassName("step-title");
    if (elementToScroll.length > 0) {
      elementToScroll[0].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }

    return validationResult;
  };

  const getFormFields = () => {
    return [...document.getElementsByClassName("form-field")];
  };

  const getActiveStep = () => {
    return steps[activeStepIndex];
  };

  const canFieldBeNavigated = (field) => {
    var withFieldValue = true;
    const multipleContainer = field.getElementsByClassName(
      "p-chips-multiple-container"
    );
    const multiSelectContainer = field.getElementsByClassName("p-multiselect");
    const inputTextContainer = field.getElementsByClassName("p-inputtext");

    const checkMultipleContainerValue = (container) => {
      return container[0]?.innerText;
    };

    const checkMultipleSelectValue = (container) => {
      return !(
        !container[0]?.innerText || container[0]?.innerText == "-- Select --"
      );
    };

    const checkInputTextValue = (container) => {
      return !!container[0]?.value;
    };

    if (multipleContainer?.length > 0) {
      withFieldValue = checkMultipleContainerValue(multipleContainer);
    } else if (multiSelectContainer?.length > 0) {
      withFieldValue = checkMultipleSelectValue(multiSelectContainer);
    } else {
      withFieldValue = checkInputTextValue(inputTextContainer);
    }

    return field.classList.contains("form-field") && !withFieldValue;
  };

  const updateActiveStepErrorNavigatorState = async () => {
    const activeStep = getActiveStep();
    const validationResult = await getStepValidation(activeStepIndex);

    let hasNextElementToScroll = false;
    let hasPrevElementToScroll = false;

    if (!validationResult.isValid) {
      if (activeStep.currentErrorControl) {
        const formFields = getFormFields();
        var currentErrorControlIndex = formFields.indexOf(
          steps[activeStepIndex].currentErrorControl
        );

        hasNextElementToScroll = formFields.some(
          (field, i) =>
            canFieldBeNavigated(field) && i >= currentErrorControlIndex
        );

        hasPrevElementToScroll = formFields.some(
          (field, i) =>
            canFieldBeNavigated(field) && i <= currentErrorControlIndex
        );
      } else {
        hasNextElementToScroll =
          document.getElementsByClassName("form-field error").length > 0;
      }
    }

    updateStep(activeStepIndex, {
      hasNextElementToScroll,
      hasPrevElementToScroll,
    });
  };

  const scrollIntoError = (iterator) => {
    const formFields = getFormFields();
    var elementToScroll;
    var hasNextElementToScroll = false;
    var hasPrevElementToScroll = false;
    var activeStep = getActiveStep();
    var currentErrorControlIndex = -1;

    if (activeStep.currentErrorControl) {
      currentErrorControlIndex = formFields.indexOf(
        activeStep.currentErrorControl
      );
    }

    if (iterator === 1) {
      elementToScroll = formFields.find(
        (field, i) => canFieldBeNavigated(field) && i > currentErrorControlIndex
      );
    } else if (iterator === -1) {
      elementToScroll = formFields
        .filter(
          (field, i) =>
            canFieldBeNavigated(field) && i < currentErrorControlIndex
        )
        .pop();
    }

    if (!elementToScroll) elementToScroll = activeStep.currentErrorControl;

    const elementToScrollIndex = formFields.indexOf(elementToScroll);

    setTimeout(() => {
      elementToScroll.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    });

    if (elementToScroll.classList.contains("focusable")) {
      elementToScroll.focus();
    } else {
      const inputControls = elementToScroll.getElementsByTagName("input");
      if (inputControls.length > 0) {
        inputControls[0].focus();
      }
    }

    hasNextElementToScroll = formFields.some(
      (field, i) =>
        (canFieldBeNavigated(field) && i > elementToScrollIndex) ||
        iterator === -1
    );

    hasPrevElementToScroll = formFields.some(
      (field, i) =>
        (canFieldBeNavigated(field) && i < elementToScrollIndex) ||
        iterator === 1
    );

    updateStep(activeStepIndex, {
      currentErrorControl: elementToScroll,
      hasNextElementToScroll,
      hasPrevElementToScroll,
    });
  };

  const handleNavigateStep = async (stepIndex) => {
    await validateStep(activeStepIndex);
    setActiveStepIndex(stepIndex);
  };

  const handleSaveGpiFormName = (formName) => {
    setGpiFormNameModalVisible(false);
    handleSave(formName);
  };

  const handleCloseGpiFormNameDialog = () => {
    setGpiFormNameModalVisible(false);
  };

  const validateAllSteps = async () => {
    const validationResults = [];

    for (let i = 0; i < steps.length; i++) {
      const result = await validateStep(i, false);
      validationResults.push(result);
    }

    const newSteps = validationResults.map((r, i) => ({
      ...steps[i],
      ...r.stepValidationStatus,
    }));

    setSteps(newSteps);

    return validationResults;
  };

  const handleCloseGpiValidationResultModal = () => {
    setGpiValidationResultModalVisible(false);
  };

  const handleFinish = () => {
    setIsCompleteGpiFormConfirmationDialogVisible(true);
  };

  const handleSave = async (
    formName,
    isFinish,
    isCustomerFinish = false,
    isSaveAndClose = true
  ) => {
    // Check if applicant name is supplied.
    if (!applicantInformationValues.companyName) {
      showError("Please enter the applicant name.");
      return;
    }

    // Check if models are properly selected.
    if (
      productTypeValues.modelVariants?.length > 0 &&
      !productTypeValues.baseModel
    ) {
      showError("Error!", "Base Model No. field is blank.");
      return;
    }

    if (!generalProductInformationValues.id && !formName) {
      let defaultFormName = applicantInformationValues.companyName;
      const gpiFormDate = moment(new Date()).format("MMDDYYYY_hhmmss");
      defaultFormName += "_" + gpiFormDate;

      dispatch(updateFormName(defaultFormName));
      setGpiFormNameModalVisible(true);

      return;
    }

    if (isCustomerView) {
      save(undefined, isFinish, isCustomerFinish);
      return;
    } else {
      save(isSaveAndClose, isFinish, isCustomerFinish);
    }
  };

  const updateCompanyDetailsState = (data) => {
    const newApplicant = {
      ...generalProductInformationValues.companyDetails.applicantInformation,
      id: data.applicantInfo?.id,
      contactId: data.applicantInfo?.gpiCompanyContactInfoId,
      gPIApplicantInfoId: data.gpiApplicantInfoId,
      applicantInfo: {
        id: data.applicantInfo?.id,
        gPICompanyContactInfoId: data.applicantInfo?.gpiCompanyContactInfoId,
        gPICompanyContactInfo: {
          id: data.applicantInfo?.gpiCompanyContactInfo?.id,
        },
      },
      // isSent: data.isSent,
    };

    const newAgent = {
      ...generalProductInformationValues.companyDetails.agentInformation,
      id: data.agentInfo?.id,
      contactId: data.agentInfo?.gpiCompanyContactInfoId,
      gpiAgentInfoId: data.gpiAgentInfoId,
      agentInfo: {
        id: data.agentInfo?.id,
        gPICompanyContactInfoId: data.agentInfo?.gpiCompanyContactInfoId,
        gPICompanyContactInfo: {
          id: data.agentInfo?.gpiCompanyContactInfo?.id,
        },
      },
      // isSent: data.isSent,
    };

    const newManufacturer = {
      ...generalProductInformationValues.companyDetails.manufacturerInformation,
      id: data.manufacturerInfo?.id,
      contactId: data.manufacturerInfo?.gpiCompanyContactInfoId,
      gpiManufacturerInfoId: data.gpiManufacturerInfoId,
      manufacturerInfo: {
        id: data.manufacturerInfo?.id,
        gPICompanyContactInfoId: data.manufacturerInfo?.gpiCompanyContactInfoId,
        gPICompanyContactInfo: {
          id: data.manufacturerInfo?.gpiCompanyContactInfo?.id,
        },
      },
      // isSent: data.isSent,
    };

    const newUlFileNumber = {
      ...generalProductInformationValues.companyDetails.ulFileNumber,
      gpiUlFileNumberId: data.gpiulFileNumberId,
      id: data.gpiulFileNumber?.id,
    };

    const newFactorySite = {
      ...generalProductInformationValues.companyDetails.factorySiteInformation,
      factorySites:
        generalProductInformationValues.companyDetails.factorySiteInformation.factorySites.map(
          (factory, i) => ({
            ...factory,
            id: data.factories[i]?.id,
            contactId: data.factories[i]?.gpiCompanyContactInfoId,
            gpiCompanyContactInfoId:
              data.factories[i]?.gpiCompanyContactContactInfoId,
            // isSent: data.isSent,
          })
        ),
    };

    const newCompanyDetails = {
      ...generalProductInformationValues.companyDetails,
      applicantInformation: newApplicant,
      agentInformation: newAgent,
      manufacturerInformation: newManufacturer,
      ulFileNumber: newUlFileNumber,
      factorySiteInformation: newFactorySite,
    };

    dispatch(updateCompanyDetails(newCompanyDetails));
  };

  const updateProductDetailsState = (data) => {
    const newProductType = {
      ...generalProductInformationValues.productDetails.productType,
      id: data.gpiProductType.id,
      baseModelId: data.gpiProductType.baseModelId,
      brandOrTrademarkAttachments:
        generalProductInformationValues.productDetails.productType.brandOrTrademarkAttachments
          ?.filter((b) => b.status === FILE_STATUS.SUCCESS)
          ?.map((b) => ({
            ...b,
            ...(data.gpiProductType.brandOrTrademarkAttachments.find(
              (a) => a.attachmentId == b.attachmentId
            ) ?? {}),
          })),
    };

    const newVoltageDetails = {
      id: data.gpiVoltageDetailsId,
      ...generalProductInformationValues.productDetails.voltageDetails,
    };

    const newRadioDetails = {
      id: data.gpiRadioDetailsId,
      ...generalProductInformationValues.productDetails.radioDetails,
    };

    const newProductDetails = {
      productType: newProductType,
      voltageDetails: newVoltageDetails,
      radioDetails: newRadioDetails,
    };

    dispatch(updateProductDetails(newProductDetails));
  };

  const updateUlProjectHandlerDetailsState = (data) => {
    const newUlProjectHandler = {
      id: data.gpiHandlerInfoId,
      ...generalProductInformationValues.ulProjectHandlerDetails
        .ulProjectHandler,
    };
    dispatch(updateULProjectHandleDetails(newUlProjectHandler));
  };

  const save = async (
    isSaveAndClose,
    isFinish = false,
    isCustomerFinish = false
  ) => {
    dispatch(updateIsLoading(true));
    var data = mapBodyData(
      generalProductInformationValues,
      workflowPackageIdParam,
      activeStepIndex,
      customerEmailAddress,
      linkId
    );

    setFormSaveMessage("Form saved successfully.");

    if (savedGPIFormData && !isCopy) {
      data = mapSavedGPIData(isFinish);
    }

    const isCreate = !!data.id;

    try {
      const response = isCreate
        ? await GeneralProductInformationService.updateGeneralProductInfo(data)
        : await GeneralProductInformationService.createGeneralProductInfo(
          data,
          giMappingIds
        );

      dispatch(updateIsLoading(false));

      if (response.isSuccess) {
        if (isCustomerView && isCustomerFinish) {
          setFormSaveMessage(
            "Finished GPI form sent to UL Project Handler successfully."
          );
          setShowDialog(true);
        } else {
          isSaveAndClose
            ? setShowDialog(true)
            : showSuccess("GPI Form", "Form saved successfully.");
        }

        updateState(response.data);
        dispatch(
          updateIsShowValidationBanner(
            response.data?.validationStatus === ValidationStatus.InProgress ||
            response.data?.validationStatus === ValidationStatus.Failed
          )
        );
        dispatch(
          updateValidationBannerStatusType(
            response.data?.validationStatus === ValidationStatus.InProgress
              ? "info"
              : response.data?.validationStatus === ValidationStatus.Failed
                ? "warning"
                : ""
          )
        );
        dispatch(updateIsSaveDisabled(true));
        dispatch(updateIsGiFormEditable(response.data?.isLinkEditable));

        return;
      }

      showError("Error!", response.message);
    } catch (e) {
      dispatch(updateIsLoading(false));

      if (e?.response?.status === 401 && isCustomerView) {
        CustomerService.onUnauthorized();
        return;
      }

      showError(
        "Error!",
        e.response?.data ||
        "There is an error processing your request. Please try again later."
      );
    }
  };

  const updateState = async (data) => {
    dispatch(updateGeneralProductInfoId(data.id));
    dispatch(updateHasFinalizedByCustomer(data.hasFinalizedByCustomer));
    setCustomerEmailAddress(data.finishedByCustomerEmail);
    dispatch(
      updateIsReadOnly(
        isCustomerView && data.hasFinalizedByCustomer && !data.isLinkEditable
      )
    );
    updateCompanyDetailsState(data);
    updateProductDetailsState(data);
    updateUlProjectHandlerDetailsState(data);
  };

  const handleChangeFormName = (formName) => {
    dispatch(updateFormName(formName));
  };

  const mapSavedGPIData = (isFinish) => {
    const id = savedGPIFormData.id;
    const workflowPackageId = savedGPIFormData.workflowPackageId ?? null;

    const applicantInfo = {
      id: savedGPIFormData.gPIApplicantInfoId,
      gPICompanyContactInfoId:
        savedGPIFormData.applicantInfo?.gPICompanyContactInfoId,
      gPICompanyContactInfo: {
        ...customerInformationMapper(
          generalProductInformationValues.companyDetails.applicantInformation,
          generalProductInformationValues.companyDetails.applicantInformation
            .companyId
          // isSent
        ),
      },
    };

    const agentInfo = {
      id: savedGPIFormData.gPIAgentInfoId,
      gPICompanyContactInfoId:
        savedGPIFormData.agentInfo?.gPICompanyContactInfoId,
      gPICompanyContactInfo: {
        ...customerInformationMapper(
          generalProductInformationValues.companyDetails.agentInformation,
          generalProductInformationValues.companyDetails.agentInformation
            .companyId
          // isSent
        ),
      },
      isAgentInformationAvailable:
        generalProductInformationValues.companyDetails.agentInformation
          .isAgentInformationAvailable,
    };

    const gpiUlFileNumber = {
      id: savedGPIFormData.gPIULFileNumberId,
      isExistingULFileNumber:
        generalProductInformationValues.companyDetails.ulFileNumber
          .hasExistingULFileNumber,
      isULCBCertificateNumber:
        generalProductInformationValues.companyDetails.ulFileNumber
          .isULCBCertificateNumber,
      isOtherGMACertificate:
        generalProductInformationValues.companyDetails.ulFileNumber
          .isOtherGMACertificate,
      fileNumber:
        generalProductInformationValues.companyDetails.ulFileNumber.fileNumber?.trim(),
      volumeNumber:
        generalProductInformationValues.companyDetails.ulFileNumber.volumeNumber?.trim(),
      sectionNumber:
        generalProductInformationValues.companyDetails.ulFileNumber.sectionNumber?.trim(),
      certificateId:
        generalProductInformationValues.companyDetails.ulFileNumber
          .certificateId,
      certificateScheme:
        generalProductInformationValues.companyDetails.ulFileNumber.certificateScheme?.trim(),
      certificateNumber:
        generalProductInformationValues.companyDetails.ulFileNumber.certificateNumber?.trim(),
      isCustomerInput:
        generalProductInformationValues.companyDetails.ulFileNumber
          .isCustomerInput,
    };

    const manufacturerInfo = {
      id: savedGPIFormData.gPIManufacturerInfoId,
      gPICompanyContactInfoId:
        savedGPIFormData.manufacturerInfo?.gPICompanyContactInfoId,
      gPICompanyContactInfo: {
        ...customerInformationMapper(
          generalProductInformationValues.companyDetails
            .manufacturerInformation,
          generalProductInformationValues.companyDetails.manufacturerInformation
            .companyId
          // isSent
        ),
      },
      IsSameAsApplicant:
        generalProductInformationValues.companyDetails.manufacturerInformation
          .isSameAsApplicant,
    };

    const factories =
      generalProductInformationValues.companyDetails.factorySiteInformation.factorySites.map(
        (factory, index) => ({
          id: (savedGPIFormData.factories || [])[index]?.id ?? null,
          generalProductInfoId: id,
          gpiCompanyContactInfoId:
            (savedGPIFormData.factories || [])[index]
              ?.gPICompanyContactInfoId ?? null,
          gpiCompanyContactInfo: customerInformationMapper(
            factory,
            factory.companyId
            // isSent
          ),
          isSameAsApplicant:
            factory.source === FACTORY_SITE_INFO_SOURCE.SAME_AS_APPLICANT,
          isSameAsManufacturer:
            factory.source === FACTORY_SITE_INFO_SOURCE.SAME_AS_MANUFACTURER,
          isManualInput:
            factory.source === FACTORY_SITE_INFO_SOURCE.MANUAL_INPUT,
          isFromCertDetails: factory.isFromCertDetails,
          isSelected: factory.isSelected,
        })
      );

    const gpiProductTypeId = savedGPIFormData.gPIProductTypeId;

    const gpiApplicantInfoId = savedGPIFormData.gPIApplicantInfoId;

    const gpiAgentInfoId = savedGPIFormData.gPIAgentInfoId;

    const gpiUlFileNumberId = savedGPIFormData.gPIULFileNumberId;

    const gpiManufacturerInfoId = savedGPIFormData.gPIManufacturerInfoId;

    const gPIVoltageDetailsId = savedGPIFormData.gPIVoltageDetailsId;

    const gpiRadioDetailsId = savedGPIFormData.gPIRadioDetailsId;

    const baseModelId = generalProductInformationValues.productDetails
      .productType.baseModel
      ? generalProductInformationValues.productDetails.productType.baseModel
        .baseModelId
      : null;

    const gpiProductType = {
      id: gpiProductTypeId ?? null,
      productName:
        generalProductInformationValues.productDetails.productType.productName,
      productType:
        generalProductInformationValues.productDetails.productType.productType,
      baseModelId,
      baseModel: generalProductInformationValues.productDetails.productType
        .baseModel
        ? {
          baseModelId,
          ...generalProductInformationValues.productDetails.productType
            .baseModel,
        }
        : null,
      modelVariants:
        generalProductInformationValues.productDetails.productType.modelVariants.map(
          (m) => ({
            gpiProductTypeId,
            modelVariant: m,
          })
        ),
      productTypes:
        generalProductInformationValues.productDetails?.productType
          ?.productTypes,
      marketingName:
        generalProductInformationValues.productDetails.productType
          .marketingName,
      productDescription:
        generalProductInformationValues.productDetails.productType
          .productDescription,
      productTypeId:
        generalProductInformationValues.productDetails.productType
          .productTypeId ?? "",
      brandOrTrademark:
        generalProductInformationValues.productDetails.productType
          .brandOrTrademark,
      brandOrTrademarkAttachments:
        generalProductInformationValues.productDetails.productType.brandOrTrademarkAttachments?.filter(
          (b) => b.status === FILE_STATUS.SUCCESS
        ),
      isModelsModified:
        generalProductInformationValues.productDetails.productType
          .isModelsModified,
    };

    const localImpReps =
      generalProductInformationValues.localRepImporterInformation.map(
        (localRep) => ({
          id: localRep.id ?? null,
          generalProductInfoId: id,
          schemeId: localRep.schemeId,
          schemeName: localRep.schemeName,
          uLToProvide: localRep.uLToProvide,
          representatives: localRep.representatives.map((rep) =>
            customerInformationMapper(rep)
          ),
        })
      );

    const isACVoltage =
      generalProductInformationValues.productDetails.voltageDetails
        .voltageType === "AC";

    const isDCVoltage =
      generalProductInformationValues.productDetails.voltageDetails
        .voltageType === "DC";

    const containsBattery =
      isDCVoltage &&
      generalProductInformationValues.productDetails.voltageDetails
        .containsBattery;

    const batteryType = containsBattery
      ? generalProductInformationValues.productDetails.voltageDetails
        .batteryType
      : "";

    const gpiVoltageDetails = {
      id: gPIVoltageDetailsId,
      voltageType:
        generalProductInformationValues.productDetails.voltageDetails
          .voltageType,
      productRatings:
        generalProductInformationValues.productDetails.voltageDetails
          .productRatings,
      connectsToMainVoltage:
        isDCVoltage &&
        generalProductInformationValues.productDetails.voltageDetails
          .connectsToMainVoltage,
      containsBattery,
      externalPowerSupplyWillBeShipped:
        isDCVoltage &&
        generalProductInformationValues.productDetails.voltageDetails
          .externalPowerSupplyWillBeShipped,
      isBatteryUserReplaceable:
        containsBattery &&
        generalProductInformationValues.productDetails.voltageDetails
          .isBatteryUserReplaceable,
      willBatteryBeShipped:
        containsBattery &&
        generalProductInformationValues.productDetails.voltageDetails
          .willBatteryBeShipped,
      batteryType,
      otherBatteryType:
        batteryType === "Other"
          ? generalProductInformationValues.productDetails.voltageDetails
            .otherBatteryType
          : "",
      ratedCurrent:
        generalProductInformationValues.productDetails.voltageDetails
          .ratedCurrent,
      ratedFrequency: isACVoltage
        ? generalProductInformationValues.productDetails.voltageDetails
          .ratedFrequency
        : "",
      operatingTemperatureRange:
        generalProductInformationValues.productDetails.voltageDetails
          .operatingTemperatureRange,
      harmonizedShippingCode:
        generalProductInformationValues.productDetails.voltageDetails
          .harmonizedShippingCode,
      operationClasses:
        generalProductInformationValues.productDetails.voltageDetails.operationClasses?.map(
          (o) => ({
            ...o,
            gPIVoltageDetailsId: savedGPIFormData.gPIVoltageDetailsId,
          })
        ),
      otherOperationClass:
        generalProductInformationValues.productDetails.voltageDetails.operationClasses?.some(
          (o) => o.operationClass === "Other"
        )
          ? generalProductInformationValues.productDetails.voltageDetails
            .otherOperationClass
          : "",
      productClassificationClass1:
        isACVoltage &&
        generalProductInformationValues.productDetails.voltageDetails
          .productClassificationClass1,
      productClassificationClass2:
        isACVoltage &&
        generalProductInformationValues.productDetails.voltageDetails
          .productClassificationClass2,
      productClassificationClass3: isDCVoltage,
    };

    const gpiRadioDetails = {
      id: gpiRadioDetailsId,
      otherEquipmentRadioType:
        generalProductInformationValues.productDetails.radioDetails
          .otherEquipmentRadioType,
      radioSpecificationComment:
        generalProductInformationValues.productDetails.radioDetails
          .radioSpecificationComment,
      equipmentRadioTypes:
        generalProductInformationValues.productDetails.radioDetails
          .equipmentRadioTypes,
      radios:
        generalProductInformationValues.productDetails.radioDetails.radios,
    };

    const gpiHandlerInfoId = savedGPIFormData.gPIHandlerInfoId;

    const handlerInfo = {
      id: gpiHandlerInfoId,
      officeName:
        generalProductInformationValues.ulProjectHandlerDetails.ulProjectHandler
          .officeName,
      officeAddress:
        generalProductInformationValues.ulProjectHandlerDetails.ulProjectHandler
          .officeAddress,
      emailAddress:
        generalProductInformationValues.ulProjectHandlerDetails.ulProjectHandler
          .emailAddress,
      telephoneNumber:
        generalProductInformationValues.ulProjectHandlerDetails.ulProjectHandler
          .telephoneNumber,
      name: generalProductInformationValues.ulProjectHandlerDetails
        .ulProjectHandler.name,
      country:
        generalProductInformationValues.ulProjectHandlerDetails.ulProjectHandler
          .country,
    };

    const gpiFormName = savedGPIFormData.gPIFormName;

    const validationWorkflowPackageId =
      workflowPackageIdParam || workflowPackageId;

    const data = {
      stepIndexSavePoint: activeStepIndex,
      gpiFormName,
      gpiProductTypeId,
      id,
      workflowPackageId,
      gpiApplicantInfoId,
      applicantInfo,
      gpiAgentInfoId,
      agentInfo,
      gpiUlFileNumberId,
      gpiUlFileNumber,
      gpiManufacturerInfoId,
      manufacturerInfo,
      factories,
      gpiProductType,
      gpiVoltageDetails,
      gPIVoltageDetailsId,
      gpiRadioDetails,
      gpiRadioDetailsId,
      gpiHandlerInfoId,
      handlerInfo,
      localImpReps,
      hasFinalizedByCustomer: isCustomerView && isFinish,
      gPICustomerInputValidationParametersModel: validationWorkflowPackageId
        ? {
          workflowPackageId: validationWorkflowPackageId,
        }
        : null,
      finishedByCustomerEmail: customerEmailAddress,
      customerLinkId: linkId,
    };

    return data;
  };

  const closeBanner = (stepIndex) => {
    updateStep(stepIndex, { closedBanner: true });
  };

  const getActiveStepErrorBanner = () => {
    const hasError =
      activeStep.hasInvalidData || activeStep.hasBlankRequiredField;

    if (!hasError || activeStep.closedBanner) return;

    if (
      !activeStep.hasNextElementToScroll &&
      !activeStep.hasPrevElementToScroll
    )
      return;

    const bannerProps = {
      warning: {
        icon: faWarning,
        message: "Some fields are blank.",
      },
      danger: {
        icon: faExclamationCircle,
        message: "Please correct all highlighted fields.",
      },
    };

    return (
      <div className={`${classes["banner"]} ${classes[activeStepErrorType]}`}>
        <div>
          <FontAwesomeIcon
            className={classes["icon"]}
            icon={bannerProps[activeStepErrorType].icon}
          />
          <h5 className={classes["message"]}>
            {bannerProps[activeStepErrorType].message}
          </h5>
          <div className={classes["step-navigator"]}>
            <button
              disabled={
                activeStep.hasPrevElementToScroll !== undefined &&
                activeStep.hasPrevElementToScroll !== true
              }
              onClick={() => scrollIntoError(-1)}
            >
              previous
            </button>
            <button
              disabled={
                activeStep.hasNextElementToScroll !== undefined &&
                activeStep.hasNextElementToScroll !== true
              }
              onClick={() => scrollIntoError(1)}
            >
              next
            </button>
          </div>
        </div>

        <FontAwesomeIcon
          onClick={() => closeBanner(activeStepIndex)}
          className={classes["close-icon"]}
          icon={faClose}
        />
      </div>
    );
  };

  const getValidationProgressBanner = () => {
    const isWarning = validationBannerStatusType == "warning";
    const bannerProps = {
      warning: {
        icon: faWarning,
        message:
          "An error has been encountered while running the validation of this form.",
      },
      info: {
        icon: faExclamationCircle,
        message:
          "This GPI Form currently has an ongoing validation. You may still continue to update the other sections and save your progress in this form.",
      },
    };

    return (
      showValidationBanner && (
        <div
          className={`${classes["banner"]} ${classes[validationBannerStatusType]}`}
        >
          <div>
            <FontAwesomeIcon
              className={classes["icon"]}
              icon={bannerProps[validationBannerStatusType].icon}
            />
            <h5 className={classes["message"]}>
              {bannerProps[validationBannerStatusType].message} &nbsp;
            </h5>
            {isWarning && (
              <h5 className={classes["message"]}>
                <a
                  className={classes["link"]}
                  onClick={() => retryValidation()}
                >
                  Click here to try again.
                </a>
              </h5>
            )}
          </div>

          <FontAwesomeIcon
            onClick={() => closeValidationBanner()}
            className={classes["close-icon"]}
            icon={faClose}
          />
        </div>
      )
    );
  };

  const closeValidationBanner = () => {
    dispatch(updateIsShowValidationBanner(false));
  };

  const retryValidation = async () => {
    dispatch(updateValidationBannerStatusType("info"));
    const result = await GeneralProductInformationService.retryGPIValidation(
      generalProductInfoId
    );

    if (!result) {
      return;
    }
  };

  const autoPopulateSavedGPIForm = (gPI) => {
    const createdBy = gPI.createdBy;
    const currentUser = authProvider.getAccountInfo()?.account;
    dispatch(updateGeneralProductInfoId(gPI.id));
    dispatch(updateHasFinalizedByCustomer(gPI.hasFinalizedByCustomer));
    dispatch(
      updateWorkflowPackageId(gPI.workflowPackageId || workflowPackageIdParam)
    );

    if (isCustomerView) {
      dispatch(
        updateIsGiFormEditable(
          gPI.customerGPILinkInfoCollection[0].isLinkEditable
        )
      );
      dispatch(
        updateIsReadOnly(
          gPI.hasFinalizedByCustomer &&
          !gPI.customerGPILinkInfoCollection[0].isLinkEditable
        )
      );
      dispatch(updateFinishedByCustomerEmail(gPI.finishedByCustomerEmail));
      setCustomerEmailAddress(gPI.finishedByCustomerEmail);
    } else {
      dispatch(updateIsReadOnly(currentUser.userName !== createdBy && !isCopy));
    }

    //Company Details tab auto populate
    if (gPI.applicantInfo) {
      dispatch(
        updateApplicantInformation(
          savedCompanyInfoMapper(gPI.applicantInfo.gPICompanyContactInfo)
        )
      );
    }

    if (gPI.agentInfo) {
      dispatch(
        updateAgentInformation({
          ...savedCompanyInfoMapper(gPI.agentInfo.gPICompanyContactInfo),
          isAgentInformationAvailable:
            gPI.agentInfo.isAgentInformationAvailable,
        })
      );
    }

    if (gPI.manufacturerInfo) {
      dispatch(
        updateManufacturerInformation({
          ...savedCompanyInfoMapper(gPI.manufacturerInfo.gPICompanyContactInfo),
          isSameAsApplicant: gPI.manufacturerInfo.isSameAsApplicant,
        })
      );
    }

    if (gPI.gPIULFileNumber) {
      dispatch(
        updateULFileNumber({
          hasExistingULFileNumber: gPI.gPIULFileNumber.isExistingULFileNumber,
          isULCBCertificateNumber: gPI.gPIULFileNumber.isULCBCertificateNumber,
          isOtherGMACertificate: gPI.gPIULFileNumber.isOtherGMACertificate,
          fileNumber: gPI.gPIULFileNumber.fileNumber,
          volumeNumber: gPI.gPIULFileNumber.volumeNumber,
          sectionNumber: gPI.gPIULFileNumber.sectionNumber,
          certificateId: gPI.gPIULFileNumber.certificateId,
          certificateScheme: gPI.gPIULFileNumber.certificateScheme,
          certificateNumber: gPI.gPIULFileNumber.certificateNumber,
          isCustomerInput: gPI.gPIULFileNumber.isCustomerInput,
          uLFileNumberOldValue: gPI.gPIULFileNumber.uLFileNumberOldValue,
          hasLoadedInitially: true,
        })
      );
    }

    if (gPI.factories?.length > 0) {
      const factoryCompanyDetails = [];

      for (var i = 0; i < gPI.factories?.length; i++) {
        var factorySource = FACTORY_SITE_INFO_SOURCE.UL_FILE_NUMBER_INPUT;
        if (gPI.factories[i].isSameAsApplicant) {
          factorySource = FACTORY_SITE_INFO_SOURCE.SAME_AS_APPLICANT;
        } else if (gPI.factories[i].isSameAsManufacturer) {
          factorySource = FACTORY_SITE_INFO_SOURCE.SAME_AS_MANUFACTURER;
        } else if (gPI.factories[i].isManualInput) {
          factorySource = FACTORY_SITE_INFO_SOURCE.MANUAL_INPUT;
        }

        factoryCompanyDetails.push({
          source: factorySource,
          isSelected: gPI.factories[i].isSelected,
          isFromCertDetails: gPI.factories[i].isFromCertDetails,
          ...savedCompanyInfoMapper(gPI.factories[i].gPICompanyContactInfo),
        });
      }

      dispatch(
        updateFactorySiteInformation({
          factoriesCount: gPI.factories.length,
          hasFetchedCertDetails: gPI.factories.some(
            (f) => f.isFromCertDetails === true
          ),
          factorySites: factoryCompanyDetails,
        })
      );
    }

    if (gPI.gPIVoltageDetails?.operationClasses?.length > 0) {
      gPI.gPIVoltageDetails.operationClasses.map((o) => ({
        ...o,
        gPIVoltageDetailsId: gPI.gPIVoltageDetailsId ?? null,
      }));
    }

    //Product Details tab auto populate
    dispatch(
      updateProductDetails({
        productType: {
          ...gPI.gPIProductType,
          productTypeOptions: [],
          productTypeId: gPI.gPIProductType?.productTypeId,
          productType: gPI.gPIProductType?.productType,
          brandOrTrademarkAttachments:
            gPI.gPIProductType?.brandOrTrademarkAttachments?.map((f) => ({
              attachmentId: f.attachmentId,
              name: f.attachment.filename,
              id: f.id,
              productTypeId: f.productTypeId,
              status: FILE_STATUS.SUCCESS,
              filepath: f.attachment.filepath,
              isCopy,
            })),
          modelVariants: gPI.gPIProductType?.modelVariants?.map(
            (m) => m.modelVariant
          ),
          productTypes: gPI.gPIProductType?.productTypes,
        },
        voltageDetails: {
          ...gPI.gPIVoltageDetails,
          hasOtherOperationClass: false,
          isOtherBatteryType: false,
        },
        radioDetails: {
          radioCount: gPI.gPIRadioDetails?.radios?.length,
          otherEquipmentRadioType: gPI.gPIRadioDetails?.otherEquipmentRadioType,
          radioSpecificationComment:
            gPI.gPIRadioDetails?.radioSpecificationComment,
          equipmentRadioTypes: gPI.gPIRadioDetails?.equipmentRadioTypes,
          radios: gPI.gPIRadioDetails?.radios,
        },
      })
    );

    //Local Rep Importer tab auto populate
    if (gPI.localImpReps?.length > 0) {
      const localReps = [];

      for (var i = 0; i < gPI.localImpReps.length; i++) {
        var representatives = [];

        // If no representative is added to database, load one default representative to UI instead.
        if (gPI.localImpReps[i].representatives.length === 0) {
          representatives.push(customerInformationInitialState);
        } else {
          for (var j = 0; j < gPI.localImpReps[i].representatives.length; j++) {
            representatives.push(
              savedCompanyInfoMapper(gPI.localImpReps[i].representatives[j])
            );
          }
        }

        localReps.push({
          id: gPI.localImpReps[i].id,
          schemeId: gPI.localImpReps[i].schemeId,
          schemeName: gPI.localImpReps[i].schemeName,
          uLToProvide: gPI.localImpReps[i].uLToProvide,
          representatives: representatives,
        });
      }

      dispatch(updateLocalRepImporters(JSON.parse(JSON.stringify(localReps))));
    }

    //UL Project Handler tab auto populat
    dispatch(
      updateULProjectHandleDetails({
        ...gPI.handlerInfo,
      })
    );

    setSavedGPIFormData(gPI);
  };

  const mapCompanyDetailsFromFlex = ({
    partySiteNumber,
    partyName: companyName,
    locationReference,
  }) => {
    const {
      addressLine1,
      addressLine2,
      addressLine3,
      addressLine4,
      city,
      state,
      province,
      countryName: country,
      postalCode: zipCode,
    } = locationReference;

    const streetAddress = [
      addressLine1,
      addressLine2,
      addressLine3,
      addressLine4,
    ]
      .filter((a) => a != "")
      .join(", ");

    return {
      partySiteNumber,
      companyName,
      streetAddress,
      city,
      country,
      zipCode,
      stateOrProvince: state + province,
    };
  };

  const retrieveApplicantInfo = async (applicantPsn) => {
    const { data, isSuccess } = await ProductTypeService.getCompanyList(
      "",
      applicantPsn,
      ""
    );

    if (isSuccess && data.length > 0) {
      const companyDetails = mapCompanyDetailsFromFlex(data[0]);
      dispatch(updateApplicantInformation(companyDetails));
    }
  };

  const retrieveManufacturerInfo = async (manufacturerPsn) => {
    const { data, isSuccess } = await ProductTypeService.getCompanyList(
      "",
      manufacturerPsn,
      ""
    );

    if (isSuccess && data.length > 0) {
      const companyDetails = mapCompanyDetailsFromFlex(data[0]);
      dispatch(updateManufacturerInformation(companyDetails));
    }
  };

  const savedCompanyInfoMapper = (companyInfo) => {
    return {
      companyId: companyInfo.gPICompanyInfoId,
      partySiteNumber: companyInfo.gPICompanyInfo.partySiteNumber,
      companyName: companyInfo.gPICompanyInfo.companyName,
      streetAddress: companyInfo.gPICompanyInfo.streetAddress,
      city: companyInfo.gPICompanyInfo.city,
      stateOrProvince: companyInfo.gPICompanyInfo.stateOrProvince,
      zipCode: companyInfo.gPICompanyInfo.zipCode,
      country: companyInfo.gPICompanyInfo.country,
      contactPerson: companyInfo.contactName,
      title: companyInfo.titleAndPosition,
      phone: companyInfo.phoneNumber,
      fax: companyInfo.faxNumber,
      email: companyInfo.emailAddress,
      website: companyInfo.website,
      directorName: companyInfo.director,
      hasSelectedCompanyName: companyInfo.gPICompanyInfo.hasSelectedCompanyName,
      hasSelectedPsn: companyInfo.gPICompanyInfo.hasSelectedPsn,
    };
  };

  const handleConfirmCompleteGpiForm = async () => {
    if (isCustomerView) {
      dispatch(updateIsLoading(true));
      setCustomerEmailAddress(
        generalProductInformationValues.finishedByCustomerEmail
      );
      setIsConfirmEmailAddressOnGpiCompleteDialogVisible(true);
      dispatch(updateIsLoading(false));
    }
  };

  const OnChangeEmailAddress = (emailAddress) => {
    setCustomerEmailAddress(emailAddress);
    setIsShowCustomerEmailErrorMsg(false);
    setCustomerEmailErrorMsg("");
  };

  const handleConfirmEmailAddressOnGpiComplete = () => {
    if (customerEmailAddress.trim() === "") {
      setIsShowCustomerEmailErrorMsg(true);
      setCustomerEmailErrorMsg(blankEmailAddErrorMsg);
      return;
    }

    if (!isValidEmailAddress(customerEmailAddress)) {
      setIsShowCustomerEmailErrorMsg(true);
      setCustomerEmailErrorMsg(invalidEmailAddErrorMsg);
      return;
    }

    setIsShowCustomerEmailErrorMsg(false);
    setCustomerEmailErrorMsg("");
    dispatch(updateFinishedByCustomerEmail(customerEmailAddress));
    setIsConfirmEmailAddressOnGpiCompleteDialogVisible(false);
    setIsCompleteGpiFormConfirmationDialogVisible(false);
    handleSave(undefined, true, true, false);
  };

  const handleCancelConfirmEmailAddressOnGpiComplete = () => {
    setIsConfirmEmailAddressOnGpiCompleteDialogVisible(false);
    setIsShowCustomerEmailErrorMsg(false);
    setCustomerEmailErrorMsg("");
  };

  const isValidEmailAddress = (emailAddress) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const restrictedChars = /[!$#%&~]/;

    if (restrictedChars.test(emailAddress)) {
      return false;
    }

    return re.test(String(emailAddress).toLowerCase());
  };

  const handleSuccessDialog = () => {
    if (isShowDialog) {
      setShowDialog(false);

      if (!isCustomerView) {
        let navigationInfo = "";
        const workflowPackageId = queryParams.get("workflowPackageId");

        if (generalProductInfoId && workflowPackageId) {
          navigationInfo =
            "navigateToR2CListGPIMappingTab_" + workflowPackageId;
          window.parent.postMessage(navigationInfo, "*");
          if (process.env.NODE_ENV === "development") {
            setTimeout(() => {
              history.push(
                "document/details/r2c-list?packageId=" +
                workflowPackageId +
                "&currentTab=2" +
                "&activeNav=5"
              );
            });
          }
        } else {
          navigationInfo = "navigateToGpiList";
          window.parent.postMessage(navigationInfo, "*");
          if (process.env.NODE_ENV === "development") {
            setTimeout(() => {
              history.push("/general-product-information/");
            });
          }
        }
      }
    } else {
      setShowDialog(true);
    }
  };

  const DialogSuccessHeader = () => {
    return (
      <>
        <h4 className={classes["modal-header"]}>Success</h4>
        <hr className="modal-footer-hr" />
      </>
    );
  };

  const DialogSuccessFooter = () => {
    return (
      <>
        <hr className="modal-footer-hr" />
        <div className={`text-center ${classes["footer-content"]}`}>
          <button
            className={`ul-button -app-primary`}
            onClick={() => handleSuccessDialog()}
          >
            OK
          </button>
        </div>
      </>
    );
  };

  const handleGPIFormName = (data) => {
    if (generalProductInfoId) {
      if (!isCustomerView && !isCopy && !workflowPackageIdParam)
        setGPIFormName(data.gPIFormName);
    }
  };

  return (
    <>
      <Dialog
        header={DialogSuccessHeader}
        footer={DialogSuccessFooter}
        visible={isShowDialog}
        modal
        style={{ width: "630px", height: "310px" }}
        closable={false}
        className={classes["container"]}
      >
        <div className={`text-center`}>
          <p> {formSaveMessage} </p>
        </div>
      </Dialog>

      <SplashPage onHide={handleHideSplashPage} visible={splashPageVisible} />
      <LoadingIndicator isLoading={isLoading} />
      <div
        className={`${classes["container"]} ${isCustomerView ? classes["customer-view"] : ""
          }`}
      >
        <div
          className={
            generalProductInfoId && !workflowPackageIdParam
              ? classes["step-menus-container-with-form"]
              : classes["step-menus-container"]
          }
        >
          {GPIFormName != null ? (
            <>
              <div className={classes["gpi-form-name-container"]}>
                <div className={classes["gpi-form-name-label"]}>
                  GPI Form Name
                </div>
                <div
                  className={classes["gpi-form-name-value"]}
                  title={GPIFormName}
                >
                  {" "}
                  {GPIFormName}
                </div>
              </div>
              <div className={classes["step-menus"]}>
                <StepMenus
                  isReadOnly={isReadOnly}
                  onNavigateStep={handleNavigateStep}
                  steps={steps}
                  activeIndex={activeStepIndex}
                />
              </div>
            </>
          ) : (
            <StepMenus
              isReadOnly={isReadOnly}
              onNavigateStep={handleNavigateStep}
              steps={steps}
              activeIndex={activeStepIndex}
            />
          )}
        </div>

        {!isReadOnly && getValidationProgressBanner()}
        {!isReadOnly && getActiveStepErrorBanner()}

        <div className={classes["step-content"]}>
          {activeStepIndex === 0 && (
            <CompanyDetails
              disableIntegration={isCustomerView}
              isReadOnly={isReadOnly}
              showFormError={
                !isReadOnly && steps[activeStepIndex].showFormError
              }
            />
          )}

          {activeStepIndex === 1 && (
            <ProductDetails
              disableIntegration={isCustomerView}
              showFormError={
                !isReadOnly && steps[activeStepIndex].showFormError
              }
              isReadOnly={isReadOnly}
            />
          )}

          {activeStepIndex === 2 && (
            <LocalRepImporter
              disableIntegration={isCustomerView}
              isReadOnly={isReadOnly}
              isUserToProvideOnly={isCustomerView}
              showFormError={
                !isReadOnly && steps[activeStepIndex].showFormError
              }
              localRepSchemesList={localRepSchemesList}
            />
          )}

          {activeStepIndex === 3 && (
            <ULProjectHandler
              isReadOnly={isReadOnly}
              showFormError={
                !isReadOnly && steps[activeStepIndex].showFormError
              }
            />
          )}
        </div>

        <div className={classes["page-footer"]}>
          <FooterActions
            isSaveDisabled={isSaveDisabled}
            activeIndex={activeStepIndex}
            steps={steps}
            onSave={() => handleSave(undefined, false, false, false)}
            onSaveAndClose={() => handleSave()}
            onFinish={() => handleFinish()}
            onNavigateStep={handleNavigateStep}
            nextVisible={activeStepIndex !== totalSteps() - 1}
            prevVisible={activeStepIndex !== 0}
            finishVisible={
              activeStepIndex === totalSteps() - 1 &&
              isCustomerView &&
              isGiFormEditable
            }
            saveVisible={
              !isCustomerView || (isCustomerView && isGiFormEditable)
            }
            includeSaveAndClose={!isCustomerView}
            goBackVisible={false}
          />
        </div>
      </div>

      <GpiFormNameModal
        visible={gpiFormNameModalVisible}
        formName={generalProductInformationValues.gpiFormName}
        onChangeFormName={handleChangeFormName}
        onCancel={handleCloseGpiFormNameDialog}
        onHide={handleCloseGpiFormNameDialog}
        onSave={handleSaveGpiFormName}
      />

      <GpiValidationResultDialog
        steps={steps}
        onClose={handleCloseGpiValidationResultModal}
        visible={gpiValidationResultModalVisible}
      />

      {/* <SendEmailModal
        isOpen={openSendEmail}
        setIsOpen={setOpenSendEmail}
        sendEmail={sendEmail}
        saveGPI={save}
      /> */}

      <CompleteGpiFormConfirmationDialog
        visible={isCompleteGpiFormConfirmationDialogVisible}
        onConfirm={handleConfirmCompleteGpiForm}
        onHide={() => setIsCompleteGpiFormConfirmationDialogVisible(false)}
      />

      <ConfirmEmailAddressOnGpiCompleteDialog
        visible={isConfirmEmailAddressOnGpiCompleteDialogVisible}
        onSubmit={handleConfirmEmailAddressOnGpiComplete}
        onCancel={handleCancelConfirmEmailAddressOnGpiComplete}
        OnChangeEmailAddress={OnChangeEmailAddress}
        customerEmailAddress={customerEmailAddress}
        isShowCustomerEmailErrorMsg={isShowCustomerEmailErrorMsg}
        customerEmailErrorMsg={customerEmailErrorMsg}
      />
    </>
  );
};

export default GeneralProductInformation;

export const mapBodyData = (
  generalProductInformationValues,
  workflowPackageIdParam,
  activeStepIndex = 0,
  customerEmailAddress = "",
  linkId = ""
) => {
  const id = generalProductInformationValues.id ?? null;
  const workflowPackageId =
    generalProductInformationValues.workflowPackageId ?? workflowPackageIdParam;

  const applicantInfo = {
    id:
      generalProductInformationValues.companyDetails.applicantInformation
        .applicantInfo?.id ?? null,
    gPICompanyContactInfoId:
      generalProductInformationValues.companyDetails.applicantInformation
        .applicantInfo?.gPICompanyContactInfoId ?? null,
    gPICompanyContactInfo: {
      ...customerInformationMapper(
        generalProductInformationValues.companyDetails.applicantInformation,
        generalProductInformationValues.companyDetails.applicantInformation
          .companyId
        // isSent
      ),
    },
  };

  const agentInfo = {
    id:
      generalProductInformationValues.companyDetails.agentInformation.agentInfo
        ?.id ?? null,
    gPICompanyContactInfoId:
      generalProductInformationValues.companyDetails.agentInformation.agentInfo
        ?.gPICompanyContactInfoId ?? null,
    gPICompanyContactInfo: {
      ...customerInformationMapper(
        generalProductInformationValues.companyDetails.agentInformation,
        generalProductInformationValues.companyDetails.agentInformation
          .companyId
        // isSent
      ),
    },
    isAgentInformationAvailable:
      generalProductInformationValues.companyDetails.agentInformation
        .isAgentInformationAvailable,
  };

  const gpiUlFileNumber = {
    id: generalProductInformationValues.companyDetails.ulFileNumber.id ?? null,
    isExistingULFileNumber:
      generalProductInformationValues.companyDetails.ulFileNumber
        .hasExistingULFileNumber,
    isULCBCertificateNumber:
      generalProductInformationValues.companyDetails.ulFileNumber
        .isULCBCertificateNumber,
    isOtherGMACertificate:
      generalProductInformationValues.companyDetails.ulFileNumber
        .isOtherGMACertificate,
    fileNumber:
      generalProductInformationValues.companyDetails.ulFileNumber.fileNumber,
    volumeNumber:
      generalProductInformationValues.companyDetails.ulFileNumber.volumeNumber,
    sectionNumber:
      generalProductInformationValues.companyDetails.ulFileNumber.sectionNumber,
    certificateId:
      generalProductInformationValues.companyDetails.ulFileNumber.certificateId,
    certificateScheme:
      generalProductInformationValues.companyDetails.ulFileNumber
        .certificateScheme,
    certificateNumber:
      generalProductInformationValues.companyDetails.ulFileNumber
        .certificateNumber,
    isCustomerInput:
      generalProductInformationValues.companyDetails.ulFileNumber
        .isCustomerInput,
  };

  const manufacturerInfo = {
    id:
      generalProductInformationValues.companyDetails.manufacturerInformation
        .manufacturerInfo?.id ?? null,
    gPICompanyContactInfoId:
      generalProductInformationValues.companyDetails.manufacturerInformation
        .manufacturerInfo?.gPICompanyContactInfoId ?? null,
    gPICompanyContactInfo: {
      ...customerInformationMapper(
        generalProductInformationValues.companyDetails.manufacturerInformation,
        generalProductInformationValues.companyDetails.manufacturerInformation
          .companyId
        // isSent
      ),
    },
    IsSameAsApplicant:
      generalProductInformationValues.companyDetails.manufacturerInformation
        .isSameAsApplicant,
  };

  const factories =
    generalProductInformationValues.companyDetails.factorySiteInformation.factorySites.map(
      (factory) => ({
        id: factory.id ?? null,
        generalProductInfoId: id,
        gpiCompanyContactInfoId: factory.gpiCompanyContactInfoId ?? null,
        gpiCompanyContactInfo: customerInformationMapper(
          factory,
          factory.companyId
          // isSent
        ),
        isSameAsApplicant:
          factory.source === FACTORY_SITE_INFO_SOURCE.SAME_AS_APPLICANT,
        isSameAsManufacturer:
          factory.source === FACTORY_SITE_INFO_SOURCE.SAME_AS_MANUFACTURER,
        isManualInput: factory.source === FACTORY_SITE_INFO_SOURCE.MANUAL_INPUT,
        isFromCertDetails: factory.isFromCertDetails,
        isSelected: factory.isSelected,
      })
    );

  const gpiProductTypeId =
    generalProductInformationValues.productDetails.productType.id ?? null;

  const gpiApplicantInfoId =
    generalProductInformationValues.companyDetails.applicantInformation
      .gPIApplicantInfoId ?? null;

  const gpiAgentInfoId =
    generalProductInformationValues.companyDetails.agentInformation
      .gPIAgentInfoId ?? null;

  const gpiUlFileNumberId =
    generalProductInformationValues.companyDetails.ulFileNumber
      .gpiUlFileNumberId ?? null;

  const gpiManufacturerInfoId =
    generalProductInformationValues.companyDetails.manufacturerInformation
      .gpiManufacturerInfoId ?? null;

  const gPIVoltageDetailsId =
    generalProductInformationValues.productDetails.voltageDetails.id ?? null;

  const gpiRadioDetailsId =
    generalProductInformationValues.productDetails.radioDetails.id ?? null;

  const baseModelId = generalProductInformationValues.productDetails.productType
    .baseModel
    ? generalProductInformationValues.productDetails.productType.baseModel
      .baseModelId
    : null;

  const gpiProductType = {
    id: gpiProductTypeId ?? null,
    productName:
      generalProductInformationValues.productDetails.productType.productName,
    baseModelId,
    baseModel: generalProductInformationValues.productDetails.productType
      .baseModel
      ? {
        baseModelId,
        ...generalProductInformationValues.productDetails.productType
          .baseModel,
      }
      : null,
    modelVariants:
      generalProductInformationValues.productDetails?.productType?.modelVariants?.map(
        (m) => ({
          gpiProductTypeId,
          modelVariant: m,
        })
      ) || [],
    productTypes:
      generalProductInformationValues.productDetails?.productType?.productTypes,
    marketingName:
      generalProductInformationValues.productDetails.productType.marketingName,
    productDescription:
      generalProductInformationValues.productDetails.productType
        .productDescription,
    productType:
      generalProductInformationValues.productDetails.productType.productType ??
      "",
    productTypeId:
      generalProductInformationValues.productDetails.productType
        .productTypeId ?? "",
    brandOrTrademark:
      generalProductInformationValues.productDetails.productType
        .brandOrTrademark,
    brandOrTrademarkAttachments:
      generalProductInformationValues.productDetails.productType.brandOrTrademarkAttachments?.filter(
        (b) => b.status === FILE_STATUS.SUCCESS
      ),
  };

  const localImpReps =
    generalProductInformationValues.localRepImporterInformation.map(
      (localRep) => ({
        id: localRep.id ?? null,
        generalProductInfoId: id,
        schemeId: localRep.schemeId,
        schemeName: localRep.schemeName,
        uLToProvide: localRep.uLToProvide,
        representatives: localRep.representatives.map((rep) =>
          customerInformationMapper(rep)
        ),
      })
    );
  const isACVoltage =
    generalProductInformationValues.productDetails.voltageDetails
      .voltageType === "AC";

  const isDCVoltage =
    generalProductInformationValues.productDetails.voltageDetails
      .voltageType === "DC";

  const containsBattery =
    isDCVoltage &&
    generalProductInformationValues.productDetails.voltageDetails
      .containsBattery;

  const batteryType = containsBattery
    ? generalProductInformationValues.productDetails.voltageDetails.batteryType
    : "";

  const gpiVoltageDetails = {
    id: gPIVoltageDetailsId,
    voltageType:
      generalProductInformationValues.productDetails.voltageDetails.voltageType,
    productRatings:
      generalProductInformationValues.productDetails.voltageDetails
        .productRatings,
    connectsToMainVoltage:
      isDCVoltage &&
      generalProductInformationValues.productDetails.voltageDetails
        .connectsToMainVoltage,
    containsBattery,
    externalPowerSupplyWillBeShipped:
      isDCVoltage &&
      generalProductInformationValues.productDetails.voltageDetails
        .externalPowerSupplyWillBeShipped,
    isBatteryUserReplaceable:
      containsBattery &&
      generalProductInformationValues.productDetails.voltageDetails
        .isBatteryUserReplaceable,
    willBatteryBeShipped:
      containsBattery &&
      generalProductInformationValues.productDetails.voltageDetails
        .willBatteryBeShipped,
    batteryType,
    otherBatteryType:
      batteryType === "Other"
        ? generalProductInformationValues.productDetails.voltageDetails
          .otherBatteryType
        : "",
    ratedCurrent:
      generalProductInformationValues.productDetails.voltageDetails
        .ratedCurrent,
    ratedFrequency: isACVoltage
      ? generalProductInformationValues.productDetails.voltageDetails
        .ratedFrequency
      : "",
    operatingTemperatureRange:
      generalProductInformationValues.productDetails.voltageDetails
        .operatingTemperatureRange,
    harmonizedShippingCode:
      generalProductInformationValues.productDetails.voltageDetails
        .harmonizedShippingCode,
    operationClasses:
      generalProductInformationValues.productDetails?.voltageDetails?.operationClasses?.map(
        (o) => ({
          ...o,
          gPIVoltageDetailsId:
            generalProductInformationValues.productDetails.voltageDetails.id ??
            null,
        })
      ) || [],
    otherOperationClass:
      generalProductInformationValues.productDetails.voltageDetails.operationClasses?.some(
        (o) => o.operationClass === "Other"
      )
        ? generalProductInformationValues.productDetails.voltageDetails
          .otherOperationClass
        : "",
    productClassificationClass1:
      isACVoltage &&
      generalProductInformationValues.productDetails.voltageDetails
        .productClassificationClass1,
    productClassificationClass2:
      isACVoltage &&
      generalProductInformationValues.productDetails.voltageDetails
        .productClassificationClass2,
    productClassificationClass3: isDCVoltage,
  };

  const gpiRadioDetails = {
    id: gpiRadioDetailsId,
    otherEquipmentRadioType:
      generalProductInformationValues.productDetails.radioDetails
        .otherEquipmentRadioType,
    radioSpecificationComment:
      generalProductInformationValues.productDetails.radioDetails
        .radioSpecificationComment,
    equipmentRadioTypes:
      generalProductInformationValues.productDetails.radioDetails
        .equipmentRadioTypes,
    radios: generalProductInformationValues.productDetails.radioDetails.radios,
  };

  const gpiHandlerInfoId =
    generalProductInformationValues.ulProjectHandlerDetails.ulProjectHandler
      .id ?? null;

  const handlerInfo = {
    id: gpiHandlerInfoId,
    officeName:
      generalProductInformationValues.ulProjectHandlerDetails.ulProjectHandler
        .officeName,
    officeAddress:
      generalProductInformationValues.ulProjectHandlerDetails.ulProjectHandler
        .officeAddress,
    emailAddress:
      generalProductInformationValues.ulProjectHandlerDetails.ulProjectHandler
        .emailAddress,
    telephoneNumber:
      generalProductInformationValues.ulProjectHandlerDetails.ulProjectHandler
        .telephoneNumber,
    name: generalProductInformationValues.ulProjectHandlerDetails
      .ulProjectHandler.name,
    country:
      generalProductInformationValues.ulProjectHandlerDetails.ulProjectHandler
        .country,
  };

  const gpiFormName = generalProductInformationValues.gpiFormName;

  const validationWorkflowPackageId =
    workflowPackageIdParam || workflowPackageId;

  const data = {
    stepIndexSavePoint: activeStepIndex,
    gpiFormName,
    gpiProductTypeId,
    id,
    workflowPackageId,
    gpiApplicantInfoId,
    applicantInfo,
    gpiAgentInfoId,
    agentInfo,
    gpiUlFileNumberId,
    gpiUlFileNumber,
    gpiManufacturerInfoId,
    manufacturerInfo,
    factories,
    gpiProductType,
    gpiVoltageDetails,
    gPIVoltageDetailsId,
    gpiRadioDetails,
    gpiRadioDetailsId,
    gpiHandlerInfoId,
    handlerInfo,
    localImpReps,
    gPICustomerInputValidationParametersModel: validationWorkflowPackageId
      ? {
        workflowPackageId: validationWorkflowPackageId,
      }
      : null,
    finishedByCustomerEmail: customerEmailAddress,
    customerLinkId: linkId,
  };

  return data;
};

export const customerInformationMapper = (customerInformation, companyId) => {
  return {
    id: customerInformation.contactId,
    contactName: customerInformation.contactPerson,
    titleAndPosition: customerInformation.title,
    phoneNumber: customerInformation.phone,
    faxNumber: customerInformation.fax,
    emailAddress: customerInformation.email,
    Website: customerInformation.website,
    Director: customerInformation.directorName,
    gpiCompanyInfo: {
      id: companyId ?? null,
      externalId: null,
      partySiteNumber: customerInformation.partySiteNumber,
      companyName: customerInformation.companyName,
      streetAddress: customerInformation.streetAddress,
      city: customerInformation.city,
      stateOrProvince: customerInformation.stateOrProvince,
      zipCode: customerInformation.zipCode,
      country: customerInformation.country,
      hasSelectedPsn: customerInformation.hasSelectedPsn,
      hasSelectedCompanyName: customerInformation.hasSelectedCompanyName,
    },
  };
};
