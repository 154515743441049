import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GeneralProductInformationService from "../../../services/GeneralProductInformationService";
import ProductTypeService from "../../../services/ProductTypeService";
import classes from "./GpiCopyFormModal.module.scss";

import CompanyInformation from "./gpi-copy-form-modal-template/CompanyInformationTemplate";
import ExistingCertificates from "./gpi-copy-form-modal-template/ExistingCertificatesTemplate";
import FactorySiteInformation from "./gpi-copy-form-modal-template/FactorySiteInformationTemplate";
import LocalRepImporter from "./gpi-copy-form-modal-template/LocalRepImporterTemplate";
import ProductDetails from "./gpi-copy-form-modal-template/ProductDetailsTemplate";
import RadioDetails from "./gpi-copy-form-modal-template/RadioDetailsTemplate";

const GpiCopyFormModal = ({ dataFields, selectedGpiForm, visible, onHide }) => {
  const history = useHistory();
  const [selectedFields, setSelectedFields] = useState([]);
  const [gPIInformation, setGPIInformation] = useState();
  const [activeTab, setActiveTab] = useState();
  const [localRepListSchemes, setLocalRepListSchemes] = useState([]);
  const gpiCopyFormKeyPrefix = "gpi-copy-form-modal";

  const header = () => {
    return (
      <div className={classes["modal-header"]}>
        <div>Select field(s) to be copied</div>
      </div>
    );
  };

  const footer = () => {
    return (
      <div className={`${classes["modal-footer"]} text-center`}>
        <Button
          className="ul-button -app-primary"
          disabled={selectedFields.length === 0}
          onClick={() => handleCreateCopy()}
        >
          Create Copy
        </Button>
        <Button className="ul-button -app-secondary" onClick={onHide}>
          Cancel
        </Button>
      </div>
    );
  };

  const loadLocalRepListSchemes = async () => {
    const localRepListSchemes =
      await GeneralProductInformationService.getLocalRepListScheme();
    setLocalRepListSchemes(localRepListSchemes);
  };

  const onSelectedFieldChange = (e) => {
    if (e.value.id === "selectAll") {
      if (e.checked) {
        setSelectedFields(dataFields);
      } else {
        setSelectedFields([]);
      }

      return;
    }

    let _selectedFields = [...selectedFields];

    if (e.checked) {
      _selectedFields.push(e.value);
    } else {
      _selectedFields = _selectedFields.filter((x) => x.id !== e.value.id);
    }

    if (_selectedFields[0]?.id === "selectAll") _selectedFields.shift();
    setSelectedFields(_selectedFields);
  };

  const handleNavigateToGpiForm = (parameterUrl) => {
    const navigationInfo =
      "navigateToGpiCopyForm_" + selectedGpiForm.id + "_" + parameterUrl;
    window.parent.postMessage(navigationInfo, "*");

    if (process.env.NODE_ENV === "development") {
      setTimeout(() => {
        history.push("/general-product-information/form" + parameterUrl);
      });
    }
  };

  const handleCreateCopy = () => {
    const selectedFieldsUrlParam = selectedFields
      .map((data) => data.id + "=true")
      .join("&");

    const urlParameters =
      "?id=" + selectedGpiForm.id + "&isCopy=true&" + selectedFieldsUrlParam;

    handleNavigateToGpiForm(urlParameters);
    visible = false;
  };

  const handleFieldNameClick = async (entity) => {
    setActiveTab("");

    const response = await GeneralProductInformationService.getCopyGPIForm(selectedGpiForm.id, entity);

    if (response?.isSuccess) {

      if (entity === "applicantInformation") {
        setGPIInformation(
          response.data?.applicantInfo?.gPICompanyContactInfo
        );
      } else if (entity === "agentInformation") {
        setGPIInformation(
          response.data?.agentInfo?.gPICompanyContactInfo
        );
      } else if (entity === "existingCertificates") {
        setGPIInformation(
          response.data?.gPIULFileNumber
        );
      } else if (entity === "manufacturerInformation") {
        setGPIInformation(
          response.data?.manufacturerInfo?.gPICompanyContactInfo
        );
      } else if (entity === "factorySiteInformation") {
        setGPIInformation(
          response.data?.factories
        );
      } else if (entity === "productDetails") {
        setGPIInformation(response.data);
      } else if (entity === "radioDetails") {
        setGPIInformation(
          response.data?.gPIRadioDetails
        );
      } else if (entity === "localRepImporter") {
        const localImpReps =
          response.data?.localImpReps.map((l) => {
            const scheme = localRepListSchemes.find(
              (s) => s.schemeId === l.schemeId
            );

            return {
              scheme,
              ...l,
            };
          });

        setGPIInformation(localImpReps);
      }

      setActiveTab(entity);
    };

  };

  const getSelectedRadioTechnologies = (radio) => {
    const radioTechnology = radio.radioTechnology;
    const radioTechnologyTypes = radio.radioTechnologyTypes
      ? [
        ...radio.radioTechnologyTypes.filter(
          (x) => x.radioTechnologyType !== "Other"
        ),
      ]
      : [];

    if (radio.otherRadioTechnologyType)
      radioTechnologyTypes.push({
        radioTechnologyType: radio.otherRadioTechnologyType,
      });

    return radioTechnology
      ? radioTechnology +
      (radioTechnologyTypes.length > 0
        ? ": " +
        radioTechnologyTypes.map((r) => r.radioTechnologyType).join(", ")
        : "")
      : "";
  };

  const getModulationTypes = (radio) => {
    let result = [];

    if (radio.modulationTypes) {
      result = radio.modulationTypes
        .filter((e) => e.modulationType !== "Other")
        .map((e) => e.modulationType);

      if (radio.modulationTypes.some((e) => e.modulationType === "Other"))
        result.push(radio.otherModulationType);
    }

    return result.join(", ");
  };

  const getEquipmentRadioTypes = (radioDetails) => {
    let result = [];

    if (radioDetails && radioDetails.equipmentRadioTypes) {
      result = radioDetails.equipmentRadioTypes
        .filter((e) => e.equipmentRadioType !== "Other")
        .map((e) => e.equipmentRadioType);

      if (
        radioDetails.equipmentRadioTypes.some(
          (e) => e.equipmentRadioType === "Other"
        )
      )
        result.push(radioDetails.otherEquipmentRadioType);
    }

    return result.join(", ");
  };

  const getOperationClasses = (voltageDetails) => {
    let result = [];

    if (voltageDetails && voltageDetails.operationClasses) {
      result = voltageDetails.operationClasses
        .filter((e) => e.operationClass !== "Other")
        .map((e) => e.operationClass);

      if (
        voltageDetails.operationClasses.some(
          (e) => e.operationClass === "Other"
        )
      )
        result.push(voltageDetails.otherOperationClass);
    }

    return result.join(", ");
  };

  const getModelVariants = (modelVariants) => {
    if (modelVariants) {
      return modelVariants.map((m) => m.modelVariant.modelName).join(", ");
    }

    return "";
  };

  const getNotULProvidedLocalReps = (localImpReps) => {
    if (!localImpReps) return [];

    return localImpReps?.filter((l) => !l.scheme?.hasMultipleRepresentative);
  };

  const getULProvidedLocalReps = (localImpReps) => {
    if (!localImpReps) return [];

    return localImpReps?.filter((l) => l.scheme?.hasMultipleRepresentative);
  };

  useEffect(() => {
    setSelectedFields([]);
    loadLocalRepListSchemes();
    setActiveTab("");
  }, [visible]);

  return (
    <Dialog
      visible={visible}
      closable={true}
      draggable={false}
      resizable={false}
      header={header}
      onHide={onHide}
      footer={footer}
      style={{ width: "70%" }}
      position="center"
      className={classes["container"]}
    >
      <div className={classes["modal-content"]}>
        <div className={classes["modal-left-content"]}>
          {dataFields.map((data, index) => {
            return (
              <div key={`gpi-copy-form-wrapper-${index}`}>
                {data.id === "selectAll" ? (
                  <div key={`${gpiCopyFormKeyPrefix}-${data.id}-${index}`} className={classes["data-field-select-all"]}>
                    <Checkbox
                      inputId={data.id}
                      value={data}
                      onChange={onSelectedFieldChange}
                      checked={selectedFields.some(
                        (item) => item.id === data.id
                      )}
                    />
                    <label>&nbsp;&nbsp;{data.value}</label>
                  </div>
                ) : (
                  <div key={`${gpiCopyFormKeyPrefix}-${data.id}-${index}`} className={classes["data-fields"]}>
                    <Button
                      className={`ul-button -app-secondary ${classes["data-field-button"]
                        } ${activeTab === data.id &&
                        classes["data-field-button-active"]
                        }`}
                      onClick={() => handleFieldNameClick(data.id)}
                    >
                      <Checkbox
                        inputId={data.id}
                        value={data}
                        onChange={onSelectedFieldChange}
                        checked={selectedFields.some(
                          (item) => item.id === data.id
                        )}
                      />
                      <label>&nbsp;&nbsp;{data.value}</label>
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={classes["modal-right-content"]}>
          <div>
            {(activeTab === "applicantInformation" ||
              activeTab === "agentInformation" ||
              activeTab === "manufacturerInformation") && (
                <CompanyInformation fieldValue={gPIInformation} />
              )}

            {activeTab === "factorySiteInformation" && (
              <FactorySiteInformation fieldValue={gPIInformation} />
            )}

            {activeTab === "existingCertificates" && (
              <ExistingCertificates fieldValue={gPIInformation} />
            )}

            {activeTab === "productDetails" && (
              <ProductDetails
                fieldValue={gPIInformation}
                getModelVariants={getModelVariants}
                getOperationClasses={getOperationClasses}
              />
            )}

            {activeTab === "radioDetails" && (
              <RadioDetails
                fieldValue={gPIInformation}
                getEquipmentRadioTypes={getEquipmentRadioTypes}
                getSelectedRadioTechnologies={getSelectedRadioTechnologies}
                getModulationTypes={getModulationTypes}
              />
            )}

            {activeTab === "localRepImporter" && (
              <LocalRepImporter
                fieldValue={gPIInformation}
                notULProvidedLocalReps={getNotULProvidedLocalReps}
                ulProvidedLocalReps={getULProvidedLocalReps}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GpiCopyFormModal;
