import {
  faAnglesLeft,
  faAnglesRight,
  faCheck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";
import { Timeline } from "primereact/timeline";
import { useEffect, useState } from "react";
import CertificateListService from "../../../../services/CertificateListService";
import "./BulkImport.scss";

import { confirmDialog } from "primereact/confirmdialog";
import { ProgressBar } from "primereact/progressbar";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCertDetailValues,
  selectProdDetailValues,
  selectValues, updateLineDetails,
  updateOrderLineNumbers,
  updateSelectedOrderLineNumber, updateSelectedProjectDetails
} from "../../../../features/createCertificate/createCertificateSlice";
import ProjectDetailsService from "../../../../services/ProjectDetailsService";
import CloseIcon from "../../../../shared/close-icon/CloseIcon";
import useToastr from "./../../../../hooks/useToastr";
import ImportResult from "./import-results/ImportResult";
import SelectTemplate from "./select-template/SelectTemplate";
import UploadFiles from "./upload-files/UploadFiles";

const BulkImport = (props) => {
  const dispatch = useDispatch();
  const projectDetailsValues = useSelector(selectValues);
  const productDetailsValues = useSelector(selectProdDetailValues);
  const certificateDetailsValues = useSelector(selectCertDetailValues);

  const steppedBackgroundColor = "#0088cc";
  const steppedTextColor = "#fff";
  const unSteppedBackgroundColor = "#d7dbe4";
  const unSteppedTextColor = "#d7dbe4";
  const initialSteps = [{ number: 1 }, { number: 2 }, { number: 3 }];

  const [currentStep, setCurrentStep] = useState(1);
  const [schemeList, setSchemeList] = useState([]);
  const [modalSteps, setModalSteps] = useState(initialSteps);
  const [isValidStep, setIsValidStep] = useState({
    2: false,
    3: false,
  });
  const { showSuccess, showError } = useToastr();

  const modalHeader = {
    1: "Product Bulk Import - Select Template",
    2: "Product Bulk Import - Upload Files",
    3: "Product Bulk Import - Import Result",
  };

  const { displayBulkImportModal, dialogFuncMap } = props;

  const [uploadedFiles, setuploadedFiles] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const [totalUploadedFiles, setTotalUploadedFiles] = useState(0);

  const reset = () => {
    setCurrentStep(1);
  };

  const onHide = (name) => {
    setCurrentStep(1);
    setModalSteps(initialSteps);
    setuploadedFiles([]);
    dialogFuncMap[`${name}`](false);
    dispatch(updateSelectedProjectDetails(null));
    dispatch(updateLineDetails([]));
    dispatch(updateOrderLineNumbers([]));
    dispatch(updateSelectedOrderLineNumber(null));
  };

  const onBack = () => {
    setCurrentStep((prevState) => {
      let newState = prevState;
      if (prevState > 1) {
        newState = prevState - 1;
      }
      if (newState === 1) {
        setModalSteps(initialSteps);
      }
      return newState;
    });
  };

  const hasUploadedFile = () => uploadedFiles.length > 0;

  const showCloseConfirmationDialog = () => {
    confirmDialog({
      message:
        "You have unsaved changes. Are you sure you want to cancel the upload?",
      header: "Close Product Bulk Import Window",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      rejectClassName: "p-button-secondary",
      accept: () => onHide("displayBulkImportModal"),
    });
  };

  const onCancel = () => {
    if (hasUploadedFile()) {
      showCloseConfirmationDialog();
      return;
    }

    onHide("displayBulkImportModal");
  };

  const onContinue = () => {
    setCurrentStep((prevState) => {
      let newState = prevState;
      if (prevState < 4) {
        newState = prevState + 1;
      }
      return newState;
    });
  };

  const handleUpload = async (file) => {
    if (file) {
      if (!isValidFile(file.name)) {
        showError(
          "Error!",
          "Bulk Import, Selected file format is not supported."
        );
        return;
      }

      setuploadedFiles([
        ...uploadedFiles,
        { fileName: file.name, FileUpload: file, ProductType: "test" },
      ]);
      // const formData = new FormData();
      // formData.append("file", file, file.name);
      // setIsUploading(true);
      // BulkImportService.uploadBulkImportTemplate(formData, uploadProgressEvent);
    }
  };

  const handleRemoveItem = async (fileName) => {
    setuploadedFiles(uploadedFiles.filter((x) => x.fileName != fileName));
  };

  const uploadProgressEvent = (progressEvent) => {
    const { loaded, total } = progressEvent;
    const percent = Math.floor((loaded * 100) / total);
    setUploadPercentage(percent);

    if (percent === 100) {
      setTimeout(() => {
        setIsUploading(false);
        setTotalUploadedFiles(uploadedFiles.length);
        setuploadedFiles([]);
        onContinue();
      }, 2000);
    }
  };

  const fileUpload = async () => {
    setIsUploading(true);
    const formData = new FormData();

    for (let i = 0; i <= uploadedFiles.length - 1; i++) {
      formData.append("files", uploadedFiles[i].FileUpload);
    }

    CertificateListService.uploadBulkImportTemplate(
      formData,
      uploadProgressEvent
    );
  };

  const isValidFile = (fileName) => {
    var ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (ext.toLowerCase() === "xlsx" || ext.toLowerCase() === "xls") {
      return true;
    } else {
      return false;
    }
  };

  const customizedMarker = (item) => {
    return (
      <>
        <Badge
          value={
            item.number < currentStep ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              item.number
            )
          }
          style={{
            backgroundColor:
              item.number <= currentStep
                ? steppedBackgroundColor
                : unSteppedBackgroundColor,
            color:
              item.number <= currentStep
                ? steppedTextColor
                : unSteppedTextColor,
            borderRadius: "50%",
          }}
          size="xlarge"
        ></Badge>
      </>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <hr className="modal-footer-hr" />
        {!isUploading && (
          <div>
            {currentStep === 1 && (
              <div>
                <button
                  className="ul-button -medium -icon add-new-cert-cancel-btn"
                  onClick={onCancel}
                >
                  <span>CANCEL</span>
                </button>

                <button
                  className="ul-button -app-primary -medium -icon add-new-cert-continue-btn"
                  onClick={onContinue}
                >
                  <span>CONTINUE&nbsp;</span>
                  <FontAwesomeIcon icon={faAnglesRight} />
                </button>
              </div>
            )}

            {currentStep === 2 && (
              <div>
                <button
                  className="ul-button -app-secondary -medium -icon float-left add-new-cert-back-btn"
                  onClick={onBack}
                >
                  <FontAwesomeIcon icon={faAnglesLeft} />
                  <span>&nbsp;BACK</span>
                </button>
                <button
                  className="ul-button -medium -icon add-new-cert-cancel-btn"
                  onClick={onCancel}
                >
                  <span>CANCEL</span>
                </button>

                {uploadedFiles.length == 0 ? (
                  <button
                    className="ul-button -app-primary -medium -icon add-new-cert-continue-btn"
                    disabled={true}
                  >
                    <span>CONTINUE&nbsp;</span>
                    <FontAwesomeIcon icon={faAnglesRight} />
                  </button>
                ) : (
                  <button
                    className="ul-button -app-primary -medium -icon add-new-cert-continue-btn"
                    onClick={fileUpload}
                  >
                    <span>CONTINUE&nbsp;</span>
                    <FontAwesomeIcon icon={faAnglesRight} />
                  </button>
                )}
              </div>
            )}

            {currentStep === 3 && (
              <div className="centered">
                <button
                  className="ul-button -app-primary -large -icon close-btn"
                  onClick={onCloseModal}
                >
                  <span>CLOSE</span>
                </button>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const onCloseModal = () => {
    if (hasUploadedFile()) {
      showCloseConfirmationDialog();
      return;
    }

    onHide("displayBulkImportModal");
  };

  return (
    <Dialog
      header={modalHeader[currentStep]}
      visible={displayBulkImportModal}
      style={{ width: "880px", minHeight: "521px", maxHeight: "80vh" }}
      footer={renderFooter("displayBulkImportModal")}
      onHide={() => onHide("displayBulkImportModal")}
      icons={<CloseIcon onCloseEvent={onCloseModal} />}
      className="timeline-demo"
      closable={false}
    >
      <hr className="modal-header-hr" />
      <div className="add-new-cert-timeline">
        <Timeline
          value={modalSteps}
          align="top"
          layout="horizontal"
          className="customized-timeline"
          marker={customizedMarker}
        />
      </div>

      <div className="add-new-cert-modal-body">
        {currentStep === 1 ? (
          <div className="add-new-cert-modal-body-content">
            {" "}
            <SelectTemplate />{" "}
          </div>
        ) : null}
        {currentStep === 2 ? (
          <div className="add-new-cert-modal-body-content">
            {isUploading && (
              <ProgressBar
                value={uploadPercentage}
                className="progress-bar"
                color="#28a745"
              />
            )}
            {!isUploading && (
              <UploadFiles
                handleUpload={handleUpload}
                uploadedFiles={uploadedFiles}
                handleRemoveItem={handleRemoveItem}
              />
            )}
          </div>
        ) : null}
        {currentStep === 3 ? (
          <div className="add-new-cert-modal-body-content">
            {" "}
            <ImportResult
              reset={reset}
              totalUploadedFiles={totalUploadedFiles}
            />{" "}
          </div>
        ) : null}
      </div>
    </Dialog>
  );
};

export default BulkImport;
