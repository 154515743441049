import React from "react";
import classes from "./LoadingIndicator.module.scss";

const LoadingIndicator = ({ isLoading = false }) => {
  return (
    isLoading && (
      <div className={classes["loader-container"]}>
        <svg className="ul-progressCircle -medium">
          <circle className="ul-progressCircle__outer" cx="50%" cy="50%" r="35%" />
          <circle className="ul-progressCircle__inner" cx="50%" cy="50%" r="35%" />
        </svg>
      </div>
    )
  );
};

export default LoadingIndicator;
