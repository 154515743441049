import { Column } from "primereact/column";
import { useRef, useState } from "react";
import GpiListModelColumnPopOver from "../pages/general-product-information/GpiListModelColumnPopOver";
import GeneralProductInformationService from "../services/GeneralProductInformationService";
import { sortList } from "../utils/helpers/array.helpers";
import GpiListValidationStatusColumnPopOver from "../pages/general-product-information/GpiListValidationStatusColumnPopOver";
import ValidationStatus from "../enums/ValidationStatus";

// eslint-disable-next-line import/no-anonymous-default-export
export default (dataTableColumns, fetchedDataMapper, signalRConnection) => {
  const defaultPageInfo = {
    totalRecordCount: 0,
    pageCount: 50,
    currentPage: 0,
    dropdownOptions: [50, 100, 200, 500, 1000],
    lazyParams: {
      first: 0,
      page: 1,
      rows: 50,
      last: 0,
    },
  };

  const [fetchedItems, setFetchedItems] = useState([]);
  const [dataTableData, setDatatableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [called, setCalled] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columns, setColumns] = useState(dataTableColumns);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [refiners, setRefiners] = useState([]);
  const [isValidationBannerVisible, setIsValidationBannerVisible] =
    useState(false);
  const [validationBannerType, setValidationBannerType] = useState("");
  const [unfilteredData, setUnfilteredData] = useState([]);

  const [isRegisteredToGroup, setIsRegisteredToGroup] = useState(false);

  const currentPageInputRef = useRef();

  const handleSort = (sortField, sortOrder) => {
    setSortField(sortField);
    setSortOrder(sortOrder);
    setDatatableData(sortList(fetchedItems, sortField, sortOrder));
  };

  const fetchData = async (page, rows, searchPhrase, refiners) => {
    const _page = page > 0 ? page : 1;
    const take = rows;

    setSearchPhrase(searchPhrase);
    setRefiners(refiners);

    setCalled(true);

    let payload = {
      skip: _page,
      take: take,
      searchString: searchPhrase,
      sort: "ModifiedDateUtc",
      sortBy: "desc",
      ...refiners,
    };

    var result = await GeneralProductInformationService.getGPIListPaginated(
      payload
    );

    if (result?.isSuccess) {
      let data = result.data;
      var newData = fetchedDataMapper(data.records);

      newData = sortList(newData, "dateTimeLastModified", -1);

      return {
        data: newData,
        pageInfo: {
          totalRecordCount: data.total,
          currentPage: _page,
        },
        isSuccess: true,
      };
    } else {
      return {
        isSuccess: false,
      };
    }
  };

  const updateList = (newData) => {
    setDatatableData(newData);
    setFetchedItems(newData);
  };

  const populateDataTable = async (page, rows, searchPhrase, refiners) => {
    setLoading(true);
    const fetchedData = await fetchData(page, rows, searchPhrase, refiners);

    setPageInfo({
      ...pageInfo,
      currentPage: fetchedData.pageInfo.currentPage,
      totalRecordCount: fetchedData.pageInfo.totalRecordCount,
      pageCount: rows,
    });

    setFetchedItems(fetchedData.data);
    setDatatableData(fetchedData.data);
    setLoading(false);
    setSelectedRows([]);

    updateValidationBanner(fetchedData.data);
    setUnfilteredData(fetchedData.data);
    setIsRegisteredToGroup(false);
    const gpiIds = fetchedData.data.map((g) => g.id);
    registerToSignalRGroup(gpiIds);

    currentPageInputRef.current.value = fetchedData.pageInfo.currentPage;
  };

  const handleChangePage = (e) => {
    const page = e.rows === pageInfo.pageCount ? e.page : 1;

    populateDataTable(page, e.rows, searchPhrase, refiners);
  };

  const onColumnResize = (e) => {
    const { column, element } = e;

    const fields = columns.map((f) => {
      if (f.id === column.props.field) {
        return {
          ...f,
          width: element.offsetWidth,
        };
      } else {
        return f;
      }
    });

    setColumns(fields);
  };

  const handleOpenIpModelDetailsWindow = async (e, model) => {
    e.stopPropagation();

    setDatatableData(
      dataTableData.map((m) => ({
        ...m,
        ipModelDetailsOpen: m.id === model.id ? true : false,
        ipModelDetailsLoading: m.id === model.id ? true : false,
      }))
    );
  };

  const handleCloseIpModelDetailsWindow = (e) => {
    setDatatableData(
      dataTableData.map((m) => {
        return {
          ...m,
          ipModelDetailsOpen: false,
        };
      })
    );

    if (e) e.stopPropagation();
  };

  const handleOpenValidationPopover = async (e, gpi) => {
    e.stopPropagation();

    setDatatableData(
      dataTableData.map((g) => ({
        ...g,
        validationPopoverOpen: g.id === gpi,
      }))
    );
  };

  const handleCloseValidationPopover = (e) => {
    setDatatableData(
      dataTableData.map((g) => {
        return {
          ...g,
          validationPopoverOpen: false,
        };
      })
    );

    if (e) e.stopPropagation();
  };

  const handleRetryValidation = async (gpiId) => {
    const result = await GeneralProductInformationService.retryGPIValidation(
      gpiId
    );
    handleCloseValidationPopover();
  };

  const updateValidationBanner = (data) => {
    if (!data) return;

    const hasFailedValidation = data.some(
      (g) => g.validationStatus == ValidationStatus.Failed
    );
    const hasOngoingValidation = data.some(
      (g) => g.validationStatus == ValidationStatus.InProgress
    );
    setIsValidationBannerVisible(hasFailedValidation || hasOngoingValidation);
    setValidationBannerType(
      hasOngoingValidation ? "info" : hasFailedValidation ? "warning" : ""
    );
  };

  const registerToSignalRGroup = async (gpiIds) => {
    if (!gpiIds) {
      setIsRegisteredToGroup(false);
      return;
    }

    if (signalRConnection && !isRegisteredToGroup) {
      const result =
        await GeneralProductInformationService.joinGpiValidationGroup(gpiIds);

      setIsRegisteredToGroup(result == 200);
    }
  };

  const dynamicColumns = () =>
    columns?.map((col, i) => {
      return (
        <Column
          sortable
          key={col.id}
          columnKey={col.id}
          field={col.id}
          header={() => (
            <div>
              <span title={col.value}>{col.value}</span>
            </div>
          )}
          alignFrozen="left"
          style={{ flexGrow: 1, flexBasis: col.width, width: col.width }}
          body={(rowData, col) => {
            if (col.field === "models" && rowData[col.field]) {
              return (
                <>
                  <div
                    title={rowData[col.field]}
                    className="text-overflow-ellipsis column-fixed-width-left"
                  >
                    {rowData[col.field]}
                  </div>
                  <div>
                    <GpiListModelColumnPopOver
                      baseModel={rowData.baseModel}
                      modelVariants={rowData.modelVariants}
                      model={rowData}
                      loading={rowData.ipModelDetailsLoading}
                      isWindowOpen={rowData.ipModelDetailsOpen}
                      onOpenWindow={handleOpenIpModelDetailsWindow}
                      onCloseWindow={handleCloseIpModelDetailsWindow}
                      onClickOutside={handleCloseIpModelDetailsWindow}
                    />
                  </div>
                </>
              );
            } else if (col.field === "gpiFormName" && rowData[col.field]) {
              return (
                <>
                  {(rowData.validationStatus == ValidationStatus.InProgress ||
                    rowData.validationStatus == ValidationStatus.Failed) && (
                    <div>
                      <GpiListValidationStatusColumnPopOver
                        gpiId={rowData.id}
                        validationStatus={rowData.validationStatus}
                        isWindowOpen={rowData.validationPopoverOpen}
                        onOpenWindow={handleOpenValidationPopover}
                        onCloseWindow={handleCloseValidationPopover}
                        onClickOutside={handleCloseValidationPopover}
                        retryValidation={handleRetryValidation}
                      />
                    </div>
                  )}
                  <div
                    title={rowData[col.field]}
                    className="text-overflow-ellipsis column-fixed-width-left"
                  >
                    {rowData[col.field]}
                  </div>
                </>
              );
            } else {
              return (
                <div
                  title={rowData[col.field]}
                  className="text-overflow-ellipsis"
                >
                  {rowData[col.field]}
                </div>
              );
            }
          }}
        />
      );
    });

  return {
    populateDataTable,
    loading,
    called,
    dataTableData,
    pageInfo,
    handleChangePage,
    setSelectedRows,
    sortField,
    sortOrder,
    handleSort,
    setColumns,
    onColumnResize,
    selectedRows,
    currentPageInputRef,
    columns,
    generalProductInfoItems: fetchedItems,
    setGeneralProductInfoItems: setFetchedItems,
    setDatatableData,
    generalProductInfoItems: fetchedItems,
    dynamicColumns,
    searchPhrase,
    setLoading,
    updateList,
    isValidationBannerVisible,
    setIsValidationBannerVisible,
    validationBannerType,
    unfilteredData,
    setUnfilteredData,
    updateValidationBanner,
  };
};
