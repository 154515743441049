import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import classes from "./ProjectDeleteModal.module.scss";

export interface ProjectDeleteModalProps {
  showDeleteModal: boolean;
  setShowDeleteModal: Function;
  handleDeleteProject: Function;
  e2eProjectId: string;
  loading: boolean;
}

const ProjectDeleteModal = (props: ProjectDeleteModalProps) => {
  const removeOrderLineHeader = () => {
    return "Delete GMAWB Project Confirmation";
  };

  const deleteFooterTemplate = () => {
    return (
      <>
        <hr />
        <div className="ul-grid__column">
          <div className="ul-grid__row">
            <div className="ul-grid__column -x50">
              <button
                className={`ul-button -medium ${classes["cancel-button"]}`}
                onClick={() => props.setShowDeleteModal(false)}
                disabled={props.loading}
              >
                CANCEL
              </button>
            </div>
            <div className="ul-grid__column -x50">
              <button
                className={`ul-button -medium ${classes["confirm-button"]}`}
                onClick={() =>
                  props.handleDeleteProject(props.e2eProjectId)
                }
                disabled={props.loading}
              >
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const deleteModalContentTemplate = () => {
    return (
      <>
        <hr />
        <div className={`ul-grid__column ${classes["content"]}`}>
          <div className="ul-grid__column">
            Are you sure you want to delete this GMAWB Project?
          </div>
          <div className="ul-grid__column">Incomplete orders will be returned to the Project Work Queue (PWQ).</div> 
        </div>
      </>
    );
  };

  return (
    <Dialog
      id="modalDialog"
      header={removeOrderLineHeader}
      style={{ width: "700px" }}
      visible={props.showDeleteModal}
      onHide={() => props.setShowDeleteModal(false)}
      footer={deleteFooterTemplate}
      closeOnEscape
      className={`${classes["dialog-close-button"]}`}
    >
      {deleteModalContentTemplate()}
    </Dialog>
  );
};

export default ProjectDeleteModal;
