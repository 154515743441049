import { useEffect, useState } from "react";
import { randomString } from "../utils/helpers/string-format.helper";

const useLazyLoadDataTable = (initialItemsPerPageCount:number = 50, defaultSortField:string, dataTableRef:any, loadList:any) => {
const [sortField, setSortField] = useState<string>(defaultSortField);
const [sortOrder, setSortOrder] = useState<any>(-1);
const [scrollBotomEvent, setScrollBottomEvent] = useState("");
const [currentPageNo, setCurrentPageNo] = useState<number>(1);
const [itemsPerPage, setItemsPerPage] = useState<number>(initialItemsPerPageCount);
const [hasNextPage, setHasNextPage] = useState(false);
const [pageEvent, setPageEvent] = useState("");

useEffect(() => {
  loadList();
}, [pageEvent]);

 //Bind lazyload event on scroll
  useEffect(() => {
    const el = dataTableRef.current.el;
  if (el && el.children[0]) {
    const gridWrapper = el.children[0];

    gridWrapper.addEventListener("scroll", () => {
      if (
        gridWrapper.scrollHeight -
          gridWrapper.scrollTop -
          gridWrapper.clientHeight <
        1
      ) {
        setScrollBottomEvent(randomString());
      } 
    });

    return () => {
      gridWrapper.removeEventListener("scroll", () => {});
    };
  }
  }, []);

  useEffect(() => {
    if (!scrollBotomEvent) return;
    if (!hasNextPage) return;
    setCurrentPageNo((prevValue) => prevValue + 1);
    setPageEvent(randomString());
  }, [scrollBotomEvent]);

  
  const resetScroll = (dataTableRef: any) => {
    const el = dataTableRef.current.el;
    if (el && el.children[0]) {
      const gridWrapper = el.children[0];
      gridWrapper.scrollTop = 0;
    }
  };
  
  const handleSort = (e: any) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
    setCurrentPageNo(1);
    resetScroll(dataTableRef);
    setPageEvent(randomString());
  };

  const reloadList = () => {
    setCurrentPageNo(1);
    resetScroll(dataTableRef);
    setPageEvent(randomString());
  };

  return{
    resetScroll,
    scrollBotomEvent,
    handleSort,
    sortField,
    sortOrder,
    pageEvent,
    currentPageNo,
    itemsPerPage,
    setHasNextPage,
    reloadList
  }
}


export default useLazyLoadDataTable;
