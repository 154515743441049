const certProdListCustomFilterColumns = [
    {
      id: "creationDate",
      value: "Creation Date"
    },
    {
      id: "family_Series",
      value: " Family or Series"
    },
    {
      id: "issueDate",
      value: "Issue Date"
    },
    {
      id: "status",
      value: "Model Status"
    },
    {
      id: "ownerReference_PartySiteID",
      value: "Product Owner (Party Site Number)"
    },
    {
      id: "productType",
      value: "Product Type"
    },
    {
      id: "projectNumber",
      value: "Project Number"
    }
  ];  
  
  export default certProdListCustomFilterColumns;
  
