import * as yup from "yup";

const ulProjectHandlerSchema = yup.object({
  officeName: yup.string().required(),
  officeAddress: yup.string().required(),
  emailAddress: yup
    .string()
    .email("Please provide a valid email address")
    .required(),
  telephoneNumber: yup.string().nullable().required(),
  name: yup.string().required(),
  country: yup.string().required(),
});

export default yup.object({
  ulProjectHandler: ulProjectHandlerSchema,
});

export { ulProjectHandlerSchema };
