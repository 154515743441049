import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import classes from "./ConfirmationModal.module.scss";
import clsx from "clsx";

interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  visible: boolean;
  headerDisplay: string;
  buttonYesDisplay: string;
  buttonNoDisplay: string;
  confirmationMessageHTML: Element | JSX.Element | string;
  style?: object | undefined;
  className?: string;
}

const ConfirmationModal = ({
  onConfirm,
  onCancel,
  visible,
  headerDisplay,
  buttonYesDisplay,
  buttonNoDisplay,
  confirmationMessageHTML,
  style,
  className,
}: ConfirmationModalProps) => {
  const headerTemplate = () => {
    return <h3 className={classes["dialog-header__title"]}>{headerDisplay}</h3>;
  };

  const footerTemplate = () => {
    return (
      <div className={classes["dialog-footer"]}>
        <Button onClick={onConfirm} className={classes["btn-confirm"]}>
          {buttonYesDisplay}
        </Button>
        <Button onClick={onCancel} className={classes["btn-cancel"]}>
          {buttonNoDisplay}
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      className={clsx(classes["dialog"], className)}
      style={style || { width: "40vw" }}
      visible={visible}
      header={headerTemplate}
      onHide={onCancel}
      footer={footerTemplate}
    >
      <div className={classes["dialog-body"]}>{confirmationMessageHTML}</div>
    </Dialog>
  );
};

export default ConfirmationModal;
