import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import classes from "./PickList.module.scss";

interface PickListProps<T> {
  emptyMessage: string;
  containerClassName: string;
  sourceList: T[];
  sourceListItemTemplate: (item: T) => React.ComponentType<T>;
  selectedItems: T[];
  selectedItemTemplate: (item: T) => React.ComponentType<T>;
  keyField: keyof T & string;
  labelField: keyof T & string;
  onAddItem: (item: T) => void;
  onRemoveItem: (item: T) => void;
  errorMessage?: string | undefined;
}

const PickList = <T,>(props: PickListProps<T>) => {
  const {
    labelField,
    containerClassName,
    sourceList,
    sourceListItemTemplate,
    keyField,
    selectedItems,
    selectedItemTemplate,
    onAddItem,
    onRemoveItem,
    emptyMessage,
    errorMessage,
  } = props;

  const [searchText, setSearchText] = useState("");

  const [selectedSourceItem, setSelectedSourceItem] = useState<T | null>(null);
  const [selectedSelectedItem, setSelectedSelectedItem] = useState<T | null>(
    null
  );

  const handleSourceItemClick = (item: T) => {
    setSelectedSourceItem(item);
    setSelectedSelectedItem(null);
  };

  const handleSelectedItemClick = (item: T) => {
    setSelectedSelectedItem(item);
    setSelectedSourceItem(null);
  };

  const isSourceItemSelected = (item: T) =>
    selectedSourceItem && selectedSourceItem[keyField] === item[keyField];

  const isSelectedItemSelected = (item: T) =>
    selectedSelectedItem && selectedSelectedItem[keyField] === item[keyField];

  const handleAddClick = () => {
    selectedSourceItem && onAddItem(selectedSourceItem);
  };

  const handleRemoveClick = () => {
    selectedSelectedItem && onRemoveItem(selectedSelectedItem);
  };

  const filteredSourceList = sourceList.filter((x) => {
    return (
      String(x[labelField]).toUpperCase().includes(searchText.toUpperCase()) &&
      !selectedItems.some((f) => {
        return f[keyField] === x[keyField];
      })
    );
  });

  const isAddDisabled = !(
    selectedSourceItem &&
    filteredSourceList.some((x) => x[keyField] === selectedSourceItem[keyField])
  );

  const isRemoveDisabled = !(
    selectedSelectedItem &&
    selectedItems.some((x) => x[keyField] === selectedSelectedItem[keyField])
  );

  const hasSelectedItem = selectedItems.length > 0;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSourceItem(null);
    setSearchText(e.target.value);
  };

  return (
    <div
      className={`${classes["container"]} ${
        containerClassName ? containerClassName : ""
      }`}
    >
      <div className={classes["search-container"]}>
        <span className="p-input-icon-right w-100">
          <i className="pi pi-search font-weight-bold" />
          <InputText
            onChange={handleSearchChange}
            value={searchText}
            placeholder="Search"
            className={`input-search w-100 ${classes["search-input"]}`}
          />
        </span>
      </div>

      <div
        className={`${classes["source-list-container"]} source-list-container`}
      >
        {filteredSourceList.map((s) => (
          <button
            key={String(s[keyField])}
            className={`${classes["source-list-item"]} ${
              isSourceItemSelected(s) ? classes["selected"] : ""
            }`}
            onClick={() => handleSourceItemClick(s)}
          >
            {sourceListItemTemplate(s)}
          </button>
        ))}
      </div>

      <div className={`${classes["actions-container"]} actions-container`}>
        <div>
          <button
            onClick={handleAddClick}
            className={`${classes["btn-add"]} btn-add`}
            disabled={isAddDisabled}
          >
            Add {">"}
          </button>
        </div>

        <div>
          <button
            onClick={handleRemoveClick}
            className={`${classes["btn-remove"]} btn-remove`}
            disabled={isRemoveDisabled}
          >
            {"<"} Remove
          </button>
        </div>
      </div>

      <div className={classes["selected-items-container"]}>
        {!hasSelectedItem && (
          <div className={classes["empty-message"]}>{emptyMessage}</div>
        )}

        {selectedItems.map((s) => (
          <button
            key={String(s[keyField])}
            className={`${classes["selected-item"]} ${
              isSelectedItemSelected(s) ? " " + classes["selected"] : ""
            }`}
            onClick={() => handleSelectedItemClick(s)}
          >
            {selectedItemTemplate(s)}
          </button>
        ))}
      </div>

      {errorMessage && (
        <div className={classes["error-message-container"]}>
          <FontAwesomeIcon
            className={classes["error-icon"]}
            icon={faExclamationCircle}
          />

          <div className={classes["error-message"]}>{errorMessage}</div>
        </div>
      )}
    </div>
  );
};

export default PickList;
