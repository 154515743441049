import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ProjectTemplateMappingService from "../../../services/ProjectTemplateMappingService";
import { ProjectTemplateMappingHeaderModel } from "../../../shared/models/ProjectTemplateMappingHeader.model";
import FormTemplateError, {
  FormTemplateErrorProps,
} from "../../project-template-mapping/shared/component/FormTemplateError";
import { ProjectTemplateMappingModes } from "../../project-template-mapping/shared/enums/ProjectTemplateMappingModes";
import { ProjectTemplateStatus } from "../../project-template-mapping/shared/enums/ProjectTemplateStatus";
import classes from "./ProjectTemplateCopyModal.module.scss";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { formatVersion } from "../../../utils/helpers/version.helper";
import {
  truncateString,
  validateSpecialCharacters,
} from "../../../utils/helpers/input.helpers";

export interface ProjectTemplateCopyModalProps {
  showCopyModal: boolean;
  setShowCopyModal: Function;
  props: CopyModalProps;
  e2ETemplateName: string;
  setE2ETemplateName: React.Dispatch<React.SetStateAction<string>>;
}

export interface CopyModalProps {
  e2eTemplateId: string;
  flexTemplateId: string;
  flexTemplateName: string;
  flexCorrelationId: string;
  status: string;
  version: any;
}

const ProjectTemplateCopyModal = (
  componentProps: ProjectTemplateCopyModalProps
) => {
  const history = useHistory();
  const [templateNameError, setTemplateNameError] =
    useState<FormTemplateErrorProps>({
      visible: false,
      errorMessage: "",
      icon: faClose,
    });
  const [loadingApplyButton, setLoadingApplyButton] = useState<boolean>(false);
  const [isApplyDisabled, setIsApplyDisabled] = useState<boolean>(true);

  const templateNameLabel = "GMA WB Template Name";
  const invalidTemplateNameMessage = `Entered ${templateNameLabel} has reached the maximum character limit.`;

  const inputText = useRef<any>();
  const handleOnShow = () => {
    if (inputText.current) {
      inputText.current.focus();
      inputText.current.select();
    }
  };

  const updateTemplateNameError = (
    visible: boolean = false,
    errorMessage: string | null = ""
  ) => {
    setTemplateNameError((current: any) => ({
      ...current,
      visible,
      errorMessage,
    }));
  };

  const clearInputAndError = () => {
    componentProps.setE2ETemplateName("");
    updateTemplateNameError();
    setIsApplyDisabled(true);
  };

  const handleOnHide = () => {
    clearInputAndError();
    componentProps.setShowCopyModal(false);
  };

  const handleE2ETemplateName = (value: string) => {
    if (templateNameError.visible) updateTemplateNameError();

    const maximumTemplateNameLength = 100;
    const trimmedValue = value?.trim();
    const truncatedTemplateName = truncateString(
      trimmedValue
    );

    const { valid, errorMessage } = validateSpecialCharacters(
      truncatedTemplateName,
      templateNameLabel
    );

    setIsApplyDisabled(!trimmedValue || !valid);

    if (!valid) {
      updateTemplateNameError(true, errorMessage);
    } else if (trimmedValue.length > maximumTemplateNameLength) {
      updateTemplateNameError(true, invalidTemplateNameMessage);
    }

    componentProps.setE2ETemplateName(truncatedTemplateName);
  };

  const handleApplyClick = () => {
    setLoadingApplyButton(true);
    if (componentProps.props) {
      ProjectTemplateMappingService.verifyTemplateDuplicate({
        flexTemplateId: componentProps.props.flexTemplateId,
        templateName: componentProps.e2ETemplateName.trim(),
        correlationId: null,
      }).then((response) => {
        if (response.data.hasError === false) {
          if (componentProps.props) {
            const newTemplate: ProjectTemplateMappingHeaderModel = {
              id: componentProps.props.e2eTemplateId,
              correlationId: null,
              name: componentProps.e2ETemplateName.trim(),
              formattedVersion: componentProps.props.version,
              flexTemplateId: componentProps.props.flexTemplateId,
              flexTemplateName: componentProps.props.flexTemplateName,
              flexCorrelationId: componentProps.props.flexCorrelationId,
              templateStatus: ProjectTemplateStatus.Draft,
              templateMode: ProjectTemplateMappingModes.Copy,
              additionalInfoId: "",
            };
            componentProps.setShowCopyModal(false);
            history.push({
              pathname: "/template-admin-mapping",
              state: newTemplate,
            });
            setLoadingApplyButton(false);
          }
        } else {
          const CreateHyperlink = () => {
            if (!response.data.hasUsedInE2EProjects) {
              return <>{response.message}</>;
            }
            const manipulateErrorMessage = response.message.split("{0}");
            return (
              <>
                <span>
                  {manipulateErrorMessage[0]}
                  {manipulateErrorMessage[1]}
                </span>
              </>
            );
          };

          setTemplateNameError((current) => ({
            ...current,
            visible: true,
            errorMessage: <CreateHyperlink />,
          }));
          setLoadingApplyButton(false);
        }
      });
    }
  };

  const footerContent = () => {
    return (
      <div className={`${classes["footer-button__container"]}`}>
        <Button
          disabled={isApplyDisabled}
          className={`${classes["apply-button"]}`}
          label="Apply"
          onClick={() => handleApplyClick()}
          loading={loadingApplyButton}
        />
        <Button
          className={`${classes["cancel-button"]}`}
          label="Cancel"
          onClick={handleOnHide}
        />
      </div>
    );
  };

  return (
    <>
      <Dialog
        header="Create a Copy of GMA WB Template"
        visible={componentProps.showCopyModal}
        style={{ width: "40vw" }}
        onHide={handleOnHide}
        onShow={handleOnShow}
        footer={footerContent}
        draggable={false}
        className={`${classes["dialog-close-button"]}`}
      >
        <>
          <div className={`${classes["dialog-content"]}`}>
            <div className="ul-grid__row">
              <div className="ul-grid__column">
                <div className="column-fill">
                  <div className={`${classes["text-label"]}`}>
                    <label>
                      {templateNameLabel}
                      <span className={`${classes["required-field"]}`}> *</span>
                    </label>
                  </div>
                  <div className={`${classes["text-box__container"]}`}>
                    <InputText
                      className={`${classes["text-box"]} ${
                        templateNameError.visible
                          ? `p-invalid ${classes["text-box--error"]}`
                          : ""
                      }`}
                      value={componentProps.e2ETemplateName}
                      onChange={(e) => handleE2ETemplateName(e.target.value)}
                      ref={inputText}
                    />
                    {templateNameError.visible && (
                      <FormTemplateError
                        errorMessage={templateNameError.errorMessage}
                        visible={templateNameError.visible}
                        icon={templateNameError.icon}
                      />
                    )}
                  </div>
                </div>
                <div className={`${classes["template-details__container"]}`}>
                  <div className={`${classes["template-details__text"]}`}>
                    <label>Status: {componentProps.props.status}</label>
                  </div>
                  <div className={`${classes["template-details__text"]}`}>
                    <label>
                      Version: {formatVersion(componentProps.props.version)}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Dialog>
    </>
  );
};

export default ProjectTemplateCopyModal;
