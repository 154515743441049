import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsReadOnly,
  selectULProjectHandlerDetails,
  updateULProjectHandleDetails,
} from "../../../../features/generalProductInformation/generalProductInformationSlice";
import FormControl from "../../form/form-control/FormControl";
import { ulProjectHandlerSchema } from "../ul-project-handler-schema";

const ULProjectHandlerDetails = () => {
  const dispatch = useDispatch();
  const ulProjectHandlerValues = useSelector(selectULProjectHandlerDetails);
  const isReadOnly = useSelector(selectIsReadOnly);

  const handleChange = (field, value) => {
    dispatch(
      updateULProjectHandleDetails({
        ...ulProjectHandlerValues,
        [field]: value,
      })
    );
  };

  const getValueAndSchema = (field) => {
    return {
      schema: ulProjectHandlerSchema.fields[field],
      value: ulProjectHandlerValues[field],
    };
  };

  return (
    <div className="form-section">
      <div className="form-section-header">
        <h3 className="form-section-title">UL Project Handler Details</h3>
      </div>
      <div className="form-section-content">
        <FormControl
          label="UL Office Company Name"
          {...getValueAndSchema("officeName")}
          control={
            <InputText
              onChange={(e) => handleChange("officeName", e.target.value)}
              disabled={isReadOnly}
            />
          }
          controlClassName="officeName"
        />
        <FormControl
          label="UL Office Address"
          {...getValueAndSchema("officeAddress")}
          control={
            <InputText
              onChange={(e) => handleChange("officeAddress", e.target.value)}
              disabled={isReadOnly}
            />
          }
          controlClassName="officeAddress"
        />
        <div className="d-flex" style={{ gap: "30px" }}>
          <div className="col-6">
            <FormControl
              label="UL Handler's E-mail Address"
              {...getValueAndSchema("emailAddress")}
              control={
                <InputText
                  onChange={(e) => handleChange("emailAddress", e.target.value)}
                  disabled={isReadOnly}
                />
              }
              controlClassName="emailAddress"
            />
          </div>
          <div className="col-6">
            <FormControl
              label="UL Handler's Tel No."
              {...getValueAndSchema("telephoneNumber")}
              control={
                <InputText
                  onChange={(e) =>
                    handleChange("telephoneNumber", e.target.value)
                  }
                  disabled={isReadOnly}
                />
              }
              controlClassName="telephoneNumber"
            />
          </div>
        </div>
        <div className="d-flex" style={{ gap: "30px" }}>
          <div className="col-6">
            <FormControl
              label="UL Handler Name"
              {...getValueAndSchema("name")}
              control={
                <InputText
                  onChange={(e) => handleChange("name", e.target.value)}
                  disabled={isReadOnly}
                />
              }
            />
          </div>
          <div className="col-6">
            <FormControl
              label="Country Name of UL Handler"
              {...getValueAndSchema("country")}
              control={
                <InputText
                  onChange={(e) => handleChange("country", e.target.value)}
                  disabled={isReadOnly}
                />
              }
              controlClassName="country"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ULProjectHandlerDetails;
