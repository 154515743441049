import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    devPaths: [],
    commonDocs: [],
    countrySpecifics: [],
    testSamples: [],
    localReps: [],
    isLoadingDelPathData: false,
    isLoadingCommonDocsData: false,
    isLoadingTestSampleData: false,
    isLoadingCountrySpecificData: false,
    isLoadingLocalRepListData: false,
    showNotUploadedValue: false
  },
};

export const dkListSlice = createSlice({
  name: "dkList",
  initialState,
  reducers: {
    updateCommonDocs: (state, action) => {
      state.value.commonDocs = action.payload;
    },
    updateCountrySpecifics: (state, action) => {
      state.value.countrySpecifics = action.payload;
    },
    updateTestSamples: (state, action) => {
      state.value.testSamples = action.payload;
    },
    updateDeliveryPaths: (state, action) => {
      state.value.devPaths = action.payload;
    },
    updateLocalReps: (state, action) => {
      state.value.localReps = action.payload;
    },
    updateIsLoadingDelPathData: (state, action) => {
      state.value.isLoadingDelPathData = action.payload;
    },
    updateIsLoadingCommonDocsData: (state, action) => {
      state.value.isLoadingCommonDocsData = action.payload;
    },
    updateIsLoadingTestSampleData: (state, action) => {
      state.value.isLoadingTestSampleData = action.payload;
    },
    updateIsLoadingCountrySpecificData: (state, action) => {
      state.value.isLoadingCountrySpecificData = action.payload;
    },
    updateIsLoadingLocalRepListData: (state, action) => {
      state.value.isLoadingLocalRepListData = action.payload;
    },
    updateShowNotUploadedValue: (state, action) => {
      state.value.showNotUploadedValue = action.payload;
    }
  },
});

export const { updateCommonDocs, updateCountrySpecifics, updateTestSamples, updateDeliveryPaths,
   updateLocalReps, updateIsLoadingDelPathData, updateIsLoadingCommonDocsData, updateIsLoadingTestSampleData,
   updateIsLoadingCountrySpecificData, updateIsLoadingLocalRepListData, updateShowNotUploadedValue } =
  dkListSlice.actions;

export const values = (state) => state.dkList.value;

export default dkListSlice.reducer;
