import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import NotificationType from "../../enums/NotificationType";
import useToastr from "../../hooks/useToastr";
import NotificationService from "../../services/NotificationService";
import CustomPaginator from "../../shared/custom-paginator/CustomPaginator";
import INotification from "../../types/INotification";
import classes from "./NotificationList.module.scss";

interface NotificationDTColumn {
  id: string;
  value: string;
  width: string;
  link?: string;
}

const defaultPageInfo = {
  totalRecordCount: 0,
  pageCount: 50,
  currentPage: 1,
  dropdownOptions: [50, 100, 200, 500, 1000],
  lazyParams: {
    first: 0,
    page: 1,
    rows: 50,
    last: 0,
  },
};

const NotificationList = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<INotification[]>([]);
  const { showSuccess, showError, showWarning } = useToastr();

  const currentPageInputRef = useRef<any>();

  const AnyCustomPaginator = CustomPaginator as any;

  const datatableColumns: NotificationDTColumn[] = [
    {
      id: "notificationDate",
      value: "Date",
      width: "calc(15% - 40px)",
    },
    {
      id: "details",
      value: "Subject/Description",
      width: "35%",
    },
    {
      id: "gpiFormName",
      value: "GPI Form name",
      width: "30%",
    },
    {
      id: "gpiFormLink",
      value: "Open GPI form Link",
      width: "20%",
    },
  ];

  useEffect(() => {
    getNotifications(pageInfo.lazyParams.page, pageInfo.lazyParams.rows);
    markAllAsViewed();
  }, []);

  const refreshNotifications = () => {
    getNotifications(pageInfo.lazyParams.page, pageInfo.lazyParams.rows);
  };

  const getNotifications = async (page: number, rows: number) => {
    setLoading(true);
    setSelectedRows([]);

    setPageInfo({
      ...pageInfo,
      currentPage: page,
      pageCount: rows,
    });

    const { data } = await NotificationService.getNotifications({
      skip: (pageInfo.lazyParams.page - 1) * pageInfo.lazyParams.rows,
      take: rows,
    });

    currentPageInputRef.current.value = page;

    setPageInfo((oldPageInfo) => ({
      ...oldPageInfo,
      totalRecordCount: data.totalRecords,
    }));

    setNotifications(data.notifications);
    setLoading(false);
  };

  const markAllAsViewed = () => {
    NotificationService.markAllUserNotificationsAsViewed(
      NotificationType.DocumentManagement
    );
  };

  const handleChangePage = (e: any) => {
    getNotifications(e.page, e.rows);
  };

  const gpiFormNameBodyTemplate = (rowData: INotification) => {
    const { GPIFormName: gpiFormName } = JSON.parse(rowData.metadata);
    return gpiFormName;
  };

  const openGpiFormLinkBodyTemplate = (rowData: INotification) => {
    const parentUrl = document.location.ancestorOrigins[0];
    const { GeneralProductInfoId: id } = JSON.parse(rowData.metadata);
    const link =
      parentUrl + "/document/general-product-information/form?id=" + id;

    return (
      <a target="_blank" href={link} rel="noopener noreferrer">
        Open GPI form Link
      </a>
    );
  };

  const renderColumns = () => {
    return datatableColumns.map((col) => {
      let bodyTemplate;

      if (col.id === "gpiFormName") bodyTemplate = gpiFormNameBodyTemplate;
      else if (col.id === "gpiFormLink")
        bodyTemplate = openGpiFormLinkBodyTemplate;

      return (
        <Column
          key={col.id}
          body={bodyTemplate}
          columnKey={col.id}
          field={col.id}
          header={col.value}
          style={{ width: col.width }}
        />
      );
    });
  };

  const selectColumn = (
    <Column
      selectionMode="multiple"
      columnKey="select"
      alignFrozen="left"
      reorderable={false}
      frozen
      style={{ width: "40px" }}
      headerStyle={{ width: "40px" }}
    />
  );

  const handleDeleteNotifications = () => {
    confirmDialog({
      message: (
        <>
          <p>Are you sure you want to delete this notification(s)?</p>
          <p>Once deleted, it can't be recovered.</p>
        </>
      ),
      header: "Delete notification(s)",
      className: classes["delete-confirmation-dialog-one-ul-design"],
      accept: async () => {
        setLoading(true);
        const notificationIds = selectedRows.map((s) => s.notificationId);

        await NotificationService.deleteNotification(notificationIds);
        
        showSuccess("Success!", "Notification(s) deleted successfully.");
        refreshNotifications();
      },
    });
  };

  return (
    <div>
      <div className={classes["header"]}>
        <h2>Notifications</h2>

        <Button
          disabled={selectedRows.length === 0}
          onClick={handleDeleteNotifications}
          className={`p-button-danger p-button-text ${classes["btn-delete-notifications"]}`}
        >
          <FontAwesomeIcon className={classes["icon"]} icon={faTrash} />
          Delete Notifications
        </Button>
      </div>

      <div
        style={{
          maxHeight: "calc(100vh - 180px)",
          minHeight: "calc(100vh - 180px)",
          overflowY: "scroll",
        }}
      >
        <DataTable
          style={{minWidth: "100%"}}
          loading={loading}
          scrollDirection="both"
          lazy
          size="small"
          scrollable
          removableSort
          resizableColumns
          columnResizeMode="expand"
          responsiveLayout="scroll"
          value={notifications}
          onSelectionChange={({ value }) => {
            setSelectedRows(value);
          }}
          selection={selectedRows}
        >
          {selectColumn}
          {renderColumns()}
        </DataTable>
      </div>

      <AnyCustomPaginator
        {...pageInfo}
        onChangePage={handleChangePage}
        isOffsetPagination={true}
        isStrictNavigation={true}
        currentPageRef={currentPageInputRef}
      />
    </div>
  );
};

export default NotificationList;
