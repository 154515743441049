import { OverlayPanel } from "primereact/overlaypanel";
import classes from "./FlexTemplateInformationPopUp.module.scss";
import { useEffect, useState } from "react";
import ProjectTemplateMappingService from "../../../../../services/ProjectTemplateMappingService";
import {
  FlexProjectTemplateDetailModel,
  FlexProjectTemplateTaskDetailModel,
} from "../../../../../shared/models/FlexProjectTemplateTaskDetail.model";
import { Skeleton } from "primereact/skeleton";

export type FlexTemplateInformationPopUpProps = {
  content: string;
  op: any;
};

const FlexTemplateInformationPopUp = (
  props: FlexTemplateInformationPopUpProps
) => {
  const initialState = {
    projectTemplateId: null,
    correlationId: null,
    templateName: null,
    templateOwner: null,
    templateVersion: null,
    templateDescription: null,
    projectRevenuePhaseDetails: [],
    projectTemplateTaskTemplateDetails: [],
  };
  const [data, setData] =
    useState<FlexProjectTemplateDetailModel>(initialState);

  const [spinner, showSpinner] = useState<boolean>(false);
  const [cachedFlexTemplateInfo, setCachedFlexTemplateInfo] = useState<
    FlexProjectTemplateDetailModel[]
  >([]);

  useEffect(() => {
    setData(initialState);
    if (!props.content) return;
    if (
      cachedFlexTemplateInfo.length > 0 &&
      cachedFlexTemplateInfo.some((x) => x.projectTemplateId === props.content)
    ) {
      setData(
        cachedFlexTemplateInfo.filter(
          (x) => x.projectTemplateId === props.content
        )[0]
      );
    } else {
      getFlexTemplateDetailIntegration();
    }
  }, [props.content]);

  const getFlexTemplateDetailIntegration = () => {
    showSpinner(true);
    ProjectTemplateMappingService.getFlexTemplateDetailIntegration(
      props.content
    )
      .then((result: FlexProjectTemplateDetailModel) => {
        if (typeof result !== "undefined") {
          setCachedFlexTemplateInfo([...cachedFlexTemplateInfo, result]);
          setData(result);
        }
      })
      .catch((error) => {
        setData(initialState);
        setCachedFlexTemplateInfo([
          ...cachedFlexTemplateInfo,
          { ...initialState, projectTemplateId: props.content },
        ]);
        console.error(error);
      })
      .finally(() => showSpinner(false));
  };

  const handleValue = (value: any) => {
    if (!value) return "-";
    return value;
  };

  const renderBusinessUnit = (
    projectTemplateTaskTemplateDetails: FlexProjectTemplateTaskDetailModel[]
  ) => {
    const container: string[] = [];
    projectTemplateTaskTemplateDetails?.forEach((x) => {
      x.businessUnitNames?.forEach((businessUnit) =>
        container.push(businessUnit)
      );
    });
    const value = container.join(", ");
    return <div title={handleValue(value)}>{handleValue(value)}</div>;
  };

  return (
    <div>
      <OverlayPanel
        ref={props.op}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "500px" }}
        showCloseIcon
        className={`${classes["overlayCloseButton"]}`}
      >
        <div className={`ul-grid__column ${classes["fieldName"]}`}>&emsp;</div>
        <div className={`ul-grid__row -wrap`}>
          {data && typeof data.templateVersion !== "undefined" && (
            <div className={`ul-grid__column`}>
              <div className={`ul-grid__row -wrap`}>
                <div className={`ul-grid__column -x50 ${classes["fieldName"]}`}>
                  Template Version:
                </div>
                <div
                  className={`ul-grid__column -x50 ${classes["fieldValue"]}`}
                  title={handleValue(data.templateVersion?.toString())}
                >
                  {spinner ? (
                    <Skeleton
                      className="mb-2"
                      width="5rem"
                      borderRadius="16px"
                    ></Skeleton>
                  ) : (
                    handleValue(data.templateVersion?.toString())
                  )}
                </div>
              </div>
            </div>
          )}
          {data && typeof data.templateOwner !== "undefined" && (
            <div className={`ul-grid__column`}>
              <div className={`ul-grid__row -wrap`}>
                <div className={`ul-grid__column -x50 ${classes["fieldName"]}`}>
                  FLEX Template Created By:
                </div>
                <div
                  className={`ul-grid__column -x50 ${classes["fieldValue"]}`}
                  title={handleValue(data.templateOwner?.toLowerCase())}
                >
                  {spinner ? (
                    <Skeleton
                      className="mb-2"
                      width="12rem"
                      borderRadius="16px"
                    ></Skeleton>
                  ) : (
                    handleValue(data.templateOwner?.toLowerCase())
                  )}
                </div>
              </div>
            </div>
          )}
          <div className={`ul-grid__column`}>
            <div className={`ul-grid__row -wrap`}>
              <div className={`ul-grid__column -x50 ${classes["fieldName"]}`}>
                Business Unit:
              </div>
              <div className={`ul-grid__column -x50 ${classes["fieldValue"]}`}>
                {spinner ? (
                  <Skeleton
                    className="mb-2"
                    width="12rem"
                    borderRadius="16px"
                  ></Skeleton>
                ) : (
                  handleValue(
                    renderBusinessUnit(data.projectTemplateTaskTemplateDetails!)
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default FlexTemplateInformationPopUp;
