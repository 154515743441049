interface ValidateSpecialCharactersResult {
  valid: boolean;
  errorMessage: string | null;
}

export const validateSpecialCharacters = (
  input: string,
  label: string
): ValidateSpecialCharactersResult => {
  let regex = /[\\/:*?"<>|]/;
  const regExResult = regex.test(input);

  return {
    valid: !regExResult,
    errorMessage: regExResult
      ? `${label} cannot contain the following characters:  \ / : * ? " < > |. Please enter a different ${label}.`
      : null,
  };
};

export const truncateString = (
  input: string = "",
  maxLength: number = 100
): string => {
  return input?.substring(0, maxLength);
};

export const removeSpecialCharacters = (input: string = ""): string => {
  if (!input || input == "") return "";

  return input.replace(/[\\/:*?"<>|]/g, "");
};
