import moment from "moment"

export const formatDateToGanttChartDate = (sourceDate: Date | string | undefined | null):string => {
    if(sourceDate === null || sourceDate === undefined)
        return moment()
            .format("DD MMM YYYY");

    
    return moment(sourceDate).format("DD MMM YYYY");
}

export const formatDateToGanttChartDateOrEmptyString = (sourceDate: Date | string | undefined | null):string => {
    if(sourceDate === null || sourceDate === undefined)
        return "";

    
    return moment(sourceDate).format("DD MMM YYYY");
}