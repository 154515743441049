import NotificationType from "../enums/NotificationType";
import IPagination from "../types/IPagination";
import RequestFactory from "./RequestFactory";

const notificationUrl = process.env.REACT_APP_GENERAL_PRODUCT_INFORMATION_URL;

const NotificationService = {
  getNotifications: async (pagination: IPagination) =>
    await RequestFactory.post(
      notificationUrl,
      `/api/general-product-info-notification/get-notification-list-per-user`,
      pagination
    ),
  markAllUserNotificationsAsViewed: async (
    notificationType: NotificationType
  ) =>
    await RequestFactory.post(
      notificationUrl,
      `/api/general-product-info-notification/mark-all-user-notifications-as-viewed`,
      {
        notificationType,
      }
    ),
  deleteNotification: async (notificationIds: number[]) =>
    await RequestFactory.post(
      notificationUrl,
      `/api/general-product-info-notification/delete-notifications`,
      {
        notificationIdList: notificationIds.join(",")
      }
    ),
};

export default NotificationService;
