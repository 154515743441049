import { useState } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerRefiner.scss";
import { formatDateToISO } from "../../../utils/helpers/date.helpers";
import { useEffect } from "react";
import CustomDatePicker from "../../custom-date-picker/CustomDatePicker";

const DatePickerRefiner = (props) => {
  const [isShow, setIsShow] = useState(false);

  const {
    refinerLabel,
    refinerId,
    onChangeDatePickerRefiner,
    onClickRemoveRefiner,
    refiners,
  } = props;

  const [dateOn, setDateOn] = useState(null);
  const [dateOnOrBefore, setDateOnOrBefore] = useState(null);
  const [dateOnOrAfter, setDateOnOrAfter] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  useEffect(() => {
    const refinersByRefinerId = refiners.filter((i) => i.Id === refinerId);
    if (refinersByRefinerId.length === 0) {
      setDateOn(null);
      setDateOnOrBefore(null);
      setDateOnOrAfter(null);
      setDateFrom(null);
      setDateTo(null);
    } else {
      const hasDateOn = refinersByRefinerId.find(
        (i) => i.Condition === "DateOn"
      );
      if (!hasDateOn) {
        setDateOn(null);
      }

      const hasDateOnOrBefore = refinersByRefinerId.find(
        (i) => i.Condition === "DateOnOrBefore"
      );
      if (!hasDateOnOrBefore) {
        setDateOnOrBefore(null);
      }

      const hasDateOnOrAfter = refinersByRefinerId.find(
        (i) => i.Condition === "DateOnOrAfter"
      );
      if (!hasDateOnOrAfter) {
        setDateOnOrAfter(null);
      }

      const hasDateFrom = refinersByRefinerId.find(
        (i) => i.Condition === "DateFrom"
      );
      if (!hasDateFrom) {
        setDateFrom(null);
      }

      const hasDateTo = refinersByRefinerId.find(
        (i) => i.Condition === "DateTo"
      );
      if (!hasDateTo) {
        setDateTo(null);
      }
    }
  }, [refiners, refinerId]);

  const onChangeDateOn = (date) => {
    setDateOn(date);
    if (date) {
      const dateString = formatDateToISO(date.toDateString());
      onChangeDatePickerRefiner({
        Value: dateString,
        Condition: "DateOn",
        DisplayText: "On " + dateString,
      });
    } else {
      onClickRemoveRefiner({ Condition: "DateOn", Id: refinerId }, false);
    }
  };

  const onChangeDateOnOrBefore = (date) => {
    setDateOnOrBefore(date);
    if (date) {
      const dateString = formatDateToISO(date.toDateString());
      onChangeDatePickerRefiner({
        Value: dateString,
        Condition: "DateOnOrBefore",
        DisplayText: "On Or Before " + dateString,
      });
    } else {
      onClickRemoveRefiner(
        { Condition: "DateOnOrBefore", Id: refinerId },
        false
      );
    }
  };

  const onChangeDateOnOrAfter = (date) => {
    setDateOnOrAfter(date);
    if (date) {
      const dateString = formatDateToISO(date.toDateString());
      onChangeDatePickerRefiner({
        Value: dateString,
        Condition: "DateOnOrAfter",
        DisplayText: "On Or After " + dateString,
      });
    } else {
      onClickRemoveRefiner(
        { Condition: "DateOnOrAfter", Id: refinerId },
        false
      );
    }
  };

  const onChangeDateFrom = (date) => {
    setDateFrom(date);

    if (date) {
      const dateString = formatDateToISO(date.toDateString());
      onChangeDatePickerRefiner({
        Value: dateString,
        Condition: "DateFrom",
        DisplayText: "From " + dateString,
      });
    } else {
      onClickRemoveRefiner({ Condition: "DateFrom", Id: refinerId }, false);
    }
  };

  const onChangeDateTo = (date) => {
    setDateTo(date);

    if (date) {
      const dateString = formatDateToISO(date.toDateString());
      onChangeDatePickerRefiner({
        Value: dateString,
        Condition: "DateTo",
        DisplayText: "To " + dateString,
      });
    } else {
      onClickRemoveRefiner({ Condition: "DateTo", Id: refinerId }, false);
    }
  };

  const resetEverything = () => {
    setDateOnOrAfter(null);
  };

  return (
    <div className="ul-card -app">
      <div
        className="ul-card__item -bordered ul-card__head cursor-pointer d-refiner-height d-container-text"
        onClick={() => setIsShow((prevState) => !prevState)}
      >
        <span className="font-size-14 font-weight-semi-bold">
          {refinerLabel}
        </span>
        <span className="float-right">
          <FontAwesomeIcon icon={isShow ? faCaretDown : faCaretRight} />
        </span>
      </div>

      {isShow && (
        <div className="ul-card__item ul-card__body">
          <div>Date On</div>
          <CustomDatePicker
            disabled={dateFrom || dateTo || dateOnOrBefore || dateOnOrAfter}
            selected={dateOn}
            onChange={(date) => onChangeDateOn(date)}
          />

          <div className="date-picker-row">On or Before</div>
          <CustomDatePicker
            disabled={dateFrom || dateTo || dateOn || dateOnOrAfter}
            selected={dateOnOrBefore}
            onChange={(date) => onChangeDateOnOrBefore(date)}
          />

          <div className="date-picker-row">On or After</div>
          <CustomDatePicker
            disabled={dateFrom || dateTo || dateOn || dateOnOrBefore}
            selected={dateOnOrAfter}
            onChange={(date) => onChangeDateOnOrAfter(date)}
          />

          <div className="date-picker-row">Date From</div>
          <CustomDatePicker
            disabled={dateOn || dateOnOrBefore || dateOnOrAfter}
            selected={dateFrom}
            onChange={(date) => onChangeDateFrom(date)}
          />

          <div className="date-picker-row">Date To</div>
          <CustomDatePicker
            disabled={dateOn || dateOnOrBefore || dateOnOrAfter}
            selected={dateTo}
            onChange={(date) => onChangeDateTo(date)}
          />
        </div>
      )}
    </div>
  );
};

export default DatePickerRefiner;
