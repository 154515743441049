import FavoriteService from "../../services/FavoritesService";
import useToastr from "../../hooks/useToastr";
import { useEffect, useState, useRef } from "react";
import classes from "./Favorites.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

const Favorites = (props) => {
  const { showSuccess, showError } = useToastr();
  const [currentFavorite, setCurrentFavorite] = useState("");
  const opFav = useRef(null);
  const [showEditFavoriteModal, setShowEditFavoriteModal] = useState(false);
  const [showDeleteFavoriteModal, setShowDeleteFavoriteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [description, setDescription] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [faveList, setFaveList] = useState([]);
  const [favoriteId, setFavoriteId] = useState(0);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isValidFavoriteName, setIsValidFavoriteName] = useState(false);
  const [hasDuplicateName, setHasDuplicateName] = useState(false);
  const [editFavoriteModel, setEditFavoriteModel] = useState({});
  const [isEditValid, setIsEditValid] = useState(false);
  const toast = useRef(null);
  const {
    favoriteTypesId,
    jsonModel,
    onFavoriteSelect,
    onFavoriteInit,
    onSetToDefaultFilter,
    setFavoriteToolTip,
    isDisabled = false,
  } = props;

  //props needed
  //favoriteTypesId - The favorite type/or what page
  //jsonDetails - The actual filter/columns object serialized in string
  //onFavoriteSelect - Method to execute on parent when favorite is selected, returns the jsonDetails object
  //onSetToDefaultFilter - Method to execute on parent when clear filter is selected

  useEffect(() => {
    FavoriteService.get(props.favoriteTypesId).then((result) => {
      if (result?.isSuccess) {
        setFaveList(result.data);

        if (result.data.find((x) => x.isDefault)) {
          setCurrentFavorite(result.data.find((x) => x.isDefault).description);
          onFavoriteInit(
            JSON.parse(result.data.find((x) => x.isDefault).jsonDetails)
          );
        } else {
          onFavoriteInit(null);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (setFavoriteToolTip) {
      setFavoriteToolTip(currentFavorite);
    }
  }, [currentFavorite]);

  const getFavorites = async function () {
    FavoriteService.get(props.favoriteTypesId).then((result) => {
      if (result.isSuccess) {
        setFaveList(result.data);
      }
    });
  };

  const handleFavoriteName = (favoriteName) => {
    const isExists = faveList.some(
      (favorite) =>
        favorite.description.toLowerCase().trim() ===
        favoriteName.toLowerCase().trim()
    );

    setHasDuplicateName(isExists);
  };

  const handleInputNameChange = (value) => {
    handleFavoriteName(value);
    setDescription(value);
    setIsValidFavoriteName(value !== "" && value.trim().length !== 0);
  };

  const handleEditNameChange = (value) => {
    let isValidFavorite = false;

    setEditDescription(value);

    if (value !== editFavoriteModel.description) {
      isValidFavorite = value !== "" && value.trim().length !== 0;
      handleFavoriteName(value);
    } else if (isDefault !== editFavoriteModel.isDefault)
      isValidFavorite = true;

    setIsEditValid(isValidFavorite);
  };

  const handleEditDefault = (value) => {
    setIsDefault(value);
    const isValid =
      editDescription !== editFavoriteModel.description ||
      value !== editFavoriteModel.isDefault;

    setIsEditValid(isValid);
  };

  const upsertFavorites = async function (favoriteName) {
    setIsSaveLoading(true);
    const jsonDetails = JSON.stringify(jsonModel);
    const trimFavoriteName = favoriteName.trim();

    let result = await FavoriteService.upsert({
      id: favoriteId == 0 ? null : favoriteId,
      favoriteTypesId,
      description: trimFavoriteName,
      jsonDetails,
      isDefault,
    });

    if (result.isSuccess) {
      showSuccess("Success!", "Favorite has been successfully saved");

      setCurrentFavorite(description);

      getFavorites();
    } else {
      showError("Error", "Favorite name already exists");
    }

    if (favoriteId != 0) {
      setEditDescription("");
      setFavoriteId(0);
      setIsEditValid(false);
      setShowEditFavoriteModal(false);
    }

    setHasDuplicateName(false);
    setDescription("");
    setIsSaveLoading(false);
    setIsDefault(false);
  };

  const onDelete = (id) => {
    setSelectedId(id);
    setShowDeleteFavoriteModal(true);
  };

  const setToDefaultFilter = () => {
    setCurrentFavorite("");
    onSetToDefaultFilter();
  };

  const handleSaveFavorites = (isEdit) => {
    const favoriteName = isEdit ? editDescription : description;
    upsertFavorites(favoriteName);
  };

  const cancelEdit = () => {
    setEditDescription("");
    setFavoriteId(0);
    setIsDefault(false);
    setShowEditFavoriteModal(false);
    setHasDuplicateName(false);
    setIsEditValid(false);
  };

  const deleteFavorite = async function () {
    const deletedFavorite = faveList.find((x) => x.id == selectedId);

    if (deletedFavorite.description == currentFavorite) {
      setCurrentFavorite("");
    }

    let result = await FavoriteService.delete({
      id: selectedId,
    });
    getFavorites();
    if (result.isSuccess) {
      showSuccess("Success", "Favorite has been successfully deleted");
      getFavorites();
    } else {
      showError("Error", result.message);
    }

    if (favoriteId != 0) {
      setFavoriteId(0);
      setShowEditFavoriteModal(false);
    }

    setShowDeleteFavoriteModal(false);
  };
  const onEditClick = (favoriteId) => {
    const selectedItem = faveList.find((x) => x.id == favoriteId);

    setEditFavoriteModel(selectedItem);
    setEditDescription(selectedItem.description);
    setIsDefault(selectedItem.isDefault);
    setFavoriteId(favoriteId);

    setShowEditFavoriteModal(true);
  };

  const handleFavoriteSelect = (jsonDetails, description) => {
    onFavoriteSelect(JSON.parse(jsonDetails));
    setCurrentFavorite(description);
  };

  const renderDeleteHeader = () => {
    return (
      <div className={classes["edit-header"]}>
        <div className={classes["header-title"]}>Delete Saved Favorite(s)</div>
      </div>
    );
  };

  const renderDeleteFooter = () => {
    return (
      <div className={classes["edit-footer"]}>
        <Button
          label="YES"
          className={classes["edit-button-save"]}
          onClick={deleteFavorite}
          autoFocus
          loading={isSaveLoading}
        />
        <Button
          label="NO"
          className={classes["edit-button-cancel"]}
          onClick={() => {
            setSelectedId(0);
            setShowDeleteFavoriteModal(false);
          }}
        />
      </div>
    );
  };

  const renderEditHeader = () => {
    return (
      <div className={classes["edit-header"]}>
        <div className={classes["header-title"]}>Edit Favorites</div>
      </div>
    );
  };

  const renderEditFooter = () => {
    return (
      <div className={classes["edit-footer"]}>
        <Button
          label="SAVE"
          className={classes["edit-button-save"]}
          onClick={() => handleSaveFavorites(true)}
          autoFocus
          loading={isSaveLoading}
          disabled={!isEditValid || hasDuplicateName}
        />
        <Button
          label="CANCEL"
          className={classes["edit-button-cancel"]}
          onClick={cancelEdit}
        />
      </div>
    );
  };

  return (
    <>
      <button
        type="button"
        className={`ul-button ${classes["favorites"]}`}
        onClick={(e) => opFav.current.toggle(e)}
        disabled={isDisabled}
      >
        <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
        <span className={classes["fav-display"]}>{currentFavorite}</span>
      </button>

      <OverlayPanel ref={opFav} dismissable>
        <div className={classes["fav-header"]}>
          <div className={classes["sub-title"]}>Add Favorites</div>
          <div className={classes["favorites-input-body"]}>
            <div>
              <InputText
                className={`
                ${hasDuplicateName ? classes["favorites-input-invalid"] : ""}
                ${classes["favorites-input"]} 
              `}
                value={description}
                onChange={(e) => handleInputNameChange(e.target.value)}
                maxLength={150}
                aria-describedby="favoriteName-help"
              />
              <div>
                {hasDuplicateName && (
                  <small className={classes["favorite-invalid-message"]}>
                    Favorite name already exists.
                  </small>
                )}
              </div>
            </div>
            <div>
              <Button
                className={`ul-button -app-primary -small ${classes["save-button"]}`}
                label="SAVE"
                onClick={() => handleSaveFavorites(false)}
                disabled={!isValidFavoriteName || hasDuplicateName}
                loading={isSaveLoading}
              />
            </div>
          </div>
          <div className={classes["def-and-clear"]}>
            <div className={classes["set-as-default"]}>
              <input
                type="checkbox"
                checked={isDefault}
                onChange={(e) => {
                  setIsDefault(e.target.checked);
                }}
              />{" "}
              Set as default
            </div>
            <div
              className={classes["clear-filters"]}
              onClick={setToDefaultFilter}
            >
              Reset View
            </div>
          </div>
        </div>
        <div className={classes["fav-body"]}>
          <div className={classes["sub-title"]}>My Favorites</div>
          <div className={classes["list"]}>
            {faveList.map((fav, i) => {
              return (
                <div key={fav.id} className={classes["item"]}>
                  <div
                    className={`${classes["fav"]} ${classes["elipsis"]}`}
                    onClick={() =>
                      handleFavoriteSelect(fav.jsonDetails, fav.description)
                    }
                    title={fav.description}
                  >
                    {" "}
                    {fav.description}
                  </div>

                  <div className={classes["fav-action"]}>
                    {fav.isDefault ? (
                      <FontAwesomeIcon
                        icon={faStar}
                        className={classes["fav-star"]}
                      ></FontAwesomeIcon>
                    ) : (
                      <></>
                    )}
                    <FontAwesomeIcon
                      icon={faPencil}
                      className={`${classes["fav-pencil"]} ${classes["action"]}`}
                      onClick={() => {
                        onEditClick(fav.id);
                      }}
                    ></FontAwesomeIcon>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className={`${classes["fav-trash"]}`}
                      onClick={() => {
                        onDelete(fav.id);
                      }}
                    ></FontAwesomeIcon>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </OverlayPanel>

      <Dialog
        className={classes["dialog-favorite"]}
        header={renderEditHeader}
        visible={showEditFavoriteModal}
        position="center"
        modal
        style={{ width: "25vw" }}
        onHide={cancelEdit}
        footer={renderEditFooter}
      >
        <div className={classes["edit-body"]}>
          <div>
            <label className={classes["edit-label"]}>Name</label>

            <InputText
              className={`
                ${hasDuplicateName ? classes["favorites-input-invalid"] : ""}
                ${classes["edit-input"]} 
              `}
              value={editDescription}
              onChange={(e) => handleEditNameChange(e.target.value)}
              maxLength={150}
            />
            <div>
              {hasDuplicateName && (
                <small className={classes["invalid-message"]}>
                  Favorite name already exists.
                </small>
              )}
            </div>
          </div>
          <div className={classes["edit-checkbox"]}>
            <Checkbox
              inputId="binary"
              checked={isDefault}
              onChange={(e) => handleEditDefault(e.target.checked)}
            />
            <label className={classes["edit-label"]}> Set as default</label>
          </div>
        </div>
      </Dialog>

      <Dialog
        className={classes["dialog-favorite"]}
        header={renderDeleteHeader}
        visible={showDeleteFavoriteModal}
        position="center"
        modal
        style={{ width: "27vw" }}
        onHide={() => setShowDeleteFavoriteModal(false)}
        footer={renderDeleteFooter}
      >
        <div className={classes["delete-body"]}>
          <p>Are you sure you want to delete this from My Favorites list?</p>
        </div>
      </Dialog>

      <Toast ref={toast} />
    </>
  );
};

export default Favorites;
