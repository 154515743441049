const isFeatureFlagEnabled = (flagName: string) => {
  let isFlagEnabled =
    process.env[`REACT_APP_FEATUREFLAG_${flagName}`] == "true";
  const urlSearch = new URLSearchParams(window.location.search);
  const flagParam = urlSearch.get(flagName);

  if (flagParam) {
    isFlagEnabled = flagParam == "true";
  }

  return isFlagEnabled;
};

export const USE_PROJECT_HANDLER_MAPPING = isFeatureFlagEnabled(
  "USE_PROJECT_HANDLER_MAPPING"
);

export const DEFAULT_REFINERS = isFeatureFlagEnabled("DEFAULT_REFINERS");

export const CARRY_OVER_CONFIGURATION_ON_NEW_TAB = isFeatureFlagEnabled(
  "CARRY_OVER_CONFIGURATON_ON_NEW_TAB"
);

export const SINGLE_COLLABORATOR_REASSIGNABLE = isFeatureFlagEnabled(
  "SINGLE_COLLABORATOR_REASSIGNABLE"
);

export const INVOICE_PRICE = isFeatureFlagEnabled("INVOICE_PRICE");
