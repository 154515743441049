import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import CloseIcon from "../close-icon/CloseIcon";
import "./WithdrawCertificateModal.scss";
import CertificateListService from "../../services/CertificateListService";
import useToastr from "../../hooks/useToastr";
import { useDispatch } from "react-redux";
import {
  reloadCertificateDetails,
  reloadCertificateList,
  updateSelectedCertificateId,
} from "../../features/certificateList/certificateListSlice";
import CustomDatePicker from "../custom-date-picker/CustomDatePicker";

const WithdrawCertificateModal = (props) => {
  const dispatch = useDispatch();
  const {
    isOpen,
    setIsOpen,
    certificateId,
    hasRevisionNumber,
    revisionNumber,
  } = props;
  const [selectedDate, setSelectedDate] = useState(null);
  const originalRevisionNumber = revisionNumber;
  const [inputRevisionNumber, setInputRevisionNumber] =
    useState(revisionNumber);
  const [isDisable, setIsDisable] = useState(false);
  const { showSuccess, showError } = useToastr();

  useEffect(() => {
    setInputRevisionNumber(revisionNumber);
  }, [revisionNumber]);

  const onSave = async (disabled) => {
    if (!disabled) {
      setIsDisable(true);

      const model = {
        certificateId: certificateId,
        revisionNumber: hasRevisionNumber
          ? inputRevisionNumber
          : +revisionNumber + 1,
        withdrawnDate: formatDate(selectedDate),
      };
      const response = await CertificateListService.withdrawCertificate(model);

      if (response.isSuccess) {
        showSuccess("Success!", "Certificate withdrawn successfully.");
        dispatch(reloadCertificateList(true));
        dispatch(updateSelectedCertificateId(response.data.certificateId));
        setIsOpen(false);
      } else {
        showError("Error!", response.message);
      }

      setIsDisable(false);
    }
  };

  const onCloseModal = (disabled) => {
    if (!disabled) {
      setIsOpen(false);
      setSelectedDate(null);
      setInputRevisionNumber(revisionNumber);
    }
  };

  const validation = () => {
    const isSame =
      originalRevisionNumber?.trim() === inputRevisionNumber?.trim();
    const isNull = !selectedDate;
    return (isSame && hasRevisionNumber) || isNull || isDisable;
  };

  const formatDate = (selectedDate) => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1;
    const day = selectedDate.getDate();
    return (
      year +
      "-" +
      (month > 9 ? "" : "0") +
      month +
      "-" +
      (day > 9 ? "" : "0") +
      day
    );
  };

  const renderFooter = () => {
    return (
      <div className="buttons-container">
        <button
          className="ul-button -app-primary -medium default-save-button"
          onClick={() => onSave(isDisable)}
          disabled={validation()}
        >
          <span>OK</span>
        </button>
        <button
          className="ul-button -medium default-cancel-button"
          onClick={() => onCloseModal(isDisable)}
        >
          <span>CANCEL</span>
        </button>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        id="withdraw-modal"
        header="Withdraw Certificate"
        visible={isOpen}
        style={{ width: "35vw" }}
        footer={renderFooter("displayBasic")}
        icons={<CloseIcon onCloseEvent={() => onCloseModal(isDisable)} />}
        closable={false}
        className="withdraw-certificate-modal"
      >
        <div>
          {hasRevisionNumber && (
            <div>
              <label>Revision Number</label>
              <input
                type="text"
                className="ul-textbox"
                value={inputRevisionNumber}
                onChange={(event) => setInputRevisionNumber(event.target.value)}
              />
            </div>
          )}

          <div className="pt-10">
            <label>Withdrawn Date</label>
            <CustomDatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              className="date-picker"
              minDate={Date.now()}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default WithdrawCertificateModal;
