import {
  DropdownProps as PrimeReactDropdownProps,
  Dropdown as PrimeReactDropdown,
} from "primereact/dropdown";
import "./Dropdown.module.scss";

export interface DropdownProps extends PrimeReactDropdownProps {}

const Dropdown = (props: DropdownProps) => {
  const valueTemplate = (option: any, props: DropdownProps) => {
    const selectedValue = props.options?.find(
      (option) => option[props.optionValue as any] === props?.value
    );

    if (!selectedValue) return props.placeholder;

    return selectedValue[props.optionLabel as any] || "";
  };

  return <PrimeReactDropdown {...props} valueTemplate={valueTemplate} />;
};

export default Dropdown;
