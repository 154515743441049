import debounce from "lodash.debounce";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addKeptCustomerDataDiscrepancies,
  customerInformationInitialState,
  selectGeneralProductInformation,
  selectIsReadOnly,
  selectKeptCustomerDataDiscrepancies,
  selectShouldCompareExistingCertDetails,
  selectULFileNumber,
  updateIsSaveDisabled,
} from "../../../../../features/generalProductInformation/generalProductInformationSlice";
import GeneralProductInformationService from "../../../../../services/GeneralProductInformationService";
import ProductTypeService from "../../../../../services/ProductTypeService";
import {
  customerChangeConfirmationMsg,
  customerChangeKeepMsg,
  customerChangeReplaceMsg,
  customerChangeUpdateMsg,
  defaultValuesConfirmationMsg,
  defaultValuesKeepMsg,
  defaultValuesReplaceMsg,
  defaultValuesUpdateMsg,
} from "../../../../../utils/constants/gpi-form-constants";
import FormControl from "../../../form/form-control/FormControl";
import { getGPIFieldErrorMessage } from "../../../helpers";
import { customerInformationSchema } from "../../company-details-schema";

const CustomerInformation = ({
  onChange,
  customerInformation,
  disabled,
  mainCustomerInfoDisabled, // disables all fields aside from the contact details
  section,
  disableIntegration, // if set to true, would disable integration with ip, workbench and flex.
  customErrors,
  onFocus,
  discrepancyCustomerInformation,
  entity,
  index,
  onDataDiscrepancyReplace,
}) => {
  // HACK: It is put on the options prop of PrimeReact DropDown component
  // if there is empty options to enable typing on filter input,
  // otherwise it won't work. The option generated by this constant
  // is hidden by css.
  const blankOptions = [{ label: "null", value: "null" }];
  const [companyOptions, setCompanyOptions] = useState({
    partySiteNumber: {
      options: blankOptions,
      loading: false,
    },
    companyName: {
      options: blankOptions,
      loading: false,
    },
  });
  const dispatch = useDispatch();

  const keptCustomerDataDiscrepancies = useSelector(
    selectKeptCustomerDataDiscrepancies
  );

  useEffect(() => {
    if (disableIntegration) return;

    const getCompanyListAsync = async () => {
      if (
        customerInformation.companyName !== "" &&
        customerInformation.partySiteNumber === ""
      ) {
        await getCompanyList(customerInformation.companyName, "companyName", [
          "partySiteNumber",
          "partyName",
          "customerDetails",
        ]);
      } else if (customerInformation.partySiteNumber !== "") {
        loadCustomerContacts(customerInformation.partySiteNumber);
      }
    };

    populateCustomerContactOptions(customerInformation.partySiteNumber);

    debounce(getCompanyListAsync, 1000);
  }, [customerInformation.companyName, customerInformation.partySiteNumber]);

  const isReadOnly = useSelector(selectIsReadOnly);
  const [customerContactOptions, setCustomerContactOptions] = useState([]);
  const [isLoadingCustomerContacts, setIsLoadingCustomerContacts] =
    useState(false);
  const isCompanyInfoFieldsDisabled =
    isReadOnly || disabled || mainCustomerInfoDisabled;

  const ulFileNumberValues = useSelector(selectULFileNumber);
  const gpiValues = useSelector(selectGeneralProductInformation);

  const shouldCompareCertificationDetails = useSelector(
    selectShouldCompareExistingCertDetails
  );

  const loadCustomerContacts = (partySiteNumber) => {
    setIsLoadingCustomerContacts(true);
    populateCustomerContactOptions(partySiteNumber);
  };

  const handleChange = (field, value) => {
    onChange({
      ...customerInformation,
      [field]: value,
    });
  };

  const isCompanyDetailsAutoPopulated = () => {
    return (
      customerInformation.hasSelectedCompanyName ||
      customerInformation.hasSelectedPsn
    );
  };

  const handleCompanyListDropdownChange = (e, field, disablerField) => {
    const { value } = e;

    // Value will be object if selected from options.
    if (typeof value === "object") {
      const newCustomerInformation = {
        ...customerInformation,
        ...mapCompanyDetails(value),
        [disablerField]: true,
      };

      onChange(newCustomerInformation);

      // Load associated customer contacts by selected company.
      !disableIntegration &&
        loadCustomerContacts(newCustomerInformation.partySiteNumber);

      return;
    }

    // Value will be string if just edited.
    if (value) {
      onChange({
        ...customerInformation,
        [field]: value,
      });

      return;
    }

    // If edited, entered empty string and has preselected company,
    // erase other auto-populated fields.
    if (customerInformation[disablerField])
      onChange(customerInformationInitialState);
    else
      onChange({
        ...customerInformation,
        [field]: value,
      });
  };

  const handleCompanyValueChange = (e, field) => {
    const value = e.target.value;
    onChange({
      ...customerInformation,
      [field]: value,
    });

    return;
  };

  /// <param name="optionLabel">Is array of fields to concat and build label</param>
  const handleCompanyListFilter = async (e, field, optionLabel) => {
    if (disableIntegration) return;

    await getCompanyList(e.filter, field, optionLabel);
  };

  const getCompanyList = async (companyName, field, optionLabel) => {
    const apiParameters = {
      companyName: "",
      partySiteNumber: "",
      oracleAccountNumber: "",
      [field]: companyName,
    };

    const { data, isSuccess } =
      (await ProductTypeService.getCompanyList(
        apiParameters.companyName,
        apiParameters.partySiteNumber,
        apiParameters.oracleAccountNumber
      )) || {};

    if (!isSuccess || !data?.length) {
      setCompanyOptions({
        ...companyOptions,
        [field]: { options: blankOptions, loading: false },
      });
      return;
    }

    const options = data.map((d) => {
      const labelBuilder = [];

      for (let i = 0; i < optionLabel.length; i++) {
        labelBuilder.push(d[optionLabel[i]]);
      }

      return { value: d, label: labelBuilder.join(" - ") };
    });

    setCompanyOptions({
      ...companyOptions,
      [field]: {
        options,
        loading: false,
      },
    });
  };

  const debouncedFilterCompanyOptions = useCallback(
    debounce(handleCompanyListFilter, 1000),
    []
  );

  const mapCompanyDetails = ({
    partySiteNumber,
    partyName: companyName,
    locationReference,
  }) => {
    const {
      addressLine1,
      addressLine2,
      addressLine3,
      addressLine4,
      city,
      state,
      province,
      countryName: country,
      postalCode: zipCode,
    } = locationReference;

    const streetAddress = [
      addressLine1,
      addressLine2,
      addressLine3,
      addressLine4,
    ]
      .filter((a) => a != "")
      .join(", ");

    return {
      partySiteNumber,
      companyName,
      streetAddress,
      city,
      country,
      zipCode,
      stateOrProvince: state + province,
    };
  };

  const populateCustomerContactOptions = async (partySiteNumber) => {
    setCustomerContactOptions([]);

    const { data, isSuccess } =
      (await GeneralProductInformationService.getCustomerContacts(
        partySiteNumber
      )) || {};
    setIsLoadingCustomerContacts(false);

    if (!isSuccess) return;

    if (data?.contacts) {
      var filteredContacts = data.contacts.filter(
        (c) => c.fullname || c.phone || c.email
      );

      setCustomerContactOptions(
        filteredContacts.map((x) => {
          const newContact = {
            ...x,
            fullName: x.fullName ?? "",
            phone: x.phone ?? "",
            email: x.email ?? "",
            title: x.title ?? "",
          };

          return {
            value: newContact,
            label: `${newContact.fullName} - ${newContact.phone} - ${newContact.email}`,
          };
        })
      );
    }
  };

  const handleCustomerContactChange = (e) => {
    const { value } = e;

    // Value will be object if selected from options.
    if (typeof value === "object") {
      const { title, phone, email, fullName: contactPerson } = value;

      onChange({
        ...customerInformation,
        contactPerson,
        title,
        phone,
        email,
      });
    } else {
      onChange({
        ...customerInformation,
        contactPerson: value,
      });
    }

    dispatch(updateIsSaveDisabled(false));
  };

  const handleCustomerContactTextChange = (e) => {
    const value = e.target.value;
    onChange({
      ...customerInformation,
      contactPerson: value,
    });
  };

  const companyListDropdown = (
    label,
    field,
    isDisabled,
    optionLabel,
    disablerField,
    isReadOnly,
    customErrorMessage
  ) => {
    return (
      <FormControl
        dataDiscrepancyOptions={getDataDiscrepancyOptions(field)}
        label={label}
        customErrorMessage={customErrorMessage}
        {...getValueAndSchema(field)}
        schemaContext={customerInformation}
        controlClassName={`${section == '' ? field : `${section}-${field}`} ${disableIntegration ? "disable-dropdown-trigger" : ""} `}
        control={
          !disableIntegration ? (
            <Dropdown
              onFocus={handleFieldsFocus}
              options={companyOptions[field].options}
              emptyFilterMessage={
                companyOptions[field].loading
                  ? "Loading..."
                  : "No results found"
              }
              onFilter={(e) => {
                if (disableIntegration) return;

                setCompanyOptions({
                  ...companyOptions,
                  [field]: {
                    ...companyOptions[field],
                    loading: true,
                  },
                });

                debouncedFilterCompanyOptions(e, field, optionLabel);
              }}
              disabled={isReadOnly ? isReadOnly : disabled || isDisabled}
              onChange={(e) =>
                handleCompanyListDropdownChange(e, field, disablerField)
              }
              editable
              filter
            />
          ) : (
            <InputText
              onFocus={handleFieldsFocus}
              editable
              onChange={(e) => {
                handleCompanyValueChange(e, field);
              }}
              disabled={isReadOnly || disabled || isDisabled}
            />
          )
        }
      />
    );
  };

  const getValueAndSchema = (field) => {
    return {
      schema: customerInformationSchema.fields[field],
      value: customerInformation[field],
    };
  };

  const handleFieldsFocus = () => {
    onFocus && onFocus();
  };

  const getDataDiscrepancyOptions = (field) => {
    if (!discrepancyCustomerInformation || !gpiValues.hasFinalizedByCustomer)
      return;

    return {
      currentValue: customerInformation[field],
      wbValue: discrepancyCustomerInformation?.[field],
      onReplace: (isFromExistingCertificate, currentValue, wbValue) => {
        handleChange(field, wbValue);
        onDataDiscrepancyReplace && onDataDiscrepancyReplace();
      },
      onKeep: () => {
        dispatch(addKeptCustomerDataDiscrepancies({ entity, field, index }));
      },
      isVisible:
        !keptCustomerDataDiscrepancies?.some(
          (x) => x.field === field && x.entity === entity && x.index == index
        ) && shouldCompareCertificationDetails,
      updateMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeUpdateMsg
        : defaultValuesUpdateMsg,
      confirmationMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeConfirmationMsg
        : defaultValuesConfirmationMsg,
      replaceMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeReplaceMsg
        : defaultValuesReplaceMsg,
      keepMessage: ulFileNumberValues.isCustomerInput
        ? customerChangeKeepMsg
        : defaultValuesKeepMsg,
      isFromExistingCertificate: true,
    };
  };

  return (
    <div className="container">
      {companyListDropdown(
        section === "agent"
          ? "Agent Party Site Number"
          : "UL Customer Party Site Number (if known)",
        "partySiteNumber",
        customerInformation.hasSelectedCompanyName || mainCustomerInfoDisabled,
        ["partySiteNumber", "partyName", "customerDetails"],
        "hasSelectedPsn",
        isReadOnly,
        getGPIFieldErrorMessage(customErrors, "Party Site Number")
      )}

      {companyListDropdown(
        section === "agent" ? "Agent Company Name" : "Company Name",
        "companyName",
        mainCustomerInfoDisabled,
        ["partyName", "partySiteNumber", "customerDetails"],
        "hasSelectedCompanyName",
        isReadOnly,
        getGPIFieldErrorMessage(customErrors, "Company Name")
      )}

      <FormControl
        dataDiscrepancyOptions={getDataDiscrepancyOptions("streetAddress")}
        label="Street Address"
        customErrorMessage={getGPIFieldErrorMessage(
          customErrors,
          "Street Address"
        )}
        {...getValueAndSchema("streetAddress")}
        control={
          <InputText
            onFocus={handleFieldsFocus}
            disabled={isCompanyInfoFieldsDisabled}
            onChange={(e) => handleChange("streetAddress", e.target.value)}
          />
        }
        controlClassName={section == '' ? 'street-address' : `${section}-street-address`}
      />

      <div className="d-flex" style={{ gap: "30px" }}>
        <div className="col-6">
          <FormControl
            label="City"
            dataDiscrepancyOptions={getDataDiscrepancyOptions("city")}
            customErrorMessage={getGPIFieldErrorMessage(customErrors, "City")}
            {...getValueAndSchema("city")}
            control={
              <InputText
                onFocus={handleFieldsFocus}
                disabled={isCompanyInfoFieldsDisabled}
                onChange={(e) => handleChange("city", e.target.value)}
              />
            }
            controlClassName={section == '' ? 'city' : `${section}-city`}
          />
        </div>

        <div className="col-6">
          <FormControl
            label="State / Province"
            customErrorMessage={getGPIFieldErrorMessage(
              customErrors,
              "State/Province"
            )}
            dataDiscrepancyOptions={getDataDiscrepancyOptions(
              "stateOrProvince"
            )}
            {...getValueAndSchema("stateOrProvince")}
            control={
              <InputText
                onFocus={handleFieldsFocus}
                disabled={isCompanyInfoFieldsDisabled}
                onChange={(e) =>
                  handleChange("stateOrProvince", e.target.value)
                }
              />
            }
            controlClassName={section == '' ? 'stateOrProvince' : `${section}-stateOrProvince`}
          />
        </div>
      </div>
      <div className="d-flex" style={{ gap: "30px" }}>
        <div className="col-6">
          <FormControl
            label="Zip Code"
            customErrorMessage={getGPIFieldErrorMessage(
              customErrors,
              "Zip Code"
            )}
            {...getValueAndSchema("zipCode")}
            dataDiscrepancyOptions={getDataDiscrepancyOptions("zipCode")}
            control={
              <InputText
                style={{ maxWidth: "140px" }}
                onFocus={handleFieldsFocus}
                disabled={isCompanyInfoFieldsDisabled}
                onChange={(e) => handleChange("zipCode", e.target.value)}
              />
            }
            controlClassName={section == '' ? 'zipCode' : `${section}-zipCode`}
          />
        </div>

        <div className="col-6">
          <FormControl
            label="Country"
            customErrorMessage={getGPIFieldErrorMessage(
              customErrors,
              "Country"
            )}
            {...getValueAndSchema("country")}
            dataDiscrepancyOptions={getDataDiscrepancyOptions("country")}
            control={
              <InputText
                onFocus={handleFieldsFocus}
                disabled={isCompanyInfoFieldsDisabled}
                onChange={(e) => handleChange("country", e.target.value)}
              />
            }
            controlClassName={section == '' ? 'country' : `${section}-country`}
          />
        </div>
      </div>
      <br />

      <FormControl
        label={section === "agent" ? "Agent Company Contact" : "Contact Person"}
        {...getValueAndSchema("contactPerson")}
        customErrorMessage={getGPIFieldErrorMessage(
          customErrors,
          "Contact Person"
        )}
        control={
          !disableIntegration ? (
            <Dropdown
              onFocus={handleFieldsFocus}
              options={customerContactOptions}
              emptyMessage={
                isLoadingCustomerContacts
                  ? "Loading..."
                  : "No available contacts"
              }
              disabled={isReadOnly ? isReadOnly : disabled}
              editable
              onChange={handleCustomerContactChange}
              filter
            />
          ) : (
            <InputText
              onFocus={handleFieldsFocus}
              onChange={handleCustomerContactTextChange}
              editable
              disabled={isReadOnly || disabled}
            />
          )
        }
        controlClassName={`${section == '' ? 'contactPerson' : `${section}-contactPerson`} ${disableIntegration ? "disable-dropdown-trigger" : ""} `}
      />

      <FormControl
        label="Title and Position"
        customErrorMessage={getGPIFieldErrorMessage(
          customErrors,
          "Contact Title and Position"
        )}
        {...getValueAndSchema("title")}
        control={
          <InputText
            onFocus={handleFieldsFocus}
            disabled={isReadOnly ? isReadOnly : disabled}
            onChange={(e) => handleChange("title", e.target.value)}
          />
        }
        controlClassName={section == '' ? 'title' : `${section}-title`}
      />

      <div className="d-flex" style={{ gap: "30px" }}>
        <div className="col-6">
          <FormControl
            customErrorMessage={getGPIFieldErrorMessage(
              customErrors,
              "Contact Tel No."
            )}
            label="TEL"
            {...getValueAndSchema("phone")}
            control={
              <InputText
                onFocus={handleFieldsFocus}
                disabled={isReadOnly ? isReadOnly : disabled}
                onChange={(e) => handleChange("phone", e.target.value)}
              />
            }
            controlClassName={section == '' ? 'phone' : `${section}-phone`}
          />
        </div>

        <div className="col-6">
          <FormControl
            label="FAX"
            {...getValueAndSchema("fax")}
            control={
              <InputText
                onFocus={handleFieldsFocus}
                disabled={isReadOnly ? isReadOnly : disabled}
                onChange={(e) => handleChange("fax", e.target.value)}
              />
            }
            controlClassName={section == '' ? 'fax' : `${section}-fax`}
          />
        </div>
      </div>
      <div className="d-flex" style={{ gap: "30px" }}>
        <div className="col-6">
          <FormControl
            label="Email"
            {...getValueAndSchema("email")}
            customErrorMessage={getGPIFieldErrorMessage(
              customErrors,
              "Contact Email Address"
            )}
            control={
              <InputText
                onFocus={handleFieldsFocus}
                disabled={isReadOnly ? isReadOnly : disabled}
                onChange={(e) => handleChange("email", e.target.value)}
              />
            }
            controlClassName={section == '' ? 'email' : `${section}-email`}
          />
        </div>

        <div className="col-6">
          <FormControl
            label="Website"
            {...getValueAndSchema("website")}
            control={
              <InputText
                onFocus={handleFieldsFocus}
                disabled={isReadOnly ? isReadOnly : disabled}
                onChange={(e) => handleChange("website", e.target.value)}
              />
            }
            controlClassName={section == '' ? 'website' : `${section}-website`}
          />
        </div>
      </div>
      {section === "applicant" && (
        <FormControl
          label="Director Name"
          {...getValueAndSchema("directorName")}
          control={
            <InputText
              onFocus={handleFieldsFocus}
              disabled={isReadOnly ? isReadOnly : disabled}
              onChange={(e) => handleChange("directorName", e.target.value)}
            />
          }
          controlClassName={section == '' ? 'directorName' : `${section}-directorName`}
        />
      )}
    </div>
  );
};

export default CustomerInformation;
