import {
  faBookOpen,
  faCircleInfo,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectShowFlexTasks } from "../../../features/projectManagement/projectManagementSlice";
import useToastr from "../../../hooks/useToastr";
import ProjectService from "../../../services/ProjectService";
import CustomOneULPaginator from "../../../shared/custom-oneul-paginator/CustomOneULPaginator";
import {
  E2EProjectLineCollaboratorModel,
  E2EProjectModel,
} from "../../../shared/models/E2EProject.model";
import { ProjectGanttChartTableFields } from "../../../shared/models/ProjectGanttChartTableFields";
import E2EProjectRequestModel from "../../../shared/models/service-models/E2EProjectRequest.model";
import { PaginatedResultModel } from "../../../shared/models/service-models/PaginatedResult.model";
import { ResultModel } from "../../../shared/models/service-models/Result.model";
import { ProjectAvatar } from "../shared/components/project-avatar/ProjectAvatar";
import { formatDateToGanttChartDate } from "../shared/utils/DateHelper";
import classes from "./ProjectList.module.scss";
import "./ProjectList.scss";
import ProjectGridLegend from "../shared/components/project-grid-legend/ProjectGridLegend";
import { classNames } from "primereact/utils";
interface ProjectDetailHolder {
  id: string;
  data: ProjectGanttChartTableFields[];
}

const ProjectList = (props: any) => {
  let pageSizes: number[] = [50, 100, 200, 500, 1000];
  let loadLazyTimeout: any = null;

  const getFlexCheck = useSelector(selectShowFlexTasks);

  const [disableJumpToInput, setDisableJumpToInput] = useState(false);
  const [projects, setProjects] = useState<E2EProjectModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<E2EProjectModel[]>([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const { showSuccess, showError } = useToastr();
  const [request, setRequest] = useState<E2EProjectRequestModel>({
    skip: 1,
    take: pageSizes[0],
  });
  const [projectDetailRows, setProjectDetailRows] = useState<
    ProjectDetailHolder[]
  >([]);

  const columns: any = [
    {
      field: "name",
      header: "GMA WB Project",
      width: "230px",
    },
    {
      field: "gmaWbProjectCreator",
      header: "GMA WB Project Creator",
      width: "230px",
    },
    {
      field: "applicant",
      header: "Applicant",
      width: "180px",
    },
    {
      field: "applicantPartySiteNumber",
      header: "Applicant Party Site Number",
      width: "270px",
    },
    {
      field: "status",
      header: "Status",
      width: "180px",
    },
    {
      field: "collaborators",
      header: "Collaborators",
      width: "220px",
    },
  ];

  const tableColumnHeader = (col: any) => (
    <div>
      <span title={col.header}>{col.header}</span>
    </div>
  );

  const tableColumnBody = (rowData: any, col: any) => {
    const stockClassName = classNames({
      "text-overflow-ellipsis": true,
    });
    return (
      <div title={rowData[col.field]} className={stockClassName}>
        {rowData[col.field]}
      </div>
    );
  };

  const dynamicColumns = columns.map((col: any) => {
    return (
      <Column
        sortable
        key={col.field}
        field={col.field}
        header={tableColumnHeader(col)}
        style={{ flexGrow: 1, flexBasis: col.width, width: col.width }}
        body={tableColumnBody.bind(col)}
      />
    );
  });

  useEffect(() => {
    setProjectDetailRows([]);
    loadLazyData();
  }, [request, getFlexCheck]);

  const getProjectDetail = (projectIdParam: string) => {
    ProjectService.getProjectDetails({
      projectId: projectIdParam,
      isFlexTaskOnly: getFlexCheck,
    })
      .then((response) => {
        const newMapData = response.data.e2EProjectDetails.map(
          (projectDetails) => {
            const returnData: ProjectGanttChartTableFields = {
              id: projectDetails.taskId,
              tasks: projectDetails.taskName || "",
              orderLineNumber: projectDetails.orderLineNumber || "",
              responsible: projectDetails.responsibles,
              totalHours: projectDetails.totalHours,
              myHours: projectDetails.myHours,
              duration: projectDetails.duration,
              startDate: projectDetails.startDate,
              endDate: projectDetails.endDate,
              flexTaskId: projectDetails.flexTaskId,
              progress: 10,
              milestoneStatus: projectDetails.milestoneStatus || "",
              child: projectDetails.subTasks?.map((children) => {
                const returnChild: ProjectGanttChartTableFields = {
                  id: children.taskId,
                  tasks: children.taskName || "",
                  orderLineNumber: children.orderLineNumber || "",
                  responsible: children.responsibles,
                  totalHours: children.totalHours,
                  myHours: children.myHours,
                  duration: children.duration,
                  startDate: children.startDate,
                  endDate: children.endDate,
                  flexTaskId: children.flexTaskId,
                  progress: 30,
                  milestoneStatus: children.milestoneStatus || "",
                };

                return returnChild;
              }),
            };
            return returnData;
          }
        );

        setProjectDetailRows((current) => [
          ...current,
          { id: projectIdParam, data: newMapData },
        ]);
      })
      .catch((error) => console.error(error));
  };

  const getProjectList = () => {
    ProjectService.getProjectList(request)
      .then((result: ResultModel<PaginatedResultModel<E2EProjectModel[]>>) => {
        if (result.isSuccess) {
          let records = result.data.records;
          records.forEach((project: E2EProjectModel) => {
            project.createdDateUtc = formatDateToGanttChartDate(
              project.createdDateUtc!
            );
            project.modifiedDateUtc = formatDateToGanttChartDate(
              project.modifiedDateUtc!
            );
          });
          setProjects(records);
          setTotalPages(result.data.totalPages);
          setTotalRecords(result.data.total);
          setFirstIndex(result.data.firstIndex);
          setLastIndex(result.data.lastIndex);
          setHasNextPage(result.data.hasNextPage);
          setHasPreviousPage(result.data.hasPreviousPage);
          setLoading(false);
          setDisableJumpToInput(false);
        } else {
          showError("Error!", result.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const loadLazyData = () => {
    setLoading(true);
    setDisableJumpToInput(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      getProjectList();
    }, Math.random() * 1000 + 250);
  };

  const renderE2EProjectName = (e: any) => {
    return (
      <div className="ul-grid__column" key={e.id}>
        <div
          title={e.name}
          className={`${classes["defaultCursor"]} ${classes["elipsisContainer"]} ul-grid__column -x90`}
        >
          {e.name}
          &emsp;
          <FontAwesomeIcon
            title="View E2E Project information"
            icon={faCircleInfo}
            className={classes["icon"]}
          />
        </div>
      </div>
    );
  };

  const collaboratorsTemplate = (e2EProject: E2EProjectModel) => {
    let E2Ecollaborators: E2EProjectLineCollaboratorModel[] = [];

    if (e2EProject && e2EProject.e2EProjectLines) {
      e2EProject.e2EProjectLines.forEach((projectLine) => {
        projectLine.e2EProjectLineCollaborators?.forEach((collaborator) => {
          if (
            !E2Ecollaborators.map((x) => x.name.toLowerCase()).includes(
              collaborator.name.toLowerCase()
            )
          ) {
            E2Ecollaborators.push({
              ...collaborator,
              ordinal: collaborator.collaboratorType
                .toLowerCase()
                .includes("order owner")
                ? 1
                : collaborator.collaboratorType
                    .toLowerCase()
                    .includes("flex handler")
                ? 2
                : collaborator.collaboratorType
                    .toLowerCase()
                    .includes("e2e handler")
                ? 3
                : 4,
            });
          }
        });
      });
    }

    E2Ecollaborators = E2Ecollaborators.sort((a, b) => a.ordinal! - b.ordinal!);
    return (
      <>
        <div key={e2EProject.id} className="ul-grid__column ">
          <ProjectAvatar key={e2EProject.id} AvatarList={E2Ecollaborators} />
        </div>
      </>
    );
  };

  const pageSizeOnChange = (data: any) => {
    setRequest({
      ...request,
      skip: 1,
      take: data.target.value,
    });
  };

  const pageOnChange = (data: number) => {
    setRequest({
      ...request,
      skip: request.skip! + data,
    });
  };

  const manualPageOnChange = (data: any) => {
    let value = data.target.value.replace(/\D/g, "");
    let skip =
      value === "" || value === "0" ? request.skip! : Number.parseInt(value);
    if (skip <= totalPages && skip !== request.skip) {
      setRequest({
        ...request,
        skip,
      });
    }
  };

  const actionHeaderTemplate = (
    <Button
      className="p-button-rounded p-button-secondary p-button-text"
      style={{ cursor: "default", pointerEvents: "none" }}
    >
      <FontAwesomeIcon icon={faBookOpen} />
    </Button>
  );

  const actionTemplate = () => {
    return (
      <Button className="p-button-rounded p-button-secondary p-button-text">
        <FontAwesomeIcon icon={faUpRightFromSquare} />
      </Button>
    );
  };

  const hideColumnHeader = <span></span>;

  return (
    <div className="ul-grid__column  -x100 -x100-mobile">
      <div className="ul-grid__row -wrap">
        <div className={`ul-grid__column project-list-grid-container`}>
          <DataTable
            dataKey="id"
            value={projects}
            loading={loading}
            selection={selectedRecords}
            onSelectionChange={(e: any) => setSelectedRecords(e.value)}
            expandedRows={expandedRows}
            onRowToggle={(e: any) => setExpandedRows(e.data)}
            scrollable
            resizableColumns
            size="small"
            selectionMode="checkbox"
            scrollDirection="both"
            showGridlines
            columnResizeMode="expand"
          >
            <Column
              // frozen
              header={hideColumnHeader}
              selectionMode="multiple"
              headerClassName={`${classes["hideHeaderCheckbox"]}`}
              style={{ width: "50px" }}
            />
            <Column expander={true} style={{ width: "50px" }} />
            {dynamicColumns}
            <Column
              frozen
              alignFrozen="right"
              header={actionHeaderTemplate}
              body={actionTemplate}
              style={{ width: "50px" }}
            />
          </DataTable>
          <CustomOneULPaginator
            pageSizes={pageSizes}
            skip={request.skip!}
            take={request.take!}
            firstIndex={firstIndex}
            lastIndex={lastIndex}
            totalRecords={totalRecords}
            totalPages={totalPages}
            hasPreviousPage={hasPreviousPage}
            hasNextPage={hasNextPage}
            pageSizeOnChange={pageSizeOnChange}
            manualPageOnChange={manualPageOnChange}
            pageOnChange={pageOnChange}
            disableJumpToInput={disableJumpToInput}
            legendTemplate={<ProjectGridLegend />}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectList;
