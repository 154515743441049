const certProdListGridSubColumns = [
  {
    id: "certificateName",
    value: "Certificate Number",
    width: "19%",
  },
  {
    id: "certificateType",
    value: "Certificate Scheme",
    width: "19%",
  },
  {
    id: "issueDate",
    value: "Issue Date",
    width: "19%",
  },
  {
    id: "certificateStatus",
    value: "Certificate Status",
    width: "19%",
  },
  {
    id: "mark",
    value: "Mark",
    width: "19%",
  },
];

export default certProdListGridSubColumns;
