import classes from "./FooterActions.module.scss";

const FooterActions = ({
  activeIndex,
  steps,
  onFinish,
  onSave,
  onSaveAndClose,
  nextVisible,
  prevVisible,
  finishVisible,
  saveVisible,
  isSaveDisabled,
  goBackVisible,
  onNavigateStep,
  includeSaveAndClose,
}) => {
  return (
    <div className={classes["container"]}>
      <div className={classes["main-actions"]}>
        <div
          className={`ul-button -app-primary ${
            !prevVisible ? classes["hidden"] : ""
          }`}
          onClick={() => onNavigateStep(activeIndex - 1)}
        >
          Previous
        </div>

        <div className={`ul-progressSteps__pagerDots ${classes["step-dots"]}`}>
          {steps.map((menu, i) => (
            <div
              key={menu.label}
              className={`ul-progressSteps__pagerDot ${
                i === activeIndex ? "-active" : ""
              }`}
            ></div>
          ))}
        </div>

        {nextVisible && (
          <div
            className={`ul-button -app-primary`}
            onClick={() => onNavigateStep(activeIndex + 1)}
          >
            Next
          </div>
        )}

        <button
          className={`ul-button -app-primary ${
            finishVisible ? "" : nextVisible ? "d-none" : classes["hidden"]
          } ${classes["btn-finish"]}`}
          onClick={onFinish}
          disabled={isSaveDisabled}
        >
          Finish
        </button>
      </div>

      <div className={classes["other-actions"]}>
          {saveVisible && (
            <>
              <button
                disabled={isSaveDisabled}
                className={`ul-button -app-tertiary ${classes["btn-save"]}`}
                onClick={!includeSaveAndClose ? onSaveAndClose : onSave}
              >
                Save
              </button>

              {includeSaveAndClose && (
                <button
                  disabled={isSaveDisabled}
                  className={`ul-button -app-tertiary ${classes["btn-save-close"]}`}
                  onClick={onSaveAndClose}
                >
                  Save & Close
                </button>
              )}
            </>
          )}

        {goBackVisible && (
          <button
            className={`ul-button -app-secondary ${classes["btn-go-back"]}`}
            onClick={onSaveAndClose}
          >
            Go back to Document Management
          </button>
        )}
      </div>
    </div>
  );
};

export default FooterActions;
