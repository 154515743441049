import { Dialog } from "primereact/dialog";
import AddAttachments from "../../add-new-certificate/add-attachments/AddAttachments";
import classes from "./AttachmentsDialog.module.scss";
import CloseIcon from "../../../../../shared/close-icon/CloseIcon";

const AttachmentsDialog = (props) => {
  const { isOpen, onClose } = props;
  alert("test");
  const footer = (
    <>
      <hr className="modal-footer-hr" />
      <div className="text-center">
        <button
          className={`ul-button -medium -icon ${classes["btn-close"]}`}
          onClick={() => {
            onClose && onClose();
          }}
        >
          CLOSE
        </button>
      </div>
    </>
  );

  return (
    <Dialog
      style={{ width: "800px", height: "630px" }}
      header="Add Attachments"
      visible={isOpen}
      footer={footer}
      onHide={() => {
        onClose && onClose();
      }}
      icons={
        <CloseIcon
          onCloseEvent={() => {
            onClose && onClose();
          }}
        />
      }
      closable={false}
    >
      <hr className="modal-header-hr" />

      <div className={classes["add-attachments-container"]}>
        <AddAttachments />
      </div>
    </Dialog>
  );
};

export default AttachmentsDialog;
