import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Paginator } from "primereact/paginator";
import React from "react";

const CustomPaginator = ({
  currentPageRef,
  dropdownOptions,
  lazyParams,
  currentPage,
  pageCount,
  totalPageCount,
  totalRecordCount,
  onChangePage,
  // If set to true, auto calculate the first, last and total page values.
  isOffsetPagination,
  // If set to true, onChangePage won't get called up if navigating out of totalPageCount.
  isStrictNavigation,
}) => {
  const onFirstPageLinkClick = () => {
    const params = lazyParams;
    params.page = 1;
    onChangePage(params);
  };

  const onPrevPageLinkClick = () => {
    const params = lazyParams;
    params.page = params.page - 1;
    onChangePage(params);
  };

  const onNextPageLinkClick = () => {
    const params = lazyParams;
    params.page = params.page + 1;
    onChangePage(params);
  };

  const onLastPageLinkClick = () => {
    const params = lazyParams;
    params.page = getTotalPageCount();
    onChangePage(params);
  };

  const onPageChange = () => {
    const { lastValue } = currentPageRef.current;

    const page = parseInt(lastValue);
    if (page >= 1 && page <= getTotalPageCount()) {
      let params = lazyParams;
      params.page = parseInt(lastValue);
      onChangePage(params);
    }
  };

  const onPageInputKeyDown = (event, options) => {
    if (event.key === "Enter") {
      onPageChange();
    }
  };

  const onItemsCountChange = (e) => {
    const { value } = e;
    let params = lazyParams;
    const totalPages = Math.ceil(totalRecordCount / value);

    if (value > pageCount && currentPage > totalPages) {
      params.rows = value;
      params.page = totalPages;
    } else {
      params.rows = value;
    }

    onChangePage(params);
  };

  const getFirst = () => {
    const first = isOffsetPagination
      ? (currentPage - 1) * pageCount + 1
      : lazyParams.first;

    return first <= 0 || totalRecordCount <= 0 ? 0 : first;
  };

  const getLast = () =>
    isOffsetPagination
      ? currentPage * pageCount > totalRecordCount
        ? totalRecordCount
        : currentPage * pageCount
      : lazyParams.last;

  const getTotalPageCount = () =>
    isOffsetPagination
      ? Math.ceil(totalRecordCount / pageCount)
      : totalPageCount;

  const paginatorLeft = () => {
    return (
      <React.Fragment>
        <span
          className="mx-1"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          Items per page:
        </span>
        <Dropdown
          value={pageCount}
          options={dropdownOptions}
          onChange={onItemsCountChange}
        />

        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "260px",
            textAlign: "center",
            marginLeft: "1em",
          }}
        >
          {getFirst()} to {getLast()} of {totalRecordCount}
        </span>
      </React.Fragment>
    );
  };

  const paginatorRight = (options) => {
    return (
      <React.Fragment>
        <button
          type="button"
          className={`p-paginator-first p-paginator-element p-link ${
            lazyParams.isFirstPageLinkDisabled && "p-disabled"
          }`}
          onClick={onFirstPageLinkClick}
          disabled={
            lazyParams.isFirstPageLinkDisabled ||
            (isStrictNavigation && currentPage <= 1)
          }
        >
          <span className="p-paginator-icon pi pi-angle-double-left"></span>
        </button>
        <button
          type="button"
          className={`p-paginator-prev p-paginator-element p-link ${
            lazyParams.isPrevPageLinkDisabled && "p-disabled"
          }`}
          onClick={onPrevPageLinkClick}
          disabled={
            lazyParams.isPrevPageLinkDisabled ||
            (isStrictNavigation && currentPage <= 1)
          }
        >
          <span className="p-paginator-icon pi pi-angle-left"></span>
        </button>

        <span
          className="mx-3"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          <InputNumber
            useGrouping={false}
            size={2}
            min={1}
            inputClassName="ml-1"
            ref={currentPageRef}
            value={currentPage}
            onKeyDown={(e) => onPageInputKeyDown(e, options)}
          />
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "260px",
              textAlign: "center",
              marginLeft: "1em",
            }}
          >
            of {getTotalPageCount()}
          </span>
        </span>

        <button
          type="button"
          className={`p-paginator-next p-paginator-element p-link ${
            lazyParams.isNextPageLinkDisabled && "p-disabled"
          }`}
          onClick={onNextPageLinkClick}
          disabled={
            lazyParams.isNextPageLinkDisabled ||
            (isStrictNavigation && currentPage === getTotalPageCount())
          }
        >
          <span className="p-paginator-icon pi pi-angle-right"></span>
        </button>
        <button
          type="button"
          className={`p-paginator-last p-paginator-element p-link ${
            lazyParams.isLastPageLinkDisabled && "p-disabled"
          }`}
          onClick={onLastPageLinkClick}
          disabled={
            lazyParams.isLastPageLinkDisabled ||
            (isStrictNavigation && currentPage === getTotalPageCount())
          }
        >
          <span className="p-paginator-icon pi pi-angle-double-right"></span>
        </button>
      </React.Fragment>
    );
  };
  
  return (
    <>
      <Paginator
        first={getFirst()}
        rows={100}
        totalRecords={totalRecordCount}
        className="justify-content-end"
        template={null}
        leftContent={paginatorLeft}
        rightContent={paginatorRight}
      ></Paginator>
    </>
  );
};

export default CustomPaginator;
