import React, { useEffect, useRef, useState } from "react";
import {
  TreeSelect,
  TreeSelectEventNodeParams,
  TreeSelectSelectionKeys,
} from "primereact/treeselect";
import { Button } from "primereact/button";
import TreeNode from "primereact/treenode";
import classes from "./RadioTechnologyTreeSelect.module.scss";
import {
  RadioTechnologyTreeSelectDefaultItems,
  RadioTechnologyTreeSelectItem,
} from "./RadioTechnologyTreeSelectItem";
import * as utils from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { radioDetailsSchema } from "../../product-details/product-details-schema";
import FormControl from "../form-control/FormControl";
import { useSelector } from "react-redux";
import { selectRadioDetails } from "../../../../features/generalProductInformation/generalProductInformationSlice";

interface ExpandedKeys {
  [key: string]: boolean;
}

interface RadioTechnologyTreeSelectProps {
  data: any;
  handleTreeSelectChange: React.Dispatch<React.SetStateAction<any>>;
  disabled: boolean;
  controlClassName?: string;
}

const RadioTechnologyTreeSelect = (props: RadioTechnologyTreeSelectProps) => {
  const TREE_SELECT_CHIP_CLASS_NAME: string = "tree-select-chip";

  const [selectedNode, setSelectedNode] = useState<
    TreeSelectSelectionKeys | object
  >();
  const [otherRadioTechnology, setOtherRadioTechnology] = useState<string>();
  const [otherRadioTechnologyType, setOtherRadioTechnologyType] =
    useState<string>();

  const [isUpdateFromUI, setIsUpdateFromUI] = useState<boolean>(false);

  const radioDetails = useSelector(selectRadioDetails);

  const treeSelectRef = useRef<any>(null);

  useEffect(() => {
    let mounted = true;
    if (treeSelectRef.current && mounted) {
      treeSelectRef.current.onClick = onTreeSelectClick.bind(treeSelectRef);
    }

    return () => {
      mounted = false;
    };
  }, [treeSelectRef]);

  useEffect(() => {
    if (props.data) {
      if (props.data.radioTechnologyTypes.length === 0) {
        setSelectedNode([]);
      }

      if (props.data.radioTechnology) {
        const getParent = RadioTechnologyTreeSelectDefaultItems.find(
          (x) => x.label === props.data.radioTechnology
        );

        const nodesToPreload = props.data.radioTechnologyTypes
          .map((radio: any) => {
            const getChildren = getParent?.children?.find(
              (child) => child.label === radio.radioTechnologyType
            );

            return [getChildren?.key!.toString(), { checked: true }];
          })
          .reduce((prev: any, current: any) => {
            prev[current[0]] = current[1];
            return prev;
          }, {});

        nodesToPreload[getParent!.key!.toString()] = { checked: true };

        setSelectedNode(nodesToPreload);
      }
    }
  }, [props.data, radioDetails]);

  useEffect(() => {
    setOtherRadioTechnology(
      checkIfHasOtherField(false) ? props.data.otherRadioTechnology : ""
    );
    setOtherRadioTechnologyType(
      checkIfHasOtherField(true) ? props.data.otherRadioTechnologyType : ""
    );
  }, [selectedNode]);

  useEffect(() => {
    if (isUpdateFromUI) {
      const getRadioTechnologies = Object.entries(selectedNode as object).map(
        (item) => {
          if (item[0].toString().length === 1) {
            return RadioTechnologyTreeSelectDefaultItems.find(
              (x) => x.key === item[0]
            )?.label;
          } else {
            return RadioTechnologyTreeSelectDefaultItems.find(
              (x) => x.key === item[0].substring(0, 1)
            )?.children?.find((x) => x.key === item[0])?.label;
          }
        }
      );

      const parentRadioTechnology = getRadioTechnologies.shift();

      props.handleTreeSelectChange({
        radioTechnology: parentRadioTechnology,
        radioTechnologyTypes:
          getRadioTechnologies.length === 1 &&
          getRadioTechnologies[0] === undefined
            ? []
            : getRadioTechnologies,
        otherRadioTechnology: checkIfHasOtherField(false)
          ? otherRadioTechnology
          : "",
        otherRadioTechnologyType: checkIfHasOtherField(true)
          ? otherRadioTechnologyType
          : "",
      });

      setIsUpdateFromUI(false);
    }
  }, [isUpdateFromUI, otherRadioTechnology, otherRadioTechnologyType]);

  const checkIfHasOtherField = (isChildNode: boolean): boolean => {
    if (!selectedNode) {
      return false;
    }

    if (isChildNode) {
      const getRadioTechnologies = Object.entries(selectedNode as object)
        .filter((x) => x[0].length !== 1)
        .map((item) => {
          return RadioTechnologyTreeSelectDefaultItems.find(
            (x) => x.key === item[0].substring(0, 1)
          )?.children?.find((x) => x.key === item[0])?.data;
        });

      return getRadioTechnologies.some((x) => x === "Other");
    } else {
      const getNode = Object.entries(selectedNode as object).find(
        (x) => x[0].length === 1
      )?.[0];
      const getNodeDetail = RadioTechnologyTreeSelectDefaultItems.find(
        (x) => x.key === getNode
      )?.data;
      return getNodeDetail === "Other";
    }
  };

  const handleExpandCollapse = (expand: boolean) => {
    if (treeSelectRef.current) {
      let nodeArrayToExpand: ExpandedKeys = {};

      if (expand) {
        RadioTechnologyTreeSelectDefaultItems.forEach((item) => {
          nodeArrayToExpand[item.key!] = expand;
          checkChildNode(item, nodeArrayToExpand, expand);
        });
      }
      treeSelectRef.current?.setState({ expandedKeys: nodeArrayToExpand });
    }
  };

  const checkChildNode = (
    node: TreeNode,
    nodeArrayToExpand: ExpandedKeys,
    expand: boolean
  ) => {
    if (node.children) {
      node.children.forEach((child) => {
        nodeArrayToExpand[child.key!] = expand;
        checkChildNode(child, nodeArrayToExpand, expand);
      });
    }
    return;
  };

  const panelHeaderCustomTemplate = () => {
    return (
      <div className={`p-treeselect-header ${classes["tree-select__buttons"]}`}>
        <Button
          label="+"
          className={`p-button-rounded p-button-outlined ${classes["tree-select__buttons--expand"]}`}
          onClick={(e) => handleExpandCollapse(true)}
        />
        <Button
          label="-"
          className={`p-button-rounded p-button-outlined ${classes["tree-select__buttons--expand-minus"]} `}
          onClick={(e) => handleExpandCollapse(false)}
        />
        <Button
          type="button"
          className="p-treeselect-close p-link"
          onClick={(e) =>
            treeSelectRef.current?.setState({ overlayVisible: false })
          }
        ></Button>
      </div>
    );
  };

  const onNodeSelectHandler = (
    node: TreeNode | TreeSelectEventNodeParams | any
  ) => {
    const nodeEventSelect = node as TreeSelectEventNodeParams;
    const nodeItem = node.node as RadioTechnologyTreeSelectItem;

    let nodeItemsToSelectAutomatically = {
      [nodeItem.key!.toString()]: {
        checked: true,
      },
      [nodeItem.key!.toString() + "-0"]: {
        checked: true,
      },
    };

    if (treeSelectRef.current) {
      let nodeArrayToExpand: ExpandedKeys = {};
      if (nodeEventSelect.node.key?.toString().length === 1) {
        RadioTechnologyTreeSelectDefaultItems.filter(
          (x) => x.key === nodeEventSelect.node.key
        ).forEach((item) => {
          nodeArrayToExpand[item.key!] = true;
        });
        treeSelectRef.current?.setState({ expandedKeys: nodeArrayToExpand });
      }
      const copySelectedNodes = structuredClone(selectedNode) as object;

      if (nodeItem.selectionType === "multiple") {
        nodeItemsToSelectAutomatically[
          nodeItem.key!.toString().substring(0, 1)
        ] = { checked: true };

        nodeItem.children?.forEach((child) => {
          if (child.data !== "Other") {
            nodeItemsToSelectAutomatically[child.key!] = { checked: true };
          }
        });
      }

      if (!copySelectedNodes) {
        const setNodeItems =
          nodeItem.key!.toString().length === 1
            ? nodeItemsToSelectAutomatically
            : {
                [nodeItem.key!.toString().substring(0, 1)]: {
                  checked: true,
                },
                [nodeItem.key!.toString()]: {
                  checked: true,
                },
              };

        setSelectedNode(setNodeItems);

        return;
      }

      const copySelectedNodesAsIterable = Object.entries(copySelectedNodes);

      if (nodeItem.key!.toString().length === 1) {
        setSelectedNode(nodeItemsToSelectAutomatically);
      } else {
        if (
          !copySelectedNodesAsIterable.find(
            (x) => x[0] === nodeItem.key?.toString().substring(0, 1)
          )
        ) {
          copySelectedNodesAsIterable.push([
            nodeItem.key!.toString().substring(0, 1),
            { checked: true },
          ]);
        }

        copySelectedNodesAsIterable.push([
          nodeItem.key!.toString(),
          { checked: true },
        ]);

        const nodeToUpdate = copySelectedNodesAsIterable
          .filter(
            (x) =>
              x[0].substring(0, 1) === nodeItem.key?.toString().substring(0, 1)
          )
          .sort((a, b) => {
            return a[0].localeCompare(b[0]);
          })
          .reduce<Record<string, any>>((prev, current) => {
            prev[current[0]] = current[1];
            return prev;
          }, {});

        const setNodeItems = {
          [nodeItem.key!.toString().substring(0, 1)]: {
            checked: true,
          },
          [nodeItem.key!.toString()]: {
            checked: true,
          },
        };

        const getParentNodeItem = RadioTechnologyTreeSelectDefaultItems.find(
          (x) => x.key === nodeItem.key?.toString().substring(0, 1)
        );

        setSelectedNode(
          getParentNodeItem?.selectionType === "multiple"
            ? nodeToUpdate
            : setNodeItems
        );
      }
    }
    setIsUpdateFromUI(true);
  };

  const onRemoveSelected = (
    node: TreeNode | TreeSelectEventNodeParams | any,
    chipRemoved: boolean
  ) => {
    let removeNode = node;

    if (!chipRemoved) {
      removeNode = node.node;
    }

    if (removeNode.key.toString().length === 1) {
      setSelectedNode({});
    } else {
      const copySelectedNodes = structuredClone(selectedNode) as object;
      if (copySelectedNodes) {
        const copySelectedNodesAsIterable = Object.entries(copySelectedNodes);

        const nodeToUpdate = copySelectedNodesAsIterable
          .filter((x) => x[0] !== removeNode.key)
          .reduce<Record<string, any>>((prev, current) => {
            if (
              copySelectedNodesAsIterable.filter(
                (x) =>
                  x[0].substring(0, 1) ===
                  removeNode.key.toString().substring(0, 1)
              ).length > 2
            ) {
              prev[current[0]] = current[1];
              return prev;
            }
            return {};
          }, {});

        setSelectedNode(nodeToUpdate);
      }
    }

    setIsUpdateFromUI(true);
  };

  const onTreeSelectClick = (event: React.BaseSyntheticEvent) => {
    const targetClassList: DOMTokenList = event.target.classList;
    if (targetClassList) {
      const getClassNameOfChipsWhenTriggered = Object.values(
        targetClassList
      ).find((val) => val === TREE_SELECT_CHIP_CLASS_NAME);
      if (getClassNameOfChipsWhenTriggered) {
        event.stopPropagation();
        return;
      }
    }

    if (
      !treeSelectRef.current.props.disabled &&
      (!treeSelectRef.current.overlayRef ||
        !treeSelectRef.current.overlayRef.current ||
        !treeSelectRef.current.overlayRef.current.contains(event.target)) &&
      !utils.DomHandler.hasClass(event.target, "p-treeselect-close")
    ) {
      treeSelectRef.current.focusInput.focus();
      if (treeSelectRef.current.state.overlayVisible) {
        treeSelectRef.current.hide();
      } else {
        treeSelectRef.current.show();
      }
    }
  };

  const valueTemplateCustom = (_props: TreeNode[]) => {
    if (_props.length === 0) {
      return <span>Select Item</span>;
    }

    return (
      <div className={`${classes["tree-selected__chips"]}`}>
        {_props.map((prop) => {
          const getParent = RadioTechnologyTreeSelectDefaultItems.find(
            (x) => x.key === prop.key?.toString().substring(0, 1)
          );

          if (getParent?.children && getParent?.key !== prop.key) {
            const displayChipLabel = `${getParent?.label}: ${prop.label}`;
            return (
              <div
                key={prop.key}
                className={`p-chip p-component ${classes["tree-selected__chips-item"]}`}
              >
                <span className="p-chip-text">{displayChipLabel}</span>
                {!props.disabled && (
                  <span
                    tabIndex={0}
                    className={`p-chip-remove-icon pi pi-times-circle ${TREE_SELECT_CHIP_CLASS_NAME}`}
                    onClick={(e) => onRemoveSelected(prop, true)}
                  ></span>
                )}
              </div>
            );
          }

          if (!getParent?.children) {
            return (
              <div
                key={prop.key}
                className={`p-chip p-component ${classes["tree-selected__chips-item"]}`}
              >
                <span className="p-chip-text">{getParent?.label}</span>
                {!props.disabled && (
                  <span
                    tabIndex={0}
                    className={`p-chip-remove-icon pi pi-times-circle ${TREE_SELECT_CHIP_CLASS_NAME}`}
                    onClick={(e) => onRemoveSelected(prop, true)}
                  ></span>
                )}
              </div>
            );
          }

          return [];
        })}
      </div>
    );
  };

  const onShowTreeSelect = () => {
    if (treeSelectRef.current) {
      let nodeArrayToExpand: ExpandedKeys = {};
      RadioTechnologyTreeSelectDefaultItems.forEach((item) => {
        nodeArrayToExpand[item.key!] = true;
        checkChildNode(item, nodeArrayToExpand, true);
      });

      treeSelectRef.current?.setState({ expandedKeys: nodeArrayToExpand });
    }
  };

  return (
    <div
      className={`${classes["tree-select__container"]} ${props.controlClassName}`}
    >
      <div>
        <TreeSelect
          className={`${classes["tree-select__container-control"]}`}
          ref={treeSelectRef}
          panelHeaderTemplate={panelHeaderCustomTemplate}
          display="chip"
          value={selectedNode as TreeSelectSelectionKeys}
          selectionMode="checkbox"
          options={RadioTechnologyTreeSelectDefaultItems}
          onShow={onShowTreeSelect}
          valueTemplate={valueTemplateCustom}
          onNodeSelect={(e) => onNodeSelectHandler(e)}
          onNodeUnselect={(e) => onRemoveSelected(e, false)}
          disabled={props.disabled}
        ></TreeSelect>
      </div>
      {checkIfHasOtherField(true) && (
        <div className={`${classes["tree-select__others"]}`}>
          <FormControl
            schemaContext={undefined}
            style={undefined}
            controlClassName={undefined}
            hideHelpTextOnError={undefined}
            labelTemplate={undefined}
            containerClassName={undefined}
            helpTextTemplate={undefined}
            schemaValue={undefined}
            forceShowError={undefined}
            showRequiredErrorMessage={false}
            customErrorMessage={undefined}
            makeContainerFocusable={undefined}
            field={undefined}
            rootObject={undefined}
            dataDiscrepancyOptions={undefined}
            label={undefined}
            value={otherRadioTechnologyType}
            schema={radioDetailsSchema.fields.otherTechnologyType}
            control={
              <InputText
                placeholder="Please specify"
                value={otherRadioTechnologyType}
                onChange={(e) => {
                  setIsUpdateFromUI(true);
                  setOtherRadioTechnologyType(e.target.value);
                }}
              ></InputText>
            }
            helpText={""}
          />
        </div>
      )}
      {checkIfHasOtherField(false) && (
        <div className={`${classes["tree-select__others"]}`}>
          <FormControl
            schemaContext={undefined}
            style={undefined}
            controlClassName={undefined}
            hideHelpTextOnError={undefined}
            labelTemplate={undefined}
            containerClassName={undefined}
            helpTextTemplate={undefined}
            schemaValue={undefined}
            forceShowError={undefined}
            showRequiredErrorMessage={false}
            customErrorMessage={undefined}
            makeContainerFocusable={undefined}
            field={undefined}
            rootObject={undefined}
            dataDiscrepancyOptions={undefined}
            label={undefined}
            value={otherRadioTechnology}
            schema={radioDetailsSchema.fields.otherTechnology}
            control={
              <InputText
                placeholder="Please specify"
                value={otherRadioTechnology}
                onChange={(e) => {
                  setIsUpdateFromUI(true);
                  setOtherRadioTechnology(e.target.value);
                }}
              ></InputText>
            }
            helpText={""}
          />
        </div>
      )}
    </div>
  );
};

export default RadioTechnologyTreeSelect;
