import { countryList } from "../../../../../utils/constants/countries.constants";
import classes from "./Header.module.scss";

const statusColor = [
  {
    status: "Active",
    color: classes["active"],
  },
  {
    status: "Expiring Soon",
    color: classes["expiring"],
  },
  {
    status: "Under Revision",
    color: classes["revision"],
  },
  {
    status: "Obsolete",
    color: classes["obsolete"],
  },
  {
    status: "Withdrawn",
    color: classes["withdrawn"],
  },
];

const Header = ({ closeIcon, details }) => {
  const colorClass = statusColor.find(
    (c) => c.status === details?.certificateStatus
  )?.color;
  const country = countryList.find((c) => c.name === details?.country);
  return (
    <div className={classes["header"]}>
      {country ? (
        <img
          src={`/images/flag-circle/${country?.code}.svg`}
          className={classes["country-image"]}
          alt={country?.name}
        />
      ) : (
        <div className={classes["no-country"]}></div>
      )}

      <div className={classes["label-container"]}>
        <span className={`ul-label ${colorClass} ${classes["status"]}`}>
          {details?.certificateStatus}
        </span>
        <span className={classes["product-label"]}>{details?.productName}</span>
        <span className={classes["country-label"]}>{details?.country}</span>
      </div>
      {closeIcon}
    </div>
  );
};

export default Header;
