import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const linkedProductsColumn = [
  {
    id: "modelName",
    value: "Model Name",
    width: "50%",
  },
  {
    id: "productType",
    value: "Product Type",
    width: "50%",
  },
];

const LinkedProducts = ({ products }) => {
  return (
    <div className="pt-10">
      <span>Linked Products</span>
      <DataTable
        scrollable
        removableSort
        scrollHeight="25rem"
        scrollDirection="both"
        value={products}
        columnResizeMode="expand"
        responsiveLayout="scroll"
        dataKey="id"
      >
        {linkedProductsColumn.map((col) => (
          <Column
            key={col.id}
            columnKey={col.id}
            field={col.id}
            header={col.value}
            style={{ width: col.width, fontSize: 14 }}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default LinkedProducts;
