import React, { Fragment, useEffect, useState } from "react";

const Loader = ({ data }) => {
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    setLoading(data);
  }, [data]);

  return loading ? (
    <svg className="ul-progressCircle -small ul-form__controlIcon">
      <circle className="ul-progressCircle__outer" cx="50%" cy="50%" r="35%" />
      <circle className="ul-progressCircle__inner" cx="50%" cy="50%" r="35%" />
    </svg>
  ) : (
    <Fragment></Fragment>
  );
};

export default Loader;
