import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayPanel } from "primereact/overlaypanel";
import { forwardRef, useImperativeHandle, useRef } from "react";
import "./GridActionPopover.scss";

const GridActionPopover = forwardRef((props, ref) => {
  const op = useRef(null);
  const { actionList, isDisable } = props;

  useImperativeHandle(ref, (event) => ({
    openPopUp(event) {
      event.stopPropagation();
      op.current.toggle(event);
    },
  }));

  return (
    <>
      <OverlayPanel className="grid-action-popover" appendTo="self" ref={op}>
        {actionList.map(function (item, index) {
          return (
            <div key={`grid-action-popover-item-${index}`}
              className={`grid-action-popover-item ${
                isDisable || item.isDisabled ? "disabled" : "active"
              }`}
              onClick={isDisable || item.isDisabled ? undefined : item.action}
            >
              <span>
                <FontAwesomeIcon icon={item.icon} /> {item.textDisplay}
              </span>
            </div>
          );
        })}
      </OverlayPanel>
    </>
  );
});

export default GridActionPopover;
