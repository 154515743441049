import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import "./CheckboxRefiner.scss";
import { useState } from "react";
import { useEffect } from "react";

const CheckboxRefiner = (props) => {
  const [isShow, setIsShow] = useState(false);
  const [isChecked, setIsChecked] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const {
    refinerLabel,
    options,
    addSelectedToYourFilters,
    onClickRemoveRefiner,
    onClickRemoveRefinerGroup,
    refinerId,
    refiners,
  } = props;

  useEffect(() => {
    const filteredRefiners = refiners.filter((i) => i.Id === refinerId);
    let newIsChecked = [false, false, false, false, false];
    let refinersFoundCount = 0;

    if (filteredRefiners && filteredRefiners.length > 0) {
      filteredRefiners.forEach((refiner) => {
        refinersFoundCount = refinersFoundCount + 1;
        let optionIndex = options.findIndex((i) => i === refiner.Value);
        newIsChecked[optionIndex] = true;
      });
    }

    setIsChecked(newIsChecked);
    if (refinersFoundCount === 4) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, [refiners, options, refinerId]);

  const onChangeSelected = (e) => {
    if (e.target.checked) {
      addSelectedToYourFilters(e.target.value);
    } else {
      onClickRemoveRefiner({ Value: e.target.value, Id: refinerId });
    }
  };

  const onChangeSelectAll = (e) => {
    if (e.target.checked) {
      options.forEach((item) => {
        addSelectedToYourFilters(item);
      });
    } else {
      onClickRemoveRefinerGroup(refinerId);
    }
  };

  return (
    <div className="ul-card -app">
      <div
        className="ul-card__item -bordered ul-card__head cursor-pointer s-refiner-height s-container-text"
        onClick={() => setIsShow((prevState) => !prevState)}
      >
        <span className="font-size-14 font-weight-semi-bold">
          {refinerLabel}
        </span>
        <span className="float-right">
          <FontAwesomeIcon icon={isShow ? faCaretDown : faCaretRight} />
        </span>
      </div>

      {isShow && (
        <div className="ul-card__item ul-card__body s-select-container">
          <div>
            <input
              type="checkbox"
              value="Select All"
              onChange={onChangeSelectAll}
              checked={isCheckAll}
            />
            &nbsp;<span>Select All</span>
          </div>
          {options.map((option, index) => (
            <div key={"cert-plv-checkbox-refiner-" + index}>
              <input
                type="checkbox"
                value={option}
                onChange={onChangeSelected}
                checked={isChecked[index]}
              />
              &nbsp;<span>{option}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CheckboxRefiner;
