import { OverlayPanel } from "primereact/overlaypanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import classes from "./CertificateProductListShowHide.module.scss"

const CertificateProductListShowHide = (props) => {
    const op = useRef(null);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [frozenCount, setFrozenCount] = useState([...Array(8).keys()]);

    const { fields
        , frozenColCount
        , setCertListColumns
        , setCertFrozenCount
    } = props;

    useEffect(() => {
        const isNotShown = fields.find(f => !f.isShown);

        if(isNotShown){
            setIsSelectAll(false);
        }
        else{
            setIsSelectAll(true);
        }
      }, []);


    const dynamicOptions = frozenCount.map((num, i) => {
        return (
            <option value={num+1}>{num+1}</option>
        );
    });

    const rowClass = (data) => {
        return {
            [classes.rowfrozen]:data.isFrozen
        };
    };

    const onRowReorder = (e) => {

        // if (e.value[e.dragIndex].isFrozen || e.value[e.dropIndex].isFrozen){

        //     return;
        // }

        if (fields[e.dropIndex].isFrozen || fields[e.dragIndex].isFrozen){
            return;
        }
 
        const columns = e.value.map((col, i) => {
            let column = col;
            column.orderNo = i + 1;
            return column;
        });

        updateCertListColumns(frozenColCount, columns);
    }

    const showColumn = (e) => {
        const { id, checked } = e.target;
        const columns = fields.map((f) => {
            if (f.id === id) {
                return {
                    ...f,
                    isShown: checked,
                };
            } else {
                return f;
            }
        });

        const hasUnchecked = columns.filter(f => !f.isShown).length > 0;

        setIsSelectAll(!hasUnchecked);
        setCertListColumns(columns);
    };

    const onFrozenColCountChange = (e) => {
        const frozenCount = e.target.value;
        updateCertListColumns(frozenCount, fields);
    }

    const updateCertListColumns = (frozenCount, certFields) => {

        const columns = certFields.map((f) => {
            if (f.orderNo <= frozenCount) {
                return {
                    ...f,
                    isShown: true,
                    isFrozen: f.isFrozen
                };
            } else {
                return {
                    ...f,
                    isFrozen: f.isFrozen
                };
            }
        });

        setCertListColumns(columns);
        setCertFrozenCount(frozenCount);
    }

    const onSelectAll = (e) => {
        const { id, checked } = e.target;

        const columns = fields.map((f) => {
            if (checked) {
                return {
                    ...f,
                    isShown: true,
                };
            } else {
                if(f.isFrozen){
                    return f;
                }
                else{
                    return {
                        ...f,
                        isShown: false
                    }
                }
            }
        });

        setCertListColumns(columns);
        setIsSelectAll(checked);
    }

    return (
        <>
            <button type="button" className={`ul-button ${classes['show-hide-button']}`} onClick={(e) => op.current.toggle(e)} >
                <FontAwesomeIcon icon={faBookOpen}></FontAwesomeIcon>
            </button>

            <OverlayPanel ref={op} dismissable>
                <div className={classes['title-caption']}>
                    <strong>Show/Hide Columns</strong>
                </div>
                {/* <div className={classes['freeze-column']}>
                    <span>Show/Freeze </span>
                    <span>
                        <select 
                            style={{width: '35px'}}
                            value={frozenColCount} 
                            onChange={onFrozenColCountChange}>
                            {dynamicOptions}
                        </select>
                    </span>
                    <span> Columns </span>
                </div> */}
                <DataTable
                    scrollable
                    removableSort
                    scrollHeight="400px"
                    scrollDirection="both"
                    value={fields}
                    rowClassName={rowClass}
                    size="small"
                    showGridlines
                    onRowReorder={onRowReorder}
                    columnResizeMode="expand"
                    responsiveLayout="scroll">
                    <Column
                        rowReorder
                        className={classes['drag-body']}
                        style={{ width: "30px" }}>
                    </Column>
                    <Column
                        key="isShown"
                        className={classes['checkbox-body']}
                        header={
                            <input
                                type="checkbox"
                                className={classes['checkbox']}
                                onChange={onSelectAll}
                                checked={isSelectAll}
                            />
                        }
                        body={(rowData, column) => (
                            <input
                                type="checkbox"
                                id={rowData.id}
                                className={classes['checkbox']}
                                onChange={showColumn}
                                checked={rowData.isShown}
                                disabled={rowData.isFrozen}
                            />
                        )
                        }
                        style={{ width: "40px" }}>
                    </Column>
                    <Column
                        key="orderNo"
                        field="orderNo"
                        header="Order"
                        align="center"
                        headerClassName={classes['table-header']}
                        style={{ width: "70px" }}>
                    </Column>
                    <Column
                        key="value"
                        field="value"
                        header="Column Header"
                        align="center"
                        headerClassName={classes['table-header']}
                        style={{ flexGrow: 1, flexBasis: "110px" }}>
                    </Column>
                </DataTable>
            </OverlayPanel>
        </>
    );
};

export default CertificateProductListShowHide;