import { Dialog } from "primereact/dialog";
import classes from "./CertificateDetailsAttachmentsDialog.module.scss";
import CloseIcon from "../../../../../shared/close-icon/CloseIcon";
import AddAttachmentsBulk, {
  IAttachmentBulkFileObject,
} from "../../add-new-certificate/add-attachments-bulk/AddAttachmentsBulk";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { CertificateDocumentType } from "../../../../../enums/CertificateDocumentType";
import { CertificateDocumentStatus } from "../../../../../enums/CertificateDocumentStatus";
import { parseToString } from "../../../../../utils/helpers/string-format.helper";
import useToastr from "../../../../../hooks/useToastr";
import { objectToFormData } from "../../../../../utils/helpers/object.helpers";
import CertificateListService from "../../../../../services/CertificateListService";
import { reloadCertificateDetails } from "../../../../../features/certificateList/certificateListSlice";
import { useTestReportValidator } from "../../add-new-certificate/test-report-matched-found-popup/useTestReportValidator";
import TestReportMatchedFoundPopup from "../../add-new-certificate/test-report-matched-found-popup/TestReportMatchedFoundPopup";
import { IPModel } from "../../../../../shared/models/IPModel.model";
import { CertificateEditDataModel } from "../../../../../shared/models/CertificateEditData.model";
import FeatureFlagService from "../../../../../services/FeatureFlagService";
import { TestReportMatchedFoundFileModel } from "../../../../../shared/models/TestReportMatchedFoundFile.model";

const CertificateDetailsAttachmentsDialog = (props: any) => {
  const {
    isOpen,
    onClose,
    details,
    inputAttachments,
    setAttachments,
    certificateDetailFromE2E,
  } = props;
  const dispatch = useDispatch();
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const [isDisableSaving, setIsDisableSaving] = useState(true);
  const [initialAttachmentsLocal, setInitialAttachmentsLocal] = useState([
    [],
    [],
    [],
  ] as any);

  const [isShowSave, setsShowSave] = useState(true);
  const [isShowLinkFile, setIsShowLinkFile] = useState(false);
  const [isCancelConfirmDialogShow, setIsCancelConfirmDialogShow] =
    useState(false);
  const [isLinkConfirmDialogShow, setIsLinkConfirmDialogShow] = useState(false);
  const [attachmentActiveTabIndex, setAttachmentActiveTabIndex] = useState(0);
  const [outputAttachmentValues, setOutputAttachmentValues] = useState(
    null as any
  );
  const { showSuccess, showError, showInfo } = useToastr();
  const [testReportMatchFound, setTestReportMatchFound] =
    useState<TestReportMatchedFoundFileModel[]>();
  const testReportsValidatorHook = useTestReportValidator();

  useEffect(() => {
    if (isOpen) {
      if (outputAttachmentValues) {
        const attachmentsForSaving = []
          .concat(...outputAttachmentValues)
          .filter((file: IAttachmentBulkFileObject) => file.id == "");
        if (attachmentsForSaving.length > 0) {
          setsShowSave(true);
        } else setsShowSave(false);
      } else {
        setsShowSave(false);
      }
    } else {
      setInitialAttachmentsLocal(null);
      setsShowSave(false);
    }
  }, [isOpen, outputAttachmentValues]);

  useEffect(() => {
    if (!isOpen) return;
    processAttachmentDataAndButtons(outputAttachmentValues);
  }, [outputAttachmentValues]);

  const processAttachmentDataAndButtons = (attachmentValues: any) => {
    if (attachmentValues && [].concat(...attachmentValues).length > 0) {
      setIsDisableSaving(false);

      let hasValid = false;

      for (let fileGroup of attachmentValues) {
        hasValid =
          fileGroup?.some(
            (file: any) =>
              file.isValid == true &&
              file.status !=
                CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
          ) == true;
        if (hasValid) break;
      }

      if (hasValid) {
        setIsDisableSaving(false);
      } else {
        setIsDisableSaving(true);
      }
      if (
        !initialAttachmentsLocal ||
        [].concat(...initialAttachmentsLocal).length == 0
      ) {
        setInitialAttachmentsLocal(processInitialAttachments(attachmentValues));
      }
    } else {
      setIsDisableSaving(true);
      setsShowSave(false);
      setInitialAttachmentsLocal(null);
    }
  };
  const processInitialAttachments = (attachmentValues: any): any => {
    //this function is use to recreate the attachment list so it is not pass as a reference object
    const initialAttachments: any = [].concat(...attachmentValues);
    const certificateAttachments = initialAttachments.filter(
      (file: IAttachmentBulkFileObject) =>
        file[`group_index`] == CertificateDocumentType.Certificate
    );

    const testReportAttachments = initialAttachments.filter(
      (file: IAttachmentBulkFileObject) =>
        file[`group_index`] == CertificateDocumentType.TestReport
    );

    const othersAttachments = initialAttachments.filter(
      (file: IAttachmentBulkFileObject) =>
        file[`group_index`] == CertificateDocumentType.Others
    );

    const attachments = [
      certificateAttachments,
      testReportAttachments,
      othersAttachments,
    ];
    return attachments;
  };
  useEffect(() => {
    if (!isOpen) return;
    processShowLink(isShowSave, outputAttachmentValues);
  }, [isShowSave, outputAttachmentValues]);

  const processShowLink = (isShowSave: boolean, attachmentValues: any) => {
    setIsShowLinkFile(false);
    if (isShowSave) {
      const attachments: any = attachmentValues;
      const allAttachments = [].concat(...attachments);
      const hasAttachmentsForLinking =
        allAttachments.filter(
          (file: IAttachmentBulkFileObject) =>
            file[`group_index`] != CertificateDocumentType.Others &&
            file.id == ""
        ).length > 0;
      if (hasAttachmentsForLinking) setIsShowLinkFile(true);
    }
  };

  const cancelModal = () => {
    if (
      parseToString(initialAttachmentsLocal) !=
      parseToString(outputAttachmentValues)
    ) {
      setIsCancelConfirmDialogShow(true);
      return;
    }
    closeModal();
  };

  const saveCloseModal = () => {
    dispatch(reloadCertificateDetails(true));
    closeModal();
  };

  const closeModal = () => {
    setInitialAttachmentsLocal(null);
    onClose();
  };

  const acceptCancelConfirmDialog = () => {
    setIsCancelConfirmDialogShow(false);
    onClose();
  };

  const rejectCancelConfirmDialog = () => {
    setIsCancelConfirmDialogShow(false);
  };

  const onClickLink = () => {
    setIsLinkConfirmDialogShow(true);
  };

  const acceptLinkConfirmDialog = () => {
    setIsLinkConfirmDialogShow(false);
    validateTestReport();
  };

  const rejectLinkConfirmDialog = () => {
    setIsLinkConfirmDialogShow(false);
  };

  const validateTestReport = async () => {
    if (
      FeatureFlagService.getECMFeatureFlag() &&
      FeatureFlagService.getLinkTestReportToMultipleCertificateFlag()
    ) {
      var duplicateTestReportRows = outputAttachmentValues[
        CertificateDocumentType.TestReport
      ].filter(
        (testReportDocument: any) =>
          testReportDocument.status ==
            CertificateDocumentStatus.TestReportDuplicate &&
          !testReportDocument.id
      );

      setTestReportMatchFound(
        duplicateTestReportRows.map((document: any) => {
          const ret: TestReportMatchedFoundFileModel = {
            name: document.name,
            ecmNodeId: document.ecmNodeId,
            isInEcmTic: true,
            associatedCertificates: document.associatedCertificates,
            group_index: 1,
          };

          return ret;
        })
      );

      if (duplicateTestReportRows.length > 0) {
        testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(true);
      } else {
        saveProcess(true);
      }
    } else {
      saveProcess(true);
    }
  };

  const saveProcess = async (
    isLinkAndEcm = false,
    // When linking with ecm and if this param is set to false, it will validate if there are duplicate test reports,
    // otherwise will save the attachments with auto linking of the existing test reports
    proceedWithMatchingTestReportsFound: boolean = false
  ) => {
    setIsSavingInProgress(true);
    let model: any = {};
    let attachments: any[] = [];

    if (outputAttachmentValues) {
      const allAttachments = []
        .concat(...outputAttachmentValues)
        .filter(
          (attachment: IAttachmentBulkFileObject) =>
            attachment.isValid == true && !attachment.ecmFileNodeId
        );
      allAttachments.forEach((attachment: IAttachmentBulkFileObject) => {
        if (
          attachment.status !=
          CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
        ) {
          let obj: any = {};
          let documentType = attachment.group_index;
          obj.file = attachment.file;
          obj.documentType = documentType;
          obj.status =
            (obj.id == null || obj.id == "") &&
            documentType != CertificateDocumentType.Others &&
            isLinkAndEcm
              ? CertificateDocumentStatus.UploadToEcmInProgress
              : attachment.status;
          obj.ecmNodeId = attachment.ecmNodeId;
          attachments.push(obj);
        }
      });
    }

    model.certificateFiles = attachments;
    model.certificateId = details.certificateId;
    model.isSaveAndActivate = isLinkAndEcm;
    model.iPCertificateDetailsModelJson = JSON.stringify(details);
    let formData = objectToFormData(model);

    let response =
      await CertificateListService.certificateAttachmentSaveLinkUploadECM(
        formData
      );

    if (response?.isSuccess) {
      if (isLinkAndEcm) {
        showInfo(
          "Document Upload Ongoing",
          "The document(s) you added is now being uploaded to Workbench and ECM TIC. You will receive a notification once completed."
        );
      } else {
        showSuccess(
          "Success!",
          `The document(s) has been uploaded temporarily to GMAWB File Storage.`
        );
      }

      saveCloseModal();
    } else {
      showError(
        "Error!",
        response
          ? response.message
          : `Error while saving the certificate attachment`
      );
    }
    setIsSavingInProgress(false);
  };

  const confirmCancelDialogBody = () => {
    return (
      <>
        <div className="custom-confirm-dialog-body">
          <div className="custom-space-below">
            All files you uploaded will be discarded.
          </div>
          <div>Would you like to Continue?</div>
        </div>
      </>
    );
  };

  const confirmCancelFooter = () => {
    return (
      <>
        <div className="custom-confirm-dialog-footer">
          <button
            className="ul-button -app-primary -medium custom-confirm-dialog-accept-btn"
            onClick={acceptCancelConfirmDialog}
          >
            YES
          </button>
          <button
            className="ul-button -medium default-cancel-button custom-confirm-dialog-reject-btn"
            onClick={rejectCancelConfirmDialog}
          >
            NO
          </button>
        </div>
      </>
    );
  };

  const confirmLinkDialogBody = () => {
    return (
      <>
        <div className="custom-confirm-dialog-body">
          <div className="custom-space-below">
            File(s) will be saved to ECM TIC and cannot be deleted.
          </div>
          <div>Would you like to Continue?</div>
        </div>
      </>
    );
  };

  const confirmLinkFooter = () => {
    return (
      <>
        <div className="custom-confirm-dialog-footer">
          <button
            className="ul-button -app-primary -medium custom-confirm-dialog-accept-btn"
            onClick={acceptLinkConfirmDialog}
          >
            YES
          </button>
          <button
            className="ul-button -medium default-cancel-button custom-confirm-dialog-reject-btn"
            onClick={rejectLinkConfirmDialog}
          >
            NO
          </button>
        </div>
      </>
    );
  };

  const footer = (
    <>
      <hr className="modal-footer-hr" />
      {!isShowSave && (
        <div
          className={`${classes["dialog-footer"]} ${classes["center-items"]}`}
        >
          <button
            className={`ul-button -small ${classes["dialog-cancel-btn"]}`}
            onClick={closeModal}
          >
            CLOSE
          </button>
        </div>
      )}
      {isShowSave && (
        <div
          className={`${classes["dialog-footer"]} ${classes["right-items"]}`}
        >
          <button
            className={`ul-button -small ${classes["dialog-cancel-btn"]}`}
            onClick={cancelModal}
          >
            CANCEL
          </button>
          <button
            className={`ul-button -small ${classes["dialog-save-btn"]}`}
            disabled={isDisableSaving}
            onClick={() => saveProcess(false)}
          >
            SAVE
          </button>
          {details?.certificateStatus === "Active" && (
            <>
              {isShowLinkFile && (
                <button
                  className={`ul-button -small ${classes["dialog-link-btn"]}`}
                  disabled={isDisableSaving}
                  onClick={() => onClickLink()}
                >
                  LINK FILE TO WB AND ECM TIC
                </button>
              )}
            </>
          )}
        </div>
      )}
    </>
  );

  return (
    <>
      <Dialog
        style={{ width: "70vw" }}
        header="Add Attachments"
        visible={isOpen}
        footer={footer}
        onHide={() => {
          cancelModal();
        }}
        icons={
          <CloseIcon
            onCloseEvent={() => {
              cancelModal();
            }}
          />
        }
        closable={false}
      >
        <hr className="modal-header-hr" />

        <div className={classes["add-attachments-container"]}>
          <div
            style={{
              display:
                isSavingInProgress || testReportsValidatorHook.isValidating
                  ? "none"
                  : "",
            }}
          >
            <AddAttachmentsBulk
              setAttachments={setAttachments}
              certificateDetailFromE2E={certificateDetailFromE2E}
              setAttachmentActiveTabIndex={setAttachmentActiveTabIndex}
              setOutputAttachmentValues={setOutputAttachmentValues}
              inputAttachments={inputAttachments}
              testReportsValidatorHook={testReportsValidatorHook}
              details={details}
            />
          </div>
          {isSavingInProgress && (
            <div className={classes["loading-div"]}>
              <div>Saving attachments......</div>
              <div>
                <svg className="ul-progressCircle -medium">
                  <circle
                    className="ul-progressCircle__outer"
                    cx="50%"
                    cy="50%"
                    r="35%"
                  />
                  <circle
                    className="ul-progressCircle__inner"
                    cx="50%"
                    cy="50%"
                    r="35%"
                  />
                </svg>
              </div>
            </div>
          )}
          {testReportsValidatorHook.isValidating && (
            <div className={classes["loading-div"]}>
              <div>Validating duplicate Test Report(s)...</div>
              <div>
                <svg className="ul-progressCircle -medium">
                  <circle
                    className="ul-progressCircle__outer"
                    cx="50%"
                    cy="50%"
                    r="35%"
                  />
                  <circle
                    className="ul-progressCircle__inner"
                    cx="50%"
                    cy="50%"
                    r="35%"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </Dialog>
      <ConfirmDialog
        style={{ width: "520px" }}
        className="custom-confirm-dialog"
        visible={isCancelConfirmDialogShow}
        header="Cancel Upload Confirmation"
        message={confirmCancelDialogBody}
        footer={confirmCancelFooter}
        onHide={() => rejectCancelConfirmDialog()}
      />
      <ConfirmDialog
        style={{ width: "520px" }}
        className="custom-confirm-dialog"
        visible={isLinkConfirmDialogShow}
        header="Link File to WB and ECM TIC Confirmation"
        message={confirmLinkDialogBody}
        footer={confirmLinkFooter}
        onHide={() => rejectLinkConfirmDialog()}
      />
      <TestReportMatchedFoundPopup
        visible={testReportsValidatorHook.testReportMatchedFoundPopupVisible}
        onProceedWithoutDuplicate={() => {
          testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
        }}
        onProceedWithDuplicate={() => {
          testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
          saveProcess(true, true);
        }}
        onClose={() => {
          testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
        }}
        rows={testReportMatchFound}
      />
    </>
  );
};

export default CertificateDetailsAttachmentsDialog;
