import { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { DataTable } from "primereact/datatable";
import clsx from "clsx";
import classes from "./ColumnResizer.module.scss";

type ColumnResizerProps = {
  datatable: React.RefObject<DataTable>;
  onColumnResize: (params: OnColumnResizeParams) => void;
  field: string;
  colWidth: number | string;
  minWidth?: number;
  maxWidth?: number;
};

type OnColumnResizeParams = {
  column: {
    props: {
      field: string;
    };
  };
  element: {
    offsetWidth: number;
  };
};

const ColumnResizer = ({
  datatable,
  onColumnResize,
  field,
  colWidth,
  minWidth = 50,
  maxWidth = 600,
}: ColumnResizerProps) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [linePosition, setLinePosition] = useState({ x: 0, y: 0 });
  const [lineStartPosition, setLineStartPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: any) => {
      if (!isDrawing) return;
      setLinePosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseUp = (e: any) => {
      if (isDrawing) {
        const distance = e.clientX - lineStartPosition.x;
        const currentColWidth = isNaN(colWidth as number)
          ? +(colWidth as string).replace("px", "")
          : +colWidth;
        let newWidth = currentColWidth + distance;

        if (newWidth < minWidth) {
          newWidth = minWidth;
        }

        if (newWidth > maxWidth) {
          newWidth = maxWidth;
        }

        onColumnResize({
          column: {
            props: {
              field,
            },
          },
          element: {
            offsetWidth: newWidth,
          },
        });
      }

      setIsDrawing(false);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDrawing]);

  const handleMouseDown = (e: any) => {
    setIsDrawing(true);
    const linePosition = { x: e.clientX, y: e.clientY };
    setLineStartPosition(linePosition);
    setLinePosition(linePosition);
  };

  return (
    <>
      <div
        className={clsx("p-column-resizer", classes["column-resizer"])}
        onMouseDown={handleMouseDown}
      ></div>

      {isDrawing && (
        <Portal>
          <div
            style={{
              position: "absolute",
              zIndex: 999999,
              left: linePosition.x,
              top: (datatable.current! as any).el.getBoundingClientRect().y,
              height: (datatable.current! as any).el.getBoundingClientRect()
                .height,
              width: 1,
              backgroundColor: "#6366F1",
            }}
          />
        </Portal>
      )}
    </>
  );
};

export default ColumnResizer;
