import { Checkbox as PCheckbox } from "primereact/checkbox";
import classes from "./Checkbox.module.scss";

const Checkbox = ({
  label,
  helpText,
  helpTextOptions,
  inputId,
  containerClassName,
  ...otherProps
}) => {
  const _inputId = inputId || "cb" + label.replace(/\s/g, "");

  return (
    <div
      className={
        classes["container"] +
        " " +
        containerClassName +
        " " +
        (otherProps.disabled ? classes["disabled"] : "")
      }
    >
      <div className={classes["checkbox-container"]}>
        <PCheckbox
          inputId={_inputId}
          className={classes["checkbox"]}
          {...otherProps}
        />
        <label className={classes["checkbox-label"]} htmlFor={_inputId}>
          {label}
        </label>

        {helpText && !helpTextOptions?.position && (
          <small className={classes["help-text-right"]}>{helpText}</small>
        )}
      </div>

      {helpText && helpTextOptions?.position === "bottom" && (
        <label className={classes["help-text-bottom"]}>{helpText}</label>
      )}
    </div>
  );
};

export default Checkbox;
