const ECM = process.env.REACT_APP_FEATUREFLAG_ECM;
const linkTestReportToMultipleCertificate =
  process.env.REACT_APP_FEATUREFLAG_LinkTestReportToMultipleCertificate;

const FeatureFlagService = {
  getECMFeatureFlag: (): boolean => ECM && JSON.parse(ECM),
  getLinkTestReportToMultipleCertificateFlag: (): boolean =>
    linkTestReportToMultipleCertificate &&
    JSON.parse(linkTestReportToMultipleCertificate),
};

export default FeatureFlagService;
