import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ProjectTemplateMappingService from "../../../services/ProjectTemplateMappingService";
import { FlexProjectTemplateDetailModel } from "../../../shared/models/FlexProjectTemplateTaskDetail.model";
import { ProjectTemplateMappingHeaderModel } from "../../../shared/models/ProjectTemplateMappingHeader.model";

import { ProjectTemplateMappingModes } from "../../project-template-mapping/shared/enums/ProjectTemplateMappingModes";
import { ProjectTemplateStatus } from "../../project-template-mapping/shared/enums/ProjectTemplateStatus";
import classes from "./ProjectTemplateAddModal.module.scss";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import FormTemplateError, {
  FormTemplateErrorProps,
} from "../../project-template-mapping/shared/component/FormTemplateError";
import { isValidFilename } from "../../../utils/helpers/file.helpers";
import { ProjectTemplateErrorMessages } from "../shared/enums/ProjectTemplateErrorMessages";

export interface ProjectTemplateAddModalProps {
  showAddModal: boolean;
  setShowAddModal: Function;
  e2ETemplateName: string;
  setE2ETemplateName: React.Dispatch<React.SetStateAction<string>>;
}

const ProjectTemplateAddModal = (
  componentProps: ProjectTemplateAddModalProps
) => {
  const history = useHistory();

  const [flexTemplateDropdownValue, setFlexTemplateDropdownValue] =
    useState("");
  const [flexTemplateList, setFlexTemplateList] = useState<
    FlexProjectTemplateDetailModel[]
  >([]);

  const [templateNameError, setTemplateNameError] =
    useState<FormTemplateErrorProps>({
      visible: false,
      errorMessage: "",
      icon: faClose,
    });
  const [loadingApplyButton, setLoadingApplyButton] = useState<boolean>(false);

  const inputText = useRef<any>();
  const handleOnShow = () => {
    if (inputText.current) {
      inputText.current.focus();
      inputText.current.select();
    }

    ProjectTemplateMappingService.getFlexTemplateListIntegration()
      .then((response) => {
        setFlexTemplateList(response);
      })
      .catch((error) => console.error(error));
  };

  const handleOnHide = () => {
    setFlexTemplateDropdownValue("");
    setFlexTemplateList([]);
    setTemplateNameError((current: any) => ({
      ...current,
      visible: false,
      errorMessage: "",
    }));
    componentProps.setShowAddModal(false);
    componentProps.setE2ETemplateName("");
  };

  const handleE2ETemplateName = (value: string) => {
    componentProps.setE2ETemplateName(value);

    if (!isValidFilename(value)) {
      setTemplateNameError((current) => ({
        ...current,
        visible: true,
        errorMessage:
          ProjectTemplateErrorMessages.TEMPLATE_NAME_CONTAINS_INVALID_CHARS,
      }));

      return;
    }

    if (templateNameError.visible)
      setTemplateNameError((current) => ({
        ...current,
        visible: false,
        errorMessage: "",
      }));
  };

  const handleApplyClick = () => {
    const newTemplateName = componentProps.e2ETemplateName.trim();

    if (!isValidFilename(newTemplateName)) {
      setTemplateNameError((current) => ({
        ...current,
        visible: true,
        errorMessage:
          ProjectTemplateErrorMessages.TEMPLATE_NAME_CONTAINS_INVALID_CHARS,
      }));

      return;
    }

    setLoadingApplyButton(true);
    const flexDetails = flexTemplateList.find(
      (x) => x.projectTemplateId === flexTemplateDropdownValue
    )!;
    ProjectTemplateMappingService.verifyTemplateDuplicate({
      flexTemplateId: flexTemplateDropdownValue,
      templateName: newTemplateName,
      correlationId: null,
    }).then((response) => {
      if (!response.data.hasError) {
        const newTemplate: ProjectTemplateMappingHeaderModel = {
          id: null,
          correlationId: null,
          name: newTemplateName,
          formattedVersion: "1.0",
          flexTemplateId: flexDetails.projectTemplateId!,
          flexTemplateName: flexDetails.templateName!,
          flexCorrelationId: flexDetails.correlationId!,
          templateStatus: ProjectTemplateStatus.Draft,
          templateMode: ProjectTemplateMappingModes.Add,
          additionalInfoId: "",
        };
        componentProps.setShowAddModal(false);
        history.push({
          pathname: "/template-admin-mapping",
          state: newTemplate,
        });
      } else {
        const CreateHyperlink = () => {
          if (!response.data.hasUsedInE2EProjects) {
            return <>{response.message}</>;
          }

          const manipulateErrorMessage = response.message.split("{0}");
          return (
            <>
              <span>
                {manipulateErrorMessage[0]}
                {manipulateErrorMessage[1]}
              </span>
            </>
          );
        };

        setTemplateNameError((current) => ({
          ...current,
          visible: true,
          errorMessage: <CreateHyperlink />,
        }));
        setLoadingApplyButton(false);
      }
    });
  };

  const footerContent = () => {
    return (
      <div className={`${classes["footer-button__container"]}`}>
        <Button
          disabled={
            !componentProps.e2ETemplateName.trim() || !flexTemplateDropdownValue
          }
          className={`${classes["apply-button"]}`}
          label="Apply"
          onClick={() => handleApplyClick()}
          loading={loadingApplyButton}
        />
        <Button
          className={`${classes["cancel-button"]}`}
          label="Cancel"
          onClick={handleOnHide}
        />
      </div>
    );
  };

  return (
    <>
      <Dialog
        header="Create New GMA WB Template"
        visible={componentProps.showAddModal}
        style={{ width: "40vw" }}
        onHide={handleOnHide}
        onShow={handleOnShow}
        footer={footerContent}
        draggable={false}
        className={`${classes["dialog-close-button"]}`}
      >
        <>
          <div className={`${classes["dialog-content"]}`}>
            <div className="ul-grid__row">
              <div className="ul-grid__column">
                <div className="column-fill">
                  <div className={`${classes["text-label"]}`}>
                    <label>
                      New GMA WB Template Name
                      <span className={`${classes["required-field"]}`}> *</span>
                    </label>
                  </div>
                  <div className={`${classes["text-box__container"]}`}>
                    <InputText
                      className={`${classes["text-box"]} ${
                        templateNameError.visible
                          ? `p-invalid ${classes["text-box--error"]}`
                          : ""
                      }`}
                      value={componentProps.e2ETemplateName}
                      onChange={(e) => handleE2ETemplateName(e.target.value)}
                      ref={inputText}
                    />
                    {templateNameError.visible && (
                      <FormTemplateError
                        errorMessage={templateNameError.errorMessage}
                        visible={templateNameError.visible}
                        icon={templateNameError.icon}
                      />
                    )}
                  </div>
                </div>

                <div className={`${classes["text-label"]}`}>
                  <label>
                    Flex Template
                    <span className={`${classes["required-field"]}`}> *</span>
                  </label>
                </div>
                <div className={`${classes["dropdown-flex__container"]}`}>
                  <Dropdown
                    className={`${classes["dropdown-flex"]}`}
                    optionLabel={"templateName"}
                    optionValue={"projectTemplateId"}
                    options={flexTemplateList}
                    placeholder={
                      flexTemplateList?.length > 0
                        ? "-- Select --"
                        : "-- Loading --"
                    }
                    onChange={(e) => setFlexTemplateDropdownValue(e.value)}
                    disabled={flexTemplateList?.length === 0}
                    value={flexTemplateDropdownValue}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </Dialog>
    </>
  );
};

export default ProjectTemplateAddModal;
