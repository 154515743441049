import React from "react";
import classes from "./SpinnerLoaderSvg.module.scss";
import { ReactComponent as InfoSvg } from "./validation_icon.svg";
import { ReactComponent as WarningSvg } from "./validation_icon_warning.svg";
const SpinnerLoaderSVG = (props) => {
  const { classType, clicked } = props;
  return (
    <div className={classes["svg-container"]} onClick={clicked}>
      {classType == "info" ? <InfoSvg /> : <WarningSvg />}
    </div>
  );
};

export default SpinnerLoaderSVG;
