import { Dialog } from "primereact/dialog";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { E2EUserModel } from "../../../models/E2EUserModel";
import classes from "./AssignFlexHandlerModal.module.scss";

interface AssignFlexHanderModalProps {
  onHide: () => void;
  visible: boolean;
  projectHandlers: E2EUserModel[];
  onSaveNewFlexHandler: (newHandlerEmail: string) => void;
}

const AssignFlexHandlerModal = ({
  onHide,
  visible,
  projectHandlers,
  onSaveNewFlexHandler,
}: AssignFlexHanderModalProps) => {
  const [selectedProjectHandler, setSelectedProjectHandler] =
    useState<E2EUserModel | null>(null);

  useEffect(() => {
    setSelectedProjectHandler(null);
  }, [visible])

  const handlerProjectHandlerChange = (e: DropdownChangeParams) => {
    setSelectedProjectHandler(e.value);
  };

  const handleSave = () => {
    onSaveNewFlexHandler(selectedProjectHandler?.normalizedEmail || "");
  };

  const header = () => {
    return (
      <>
        <h4 className={classes["modal__header"]}>Assign FLEX Handler</h4>
        <hr className={classes["modal__header-hr"]} />
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <div className={`text-center ${classes["footer-content"]}`}>
          <button
            disabled={!selectedProjectHandler}
            className={`ul-button -app-primary ${classes["btn-save"]}`}
            onClick={handleSave}
          >
            Save
          </button>

          <button
            className={`ul-button -app-default ${classes["btn-cancel"]}`}
            onClick={() => onHide()}
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  return (
    <Dialog
      closable={true}
      draggable={false}
      resizable={false}
      header={header}
      footer={footer}
      onHide={() => onHide && onHide()}
      visible={visible}
      style={{ width: "630px" }}
      className={classes["container"]}
    >
      <div className={classes["modal__body"]}>
        <Dropdown
          value={selectedProjectHandler}
          onChange={handlerProjectHandlerChange}
          filter={true}
          optionLabel="displayName"
          options={projectHandlers}
          className={classes["assign-flex-handler__dropdown"]}
          placeholder="-- Select --"
        />
      </div>
    </Dialog>
  );
};

export default AssignFlexHandlerModal;
