import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import { DocumentKnowledgeRequestModel } from "../../shared/service-models/DocumentKnowledgeRequestModel.model";
import DocKnowledgeButtons from "./doc-knowledge-buttons/DocKnowledgeButtons";
import DocKnowledgeMultiSelects from "./doc-knowledge-multi-selects/DocKnowledgeMultiSelects";
import classes from "./DocumentKnowledge.module.scss";
import DocKnowledgeNavTabs from "./doc-knowledge-nav-tabs/DocKnowledgeNavTabs";
import { DataTableSortOrderType } from "primereact/datatable";

export interface DataTableSortProps {
  devPath: DataTableSortObject;
  commonDoc: DataTableSortObject;
  countrySpec: DataTableSortObject;
  testSample: DataTableSortObject;
  localRep: DataTableSortObject;
}

interface DataTableSortObject {
  column: string;
  sort: DataTableSortOrderType;
}

const DocumentKnowledge = () => {
  const [isJapanese, setIsJapanese] = useState(false);
  const [sorting, setSorting] = useState<DataTableSortProps>({
    devPath: {
      column: "index",
      sort: 1,
    },
    commonDoc: {
      column: "index",
      sort: 1,
    },
    countrySpec: {
      column: "index",
      sort: 1,
    },
    testSample: {
      column: "index",
      sort: 1,
    },
    localRep: {
      column: "index",
      sort: 1,
    },
  });

  const [request, setRequest] = useState<DocumentKnowledgeRequestModel>({
    schemeSelected: "",
    devPathSelected: "",
    applicationTypeSelected: "",
    technologySelected: "",
  });

  const handleRequestModelChange = (request: DocumentKnowledgeRequestModel) => {
    request.devPathSelected = request.devPathSelected || "[]";
    request.applicationTypeSelected = request.applicationTypeSelected || "[]";
    request.technologySelected = request.technologySelected || "[]";

    setRequest(request);
  };

  return (
    <div className={classes["doc-knowledge-full-div"]}>
      <div className={classes["document-knowledge-container"]}>
        <PageTitle title="Document Knowledge"></PageTitle>
        <span className={`${classes["description"]} ul-body-2`}>
          Want to know the required documents for a specific Certificate Scheme,
          Delivery Path, and Technology? Fill out the form below.
        </span>
      </div>

      <div className={classes["actions-container"]}>
        <DocKnowledgeMultiSelects
          handleRequestModelChange={handleRequestModelChange}
        />
        <DocKnowledgeButtons
          isJapaneseSelected={isJapanese}
          sortDetails={sorting}
          requestModel={request}
        />
      </div>
      <DocKnowledgeNavTabs
        isJapanese={isJapanese}
        setIsJapanese={setIsJapanese}
        sorting={sorting}
        setSorting={setSorting}
      />
    </div>
  );
};

export default DocumentKnowledge;
