import { ArrowContainer, Popover } from "react-tiny-popover";
import classes from "../../shared/model-search/ip-model-details-popover/IpModelDetailsPopover.module.scss";
import ValidationStatus from "../../enums/ValidationStatus";
import SpinnerLoaderSVG from "../../shared/spinner-loader-svg/SpinnerLoaderSvg";
const GpiListValidationStatusColumnPopOver = ({
  gpiId,
  validationStatus,
  isWindowOpen,
  onOpenWindow,
  onCloseWindow,
  onClickOutside,
  retryValidation,
}) => {
  const handleClicked = async (e, model) => {
    e.stopPropagation();

    isWindowOpen ? onCloseWindow(e) : onOpenWindow(e, gpiId);
  };

  return (
    <Popover
      isOpen={isWindowOpen}
      positions={["bottom"]}
      align="start"
      onClickOutside={onClickOutside}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"white"}
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className={classes["container"]}>
            {validationStatus == ValidationStatus.InProgress && (
              <div className={classes["message"]}>
                There is an ongoing validation in this GPI form.
              </div>
            )}
            {validationStatus == ValidationStatus.Failed && (
              <div className={classes["message"]}>
                An error has been encountered while running the validation of
                this form. &nbsp;
                <a
                  className={classes["link"]}
                  onClick={() => retryValidation(gpiId)}
                >
                  Click here to try again.
                </a>
              </div>
            )}
          </div>
        </ArrowContainer>
      )}
    >
      <div>
        <SpinnerLoaderSVG
          classType={
            validationStatus == ValidationStatus.Failed ? "warning" : "info"
          }
          clicked={handleClicked}
        />
      </div>
    </Popover>
  );
};

export default GpiListValidationStatusColumnPopOver;
