import { ProgressSpinner } from 'primereact/progressspinner'
import React from 'react'
import classes from "./ProjectDetailsLoader.module.scss"
const ProjectDetailsLoader = () => {
  return (
    <div className={`${classes["projectDetailsLoader__container"]}`}>
        <ProgressSpinner strokeWidth="5" animationDuration='1.0s' className={`${classes["spinner"]}`}/>
        <span>Loading projects...</span>
    </div>
  )
}

export default ProjectDetailsLoader