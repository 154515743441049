import { Route } from "react-router-dom";
import CustomerGpiProtectedRoute from "../guards/CustomerGpiProtectedRoute.";
import CustomerViewOtpAuthenticator from "../pages/customer-view/CustomerViewOtpAuthenticator";
import GeneralProductInformation from "../pages/general-product-information/GeneralProductInformation";

export default function CustomerView() {
  return (
    <>
      <Route
        exact
        path="/customer-view/otp"
        render={(props: any) => <CustomerViewOtpAuthenticator {...props} />}
      />

      <Route
        exact
        path="/general-product-information"
        render={(props: any) => (
          <CustomerGpiProtectedRoute>
            <GeneralProductInformation {...props} isCustomerView={true} />
          </CustomerGpiProtectedRoute>
        )}
      />
    </>
  );
}
