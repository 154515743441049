import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { useDispatch, useSelector } from "react-redux";
import {
  selectVoltageDetails,
  updateVoltageDetails,
  voltageDetailsInitialState,
  selectIsReadOnly,
  updateIsSaveDisabled,
} from "../../../../features/generalProductInformation/generalProductInformationSlice";
import Checkbox from "../../form/checkbox/Checkbox";
import FormControl from "../../form/form-control/FormControl";
import { voltageDetailsSchema } from "../product-details-schema";
import classes from "./VoltageDetails.module.scss";
import { useEffect } from "react";

const VoltageDetails = () => {
  const dispatch = useDispatch();
  const voltageDetailsValues = useSelector(selectVoltageDetails);
  const isReadOnly = useSelector(selectIsReadOnly);

  const voltageTypes = [
    {
      value: "AC",
      label: "AC Voltage",
    },
    {
      value: "DC",
      label: "DC Voltage",
    },
  ];

  const batteryTypes = [
    "Battery Packs and Chargers",
    "Portable Battery Packs",
    "Rechargeable Battery Cells",
    "Other",
  ];

  const batteryTypeOptions = batteryTypes.map((b) => ({ value: b, label: b }));

  const operationClasses = [
    "Commercial",
    "Heavy Industrial",
    "ISM - Industrial, Scientific, Medical",
    "ITE - Information Technology Equipment",
    "Laboratory",
    "Light Industrial",
    "Lighting",
    "Medical",
    "Residential - Class B",
    "Telecom",
    "Other",
  ];

  const operationClassOptions = operationClasses.map((c) => ({
    operationClass: c,
  }));

  const getValueAndSchema = (field) => {
    return {
      schema: voltageDetailsSchema.fields[field],
      value: voltageDetailsValues[field],
    };
  };

  const handleChange = (field, value) => {
    dispatch(
      updateVoltageDetails({
        ...voltageDetailsValues,
        [field]: value,
      })
    );
    if (!isReadOnly) {
      dispatch(updateIsSaveDisabled(false));
    }
  };

  const handleChangeVoltageType = (value) => {
    dispatch(
      updateVoltageDetails({
        ...voltageDetailsInitialState,
        voltageType: value,
      })
    );
    if (!isReadOnly) {
      dispatch(updateIsSaveDisabled(false));
    }
  };

  const selectedOperationClassesTemplate = (option) => {
    return option?.operationClass ? (
      <label>{option.operationClass}</label>
    ) : null;
  };

  const connectsToMainVoltageQuestionsTemplate = () => (
    <Checkbox
      disabled={isReadOnly}
      label="External power supply will be shipped in the box with the end product."
      helpText="Check if applicable. If you are not shipping an external power supply with the end product, do not check."
      helpTextOptions={{
        position: "bottom",
      }}
      checked={voltageDetailsValues.externalPowerSupplyWillBeShipped}
      onChange={(e) => {
        handleChange("externalPowerSupplyWillBeShipped", e.checked);
      }}
    />
  );

  const containsBatteryQuestionsTemplate = () => (
    <>
      <div className={classes["battery-type-field"]}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <label style={{ minWidth: "95px" }}>Battery Type</label>

          <Dropdown
            disabled={isReadOnly}
            className={classes["battery-type-dropdown"]}
            placeholder="-- Select --"
            options={batteryTypeOptions}
            value={voltageDetailsValues.batteryType}
            onChange={handleChangeBatteryType}
          />
        </div>

        {voltageDetailsValues.batteryType === "Other" && (
          <div className={classes["battery-type-other-container"]}>
            <FormControl
              {...getValueAndSchema("otherBatteryType")}
              controlClassName={classes["battery-type-other"]}
              control={
                <InputText
                  onChange={(e) =>
                    handleChange("otherBatteryType", e.target.value)
                  }
                />
              }
              helpText="Please specify “Other” Battery Type"
            />
          </div>
        )}
      </div>

      <Checkbox
        disabled={isReadOnly}
        label="User replaceable battery"
        checked={voltageDetailsValues.isBatteryUserReplaceable}
        onChange={(e) => {
          handleChange("isBatteryUserReplaceable", e.checked);
        }}
        helpText="Please specify if the battery is accessible to the user to replace."
        helpTextOptions={{
          position: "bottom",
        }}
      />

      <Checkbox
        disabled={isReadOnly}
        label="Battery will be shipped with the end product"
        checked={voltageDetailsValues.willBatteryBeShipped}
        onChange={(e) => {
          handleChange("willBatteryBeShipped", e.checked);
        }}
      />
    </>
  );

  const dcQuestionsTemplate = () => (
    <div className={classes["dc-questions-container"]}>
      <Checkbox
        disabled={isReadOnly}
        label="Product connects to mains voltage via an external power supply."
        checked={voltageDetailsValues.connectsToMainVoltage}
        onChange={handleChangeConnectsToMainVoltage}
      />

      {voltageDetailsValues.connectsToMainVoltage && (
        <div className={classes["sub-questions"]}>
          {connectsToMainVoltageQuestionsTemplate()}
        </div>
      )}

      <Checkbox
        disabled={isReadOnly}
        label="Product contains a Battery"
        checked={voltageDetailsValues.containsBattery}
        onChange={handleChangeContainsBattery}
      />

      {voltageDetailsValues.containsBattery && (
        <div className={classes["sub-questions"]}>
          {containsBatteryQuestionsTemplate()}
        </div>
      )}
    </div>
  );

  const handleChangeBatteryType = (e) => {
    dispatch(
      updateVoltageDetails({
        ...voltageDetailsValues,
        batteryType: e.value,
        otherBatteryType:
          e.value === "Other" ? voltageDetailsValues.otherBatteryType : "",
      })
    );
  };

  const handleChangeConnectsToMainVoltage = (e) => {
    dispatch(
      updateVoltageDetails({
        ...voltageDetailsValues,
        connectsToMainVoltage: e.checked,
        externalPowerSupplyWillBeShipped: e.checked
          ? voltageDetailsValues.externalPowerSupplyWillBeShipped
          : false,
      })
    );
  };

  const handleChangeContainsBattery = (e) => {
    if (e.checked) {
      dispatch(
        updateVoltageDetails({
          ...voltageDetailsValues,
          containsBattery: e.checked,
        })
      );
    } else {
      // Clear sub section.
      dispatch(
        updateVoltageDetails({
          ...voltageDetailsValues,
          containsBattery: e.checked,
          batteryType: "",
          otherBatteryType: "",
          willBatteryBeShipped: false,
          isBatteryUserReplaceable: false,
        })
      );
    }
  };

  const handleChangeOperationClass = (e) => {
    dispatch(
      updateVoltageDetails({
        ...voltageDetailsValues,
        operationClasses: e.value,
        otherOperationClass: e.value?.some((o) => o.operationClass === "Other")
          ? voltageDetailsValues.otherOperationClass
          : "",
      })
    );

    dispatch(updateIsSaveDisabled(false));
  };

  return (
    <div className={classes["container"]}>
      <div className="form-section">
        <div className="form-section-content">
          <FormControl
            label="Rating Voltage"
            control={
              <>
                <div className={classes["voltage-type-radio-buttons"]}>
                  {voltageTypes.map((v) => (
                    <div
                      key={v.value}
                      className={`radio-button-container`}
                      onChange={(e) => handleChangeVoltageType(v.value)}
                    >
                      <input
                        checked={v.value === voltageDetailsValues.voltageType}
                        readOnly
                        id={v.value}
                        type="radio"
                        name="voltage-type"
                        disabled={isReadOnly}
                      />
                      <label value={v.value} htmlFor={v.value}>
                        {v.label}
                      </label>
                    </div>
                  ))}
                </div>
              </>
            }
          />

          <FormControl
            {...getValueAndSchema("productRatings")}
            containerClassName={classes["product-ratings-field"]}
            control={
              <InputText
                onChange={(e) => handleChange("productRatings", e.target.value)}
                disabled={isReadOnly}
              />
            }
            helpText="Please specify product ratings  (this should be the same on the product label, manual and test reports). ex: 12V, 120-240 V ac, etc."
          />

          {voltageDetailsValues.voltageType === "DC" && dcQuestionsTemplate()}

          <div className={classes["rating-container"]}>
            {voltageDetailsValues.voltageType === "AC" && (
              <div>
                <FormControl
                  {...getValueAndSchema("ratedFrequency")}
                  label="Rated (Frequency)"
                  control={
                    <InputText
                      onChange={(e) =>
                        handleChange("ratedFrequency", e.target.value)
                      }
                      disabled={isReadOnly}
                    />
                  }
                  helpText="Please specify product frequency. ex: 50-60 Hz."
                />
              </div>
            )}

            <div>
              <FormControl
                {...getValueAndSchema("ratedCurrent")}
                label="Rated (Current)"
                control={
                  <InputText
                    onChange={(e) =>
                      handleChange("ratedCurrent", e.target.value)
                    }
                    disabled={isReadOnly}
                  />
                }
                controlClassName={`ratedCurrent`}
                helpText="Please specify product current. ex: 2 A, 1-6 A, etc."
              />
            </div>
          </div>

          <FormControl
            label="Operating Temperature Range"
            helpText="Please specify product normal operating temperature range. ex: -20 to + 60 etc."
            {...getValueAndSchema("operatingTemperatureRange")}
            control={
              <InputText
                onChange={(e) =>
                  handleChange("operatingTemperatureRange", e.target.value)
                }
                disabled={isReadOnly}
              />
            }
            controlClassName={`operatingTemperatureRange`}
          />

          <div className={classes["operation-class-container"]}>
            <div>
              <FormControl
                label="Operation Class"
                helpText="Please specify the operation class of the product."
                value={voltageDetailsValues.operationClasses}
                control={
                  <MultiSelect
                    showClear
                    tooltipOptions={{
                      position: "bottom",
                    }}
                    tooltip={voltageDetailsValues.operationClasses
                      ?.map((o) => o.operationClass)
                      ?.join(", ")}
                    placeholder="-- Select --"
                    selectedItemTemplate={selectedOperationClassesTemplate}
                    options={operationClassOptions}
                    optionLabel="operationClass"
                    onChange={handleChangeOperationClass}
                    disabled={isReadOnly}
                  />
                }
                controlClassName={`operationClass`}
              />
            </div>

            {voltageDetailsValues.operationClasses?.some(
              (o) => o.operationClass === "Other"
            ) && (
                <div className={classes["operation-class-other-container"]}>
                  <FormControl
                    {...getValueAndSchema("otherOperationClass")}
                    control={
                      <InputText
                        onChange={(e) =>
                          handleChange("otherOperationClass", e.target.value)
                        }
                        disabled={isReadOnly}
                      />
                    }
                    controlClassName={`otherOperationClass`}
                    helpText="Please specify “Other” Operation Class"
                  />
                </div>
              )}
          </div>

          <FormControl
            label="Harmonized Shipping (HS) Code"
            helpText="Please specify the Harmonized System Code associated with this product. (The Harmonized System is used to ease global trade by creating unified categories to classify different types of goods."
            {...getValueAndSchema("harmonizedShippingCode")}
            control={
              <InputText
                onChange={(e) =>
                  handleChange("harmonizedShippingCode", e.target.value)
                }
                disabled={isReadOnly}
              />
            }
            controlClassName={`harmonizedShippingCode`}
          />

          <div className={classes["product-classification-container"]}>
            <h4>Product Classification</h4>

            <Checkbox
              disabled={isReadOnly || voltageDetailsValues.voltageType === "DC"}
              label="Class I Cord Connected Device - 3-Prong Plug (Excluding Power Supplies)"
              checked={voltageDetailsValues.productClassificationClass1}
              onChange={(e) => {
                handleChange("productClassificationClass1", e.checked);
              }}
            />

            <Checkbox
              disabled={isReadOnly || voltageDetailsValues.voltageType === "DC"}
              label="Class II Cord Connected Device - 2-Prong Plug (Excluding Power Supplies)"
              checked={voltageDetailsValues.productClassificationClass2}
              onChange={(e) => {
                handleChange("productClassificationClass2", e.checked);
              }}
            />

            <Checkbox
              label="Class III - No Internal Power Supply. Laptops, etc., with Low Voltage DC Input. Products typically provided with external power supplies."
              checked={voltageDetailsValues.voltageType === "DC"}
              disabled={isReadOnly || voltageDetailsValues.voltageType === "AC"}
              onChange={(e) => {
                handleChange("productClassificationClass3", e.checked);
              }}
            />

            <div className={classes["product-classification-guidelines"]}>
              <p>
                This section collects detail about the type of product to help
                identify the tests and resources that will be required. Select
                all that apply.
              </p>

              <p>
                Class I Equipment has basic insulation and relies on an earth
                for protection and have an earth wire in the plug.
              </p>

              <p>
                Class II Equipment is double insulated and will not have an
                earth circuit and no earth wire in the plug.
              </p>

              <p>
                Class III Equipment is supplied from a separated/safety
                extra-low voltage (SELV) source. The voltage from a SELV supply
                is low enough that under normal conditions a person can safely
                come into contact with it without risk of shock.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoltageDetails;
