enum ProjectTaskStatus {
  NotScheduled = 0,
  NotStarted = 100,
  AwaitingAssignment = 150,
  InProgress = 200,
  OnHold = 300,
  Completed = 400,
  Cancelled = 500,
}
export default ProjectTaskStatus;
