import * as React from "react";
import { Redirect } from "react-router-dom";
import GpiAuthService from "../services/GpiAuthService";

export interface ICustomerGpiProtectedRouteProps {
  children: React.ReactNode;
}

export default function CustomerGpiProtectedRoute({
  children,
}: ICustomerGpiProtectedRouteProps) {
  const authInfo = GpiAuthService.getGpiAuthInfo();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (!params.id) return <>Invalid Link</>;

  if (!authInfo || authInfo.isExpired || authInfo.gpiId !== params.id) {
    return <Redirect to={"/customer-view/otp?id=" + params.id} />;
  }

  return <>{children}</>;
}
