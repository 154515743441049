export const findSortKey = (
  columnList: any[],
  field: string,
  defaultSort: string,
  columnIdKey: string = "field",
  columnOrderKey: string = "sortKey"
): string => {
  const column = columnList.find((column) => column[columnIdKey] == field);
  if (column) return column[columnOrderKey];
  return defaultSort;
};

export const getSortOrder = (
  sortOrder: number,
  defaultSortOrder: number = -1
): string => {
  if (sortOrder == 0) sortOrder = defaultSortOrder;
  return sortOrder == 1 ? "ASC" : "DESC";
};

export const updateColumnWidth = (
  setColumns: any,
  widthValue: string,
  columnName: string
) => {
  setColumns((prevColumnList: any) => {
    const newColumnList = JSON.parse(JSON.stringify(prevColumnList));
    newColumnList.map((column: any) => {
      if (column.id === columnName) {
        column.width = widthValue;
      }
      return column;
    });
    return newColumnList;
  });
};
