import { useEffect, useState } from "react";
import CloseIcon from "../close-icon/CloseIcon";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import AddNewCertificate from "../../pages/certificate-list-view/cetificate-list/add-new-certificate/AddNewCertificate";
import { useDispatch } from "react-redux";
import {
  updateCertificateNumber,
  updateLineDetails,
  updateModelList,
  updateOrderLineNumbers,
  updateProdDetailsModelList,
  updateSelectedOrderLineNumber,
  updateSelectedProjectDetails,
  updateCertificateScheme,
} from "../../features/createCertificate/createCertificateSlice";
import ProjectDetailsService from "../../services/ProjectDetailsService";
import "./CopyCertificateModal.scss";
import { updateCopyPartyInformation } from "../../features/createCertificate/createCertificateSlice";
import { flexProjectContactRole } from "../../utils/constants/flex-project-contact-role.constants";

const initialCopyState = {
  certificateScheme: false,
  certificateNumber: false,
  models: false,
  partyInformation: false,
};

const CopyCertificateModal = (props) => {
  const { isOpen, setIsOpen, details, setShowCertificateDetails } = props;
  const [displayAddNewCertModal, setDisplayAddNewCertModal] = useState(false);
  const [certificateSchemeToPass, setCertificateSchemeToPass] = useState(null);
  const [passedCurrentStep, setPassedCurrentStep] = useState(null);
  const [isCopyingDetails, setIsCopyingDetails] = useState(false);
  const [copyState, setCopyState] = useState(initialCopyState);
  const dispatch = useDispatch();

  const onCloseModal = () => {
    closeOnCopy();
    dispatch(updateCertificateNumber(null));
    dispatch(updateProdDetailsModelList(null));
    dispatch(updateModelList([]));
  };

  const closeOnCopy = () => {
    setIsOpen(false);
    setCopyState(initialCopyState);
    setCertificateSchemeToPass(null);
  };

  const onCopy = async () => {
    if (copyState.certificateScheme) {
      setCertificateSchemeToPass(details.certificateScheme);
      dispatch(updateCertificateScheme(details.certificateScheme));
    }

    if (copyState.certificateNumber) {
      dispatch(updateCertificateNumber(details.certificateNumber));
    }

    if (copyState.models) {
      if (details.assets && details.assets.length > 0) {
        dispatch(updateProdDetailsModelList(details.assets));
        dispatch(updateModelList(details.assets));
      }
    }

    dispatch(updateCopyPartyInformation(copyState.partyInformation));

    setIsCopyingDetails(true);
    await buildProjectDetails();
    setIsCopyingDetails(false);
    dialogFuncMap["displayAddNewCertModal"](true);
    closeOnCopy();
  };

  const isDisabled = () =>
    !(
      copyState.certificateScheme ||
      copyState.certificateNumber ||
      copyState.models ||
      copyState.partyInformation
    );

  const dialogFuncMap = {
    displayAddNewCertModal: setDisplayAddNewCertModal,
  };

  const buildProjectDetails = async () => {
    let projectNumber = details.localData?.project_number;
    let lineNumber = details.localData?.line_number;
    let updatedSelectedProjectDetails;

    if (!projectNumber) {
      projectNumber = details.projectNumber;
    }

    if (!projectNumber || projectNumber === "") return;

    const projectNumbers = await ProjectDetailsService.getProjectSuggestions(
      projectNumber
    );

    if (projectNumbers.length === 0) return;

    const item = projectNumbers[0];
    const data = await ProjectDetailsService.getProjectDetail(
      item.project_Number
    );

    let tempLineDetails = [];
    data.value.forEach((d) => {
      //Filter - Get Child Lines
      const lineDatas = d.projectServiceLines.filter(
        (l) => l.lineNumber.split(".").length === 3
      );

      if (lineDatas.length === 0) {
        return;
      }

      for (var i = 0; i < lineDatas.length; i++) {
        const projectContacts = getProjectContact(d.projectContacts);

        lineDatas[i].partySiteNumber = projectContacts.partySiteNumber;
        lineDatas[i].companyId = d.companyId;
        lineDatas[i].address = projectContacts.address;
        lineDatas[i].city = projectContacts.city;
        lineDatas[i].state = projectContacts.state;
        lineDatas[i].province = projectContacts.province;
        lineDatas[i].country = projectContacts.country;
        lineDatas[i].postalcode = projectContacts.postalCode;
        lineDatas[i].partysite = projectContacts.partySiteNumber;
        lineDatas[i].serviceLine = item.service_Line;
        lineDatas[i].customer = d.companyName;
        lineDatas[i].poc = projectContacts.country;
        lineDatas[i].handlerName = d.projectHandler;
        lineDatas[i].fulfillmentLocation = item.preferred_Fulfillment_Location;
        lineDatas[i].projectFlexId = d.projectId;
        lineDatas[i].projectNumber = d.projectNumber;
        lineDatas[i].orderNumber = d.orderNumber;
        lineDatas[i].quoteNo = d.quoteNo;
        lineDatas[i].projectName = d.name;
      }

      tempLineDetails = tempLineDetails.concat(lineDatas);
    });
    tempLineDetails = tempLineDetails.filter((l) => l != null);

    const lineNumbers = tempLineDetails.map((d) => d.lineNumber);
    const sortedLineNumbers = lineNumbers
      .map((a) =>
        a
          .split(".")
          .map((n) => +n + 100000)
          .join(".")
      )
      .sort()
      .map((a) =>
        a
          .split(".")
          .map((n) => +n - 100000)
          .join(".")
      );

    const mappedLines = sortedLineNumbers.map((d) => {
      return {
        name:
          d +
          " - " +
          tempLineDetails.find((l) => l.lineNumber === d)?.projectName,
        value: d,
      };
    });

    const selectedProjectDetails = tempLineDetails.find(
      (t) => t.lineNumber === lineNumber
    );

    if (selectedProjectDetails) {
      updatedSelectedProjectDetails = await getUserDetails(
        selectedProjectDetails
      );
    } else {
      updatedSelectedProjectDetails = await getUserDetails(tempLineDetails[0]);
      lineNumber = tempLineDetails[0].lineNumber;
    }

    dispatch(updateLineDetails(tempLineDetails));
    dispatch(updateOrderLineNumbers(mappedLines));
    dispatch(updateSelectedProjectDetails(updatedSelectedProjectDetails));
    dispatch(updateSelectedOrderLineNumber(lineNumber));
  };

  const handleChange = (e) => {
    const { target, checked } = e;

    setCopyState((prevState) => ({
      ...prevState,
      [target.id]: checked,
    }));
  };

  const getUserDetails = async (selectedProjectDetails) => {
    if (!selectedProjectDetails || !selectedProjectDetails.handlerName)
      return selectedProjectDetails;

    const response = await ProjectDetailsService.getUserDetails(
      selectedProjectDetails.handlerName
    );
    if (!response) return;

    const handlerCountry = response.split("-")[0];
    const handlerLocation = response.split("-")[1];

    selectedProjectDetails.handlerCountry = handlerCountry;
    selectedProjectDetails.handlerLocation = handlerLocation;
    return selectedProjectDetails;
  };

  const getProjectContact = (projectContacts) => {
    const soldTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.SoldTo
    );
    if (soldTo?.partySiteNumber) return soldTo;

    const shipTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.ShipTo
    );
    if (shipTo?.partySiteNumber) return shipTo;

    const customer = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.Customer
    );
    if (customer?.partySiteNumber) return customer;

    const billTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.BillTo
    );
    if (billTo?.partySiteNumber) return billTo;
  };

  const hidePrefereredWayStep =
    process.env
      .REACT_APP_FEATUREFLAG_ADD_CERTFICATE_REMOVE_PREFERRED_WAY_STEP ===
    "true";

  useEffect(() => {
    if (
      copyState.certificateScheme ||
      copyState.models ||
      copyState.partyInformation
    ) {
      setPassedCurrentStep(hidePrefereredWayStep ? 3 : 2);

      return;
    }

    if (
      !copyState.certificateScheme &&
      !copyState.models &&
      !copyState.partyInformation &&
      copyState.certificateNumber
    ) {
      setPassedCurrentStep(hidePrefereredWayStep ? 1 : 2);
      return;
    }
  }, [copyState]);

  const renderFooter = () => {
    return (
      <>
        <hr className="modal-footer-hr" />
        <div className="footer-center-button">
          <button
            className="ul-button -app-primary -medium default-save-button"
            disabled={isDisabled() || isCopyingDetails}
            onClick={onCopy}
          >
            <span>OK</span>
          </button>
          <button
            className="ul-button -medium default-cancel-button"
            onClick={onCloseModal}
            disabled={isCopyingDetails}
          >
            <span>CANCEL</span>
          </button>
        </div>
      </>
    );
  };

  return (
    <div>
      <AddNewCertificate
        editData={details?.editData}
        dialogFuncMap={dialogFuncMap}
        displayAddNewCertModal={displayAddNewCertModal}
        certificateScheme={certificateSchemeToPass}
        passedCurrentStep={passedCurrentStep}
        setPassedCurrentStep={setPassedCurrentStep}
        setShowCertificateDetails={setShowCertificateDetails}
      />
      <Dialog
        header="Copy Existing Certificate"
        visible={isOpen}
        style={{ width: "30vw" }}
        footer={renderFooter()}
        icons={<CloseIcon onCloseEvent={onCloseModal} />}
        closable={false}
        className="copy-certificate-modal"
      >
        <hr className="modal-header-hr" />
        {isCopyingDetails && (
          <div className="loading-container">
            <div>Copying certificate details......</div>
            <div>
              <svg className="ul-progressCircle -medium">
                <circle
                  className="ul-progressCircle__outer"
                  cx="50%"
                  cy="50%"
                  r="35%"
                />
                <circle
                  className="ul-progressCircle__inner"
                  cx="50%"
                  cy="50%"
                  r="35%"
                />
              </svg>
            </div>
          </div>
        )}
        {!isCopyingDetails && (
          <>
            <div className="pt-10">Select the data you want to copy over:</div>
            <div className="pt-10">
              <Checkbox
                id="certificateScheme"
                checked={copyState.certificateScheme}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="certificateScheme" className="pl-5">
                Certification Scheme
              </label>
            </div>
            <div className="pt-10">
              <Checkbox
                id="certificateNumber"
                checked={copyState.certificateNumber}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="certificateNumber" className="pl-5">
                Certificate Number
              </label>
            </div>
            <div className="pt-10">
              <Checkbox
                id="models"
                checked={copyState.models}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="models" className="pl-5">
                Model Name(s)
              </label>
            </div>

            <div className="pt-10">
              <Checkbox
                id="partyInformation"
                checked={copyState.partyInformation}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="partyInformation" className="pl-5">
                Party Information
              </label>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default CopyCertificateModal;
