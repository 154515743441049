import axios from "axios";
import { debounce } from "lodash";
import { React, useEffect, useState } from "react";
import CertificateListService from "../../../services/CertificateListService";
import certificateRefinerColumns from "../../../shared/refiners/data/certificate-refiner-columns";
import DatePickerRefiner from "../../../shared/refiners/date-picker-refiner/DatePickerRefiner";
import SelectRefiner from "../../../shared/refiners/select-refiner/SelectRefiner";
import classes from "./CertificateListRefiners.module.scss";
const CertificateListRefiners = (props) => {
  const [certNumberOptions, setCertNumberOptions] = useState([]);
  const [certSchemeOptions, setCertSchemeOptions] = useState([]);
  const [certStatusOptions, setCertStatusOptions] = useState([]);
  const [issuingBodyOptions, setIssuingBodyOptions] = useState([]);
  const [createdOnDateOptions, setCreatedOnDateOptions] = useState([]);
  const [expirationDateOptions, setExpirationDateOptions] = useState([]);
  const [issueDateOptions, setIssueDateOptions] = useState([]);
  const [markOptions, setMarkOptions] = useState([]);
  const [partySiteNumberOptions, setPartySiteNumberOptions] = useState([]);
  const [ccnOptions, setCcnOptions] = useState([]);
  const [updatedOnDateOptions, setUpdatedOnDateOptions] = useState([]);
  const [withdrawnDateOptions, setWithdrawnDateOptions] = useState([]);
  const [isRefinerLoading, setIsRefinerLoading] = useState(false);

  let cancellationTokens = {
    getIpCertificateRefiners: null,
  };

  const {
    refiners,
    addSelectedToYourFilters,
    replaceFilterBySelected,
    onChangeDatePickerRefiner,
    onClickRemoveRefiner,
    statusListRefiners,
    clearSelectedValues,
  } = props;

  useEffect(() => {
    CertificateListService.getIpCertificateRefiners(
      certificateRefinerColumns[0].Id,
      ""
    ).then((result) => {
      setCertNumberOptions(result);
    });

    CertificateListService.getIpCertificateRefiners(
      certificateRefinerColumns[2].Id,
      ""
    ).then((result) => {
      setCertStatusOptions(result);
    });

    CertificateListService.getIpCertificateRefiners(
      certificateRefinerColumns[9].Id,
      ""
    ).then((result) => {
      setCcnOptions(result);
    });
  }, []);

  const debouncedGetIpCertificateRefiners = debounce(
    async (refinerKey, filter, cancellationTokens, setData) => {
      setIsRefinerLoading(true);
      const result = await CertificateListService.getIpCertificateRefiners(
        refinerKey,
        filter,
        { cancelToken: cancellationTokens.getIpCertificateRefiners.token }
      );
      if (result && result.length > 0) {
        let finalResult = [];
        result.forEach((item) => {
          const existingObj = refiners.find(
            (i) => i.Value === item.name && i.Id === refinerKey
          );
          if (!existingObj) {
            finalResult.push(item);
          }
        });
        setData(finalResult);
      }
      setIsRefinerLoading(false);
    },
    200
  );

  const onChangeSelectedFilterText = async (event, refinerKey, setData) => {
    if (cancellationTokens.getIpCertificateRefiners) {
      cancellationTokens.getIpCertificateRefiners.cancel("op cancelled");
    }
    cancellationTokens.getIpCertificateRefiners = axios.CancelToken.source();
    debouncedGetIpCertificateRefiners(
      refinerKey,
      event.filter,
      cancellationTokens,
      setData
    );
  };

  return (
    <div className={classes["cert-refiners-section"]}>
      <div className="ul-card -app">
        <div
          className={`ul-card__item -bordered ul-card__head font-size-12 ${classes["select-a-filter-text"]}`}
        >
          Select a filter below to refine your search
        </div>
      </div>

      <div className={classes["cert-refiners-accordion"]}>
        <SelectRefiner
          refinerLabel={certificateRefinerColumns[0].Value}
          options={certNumberOptions}
          addSelectedToYourFilters={(v) => {
            replaceFilterBySelected(v, certificateRefinerColumns[0].Id);
          }}
          isFilterOn={true}
          isMultiSelect={false}
          onFilter={(e) =>
            onChangeSelectedFilterText(
              e,
              certificateRefinerColumns[0].Id,
              setCertNumberOptions
            )
          }
          isRefinerLoading={isRefinerLoading}
        />

        <SelectRefiner
          refinerLabel={certificateRefinerColumns[1].Value}
          options={certSchemeOptions}
          addSelectedToYourFilters={(v) => {
            addSelectedToYourFilters(v, certificateRefinerColumns[1].Id);
          }}
          isFilterOn={true}
          isMultiSelect={true}
          onFilter={(e) =>
            onChangeSelectedFilterText(
              e,
              certificateRefinerColumns[1].Id,
              setCertSchemeOptions
            )
          }
          isRefinerLoading={isRefinerLoading}
        />

        <SelectRefiner
          refinerLabel={certificateRefinerColumns[2].Value}
          options={certStatusOptions}
          addSelectedToYourFilters={(v) => {
            addSelectedToYourFilters(v, certificateRefinerColumns[2].Id);
          }}
          placeHolder="-- Click to select --"
          onFilter={(e) => { }}
          isMultiSelect={true}
          isRefinerLoading={isRefinerLoading}
          statusListRefiners={statusListRefiners}
          clearSelectedValues={clearSelectedValues}
        />

        <SelectRefiner
          refinerLabel={certificateRefinerColumns[3].Value}
          options={issuingBodyOptions}
          addSelectedToYourFilters={(v) => {
            addSelectedToYourFilters(v, certificateRefinerColumns[3].Id);
          }}
          isFilterOn={true}
          isMultiSelect={true}
          onFilter={(e) =>
            onChangeSelectedFilterText(
              e,
              certificateRefinerColumns[3].Id,
              setIssuingBodyOptions
            )
          }
          isRefinerLoading={isRefinerLoading}
        />

        <DatePickerRefiner
          refiners={refiners}
          refinerId={certificateRefinerColumns[4].Id}
          onClickRemoveRefiner={onClickRemoveRefiner}
          refinerLabel={certificateRefinerColumns[4].Value}
          options={createdOnDateOptions}
          onChangeDatePickerRefiner={(v) => {
            onChangeDatePickerRefiner(v, certificateRefinerColumns[4].Id);
          }}
        />

        <DatePickerRefiner
          refiners={refiners}
          refinerId={certificateRefinerColumns[5].Id}
          refinerLabel={certificateRefinerColumns[5].Value}
          options={expirationDateOptions}
          onChangeDatePickerRefiner={(v) => {
            onChangeDatePickerRefiner(v, certificateRefinerColumns[5].Id);
          }}
        />

        <DatePickerRefiner
          refiners={refiners}
          onClickRemoveRefiner={onClickRemoveRefiner}
          refinerId={certificateRefinerColumns[6].Id}
          refinerLabel={certificateRefinerColumns[6].Value}
          options={issueDateOptions}
          onChangeDatePickerRefiner={(v) => {
            onChangeDatePickerRefiner(v, certificateRefinerColumns[6].Id);
          }}
        />

        <SelectRefiner
          refinerLabel={certificateRefinerColumns[7].Value}
          options={markOptions}
          addSelectedToYourFilters={(v) => {
            addSelectedToYourFilters(v, certificateRefinerColumns[7].Id);
          }}
          isFilterOn={true}
          isMultiSelect={true}
          onFilter={(e) =>
            onChangeSelectedFilterText(
              e,
              certificateRefinerColumns[7].Id,
              setMarkOptions
            )
          }
          isRefinerLoading={isRefinerLoading}
        />

        <SelectRefiner
          refinerLabel={certificateRefinerColumns[8].Value}
          options={partySiteNumberOptions}
          addSelectedToYourFilters={(v) => {
            addSelectedToYourFilters(v, certificateRefinerColumns[8].Id);
          }}
          isFilterOn={true}
          isMultiSelect={true}
          onFilter={(e) => {
            setPartySiteNumberOptions([]);

            onChangeSelectedFilterText(
              e,
              certificateRefinerColumns[8].Id,
              setPartySiteNumberOptions
            );
          }}
          showMore={true}
          isRefinerLoading={isRefinerLoading}
        />

        <SelectRefiner
          refinerLabel={certificateRefinerColumns[9].Value}
          options={ccnOptions}
          addSelectedToYourFilters={(v) => {
            addSelectedToYourFilters(v, certificateRefinerColumns[9].Id);
          }}
          placeHolder="-- Click to select --"
          onFilter={(e) => { }}
          isMultiSelect={true}
          isRefinerLoading={isRefinerLoading}
        />

        <DatePickerRefiner
          refiners={refiners}
          onClickRemoveRefiner={onClickRemoveRefiner}
          refinerId={certificateRefinerColumns[10].Id}
          refinerLabel={certificateRefinerColumns[10].Value}
          options={updatedOnDateOptions}
          onChangeDatePickerRefiner={(v) => {
            onChangeDatePickerRefiner(v, certificateRefinerColumns[10].Id);
          }}
        />

        <DatePickerRefiner
          refiners={refiners}
          onClickRemoveRefiner={onClickRemoveRefiner}
          refinerId={certificateRefinerColumns[11].Id}
          refinerLabel={certificateRefinerColumns[11].Value}
          options={withdrawnDateOptions}
          onChangeDatePickerRefiner={(v) => {
            onChangeDatePickerRefiner(v, certificateRefinerColumns[11].Id);
          }}
        />
      </div>
    </div>
  );
};

export default CertificateListRefiners;
