import { ColumnBodyOptions } from "primereact/column";
import PmrE2EProjectDetailsOrderlineModel from "../../shared/interfaces/pmr-e2e-project-details-orderline-model";
import PmrE2EProjectModel from "../../shared/interfaces/pmr-e2e-project-model";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { Button } from "primereact/button";
import classes from "./InvoicePriceTemplate.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faMoneyCheckAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";
import { Field, FieldProps, Form, FormikProvider, useFormik } from "formik";
import PmrMultiSelectCurrencies from "../../shared/components/pmr-multiselect-currencies/PmrMultiSelectCurrencies";
import CurrencyService from "../../../../services/CurrencyService";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrencies } from "../../../../features/projectManagementPmr/projectManagementPmrSlice";
import * as yup from "yup";
import numeral from "numeral";
import { updateOrderLineInvoice } from "../../../../features/projectManagement/projectManagementSlice";
import UpdateProjectlineCurrencyModel from "../../../../shared/models/currency-models/UpdateProjectlineCurrencyModel";
import useToastr from "../../../../hooks/useToastr";

interface InvoicePriceTemplateProps {
  rowData: PmrE2EProjectDetailsOrderlineModel;
  project: PmrE2EProjectModel;
  col: ColumnBodyOptions;
}

export interface InvoicePriceTemplateInput {
  invoicePriceAmount?: number | null;
  invoicePriceCurrency?: string | null;
}

export const invoicePriceValidationSchema: yup.SchemaOf<InvoicePriceTemplateInput> =
  yup.object().shape({
    invoicePriceAmount: yup.number().min(1).required(),
    invoicePriceCurrency: yup.string().required(),
  });

export const ulPayment = "UL: Payment";

export const defaultInvoicePriceAmountProps: InputNumberProps = {
  maxFractionDigits: 2,
  minFractionDigits: 2,
  allowEmpty: true,
  autoFocus: true,
  placeholder: "0.00",
  mode: "decimal",
  locale: "en-US",
};

export default function InvoicePriceTemplate({
  rowData,
  project,
  col,
}: InvoicePriceTemplateProps) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currencies = useSelector(selectCurrencies);
  const dispatch = useDispatch();
  const { showSuccess } = useToastr();

  const buttonDefaultClasses = "p-button-rounded p-button-text p-button-sm";

  const successMessage = () => (
    <>
      Invoice Price for Order Line ‘<b>{rowData.orderLineNumber}</b>’ has been
      successfully updated.
    </>
  );
  const onSubmit = async (values: InvoicePriceTemplateInput) => {
    if (!formik.isValid) return;
    setIsSubmitting(true);
    try {
      if (!selectedCurrency) {
        console.warn("Currency not found");
        return;
      }

      const request: UpdateProjectlineCurrencyModel = {
        e2eProjectLineId: rowData.id,
        invoicePriceAmount: values.invoicePriceAmount ?? null,
        invoiceCurrencyCode: selectedCurrency?.code,
        invoiceCurrencyId: selectedCurrency.id,
      };
      await CurrencyService.updateProjectlineCurrency([request]);
      dispatch(updateOrderLineInvoice([request]));
      setIsEditMode(false);
      showSuccess("Invoice Price Updated", "", successMessage);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const defaultCurrency = currencies.find(
    (item) => item.code === rowData.currency
  );
  const formik = useFormik<InvoicePriceTemplateInput>({
    initialValues: {
      invoicePriceAmount: rowData.invoicePriceAmount,
      invoicePriceCurrency: rowData.invoiceCurrencyId ?? defaultCurrency?.id,
    },
    onSubmit,
    validationSchema: invoicePriceValidationSchema,
    enableReinitialize: true,
  });

  const selectedCurrency = currencies.find(
    (item) => item.id === formik.values?.invoicePriceCurrency
  );

  const handleEdit = () => {
    formik.resetForm();
    setIsEditMode(true);
  };

  useEffect(() => {
    formik.validateForm();
  }, []);

  if (
    !project.e2EProjectLineMilestoneGroupItems.some(
      (milestone) => milestone.name === ulPayment
    )
  )
    return <>N/A</>;

  if (!isEditMode) {
    const display = `${numeral(formik.values.invoicePriceAmount).format(
      "0,0.00"
    )} ${selectedCurrency?.code}`;
    const isValid =
      formik.isValid &&
      formik.values.invoicePriceAmount &&
      formik.values.invoicePriceCurrency;
    return (
      <div
        key={display}
        className={clsx("cursor-pointer", classes["invoice-button"])}
        onClick={handleEdit}
        title="Click to enter Invoice Amount and set Invoice Currency"
      >
        {!isValid && <FontAwesomeIcon icon={faMoneyCheckAlt} />}
        {isValid && display}
      </div>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Form className={clsx(classes["invoice-form"], "invoice-price-template")}>
        <Field name="invoicePriceAmount">
          {({ field, meta, form }: FieldProps) => (
            <InputNumber
              {...field}
              {...defaultInvoicePriceAmountProps}
              onChange={(event) =>
                form.getFieldHelpers(field.name).setValue(event.value)
              }
              style={{ width: "120px" }}
              className={clsx(meta.error && "p-invalid")}
              disabled={isSubmitting}
            />
          )}
        </Field>

        <Field name="invoicePriceCurrency">
          {({ field, meta }: FieldProps) => (
            <PmrMultiSelectCurrencies
              {...field}
              style={{
                width: "180px",
                height: "32px",
              }}
              className={clsx(meta.error && "p-invalid")}
              disabled={isSubmitting}
            />
          )}
        </Field>

        <div className={classes["button-group"]}>
          <Button
            disabled={!formik.isValid}
            type="submit"
            icon={<FontAwesomeIcon icon={faCheck} />}
            className={clsx("p-button-success", buttonDefaultClasses)}
            loading={isSubmitting}
          />
          {!isSubmitting && (
            <Button
              type="button"
              icon={<FontAwesomeIcon icon={faTimes} />}
              className={clsx("p-button-danger", buttonDefaultClasses)}
              onClick={() => {
                formik.resetForm();
                setIsEditMode(false);
              }}
            />
          )}
        </div>
      </Form>
    </FormikProvider>
  );
}
