export enum CertificateDocumentStatus {
  NotYetUploaded = -1,
  UploadToEcmFailed = 0,
  UploadToEcmInProgress = 1,
  LinkedToWbAndEcmTic = 2,
  SavedInEcmTic = 3,
  ReadyToLinkToWbAndEcmTic = 4,
  UploadToBlobFailed = 5,
  RetryUploadToEcmTicInProgress = 6,
  TestReportDuplicate = 7,
  TestReportDuplicateLinkToEcmInProgress = 8,
  TestReportDuplicateLinkToEcmFailed = 9,
}
