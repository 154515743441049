import React from "react";
import { useSelector } from "react-redux";
import { values } from "./../../../../features/dkList/dkListSlice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DataTableSortProps } from "../../DocumentKnowledge";
interface TestSampleListProps {
  isJapanese: boolean;
  sorting: DataTableSortProps;
  setSorting: React.Dispatch<React.SetStateAction<DataTableSortProps>>;
  isCollapsed: boolean;
  collapsedTemplate: (rowData: any, field: any) => {};
}

const TestSampleList = (props: TestSampleListProps) => {
  const value = useSelector(values);

  const fieldsEnglish = {
    certificationScheme: "Certification Scheme",
    segment: "Segment",
    agents: "Agents",
    isEssential: "Is Essential",
    applicationType: "Application Type",
    technology: "Technology",
    testSampleEn: "Test Sample",
    noOfUnitsRequired: "No. of Units Required",
    remarksEn: "Test Sample Remarks English",
    internalRemarks: "Internal Remarks",
  };

  const fieldsJapanese = {
    certificationScheme: "Certification Scheme",
    segment: "Segment",
    agents: "Agents",
    isEssential: "Is Essential",
    applicationType: "Application Type",
    technology: "Technology",
    testSampleJpn: "Test Sample (Japanese)*",
    noOfUnitsRequired: "No. of Units Required",
    remarksJpn: "Test Sample Remarks Japanese*",
    internalRemarks: "Internal Remarks",
  };

  const onSortChange = (sortProps: any) => {
    props.setSorting((current: any) => ({
      ...current,
      testSample: {
        column: sortProps.sortField,
        sort: sortProps.sortOrder,
      },
    }));
  };

  return (
    <DataTable
      key={"testSampleListGrid"}
      loading={value.isLoadingTestSampleData}
      className={`ul-table`}
      value={value.testSamples}
      resizableColumns
      sortMode="single"
      removableSort
      responsiveLayout="scroll"
      sortField={props.sorting.testSample.column}
      sortOrder={props.sorting.testSample.sort}
      onSort={onSortChange}
      showGridlines
      columnResizeMode="expand"
    >
      <Column field="id" header="Id" hidden={true}></Column>
      {Object.entries(props.isJapanese ? fieldsJapanese : fieldsEnglish).map(
        (col) => {
          return (
            <Column
              key={`testSampleListGrid-${col[0]}`}
              field={col[0]}
              header={col[1]}
              sortable
              body={
                props.isCollapsed
                  ? (e) => props.collapsedTemplate(e, col[0])
                  : null
              }
            ></Column>
          );
        }
      )}
    </DataTable>
  );
};

export default TestSampleList;
