import { uniqWith } from "lodash";
import PmrE2EProjectHandler from "../../../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-handlers";
import { E2EUserPaginatedModel } from "../../../shared/models/E2EUserPaginatedModel";

const ContributorsHelper = () => {
  const distinctContributorList = (list: PmrE2EProjectHandler[]) => {
    return uniqWith(
      list,
      (comparable: PmrE2EProjectHandler, comparator: PmrE2EProjectHandler) => {
        return (
          comparable.displayName === comparator.displayName &&
          comparable.handlerType === comparator.handlerType
        );
      }
    );
  };

  const updateCollaborator = (
    collaborator: PmrE2EProjectHandler,
    handler: E2EUserPaginatedModel
  ) => {
    collaborator.displayName = handler.displayName;
    collaborator.userEmail = handler.normalizedEmail;
    collaborator.assignOrder += 1;
  };

  return {
    distinctContributorList,
    updateCollaborator,
  };
};

export default ContributorsHelper;
