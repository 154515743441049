import RequestFactory from "./RequestFactory";

const ecmIntegrationUrl = process.env.REACT_APP_GMAE2E_URL;

const EcmIntegrationService = {
  createEcmFolder: async (model: any) =>
    await RequestFactory.post(
      ecmIntegrationUrl,
      "/api/ecm-integration/create-ecm-folder",
      model
    ),
  downloadEcmFile: async (nodeId: string) =>
    await RequestFactory.downloadGet(
      ecmIntegrationUrl,
      `/api/ecm-integration/download/${nodeId}`
    ),
};

export default EcmIntegrationService;
