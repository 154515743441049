import {
  faAnglesLeft,
  faAnglesRight,
  faCheck,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Badge } from "primereact/badge";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Timeline } from "primereact/timeline";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { CertificateDocumentStatus } from "../../../../enums/CertificateDocumentStatus";
import { CertificateDocumentType } from "../../../../enums/CertificateDocumentType";
import { reloadCertificateList } from "../../../../features/certificateList/certificateListSlice";
import {
  selectAutotimestampTask,
  selectCertDetailFormValues,
  selectCertDetailValues,
  selectHasCertificateProductStandard,
  selectHasEmptyProductStandardRow,
  selectModelsProductStandardsUpdates,
  selectProdDetailValues,
  selectStandardValue,
  selectValues,
  updateAutoTimestampTask,
  updateCertDetailsFormValues,
  updateCertificateNumber,
  updateCertificateScheme,
  updateCopyPartyInformation,
  updateHasCertificateProductStandard,
  updateHasEmptyProductStandardRow,
  updateHasExistingModels,
  updateLineDetails,
  updateModelList,
  updateOrderLineNumbers,
  updateProdDetailsAttachments,
  updateProdDetailsModelList,
  updateProductCategoryStandards,
  updateSelectedOrderLineNumber,
  updateSelectedProjectDetails,
  updateStandardOptions,
  updateStandardValues,
} from "../../../../features/createCertificate/createCertificateSlice";
import useToastr from "../../../../hooks/useToastr";
import CertificateListService from "../../../../services/CertificateListService";
import FeatureFlagService from "../../../../services/FeatureFlagService";
import ProjectDetailsService from "../../../../services/ProjectDetailsService";
import { appInsights } from "../../../../services/appInsights";
import CloseIcon from "../../../../shared/close-icon/CloseIcon";
import { formatDateToISO } from "../../../../utils/helpers/date.helpers";
import { parseToString } from "../../../../utils/helpers/string-format.helper";
import "./AddNewCertificate.scss";
import AddNewCertDetails from "./add-new-cert-details/AddNewCertDetails";
import AddNewCertProdDetails from "./add-new-cert-prod-details/AddNewCertProdDetails";
import CreateOptions from "./create-options/CreateOptions";
import ImportSpreadSheet from "./import-spreadsheet/ImportSpreadSheet";
import ProjectOrderDetails from "./project-order-details/ProjectOrderDetails";

import clsx from "clsx";
import ExpandIcon from "../../../../shared/expand-icon/ExpandIcon";
import {
  isObjectInvalid,
  objectToFormData,
} from "../../../../utils/helpers/object.helpers";
import classes from "./AddNewCertificate.module.scss";
import TestReportMatchedFoundPopup from "./test-report-matched-found-popup/TestReportMatchedFoundPopup";
import { useTestReportValidator } from "./test-report-matched-found-popup/useTestReportValidator";

const AddNewCertificate = (props) => {
  const logPrefix = `AddNewCertificate - ${uuidv4()}`;

  const {
    displayAddNewCertModal,
    dialogFuncMap,
    isEditCert,
    isModifyCert,
    setIsModifyCert,
    hierarchyId,
    editData,
    localData,
    hasRevisions,
    certificateId,
    certificateScheme,
    revisionNumber,
    setShowCertificateDetails,
    passedCurrentStep,
    setPassedCurrentStep,
    setIsOpenAutotimestampModal,
    customModalHeader,
    certificateStatus,
    inputAttachments,
    setAttachments,
  } = props;

  const dispatch = useDispatch();
  const projectDetailsValues = useSelector(selectValues);
  const productDetailsValues = useSelector(selectProdDetailValues);
  const certificateDetailsValues = useSelector(selectCertDetailValues);
  const certificateDetailFormValues = useSelector(selectCertDetailFormValues);
  const modelsProductStandardsUpdatesValues = useSelector(
    selectModelsProductStandardsUpdates
  );
  const hasEmptyProductStandardRow = useSelector(
    selectHasEmptyProductStandardRow
  );
  const standardValues = useSelector(selectStandardValue);

  const hasCertificateProductStandard = useSelector(
    selectHasCertificateProductStandard
  );

  const isEmptyStandard =
    hasEmptyProductStandardRow &&
    (isEditCert || isModifyCert) &&
    hasCertificateProductStandard;

  const tasks = useSelector(selectAutotimestampTask);
  const [formValues, setFormValues] = useState(editData ?? {});

  const steppedBackgroundColor = "#0088cc";
  const steppedTextColor = "#fff";
  const unSteppedBackgroundColor = "#d7dbe4";
  const unSteppedTextColor = "#d7dbe4";
  const invalidStepBackgroundColor = "#ffa700";
  const invalidStepTextColor = "#fff";
  const initialStepsWhenAdding = [{ number: 1 }, { number: 2 }];
  const initialStepsWhenEditing = [{ number: 1 }, { number: 2 }, { number: 3 }];
  const hidePrefereredWayStep =
    process.env
      .REACT_APP_FEATUREFLAG_ADD_CERTFICATE_REMOVE_PREFERRED_WAY_STEP ===
    "true";
  const [modalSteps, setModalSteps] = useState(
    hidePrefereredWayStep
      ? [{ number: 1 }, { number: 2 }, { number: 3 }]
      : isEditCert || isModifyCert
      ? initialStepsWhenEditing
      : initialStepsWhenAdding
  );

  const [isCancelConfirmDialogShow, setIsCancelConfirmDialogShow] =
    useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [schemeList, setSchemeList] = useState([]);
  const [isValidStep, setIsValidStep] = useState({
    2: false,
    3: false,
  });
  const [isDisableContinue, setIsDisableContinue] = useState(true);
  const [isDisableSaveAndAttach, setIsDisableSaveAndAttach] = useState(true);
  const [isSavingCertificate, setIsSavingCertificate] = useState(false);
  const [
    initialProductDetailsValuesLocal,
    setInitialProductDetailsValuesLocal,
  ] = useState(null);
  const [
    initialCertificateDetailFormValuesLocal,
    setInitialCertificateDetailFormValuesLocal,
  ] = useState(null);
  const [initialAttachmentValuesLocal, setInitialAttachmentValuesLocal] =
    useState(null);
  const [outputAttachmentValues, setOutputAttachmentValues] = useState(null);

  const [testReportRows, setTestReportRows] = useState(null);

  const testReportsValidatorHook = useTestReportValidator();
  const { showSuccess, showError, showWarning } = useToastr();

  const [footerActionsDisabled, setFooterActionsDisabled] = useState(false);
  const [formAttributesWithError, setFormAttributesWithError] = useState(null);
  const [formValidityMessages, setFormValidityMessages] = useState({});
  const [scrollIntoBlankRequiredElement, setScrollIntoBlankRequiredElement] =
    useState(null);

  const [isModelInRowEditMode, setIsModelInRowEditMode] = useState(false);
  const [isActivateConfirmDialogShow, setIsActivateConfirmDialogShow] =
    useState(false);

  const [hasCertificateBeenActive, setHasCertificateBeenActive] =
    useState(false);

  const [dialogMaximized, setDialogMaximized] = useState(true);

  const [isCertificateHaveStandard, setIsCertificateHaveStandard] =
    useState(null);

  const modalHeader = hidePrefereredWayStep
    ? {
        1: "Add New Certificate - Project Details",
        2: "Add New Certificate - Certificate Details",
        3: "Add New Certificate - Product Details",
      }
    : {
        1: "Add New Certificate",
        2: "Add New Certificate - Project Details",
        3: "Add New Certificate - Certificate Details",
        4: "Add New Certificate - Product Details",
      };

  const bulkImportModalHeader = "Add New Certificate - Bulk Import";

  let topView = useRef < HTMLDivElement > null;

  useEffect(() => {
    if (!certificateId) {
      setHasCertificateBeenActive(false);
      return;
    }

    const hasCertificateBeenActive = async () => {
      const result = await CertificateListService.getHasCertificateBeenActive(
        certificateId
      );
      setHasCertificateBeenActive(result.data);
    };

    hasCertificateBeenActive();
  }, [certificateId]);

  useEffect(() => {
    if (displayAddNewCertModal) {
      getSchemeList();
      //when modals open, clear standard options
      dispatch(updateStandardOptions(null));
    } else {
      dispatch(updateCopyPartyInformation(false));
    }

    setDialogMaximized(true);
  }, [displayAddNewCertModal]);

  const getInitialSteps = () => {
    return isEditCert || isModifyCert
      ? initialStepsWhenEditing
      : initialStepsWhenAdding;
  };

  const onHide = (name, isSaved = false) => {
    setCurrentStep(1);
    setFormAttributesWithError(null);

    if (!hidePrefereredWayStep) {
      if (isEditCert || isModifyCert) {
        setModalSteps(initialStepsWhenEditing);
      } else {
        setModalSteps(initialStepsWhenAdding);
      }
    }

    if (setPassedCurrentStep !== undefined) {
      setPassedCurrentStep(null);
    }

    dialogFuncMap[`${name}`](false);
    dispatch(updateSelectedProjectDetails(null));
    dispatch(updateLineDetails([]));
    dispatch(updateOrderLineNumbers([]));
    dispatch(updateSelectedOrderLineNumber(null));
    dispatch(updateProdDetailsAttachments(null));
    dispatch(updateCertificateNumber(null));
    dispatch(updateProdDetailsModelList(null));
    dispatch(updateCertificateScheme(null));
    dispatch(updateModelList([]));
    dispatch(updateStandardOptions(null));
    dispatch(updateHasExistingModels(false));
    dispatch(updateCertDetailsFormValues({}));
    setInitialProductDetailsValuesLocal(null);
    setInitialCertificateDetailFormValuesLocal(null);
    setInitialAttachmentValuesLocal(null);
    dispatch(updateStandardValues(null));
    dispatch(updateHasEmptyProductStandardRow(true));
    dispatch(updateHasCertificateProductStandard(true));
    dispatch(updateProductCategoryStandards(null));
    if (isSaved) {
      if (setShowCertificateDetails !== undefined) {
        setShowCertificateDetails(false);
      }

      if (setIsOpenAutotimestampModal !== undefined && tasks.length > 0) {
        setIsOpenAutotimestampModal(true);
      }
      dispatch(reloadCertificateList(true));
    } else {
      dispatch(updateAutoTimestampTask([]));
    }
  };

  const onBack = () => {
    setCurrentStep((prevState) => {
      let newState = prevState;
      if (prevState > 1) {
        newState = prevState - 1;
      }

      if (newState === 1 && !hidePrefereredWayStep) {
        setModalSteps(getInitialSteps());
      }

      return newState;
    });
  };

  const validateTestReport = async () => {
    if (
      FeatureFlagService.getECMFeatureFlag() &&
      FeatureFlagService.getLinkTestReportToMultipleCertificateFlag()
    ) {
      var duplicateTestReportRows = outputAttachmentValues[
        CertificateDocumentType.TestReport
      ].filter(
        (testReportDocument) =>
          testReportDocument.status ==
          CertificateDocumentStatus.TestReportDuplicate
      );

      if (duplicateTestReportRows.length > 0) {
        setTestReportRows(duplicateTestReportRows);
        testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(true);
      } else {
        onFinish(true);
      }
    } else {
      onFinish(true);
    }
  };

  const onFinish = async (isAutoActivated = false) => {
    testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);

    setIsSavingCertificate(true);
    let newAttributeFlatList = JSON.parse(
      JSON.stringify(certificateDetailsValues.flatAttributeList)
    );

    newAttributeFlatList.forEach((item) => {
      if (item)
        item.value =
          item.data_type !== "date"
            ? certificateDetailsValues.formValues[item.attribute_name]
            : formatDateToISO(
                certificateDetailsValues.formValues[item.attribute_name]
              );
    });

    let attachments = [];

    if (!FeatureFlagService.getECMFeatureFlag()) {
      if (productDetailsValues.attachments) {
        productDetailsValues.attachments.forEach((group, groupIndex) => {
          group
            .filter(
              (x) =>
                x.isValid &&
                x.status !=
                  CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
            )
            .forEach((file) => {
              let newFile = JSON.parse(JSON.stringify(file));
              newFile["group_index"] = groupIndex;
              attachments.push(newFile);
            });
        });
      }
    }

    const writebackData = {
      projectDetailsValues: projectDetailsValues.selectedProjectDetails,
      newAttributeFlatList: newAttributeFlatList,
      modelList: productDetailsValues.modelList,
      remarks: productDetailsValues.remarks,
      attachments: attachments,
      isAutoActivated: isAutoActivated,
      revisionNumber: revisionNumber,
      certificateId: certificateId,
      modelsProductStandardsUpdates: modelsProductStandardsUpdatesValues,
    };
    let payload = writebackData;
    let modifyCertificate = CertificateListService.modifyCertificate;
    let ipCertificateWriteback = CertificateListService.ipCertificateWriteback;

    if (FeatureFlagService.getECMFeatureFlag()) {
      modifyCertificate = CertificateListService.modifyCertificateV2;
      ipCertificateWriteback = CertificateListService.ipCertificateWritebackV2;
    }

    appInsights.trackTrace({
      message: `${logPrefix} - onFinish writeback data - ${JSON.stringify(
        writebackData
      )}`,
      severityLevel: SeverityLevel.Information,
    });

    const request = isModifyCert ? modifyCertificate : ipCertificateWriteback;

    request(payload).then(async (response) => {
      if (response?.isSuccessful) {
        if (FeatureFlagService.getECMFeatureFlag()) {
          await certificateSaveAndUploadECM(response.data, isAutoActivated);
        } else {
          showSuccess("Success!", response.message);
          onHide("displayAddNewCertModal", true);
          setIsModifyCert(false);
        }
      } else {
        showError("Error!", response?.message);
      }
      setIsSavingCertificate(false);
      testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
    });
  };

  const certificateSaveAndUploadECM = async (data, isAutoActivated) => {
    let model = {};
    let attachments = [];
    model = data;

    if (outputAttachmentValues) {
      const allAttachments = []
        .concat(...outputAttachmentValues)
        .filter(
          (attachment) =>
            attachment.isValid == true &&
            attachment.status !=
              CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
        );

      allAttachments.forEach((attachment) => {
        let obj = {};
        let documentType = attachment.group_index;
        obj.id = attachment.id;
        obj.file = attachment.file;
        obj.documentType = documentType;
        obj.status =
          (obj.id == null || obj.id == "") &&
          documentType != CertificateDocumentType.Others &&
          model?.isSaveAndActivate
            ? CertificateDocumentStatus.UploadToEcmInProgress
            : attachment.status;
        obj.ecmNodeId = attachment.ecmNodeId;
        attachments.push(obj);
      });
    }

    model.certificateFiles = attachments;

    let upsertCertificateAndUploadECM =
      CertificateListService.certificateSaveAndUploadECM;

    let promptMessage = `Certificate saved successfully`;

    if (isEditCert) {
      upsertCertificateAndUploadECM =
        CertificateListService.certificateUpdateAndUploadECM;
      promptMessage = `Certificate updated successfully`;
    }

    if (isModifyCert) {
      upsertCertificateAndUploadECM =
        CertificateListService.certificateSaveAndUploadECM;
      promptMessage = `Certificate updated successfully`;
    }

    if (isAutoActivated) {
      promptMessage = `Certificate successfully created and activated`;
    }

    let formData = objectToFormData(model);
    let response = await upsertCertificateAndUploadECM(formData);

    if (response?.isSuccess) {
      showSuccess("Success!", promptMessage);
    }
    onHide("displayAddNewCertModal", true);
    setIsSavingCertificate(false);
  };

  const isAttachmentAdded = (attachments) => {
    if (attachments == null || attachments.length == 0) return false;

    return attachments[0]?.length > 0 || attachments[1]?.length > 0;
  };

  const onClickSave = () => {
    onFinish(false);
  };

  const onClickSaveAndActivate = () => {
    if (!FeatureFlagService.getECMFeatureFlag()) onFinish(true);
    else {
      if (outputAttachmentValues) {
        const attachments = outputAttachmentValues;
        const allAttachments = [].concat(...attachments);
        const hasAttachmentsForLinkAndECM =
          allAttachments.filter(
            (file) =>
              file[`group_index`] != CertificateDocumentType.Others &&
              (file.status == CertificateDocumentStatus.NotYetUploaded ||
                file.status == CertificateDocumentStatus.UploadToEcmFailed ||
                file.status ==
                  CertificateDocumentStatus.ReadyToLinkToWbAndEcmTic ||
                file.status == CertificateDocumentStatus.TestReportDuplicate)
          ).length > 0;
        if (hasAttachmentsForLinkAndECM) setIsActivateConfirmDialogShow(true);
        else {
          setTimeout(() => {
            validateTestReport();
          }, 500);
        }
      } else {
        validateTestReport();
      }
    }
  };

  const onCancel = () => {
    hideModal();
  };

  const hideModal = () => {
    if (checkIfHasUnsavedChanges()) {
      setIsCancelConfirmDialogShow(true);
      return;
    }
    onHide("displayAddNewCertModal");
  };

  const checkIfHasUnsavedChanges = () => {
    //Note that for ECM, we utilized the outputAttachmentValues and not the productDetailsValues.attachments. Because we cannot store/pass the file object to redux.
    let initialAttachments = initialProductDetailsValuesLocal?.attachments;
    let latestAttachments = productDetailsValues?.attachments;

    if (FeatureFlagService.getECMFeatureFlag()) {
      initialAttachments = initialAttachmentValuesLocal;
      latestAttachments = outputAttachmentValues;
    }
    if (isEditCert || isModifyCert) {
      return (
        parseToString(initialProductDetailsValuesLocal?.remarks) !=
          parseToString(productDetailsValues?.remarks) ||
        parseToString(initialProductDetailsValuesLocal?.modelList) !=
          parseToString(productDetailsValues?.modelList) ||
        parseToString(initialAttachments) != parseToString(latestAttachments) ||
        parseToString(initialCertificateDetailFormValuesLocal) !=
          parseToString(certificateDetailFormValues)
      );
    } else {
      return (
        productDetailsValues?.remarks ||
        latestAttachments ||
        productDetailsValues?.modelList ||
        parseToString(certificateDetailFormValues)
      );
    }
  };

  const acceptCancelConfirmDialog = () => {
    setIsCancelConfirmDialogShow(false);
    onHide("displayAddNewCertModal");
  };

  const rejectCancelConfirmDialog = () => {
    setIsCancelConfirmDialogShow(false);
  };

  const acceptActivateConfirmDialog = () => {
    setIsActivateConfirmDialogShow(false);
    validateTestReport();
  };

  const rejectActivateConfirmDialog = () => {
    setIsActivateConfirmDialogShow(false);
  };

  const onInvalidStepClick = () => {
    var fieldIds = Object.entries(formAttributesWithError)
      .filter((filterFieldsWithError) => filterFieldsWithError[1])
      .map((field) => {
        return `certDetails-${field[0]}`;
      });

    setCurrentStep(2);
    setScrollIntoBlankRequiredElement(
      hasEmptyProductStandardRow &&
        standardValues &&
        standardValues.length === 0
        ? ["certDetails-standard"]
        : fieldIds
    );
  };

  const onContinue = () => {
    setCurrentStep((prevState) => {
      let newState = prevState;
      if (prevState < 4) {
        newState = prevState + 1;
      }
      return newState;
    });

    if (currentStep === 2) {
      saveProjectDetails();
    }

    topView.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!displayAddNewCertModal) return;
    if (
      !productDetailsValues?.attachments &&
      !productDetailsValues?.modelList &&
      !productDetailsValues?.remarks
    )
      return;

    if ((isEditCert || isModifyCert) && !initialProductDetailsValuesLocal) {
      setInitialProductDetailsValuesLocal(productDetailsValues);
    }
    setDisableSaveAndActivate(productDetailsValues);
  }, [productDetailsValues, displayAddNewCertModal]);

  useEffect(() => {
    if (!displayAddNewCertModal) return;
    if (isObjectInvalid(certificateDetailFormValues)) return;

    if (
      (isEditCert || isModifyCert) &&
      !initialCertificateDetailFormValuesLocal &&
      certificateDetailFormValues.certificateName
    ) {
      setInitialCertificateDetailFormValuesLocal(certificateDetailFormValues);
    }
  }, [certificateDetailFormValues, displayAddNewCertModal]);

  //Note that for ECM, we utilized the outputAttachmentValues and not the productDetailsValues.attachments. Because we cannot store/pass the file object to redux.
  useEffect(() => {
    if (!displayAddNewCertModal) return;
    if (!FeatureFlagService.getECMFeatureFlag()) return;
    if (!outputAttachmentValues) return;
    if ((isEditCert || isModifyCert) && !initialAttachmentValuesLocal) {
      setInitialAttachmentValuesLocal(
        processInitialAttachments(outputAttachmentValues)
      );
    }
  }, [outputAttachmentValues, displayAddNewCertModal]);

  const processInitialAttachments = (attachmentValues) => {
    //this function is use to recreate the attachment list so it is not pass as a reference object
    const initialAttachments = [].concat(...attachmentValues);
    const certificateAttachments = initialAttachments.filter(
      (file) => file[`group_index`] == CertificateDocumentType.Certificate
    );

    const testReportAttachments = initialAttachments.filter(
      (file) => file[`group_index`] == CertificateDocumentType.TestReport
    );

    const othersAttachments = initialAttachments.filter(
      (file) => file[`group_index`] == CertificateDocumentType.Others
    );

    const attachments = [
      certificateAttachments,
      testReportAttachments,
      othersAttachments,
    ];
    return attachments;
  };

  const setDisableSaveAndActivate = (productDetailsValues) => {
    if (
      productDetailsValues &&
      productDetailsValues.modelList &&
      Array.isArray(productDetailsValues.modelList) &&
      productDetailsValues.modelList.length > 0
    ) {
      setIsDisableSaveAndAttach(false);
    } else {
      setIsDisableSaveAndAttach(true);
    }
  };

  useEffect(() => {
    if (passedCurrentStep) {
      setCurrentStep(passedCurrentStep);
      !hidePrefereredWayStep && setModalSteps(initialStepsWhenAdding);
    }
  }, [passedCurrentStep]);

  const getSchemeList = async function () {
    const result = await CertificateListService.getCertificateScheme();

    if (result && Array.isArray(result) && result.length > 0) {
      setSchemeList(result);
    }
  };

  const saveProjectDetails = () => {
    ProjectDetailsService.saveProjectDetail({
      projectLineDetail: projectDetailsValues.selectedProjectDetails,
    });
  };

  const isModifyOrEditCertificate =
    (isEditCert || isModifyCert) && (currentStep === 2 || currentStep === 3);

  const customizedMarker = (item) => {
    return (
      <>
        <Badge
          value={
            item.number === 2 &&
            (!isValidStep[3] ||
              (hasEmptyProductStandardRow &&
                standardValues &&
                standardValues.length === 0)) &&
            isModifyOrEditCertificate ? (
              <FontAwesomeIcon
                className={
                  isModelInRowEditMode || currentStep === 2
                    ? undefined
                    : classes["invalidStep__button"]
                }
                icon={faExclamation}
                onClick={
                  isModelInRowEditMode || currentStep === 2
                    ? undefined
                    : onInvalidStepClick
                }
              />
            ) : item.number < currentStep ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              item.number
            )
          }
          style={{
            backgroundColor:
              item.number === 2 &&
              (!isValidStep[3] ||
                (hasEmptyProductStandardRow &&
                  standardValues &&
                  standardValues.length === 0)) &&
              isModifyOrEditCertificate
                ? invalidStepBackgroundColor
                : item.number <= currentStep
                ? steppedBackgroundColor
                : unSteppedBackgroundColor,
            color:
              item.number === 2 &&
              (!isValidStep[3] ||
                (hasEmptyProductStandardRow &&
                  standardValues &&
                  standardValues.length === 0)) &&
              isModifyOrEditCertificate
                ? invalidStepTextColor
                : item.number <= currentStep
                ? steppedTextColor
                : unSteppedTextColor,
            borderRadius: "50%",
            opacity:
              item.number === 2 &&
              isModelInRowEditMode &&
              (!isValidStep[3] ||
                (hasEmptyProductStandardRow &&
                  standardValues &&
                  standardValues.length === 0)) &&
              isModifyOrEditCertificate
                ? 0.5
                : 1,
          }}
          size="xlarge"
        ></Badge>
      </>
    );
  };

  const renderFooter = () => {
    return (
      <div className={footerActionsDisabled ? classes["footer--disabled"] : ""}>
        <hr className="modal-footer-hr" />
        <div className="add-new-certificate-container">
          {currentStep !== 1 && (
            <button
              className="ul-button -app-secondary -medium -icon float-left add-new-cert-back-btn"
              onClick={onBack}
            >
              <FontAwesomeIcon icon={faAnglesLeft} />
              <span>&nbsp;BACK</span>
            </button>
          )}
          <button
            className="ul-button -medium -icon add-new-cert-btn-cancel"
            onClick={onCancel}
            disabled={isSavingCertificate}
          >
            <span>CANCEL</span>
          </button>
          {(hidePrefereredWayStep
            ? currentStep === 1
            : (currentStep === 2 && !isEditCert) ||
              (currentStep === 1 && (isEditCert || isModifyCert))) && (
            <button
              className="ul-button -app-primary -medium -icon add-new-cert-continue-btn"
              onClick={onContinue}
              disabled={
                !(
                  projectDetailsValues.selectedProjectDetails &&
                  projectDetailsValues.orderLineNumbers
                ) || isDisableContinue
              }
            >
              <span>CONTINUE&nbsp;</span>
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          )}
          {(hidePrefereredWayStep
            ? currentStep === 2
            : (currentStep === 3 && !isEditCert) ||
              ((isEditCert || isModifyCert) && currentStep === 2)) && (
            <button
              className="ul-button -app-primary -medium -icon add-new-cert-continue-btn"
              onClick={onContinue}
              disabled={
                !isEditCert
                  ? !isValidStep[3] || isDisableContinue
                  : isDisableContinue
              }
            >
              <span>CONTINUE&nbsp;</span>
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          )}
          {(hidePrefereredWayStep
            ? currentStep === 3
            : currentStep === 4 ||
              (currentStep === 3 && (isEditCert || isModifyCert))) && (
            <>
              <button
                className="ul-button -medium -icon add-new-cert-save-btn"
                onClick={onClickSave}
                disabled={isSavingCertificate}
              >
                SAVE
              </button>

              <button
                className="ul-button -medium -icon add-new-cert-save-activate-btn"
                onClick={onClickSaveAndActivate}
                disabled={
                  isDisableSaveAndAttach ||
                  isSavingCertificate ||
                  isEmptyStandard ||
                  ((isModifyCert || isEditCert) &&
                    Object.entries(formAttributesWithError || {}).some(
                      (field) => field[1]
                    ))
                }
              >
                SAVE AND ACTIVATE
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  const onCloseModal = () => {
    hideModal();
  };

  const getHeader = () => {
    if (isEditCert) return customModalHeader;
    if (!hidePrefereredWayStep && currentStep === 3 && modalSteps.length === 3)
      return bulkImportModalHeader;
    return modalHeader[currentStep];
  };

  const confirmCancelDialogBody = () => {
    return (
      <>
        <div className="custom-confirm-dialog-body">
          <div>You have updated information that has not been saved.</div>
          <div className="custom-space-below">
            Any unsaved changes will be lost.
          </div>
          <div>Would you like to Continue?</div>
        </div>
      </>
    );
  };

  const confirmCancelFooter = () => {
    return (
      <>
        <div className="custom-confirm-dialog-footer">
          <button
            className="ul-button -app-primary -medium custom-confirm-dialog-accept-btn"
            onClick={acceptCancelConfirmDialog}
          >
            YES
          </button>
          <button
            className="ul-button -medium default-cancel-button custom-confirm-dialog-reject-btn"
            onClick={rejectCancelConfirmDialog}
          >
            NO
          </button>
        </div>
      </>
    );
  };

  const confirmActivateDialogBody = () => {
    return (
      <>
        <div className="custom-confirm-dialog-body">
          <div className="custom-space-below">
            File(s) will be saved to ECM TIC and cannot be deleted.
          </div>
          <div>Would you like to Continue?</div>
        </div>
      </>
    );
  };

  const confirmActivateFooter = () => {
    return (
      <>
        <div className="custom-confirm-dialog-footer">
          <button
            className="ul-button -app-primary -medium custom-confirm-dialog-accept-btn"
            onClick={acceptActivateConfirmDialog}
          >
            YES
          </button>
          <button
            className="ul-button -medium default-cancel-button custom-confirm-dialog-reject-btn"
            onClick={rejectActivateConfirmDialog}
          >
            NO
          </button>
        </div>
      </>
    );
  };

  const headerIconsTemplate = () => {
    return (
      <>
        <ExpandIcon
          className={classes["modal-expand-icon"]}
          onExpand={() => setDialogMaximized(true)}
          onCollapse={() => setDialogMaximized(false)}
          expanded={dialogMaximized}
        />
        <CloseIcon onCloseEvent={onCloseModal} />
      </>
    );
  };

  const getDialogStyle = () => {
    if (dialogMaximized) {
      return { width: "100vw", height: "100vh" };
    }

    return { width: "90vw", height: "90vh" };
  };

  const handleOnProceedWithoutDuplicate = () => {
    testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);

    const newOutputAttachmentValues =
      testReportsValidatorHook.removeDuplicateTestReportAttachments(
        outputAttachmentValues
      );

    setOutputAttachmentValues(newOutputAttachmentValues);
    onFinish(true);
  };

  const spinner = (
    <svg className={clsx("ul-progressCircle", "-medium", classes["spinner"])}>
      <circle className="ul-progressCircle__outer" cx="50%" cy="50%" r="35%" />
      <circle className="ul-progressCircle__inner" cx="50%" cy="50%" r="35%" />
    </svg>
  );

  return (
    <>
      <Dialog
        draggable={false}
        header={getHeader()}
        visible={displayAddNewCertModal}
        style={getDialogStyle()}
        footer={renderFooter("displayAddNewCertModal")}
        onHide={() => onHide("displayAddNewCertModal")}
        icons={headerIconsTemplate()}
        className={`add-new-cert-dialog ${
          dialogMaximized ? classes["expanded"] : classes["collapsed"]
        }`}
        closable={false}
      >
        <hr className={`modal-header-hr ${classes[`modal-header-hr`]}`} />
        <div className="add-new-cert-timeline">
          <Timeline
            value={modalSteps}
            align="top"
            layout="horizontal"
            className="customized-timeline"
            marker={customizedMarker}
          />
        </div>
        <div className="add-new-cert-modal-body">
          {testReportsValidatorHook.isValidating && (
            // <div className={classes["spinner-container"]}>{spinner}</div>
            <div className="loading-cert-attr-div">
              <div>
                <div className="fetching-attr-load-label">
                  Validating duplicate Test Report(s)...
                </div>
                <div>
                  <svg className="ul-progressCircle -medium">
                    <circle
                      className="ul-progressCircle__outer"
                      cx="50%"
                      cy="50%"
                      r="35%"
                    />
                    <circle
                      className="ul-progressCircle__inner"
                      cx="50%"
                      cy="50%"
                      r="35%"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}

          <div
            className={clsx(
              testReportsValidatorHook.isValidating && "display-none",
              classes["body-content"]
            )}
          >
            <div
              ref={(el) => {
                topView = el;
              }}
            ></div>
            <div
              className={
                hidePrefereredWayStep ||
                !(currentStep === 3 && modalSteps.length === 3 && !isEditCert)
                  ? "display-none"
                  : "add-new-cert-modal-body-content"
              }
            >
              <ImportSpreadSheet onHide={onHide} />
            </div>
            <div
              className={
                hidePrefereredWayStep || !(currentStep === 1 && !isEditCert)
                  ? "display-none"
                  : "add-new-cert-modal-body-content"
              }
            >
              <CreateOptions
                setCurrentStep={setCurrentStep}
                setModalSteps={setModalSteps}
              />
            </div>
            <div
              className={
                (
                  hidePrefereredWayStep
                    ? currentStep !== 1
                    : !(
                        (currentStep === 2 && !isEditCert) ||
                        (currentStep === 1 && isEditCert)
                      )
                )
                  ? "display-none"
                  : "add-new-cert-modal-body-content"
              }
            >
              <ProjectOrderDetails
                certificateId={certificateId}
                certificateScheme={certificateScheme}
                localData={localData}
                isEditCert={isEditCert}
                isModifyCert={isModifyCert}
                setIsDisableContinue={setIsDisableContinue}
              />
            </div>
            <div
              className={
                (
                  hidePrefereredWayStep
                    ? currentStep !== 2
                    : !(
                        (currentStep === 3 && !isEditCert) ||
                        (currentStep === 2 && isEditCert)
                      )
                )
                  ? "display-none"
                  : "add-new-cert-modal-body-content"
              }
            >
              <AddNewCertDetails
                onContinue={onContinue}
                schemeList={schemeList}
                setIsValidStep={setIsValidStep}
                isEditCert={isEditCert}
                isModifyCert={isModifyCert}
                hierarchyId={hierarchyId}
                editData={editData}
                setFormValues={setFormValues}
                formValues={formValues}
                hasRevisions={hasRevisions}
                setIsDisableContinue={setIsDisableContinue}
                certificateStatus={certificateStatus}
                formAttributesWithError={formAttributesWithError}
                setFormAttributesWithError={setFormAttributesWithError}
                formValidityMessages={formValidityMessages}
                setFormValidityMessages={setFormValidityMessages}
                scrollIntoBlankRequiredElement={scrollIntoBlankRequiredElement}
                setScrollIntoBlankRequiredElement={
                  setScrollIntoBlankRequiredElement
                }
                isCertificateSchemeUpdatable={!hasCertificateBeenActive}
                currentStep={currentStep}
                setIsCertificateHaveStandard={setIsCertificateHaveStandard}
              />
            </div>
            <div
              className={
                (
                  hidePrefereredWayStep
                    ? currentStep !== 3
                    : !(currentStep === 4 || (currentStep === 3 && isEditCert))
                )
                  ? "display-none"
                  : "add-new-cert-modal-body-content"
              }
            >
              <AddNewCertProdDetails
                certificateId={certificateId}
                setFormValues={setFormValues}
                isSavingCertificate={isSavingCertificate}
                isEditCert={isEditCert}
                isModifyCert={isModifyCert}
                setOutputAttachmentValues={setOutputAttachmentValues}
                onSetRowEditMode={setFooterActionsDisabled}
                currentStep={currentStep}
                setIsModelInRowEditMode={setIsModelInRowEditMode}
                formValidityMessages={formValidityMessages}
                setFormValidityMessages={setFormValidityMessages}
                inputAttachments={inputAttachments}
                setAttachments={setAttachments}
                isCertificateHaveStandard={isCertificateHaveStandard}
                testReportsValidatorHook={testReportsValidatorHook}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <ConfirmDialog
        style={{ width: "600px" }}
        className="custom-confirm-dialog"
        visible={isCancelConfirmDialogShow}
        header="Unsaved Changes Confirmation"
        message={confirmCancelDialogBody}
        footer={confirmCancelFooter}
        onHide={() => rejectCancelConfirmDialog()}
      />
      <ConfirmDialog
        style={{ width: "520px" }}
        className="custom-confirm-dialog"
        visible={isActivateConfirmDialogShow}
        header="Save and Activate Confirmation"
        message={confirmActivateDialogBody}
        footer={confirmActivateFooter}
        onHide={() => rejectActivateConfirmDialog()}
      />
      <TestReportMatchedFoundPopup
        visible={testReportsValidatorHook.testReportMatchedFoundPopupVisible}
        onProceedWithoutDuplicate={() => {
          testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
        }}
        onProceedWithDuplicate={() => {
          onFinish(true);
        }}
        onClose={() => {
          testReportsValidatorHook.setTestReportMatchedFoundPopupVisible(false);
        }}
        rows={testReportRows}
      />
    </>
  );
};

export default AddNewCertificate;
