export const formatDateToISO = (date) => {
  if (!date || date === "") return "";

  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatDateToString = (date) => {
  return new Date(date).toDateString();
}

export const isDateValid = (date) => {
  const unsafeDateCharacters = /[\\/*?=|$]/;

  return !unsafeDateCharacters.test(date.toString());
}

export const parseDate = (value) => {
  if (!value) return null; 

  const validDate = isDateValid(value);
  if (!validDate) return null; 


  const parsedDateTime = Date.parse(value);
  if (!isNaN(parsedDateTime)) return new Date(value);

  const parsedDate = Date.parse(value + " 00:00");
  return !isNaN(parsedDate) ? new Date(value + " 00:00") : null;
}
