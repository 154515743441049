import classes from "./Details.module.scss";

const certificateFields = [
  {
    id: "certificateScheme",
    display: "Certification Scheme",
  },
  {
    id: "certificateNumber",
    display: "Certificate Number",
  },
  {
    id: "issueDate",
    display: "Issue Date",
  },
  {
    id: "expirationDate",
    display: "Expiration Date",
  },
  {
    id: "projectNumber",
    display: "Project Number",
  },
  {
    id: "certificationBody",
    display: "Certification Body",
  },
  {
    id: "country",
    display: "Country",
  },
  {
    id: "productOwner",
    display: "Product Owner",
  },
  {
    id: "productName",
    display: "Product Name",
  },
  {
    id: "brand",
    display: "Trademark/Brand",
  },
  {
    id: "productCategory",
    display: "Product Category",
  },
  {
    id: "deliverable",
    display: "Deliverable",
  },
  {
    id: "licenseHolder",
    display: "License Holder",
  },
];

const certificateFieldsRevision = [
  {
    id: "certificateScheme",
    display: "Certification Scheme",
  },
  {
    id: "certificateNumber",
    display: "Certificate Number",
  },
  {
    id: "issueDate",
    display: "Issue Date",
  },
  {
    id: "expirationDate",
    display: "Expiration Date",
  },
  {
    id: "revisionNumber",
    display: "Revision Number",
  },
  {
    id: "projectNumber",
    display: "Project Number",
  },
  {
    id: "certificationBody",
    display: "Certification Body",
  },
  {
    id: "country",
    display: "Country",
  },
  {
    id: "productName",
    display: "Product Name",
  },
  {
    id: "brand",
    display: "Trademark/Brand",
  },
  {
    id: "deliverable",
    display: "Deliverable",
  },
  {
    id: "productOwner",
    display: "Product Owner (Ship To)",
  },
  {
    id: "mark",
    display: "Mark",
  },
  {
    id: "standard",
    display: "Standard",
  },
];

const Details = ({ details, isRevision = false }) => {
  return details && !isRevision ? (
    <div className={`${classes["certificate-details__container"]}`}>
      <div className={`${classes["title__section"]}`}>General Information</div>
      {certificateFields.map((c, i) => (
        <div key={i} className="ul-grid__row">
          <div className="ul-grid__column">
            <span>{c.display}</span>
          </div>
          <div className="ul-grid__column">
            <span>{details[c.id]}</span>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className={`${classes["certificate-details__container"]}`}>
      <div className={`${classes["title__section"]}`}>General Information</div>
      {certificateFieldsRevision.map((c, i) => (
        <div key={i} className="ul-grid__row">
          <div className="ul-grid__column">
            <span>{c.display}</span>
          </div>
          <div className="ul-grid__column">
            <span>{details[c.id]}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Details;
