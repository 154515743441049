import TreeNode from "primereact/treenode";
import classes from "./RadioTechnologyTreeSelect.module.scss";

export interface RadioTechnologyTreeSelectItem extends TreeNode {
  selectionType: RadioTechnologyTreeSelectSelectionType;
}

export type RadioTechnologyTreeSelectSelectionType = "single" | "multiple";

export const RadioTechnologyTreeSelectDefaultItems: RadioTechnologyTreeSelectItem[] = [
  {
    key: "0",
    data: "802.11",
    label: "802.11",
    selectionType: "multiple",
    children: [
      {
        key: "0-0",
        data: "a",
        label: "a",
      },
      {
        key: "0-1",
        data: "b",
        label: "b",
      },
      {
        key: "0-2",
        data: "g",
        label: "g",
      },
      {
        key: "0-3",
        data: "n (Wi-Fi 4)",
        label: "n (Wi-Fi 4)",
      },
      {
        key: "0-4",
        data: "ac (Wi-Fi 5)",
        label: "ac (Wi-Fi 5)",
      },
      {
        key: "0-5",
        data: "ax (Wi-Fi 6)",
        label: "ax (Wi-Fi 6)",
      },
      {
        key: "0-6",
        data: "Other",
        label: "Other",
      },
    ],
  },
  {
    key: "1",
    data: "BLE (Bluetooth Low Energy)",
    label: "BLE (Bluetooth Low Energy)",
    className: `${classes["tree-select--single"]}`,
    selectionType: "single",
    children: [
      {
        key: "1-0",
        data: "V4.0",
        label: "V4.0a",
      },
      {
        key: "1-1",
        data: "V4.1",
        label: "V4.1",
      },
      {
        key: "1-2",
        data: "V4.2",
        label: "V4.2",
      },
      {
        key: "1-3",
        data: "V5.0",
        label: "V5.0",
      },
      {
        key: "1-4",
        data: "V5.2",
        label: "V5.2",
      },
      {
        key: "1-5",
        data: "Other",
        label: "Other",
      },
    ],
  },
  {
    key: "2",
    data: "BT (Bluetooth)",
    label: "BT (Bluetooth)",
    className: `${classes["tree-select--single"]}`,
    selectionType: "single",
    children: [
      {
        key: "2-0",
        data: "V4.0",
        label: "V4.0",
      },
      {
        key: "2-1",
        data: "V4.1",
        label: "V4.1",
      },
      {
        key: "2-2",
        data: "V4.2",
        label: "V4.2",
      },
      {
        key: "2-3",
        data: "V5.0",
        label: "V5.0",
      },
      {
        key: "2-4",
        data: "V5.2",
        label: "V5.2",
      },
      {
        key: "2-5",
        data: "Other",
        label: "Other",
      },
    ],
  },
  {
    key: "3",
    data: "Cellular",
    label: "Cellular",
    selectionType: "multiple",
    children: [
      {
        key: "3-0",
        data: "2G",
        label: "2G",
      },
      {
        key: "3-1",
        data: "3G",
        label: "3G",
      },
      {
        key: "3-2",
        data: "4G",
        label: "4G",
      },
      {
        key: "3-3",
        data: "5G",
        label: "5G",
      },
      {
        key: "3-4",
        data: "LTE",
        label: "LTE",
      },
      {
        key: "3-5",
        data: "Millimeter-wave (mmW)",
        label: "Millimeter-wave (mmW)",
      },
      {
        key: "3-6",
        data: "Other",
        label: "Other",
      },
    ],
  },
  {
    key: "4",
    data: "NFC",
    label: "NFC",
    className: `${classes["tree-select--single"]}`,
    selectionType: "single",
    children: [
      {
        key: "4-0",
        data: "Active",
        label: "Active",
      },
      {
        key: "4-1",
        data: "Passive",
        label: "Passive",
      },
    ],
  },
  {
    key: "5",
    data: "RFID",
    label: "RFID",
    className: `${classes["tree-select--single"]}`,
    selectionType: "single",
    children: [
      {
        key: "5-0",
        data: "13.56 MHz",
        label: "13.56 MHz",
      },
      {
        key: "5-1",
        data: "800 MHz",
        label: "800 MHz",
      },
      {
        key: "5-2",
        data: "900 MHz",
        label: "900 MHz",
      },
      {
        key: "5-3",
        data: "125 KHz",
        label: "125 KHz",
      },
    ],
  },
  {
    key: "6",
    label: "SRD (Short Range Device)/Mifare (13.56 MHz)",
    data: "SRD (Short Range Device)/Mifare (13.56 MHz)",
    className: `${classes["tree-select--single"]}`,
    selectionType: "single",
  },
  {
    key: "7",
    data: "WPT (Wireless Power Transfer)",
    label: "WPT (Wireless Power Transfer)",
    className: `${classes["tree-select--single"]}`,
    selectionType: "single",
    children: [
      {
        key: "7-0",
        data: "110-140 KHz",
        label: "110-140 KHz",
      },
      {
        key: "7-1",
        data: "2.4 GHz",
        label: "2.4 GHz",
      },
      {
        key: "7-2",
        data: "900 MHz",
        label: "900 MHz",
      },
      {
        key: "7-3",
        data: "Other",
        label: "Other",
      },
    ],
  },
  {
    key: "8",
    data: "Zigbee 802.15.4",
    label: "Zigbee 802.15.4",
    className: `${classes["tree-select--single"]}`,
    selectionType: "single",
  },
  {
    key: "9",
    data: "Other",
    label: "Other",
    className: `${classes["tree-select--single"]}`,
    selectionType: "single",
  },
];
