import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useRef, useState } from "react";
import { TemplateHistoryModel } from "../../../models/TemplateHistory.model";
import { TemplateHistoryAssociatedSchemeChangeModel } from "../../../models/TemplateHistoryAssociatedSchemeChange.model";
import { TemplateHistoryFlexTaskChangeModel } from "../../../models/TemplateHistoryFlexTaskChange.model";
import ProjectTemplateMappingService from "../../../services/ProjectTemplateMappingService";
import classes from "./ProjectTemplateViewHistory.module.scss";
const ProjectTemplateViewHistory = (props: any) => {
  const datatableRef = useRef<DataTable>(null);
  const [data, setData] = useState([{}]);

  let dialogInstance: DialogComponent | null;

  const footerTemplate = () => {
    const onHideDialog = () => {
      dialogInstance?.hide();
    };
    return (
      <div className={classes["btnContainer"]}>
        <button onClick={onHideDialog} className={classes["closeBtn"]}>
          Close
        </button>
      </div>
    );
  };

  const onBeforeOpen = () => {
    ProjectTemplateMappingService.getTemplateHistory(props.correlationId)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };

  const onBeforeClose = () => {
    datatableRef?.current?.resetScroll();
  };

  const hasPreviousFlexTaskValues = (t: TemplateHistoryFlexTaskChangeModel) => {
    return t.e2EMilestoneName || t.e2EMilestonePredecessorName;
  };

  const renderTemplateHistoryFlexTaskChanges = (
    history: TemplateHistoryModel
  ) => {
    const renderChange = (
      t: TemplateHistoryFlexTaskChangeModel,
      i: number,
      arr: TemplateHistoryFlexTaskChangeModel[]
    ) => {
      if (t.operation === "ADD" || t.operation === "DELETE") {
        const operation = t.operation === "ADD" ? "Add" : "Delete";

        return (
          <div key={t.id}>
            <div>
              {t.flexTaskName !== arr[i - 1]?.flexTaskName && (
                <label className={classes["flex-task-name"]}>
                  {t.flexTaskName}
                </label>
              )}

              {t.e2EMilestoneName && (
                <div>
                  {operation} - Milestone: {t.e2EMilestoneName}
                </div>
              )}

              {t.e2EMilestonePredecessorName && (
                <div>
                  Milestone Predecessor: {t.e2EMilestonePredecessorName}
                </div>
              )}
            </div>
          </div>
        );
      }

      if (t.operation === "REMOVE" && hasPreviousFlexTaskValues(t)) {
        const operation = "Delete";
        return (
          <div key={t.id}>
            <div>
              {t.flexTaskName !== arr[i - 1]?.flexTaskName && (
                <label className={classes["flex-task-name"]}>
                  {t.flexTaskName}
                </label>
              )}

              {t.e2EMilestoneName && (
                <div>
                  {operation} - Milestone: {t.e2EMilestoneName}
                </div>
              )}

              {t.e2EMilestonePredecessorName && (
                <div>
                  {!t.e2EMilestoneName ? operation.concat(" - ") : ""} Milestone
                  Predecessor: {t.e2EMilestonePredecessorName}
                </div>
              )}
            </div>
          </div>
        );
      }

      if (t.operation === "UPDATE") {
        return (
          <div key={t.id}>
            <div>
              {t.flexTaskName && <div>{t.flexTaskName}</div>}
              {t.e2EMilestoneName && <div>Milestone: {t.e2EMilestoneName}</div>}
              {t.e2EMilestonePredecessorName && (
                <div>
                  Milestone Predecessor: {t.e2EMilestonePredecessorName}
                </div>
              )}
            </div>
          </div>
        );
      }
    };

    return (
      <>
        {history.templateHistoryFlexTaskChanges?.length > 0 ? (
          <div className={classes["flex-tasks"]}>
            <div>
              {history.templateHistoryFlexTaskChanges?.map(renderChange)}
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const renderTemplateHistoryAssociatedSchemeChanges = (
    history: TemplateHistoryModel
  ) => {
    const renderChange = (t: TemplateHistoryAssociatedSchemeChangeModel) => {
      if (t.operation === "ADD" || t.operation === "DELETE") {
        const operation = t.operation === "ADD" ? "Add" : "Delete";

        return (
          <div key={t.id}>
            <div>
              {operation} - Certificate Scheme: {t.schemeName}
            </div>
            <div>Delivery Path: {t.deliveryPathName}</div>
            <div>Application Type: {t.applicationTypeName}</div>
            <div>Additional Info: {t.additionalInfoName}</div>
          </div>
        );
      }

      if (t.operation === "UPDATE") {
        return (
          <div key={t.id}>
            {t.schemeName !== null && (
              <div>Certificate Scheme: {t.schemeName}</div>
            )}

            {t.deliveryPathName !== null && (
              <div>Delivery Path: {t.deliveryPathName}</div>
            )}

            {t.applicationTypeName !== null && (
              <div>Application Type: {t.applicationTypeName}</div>
            )}

            {t.additionalInfoName !== null && (
              <div>Additional Info: {t.additionalInfoName}</div>
            )}
          </div>
        );
      }
    };

    return (
      <>
        {history.templateHistoryAssociatedSchemeChanges?.length > 0 ? (
          <div className={classes["associated-scheme"]}>
            <label style={{ fontWeight: "bold" }}>Associated Scheme:</label>
            <div>
              {history.templateHistoryAssociatedSchemeChanges?.map(
                renderChange
              )}
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const detailsTemplate = (history: TemplateHistoryModel) => {
    const templateNameChange =
      history.templateNameChange !== null ? (
        <div>
          <label>Template Name Update: {history.templateNameChange}</label>
        </div>
      ) : null;

    const mappedFlexTemplateChange =
      history.mappedFlexTemplateChange !== null ? (
        <div>
          <label>
            Mapped FLEX Template Update: {history.mappedFlexTemplateChange}
          </label>
        </div>
      ) : null;

    const templateHistoryAssociatedSchemeChanges =
      renderTemplateHistoryAssociatedSchemeChanges(history);

    const templateHistoryFlexTaskChanges =
      renderTemplateHistoryFlexTaskChanges(history);

    return (
      <div className={classes["change-details"]}>
        {templateNameChange}
        {mappedFlexTemplateChange}
        {templateHistoryAssociatedSchemeChanges}
        {templateHistoryFlexTaskChanges}
      </div>
    );
  };

  const columns: unknown[] = [
    {
      field: "version",
      header: "Version",
      width: "10%",
    },
    {
      field: "status",
      header: "Status",
      width: "10%",
    },
    {
      field: "event",
      header: "Event",
      width: "10%",
    },
    {
      field: "details",
      header: "Details",
      width: "30%",
      body: detailsTemplate,
    },
    {
      field: "modifiedBy",
      header: "Updated By",
      width: "20%",
    },
    {
      field: "modifiedDateUtc",
      header: "Updated on",
      width: "20%",
    },
  ];

  const dynamicColumns = columns.map((col: any, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
          maxWidth: col.width,
          minWidth: col.width,
          verticalAlign: "baseline",
          alignItems: "baseline",
        }}
        body={col.body}
      />
    );
  });

  return (
    <div id="dialog-target" className={`${classes["view-history"]}`}>
      <DialogComponent
        width="75vw"
        target={`#${props.target}`}
        showCloseIcon={true}
        header="GMA WB Template Version History"
        closeOnEscape={false}
        close={props.onCloseDialog}
        beforeOpen={onBeforeOpen}
        footerTemplate={footerTemplate}
        ref={(dialog) => (dialogInstance = dialog)}
        visible={props.isVisible}
        isModal={true}
        height="90vh"
        className={classes["dialog"]}
        beforeClose={onBeforeClose}
      >
        <div className={classes["table-container"]}>
          <DataTable
            ref={datatableRef}
            scrollable
            scrollHeight="100%"
            value={data}
            showGridlines
          >
            {dynamicColumns}
          </DataTable>
        </div>
      </DialogComponent>
    </div>
  );
};

export default ProjectTemplateViewHistory;
