import clsx from "clsx";
import { TabPanel, TabView } from "primereact/tabview";
import { useState, useEffect } from "react";
import ProjectDetailsOrderLineList from "../project-details-order-line-list/ProjectDetailsOrderLineList";
import ProjectDetails from "../project-details/ProjectDetails";
import ProjectTaskList from "../project-task-list/ProjectTaskList";
import classes from "./ProjectListDetailTab.module.scss";
import PmrE2EProjectModel from "../shared/interfaces/pmr-e2e-project-model";
import { ComponentWithIndices } from "../../../hooks/usePmrShiftSelect";
interface ProjectListDetailTabProps extends ComponentWithIndices {
  projectId: string;
  project: PmrE2EProjectModel;
  overrideActiveTabIndex: number | null;
}
const ProjectListDetailTab = (props: ProjectListDetailTabProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (props.overrideActiveTabIndex != null)
      setActiveTabIndex(props.overrideActiveTabIndex);
  }, [props.overrideActiveTabIndex]);

  return (
    <>
      <div className={`${classes["tab-container"]}`}>
        <TabView
          activeIndex={activeTabIndex}
          onTabChange={(e) => setActiveTabIndex(e.index)}
        >
          <TabPanel header="Table" />
          <TabPanel header="Project Details" />
          <TabPanel header="Order Line Details" />
        </TabView>

        <div className={classes["tab-panel"]}>
          <div className={clsx(activeTabIndex !== 0 && "d-none")}>
            <ProjectTaskList
              projectName={props.project.name}
              projectStatus={props.project.status}
              projectId={props.projectId}
              isActive={activeTabIndex === 0}
              depthIndices={props.depthIndices}
            ></ProjectTaskList>
          </div>

          {activeTabIndex === 1 && (
            <ProjectDetails projectId={props.projectId}></ProjectDetails>
          )}

          {activeTabIndex === 2 && (
            <ProjectDetailsOrderLineList
              project={props.project}
              projectId={props.projectId}
            ></ProjectDetailsOrderLineList>
          )}
        </div>
      </div>
    </>
  );
};
export default ProjectListDetailTab;
