import classes from "./ProjectTemplateDeleteModal.module.scss";
import { TemplateModel } from "../../../shared/models/Template.model";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { authProvider } from "../../../providers/authProvider";

export type ProjectTemplateDeleteModalProps = {
  showDeleteModal: boolean;
  setShowDeleteModal: Function;
  templatesToDelete: TemplateModel[];
  setTemplatesToDelete: Function;
  handleDeleteProjectTemplateList: Function;
};

const ProjectTemplateDeleteModal = (props: ProjectTemplateDeleteModalProps) => {
  const currentUser: string = authProvider
    .getAccountInfo()
    ?.account.idToken.email.toLowerCase()!;

  const handleDeletion = (status: boolean) => {
    if (status) props.handleDeleteProjectTemplateList(props.templatesToDelete);
    props.setShowDeleteModal(false);
  };

  const canDeleteHeader = () => {
    return "Delete GMA WB Template Confirmation";
  };

  const canDeleteContent: JSX.Element = (
    <>
      <hr />
      <div className={`ul-grid__column ${classes["content"]}`}>
        <label className="ul-grid__column">
          Are you sure you want to delete this GMA WB template(s)?
        </label>
      </div>
    </>
  );

  const canDeleteFooter = () => {
    return (
      <div className="ul-grid__column">
        <div className="ul-grid__row">
          <div className="ul-grid__column -x50">
            <Button
              className={classes["yesButton"]}
              onClick={() => handleDeletion(true)}
            >
              Yes
            </Button>
          </div>
          <div className="ul-grid__column -x50">
            <Button
              className={`p-button-secondary ${classes["noButton"]}`}
              onClick={() => handleDeletion(false)}
            >
              No
            </Button>
          </div>
          <div className="column-fill"></div>
          <div className="column-fill"></div>
        </div>
      </div>
    );
  };

  const cannotDeleteHeader = () => {
    return "Cannot Delete GMA WB Template";
  };

  const cannotDeleteContent: JSX.Element = (
    <>
      <hr />
      <div className={`ul-grid__column ${classes["content"]}`}>
        {props.templatesToDelete.length < 2 ? (
          <div className="ul-grid__column">
            Selected GMA WB template(s) cannot be deleted because it is created
            by another Admin. Please notify GMA WB Template Creator of your
            request for the GMA WB Template to be deleted.
          </div>
        ) : (
          <div className="ul-grid__column">
            <div className="ul-grid__column">
              Selected GMA WB template(s) cannot be deleted because it is
              created by another Admin:
            </div>
            <br />
            <div
              className={`ul-grid__column ${classes["divTextOverFlow"]} ${
                props.templatesToDelete.filter(
                  (x) => x.createdBy !== currentUser
                ).length > 10 && classes["divScroll"]
              }`}
            >
              <ul>
                {props.templatesToDelete
                  .filter((x) => x.createdBy !== currentUser)
                  .map((template) => {
                    return (
                      <li
                        className={`${classes["templatesCannotBeDeletedList"]}`}
                        key={template.id}
                        title={template.name}
                      >
                        {template.name}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <br />
            {props.templatesToDelete.some(
              (x) => x.createdBy === currentUser
            ) ? (
              <span>
                Please deselect to proceed with other selected GMA WB Templates.
                Otherwise, please notify GMA WB Template Creator of your request
                for the GMA WB Template(s) to be deleted.
              </span>
            ) : (
              <span>
                Please notify GMA WB Template Creator of your request for the
                GMA WB Template to be deleted.
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );

  const cannotDeleteFooter = () => {
    return (
      <div className={`ul-grid__row -wrap ${classes["centerFooterButton"]}`}>
        <Button
          className={`${classes["yesButton"]}`}
          onClick={() => handleDeletion(false)}
        >
          OK
        </Button>
      </div>
    );
  };

  return (
    <>
      {props.templatesToDelete.every((x) => x.createdBy === currentUser) ? (
        <Dialog
          id="modalDialog"
          header={canDeleteHeader}
          style={{ width: "500px" }}
          visible={props.showDeleteModal}
          onHide={() => props.setShowDeleteModal(false)}
          footer={canDeleteFooter}
          closeOnEscape
          className={`${classes["dialog-close-button"]}`}
        >
          {canDeleteContent}
        </Dialog>
      ) : (
        <Dialog
          id="modalDialog"
          header={cannotDeleteHeader}
          style={{ width: "500px" }}
          visible={props.showDeleteModal}
          onHide={() => props.setShowDeleteModal(false)}
          footer={cannotDeleteFooter}
          closeOnEscape
          className={`${classes["dialog-close-button"]}`}
        >
          {cannotDeleteContent}
        </Dialog>
      )}
    </>
  );
};

export default ProjectTemplateDeleteModal;
