import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { selectIsReadOnly } from "../../../../../features/generalProductInformation/generalProductInformationSlice";
import CustomerInformation from "../../../company-details/shared/CustomerInformation/CustomerInformation";
import CollapsibleForms from "../../../form/collapsible-forms/CollapsibleForms";
import classes from "./SchemeInformationDetailRepresentative.module.scss";

const SchemeInformationDetailRepresentative = ({
  scheme,
  schemes,
  onChangeRepresentative,
  onRemoveRepresentative,
  onAddRepresentative,
  disableIntegration,
  onFormFocus,
  controlClassName
}) => {
  const isReadOnly = useSelector(selectIsReadOnly);

  const setAddRepresentative = (scheme) => {
    onAddRepresentative(scheme);
  };

  const handleChangeRepresentative = (value, index, source) => {
    const newValue = {
      ...value,
    };

    if (source) newValue.source = source;
    else newValue.source = undefined;

    onChangeRepresentative(scheme, newValue, index);
  };

  const getCopyRepresentativeOptions = (schemes, scheme) => {
    let ret = [];

    for (let currentScheme of schemes) {
      if (currentScheme.schemeId != scheme.schemeId) {
        if (
          currentScheme.countryCode == scheme.countryCode ||
          scheme.countryCode.split("|").indexOf(currentScheme.countryCode) >=
          0 ||
          currentScheme.countryCode.split("|").indexOf(scheme.countryCode) >= 0
        ) {
          for (
            let ctr = 0;
            ctr <= currentScheme.representatives.length - 1;
            ctr++
          ) {
            ret.push({
              label: `${currentScheme.certScheme} - Representative ${ctr + 1}`,
              value: {
                schemeId: currentScheme.schemeId,
                representativeIndex: ctr,
              },
            });
          }
        }
      }
    }

    return ret;
  };

  const handleRepresentativeCopy = (e, representativeDestinationIndex) => {
    const { schemeId, representativeIndex } = e.value;

    const copyRepresentative = schemes.find((s) => s.schemeId === schemeId)
      .representatives[representativeIndex];

    handleChangeRepresentative(
      { ...copyRepresentative },
      representativeDestinationIndex,
      e.value
    );
  };

  return (
    <div className={`${classes["container"]} ${controlClassName}`}>
      <div className={`${classes["accordion-header"]}`}>
        <h4>Importer/Representative Information</h4>
        <h5>
          Use this space to specify the "legal" company name and details for
          your local Representative/importer details.
        </h5>
      </div>

      {
        <CollapsibleForms
          items={scheme.representatives}
          formTitleTemplate={(representative, i) => {
            const representativeLabel = "Representative " + (i + 1);
            const companyAndContactNames = [];

            if (representative.companyName)
              companyAndContactNames.push(representative.companyName);

            if (representative.contactPerson)
              companyAndContactNames.push(representative.contactPerson);

            var companyAndContactNamesLabel =
              companyAndContactNames.join(" - ");

            return (
              representativeLabel +
              (companyAndContactNamesLabel
                ? " ( " + companyAndContactNamesLabel + " )"
                : "")
            );
          }}
          deletable={true}
          confirmDeleteSingleMessageTemplate={() =>
            "Are you sure you want to remove this representative information?"
          }
          confirmDeleteHeaderLabel={() => "Delete Representative"}
          formTemplate={(representative, i, expand) => {
            const repSection = `${controlClassName}-representative-${(i + 1)}`;
            return <CustomerInformation
              disableIntegration={disableIntegration}
              customerInformation={representative}
              onChange={(value) => handleChangeRepresentative(value, i)}
              onFocus={() => {
                expand();
                onFormFocus();
              }}
              section={repSection}
            />
          }
          }
          onDeleteItem={(i) => onRemoveRepresentative(scheme, i)}
          customOptionsTemplate={(representative, index) => (
            <div className={`${classes["copy-section"]}`}>
              <Dropdown
                disabled={isReadOnly}
                placeholder="-- Copy details --"
                optionValue="value"
                optionLabel="label"
                options={getCopyRepresentativeOptions(schemes, scheme)}
                value={representative.source}
                onChange={(e) => {
                  handleRepresentativeCopy(e, index);
                }}
                className={`${classes["select-copy-detail"]}`}
              />
            </div>
          )}
          canDeleteAll={scheme.uLToProvide}
        />
      }

      <button
        disabled={isReadOnly}
        className={`ul-button ${classes["add-rep-button"]}`}
        onClick={(e) => setAddRepresentative(scheme)}
      >
        Add Representative / Importer
      </button>
    </div>
  );
};

export default SchemeInformationDetailRepresentative;
