import { E2EUserPaginatedModelResult } from "../shared/models/service-models/E2EUserPaginatedModelResult.model";
import RequestFactory from "./RequestFactory";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

const GMAWBUsersService = {
  getPaginatedUsers: async (
    skip: number,
    take: number,
    handlerType: number,
    isGmaTcTask?: boolean | undefined | null,
    searchPhrase?: string
  ) => {
    let payload = `handlerType=${handlerType}`;

    if (skip) payload += `&skip=${skip}`;
    if (take) payload += `&take=${take}`;
    if (isGmaTcTask !== null && isGmaTcTask !== undefined)
      payload += `&isGmaTcTask=${isGmaTcTask}`;
    if (searchPhrase) payload += `&searchPhrase=${searchPhrase}`;

    return (await RequestFactory.get(
      GMAE2EURL,
      `/api/gma-wb-users/get-paginated?${payload}`
    )) as E2EUserPaginatedModelResult;
  },

  getCOHandlerPaginatedUsers: async (
    skip: number,
    take: number,
    handlerType: number,
    isGmaTcTask?: boolean | undefined | null,
    searchPhrase?: string
  ) => {
    let payload = `handlerType=${handlerType}`;

    if (skip) payload += `&skip=${skip}`;
    if (take) payload += `&take=${take}`;
    if (isGmaTcTask !== null && isGmaTcTask !== undefined)
      payload += `&isGmaTcTask=${isGmaTcTask}`;
    if (searchPhrase) payload += `&searchPhrase=${searchPhrase}`;

    return (await RequestFactory.get(
      GMAE2EURL,
      `/api/gma-wb-users/cohandlers/get-paginated?${payload}`
    )) as E2EUserPaginatedModelResult;
  },
  saveContributors: async (data: any) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/save-contributors",
      data
    ),
  assignCoHandler: async (data: any) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/assign-cohandlers",
      data
    ),
};

export default GMAWBUsersService;
