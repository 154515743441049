import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import ProjectTemplateMappingService from "../../../../services/ProjectTemplateMappingService";
import classes from "./ProjectTemplateSchemeEditGrid.module.scss";
import {
  faPlus,
  faMinus,
  faExclamation,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ProjectTemplateSchemeEditGrid.scss";
import { Button } from "primereact/button";
import { TemplateAssociationModel } from "../../../../shared/models/TemplateAssociation.model";
import { SelectListResultModel } from "../../../../shared/models/service-models/SelectListResult.model";
import { ProjectTemplateComponentData } from "../../ProjectTemplateMapping";
import FormTemplateError from "../../shared/component/FormTemplateError";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core/index";
import { Dropdown } from "primereact/dropdown";
import { AssociationSchemeDataRequestModel } from "../../../../shared/models/service-models/AssociationSchemeDataRequest.model";
interface ProjectTemplateSchemeEditGridProps {
  assocSchemeData: TemplateAssociationModel[];
  setComponentData: React.Dispatch<
    React.SetStateAction<ProjectTemplateComponentData>
  >;
  schemeList: Array<SelectListResultModel>;
  additionalInfoId: string;
  isFetchSchemesLoading: boolean;
}

const ProjectTemplateSchemeEditGrid = (
  componentProps: ProjectTemplateSchemeEditGridProps
) => {
  const [certSchemes, setCertSchemes] = useState<SelectListResultModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { assocSchemeData, additionalInfoId } = componentProps;

  const initialBlankRow: TemplateAssociationModel = {
    id: null,
    schemeId: null,
    schemeName: null,
    certificateSchemeList: [],
    deliveryPathId: null,
    deliveryPathName: null,
    deliveryPathListResult: [],
    applicationTypeId: null,
    applicationTypeName: null,
    applicationTypeListResult: [],
    additionalInfoId: null,
    additionalInfoName: null,
    additionalInfoListResult: [],
  };

  useEffect(() => {
    setCertSchemes(componentProps.schemeList);
  }, [componentProps.schemeList]);

  useEffect(() => {
    setIsLoading(componentProps.isFetchSchemesLoading);
  }, [componentProps.isFetchSchemesLoading]);

  useEffect(() => {
    const list = checkDuplicates(componentProps.assocSchemeData);

    componentProps.setComponentData((current) => ({
      ...current,
      associatedSchemeData: list,
    }));

    (async () => {
      setIsLoading(true);

      await handlePreDefaultData();
      setIsLoading(false);
    })();
  }, []);

  const handlePreDefaultData = async () => {
    let newAssocSchemeData = [...componentProps.assocSchemeData];

    let newList: any = [];

    for (
      var schemeCount = 0;
      schemeCount <= newAssocSchemeData.length - 1;
      schemeCount++
    ) {
      let parameter = { ...newAssocSchemeData[schemeCount] };

      let requestParameter: AssociationSchemeDataRequestModel =
        {} as AssociationSchemeDataRequestModel;

      let selectedScheme: any = {
        id: parameter.schemeId,
        value: parameter.schemeName,
        selected: true,
      };

      let selectedSchemes: SelectListResultModel[] = [selectedScheme];

      requestParameter = {
        AdditionalInfoId: additionalInfoId,
        SelectedScheme: JSON.stringify(selectedSchemes),
        SelectedApplicationType: "[]",
      };

      if (parameter.applicationTypeId) {
        let selectedApplicationType: any = {
          id: parameter.applicationTypeId,
          value: parameter.applicationTypeName,
          selected: true,
        };

        let selectedApplicationTypes: SelectListResultModel[] = [
          selectedApplicationType,
        ];

        requestParameter.SelectedApplicationType = JSON.stringify(
          selectedApplicationTypes
        );
      }

      await ProjectTemplateMappingService.getAssociationSchemeDropdownByAdditionalInfoId(
        requestParameter
      )
        .then((response) => {
          parameter.deliveryPathListResult = response.data[0].deliveryPath;
          parameter.applicationTypeListResult =
            response.data[0].applicationType;
        })
        .catch((error) => console.error(error));

      newList.push(parameter);
    }

    componentProps.setComponentData((current) => ({
      ...current,
      associatedSchemeData: newList,
    }));
  };

  const handleDropdownChange = async (
    e: any,
    data: TemplateAssociationModel,
    options: ColumnBodyOptions,
    ...fields: string[]
  ) => {
    const [idField, nameField, isEmptyField] = fields;
    const { rowIndex } = options;

    const list = await Promise.all(
      componentProps.assocSchemeData.map(
        async (m: TemplateAssociationModel, index: number) => {
          if (rowIndex === index) {
            if (idField === "schemeId") {
              const certSchemeName = certSchemes.find(
                (certScheme) => certScheme.id === e.value
              )?.value;
              return {
                ...m,
                [idField]: e.value,
                [nameField]: certSchemeName,
                [isEmptyField]: false,
                deliveryPathId: null,
                deliveryPathName: null,
                applicationTypeId: null,
                applicationTypeName: null,
                additionalInfoId: null,
                additionalInfoName: null,
                additionalInfoListResult: [],
              };
            }

            return {
              ...m,
              [idField]: e.value,
              [nameField]: getValueName(m, idField, e),
              [isEmptyField]: false,
            };
          }

          return {
            ...m,
          };
        }
      )
    );

    if (idField == "schemeId" || idField == "applicationTypeId") {
      setIsLoading(true);

      await getAssociationSchemeDropdowns(e, data, list, idField, rowIndex);
      setIsLoading(false);
    } else {
      const newList = checkDuplicates(list);

      componentProps.setComponentData((current) => ({
        ...current,
        associatedSchemeData: newList,
      }));
    }
  };

  const getAssociationSchemeDropdowns = async (
    e: any,
    data: TemplateAssociationModel,
    list: TemplateAssociationModel[],
    idField: string,
    rowIndex: number
  ) => {
    let parameter = { ...data };

    let requestParameter: AssociationSchemeDataRequestModel =
      {} as AssociationSchemeDataRequestModel;

    let selectedScheme: any;
    if (idField == "schemeId") {
      selectedScheme = certSchemes.find((x) => x.id == e.value);
    } else {
      selectedScheme = certSchemes.find((x) => x.id == parameter.schemeId);
    }

    selectedScheme!.selected = true;

    let selectedSchemes: SelectListResultModel[] = [selectedScheme];

    requestParameter = {
      AdditionalInfoId: additionalInfoId,
      SelectedScheme: JSON.stringify(selectedSchemes),
      SelectedApplicationType: "[]",
    };

    if (idField == "applicationTypeId") {
      let selectedApplicationType: any =
        parameter.applicationTypeListResult.find((x) => x.id == e.value);

      selectedApplicationType!.selected = true;

      let selectedApplicationTypes: SelectListResultModel[] = [
        selectedApplicationType,
      ];

      requestParameter.SelectedApplicationType = JSON.stringify(
        selectedApplicationTypes
      );
      parameter.deliveryPathId = null;
      parameter.deliveryPathName = null;
      parameter.deliveryPathListResult = [];
    }

    await ProjectTemplateMappingService.getAssociationSchemeDropdownByAdditionalInfoId(
      requestParameter
    )
      .then((response) => {
        parameter.deliveryPathListResult = response.data[0].deliveryPath;
        parameter.applicationTypeListResult = response.data[0].applicationType;
      })
      .catch((error) => console.error(error));

    const listWithResults: any = list.map(
      (m: TemplateAssociationModel, index: number) => {
        if (rowIndex === index) {
          return {
            ...m,
            deliveryPathListResult: parameter.deliveryPathListResult,
            deliveryPathId: parameter.deliveryPathId,
            deliveryPathName: parameter.deliveryPathName,
            applicationTypeListResult: parameter.applicationTypeListResult,
          };
        } else {
          return { ...m };
        }
      }
    );

    const newList = checkDuplicates(listWithResults);

    componentProps.setComponentData((current) => ({
      ...current,
      associatedSchemeData: newList,
    }));
  };

  const handleDeleteRow = (options: ColumnBodyOptions) => {
    const schemes = componentProps.assocSchemeData.map((m) => {
      return { ...m };
    });

    schemes.splice(options.rowIndex, 1);

    if (schemes.length === 0) {
      schemes.splice(options.rowIndex + 1, 0, initialBlankRow);
    }

    const newList = checkDuplicates(schemes);

    componentProps.setComponentData((current) => ({
      ...current,
      associatedSchemeData: newList,
    }));
  };

  const handleAddRow = (options: ColumnBodyOptions) => {
    const schemes = componentProps.assocSchemeData.map((m) => {
      return { ...m };
    });

    schemes.splice(options.rowIndex + 1, 0, initialBlankRow);

    componentProps.setComponentData((current) => ({
      ...current,
      associatedSchemeData: schemes,
    }));
  };

  const errorTemplate = (message: string, icon: IconDefinition) => {
    return (
      <FormTemplateError errorMessage={message} visible={true} icon={icon} />
    );
  };

  const hasCertificateSchemeError = (data: TemplateAssociationModel) => {
    return data.isCertSchemeEmpty || data.isDuplicate || !data.schemeId;
  };

  const hasDeliveryPathError = (data: TemplateAssociationModel) => {
    return data.schemeId && data.applicationTypeId && !data.deliveryPathName;
  };

  const hasApplicationTypeError = (data: TemplateAssociationModel) => {
    return data.schemeId && !data.applicationTypeName;
  };

  const getValueName = (
    m: TemplateAssociationModel,
    field: string,
    e: any
  ): string | null | undefined => {
    let associateSchemeField = null;

    if (field === "deliveryPathId") associateSchemeField = "deliveryPath";
    else if (field === "applicationTypeId")
      associateSchemeField = "applicationType";

    const list = m[`${associateSchemeField}ListResult`];
    const item = (list as SelectListResultModel[]).find(
      (item) => item.id === e.value
    );
    return item?.value ?? null;
  };

  const columns = [
    {
      className: "column-wrapper",
      field: "schemeName",
      headerText: "Certificate Scheme",
      maxWidth: "unset",
      body: (data: TemplateAssociationModel, options: ColumnBodyOptions) => (
        <div
          className={`${classes["autocomplete-wrapper"]} ${
            data.isDuplicate ? classes["invalid-column-error"] : ""
          }`}
        >
          <Dropdown
            className={`${classes["auto-complete"]} ${
              componentProps.additionalInfoId && hasCertificateSchemeError(data)
                ? "p-invalid " + classes["button-invalid"]
                : ""
            }`}
            value={data.schemeId}
            options={certSchemes ?? []}
            onChange={(e) =>
              handleDropdownChange(
                e,
                data,
                options,
                "schemeId",
                "schemeName",
                "isCertSchemeEmpty"
              )
            }
            optionLabel="value"
            optionValue="id"
            placeholder="-- Select --"
            style={{ width: "100%" }}
            filter
            filterMatchMode="contains"
            resetFilterOnHide={true}
            disabled={!componentProps.additionalInfoId}
          />
          {(componentProps.additionalInfoId &&
            (data.isCertSchemeEmpty || !data.schemeId) &&
            errorTemplate("Please select a certificate Scheme", faClose)) ||
            (data.isDuplicate &&
              errorTemplate(
                "Associated Scheme details already exists. Please enter a unique combination for this row.",
                faExclamation
              ))}
        </div>
      ),
    },
    {
      className: "column-wrapper",
      field: "applicationTypeName",
      headerText: "Application Type",
      maxWidth: "unset",
      body: (data: TemplateAssociationModel, options: ColumnBodyOptions) => (
        <div
          className={`${classes["autocomplete-wrapper"]} ${
            data.isDuplicate ? classes["invalid-column-error"] : ""
          }`}
        >
          <Dropdown
            className={`${classes["auto-complete"]} ${
              hasApplicationTypeError(data) || data.isDuplicate
                ? "p-invalid " + classes["button-invalid"]
                : ""
            } `}
            value={data.applicationTypeId}
            options={data.applicationTypeListResult ?? []}
            onChange={(e) =>
              handleDropdownChange(
                e,
                data,
                options,
                "applicationTypeId",
                "applicationTypeName"
              )
            }
            optionLabel="value"
            optionValue="id"
            placeholder="-- Select --"
            style={{ width: "100%" }}
            disabled={
              !(data.applicationTypeListResult?.length || data.schemeId)
            }
            filter
            filterMatchMode="contains"
            resetFilterOnHide={true}
          />
          {hasApplicationTypeError(data) &&
            errorTemplate("Please select an Application Type.", faClose)}
        </div>
      ),
    },
    {
      className: "column-wrapper",
      field: "deliveryPathName",
      headerText: "Delivery Path(s)",
      maxWidth: "unset",
      body: (data: TemplateAssociationModel, options: ColumnBodyOptions) => (
        <div
          className={`${classes["autocomplete-wrapper"]} ${
            data.isDuplicate ? classes["invalid-column-error"] : ""
          }`}
        >
          <Dropdown
            className={`${classes["auto-complete"]} ${
              hasDeliveryPathError(data) || data.isDuplicate
                ? "p-invalid " + classes["button-invalid"]
                : ""
            } `}
            value={data.deliveryPathId}
            options={data.deliveryPathListResult ?? []}
            onChange={(e) =>
              handleDropdownChange(
                e,
                data,
                options,
                "deliveryPathId",
                "deliveryPathName"
              )
            }
            optionLabel="value"
            optionValue="id"
            placeholder="-- Select --"
            disabled={!data.applicationTypeId}
            style={{ width: "100%" }}
            filter
            filterMatchMode="contains"
            resetFilterOnHide={true}
          />

          {hasDeliveryPathError(data) &&
            errorTemplate("Please select a Delivery Path.", faClose)}
        </div>
      ),
    },

    {
      className: "column-wrapper",
      field: "action",
      headerText: "",
      maxWidth: "10%",
      body: (data: any, options: ColumnBodyOptions) => (
        <div
          className={`${classes["autocomplete-wrapper"]} ${
            data.isDuplicate ? classes["invalid-column-error"] : ""
          }`}
        >
          <div className={`${classes["action-wrapper"]}`}>
            <Button
              onClick={() => handleDeleteRow(options)}
              disabled={
                componentProps.assocSchemeData.length === 1 ||
                !componentProps.additionalInfoId
              }
              className={`${classes["button"]}`}
              title={"Remove Row"}
            >
              <FontAwesomeIcon
                className={`${classes["icon"]}`}
                icon={faMinus}
              />
            </Button>

            <Button
              onClick={() => handleAddRow(options)}
              className={`${classes["button"]}`}
              title={"Add Row"}
              disabled={!componentProps.additionalInfoId}
            >
              <FontAwesomeIcon className={`${classes["icon"]}`} icon={faPlus} />
            </Button>
          </div>
        </div>
      ),
    },
  ];
  const renderDynamicColumns = () =>
    columns.map((c, index) => (
      <Column
        key={c.headerText}
        field={c.field}
        header={c.headerText}
        body={c.body}
        style={{ maxWidth: c.maxWidth }}
        className={classes[`${c.className}`]}
      />
    ));

  return (
    <>
      <DataTable
        value={componentProps.assocSchemeData}
        scrollable
        showGridlines
        loading={isLoading}
        className={classes["association-table"]}
        size={"small"}
      >
        {renderDynamicColumns()}
      </DataTable>
    </>
  );
};

export default ProjectTemplateSchemeEditGrid;

const hasBlankField = (data: TemplateAssociationModel) => {
  return !(data.schemeId && data.deliveryPathId && data.applicationTypeId);
};

export const checkDuplicates = (
  currentTemplateAssocArr: Array<TemplateAssociationModel>
): Array<TemplateAssociationModel> => {
  const newTemplateAssocArr = currentTemplateAssocArr.map(
    (templateAssoc, index, currentTemplateAssocArr) => {
      if (hasBlankField(templateAssoc)) {
        return { ...templateAssoc, isDuplicate: false };
      }

      const hasDuplicate =
        currentTemplateAssocArr.findIndex(
          ({ schemeId, deliveryPathId, applicationTypeId }) =>
            schemeId === templateAssoc.schemeId &&
            deliveryPathId === templateAssoc.deliveryPathId &&
            applicationTypeId === templateAssoc.applicationTypeId
        ) !== index;

      return { ...templateAssoc, isDuplicate: hasDuplicate };
    }
  );

  return newTemplateAssocArr;
};
