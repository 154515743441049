import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import classes from "./CDPDeleteModal.module.scss";
import { E2EProjectLineModel } from "../../../shared/models/E2EProject.model";

export interface CDPDeleteModalProps {
  showDeleteModal: boolean;
  setShowDeleteModal: Function;
  handleDeleteProjectLines: Function;
  selectedProjectLines: E2EProjectLineModel[];
}

const CDPDeleteModal = (props: CDPDeleteModalProps) => {
  const removeOrderLineHeader = () => {
    return "Remove Order Line(s)";
  };

  const deleteFooterTemplate = () => {
    return (
      <>
        <hr />
        <div className="ul-grid__column">
          <div className="ul-grid__row">
            <div className="ul-grid__column -x50">
              <Button
                className={classes["yes-button"]}
                onClick={() =>
                  props.handleDeleteProjectLines(props.selectedProjectLines)
                }
              >
                Yes
              </Button>
            </div>
            <div className="ul-grid__column -x50">
              <Button
                className={`p-button-secondary ${classes["no-button"]}`}
                onClick={() => props.setShowDeleteModal(false)}
              >
                No
              </Button>
            </div>
            {/* <div className="column-fill"></div>
            <div className="column-fill"></div> */}
          </div>
        </div>
      </>
    );
  };

  const deleteModalContentTemplate = () => {
    return (
      <>
        <hr />
        <div className={`ul-grid__column ${classes["content"]}`}>
          <div className="ul-grid__column">
            Removing the selected Order Line(s) in this GMA WB Project will move
            the Order Line(s) back to Project Work Queue.
          </div>
          <br />
          <div className="ul-grid__column">Do you want to continue?</div>
        </div>
      </>
    );
  };

  return (
    <Dialog
      id="modalDialog"
      header={removeOrderLineHeader}
      style={{ width: "700px" }}
      visible={props.showDeleteModal}
      onHide={() => props.setShowDeleteModal(false)}
      footer={deleteFooterTemplate}
      closeOnEscape
      className={`${classes["dialog-close-button"]}`}
    >
      {deleteModalContentTemplate()}
    </Dialog>
  );
};

export default CDPDeleteModal;
