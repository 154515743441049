import RequestFactory from "./RequestFactory";

const certificateUrl = process.env.REACT_APP_CERTIFICATE_URL;

const BulkImportService = {
  downloadBulkImportTemplate: async () =>
    await RequestFactory.downloadGet(certificateUrl, `/bulkimports/template`),
  uploadBulkImportTemplate: async (formData, uploadProgressEvent) =>
    await RequestFactory.upload(
      certificateUrl,
      `/bulkimports`,
      formData,
      uploadProgressEvent
    ),
};

export default BulkImportService;
