import classes from "../GpiCopyFormModal.module.scss";

const CompanyInformation = ({ fieldValue }) => {
  return (
    <div className={classes["details-content-container"]}>
      <div className={classes["details-field"]}>
        UL Customer Party Site Number:{" "}
        <span>{fieldValue?.gPICompanyInfo?.partySiteNumber}</span>
      </div>
      <div className={classes["details-field"]}>
        Company Name: <span>{fieldValue?.gPICompanyInfo?.companyName}</span>
      </div>
      <div className={classes["details-field"]}>
        Street Address: <span>{fieldValue?.gPICompanyInfo?.streetAddress}</span>
      </div>
      <div className={classes["details-field"]}>
        City: <span>{fieldValue?.gPICompanyInfo?.city}</span>
      </div>
      <div className={classes["details-field"]}>
        State/Province:{" "}
        <span>{fieldValue?.gPICompanyInfo?.stateOrProvince}</span>
      </div>
      <div className={classes["details-field"]}>
        Zip Code: <span>{fieldValue?.gPICompanyInfo?.zipCode}</span>
      </div>
      <div className={classes["details-field"]}>
        Country: <span>{fieldValue?.gPICompanyInfo?.country}</span>
      </div>
      <div className={classes["details-field"]}>
        Contact: <span>{fieldValue?.contactName}</span>
      </div>
      <div className={classes["details-field"]}>
        Title: <span>{fieldValue?.titleAndPosition}</span>
      </div>
      <div className={classes["details-field"]}>
        Contact Number: <span>{fieldValue?.phoneNumber}</span>
      </div>
    </div>
  );
};

export default CompanyInformation;
