import {
  faAngleDown,
  faCopy,
  faFileLines,
  faHistory,
  faInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  removeToaster,
  selectToaster,
} from "../../features/projectTemplateMapping/projectTemplateMappingSlice";
import useToastr from "../../hooks/useToastr";
import { authProvider } from "../../providers/authProvider";
import TemplateService from "../../services/TemplateService";
import CustomOneULPaginator from "../../shared/custom-oneul-paginator/CustomOneULPaginator";
import TemplateListRequestModel from "../../shared/models/service-models/TemplateListRequest.model";
import { TemplateModel } from "../../shared/models/Template.model";
import { formatVersion } from "../../utils/helpers/version.helper";
import ProjectTemplateViewHistory from "../project-template-mapping/project-template-view-history/ProjectTemplateViewHistory";
import ProjectTemplateAddModal from "./project-template-add-modal/ProjectTemplateAddModal";
import ProjectTemplateCopyModal, {
  CopyModalProps,
} from "./project-template-copy-modal/ProjectTemplateCopyModal";
import ProjectTemplateDeleteModal from "./project-template-delete-modal/ProjectTemplateDeleteModal";
import classes from "./ProjectTemplateList.module.scss";
import ProjectTemplateListRefiners from "./refiners/ProjectTemplateListRefiners";
import DeliveryPathAndAssociatedSchemeModal from "./shared/enums/deliverypath-and-associatedscheme-modal/DeliveryPathAndAssociatedSchemeModal";
import FlexTemplateInformationPopUp from "./shared/enums/flex-template-information-popup/FlexTemplateInformationPopUp";
import { refinerRequestName } from "../../utils/constants/project-management.constants";
export interface DialogModalPropsModel {
  header?: string;
  content?: any;
}

const ProjectTemplateList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const toasterMessage = useSelector(selectToaster);

  let pageSizes: number[] = [50, 100, 200, 500, 1000];
  let loadLazyTimeout: any = null;

  const [disableJumpToInput, setDisableJumpToInput] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [templatesToDelete, setTemplatesToDelete] = useState<TemplateModel[]>(
    []
  );
  const [dialogProp, setDialogProp] = useState<DialogModalPropsModel>({});
  const [flexTemplateInfoProp, setFlexTemplateInfoProp] =
    useState<DialogModalPropsModel>({});
  const flexTemplateInfoOp = useRef<any>(null);
  const op = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [correlationId, setCorrelationId] = useState<string>();
  const { showSuccess, showError } = useToastr();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showCopyModal, setShowCopyModal] = useState<boolean>(false);
  const [copyModalProps, setCopyModalProps] = useState<CopyModalProps>();
  const [e2ETemplateName, setE2ETemplateName] = useState<string>("");

  const [selectedRecords, setSelectedRecords] = useState<TemplateModel[]>([]);
  const [templates, setTemplates] = useState<TemplateModel[]>([]);
  const [request, setRequest] = useState<TemplateListRequestModel>(
    sessionStorage.getItem(refinerRequestName) != null
      ? { ...JSON.parse(sessionStorage.getItem(refinerRequestName)!) }
      : {
          skip: 1,
          take: pageSizes[0],
        }
  );

  const columns: any = [
    {
      field: "name",
      header: "GMA WB Templates",
      width: "300px",
    },
    {
      field: "flexTemplateName",
      header: "FLEX Template",
      width: "300px",
    },
    {
      field: "certificateScheme",
      header: "Certificate Scheme",
      width: "300px",
    },
    {
      field: "deliveryPath",
      header: "Delivery Path / Agent",
      width: "300px",
    },
    {
      field: "version",
      header: "GMA WB Template Version",
      width: "230px",
    },
    {
      field: "templateStatus",
      header: "GMA WB Template Status",
      width: "230px",
    },
    {
      field: "publishedDate",
      header: "Published Date",
      width: "200px",
    },
    {
      field: "createdBy",
      header: "GMA WB Template Created by",
      width: "300px",
    },
    {
      field: "createdDateUtc",
      header: "GMA WB Template Created on",
      width: "300px",
    },
    {
      field: "modifiedBy",
      header: "GMA WB Template Updated by",
      width: "300px",
    },
    {
      field: "modifiedDateUtc",
      header: "GMA WB Template Updated on",
      width: "300px",
    },
  ];

  const dynamicColumns = columns.map((col: any) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={(e: TemplateModel, opt: ColumnBodyOptions) =>
          col.field === "version"
            ? renderVersionTemplate(e)
            : ["certificateScheme", "deliveryPath"].includes(col.field)
            ? renderColumnWithDialogTemplate(e[col.field], col.header)
            : renderTooltipTemplate(e, col.field, opt.rowIndex)
        }
      />
    );
  });

  window.onbeforeunload = function (e) {
    sessionStorage.removeItem(refinerRequestName);
  };

  const location = useLocation();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      loadLazyData();
    }

    return () => {
      mounted = false;
    };
  }, [request]);

  const loadLazyData = () => {
    setLoading(true);
    setDisableJumpToInput(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      getProjectTemplateList();
    }, Math.random() * 1000 + 250);
  };

  const dateFormatter = (date: string | Date) => {
    return date ? moment(date).format("DD MMM YYYY") : "";
  };

  const getProjectTemplateList = () => {
    TemplateService.getProjectTemplateList(request)
      .then((result) => {
        if (result.isSuccess) {
          let records = result.data.records;
          records.forEach((template: TemplateModel) => {
            template.createdDateUtc = dateFormatter(template.createdDateUtc!);
            template.publishedDate = dateFormatter(template.publishedDate!);
            template.modifiedDateUtc = dateFormatter(template.modifiedDateUtc!);
          });
          setTemplates(records);
          setTotalPages(result.data.totalPages);
          setTotalRecords(result.data.total);
          setFirstIndex(result.data.firstIndex);
          setLastIndex(result.data.lastIndex);
          setHasNextPage(result.data.hasNextPage);
          setHasPreviousPage(result.data.hasPreviousPage);
          setLoading(false);
          setDisableJumpToInput(false);
        } else {
          showError("Error!", result.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleDeleteProjectTemplateList = (templates: TemplateModel[]) => {
    setLoading(true);
    let ids = templates.length > 0 ? templates.map((x) => x.id!) : [];
    if (ids.length === 0) return;
    TemplateService.deleteTemplates(ids)
      .then((result) => {
        if (result.isSuccess) {
          setTemplatesToDelete([]);
          setRequest({ ...request });
          showSuccess("Success!", result.message);
          setSelectedRecords(
            selectedRecords.filter((x) => !ids.includes(x.id!))
          );
        } else {
          showError("Error!", result.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleSelectedRecordsForDeletion = (templates: TemplateModel[]) => {
    setTemplatesToDelete(templates);
    setShowDeleteModal(true);
  };

  const copyTemplate = (data: any) => {
    const templateData: CopyModalProps = {
      e2eTemplateId: data.id,
      version: data.version,
      status: data.templateStatus,
      flexTemplateName: data.flexTemplateName,
      flexTemplateId: data.flexTemplateId,
      flexCorrelationId: data.flexCorrelationId,
    };

    setE2ETemplateName("");
    setCopyModalProps(templateData);
    setShowCopyModal(true);
  };

  const renderColumnWithDialogTemplate = (content: any, header: string) => {
    const value = content
      .filter((item: string) => item !== null)
      .sort((a: string, b: string) => {
        return a.localeCompare(b);
      })
      .join(", ");
    return (
      <div className="ul-grid__column">
        {" "}
        <div className={`${classes["centerContents"]} ul-grid__row -wrap`}>
          {" "}
          {
            <>
              {" "}
              <div
                title={value}
                className={`${classes["defaultCursor"]} ${classes["elipsisContainer"]} ul-grid__column -x90`}
              >
                {" "}
                {value}
              </div>{" "}
              <div className={`ul-grid__column -x10`}>
                {" "}
                {value.trim() && (
                  <Button
                    title={`Click to view all ${header}s`}
                    className={`p-button-secondary p-button-rounded ${classes["angleDownIcon"]}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      op?.current.toggle(e);
                      setDialogProp({
                        header: header,
                        content: content,
                      });
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faAngleDown} size="sm" />{" "}
                  </Button>
                )}
              </div>{" "}
            </>
          }
        </div>{" "}
      </div>
    );
  };

  const actionTemplate = (data: any, opt: ColumnBodyOptions) => {
    return (
      <div
        className={`col-xs-6 col-sm-6 col-lg-6 col-md-6  ${
          "table-templates-row-actions-" + opt.rowIndex
        } `}
      >
        <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6">
          <Button
            className="p-button-rounded p-button-secondary p-button-text copy-btn"
            title="Copy GMA WB Template"
            onClick={(e) => {
              e.currentTarget.blur();
              copyTemplate(data);
            }}
          >
            <FontAwesomeIcon icon={faCopy} className="fa-lg" />
          </Button>
          <Button
            className="p-button-rounded p-button-secondary p-button-text delete-btn"
            title="Delete Template"
            onClick={(e) => {
              e.currentTarget.blur();
              e.stopPropagation();
              handleSelectedRecordsForDeletion([data]);
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="fa-lg" />
          </Button>
        </div>
      </div>
    );
  };

  const renderVersionTemplate = (data: any) => {
    const previewVersionTemplate = (e: any) => {
      setCorrelationId(data.correlationId);
      setVisibility(true);
    };

    const formattedVersion = formatVersion(data.version);

    return (
      <div className="ul-grid__column">
        <div className={`${classes["centerContents"]} ul-grid__row -wrap`}>
          <div className={`${classes["defaultCursor"]} ul-grid__column -x50`}>
            <span title={formattedVersion}>{formattedVersion}</span>
          </div>
          <div className="ul-grid__column -x50">
            <Button
              title="View Template Version History"
              className={`${classes["floatRight"]} p-button-rounded p-button-secondary p-button-text`}
              onClick={(e) => {
                e.stopPropagation();
                e.currentTarget.blur();
                previewVersionTemplate(e);
              }}
            >
              <FontAwesomeIcon icon={faHistory} className="fa-lg" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const handleDoubleClick = (data: any) => {
    sessionStorage.setItem(refinerRequestName, JSON.stringify(request));
    history.push(`/template-admin-mapping?id=${data.data.id}`);
  };

  const pageSizeOnChange = (data: any) => {
    setRequest({
      ...request,
      skip: 1,
      take: data.target.value,
    });
  };

  const pageOnChange = (data: number) => {
    setRequest({
      ...request,
      skip: request.skip! + data,
    });
  };

  const manualPageOnChange = (data: any) => {
    let value = data.target.value.replace(/\D/g, "");
    let skip =
      value === "" || value === "0" ? request.skip! : Number.parseInt(value);
    if (skip <= totalPages && skip !== request.skip) {
      setRequest({
        ...request,
        skip: skip,
      });
    }
  };

  const ToolbarTemplate = () => {
    return (
      <div className="ul-grid__row -wrap">
        <div className="ul-grid__column">
          <Button
            className={`${classes["floatRight"]} p-button-rounded p-button-secondary p-button-text`}
            title="Delete Templates"
            onClick={(e) => {
              e.currentTarget.blur();
              handleSelectedRecordsForDeletion(selectedRecords);
            }}
            disabled={selectedRecords.length > 0 ? false : true}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className={`${classes["canBeDisabled"]} fa-lg`}
            />
          </Button>
          <Button
            title="Create new GMA WB Template"
            className={`${classes["floatRight"]} p-button-rounded p-button-secondary p-button-text`}
            onClick={(e) => {
              e.currentTarget.blur();

              const dateTimeNow = moment().format("MMDDyyyy");
              setE2ETemplateName(`E2E Template_New_${dateTimeNow}`);
              setShowAddModal(true);
            }}
          >
            <FontAwesomeIcon icon={faFileLines} className="fa-lg" />
          </Button>
        </div>
      </div>
    );
  };

  const handleSetFlexTemplateInfoProp = (e: any, flexTemplateId: string) => {
    e.currentTarget.blur();
    flexTemplateInfoOp?.current.toggle(e);
    setFlexTemplateInfoProp({
      content: flexTemplateId,
    });
  };

  const renderTooltipTemplate = (
    prop: any,
    field: string,
    rowIndex: number
  ) => (
    <div className="ul-grid__column">
      <div className={`${classes["centerContents"]} ul-grid__row -wrap`}>
        {field === "flexTemplateName" ? (
          <>
            <div
              title={prop[field]}
              className={`${classes["defaultCursor"]} ${classes["elipsisContainer"]} ul-grid__column -x90`}
            >
              {prop[field]}
            </div>
            <div className={`ul-grid__column -x10`}>
              <Button
                title="View FLEX Template information"
                className={`p-button-secondary p-button-rounded ${classes["angleDownIcon"]}`}
                onClick={(e) =>
                  handleSetFlexTemplateInfoProp(e, prop.flexTemplateId)
                }
              >
                <FontAwesomeIcon icon={faInfo} size="sm" />
              </Button>
            </div>
          </>
        ) : (
          <div
            title={prop[field]}
            className={`${classes["defaultCursor"]} ${
              classes["elipsisContainer"]
            } ${
              "table-templates-row-" + field + "-" + rowIndex
            } ul-grid__column`}
          >
            {prop[field]}
          </div>
        )}
      </div>
    </div>
  );

  const setUpdatedRequest = (data: any) => {
    setRequest(data);
  };

  const onCloseDialog = () => {
    setVisibility(false);
  };

  const ProjectTemplateGrid = () => {
    return (
      <div>
        <FlexTemplateInformationPopUp
          op={flexTemplateInfoOp}
          content={flexTemplateInfoProp.content}
        />
        <DeliveryPathAndAssociatedSchemeModal
          op={op}
          header={dialogProp.header}
          content={dialogProp.content}
        />

        <ProjectTemplateAddModal
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          e2ETemplateName={e2ETemplateName}
          setE2ETemplateName={setE2ETemplateName}
        />

        {copyModalProps && (
          <ProjectTemplateCopyModal
            showCopyModal={showCopyModal}
            setShowCopyModal={setShowCopyModal}
            props={copyModalProps}
            e2ETemplateName={e2ETemplateName}
            setE2ETemplateName={setE2ETemplateName}
          />
        )}

        <ProjectTemplateDeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          templatesToDelete={templatesToDelete}
          setTemplatesToDelete={setTemplatesToDelete}
          handleDeleteProjectTemplateList={handleDeleteProjectTemplateList}
        />

        <ProjectTemplateViewHistory
          isVisible={visibility}
          onCloseDialog={onCloseDialog}
          correlationId={correlationId}
          target={"dialog-target"}
        />
        <div className={`ul-grid__column`}>
          <div className="ul-grid__row -wrap">
            <div className="ul-grid__column">
              <ToolbarTemplate />
            </div>
            <div
              className={`ul-grid__column ${classes["data-table-template-list"]}`}
            >
              <DataTable
                emptyMessage="No result found"
                dataKey="id"
                value={templates}
                tableClassName={`${classes["dataTableHeight"]}`}
                loading={loading}
                selection={selectedRecords}
                onSelectionChange={(e) => {
                  setSelectedRecords(e.value);
                }}
                onRowDoubleClick={handleDoubleClick}
                scrollHeight="calc(102vh - 162px)"
                scrollable
                resizableColumns
                size="small"
                selectionMode="single"
                selectionPageOnly
                scrollDirection="both"
                showGridlines
              >
                <Column
                  selectionMode="multiple"
                  frozen
                  alignFrozen="left"
                  style={{ width: "3em", zIndex: 1 }}
                ></Column>
                {dynamicColumns}
                <Column
                  frozen
                  alignFrozen="right"
                  body={(data, opt: ColumnBodyOptions) =>
                    actionTemplate(data, opt)
                  }
                  style={{ width: "150px" }}
                />
              </DataTable>
            </div>
            <div className={`ul-grid__column ${classes["paginationPosition"]}`}>
              <CustomOneULPaginator
                pageSizes={pageSizes}
                skip={request.skip!}
                take={request.take!}
                firstIndex={firstIndex}
                lastIndex={lastIndex}
                totalRecords={totalRecords}
                totalPages={totalPages}
                hasPreviousPage={hasPreviousPage}
                hasNextPage={hasNextPage}
                pageSizeOnChange={pageSizeOnChange}
                manualPageOnChange={manualPageOnChange}
                pageOnChange={pageOnChange}
                disableJumpToInput={disableJumpToInput}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes["container"]}>
      <ProjectTemplateListRefiners
        request={request}
        setRequest={setUpdatedRequest}
      ></ProjectTemplateListRefiners>
      <div className={classes["main"]}>{ProjectTemplateGrid()}</div>
    </div>
  );
};

export default ProjectTemplateList;
