import React from 'react'
import HandlerType from '../../enums/handler-type'
import { Tooltip } from 'primereact/tooltip'
import classes from "./PmrHandlerTooltip.module.scss";

interface PmrHandlerTooltipProps{
    handlerType: HandlerType | null;
    target: string;
}

const PmrHandlerTooltip = (props: PmrHandlerTooltipProps) => {

    const RenderTooltip = () => {
        if(props.handlerType === HandlerType.FlexHandler) {
            return (
                <Tooltip
                position="right"
                mouseTrack
                className={`${classes["tooltip"]}`}
                target={props.target}
              >
                <div className={classes["tooltip-content"]}>
                  <div>
                    Assigning the FLEX Handler applies to the entire Order Line,
                    as well as other order lines belonging to the same FLEX
                    Project. This includes all associated FLEX Tasks and GMAWB
                    Milestones within the order lines. This value will be
                    reflected in the FLEX system.
                  </div>
                </div>
              </Tooltip>
            )
        }

        if(props.handlerType === HandlerType.GmaWBHandler) {
            return (
                <Tooltip
                position="right"
                mouseTrack
                className={`${classes["tooltip"]}`}
                target={props.target}
              >
                <div className={classes["tooltip-content"]}>
                  <div>
                    GMAWB Handler assignments apply to the entire order line.
                    Reassigning the GMAWB Handler role to a user automatically makes
                    them Milestone Handlers for these milestones:
                  </div>
    
                  <ul>
                    <li>Customer: Send All Documents to UL</li>
                    <li>Customer: Send All Samples to UL</li>
                    <li>UL: Review and Prepare Application Package</li>
                    <li>UL: Submit to Authority/Authority Review</li>
                    <li>UL: Submit Samples to Test Lab/In-Country Testing</li>
                    <li>
                      UL: Payment (regardless of order of payment; pre-payment,
                      payment before certification, payment after certification)
                    </li>
                    <li>UL: Sample Return</li>
                  </ul>
                </div>
              </Tooltip>
            )
        }
        return <></>
    }
  
    return (
        <RenderTooltip/>     
  )
}

export default PmrHandlerTooltip