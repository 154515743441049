import classes from "../GpiCopyFormModal.module.scss";
import CompanyInformation from "./CompanyInformationTemplate";

const FactorySiteInformation = ({ fieldValue }) => {
  return (
    <>
      {fieldValue?.map((data, i) => {
        return (
          <div className={classes["details-content-container"]}>
            <div className={classes["details-header"]}>Factory {i + 1}</div>
            <CompanyInformation fieldValue={data.gPICompanyContactInfo} />
            <hr />
          </div>
        );
      })}
    </>
  );
};

export default FactorySiteInformation;
