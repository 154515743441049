import "./SelectTemplate.scss";
import { MultiSelect } from "primereact/multiselect";
import ProductTypeService from "./../../../../../services/ProductTypeService";
import { useState } from "react";
import { useEffect } from "react";
import CustomerNameLookup from "../../../../../shared/model-search/customer-name-lookup/CustomerNameLookup";
import saveAs from "file-saver";
import Loader from "../../../../../components/Loader";
import useToastr from "./../../../../../hooks/useToastr";

import classes from "./SelectTemplate.module.scss"
import CustomerNameLookupDialog from "../../../../../shared/model-search/customer-name-lookup/CustomerNameLookupDialog";
const SelectTemplate = ({}) => {
  const [productTypesUploadList, setProductTypesUploadTemplate] = useState([]);
  const [isLoadingProductTypes, setIsLoadingProductTypes] = useState(true);
  const [isDownloadingTemplates, setIsDownloadingTemplates] = useState(false);
  const [showCustomerLookup, setShowCustomerLookup] = useState(false);
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const { showSuccess, showError } = useToastr();

  useEffect(() => {
    getProductTypesUploadTemplate(setProductTypesUploadTemplate);
  }, []);

  const getProductTypesUploadTemplate = async (onGetCallback) => {
    setIsLoadingProductTypes(true);
    const result =
      await ProductTypeService.getProductTypeBulkUploadTemplatesFromSharePoint();
    //const result = await ProductTypeService.getProductTypesUploadTemplate();
    if (result && Array.isArray(result) && result.length > 0) {
      onGetCallback(result);
    }
    setIsLoadingProductTypes(false);
  };

  const onChangeSelected = (e) => {
    setSelectedProductTypes(e.value);
  };

  const onCustomerLookupClick = (e) => {
    setShowCustomerLookup(true);
  };

  const onHideCustomerNameLookup = () => {
    setShowCustomerLookup(false);
  };

  const onDownloadTemplateClick = async (e) => {
    setIsDownloadingTemplates(true);
    ProductTypeService.downloadProductTypeUploadTemplate(
      selectedProductTypes.map((p) => p.name)
    )
      .then((response) => {
        if (!response) {
          showError("Error!", "File(s) failed to download.");
          return;
        }

        const current = new Date();
        if (selectedProductTypes.length > 1) {
          const fileName = `Product Bulk Upload Templates_${(
            "0" +
            (current.getMonth() + 1)
          ).slice(-2)}${("0" + current.getDate()).slice(-2)}${current
            .getFullYear()
            .toString()
            .slice(-2)}${("0" + current.getHours()).slice(-2)}${(
            "0" + current.getMinutes()
          ).slice(-2)}${("0" + current.getSeconds()).slice(-2)}.zip`;
          saveAs(response, fileName);
          showSuccess("Success!", "File(s) downloaded successfully.");
        } else if (selectedProductTypes.length === 1) {
          saveAs(response, selectedProductTypes[0].name);

          showSuccess("Success!", "File(s) downloaded successfully.");
        }
      })
      .finally(() => {
        setIsDownloadingTemplates(false);
      });
  };

  const multiSelectPanelFooterTemplate = () => {
    const selectedItems = selectedProductTypes;
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="items-selected">
        <b>{length}</b> item{length > 1 ? "s" : ""} selected.
      </div>
    );
  };

  return (
    <div>
      <CustomerNameLookupDialog
        displayCustomerNameLookup={showCustomerLookup}
        useCloseButtonOnly={true}
        onHideCustomerNameLookup={onHideCustomerNameLookup}
        // setPartySiteNumber={setPartySiteNumber}
      />
      <p className="select-template-header">
        Please select a product type in order to download its bulk import
        template. Multiple selections will download in a .zip file
      </p>

      <div className="picker-container">
        <div class="product-types-container">
          <MultiSelect
            resetFilterOnHide
            value={selectedProductTypes}
            options={productTypesUploadList}
            disabled={isLoadingProductTypes}
            filterPlaceholder="Search"
            onChange={onChangeSelected}
            optionLabel="formattedName"
            placeholder="Select"
            display="chip"
            panelFooterTemplate={multiSelectPanelFooterTemplate}
            filter
          />
          <Loader data={isLoadingProductTypes} />
        </div>

        <div className="download-button-container">
          <button
            className="ul-button -app-tertiary -medium -icon search-icon-btn"
            aria-label="Bulk Import"
            onClick={onDownloadTemplateClick}
            disabled={
              isLoadingProductTypes ||
              isDownloadingTemplates ||
              selectedProductTypes?.length === 0
            }
          >
            Download
          </button>
          <Loader data={isDownloadingTemplates} />
        </div>
      </div>

      <p className="">
        Use the
        <button className={classes["link-btn"]} onClick={onCustomerLookupClick}>
          customer lookup tool
        </button>{" "}
        to check the PSNs to be used in the file.
      </p>
      <p className="">
        Click "Continue" if you already have a file for upload.
      </p>
    </div>
  );
};

export default SelectTemplate;
