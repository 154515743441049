import { downloadFile } from "../utils/helpers/file.helpers";
import RequestFactory from "./RequestFactory";

const projectUrl = process.env.REACT_APP_PROJECT_URL;
const documentUrl = process.env.REACT_APP_DOCUMENT_URL;

const DocumentKnowledgeService = {
  getDropdowns: async (data) =>
    await RequestFactory.post(
      projectUrl,
      "/DeliveryKnowledge/delivery-knowledge-dropdown",
      data
    ),

  getDeliveryPathInfoDataList: async (data) =>
    await RequestFactory.post(
      projectUrl,
      "/deliveryknowledge/delivery-knowledge-devpath-get",
      data
    ),

  getCommonDocsDataList: async (data) =>
    await RequestFactory.post(
      projectUrl,
      "/deliveryknowledge/delivery-knowledge-common-get",
      data
    ),

  getCountrySpecificDataList: async (data) =>
    await RequestFactory.post(
      projectUrl,
      "/deliveryknowledge/delivery-knowledge-countryspecific-get",
      data
    ),

  getTestSampleDataList: async (data) =>
    await RequestFactory.post(
      projectUrl,
      "/deliveryknowledge/delivery-knowledge-testsample-get",
      data
    ),

  getLocalRepDataList: async (data) =>
    await RequestFactory.post(
      projectUrl,
      "/deliveryknowledge/delivery-knowledge-localrep-get",
      data
    ),

  generateExport: async (data) =>
    await RequestFactory.download(
      documentUrl,
      "/documentknowledge/export",
      data
    ),

  downloadR2CDocument: async (id, filename) => {
    await RequestFactory.downloadRaw(
      documentUrl,
      "/documentknowledge/download-r2c-document",
      {
        id,
      }
    ).then((result) => {
      downloadFile(result, filename);
    });
  },
};

export default DocumentKnowledgeService;
