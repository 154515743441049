import jwt_decode from "jwt-decode";

const GpiAuthService = {
  getGpiAuthInfo: () => {
    if (!sessionStorage.getItem("gpi_token")) return;

    var result: any = jwt_decode(sessionStorage.getItem("gpi_token")!);

    return {
      ...result,
      isExpired: result.exp < (Date.now() / 1000),
    };
  },
};

export default GpiAuthService;
