import React from "react";
import CustomerInformation from "../shared/CustomerInformation/CustomerInformation";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAgentInformation,
  updateAgentInformation,
  selectIsReadOnly,
} from "../../../../features/generalProductInformation/generalProductInformationSlice";
import { Checkbox } from "primereact/checkbox";

const AgentInformation = ({ disableIntegration }) => {
  const dispatch = useDispatch();
  const agentInformationValues = useSelector(selectAgentInformation);
  const isReadOnly = useSelector(selectIsReadOnly);

  const handleChangeCustomerInformation = (customerInformation) => {
    dispatch(
      updateAgentInformation({
        ...agentInformationValues,
        ...customerInformation,
      })
    );
  };

  const handleChange = (field, value) => {
    dispatch(
      updateAgentInformation({
        ...agentInformationValues,
        [field]: value,
      })
    );
  };

  return (
    <div className="form-section">
      <div className="form-section-header">
        <h3 className="form-section-title">
          Agent Information
          <small>( Company that acts on behalf of the Applicant. )</small>
        </h3>
      </div>

      <div className="form-section-content">
        <div className="question-w-checkbox-container">
          <Checkbox
            inputId="cbIsAgentInformationAvailable"
            checked={agentInformationValues.isAgentInformationAvailable}
            onChange={({ checked }) =>
              handleChange("isAgentInformationAvailable", checked)
            }
            disabled={isReadOnly}
          />
          <label htmlFor="cbIsAgentInformationAvailable">
            Agent information available
          </label>
        </div>

        {agentInformationValues.isAgentInformationAvailable && (
          <CustomerInformation
            disableIntegration={disableIntegration}
            onChange={handleChangeCustomerInformation}
            customerInformation={agentInformationValues}
            section="agent"
          />
        )}
      </div>
    </div>
  );
};

export default AgentInformation;
