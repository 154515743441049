import RequestFactory from "./RequestFactory";

const projectUrl = process.env.REACT_APP_PROJECT_URL;

const ProjectManagementService = {
  autotimestamp: async (data) =>
    await RequestFactory.post(
      projectUrl,
      `/milestoneview/document-auto-timestamp`,
      data
    ),
  getFlexTotalHours: async (data) =>
    await RequestFactory.post(projectUrl, `/tempus/get-flex-total-hours`, data),
};

export default ProjectManagementService;
