import { createSlice } from "@reduxjs/toolkit";
import { ProjectTemplateList, ProjectTemplateListWrapper } from "./projectTemplateListSliceInterface";

const initialState: ProjectTemplateList = {
    Data: [],
    Request: {
        skip: 1,
        take: 15,
    }
}

export const ProjectTemplatePropsSlice = createSlice({
    name: "projectTemplateList",
    initialState: initialState as ProjectTemplateList,
    reducers: {
        updateTemplateList: (state, action) => {
            state.Data = action.payload;
        },
        updateTemplateListRequest: (state, action) => {
            state.Request = action.payload;
        }
    }
});

export const {
    updateTemplateList,
    updateTemplateListRequest,
} = ProjectTemplatePropsSlice.actions;

export const selectProjectTemplateList = (state: ProjectTemplateListWrapper) => state.projectTemplateList.Data;
export const selectProjectTemplateListRequest = (state: ProjectTemplateListWrapper) => state.projectTemplateList.Request;

export default ProjectTemplatePropsSlice.reducer;