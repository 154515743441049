import React from "react";
import ConfirmationModalV2 from "../../../../../shared/confirmation-modal-v2/ConfirmationModalV2";
import { Button } from "primereact/button";
import classes from "./RemoveTimestampConfirmationDialog.module.scss";

interface RemoveTimestampConfirmationDialogProps {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmLoading: boolean;
}

const RemoveTimestampConfirmationDialog = (
  props: RemoveTimestampConfirmationDialogProps
) => {
  const dialogBodyTemplate = (
    <div className={`${classes["modal-body-content"]}`}>
      <p className={`${classes["modal-body-text"]}`}>
        Are you sure you want to remove the timestamp for this selected
        Milestone?
      </p>
   
      <p className={`${classes["modal-body-text"]}`}>
        Note: This will update the status of the GMAWB Milestone from "Complete"
        to "In Progress" and will remove its Completion Date.
      </p>
    </div>
  );

  const footerTemplate = (
    <>
      <Button disabled={props.confirmLoading} label={"No"} onClick={props.onCancel} className={classes["btn-no"]}/>

      <Button label={"Yes"} onClick={props.onConfirm} loading={props.confirmLoading} className={classes["btn-yes"]}/>
    </>
  );


  return (
    <ConfirmationModalV2
    bodyClassName={`${classes["modal-body"]}`}
      style={{ width: "30vw" }}
      visible={props.visible}
      onClose={props.onCancel}
      headerDisplay={"Remove Timestamp Confirmation"}
      confirmationMessageHTML={dialogBodyTemplate}
      customFooter={footerTemplate}
    ></ConfirmationModalV2>
  );
};

export default RemoveTimestampConfirmationDialog;
