import clsx from "clsx";
import classes from "./ProjectOrderlineStatusOnHoldReasonDialog.module.scss";
import { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import FlexIntegrationService from "../../../../../services/FlexIntegrationService";
import { FlexProjectStatusOnHoldReason } from "../../interfaces/pmr-e2e-flex-project-status-onhold-reason-model";
import { Tooltip } from "primereact/tooltip";

interface ProjectOrderlineStatusOnHoldReasonDialogProps {
  closeModal: () => void;
  visible: boolean;
  handleSelectOnHoldReason: (
    onholdreason: any,
    flexprojectonholdreason: any
  ) => void;
  flexProjectToUpdate: any[];
}

const ProjectOrderlineStatusOnHoldReasonDialog = (
  props: ProjectOrderlineStatusOnHoldReasonDialogProps
) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reasons, setReasons] = useState(new Array());
  const [selectedReason, setSelectedReason] = useState(null);
  const [isOnHoldFlexProjectReasonShow, setIsOnHoldFlexProjectReasonShow] =
    useState(true);
  const [flexProjectReasons, setFlexProjectReasons] = useState(new Array());
  const [selectedFlexProjectReason, setSelectedFlexProjectReason] =
    useState<FlexProjectStatusOnHoldReason | null>(null);
  useEffect(() => {
    if (props.visible) {
      loadData();
    }
  }, [props.visible]);

  const loadData = async () => {
    setIsLoading(true);
    let [projectLineReasonResult, flexProjectReasonResult] = await Promise.all([
      FlexIntegrationService.getProjectLineOnHoldReasons(),
      FlexIntegrationService.getFlexProjectOnHoldReasons(),
    ]);
    if (projectLineReasonResult) setReasons(projectLineReasonResult);
    if (flexProjectReasonResult) setFlexProjectReasons(flexProjectReasonResult);

    setIsLoading(false);
  };
  const hideModal = () => {
    setSelectedFlexProjectReason(null);
    setSelectedReason(null);
    props.closeModal();
  };
  const onReasonChange = (e: DropdownChangeParams) => {
    setSelectedReason(e.value);
  };
  const onFlexProjectReasonChange = (e: DropdownChangeParams) => {
    setSelectedFlexProjectReason(e.value);
  };
  const save = () => {
    props.handleSelectOnHoldReason(selectedReason, selectedFlexProjectReason);
    hideModal();
  };
  const isConfirmButtonDisabled = () => {
    return isSaving || isLoading || isOrderlineReasonNotValid();
  };
  const isOrderlineReasonNotValid = () => {
    return !selectedReason;
  };
  const confirmButtonToolTip = () => {
    return isOrderlineReasonNotValid()
      ? "Please select a reason for putting the order line 'On Hold'."
      : "";
  };
  const renderFooter = (
    <>
      <hr className="modal-footer-hr" />
      <div className={`${classes["dialog-footer"]} ${classes["center-items"]}`}>
        <button
          className={`ul-button -medium ${classes["dialog-cancel-btn"]}`}
          onClick={() => {
            hideModal();
          }}
        >
          CANCEL
        </button>
        <button
          className={`ul-button -medium ${classes["dialog-save-btn"]} confirm-button-target`}
          disabled={isConfirmButtonDisabled()}
          onClick={save}
        >
          CONFIRM
        </button>
        <Tooltip
          target={`.confirm-button-target`}
          className="custom-tooltip"
          my="left top"
          at="left bottom"
          showOnDisabled={true}
        >
          {confirmButtonToolTip()}
        </Tooltip>
      </div>
    </>
  );
  const renderBody = () => {
    return (
      <>
        <div>
          <div>
            Please select a reason from the list below:
            <span className={classes["error"]}>*</span>
          </div>
          <Dropdown
            value={selectedReason}
            options={reasons}
            optionLabel="fulFillmentStatusDetail"
            onChange={onReasonChange}
            className={classes["custom-dropdown"]}
            style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}
            placeholder="Reason for On Hold"
            disabled={isLoading}
            showClear
          />
        </div>
        {isOnHoldFlexProjectReasonShow && (
          <>
            <div>
              <hr className={classes["hr"]}></hr>
            </div>
            <div style={{ marginTop: "1rem" }}>
              Please select a reason that will apply to the project list down
              below:
            </div>
            <div style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>
              • Project Number {renderFlexProjectForUpdate()}
            </div>
            <div>
              <Dropdown
                value={selectedFlexProjectReason}
                options={flexProjectReasons}
                optionLabel="projectStatusReason"
                onChange={onFlexProjectReasonChange}
                className={classes["custom-dropdown"]}
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
                placeholder="Select FLEX Project 'On Hold' reason"
                showClear
              />
            </div>
          </>
        )}
      </>
    );
  };
  const renderFlexProjectForUpdate = () => {
    return (
      <>
        {props.flexProjectToUpdate
          .map((item) => item.flexProjectNumber)
          .join(", ")}
      </>
    );
  };
  return (
    <>
      <Dialog
        className={classes["custom-dialog"]}
        header={`Set Order Line to 'On Hold'`}
        footer={renderFooter}
        draggable={false}
        visible={props.visible}
        onHide={hideModal}
        style={{ width: "30vw" }}
      >
        <hr className="modal-header-hr" />
        <div className={classes["body-container"]}>
          <div
            style={{
              display: isSaving || isLoading ? "none" : "",
            }}
          >
            {renderBody()}
          </div>
          {(isSaving || isLoading) && (
            <div className={classes["loading-div"]}>
              <div>
                {isSaving && `Saving`} {isLoading && `Loading`} ......
              </div>
              <div>
                <svg className="ul-progressCircle -medium">
                  <circle
                    className="ul-progressCircle__outer"
                    cx="50%"
                    cy="50%"
                    r="35%"
                  />
                  <circle
                    className="ul-progressCircle__inner"
                    cx="50%"
                    cy="50%"
                    r="35%"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};
export default ProjectOrderlineStatusOnHoldReasonDialog;
