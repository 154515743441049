export const downloadFile = (result, fileName) => {
  if (!fileName) {
    const contentDisposition = result.headers["content-disposition"];
    const patternToExtractFilename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const match = patternToExtractFilename.exec(contentDisposition);

    if (match) fileName = match[1].replaceAll('"', "");
  }

  const a = document.createElement("a");
  const url = window.URL.createObjectURL(result.data);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const isValidFilename = (filename) => {
  const invalidChars = /[\/:*?"<>|]/;

  return !invalidChars.test(filename);
};
