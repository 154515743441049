export const parseToString = (data: any) => {
  let response = "";
  if (data && typeof data === "object" && Object.keys(data).length != 0) {
    response = JSON.stringify(data);
  }
  return response;
};

export const randomString = () => {
  return (+new Date()).toString();
};
