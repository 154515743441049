import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classes from "./ProjectCollaboratorAssignmentDialog.module.scss";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { E2EUserPaginatedModel } from "../../../../../shared/models/E2EUserPaginatedModel";
import GMAWBUsersService from "../../../../../services/GMAWBUsersService";
import ProjectCollaboratorAssignmentDropdown from "./project-collaborator-assignment-dropdown/ProjectCollaboratorAssignmentDropdown";
import { useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "primereact/tooltip";
import useToastr from "../../../../../hooks/useToastr";
import PmrE2EProjectTaskHandler from "../../interfaces/pmr-e2e-project-task-handlers";
import HandlerType from "../../enums/handler-type";
import { selectShowFlexTasks } from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
import {
  updateFlexTaskOrderLineContributor,
  updateFlexTaskMilestoneOrderLineContributor,
  upsertProjectFlexTasksContributors,
  updateProjectLevelContributors,
} from "../../../../../features/projectManagement/projectManagementSlice";
import ProjectService from "../../../../../services/ProjectService";
import PmrE2EProjectTaskModel, {
  PmrE2EProjectTaskModelList,
} from "../../interfaces/pmr-e2e-project-task-model";

interface ProjectCollaboratorAssignmentDialogProps {
  e2EProjectId: string;
  workBreakdownStructure: number;
  onClose: () => void;
  show: boolean;
  selectedE2EProjectLineFlexTask:
    | {
        e2EProjectLineFlexTaskId: string;
        collaborators: PmrE2EProjectTaskHandler[];
        isGmaTcTask: boolean;
      }
    | undefined;
  e2EProjectLineMilestoneId?: string;
  e2EProjectLineId: string;
  projectNumber?: string;
  isCoHandlerMilestone: boolean;
}

interface ProjectCollaboratorsDetails {
  e2eProjectId?: string;
  e2EProjectLineFlexTaskId?: string;
  e2EProjectLineMilestoneId?: string;
  projectNumber?: string;
  flexHandlerDetails?: E2EUserPaginatedModel;
  gmaWbHandlerDetails?: E2EUserPaginatedModel;
  taskHandlerDetails?: E2EUserPaginatedModel;
  milestoneHandlerDetails?: E2EUserPaginatedModel;
}

export interface ProjectUserSource {
  currentSkip: number;
  currentTake: number;
  currentSearchPhrase: string;
  data: E2EUserPaginatedModel[];
}

interface DropdownLoading {
  flexDropdownLoading: boolean;
  gmawbDropdownLoading: boolean;
  taskHandlerDropdownLoading: boolean;
  milestoneHandlerDropdownLoading: boolean;
}

const ProjectCollaboratorAssignmentDialog = (
  props: ProjectCollaboratorAssignmentDialogProps
) => {
  const initDropdownValues: ProjectUserSource = {
    currentSkip: 0,
    currentTake: 5,
    currentSearchPhrase: "",
    data: [],
  };
  const showFlexTasks = useSelector(selectShowFlexTasks);
  const { showSuccess } = useToastr();
  const dispatch = useDispatch();

  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

  const [flexHandlerDropdownOptions, setFlexHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);
  const [gmawbHandlerDropdownOptions, setGmawbHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);
  const [taskHandlerDropdownOptions, setTaskHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);
  const [milestoneHandlerDropdownOptions, setMilestoneHandlerDropdownOptions] =
    useState<ProjectUserSource>(initDropdownValues);

  const [dropdownLoading, setDropdownLoading] = useState<DropdownLoading>({
    flexDropdownLoading: true,
    gmawbDropdownLoading: true,
    taskHandlerDropdownLoading: true,
    milestoneHandlerDropdownLoading: !showFlexTasks.isOn,
  });

  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  const fetchFlexHandlerCollaborator =
    props.selectedE2EProjectLineFlexTask?.collaborators?.find(
      (collaborator) => collaborator?.handlerType === HandlerType.FlexHandler
    );
  const fetchGMAWBHandlerCollaborator =
    props.selectedE2EProjectLineFlexTask?.collaborators?.find(
      (collaborator) => collaborator?.handlerType === HandlerType.GmaWBHandler
    );
  const fetchTaskHandlerCollaborator =
    props.selectedE2EProjectLineFlexTask?.collaborators?.find(
      (collaborator) => collaborator?.handlerType === HandlerType.TaskHandler
    );
  const fetchMilestoneHandlerCollaborator =
    props.selectedE2EProjectLineFlexTask?.collaborators?.find(
      (collaborator) =>
        collaborator?.handlerType === HandlerType.MilestoneHandler
    );

  const [flexHandler, setFlexHandler] = useState<E2EUserPaginatedModel>();
  const [gmawbHandler, setGmawbHandler] = useState<E2EUserPaginatedModel>();
  const [taskHandler, setTaskHandler] = useState<E2EUserPaginatedModel>();
  const [milestoneHandler, setMilestoneHandler] =
    useState<E2EUserPaginatedModel>();

  useEffect(() => {
    if (props.show) {
      setIsModalLoading(true);
      GMAWBUsersService.getPaginatedUsers(
        0,
        5,
        HandlerType.FlexHandler,
        props.selectedE2EProjectLineFlexTask!.isGmaTcTask,
        ""
      ).then((response) => {
        let flexData: E2EUserPaginatedModel[] = structuredClone(response?.data);
        if (fetchFlexHandlerCollaborator) {
          if (
            flexData?.some(
              (data) =>
                data.normalizedEmail === fetchFlexHandlerCollaborator.userEmail
            )
          ) {
            flexData = flexData?.filter(
              (data) =>
                data.normalizedEmail !== fetchFlexHandlerCollaborator.userEmail
            );
          }
          flexData?.unshift({
            displayName: fetchFlexHandlerCollaborator.displayName,
            normalizedEmail: fetchFlexHandlerCollaborator.userEmail,
          });
        }

        setFlexHandlerDropdownOptions((current) => ({
          ...current,
          data: flexData,
        }));

        setFlexHandler((current) => ({
          ...current,
          normalizedEmail: fetchFlexHandlerCollaborator?.userEmail!,
          displayName: fetchFlexHandlerCollaborator?.displayName!,
        }));

        setDropdownLoading((current) => ({
          ...current,
          flexDropdownLoading: false,
        }));
      });

      GMAWBUsersService.getPaginatedUsers(
        0,
        5,
        HandlerType.GmaWBHandler,
        props.selectedE2EProjectLineFlexTask!.isGmaTcTask,
        ""
      ).then((response) => {
        let gmawbData: E2EUserPaginatedModel[] = structuredClone(
          response?.data
        );

        if (fetchGMAWBHandlerCollaborator) {
          if (
            gmawbData?.some(
              (data) =>
                data.normalizedEmail === fetchGMAWBHandlerCollaborator.userEmail
            )
          ) {
            gmawbData = gmawbData?.filter(
              (data) =>
                data.normalizedEmail !== fetchGMAWBHandlerCollaborator.userEmail
            );
          }
          gmawbData?.unshift({
            displayName: fetchGMAWBHandlerCollaborator.displayName,
            normalizedEmail: fetchGMAWBHandlerCollaborator.userEmail,
          });
        }

        setGmawbHandlerDropdownOptions((current) => ({
          ...current,
          data: gmawbData,
        }));

        setGmawbHandler((current) => ({
          ...current,
          normalizedEmail: fetchGMAWBHandlerCollaborator?.userEmail!,
          displayName: fetchGMAWBHandlerCollaborator?.displayName!,
        }));

        setDropdownLoading((current) => ({
          ...current,
          gmawbDropdownLoading: false,
        }));
      });

      GMAWBUsersService.getPaginatedUsers(
        0,
        5,
        HandlerType.TaskHandler,
        props.selectedE2EProjectLineFlexTask!.isGmaTcTask,
        ""
      ).then((response) => {
        let taskHandlerData: E2EUserPaginatedModel[] = structuredClone(
          response?.data
        );
        if (fetchTaskHandlerCollaborator) {
          if (
            taskHandlerData?.some(
              (data) =>
                data.normalizedEmail === fetchTaskHandlerCollaborator.userEmail
            )
          ) {
            taskHandlerData = taskHandlerData?.filter(
              (data) =>
                data.normalizedEmail !== fetchTaskHandlerCollaborator.userEmail
            );
          }
          taskHandlerData?.unshift({
            displayName: fetchTaskHandlerCollaborator.displayName,
            normalizedEmail: fetchTaskHandlerCollaborator.userEmail,
          });
        }

        setTaskHandlerDropdownOptions((current) => ({
          ...current,
          data: taskHandlerData,
        }));

        setTaskHandler((current) => ({
          ...current,
          normalizedEmail: fetchTaskHandlerCollaborator?.userEmail!,
          displayName: fetchTaskHandlerCollaborator?.displayName!,
        }));

        setDropdownLoading((current) => ({
          ...current,
          taskHandlerDropdownLoading: false,
        }));
      });

      if (!showFlexTasks.isOn) {
        GMAWBUsersService.getPaginatedUsers(
          0,
          5,
          HandlerType.MilestoneHandler,
          props.selectedE2EProjectLineFlexTask!.isGmaTcTask,
          ""
        ).then((response) => {
          let milestoneHandler: E2EUserPaginatedModel[] = structuredClone(
            response?.data
          );

          if (fetchMilestoneHandlerCollaborator) {
            if (
              milestoneHandler?.some(
                (data) =>
                  data.normalizedEmail ===
                  fetchMilestoneHandlerCollaborator.userEmail
              )
            ) {
              milestoneHandler = milestoneHandler?.filter(
                (data) =>
                  data.normalizedEmail !==
                  fetchMilestoneHandlerCollaborator.userEmail
              );
            }
            milestoneHandler?.unshift({
              displayName: fetchMilestoneHandlerCollaborator.displayName,
              normalizedEmail: fetchMilestoneHandlerCollaborator.userEmail,
            });
          }

          setMilestoneHandlerDropdownOptions((current) => ({
            ...current,
            data: milestoneHandler,
          }));

          setMilestoneHandler((current) => ({
            ...current,
            normalizedEmail: fetchMilestoneHandlerCollaborator?.userEmail!,
            displayName: fetchMilestoneHandlerCollaborator?.displayName!,
          }));

          setDropdownLoading((current) => ({
            ...current,
            milestoneHandlerDropdownLoading: false,
          }));
        });
      }
    }
  }, [props.show]);

  useEffect(() => {
    if (
      !dropdownLoading.flexDropdownLoading &&
      !dropdownLoading.gmawbDropdownLoading &&
      !dropdownLoading.taskHandlerDropdownLoading
    ) {
      if (
        !showFlexTasks.isOn &&
        !dropdownLoading.milestoneHandlerDropdownLoading
      )
        setIsModalLoading(false);

      if (showFlexTasks.isOn) setIsModalLoading(false);
    }
  }, [dropdownLoading]);

  const dialogStyle = {
    width: "25rem",
    height: showFlexTasks.isOn ? "27rem" : "32rem",
  };

  const handlerUpdateProjectLevelContributors = async () => {
    const response = await ProjectService.getProjectContributorsList(
      props.e2EProjectId
    );
    if (response.isSuccess) {
      dispatch(
        updateProjectLevelContributors({
          e2eProjectId: props.e2EProjectId,
          projectHandlers: response.data.projectHandlers,
        })
      );
    }
  };

  const handleUpdateFlexTaskLevelContributors = async (
    isMilestoneListLoaded: boolean
  ) => {
    const response = await ProjectService.getProjectTasksByProject({
      projectId: props.e2EProjectId,
      isMilestoneListLoaded: isMilestoneListLoaded,
    });

    if (response.isSuccess) {
      let records = response?.data?.e2EProjectLineFlexTasks?.map(
        (task: PmrE2EProjectTaskModel, index: number) => {
          task.workBreakdownStructure = index + 1;
          task.isChecked = false;
          return task;
        }
      );

      dispatch(
        upsertProjectFlexTasksContributors({
          e2eProjectId: props.e2EProjectId,
          tasks: records,
        } as PmrE2EProjectTaskModelList)
      );
    }
  };

  const handleSave = async () => {
    setIsSaveLoading(true);
    const newContributors: ProjectCollaboratorsDetails = {
      e2eProjectId: props.e2EProjectId,
      projectNumber: props.projectNumber,
      e2EProjectLineFlexTaskId:
        props.selectedE2EProjectLineFlexTask?.e2EProjectLineFlexTaskId,
      e2EProjectLineMilestoneId: props?.e2EProjectLineMilestoneId,
      flexHandlerDetails: flexHandler,
      taskHandlerDetails: taskHandler,
      gmaWbHandlerDetails: gmawbHandler,
      milestoneHandlerDetails: milestoneHandler,
    };

    const isMilestoneListLoaded = props?.e2EProjectLineMilestoneId
      ? true
      : false;

    const updateResult = await GMAWBUsersService.saveContributors(
      newContributors
    );
    props.onClose();

    if (updateResult.isSuccess) {
      showSuccess("Success", updateResult.message);

      if (props.e2EProjectLineMilestoneId) {
        dispatch(
          updateFlexTaskMilestoneOrderLineContributor({
            projectId: props.e2EProjectId,
            projectNumber: props.projectNumber,
            workBreakdownStructure: props.workBreakdownStructure,
            e2EProjectLineMilestoneId: props.e2EProjectLineMilestoneId,
            e2EProjectLineId: props.e2EProjectLineId,
            flexHandler,
            taskHandler,
            gmawbHandler,
            milestoneHandler,
          })
        );
      } else {
        dispatch(
          updateFlexTaskOrderLineContributor({
            projectId: props.e2EProjectId,
            projectNumber: props.projectNumber,
            workBreakdownStructure: props.workBreakdownStructure,
            e2EProjectLineFlexTaskId:
              props.selectedE2EProjectLineFlexTask?.e2EProjectLineFlexTaskId,
            flexHandler,
            taskHandler,
            gmawbHandler,
            e2EProjectLineId: props.e2EProjectLineId,
          })
        );
      }

      handleUpdateFlexTaskLevelContributors(isMilestoneListLoaded);
      handlerUpdateProjectLevelContributors();
    }

    setIsSaveLoading(false);
  };

  const header = (
    <div>
      <span>Assign Contributors</span>
    </div>
  );

  const footer = (
    <div className={classes["footer"]}>
      <div>
        <Button
          className={`${classes["button-cancel"]}`}
          label="CANCEL"
          onClick={props.onClose}
          disabled={isModalLoading}
        />
      </div>
      <div className={`${classes["button-save-wrapper"]}`}>
        <Button
          className={`${classes["button-save"]}`}
          label="SAVE"
          onClick={handleSave}
          disabled={isModalLoading}
          loading={isSaveLoading}
        />
      </div>
    </div>
  );

  const onHide = () => {
    setFlexHandlerDropdownOptions(initDropdownValues);
    setGmawbHandlerDropdownOptions(initDropdownValues);
    setTaskHandlerDropdownOptions(initDropdownValues);
    setMilestoneHandlerDropdownOptions(initDropdownValues);
    setFlexHandler(undefined);
    setGmawbHandler(undefined);
    setTaskHandler(undefined);
    setMilestoneHandler(undefined);
    setIsModalLoading(false);
    setIsSaveLoading(false);
    props.onClose();
  };

  return (
    <Dialog
      draggable={false}
      resizable={false}
      className={classes["assign-collaborator-dialog"]}
      header={header}
      footer={footer}
      visible={props.show}
      style={dialogStyle}
      modal
      onHide={onHide}
      contentClassName={classes["content"]}
    >
      {isModalLoading && (
        <div className={`${classes["spinner__container"]}`}>
          <ProgressSpinner
            strokeWidth="5"
            animationDuration="5s"
            className={`${classes["spinner"]}`}
          />
          <div>Loading Contributors.</div>
        </div>
      )}
      {!isModalLoading && [
        <div className={classes["dropdown"]}>
          <Tooltip
            position="right"
            mouseTrack
            className={`${classes["tooltip"]}`}
            target=".flex-handler-modal-tooltip"
          >
            <div className={classes["tooltip-content"]}>
              Assigning the FLEX Handler applies to the entire Order Line, as
              well as other order lines belonging to the same FLEX Project. This
              includes all associated FLEX Tasks and GMAWB Milestones within the
              order lines. This value will be reflected in the FLEX system.
            </div>
          </Tooltip>
          <span>
            FLEX Handler
            <FontAwesomeIcon
              icon={faCircleInfo}
              className={`${classes["icon"]} flex-handler-modal-tooltip`}
            />
          </span>
          <ProjectCollaboratorAssignmentDropdown
            value={flexHandler}
            dropdownOptions={flexHandlerDropdownOptions}
            setValue={setFlexHandler}
            setDropdownOptions={setFlexHandlerDropdownOptions}
            handlerType={HandlerType.FlexHandler}
            isGmaTcTask={props.selectedE2EProjectLineFlexTask?.isGmaTcTask}
            isDisabled={props.isCoHandlerMilestone}
          />
        </div>,
        <div className={classes["dropdown"]}>
          <Tooltip
            position="right"
            mouseTrack
            className={`${classes["tooltip"]}`}
            target=".gmawb-handler-modal-tooltip"
          >
            <div className={classes["tooltip-content"]}>
              <div>
                GMAWB Handler assignments apply to the entire order line.
                Reassigning the GMAWB Handler role to a user automatically makes
                them Milestone Handlers for these milestones:
              </div>

              <ul>
                <li>Customer: Send All Documents to UL</li>
                <li>Customer: Send All Samples to UL</li>
                <li>UL: Review and Prepare Application Package</li>
                <li>UL: Submit to Authority/Authority Review</li>
                <li>UL: Submit Samples to Test Lab/In-Country Testing</li>
                <li>
                  UL: Payment (regardless of order of payment; pre-payment,
                  payment before certification, payment after certification)
                </li>
                <li>UL: Sample Return</li>
              </ul>
            </div>
          </Tooltip>
          <span>
            GMAWB Handler
            <FontAwesomeIcon
              icon={faCircleInfo}
              className={`${classes["icon"]} gmawb-handler-modal-tooltip`}
            />
          </span>
          <ProjectCollaboratorAssignmentDropdown
            value={gmawbHandler}
            dropdownOptions={gmawbHandlerDropdownOptions}
            setValue={setGmawbHandler}
            setDropdownOptions={setGmawbHandlerDropdownOptions}
            handlerType={HandlerType.GmaWBHandler}
            isGmaTcTask={props.selectedE2EProjectLineFlexTask?.isGmaTcTask}
            isDisabled={props.isCoHandlerMilestone}
          />
        </div>,
        <div className={classes["dropdown"]}>
          <Tooltip
            position="right"
            mouseTrack
            className={`${classes["tooltip"]}`}
            target=".task-handler-modal-tooltip"
          >
            <div className={classes["tooltip-content"]}>
              The assigned Task Handler is specific to this FLEX Task and will
              be reflected in the FLEX system under "Assigned to" for their FLEX
              Project.
            </div>
          </Tooltip>
          <span>
            Task Handler
            <FontAwesomeIcon
              icon={faCircleInfo}
              className={`${classes["icon"]} task-handler-modal-tooltip`}
            />
          </span>
          <ProjectCollaboratorAssignmentDropdown
            value={taskHandler}
            dropdownOptions={taskHandlerDropdownOptions}
            setValue={setTaskHandler}
            setDropdownOptions={setTaskHandlerDropdownOptions}
            handlerType={HandlerType.TaskHandler}
            isGmaTcTask={props.selectedE2EProjectLineFlexTask?.isGmaTcTask}
            isDisabled={false}
          />
        </div>,
        showFlexTasks.isOn === false && (
          <div className={classes["dropdown"]}>
            <Tooltip
              position="right"
              mouseTrack
              className={`${classes["tooltip"]}`}
              target=".milestone-handler-modal-tooltip"
            >
              <div className={classes["tooltip-content"]}>
                Assigning the Milestone Handler applies specifically to this
                GMAWB Milestone within the order line.
              </div>
            </Tooltip>

            <span>
              Milestone Handler
              <FontAwesomeIcon
                icon={faCircleInfo}
                className={`${classes["icon"]} milestone-handler-modal-tooltip`}
              />
            </span>
            <ProjectCollaboratorAssignmentDropdown
              value={milestoneHandler}
              dropdownOptions={milestoneHandlerDropdownOptions}
              setValue={setMilestoneHandler}
              setDropdownOptions={setMilestoneHandlerDropdownOptions}
              handlerType={HandlerType.MilestoneHandler}
              isGmaTcTask={props.selectedE2EProjectLineFlexTask?.isGmaTcTask}
              isDisabled={false}
            />
          </div>
        ),
      ]}
    </Dialog>
  );
};

export default ProjectCollaboratorAssignmentDialog;
