import classes from "../GpiCopyFormModal.module.scss";

const ExistingCertificates = ({ fieldValue }) => {
  return (
    <div className={classes["details-content-container"]}>
      <div className={classes["details-field"]}>
        Certificate Type:{" "}
        {fieldValue?.isExistingULFileNumber && (
          <span>UL File Number / Volume</span>
        )}
        {fieldValue?.isULCBCertificateNumber && (
          <span>UL CB Certificate Number</span>
        )}
        {fieldValue?.isOtherGMACertificate && (
          <span>Other GMA Certificate</span>
        )}
      </div>

      {fieldValue?.isExistingULFileNumber && (
        <>
          <div className={classes["details-field"]}>
            UL File Number: <span>{fieldValue?.fileNumber}</span>
          </div>

          <div className={classes["details-field"]}>
            Volume Number: <span>{fieldValue?.volumeNumber}</span>
          </div>
        </>
      )}

      {fieldValue?.isULCBCertificateNumber && (
        <div className={classes["details-field"]}>
          UL CB Certificate Number :{" "}
          <span>{fieldValue?.certificateNumber}</span>
        </div>
      )}

      {fieldValue?.isOtherGMACertificate && (
        <>
          <div className={classes["details-field"]}>
            Certificate Scheme: <span>{fieldValue?.certificateScheme}</span>
          </div>

          <div className={classes["details-field"]}>
            Certificate Number : <span>{fieldValue?.certificateNumber}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default ExistingCertificates;
