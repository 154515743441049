import classes from "./ProjectFullView.module.scss";
import ProjectListDetailTab from "../project-list-detail-tab/ProjectListDetailTab";
import PmrE2EProjectModel from "../shared/interfaces/pmr-e2e-project-model";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import ProjectStatusTemplate from "../shared/components/project-status-template/ProjectStatusTemplate";
import ProjectCollaboratorAvatar from "../shared/components/project-collaborator-avatar/ProjectCollaboratorAvatar";
import * as ProjectListPmr from "../project-list-pmr/ProjectListPmr";
import * as axios from "axios";
import {
  projectColumnStatus,
  projectColumnCollaborator,
} from "../data/project-grid-columns";
import ProjectListHeaderActions from "../shared/components/project-list-header-actions/ProjectListHeaderActions";
import {
  selectShowFlexTasks,
  updateShowFlexTasks,
} from "../../../features/projectManagementPmr/projectManagementPmrSlice";
import maintenanceIcon from "../../../assets/images/maintenance-icon.png";
import { Tooltip } from "primereact/tooltip";
import clsx from "clsx";
import useProjectManagementPmrSignalr from "../../../hooks/useProjectManagementPmr";
import { updateProjectManagementViewProjects } from "../../../features/projectManagement/projectManagementSlice";
import { ProjectManagementPmrShowFlexTasks } from "../../../features/projectManagementPmr/prohectManagementPmrSliceInterfaces";

export const projectFullViewMessageKey = "project-full-view-message";

const ProjectFullView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);
  const segments = pathname.split("/");
  const projectName = segments[segments.length - 1];
  const urlSearch = new URLSearchParams(location.search);
  const [project, setProject] = useState<PmrE2EProjectModel>();
  const getProjectListCancelSource = useRef<any>(null);
  const [fetchFromAPI, setfetchFromAPI] = useState<boolean>(false);
  const showFlexTaskOnly = useSelector(selectShowFlexTasks);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const projectManagementPmrSignalrHook = useProjectManagementPmrSignalr();

  useEffect(() => {
    if (fetchFromAPI) getProject(projectName);
  }, [fetchFromAPI]);

  useEffect(() => {
    const showFlexTaskOnlyParam =
      urlSearch.get("showFlexTaskOnly") == "false" ||
      urlSearch.get("showFlexTaskOnly") == "0"
        ? false
        : true;

    const showFlexTask: ProjectManagementPmrShowFlexTasks = {
      isOn: showFlexTaskOnlyParam,
      isFromClicked: false,
    };
    dispatch(updateShowFlexTasks(showFlexTask));

    const projectFullViewMessage = sessionStorage.getItem(
      projectFullViewMessageKey
    );

    if (projectFullViewMessage == null) {
      setfetchFromAPI(true);
    } else {
      setfetchFromAPI(false);
      let jsonProject = JSON.parse(projectFullViewMessage);
      let records = [jsonProject];
      dispatch(updateProjectManagementViewProjects(records));
      setProject(jsonProject);
      sessionStorage.removeItem(projectFullViewMessageKey);
      setIsLoaded(true);
    }
  }, [projectName]);

  const getProject = async (projectName: string) => {
    const model = {
      skip: 0,
      take: 1,
      sort: "createdDateUtc",
      sortBy: "DESC",
      searchString: projectName,
      showFlexTask: showFlexTaskOnly.isOn,
      isFirstAndExactProjectName: true,
    };

    if (getProjectListCancelSource.current) {
      getProjectListCancelSource.current.cancel();
    }

    getProjectListCancelSource.current = (axios as any).CancelToken.source();

    try {
      const response = await ProjectListPmr.getProjectsHelper(
        model,
        getProjectListCancelSource.current
      );

      if (!response.isSuccess) return;

      dispatch(updateProjectManagementViewProjects(response.records));
      if (response.records[0]) setProject(response.records[0]);
    } catch (e) {
      if ((axios as any).isCancel(e)) {
        console.warn("Get Project List request cancelled");
        return;
      }
    } finally {
      setIsLoaded(true);
      setfetchFromAPI(false);
    }
  };

  return (
    <>
      {project != null && isLoaded && (
        <div className={classes["project-full-view-container"]}>
          <div className={classes["header"]}>
            <h2>{projectName}</h2>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className={clsx(classes["info-icon"], `info-icon`)}
            />

            <Tooltip
              position="bottom"
              mouseTrack
              className={`${classes["tooltip"]}`}
              target={`.info-icon`}
            >
              <div className={`${classes["tooltip-title"]}`}>
                GMAWB Project Creator
              </div>
              {
                <div className={`${classes["tooltip-label"]}`}>
                  {project.createdByDisplayName}
                </div>
              }
              <br />
              <div className={`${classes["tooltip-title"]}`}>
                FLEX Sold To (Customer Company Name)
              </div>
              {
                <div className={`${classes["tooltip-label"]}`}>
                  {project.flexSoldTo}
                </div>
              }

              <br />
              <div className={`${classes["tooltip-title"]}`}>
                FLEX Sold To (Party Site Number)
              </div>
              {
                <div className={`${classes["tooltip-label"]}`}>
                  {project.partySiteNumber}
                </div>
              }
            </Tooltip>

            <ProjectStatusTemplate
              tooltip="Status determined based on underlying GMAWB Task Statuses."
              status={project[projectColumnStatus]}
            ></ProjectStatusTemplate>
          </div>
          <ProjectCollaboratorAvatar
            collaborator={project[projectColumnCollaborator]}
          ></ProjectCollaboratorAvatar>
          <div className={`${classes["body"]}`}>
            <ProjectListHeaderActions
              filterByProject={true}
              project={project}
            />

            <div className={`${classes["table-div"]}`}>
              <ProjectListDetailTab
                project={project}
                projectId={project.id || ""}
                overrideActiveTabIndex={null}
                depthIndices={[0]}
              ></ProjectListDetailTab>
            </div>
          </div>
        </div>
      )}

      {project == null && isLoaded && (
        <div className={classes["project-full-view-container"]}>
          <div className={classes["error-div"]}>
            <img src={maintenanceIcon} />
            <h1>Server Error</h1>
            <h3>GMAWB Project "{projectName}" does not exist.</h3>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectFullView;
