import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faInfoCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { selectValues } from "../../features/createCertificate/createCertificateSlice";
import ProductTypeService from "../../services/ProductTypeService";
import { formatDateToISO } from "../../utils/helpers/date.helpers";
import CustomDatePicker from "../custom-date-picker/CustomDatePicker";
import CustomPaginator from "../custom-paginator/CustomPaginator";
import CustomerNameLookupDialog from "./customer-name-lookup/CustomerNameLookupDialog";
import IpModelDetailsPopover from "./ip-model-details-popover/IpModelDetailsPopover";
import classes from "./ModelSearch.module.scss";
import "./ModelSearch.scss";
import { v4 as uuidv4 } from "uuid";
import { appInsights } from "../../services/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import CertificateListService from "../../services/CertificateListService";
const workBenchCreateNewProductUrl =
  process.env.REACT_APP_WORKBENCH_CREATE_NEW_PRODUCT_URL;
const workBenchProductUrl = process.env.REACT_APP_WORKBENCH_PRODUCT_URL;

const logPrefix = `ModalSearch - ${uuidv4()}`;

const ModelSearch = (props) => {
  const {
    visible,
    onSelectedModelsApply,
    hideApplyButton,
    productType,
    partySiteNumber,
    productTypes,
    shouldPopulateWorkbenchModelForm = true,
    onClose,
    disablePsn,
    preSelectedModels,
    certificateId,
  } = props;

  const step2Values = useSelector(selectValues);

  const [isShowModelSearchDetailInputs, setIsShowModelSearchDetailInputs] =
    useState(false);
  const [displayCustomerNameLookup, setDisplayCustomerNameLookup] =
    useState(false);
  const [enteredModelName, setEnteredModelName] = useState([]);

  const [_partySiteNumber, _setPartySiteNumber] = useState("");
  const [projectNumber, setProjectNumber] = useState("");
  const [_productType, _setProductType] = useState(null);
  const [orderNumber, setOrderNumber] = useState("");
  const [quoteNumber, setQuoteNumber] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [fromCreatedDate, setFromCreatedDate] = useState("");
  const [toCreatedDate, setToCreatedDate] = useState("");
  const [modelList, setModelList] = useState([]);
  const [baseModelList, setBaseModelList] = useState([]);
  const [modelVariantList, setModelVariantList] = useState([]);
  const [_productTypes, _setProductTypes] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedBaseModels, setSelectedBaseModels] = useState([]);
  const [selectedModelVariants, setSelectedModelVariants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(50);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    last: 0,
    rows: 50,
    page: 0,
    isFirstPageLinkDisabled: true,
    isPrevPageLinkDisabled: true,
    isNextPageLinkDisabled: true,
    isLastPageLinkDisabled: true,
  });
  const dropdownOptions = [
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ];
  const currentPageRef = useRef(null);

  const modelName = useRef("");

  useEffect(() => {
    if (!visible) _setPartySiteNumber("");
  });

  useEffect(() => {
    appInsights.trackTrace({
      message: `${logPrefix} - useEffect-productTypes - length = ${productTypes?.length}`,
      severityLevel: SeverityLevel.Information,
    });
  }, [productTypes]);

  useEffect(() => {
    if (visible) {
      setIsShowModelSearchDetailInputs(false);
      setDisplayCustomerNameLookup(false);
      setEnteredModelName("");
      setProjectNumber("");
      setOrderNumber("");
      setQuoteNumber("");
      setCollectionName("");
      setFromCreatedDate("");
      setToCreatedDate("");
      setModelList([]);
      setSelectedModels([]);
      setTotalRecordCount([]);
      setTotalPageCount(1);
      setCurrentPage(1);
      setPageCount(50);
      setLazyParams({
        first: 1,
        last: 50,
        rows: 50,
        page: 1,
        isFirstPageLinkDisabled: false,
        isPrevPageLinkDisabled: false,
        isNextPageLinkDisabled: false,
        isLastPageLinkDisabled: false,
      });

      const baseModel = preSelectedModels[0];

      const partySiteNumberInput =
        _partySiteNumber || baseModel?.partySiteNumber || partySiteNumber;

      const productTypeInput = baseModel?.productType;

      _setPartySiteNumber(partySiteNumberInput);
      _setProductType(productTypeInput);

      populateProductTypeOptions();
      populateSelectedModelList();
      getModelsList("", partySiteNumberInput, "", productTypeInput);
    }
  }, [visible, partySiteNumber, productType]);

  useEffect(() => {
    const selectedModels = [...baseModelList, ...modelVariantList];

    const partySiteNumber = selectedModels?.find(
      (x) => x.partySiteNumber
    )?.partySiteNumber;

    if (partySiteNumber) {
      _setPartySiteNumber(partySiteNumber);
    }
  }, [baseModelList, modelVariantList]);

  const populateSelectedModelList = async () => {
    if (preSelectedModels?.length > 0) {
      let baseModel = preSelectedModels.find((f) => f.isBaseModel);
      let modelVariants = preSelectedModels.filter((f) => !f.isBaseModel);

      if (!baseModel || baseModel?.length <= 0) {
        const detailFromE2E =
          await CertificateListService.getCertificateDetailByWorkbenchCertificateId(
            certificateId
          );
        if (detailFromE2E?.isSuccess) {
          if (
            preSelectedModels.find(
              (model) => model.modelName == detailFromE2E.data?.baseModel
            )
          ) {
            baseModel = preSelectedModels.find(
              (model) => model.modelName == detailFromE2E.data?.baseModel
            );
            modelVariants = preSelectedModels.filter(
              (model) => model.modelName != detailFromE2E.data?.baseModel
            );
          } else {
            baseModel = preSelectedModels[0];
            modelVariants = preSelectedModels.filter((f, i) => i > 0);
          }
        }
      }

      setBaseModelList([baseModel]);
      setModelVariantList(modelVariants);
      return;
    }

    setBaseModelList([]);
    setModelVariantList([]);
  };

  const onHideCustomerNameLookup = () => setDisplayCustomerNameLookup(false);

  const populateProductTypeOptions = () => {
    appInsights.trackTrace({
      message: `${logPrefix} - populateProductTypeOptions productTypes - length = ${productTypes?.length}`,
      severityLevel: SeverityLevel.Information,
    });

    if (!productTypes?.length && _productTypes.length === 0) {
      ProductTypeService.getIpProductType().then((response) => {
        if (response && response.isSuccess) {
          appInsights.trackTrace({
            message: `${logPrefix} - populateProductTypeOptions _productTypes - length = ${_productTypes?.length}`,
            severityLevel: SeverityLevel.Information,
          });

          _setProductTypes(response.data);
        }
      });
    }
  };

  const toggleIsShowModelSearchDetailInputs = () => {
    setIsShowModelSearchDetailInputs((prevState) => !prevState);
  };

  const handleBaseModelAdd = () => {
    setBaseModelList(selectedModels);
    setSelectedModels([]);
  };

  const handleModelVariantsAdd = () => {
    setModelVariantList((curr) => [...curr, ...selectedModels]);
    setSelectedModels([]);
  };

  const isModelAlreadyABaseOrVariant = (model) => {
    return [...baseModelList, ...modelVariantList].some(
      (m) => m.assetId === model.assetId
    );
  };

  const isModelSelectable = ({ data }) => {
    return !isModelAlreadyABaseOrVariant(data);
  };

  const hasSelectedModel = selectedModels.length > 0;

  const hasSelectedBaseModelOrVariant =
    [...selectedBaseModels, ...selectedModelVariants].length > 0;

  const combinedSelectedModels = [
    ...selectedModels,
    ...modelVariantList,
    ...baseModelList,
  ];

  const baseModelAndModelVariantsList = [...baseModelList, ...modelVariantList];

  const hasSelectedBaseModel =
    baseModelAndModelVariantsList.length == 0
      ? preSelectedModels.length > 0
      : baseModelList.length > 0;

  const columns = [
    {
      id: "modelName",
      value: "Model Name",
      width: "300px",
      sortable: true,
      isAllModelsTableOnly: false,
    },
    {
      id: "productType",
      value: "Product Type",
      width: "300px",
      sortable: true,
      isAllModelsTableOnly: false,
    },
    {
      id: "familySeries",
      value: "Family / Series",
      width: "265px",
      sortable: true,
      isAllModelsTableOnly: true,
    },
    {
      id: "status",
      value: "Status",
      width: "125px",
      sortable: false,
      isAllModelsTableOnly: true,
    },
    {
      id: "projectNumber",
      value: "Project Number",
      width: "210px",
      sortable: false,
      isAllModelsTableOnly: true,
    },
    {
      id: "collectionName",
      value: "Collection Name",
      width: "210px",
      sortable: false,
      isAllModelsTableOnly: true,
    },
    {
      id: "applicantName",
      value: "Applicant",
      width: "245px",
      sortable: true,
      isAllModelsTableOnly: true,
    },
    {
      id: "partySiteNumber",
      value: "Applicant (Party Site Number)",
      width: "225px",
      sortable: true,
      isAllModelsTableOnly: true,
    },
    {
      id: "createdOn",
      value: "Last Updated Date",
      width: "165px",
      sortable: true,
      isAllModelsTableOnly: true,
    },
  ];

  const handleOpenIpModelDetailsWindow = async (e, model, listSetter) => {
    e.stopPropagation();

    // We need to iterate tru this models list setters to close
    // other currently opened ip model details popoevers
    // (main models list, base models list, model variants list)
    [setModelList, setBaseModelList, setModelVariantList].forEach((setList) =>
      setList((currList) =>
        currList.map((currItem) => ({
          ...currItem,
          ipModelDetailsOpen:
            currItem.assetId === model.assetId && listSetter === setList,
        }))
      )
    );
  };

  const handleCloseIpModelDetailsWindow = (e, setList) => {
    setList((currList) =>
      currList.map((m) => {
        return {
          ...m,
          ipModelDetailsOpen: false,
        };
      })
    );

    if (e) e.stopPropagation();
  };

  const collectionNameTemplate = (rowData) => {
    return (
      <div>
        <a
          href="#"
          onClick={() => filterByCollectionName(rowData.collectionName)}
        >
          {rowData.collectionName}
        </a>
      </div>
    );
  };

  const getColWidth = (isAllModelsTable, col) => {
    if (isAllModelsTable) return col.width;

    if (col.id === "modelName") return "50%";
    else if (col.id === "productType") return "calc(50% - 3em)";
  };

  const renderColumns = (isAllModelsTable, setList) =>
    columns
      .filter((c) => isAllModelsTable || !c.isAllModelsTableOnly)
      .map((col) => {
        let colWidth = getColWidth(isAllModelsTable, col);

        return col.id === "collectionName" ? (
          <Column
            key={col.id}
            header="Collection Name"
            body={collectionNameTemplate}
            style={{ width: colWidth }}
          />
        ) : (
          <Column
            sortable={col.sortable}
            key={col.id}
            columnKey={col.id}
            field={col.id}
            header={() => (
              <div title={col.value} className="text-overflow-ellipsis">
                {col.value}
              </div>
            )}
            style={{ width: colWidth }}
            body={(rowData) => {
              return (
                <div className={`${classes[col.id]}`}>
                  <div
                    title={rowData[col.id]}
                    className={`text-overflow-ellipsis ${
                      col.id === "modelName" && !rowData.assetId
                        ? classes["model-name--red"]
                        : ""
                    }`}
                  >
                    {rowData[col.id]}
                  </div>
                  {col.id === "modelName" && rowData.assetId && (
                    <IpModelDetailsPopover
                      model={rowData}
                      ipModelDetails={rowData.ipModelDetails}
                      includeBasicModelDetails={!isAllModelsTable}
                      isWindowOpen={rowData.ipModelDetailsOpen}
                      onOpenWindow={(e, model, includeBasicModelDetails) =>
                        handleOpenIpModelDetailsWindow(
                          e,
                          model,
                          setList,
                          includeBasicModelDetails
                        )
                      }
                      onCloseWindow={(e) =>
                        handleCloseIpModelDetailsWindow(e, setList)
                      }
                      onClickOutside={(e) =>
                        handleCloseIpModelDetailsWindow(e, setList)
                      }
                    />
                  )}
                </div>
              );
            }}
          />
        );
      });

  const onKeyDownModelName = (e) => {
    if (e.key === "Enter") {
      const enteredModelName = modelName.current.value;
      setEnteredModelName(enteredModelName);
      getModelsList(
        enteredModelName,
        _partySiteNumber,
        projectNumber,
        _productType
      );
    }
  };

  const getModelsList = async function (
    modelNameInput,
    psnInput,
    projectNumInput = projectNumber,
    prodTypeInput = _productType,
    orderNumInput = orderNumber,
    quoteNumInput = quoteNumber,
    collectionNameInput = collectionName,
    fromCreatedDateInput = fromCreatedDate,
    toCreatedDateInput = toCreatedDate,
    pageCountInput = pageCount,
    currentPageInput = currentPage,
    lazyParam = lazyParams
  ) {
    setIsLoading(true);
    setSelectedModels([]);

    lazyParam.first = lazyParam.first == 0 ? 1 : lazyParam.first;
    lazyParam.last = lazyParam.last == 0 ? lazyParam.rows : lazyParam.last;
    lazyParam.page = lazyParam.page == 0 ? 1 : lazyParam.page;
    currentPageInput = currentPageInput == 0 ? 1 : currentPageInput;

    let getIpModelsModel = {
      modelName: modelNameInput?.trim(),
      partySiteNumber: psnInput?.trim(),
      projectNumber: projectNumInput,
      productType: prodTypeInput,
      orderNumber: orderNumInput,
      quoteNumber: quoteNumInput,
      collectionName: collectionNameInput,
      fromCreatedDate: fromCreatedDateInput,
      toCreatedDate: toCreatedDateInput,
      pageCount: pageCountInput,
      currentPage: currentPageInput,
    };

    var response = await ProductTypeService.getIpModels(getIpModelsModel);

    if (response) {
      if (response.isSuccess) {
        if (response.data.asset && response.data.asset.length > 0) {
          const totalPage = Math.ceil(
            response.data.totalCount / pageCountInput
          );
          const totalRecords = response.data.totalCount;

          const asset = response.data.asset.map((a) => {
            return {
              ...a,
              createdOn: formatDateToISO(a.createdOn),
            };
          });

          setModelList(asset);

          lazyParam.last = pageCountInput * currentPageInput;
          if (lazyParam.last > parseInt(response.data.totalCount)) {
            lazyParam.last = parseInt(response.data.totalCount);
          }

          pageChange(lazyParam, totalPage, totalRecords);
        } else {
          setModelList(response.data.asset);

          if (currentPageInput > 1) {
            lazyParam.first = 1;
            lazyParam.last = lazyParam.rows;
            lazyParam.page = 1;

            getModelsList(
              modelNameInput,
              psnInput,
              projectNumInput,
              prodTypeInput,
              orderNumInput,
              quoteNumInput,
              fromCreatedDateInput,
              toCreatedDateInput,
              pageCountInput,
              1,
              lazyParam
            );
          } else {
            setModelList([]);

            lazyParam.first = 0;
            lazyParam.last = 0;
            lazyParam.page = 0;

            pageChange(lazyParam, 0, 0);
          }
        }
      } else {
        setModelList([]);

        lazyParam.first = 0;
        lazyParam.last = 0;
        lazyParam.page = 0;

        pageChange(lazyParam, 0, 0);
      }
    } else {
      setIsLoading(false);
    }
  };

  const onChangeProductType = (e) => {
    let { value } = e;
    if (!value) {
      value = null;
    }

    _setProductType(value);
    getModelsList(enteredModelName, _partySiteNumber, projectNumber, value);
  };

  const onChangePartySiteNumber = (e) => {
    _setPartySiteNumber(e.value);
    getModelsList(enteredModelName, e.value);
  };

  const onChangeProjectNumber = (e) => {
    setProjectNumber(e.value);
    getModelsList(enteredModelName, _partySiteNumber, e.value);
  };

  const onChangeOrderNumber = (e) => {
    setOrderNumber(e.value);
    getModelsList(
      enteredModelName,
      _partySiteNumber,
      projectNumber,
      _productType,
      e.value
    );
  };

  const onChangeQuoteNumber = (e) => {
    setQuoteNumber(e.value);
    getModelsList(
      enteredModelName,
      _partySiteNumber,
      projectNumber,
      _productType,
      orderNumber,
      e.value
    );
  };

  const onChangeCollectionName = (e) => {
    setCollectionName(e.value);
    getModelsList(
      enteredModelName,
      _partySiteNumber,
      projectNumber,
      _productType,
      orderNumber,
      quoteNumber,
      e.value
    );
  };

  const onChangeFromCreatedDate = (date) => {
    setFromCreatedDate(date);
    getModelsList(
      enteredModelName,
      _partySiteNumber,
      projectNumber,
      _productType,
      orderNumber,
      quoteNumber,
      collectionName,
      date
    );
  };

  const onChangeToCreatedDate = (date) => {
    setToCreatedDate(date);
    getModelsList(
      enteredModelName,
      _partySiteNumber,
      projectNumber,
      _productType,
      orderNumber,
      quoteNumber,
      collectionName,
      fromCreatedDate,
      date
    );
  };

  const goToUrl = () => {
    if (shouldPopulateWorkbenchModelForm) {
      if (step2Values.selectedProjectDetails.projectNumber) {
        window.open(
          encodeURI(
            workBenchCreateNewProductUrl +
              "PartySiteID=" +
              step2Values.selectedProjectDetails.partySiteNumber +
              "&projectNumber=" +
              step2Values.selectedProjectDetails.projectNumber +
              "&orderNumber=" +
              step2Values.selectedProjectDetails.orderNumber +
              "&quoteNumber=" +
              step2Values.selectedProjectDetails.quoteNo
          ),
          "_blank"
        );
      } else {
        window.open(
          encodeURI(
            workBenchCreateNewProductUrl +
              "PartySiteID=" +
              step2Values.selectedProjectDetails.partySiteNumber
          ),
          "_blank"
        );
      }
    } else {
      window.open(encodeURI(workBenchCreateNewProductUrl));
    }
  };

  const handleModelsApply = (isClose) => {
    let modelVariants = structuredClone(modelVariantList);
    modelVariants = modelVariants.map((model) => {
      const newestHierarchyid = getProductTypes().find(
        (p) => p.hierarchyValue === model.productType
      )?.hierarchyId;

      return {
        ...model,
        hierarchyId: newestHierarchyid,
        isBaseModel: false,
      };
    });

    let baseModel = structuredClone(baseModelList);
    baseModel = baseModel.map((model) => {
      const newestHierarchyid = getProductTypes().find(
        (p) => p.hierarchyValue === model.productType
      )?.hierarchyId;

      return {
        ...model,
        hierarchyId: newestHierarchyid,
        isBaseModel: true,
      };
    });

    const selectedModels = [...baseModel, ...modelVariants];

    onSelectedModelsApply(selectedModels, isClose);
  };

  const linkToWorkBenchTemplate = (rowData) => {
    let displayText = "";

    if (!rowData.isE2E) {
      displayText = "Open Model In Workbench";
    } else {
      displayText = "N/A; E2E Only";
    }

    return (
      <div>
        <a target="_blank" href={getWorkBenchTemplateLink(rowData)}>
          {displayText}
        </a>
      </div>
    );
  };

  const getWorkBenchTemplateLink = (rowData) => {
    if (!rowData.isE2E) {
      return `${workBenchProductUrl}${rowData.assetId}`;
    }
  };

  const filterByCollectionName = (collectionName) => {
    setCollectionName(collectionName);
    getModelsList(
      enteredModelName,
      _partySiteNumber,
      projectNumber,
      _productType,
      orderNumber,
      quoteNumber,
      collectionName
    );
  };

  const onChangePage = (params) => {
    params.first = (params.page - 1) * params.rows + 1;
    const last = params.page * params.rows;
    params.last = last > totalRecordCount ? totalRecordCount : last;

    getModelsList(
      enteredModelName,
      _partySiteNumber,
      projectNumber,
      _productType,
      orderNumber,
      quoteNumber,
      collectionName,
      fromCreatedDate,
      toCreatedDate,
      params.rows,
      params.page,
      params
    );
  };

  const pageChange = (params, totalPages = 0, totalRecords) => {
    if (params.page > 1) {
      params.isFirstPageLinkDisabled = false;
      params.isPrevPageLinkDisabled = false;
    } else {
      params.isFirstPageLinkDisabled = true;
      params.isPrevPageLinkDisabled = true;
    }

    if (params.page < totalPages) {
      params.isLastPageLinkDisabled = false;
      params.isNextPageLinkDisabled = false;
    } else {
      params.isLastPageLinkDisabled = true;
      params.isNextPageLinkDisabled = true;
    }

    setPageCount(params.rows);
    setCurrentPage(params.page);
    setLazyParams(params);
    setTotalPageCount(totalPages);
    setTotalRecordCount(totalRecords);
    setIsLoading(false);

    if (currentPageRef.current) currentPageRef.current.value = params.page;
  };

  const uniqueSelectedPsns = [
    ...new Set(combinedSelectedModels.map((c) => c.partySiteNumber)),
  ];

  const hasSelectedDifferentPsn = uniqueSelectedPsns.length > 1;

  const isApplyDisabled = !hasSelectedBaseModel || hasSelectedDifferentPsn;

  const footer = () => (
    <div className={classes["footer"]}>
      {hasSelectedDifferentPsn && (
        <div className={`${classes["error-message"]} text-red text-italic`}>
          ERROR: Please select Models with the same PSN
        </div>
      )}

      <div className={`${classes["mdl-srch-footer-btns"]}`}>
        {!hideApplyButton && (
          <button
            className={`ul-button -medium ${classes["mdl-srch-apply-btn"]}`}
            disabled={isApplyDisabled}
            onClick={() => handleModelsApply()}
          >
            APPLY
          </button>
        )}

        <button
          className={`ul-button -medium ${classes["mdl-srch-apply-close-btn"]}`}
          disabled={isApplyDisabled}
          onClick={() => handleModelsApply(true)}
        >
          APPLY AND CLOSE
        </button>
        <button
          className={`ul-button -medium ${classes["mdl-srch-cancel-btn"]}`}
          onClick={onClose}
        >
          CANCEL
        </button>
      </div>
    </div>
  );

  const checkboxColumn = (
    <Column
      selectionMode="multiple"
      alignFrozen="left"
      frozen
      headerStyle={{ width: "3em" }}
    ></Column>
  );

  const handleModelSelectionChange = (e) => {
    const newSelectedModels = e.value.filter(
      (m) => !isModelAlreadyABaseOrVariant(m)
    );
    setSelectedModels(newSelectedModels);
  };

  const handleBaseModelsSelectionChange = ({ value }) => {
    setSelectedBaseModels(value);
  };

  const handleModelVariantsSelectionChange = ({ value }) => {
    setSelectedModelVariants(value);
  };

  const handleModelsRemove = () => {
    setBaseModelList((curr) =>
      curr.filter(
        (c) => !selectedBaseModels.some((s) => s.assetId === c.assetId)
      )
    );

    setModelVariantList((curr) =>
      curr.filter(
        (c) =>
          !selectedModelVariants.some(
            (s) => getModelDataKey(s) === getModelDataKey(c)
          )
      )
    );

    setSelectedBaseModels([]);
    setSelectedModelVariants([]);
  };

  const getModelsTableRowClassName = (data) => {
    if (isModelAlreadyABaseOrVariant(data)) {
      return classes["already-selected"];
    }

    return "";
  };

  const getProductTypes = () => productTypes || _productTypes;

  const getModelDataKey = (model) => model.id + model.assetId;

  const modelVariantsDatatableList = modelVariantList.map((m) => ({
    ...m,
    dataKey: getModelDataKey(m),
  }));

  const isPartySiteNumberDisabled =
    disablePsn ||
    (_partySiteNumber &&
      baseModelAndModelVariantsList.some((x) => x.partySiteNumber));

  return (
    <>
      <CustomerNameLookupDialog
        displayCustomerNameLookup={displayCustomerNameLookup}
        onHideCustomerNameLookup={onHideCustomerNameLookup}
        setPartySiteNumber={_setPartySiteNumber}
      />
      <Dialog
        className={classes["dialog"]}
        header="Model Search"
        onHide={onClose}
        style={{ width: "95vw", height: "100vh", maxHeight: "95%" }}
        maximizable
        visible={visible}
        footer={footer}
      >
        <div className={`${classes["mdl-srch-container"]} p-0 w-100`}>
          <div
            className={`ul-grid__row p-0 w-100 ${classes["main-search-container"]}`}
          >
            <div className="ul-grid__column -x40">
              <div className="font-weight-bold">Model Name</div>
              <div className={`p-input-icon-left`}>
                <i className="pi pi-search" />
                <InputText
                  placeholder="type and press enter"
                  ref={modelName}
                  onKeyDown={onKeyDownModelName}
                />
              </div>
            </div>
            <div className="ul-grid__column -x30">
              <div className="font-weight-bold">Product Type</div>
              <div>
                <Dropdown
                  value={_productType}
                  options={productTypes?.length ? productTypes : _productTypes}
                  optionLabel="hierarchyValue"
                  optionValue="hierarchyValue"
                  onChange={onChangeProductType}
                  filter
                  showClear
                  className={`${classes["mdl-srch-input-div"]}`}
                />
              </div>
            </div>
            <div className="ul-grid__column -x30">
              <div>
                <span className="font-weight-bold">Party Site Number </span>
                <FontAwesomeIcon
                  className={`${classes["psn-info-icon"]}`}
                  icon={faInfoCircle}
                  size="xs"
                  title="This is the Ship-to Party Site Number."
                />
                <span className={`${classes["psn-search-bar"]}`}>|</span>
                <button
                  disabled={isPartySiteNumberDisabled}
                  className={`${classes["psn-search"]}`}
                  onClick={() => setDisplayCustomerNameLookup(true)}
                >
                  <FontAwesomeIcon icon={faSearch} />
                  <span>Search</span>
                </button>
              </div>
              <div>
                <InputText
                  disabled={isPartySiteNumberDisabled}
                  className={`${classes["mdl-srch-input-div"]}`}
                  value={_partySiteNumber}
                  onChange={(e) => onChangePartySiteNumber(e.target)}
                />
              </div>
            </div>
          </div>

          {isShowModelSearchDetailInputs && (
            <div className="p-0 w-100">
              <div
                className={`ul-grid__row w-100 ${classes["mdl-srch-dtls-separator"]} ${classes["mdl-srch-dtls-input"]}`}
              >
                <div className={`ul-grid__column -x40`}>
                  <div>Project Number</div>
                  <div>
                    <InputText
                      className={`${classes["mdl-srch-input-div"]}`}
                      value={projectNumber}
                      onChange={(e) => onChangeProjectNumber(e.target)}
                    />
                  </div>
                </div>

                <div className={`ul-grid__column -x30`}>
                  <div>Order Number</div>
                  <div>
                    <InputText
                      className={`${classes["mdl-srch-input-div"]}`}
                      value={orderNumber}
                      onChange={(e) => onChangeOrderNumber(e.target)}
                    />
                  </div>
                </div>

                <div className={`ul-grid__column -x30`}>
                  <div>Quote Number</div>
                  <div>
                    <InputText
                      className={`${classes["mdl-srch-input-div"]}`}
                      value={quoteNumber}
                      onChange={(e) => onChangeQuoteNumber(e.target)}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`ul-grid__row w-100 ${classes["mdl-srch-dtl-date-div"]}`}
              >
                <div className={`ul-grid__column -40`}>
                  <div>Collection Name</div>
                  <div>
                    <InputText
                      className={`${classes["mdl-srch-input-div"]}`}
                      value={collectionName}
                      onChange={(e) => onChangeCollectionName(e.target)}
                    />
                  </div>
                </div>
                <div className={`ul-grid__column -x60`}>
                  <div>Created Date</div>
                  <div
                    className={`${classes["from-to-date-div"]} 
                    ${classes["mdl-srch-dtl-date"]}`}
                  >
                    <span>From:&nbsp;</span>
                    <span>
                      <CustomDatePicker
                        selected={fromCreatedDate}
                        onChange={(date) => onChangeFromCreatedDate(date)}
                      />
                    </span>
                    <span>&nbsp;&nbsp;To:&nbsp;</span>
                    <span>
                      <CustomDatePicker
                        selected={toCreatedDate}
                        onChange={(date) => onChangeToCreatedDate(date)}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="ul-grid__row p-0 w-100">
            <div
              className={`ul-grid__column -x100 ${classes["mdl-srch-dtls-separator"]}`}
            >
              <span
                className={`float-right ${classes["mdl-srch-dtls-collapse-btn"]}`}
                onClick={toggleIsShowModelSearchDetailInputs}
              >
                {!isShowModelSearchDetailInputs && (
                  <FontAwesomeIcon icon={faAngleDoubleDown} />
                )}
                {isShowModelSearchDetailInputs && (
                  <FontAwesomeIcon icon={faAngleDoubleUp} />
                )}
              </span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
              marginBottom: ".75rem",
              marginTop: "-.75rem",
            }}
          >
            <div className={`${classes["create-model-div"]} p-0`}>
              <span className={`${classes["search-result-label"]}`}>
                Search Result
              </span>
              <span>
                <button
                  className={`ul-button -medium ${classes["create-new-frm-wb"]}`}
                  onClick={goToUrl}
                >
                  CREATE NEW MODEL IN WORKBENCH
                </button>
              </span>
            </div>
          </div>

          <div className={`p-0 ${classes["models-mapper-container"]}`}>
            <div className={classes["all-models-table-container"]}>
              <label className={classes["selected-models-total"]}>
                ({selectedModels.length}) Models Selected
              </label>

              <DataTable
                rowClassName={getModelsTableRowClassName}
                className={classes["all-models-table"]}
                scrollable
                removableSort
                scrollDirection="both"
                columnResizeMode="expand"
                responsiveLayout="scroll"
                size="small"
                dataKey="assetId"
                loading={isLoading}
                value={modelList}
                selection={combinedSelectedModels}
                onSelectionChange={handleModelSelectionChange}
                isDataSelectable={isModelSelectable}
              >
                {checkboxColumn}
                {renderColumns(true, setModelList)}
              </DataTable>

              <CustomPaginator
                currentPageRef={currentPageRef}
                dropdownOptions={dropdownOptions}
                lazyParams={lazyParams}
                currentPage={currentPage}
                pageCount={pageCount}
                totalPageCount={totalPageCount}
                totalRecordCount={totalRecordCount}
                onChangePage={onChangePage}
              ></CustomPaginator>
            </div>

            <div className={classes["actions-container"]}>
              <button
                disabled={
                  !hasSelectedModel ||
                  baseModelList.length == 1 ||
                  selectedModels.length != 1
                }
                onClick={handleBaseModelAdd}
                className={`ul-button`}
              >
                Add to Base Model No
                <span className="material-icons">chevron_right</span>
              </button>

              <button
                disabled={!hasSelectedModel}
                onClick={handleModelVariantsAdd}
                className={`ul-button`}
              >
                Add to Model Variants{" "}
                <span className="material-icons">chevron_right</span>
              </button>
              <button
                disabled={!hasSelectedBaseModelOrVariant}
                onClick={handleModelsRemove}
                className={`ul-button`}
              >
                <span className="material-icons">chevron_left</span> Remove
                Model(s)
              </button>
            </div>
            <div className={classes["mapped-models-container"]}>
              <div className={classes["base-model-table-container"]}>
                <div className={classes["header"]}>Base Model No.</div>
                <DataTable
                  className={classes["base-model-table"]}
                  value={baseModelList}
                  selection={selectedBaseModels}
                  onSelectionChange={handleBaseModelsSelectionChange}
                  scrollable
                  removableSort
                  scrollDirection="both"
                  columnResizeMode="expand"
                  responsiveLayout="scroll"
                  size="small"
                  dataKey="assetId"
                >
                  {checkboxColumn}
                  {renderColumns(false, setBaseModelList)}
                </DataTable>
              </div>

              <div className={classes["model-variants-table-container"]}>
                <div className={classes["header"]}>Model Variants</div>
                <DataTable
                  className={classes["model-variants-table"]}
                  value={modelVariantsDatatableList}
                  selection={selectedModelVariants}
                  onSelectionChange={handleModelVariantsSelectionChange}
                  scrollable
                  removableSort
                  scrollDirection="both"
                  columnResizeMode="expand"
                  responsiveLayout="scroll"
                  size="small"
                  dataKey="dataKey"
                >
                  <Column
                    selectionMode="multiple"
                    alignFrozen="left"
                    frozen
                    headerStyle={{ width: "3em" }}
                  ></Column>
                  {renderColumns(false, setModelVariantList)}
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ModelSearch;
