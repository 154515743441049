import { React } from "react";
import { useState } from "react";
import SelectRefiner from "../../../shared/refiners/select-refiner/SelectRefiner";
import CertificateListService from "../../../services/CertificateListService";
import classes from "./CertificateProductListRefiners.module.scss";
import { useEffect } from "react";
import certProdListRefinerColumns from "../../../shared/refiners/data/cert-prod-list-refiner-columns";
import CheckboxRefiner from "../../../shared/refiners/checkbox-refiner/CheckboxRefiner";

const CertificateProductListRefiners = (props) => {
  const [certStatusOptions, setCertStatusOptions] = useState([]);
  const [prodStatusOptions, setProdStatusOptions] = useState([]);
  const [ccnOptions, setCcnOptions] = useState([]);
  const [isRefinerLoading, setIsRefinerLoading] = useState(false);

  const {
    refiners,
    addSelectedToYourFilters,
    onClickRemoveRefiner,
    onClickRemoveRefinerGroup,
  } = props;

  useEffect(() => {
    setCertStatusOptions([
      "Active",
      "Obsolete",
      "Under Revision",
      "Withdrawn",
    ]);

    setProdStatusOptions(["Active", "Draft"]);
  }, []);

  const onChangeSelectedFilterText = async function (
    event,
    refinerKey,
    setData
  ) {
    setIsRefinerLoading(true);
    const result = await CertificateListService.getIpCertificateRefiners(
      refinerKey,
      event.filter
    );
    let finalResult = [];
    result.forEach((item) => {
      const existingObj = refiners.find(
        (i) => i.Value === item.name && i.Id === refinerKey
      );
      if (!existingObj) {
        finalResult.push(item);
      }
    });
    setData(finalResult);
    setIsRefinerLoading(false);
  };

  return (
    <div className={classes["cert-refiners-section"]}>
      <div className="ul-card -app">
      <div className={`ul-card__item -bordered ul-card__head font-size-12 ${classes["select-a-filter-text"]}`}>
          Select a filter below to refine your search
        </div>
      </div>

      <div className={classes["cert-refiners-accordion"]}>
        <CheckboxRefiner
          refiners={refiners}
          refinerLabel={certProdListRefinerColumns[0].Value}
          options={certStatusOptions}
          addSelectedToYourFilters={(v) => {
            addSelectedToYourFilters(v, certProdListRefinerColumns[0].Id);
          }}
          onClickRemoveRefiner={onClickRemoveRefiner}
          refinerId={certProdListRefinerColumns[0].Id}
          onClickRemoveRefinerGroup={onClickRemoveRefinerGroup}
        />

        <CheckboxRefiner
          refiners={refiners}
          refinerLabel={certProdListRefinerColumns[1].Value}
          options={prodStatusOptions}
          addSelectedToYourFilters={(v) => {
            addSelectedToYourFilters(v, certProdListRefinerColumns[1].Id);
          }}
          onClickRemoveRefiner={onClickRemoveRefiner}
          refinerId={certProdListRefinerColumns[1].Id}
          onClickRemoveRefinerGroup={onClickRemoveRefinerGroup}
        />

        <SelectRefiner
          refinerLabel={certProdListRefinerColumns[2].Value}
          options={ccnOptions}
          addSelectedToYourFilters={(v) => {
            addSelectedToYourFilters(v, certProdListRefinerColumns[2].Id);
          }}
          isFilterOn={true}
          isMultiSelect={true}
          onFilter={(e) =>
            onChangeSelectedFilterText(
              e,
              certProdListRefinerColumns[2].Id,
              setCcnOptions
            )
          }
          isRefinerLoading={isRefinerLoading}
        />
      </div>
    </div>
  );
};

export default CertificateProductListRefiners;
