import { IGetProjectNumbersQuery } from "../shared/models/refiner-models/get-project-numbers";
import { PaginatedRequestModel } from "../shared/models/service-models/PaginatedRequest.model";
import RequestFactory from "./RequestFactory";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

const ProjectWorkQueueRefinerService = {
  getFlexProjectNumbers: async (body: IGetProjectNumbersQuery) => {
    return await RequestFactory.post(
      GMAE2EURL,
      "/api/project-work-queue-refiners/get-flex-project-numbers",
      body
    );
  },
  getFlexProjectOrderNumbers: async (body: IGetProjectNumbersQuery) => {
    return await RequestFactory.post(
      GMAE2EURL,
      "/api/project-work-queue-refiners/get-flex-project-order-numbers",
      body
    );
  },
  getFlexHandlers: async (body: PaginatedRequestModel) => {
    return await RequestFactory.post(
      GMAE2EURL,
      "/api/project-work-queue-refiners/get-flex-handlers",
      body
    );
  },
};

export default ProjectWorkQueueRefinerService;
