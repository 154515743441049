import React, { MouseEventHandler } from 'react'
import classes from "./ProjectDetailsFlexListItem.module.scss"
import { FlexProjectItem } from '../../ProjectDetails'
import clsx from "clsx";

export interface ProjectDetailsFlexListItemProps {
    item?: FlexProjectItem;
    onClick?: MouseEventHandler | undefined;
}

const ProjectDetailsFlexListItem = (props: ProjectDetailsFlexListItemProps) => {
   const containerClassName = clsx(
        `${classes["projectDetailsFlexListItem__container"]}`,
        {[classes["projectDetailsFlexListItem__container-active"]]: props.item?.isActive},
      );

  return (
    <div className={containerClassName} onClick={props.onClick}>
        <h4 className={`${classes["header"]}`} title={props.item?.name}>{props.item?.name}</h4>
        <p className={`${classes["status"]}`} title={props.item?.status}>{props.item?.status}</p>
    </div>
  )
}

export default ProjectDetailsFlexListItem