import { Dialog } from "primereact/dialog";
import classes from "./BulkSaveConfirmationDialog.module.scss";

type BulkSaveConfirmationDialogProps = {
  visible: boolean;
  onConfirm: () => {};
  onCancel: () => {};
  selectedModelsCount: number;
};

const BulkSaveConfirmationDialog = (props: BulkSaveConfirmationDialogProps) => {
  const header = () => {
    return (
      <>
        <h4 className={classes["modal-header"]}>Batch Edit Confirmation</h4>
        <hr className={classes["modal-header-hr"]} />
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <hr className={classes["modal-footer-hr"]} />
        <div className={`text-center ${classes["footer-content"]}`}>
          <button
            className={`ul-button -app-primary ${classes["btn-yes"]}`}
            onClick={() => props.onConfirm && props.onConfirm()}
          >
            YES
          </button>

          <button
            className={`ul-button -app-secondary ${classes["btn-no"]}`}
            onClick={() => props.onCancel && props.onCancel()}
          >
            NO
          </button>
        </div>
      </>
    );
  };

  return (
    <Dialog
      closable={true}
      draggable={false}
      resizable={false}
      header={header}
      footer={footer}
      onHide={() => props.onCancel && props.onCancel()}
      visible={props.visible}
      style={{ width: "630px" }}
      className={classes["container"]}
    >
      <p className={classes["body-content"]}>
        Changes will apply to ({props.selectedModelsCount}) selected models.
        <br /> <br />
        Would you like to proceed?
      </p>
    </Dialog>
  );
};

export default BulkSaveConfirmationDialog;
