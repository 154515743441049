const certificateProductListGridColumns = [
    {
      id: "modelName",
      value: "Model Name",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: true,
      width: "150px",
      orderNo: 1
    },
    {
      id: "status",
      value: "Model Status",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "80px",
      orderNo: 2
    },
    {
      id: "partySiteNumber",
      value: "Product Owner (Party Site Number)",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "55px",
      orderNo: 3
    },
  
    {
      id: "projectNumber",
      value: "Project Number",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "55px",
      orderNo: 4
    },
    {
      id: "productType",
      value: "Product Type",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "55px",
      orderNo: 5
    },
    {
      id: "familySeries",
      value: " Family or Series",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "100px",
      orderNo: 6
    },
    {
      id: "creationDate",
      value: "Creation date",
      selected: false,
      orderBy: "",
      isShown: true,
      isFrozen: false,
      width: "55px",
      orderNo: 7
    }
  ];
  
  export default certificateProductListGridColumns;
  