import FormContext from "../form/context/FormContext";
import AgentInformation from "./agent-information/AgentInformation";
import ApplicantInformation from "./applicant-information/ApplicantInformation";
import FactorySiteInformation from "./factory-site-information/FactorySiteInformation";
import ManufacturerInformation from "./manufacturer-information/ManufacturerInformation";
import ULFileNumber from "./ul-file-number/ULFileNumber";
import { useSelector } from "react-redux";
import { selectULFileNumber } from "../../../features/generalProductInformation/generalProductInformationSlice";
import {
  existingCertificateNoteMessage,
  fileNumberErrorMessage,
  otherGmaCertErrorMessage,
  uLCBCertErrorMessage,
} from "../../../utils/constants/gpi-form-constants";
const CompanyDetails = ({ showFormError, isReadOnly, disableIntegration }) => {
  const ulFileNumberValues = useSelector(selectULFileNumber);

  const ErrorMessage = ({ existingCertificationErrorMessage }) => {
    return (
      <>
        {existingCertificationErrorMessage}
        <br /> <br />
        {existingCertificateNoteMessage}
      </>
    );
  };

  const getErrorMessageTemplate = () => {
    if (ulFileNumberValues.isULCBCertificateNumber) {
      return (
        <>
          <ErrorMessage
            existingCertificationErrorMessage={uLCBCertErrorMessage}
          />
        </>
      );
    }

    if (ulFileNumberValues.isOtherGMACertificate) {
      return (
        <>
          <ErrorMessage
            existingCertificationErrorMessage={otherGmaCertErrorMessage}
          />
        </>
      );
    }

    if (ulFileNumberValues.hasExistingULFileNumber) {
      return (
        <>
          <ErrorMessage
            existingCertificationErrorMessage={fileNumberErrorMessage}
          />
        </>
      );
    }
  };

  return (
    <>
      <FormContext.Provider value={{ showFormError, isReadOnly }}>
        <h2 className="step-title">Company Details</h2>
        <ApplicantInformation disableIntegration={disableIntegration} />
        <AgentInformation disableIntegration={disableIntegration} />
        <ULFileNumber
          disableIntegration={disableIntegration}
          errorMessageTemplate={getErrorMessageTemplate()}
        />
        <ManufacturerInformation disableIntegration={disableIntegration} />
        <FactorySiteInformation disableIntegration={disableIntegration} />
      </FormContext.Provider>
    </>
  );
};

export default CompanyDetails;
