import {
  MultiSelect,
  MultiSelectChangeParams,
  MultiSelectPanelHeaderTemplateParams,
} from "primereact/multiselect";
import classes from "./PmrMultiselectRefiner.module.scss";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import React from "react";
import { InputText } from "primereact/inputtext";
import useDebounce from "../../../../../hooks/useDebounce";
import { InputNumber } from "primereact/inputnumber";

export interface onPmrMultiSelectModelChange {
  filter: string;
  /**
   * When filter is change current page resets to 0
   */
  currentPage: number;
}

export const sortMultiSelectRefinerByLabel = (
  firstItem: any,
  secondItem: any
) => firstItem.label.localeCompare(secondItem.label);
export const showMultiSelectRefinerByLabel = (value: any) => value.label;

export interface PmrMultiSelectModel extends onPmrMultiSelectModelChange {
  loading: boolean;
  showMore: boolean;
}

export interface PmrMultiSelectFilterProps {
  placeholder?: string;
  minLength?: number;
  type: "number" | "text";
}

export interface PmrMultiSelectRefinerProps {
  onModelChange: <T>(event: onPmrMultiSelectModelChange) => Promise<
    | T[]
    | {
        items: T;
        showMore: boolean;
      }
  >;
  /**
   * Initial model state
   */
  model?: onPmrMultiSelectModelChange;
  itemSize?: number;
  filterProps?: PmrMultiSelectFilterProps;
  resetFilterOnHide?: boolean;
  value?: any;
  onChange?: (event: MultiSelectChangeParams) => void;
  name?: string;
  itemTemplate?: (item: any) => void;
}

export type PmrMultiSelectRefinerRef = {
  modelChange: () => Promise<void> | void;
};

const parseFilterToNumber = (filter: string) => {
  if (filter.trim() === "") return null;
  return parseInt(filter);
};

/**
 * Server side mode only
 */
const PmrMultiSelectRefiner = React.forwardRef<
  PmrMultiSelectRefinerRef,
  PmrMultiSelectRefinerProps
>((props, ref) => {
  const {
    onModelChange,
    model,
    itemSize,
    filterProps,
    resetFilterOnHide,
    name,
    onChange,
    value,
    itemTemplate: overrideItemTemplate,
  } = props;
  const [modelRef, setModelRef] = useState<PmrMultiSelectModel>({
    currentPage: model?.currentPage ?? 0,
    filter: model?.filter ?? "",
    loading: false,
    showMore: false,
  });
  const [options, setOptions] = useState<any[]>([]);
  const [mounted, setMounted] = useState(false);

  const modelChange = async (append: boolean = false) => {
    setModelRef((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const modelChangeResult = await onModelChange({
        currentPage: modelRef.currentPage,
        filter: modelRef.filter,
      });
      const isResultArray = Array.isArray(modelChangeResult);
      let data: any[] = [];

      if (isResultArray) {
        data = modelChangeResult;
      } else {
        data = modelChangeResult.items as any[];
      }

      const hasData = isResultArray
        ? data.length > 0
        : modelChangeResult.showMore;

      if (append && modelRef.currentPage > 0) {
        if (isResultArray && hasData) {
          setOptions((prev) => [...prev, ...data]);
        } else if (!isResultArray) {
          setOptions((prev) => [...prev, ...data]);
        }
      } else {
        setOptions(data as any[]);
      }
      setModelRef((prev) => ({
        ...prev,
        showMore: hasData,
      }));
    } finally {
      setModelRef((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const onHide = () => {
    if (resetFilterOnHide ?? true) {
      setModelRef((prev) => ({
        ...prev,
        filter: "",
        currentPage: 0,
      }));
    }
  };

  const onShowMore = () => {
    setModelRef((prev) => ({
      ...prev,
      currentPage: prev.currentPage + 1,
    }));
  };

  const handlerFilter = useDebounce(() => {
    modelChange();
  }, 800);

  const footerTemplate = () => {
    return (
      modelRef.showMore && (
        <Button
          className={clsx(
            "p-button-text font-weight-bold",
            classes["custom-showmore"],
            classes["color-blue"]
          )}
          disabled={modelRef.loading}
          onClick={onShowMore}
        >
          {modelRef.loading && "Loading..."}
          {!modelRef.loading && "Show More"}
        </Button>
      )
    );
  };

  const headerTemplate = (header: MultiSelectPanelHeaderTemplateParams) => {
    const inputProps = {
      placeholder: filterProps?.placeholder,
      onChange: (e: any) => {
        setModelRef((prev) => ({
          ...prev,
          filter:
            filterProps?.type === "number"
              ? `${e.value ?? ""}`
              : e.target.value,
          currentPage: 0,
        }));
      },
    };

    return (
      <div className="p-multiselect-header">
        <div className={classes["multiselect-wrapper"]}>
          <div className={classes["multiselect-items"]}>
            {header.checkboxElement}
            <div className={classes["filter-container"]}>
              {filterProps && filterProps.placeholder && (
                <div className={classes["filter-hidden-label"]}>
                  {filterProps.placeholder}
                </div>
              )}

              <span
                className={clsx("p-input-icon-right", classes["custom-filter"])}
              >
                <i
                  className={clsx(
                    "pi",
                    modelRef.loading ? "pi-spin pi-spinner" : "pi-search"
                  )}
                ></i>
                {filterProps?.type === "number" ? (
                  <InputNumber
                    {...inputProps}
                    value={parseFilterToNumber(modelRef.filter)}
                    useGrouping={false}
                    allowEmpty
                  />
                ) : (
                  <InputText {...inputProps} value={modelRef.filter} />
                )}
              </span>
            </div>
            {header.closeElement}
          </div>
          {filterProps &&
            filterProps.minLength &&
            !!modelRef.filter &&
            modelRef.filter.length < filterProps.minLength && (
              <span className={classes["color-red"]}>
                Invalid. {filterProps.minLength} numerical chars. needed.
              </span>
            )}
        </div>
      </div>
    );
  };

  const itemTemplate = (item: any) => (
    <div className={clsx(classes["custom-option"])} title={item.label}>
      {overrideItemTemplate && overrideItemTemplate(item)}
      {!overrideItemTemplate && item.label}
    </div>
  );

  const selectedItemTemplate = () => (
    <div className={classes["refiner-dropdown-placeholder"]}>-- Select --</div>
  );

  useEffect(() => {
    if (
      modelRef.filter.length >= (filterProps?.minLength ?? 0) ||
      modelRef.filter === ""
    )
      handlerFilter();
  }, [modelRef.filter]);

  useEffect(() => {
    if (mounted) modelChange(true);
  }, [modelRef.currentPage]);

  useEffect(() => {
    setMounted(true);
  }, []);

  React.useImperativeHandle(
    ref,
    () => ({
      modelChange: async () => {
        await modelChange();
      },
    }),
    [ref]
  );

  return (
    <MultiSelect
      filter
      placeholder="--Select--"
      className={clsx(classes["custom-multiselect"])}
      panelFooterTemplate={footerTemplate}
      panelHeaderTemplate={headerTemplate}
      selectedItemTemplate={selectedItemTemplate}
      itemTemplate={itemTemplate}
      onHide={onHide}
      options={options}
      optionValue={((selected: any) => selected) as any}
      scrollHeight="250px"
      virtualScrollerOptions={{
        lazy: true,
        onLazyLoad: () => {},
        itemSize: itemSize ?? 5,
      }}
      name={name}
      onChange={onChange}
      value={value}
    />
  );
});

export default PmrMultiSelectRefiner;
