import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedIndices,
  updateSelectedIndices,
} from "../features/projectManagementPmr/projectManagementPmrSlice";
import { CheckboxChangeParams } from "primereact/checkbox";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";

export interface ComponentWithIndices {
  depthIndices: number[];
}

const sortByHierarchy = (a: number[], b: number[]) => {
  const maxLength = Math.max(a.length, b.length);

  for (let i = 0; i < maxLength; i++) {
    // Access elements and handle potential undefined values
    const numA = a[i] || 0;
    const numB = b[i] || 0;

    // Compare numeric parts
    if (numA < numB) {
      return -1;
    } else if (numA > numB) {
      return 1;
    }
  }

  // If all parts are equal, the versions are equal
  return 0;
};

const getRangeFromIndices = (
  depthIndices: number[],
  sortedRange: number[][],
  srcSize: number
) => {
  const sortedRangeCopy = sortedRange.map((item) => {
    return item.slice(0, depthIndices.length + 1);
  });
  const fromRange = sortedRangeCopy[0].slice();
  const toRange = sortedRangeCopy[1].slice();

  let from = fromRange.pop() ?? 0;
  let to = toRange.pop() ?? 0;

  if (depthIndices.length < 2) {
    from++;
    if (from === to) return null; // no in-between ranges
  }

  const hasFoundEnd = isEqual(depthIndices, toRange);
  const hasFoundStart = isEqual(depthIndices, fromRange);

  if (hasFoundStart) {
    return [from, hasFoundEnd ? to : srcSize];
  }
  if (hasFoundEnd) {
    return [0, to + 1];
  }
  return null;
};

export default function usePmrShiftSelect(
  depthIndices?: number[],
  items?: any[]
) {
  const selectedIndices = useSelector(selectSelectedIndices);
  const dispatch = useDispatch();
  const [rangeItems, setRangeItems] = useState<any[]>([]);

  const generateRangeItems = () => {
    if (!items) return;
    if (!selectedIndices.last || !selectedIndices.shift) return;

    const sortedRange = [selectedIndices.last, selectedIndices.shift].sort(
      sortByHierarchy
    );

    let range: number[] | null = getRangeFromIndices(
      depthIndices ?? [],
      sortedRange,
      items.length
    );

    if (range != null) setRangeItems(items.slice(...range));
  };

  const register = (indices: number[], event: CheckboxChangeParams) => {
    let copiedSelectedIndices = structuredClone(selectedIndices);
    const shiftKey = (event.originalEvent as any).shiftKey;
    const activeKey = shiftKey ? "shift" : "last";

    if (isEqual(copiedSelectedIndices[activeKey], indices) && !event.checked) {
      copiedSelectedIndices[activeKey] = null;
    } else if (event.checked) {
      copiedSelectedIndices[activeKey] = indices;
    }

    if (!shiftKey) {
      copiedSelectedIndices.shift = null;
    }

    dispatch(updateSelectedIndices(copiedSelectedIndices));
  };

  useEffect(() => {
    generateRangeItems();
  }, [selectedIndices]);

  return {
    register,
    rangeItems,
  };
}
