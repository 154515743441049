import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCaretDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import classes from "./Attachments.module.scss";
import CertificateListService from "../../../../../services/CertificateListService";
import useToastr from "../../../../../hooks/useToastr";
import { useDispatch } from "react-redux";
import { reloadCertificateDetails } from "../../../../../features/certificateList/certificateListSlice";
import { saveAs } from "file-saver";

const storedCertificateUrl = process.env.REACT_APP_AZURE_STORED_CERTIFICATE_URL;

const initialFiles = [
  {
    id: 1,
    display: "Certificate",
    attachments: [],
    isExpand: false,
  },
  {
    id: 2,
    display: "Test Reports",
    attachments: [],
    isExpand: false,
  },
  {
    id: 3,
    display: "Others",
    attachments: [],
    isExpand: false,
  },
];

const Attachments = ({ attachments }) => {
  const [files, setFiles] = useState(initialFiles);
  const { showSuccess, showError } = useToastr();
  const dispatch = useDispatch();

  const downloadFile = async (file) => {
    const data = {};
    data.fileId = file.id;
    data.fileName = file.fileName;

    await CertificateListService.downloadCertificateAttachment(data).then(
      function (response) {
        if (response) {
          saveAs(response, file.fileName);
        }
      }
    );
  };

  const onExpand = (item) => {
    item.isExpand = !item.isExpand;
    const updatedFiles = files.map((f) => (f.id === item.id ? item : f));
    setFiles(updatedFiles);
  };

  const onDelete = async (file) => {
    const model = {
      fileId: file.id,
      fileName: file.fileName,
    };
    const isDeleted = await CertificateListService.deleteStoreCertificate(
      model
    );

    if (isDeleted) {
      showSuccess("Success!", "Attachment has been deleted");
      dispatch(reloadCertificateDetails(true));
    } else {
      showError("Error!", "Attachment has not been deleted");
    }
  };

  useEffect(() => {
    const withAttachments = files.map((f) => ({
      ...f,
      attachments:
        attachments && attachments.length > 0
          ? attachments.filter((a) => a.attachmentType === f.display)
          : [],
      isExpand:
        attachments && attachments.length > 0
          ? attachments.filter((a) => a.attachmentType === f.display).length > 0
            ? true
            : false
          : false,
    }));
    setFiles(withAttachments);
  }, [attachments]);

  return (
    <div className={`${classes["certificate-attachments__container"]}`}>
      <div className={classes["title__section"]}>Attachments</div>
      {files?.map((f) => (
        <div key={f.id}>
          <div onClick={() => onExpand(f)} className={classes["container"]}>
            <span>{f.display}</span>
            <div>
              <FontAwesomeIcon icon={f.isExpand ? faCaretDown : faCaretRight} />
            </div>
          </div>
          {f.isExpand && f.attachments.length === 0 && (
            <span className={classes["files"]}>No Files</span>
          )}
          {f.isExpand &&
            f.attachments.map((a) => (
              <div key={a.id} className={classes["files"]}>
                <a
                  rel="noreferrer"
                  //href={buildDownloadLink(a)}
                  className={classes["link"]}
                  target="_blank"
                  onClick={() => downloadFile(a)}
                >
                  {a.fileName}
                </a>
                <FontAwesomeIcon
                  icon={faTrash}
                  className={classes["delete-icon"]}
                  onClick={() => onDelete(a)}
                />
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default Attachments;
