import { downloadFile } from "../utils/helpers/file.helpers";
import RequestFactory from "./RequestFactory";

const generalProductInformationUrl =
  process.env.REACT_APP_GENERAL_PRODUCT_INFORMATION_URL;
const documentUrl = process.env.REACT_APP_DOCUMENT_URL;
const functionUrl = process.env.REACT_APP_GMAE2E_FUNC_URL;

const GeneralProductInformationService = {
  query: async (query) =>
    await RequestFactory.post(generalProductInformationUrl, `/graphql`, query),
  getCustomerContacts: async (partySiteNumber) =>
    await RequestFactory.get(
      documentUrl,
      `/oracleintegration/get-company-by-partysite?partySiteNumber=${partySiteNumber}`
    ),
  createGeneralProductInfo: async (data, giMappingIds) => {
    let path = `/api/general-product-info/create`;

    if (giMappingIds?.length)
      path += "?" + giMappingIds.map((x) => "mappingIds=" + x).join("&");

    return await RequestFactory.post(generalProductInformationUrl, path, data);
  },
  updateGeneralProductInfo: async (data) =>
    await RequestFactory.post(
      generalProductInformationUrl,
      `/api/general-product-info/update`,
      data
    ),
  updateGpiFormName: async (gpiFormId, newFormName) =>
    await RequestFactory.patch(
      generalProductInformationUrl,
      `/api/general-product-info/update-gpi-form-name?gpiFormId=${gpiFormId}&newFormName=${newFormName}`,
      null
    ),
  sendEmailForCustomerCreation: async (data) =>
    await RequestFactory.post(
      generalProductInformationUrl,
      `/api/general-product-info/send-email-for-customer-creation`,
      data
    ),
  addAttachment: async (formData, uploadProgressEvent) =>
    await RequestFactory.upload(
      generalProductInformationUrl,
      "/api/general-product-info/attachment",
      formData,
      uploadProgressEvent
    ),
  getAttachmentSignedUrl: async (filepath) =>
    await RequestFactory.get(
      generalProductInformationUrl,
      "/api/general-product-info/attachment-signed-url?filepath=" + filepath
    ),
  deleteBrandOrTrademarkAttachment: async (attachmentId) =>
    await RequestFactory.delete(
      generalProductInformationUrl,
      "/api/general-product-info/brand-or-trademark-attachment/" + attachmentId
    ),
  deleteGeneralProductInfoItems: async (ids) => {
    return await RequestFactory.post(
      generalProductInformationUrl,
      "/api/general-product-info/delete-general-product-info-items",
      ids
    );
  },
  ulProjectHandlerDetails: async () =>
    await RequestFactory.get(
      generalProductInformationUrl,
      `/api/general-product-info/get-handler-details`
    ),

  getListSchemes: async () =>
    await RequestFactory.get(documentUrl, `/reference/listscheme`),
  getLocalRepListScheme: async () =>
    await RequestFactory.get(documentUrl, `/document/local_rep_list_schemes`),
  getSegmentByScheme: async (schemeId) =>
    await RequestFactory.get(
      documentUrl,
      `/reference/getsegment_by_scheme?schemeId=${schemeId}`
    ),
  gpiFormNameExists: async (formName) =>
    await RequestFactory.get(
      generalProductInformationUrl,
      "/api/general-product-info/gpi-form-name-exists?formName=" + formName
    ),
  getExistingOrGenerateNewGPICustomerInputValidationResult: async (
    generalProductInfo
  ) =>
    await RequestFactory.post(
      generalProductInformationUrl,
      "/api/general-product-info/get-existing-or-generate-new-customer-input-validation-result",
      generalProductInfo
    ),
  getCustomerCompanyDetailsInputValidationResult: async (generalProductInfo) =>
    await RequestFactory.post(
      generalProductInformationUrl,
      "/api/general-product-info/get-customer-company-details-input-validation-result",
      generalProductInfo
    ),
  getUserContactDetails: async (searchText, flexProjectId = null) =>
    await RequestFactory.get(
      generalProductInformationUrl,
      `/api/general-product-info/get-user-contact-details?searchText=${searchText}&flexProjectId=${flexProjectId}`
    ),
  getGPIFormConfig: async () =>
    await RequestFactory.get(
      generalProductInformationUrl,
      "/api/general-product-info/form-config"
    ),
  downloadAsWord: async (id) => {
    await RequestFactory.downloadRaw(
      generalProductInformationUrl,
      "/api/general-product-info/download-as-word?id=" + id
    ).then((result) => {
      downloadFile(result);
    });
  },
  downloadMultipleAsWord: async (ids) => {
    await RequestFactory.downloadRaw(
      generalProductInformationUrl,
      "/api/general-product-info/download-multiple-as-word",
      ids
    ).then((result) => {
      downloadFile(result);
    });
  },
  getLastModelsChangesSnapshot: async (generalProductInfoId) =>
    await RequestFactory.get(
      generalProductInformationUrl,
      "/api/general-product-info/get-last-models-changes-snapshot?generalProductInfoId=" +
        generalProductInfoId
    ),
  joinGpiValidationGroup: async (generalProductInfoId) =>
    await RequestFactory.post(
      functionUrl,
      "/api/jointogpivalidationgroup",
      generalProductInfoId
    ),
  retryGPIValidation: async (generalProductInfoId) =>
    await RequestFactory.get(
      generalProductInformationUrl,
      "/api/general-product-info/retry-gpi-validation?gpiId=" +
        generalProductInfoId
    ),
  getGPIDetails: async (payload) =>
    await RequestFactory.post(
      generalProductInformationUrl,
      "/api/general-product-info/gpi-details",
      payload
    ),
  getCopyGPIForm: async (gpiId, entity) =>
    await RequestFactory.get(
      generalProductInformationUrl,
      `/api/general-product-info/copy-gpi-form/${gpiId}?entity=${entity}`
    ),
  getGPIListFilters: async (payload) =>
    await RequestFactory.get(
      generalProductInformationUrl,
      `/api/general-product-info/list/filters?filterType=${payload.filterType}&filterText=${payload.filterText}`
    ),
  getGPIListPaginated: async (payload) =>
    await RequestFactory.post(
      generalProductInformationUrl,
      `/api/general-product-info/list`,
      payload
    ),
};

export default GeneralProductInformationService;
