import { useEffect, useState } from "react";
import classes from "./ProjectDetails.module.scss";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import ProjectDetailsInfo from "./project-details-info/ProjectDetailsInfo";
import ProjectDetailsFlexList from "./project-details-flex-list/ProjectDetailsFlexList";
import ProjectDetailsOrderLineGrid from "./project-details-order-line-grid/ProjectDetailsOrderLineGrid";
import ProjectService from "../../../services/ProjectService";
import ProjectDetailsLoader from "./project-details-loader/ProjectDetailsLoader";
import clsx from "clsx";
import { GMAWBProjectFlexProjectsModel } from "../../../shared/models/GMAWBProjectFlexProjectsModel";
import { Button } from "primereact/button";
import useDebounce from "../../../hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import {
  selectForceReloadProject,
  selectRefinerLineResult,
  updateForceReloadProject,
} from "../../../features/projectManagement/projectManagementSlice";
import { selectRefinerState } from "../../../features/projectManagementPmr/projectManagementPmrSlice";

interface ProjectDetailsProps {
  projectId: string;
}

export interface ProjectDetailInfo {
  header: string;
  keyValuePair: ProjectDetailInfoItem[];
}

export interface ProjectDetailInfoItem {
  key: string;
  value?: string | JSX.Element;
}

export interface FlexProjectItem extends GMAWBProjectFlexProjectsModel {
  isActive: boolean;
}

export interface FlexProjectOrderLineItem {
  lineNumber: string;
  name: string;
  status: string;
}

const ProjectDetails = (props: ProjectDetailsProps) => {
  const GMAWBProjectSummaryHeader = "GMAWB Project Summary";
  const FLEXProjectSummaryHeader = "FLEX Project Summary";
  const FLEXProjectDetailsCustomerHeader =
    "FLEX Project Details - Customer Information";
  const OrderInformationHeader = "Order Information";
  const AgentInformationHeader = "Agent Information";

  const [projectDetailsSection, setProjectDetailsSection] = useState<
    ProjectDetailInfo[]
  >([
    {
      header: GMAWBProjectSummaryHeader,
      keyValuePair: [
        { key: "GMAWB Project Template:" },
        { key: "GMAWB Project ID:" },
        { key: "GMAWB Project Name:" },
      ],
    },
    {
      header: FLEXProjectSummaryHeader,
      keyValuePair: [
        { key: "Project Name:" },
        { key: "Oracle Project Number:" },
        { key: "Project Scope:" },
        { key: "Project Hold Reason:" },
      ],
    },
    {
      header: FLEXProjectDetailsCustomerHeader,
      keyValuePair: [
        { key: "Customer Company Contact:" },
        { key: "Customer Company Contact Email:" },
        { key: "Customer Company Address:" },
        { key: "CEM / Order Owner:" },
        { key: "Order Booked Date" },
        { key: "Total Order Price:" },
      ],
    },
    {
      header: OrderInformationHeader,
      keyValuePair: [
        { key: "Order Number:" },
        { key: "Quote Number:" },
        { key: "Customer PO:" },
        { key: "Sales Person:" },
        { key: "Order Creator:" },
      ],
    },
    {
      header: AgentInformationHeader,
      keyValuePair: [
        { key: "Agent Company Name:" },
        { key: "Agent Company Contact:" },
        { key: "Agent Company Contact Number:" },
        { key: "Agent Party Site Number:" },
      ],
    },
  ]);

  const [flexOrderLines, setFlexOrderLines] = useState<
    FlexProjectOrderLineItem[]
  >([]);

  const [isRightPanelHidden, setIsRightPanelHidden] = useState<boolean>(false);

  const refinerLineResult = useSelector(selectRefinerLineResult);
  const refinerState = useSelector(selectRefinerState);

  const [flexProjects, setFlexProjects] = useState<FlexProjectItem[]>([]);

  const filterFlexProjects = () => {
    const { orderLines } = refinerState.value;

    if (refinerLineResult.flexProjectIds === null || !orderLines)
      return flexProjects;

    return flexProjects.filter((project) =>
      refinerLineResult.flexProjectIds?.includes(project.id)
    );
  };

  const filteredFlexProjects = filterFlexProjects();

  const [untouchedFlexProjects, setUntouchedFlexProjects] = useState<
    FlexProjectItem[]
  >([]);

  const [isFlexProjectsLoading, setIsFlexProjectsLoading] =
    useState<boolean>(true);

  const [isFlexProjectDetailLoading, setIsFlexProjectDetailLoading] =
    useState<boolean>(false);

  const [searchCriteria, setSearchCriteria] = useState<string>("");
  const orderInfoUrl = process.env.REACT_APP_FLEX_PROJECT_ORDER_INFO_URL;

  const forceReloadProject = useSelector(selectForceReloadProject);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setFlexProjects([]);
      setFlexOrderLines([]);
    };
  }, []);

  useEffect(() => {
    fetchFlexProjects();
  }, [props.projectId]);

  useEffect(() => {
    if (flexProjects.some((project) => project.isActive)) {
      const delayDebounce = setTimeout(() => fetchFlexProjectDetails(), 500);
      return () => clearTimeout(delayDebounce);
    }
  }, [flexProjects, props.projectId]);

  useEffect(() => {
    if (forceReloadProject === true) {
      fetchFlexProjects();
      fetchFlexProjectDetails();
      dispatch(updateForceReloadProject(false));
    }
  }, [forceReloadProject]);

  const fetchFlexProjects = async () => {
    try {
      setIsFlexProjectsLoading(true);
      const response = await ProjectService.getGMAWBFlexProjects(
        props.projectId
      );
      const flexProjects = response.data.map((item: FlexProjectItem) => ({
        id: item.id,
        name: item.name,
        status: item.status,
        isActive: false,
      }));

      if (flexProjects.length > 0) {
        flexProjects[0].isActive = true;
      }

      setFlexProjects(flexProjects);
      setUntouchedFlexProjects(flexProjects);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFlexProjectsLoading(false);
    }
  };

  const fetchFlexProjectDetails = async () => {
    const activeProject = flexProjects.find((project) => project.isActive);
    if (!activeProject) return;

    try {
      setIsFlexProjectDetailLoading(true);
      const response = await ProjectService.getGMAWBFlexProjectDetails(
        activeProject.id,
        props.projectId
      );
      const details = mapResponseToProjectDetails(response.data);
      setProjectDetailsSection(details.flexProjectDetails);
      setFlexOrderLines(details.orderLines);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFlexProjectDetailLoading(false);
    }
  };

  const mapResponseToProjectDetails = (responseItem: any) => {
    const orderLines = responseItem.flexProjectOrderLines?.map(
      (orderLine: FlexProjectOrderLineItem) => ({
        lineNumber: orderLine.lineNumber,
        name: orderLine.name,
        status: orderLine.status,
      })
    );

    const flexProjectDetails: ProjectDetailInfo[] = [
      {
        header: GMAWBProjectSummaryHeader,
        keyValuePair: [
          {
            key: "GMAWB Project Template:",
            value: `${responseItem.gmawbProjectTemplate}`,
          },
          {
            key: "GMAWB Project ID:",
            value: `${responseItem.gmawbProjectId}`,
          },
          {
            key: "GMAWB Project Name:",
            value: `${responseItem.gmawbProjectName}`,
          },
        ],
      },
      {
        header: FLEXProjectSummaryHeader,
        keyValuePair: [
          {
            key: "Project Name:",
            value: `${responseItem.flexProjectName}`,
          },
          {
            key: "Oracle Project Number:",
            value: `${responseItem.flexOracleProjectNumber}`,
          },
          {
            key: "Estimated Completion Date",
            value: `${responseItem.flexProjectDetailsEndDate}`,
          },
          {
            key: "Project Scope:",
            value: `${responseItem.flexProjectScope}`,
          },
          {
            key: "Project Hold Reason:",
            value: `${responseItem.flexProjectHoldReason}`,
          },
        ],
      },
      {
        header: FLEXProjectDetailsCustomerHeader,
        keyValuePair: [
          {
            key: "Customer Company Contact:",
            value: `${responseItem.flexProjectDetailsCustomerCompanyContact}`,
          },
          {
            key: "Customer Company Contact Email:",
            value: `${responseItem.flexProjectDetailsCustomerCompanyContactEmail}`,
          },
          {
            key: "Customer Company Address:",
            value: `${responseItem.flexProjectDetailsCustomerCompanyAddress}`,
          },
          {
            key: "CEM / Order Owner:",
            value: `${responseItem.flexProjectDetailsCEMOrderOwner}`,
          },
          {
            key: "Order Booked Date:",
            value: `${responseItem.flexProjectDetailsDateOrdered}`,
          },
          {
            key: "Total Order Price:",
            value: `${responseItem.flexProjectDetailsTotalOrderPrice}`,
          },
        ],
      },
      {
        header: OrderInformationHeader,
        keyValuePair: [
          {
            key: "Order Number:",
            value: `${responseItem.orderInformationOrderNumber}`,
          },
          {
            key: "Quote Number:",
            value: `${responseItem.orderInformationQuoteNumber}`,
          },
          {
            key: "Customer PO:",
            value: `${responseItem.orderInformationCustomerPO}`,
          },
          {
            key: "Sales Person:",
            value: `${responseItem.orderInformationSalesPerson}`,
          },
          {
            key: "Order Creator:",
            value:
              responseItem.orderInformationOrderCreator &&
              responseItem.orderInformationOrderCreator !== "-" ? (
                `${responseItem.orderInformationOrderCreator}`
              ) : (
                <p className={classes["order-information-note"]}>
                  {`Note: Order Creator detail cannot be viewed from FLEX as of this time.
                Click this `}
                  <a
                    href={`${orderInfoUrl}${
                      flexProjects.find((project) => project.isActive)?.id
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    link
                  </a>
                  {` to navigate to the FLEX Project's Order Information page.`}
                </p>
              ),
          },
        ],
      },
      {
        header: AgentInformationHeader,
        keyValuePair: [
          {
            key: "Agent Company Name:",
            value: `${responseItem.agentInformationCompanyName}`,
          },
          {
            key: "Agent Company Contact:",
            value: `${responseItem.agentInformationCompanyContact}`,
          },
          {
            key: "Agent Company Contact Number:",
            value: `${responseItem.agentInformationCompanyContactNumber}`,
          },
          {
            key: "Agent Party Site Number:",
            value: `${responseItem.agentInformationPartySiteNumber}`,
          },
        ],
      },
    ];

    return { flexProjectDetails, orderLines };
  };

  const execSearchCriteria = useDebounce(() => {
    let copyUntouchedFlexProjects = structuredClone(untouchedFlexProjects);
    copyUntouchedFlexProjects = copyUntouchedFlexProjects.filter(
      (projectItem) =>
        projectItem.name
          .toLowerCase()
          .includes(searchCriteria.toLowerCase().trim())
    );

    if (copyUntouchedFlexProjects.length > 0)
      copyUntouchedFlexProjects[0].isActive = true;

    setFlexProjects(copyUntouchedFlexProjects);
    setFlexOrderLines([]);
  }, 400);

  useEffect(() => {
    execSearchCriteria();
  }, [searchCriteria]);

  const NoRecordFoundTemplate = () => {
    return (
      <Card className={`${classes["no-record-found__container"]}`}>
        <p className={`${classes["label"]}`}>--- No records found. ---</p>
      </Card>
    );
  };

  const projectDetailsClassName = clsx(
    [classes["project-details__container"]],
    {
      [classes["project-details__container-no-record"]]: !flexProjects.some(
        (projects) => projects.isActive
      ),
    },
    {
      [classes["project-details__container-collapsed"]]: isRightPanelHidden,
    }
  );

  return (
    <div className={projectDetailsClassName}>
      <Card className={`${classes["left-panel"]}`}>
        {isFlexProjectsLoading && <ProjectDetailsLoader />}
        {!isFlexProjectsLoading && (
          <>
            <div className={`${classes["search-box__container"]}`}>
              <span className="p-input-icon-right">
                <i className="pi pi-search" />
                <InputText
                  className={`${classes["search-box"]}`}
                  placeholder="Search"
                  onChange={(e) => setSearchCriteria(e.target.value.trim())}
                />
              </span>
            </div>

            <ProjectDetailsFlexList
              items={filteredFlexProjects}
              setFlexProjects={setFlexProjects}
            />
          </>
        )}
      </Card>
      {!filteredFlexProjects.some((projects) => projects.isActive) && (
        <NoRecordFoundTemplate />
      )}
      {filteredFlexProjects.some((projects) => projects.isActive) && (
        <>
          <Card className={`${classes["center-panel"]}`}>
            <ProjectDetailsInfo
              header={GMAWBProjectSummaryHeader}
              isLoading={isFlexProjectDetailLoading}
              isRightPanelHidden={isRightPanelHidden}
              fieldKeyValue={
                projectDetailsSection.find(
                  (section) => section.header === GMAWBProjectSummaryHeader
                )?.keyValuePair
              }
            />
            <ProjectDetailsInfo
              header={FLEXProjectSummaryHeader}
              isLoading={isFlexProjectDetailLoading}
              isRightPanelHidden={isRightPanelHidden}
              fieldKeyValue={
                projectDetailsSection.find(
                  (section) => section.header === FLEXProjectSummaryHeader
                )?.keyValuePair
              }
            />
            <ProjectDetailsOrderLineGrid
              items={flexOrderLines}
              isLoading={isFlexProjectDetailLoading}
            />
          </Card>
          {!isRightPanelHidden && (
            <Card className={`${classes["right-panel"]}`}>
              <ProjectDetailsInfo
                header={FLEXProjectDetailsCustomerHeader}
                isLoading={isFlexProjectDetailLoading}
                isRightPanelHidden={isRightPanelHidden}
                fieldKeyValue={
                  projectDetailsSection.find(
                    (section) =>
                      section.header === FLEXProjectDetailsCustomerHeader
                  )?.keyValuePair
                }
              />
              <ProjectDetailsInfo
                header={OrderInformationHeader}
                isLoading={isFlexProjectDetailLoading}
                isRightPanelHidden={isRightPanelHidden}
                fieldKeyValue={
                  projectDetailsSection.find(
                    (section) => section.header === OrderInformationHeader
                  )?.keyValuePair
                }
              />
              <ProjectDetailsInfo
                header={AgentInformationHeader}
                isLoading={isFlexProjectDetailLoading}
                isRightPanelHidden={isRightPanelHidden}
                fieldKeyValue={
                  projectDetailsSection.find(
                    (section) => section.header === AgentInformationHeader
                  )?.keyValuePair
                }
                flexProjectId={
                  flexProjects.find((project) => project.isActive)?.id
                }
              />
            </Card>
          )}

          <Card className={`${classes["collapse-panel"]}`}>
            <Button
              className={`${classes["collapse-button"]}`}
              icon={
                isRightPanelHidden
                  ? "pi pi-angle-double-left"
                  : "pi pi-angle-double-right"
              }
              onClick={(e) => setIsRightPanelHidden(!isRightPanelHidden)}
            />
          </Card>
        </>
      )}
    </div>
  );
};

export default ProjectDetails;
