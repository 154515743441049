import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import classes from "./GpiValidationResultDialog.module.scss";

const errorTypes = {
  blankRequiredField: "blankRequiredField",
  invalidField: "invalidField",
};

const GpiValidationResultDialog = ({ visible, onClose, steps }) => {
  const errorType = steps.some((s) => s.hasBlankRequiredField)
    ? errorTypes.blankRequiredField
    : errorTypes.invalidField;

  const title =
    errorType === errorTypes.blankRequiredField
      ? "Incomplete GPI Form"
      : "Error in GPI Form";

  const initialMessage =
    errorType === errorTypes.blankRequiredField
      ? "There are blank fields in the following tabs/pages:"
      : "There are fields that have incorrect/invalid format in the following tabs/pages:";

  const closingMessage =
    errorType === errorTypes.blankRequiredField
      ? "Please complete the forms then try again."
      : "Please update the forms then try again.";

  const stepsWithErrors = steps.filter((s) =>
    errorType === errorTypes.blankRequiredField
      ? s.hasBlankRequiredField
      : s.hasInvalidData
  );

  const header = () => {
    return (
      <>
        <h4 className={classes["modal-header"]}>{title}</h4>
        <hr className={classes["modal-header-hr"]} />
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <hr className={classes["modal-footer-hr"]} />
        <div className={`text-center ${classes["footer-content"]}`}>
          <Button
            onClick={onClose}
            className={`ul-button -app-primary ${classes["btn-ok"]}`}
          >
            OK
          </Button>
        </div>
      </>
    );
  };

  return (
    <Dialog
      closable={true}
      draggable={false}
      resizable={false}
      visible={visible}
      header={header}
      footer={footer}
      onHide={onClose}
      className={classes["container"]}
      style={{ width: "620px" }}
    >
      <div className={classes["modal-content"]}>
        <p className={classes["message"]}>{initialMessage}</p>

        <ul>
          {stepsWithErrors.map((s) => (
            <li key={s.label}>{s.label}</li>
          ))}
        </ul>

        <p className={`${classes["message"]} ${classes["closing-message"]}`}>
          {closingMessage}
        </p>
      </div>
    </Dialog>
  );
};

export default GpiValidationResultDialog;
