export const isObjectInvalid = (obj: any) => {
  if (obj == undefined || obj == null) return true;
  return obj && Object.keys(obj).length == 0;
};

export const isValidNumber = (value: any) => {
  return /^[-+]?\d*\.?\d*$/.test(value);
};

export const objectToFormData = (
  model: any,
  form: FormData = new FormData(),
  namespace = ""
): FormData => {
  let formData = form || new FormData();
  for (let propertyName in model) {
    if (
      !model.hasOwnProperty(propertyName) ||
      model[propertyName] == null ||
      model[propertyName] == undefined
    )
      continue;
    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof Date) {
      formData.append(formKey, model[propertyName]);
    } else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element: any, index: any) => {
        const tempFormKey = `${formKey}[${index}]`;
        objectToFormData(element, formData, tempFormKey);
      });
    } else if (
      typeof model[propertyName] === "object" &&
      !(model[propertyName] instanceof File)
    )
      objectToFormData(model[propertyName], formData, formKey);
    else if (model[propertyName] instanceof File) {
      let newFormKey = namespace
        ? `${namespace}.${propertyName}`
        : propertyName;
      formData.append(
        newFormKey,
        model[propertyName],
        model[propertyName]["name"]
      );
    } else {
      formData.append(formKey, model[propertyName]);
    }
  }
  return formData;
};

export const cleanEmpty: (obj: any, defaults?: any[]) => any = function (
  obj,
  defaults = [undefined, null, NaN, "", {}, false]
) {
  if (defaults.includes(obj)) return;

  if (Array.isArray(obj))
    return obj
      .map((v) => (v && typeof v === "object" ? cleanEmpty(v, defaults) : v))
      .filter((v) => !defaults.includes(v));

  return Object.entries(obj).length
    ? Object.entries(obj)
        .map(([k, v]) => [
          k,
          v && typeof v === "object" ? cleanEmpty(v, defaults) : v,
        ])
        .filter(([k, v]) => {
          let valid = !defaults.includes(v);
          if (typeof v === "object" && v) {
            valid = Object.keys(v).length > 0;
          }

          if (typeof v === "string") {
            valid = v.trim() !== "";
          }
          return valid;
        })
        .reduce(
          (a, [k, v]) => (defaults.includes(v) ? a : { ...a, [k]: v }),
          {}
        )
    : obj;
};

export const transformOptionLabelValueToValue: any = (formValues: any) => {
  if (Array.isArray(formValues)) {
    return formValues.map((item) => {
      if (Object.hasOwn(item, "label") || Object.hasOwn(item, "value")) {
        return item.value ?? item.label;
      }
      return item;
    });
  }

  if (Object.entries(formValues).length) {
    return Object.entries(formValues)
      .map(([key, value]) => [
        key,
        value && typeof value === "object"
          ? transformOptionLabelValueToValue(value)
          : value,
      ])
      .reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]: value,
        }),
        {}
      );
  } else {
    return formValues;
  }
};

export const toCamel = (o: any) => {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {} as any;
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};
