import RequestFactory from "./RequestFactory";

const documentUrl = process.env.REACT_APP_DOCUMENT_URL;
const certificateUrl = process.env.REACT_APP_CERTIFICATE_URL;

const ProductTypeService = {
  downloadProductTypeUploadTemplate: async (data) => {
    return await RequestFactory.download(
      certificateUrl,
      "/ipintegration/download-product-type-upload-template",
      data
    );
  },
  getIpModels: async (data) =>
    await RequestFactory.post(
      documentUrl,
      "/ipintegration/get-ip-models-paginated",
      data
    ),
  getIpModelDetails: async (data) =>
    await RequestFactory.post(
      documentUrl,
      "/ipintegration/get-ip-models",
      data
    ),
  getIpProductType: async () =>
    await RequestFactory.get(documentUrl, "/ipintegration/get-ip-product-type"),
  getProductTypesUploadTemplate: async () =>
    await RequestFactory.get(
      certificateUrl,
      "/ipintegration/get_product_type_upload_template"
    ),
  getProductTypeBulkUploadTemplatesFromSharePoint: async () =>
    await RequestFactory.get(
      certificateUrl,
      "/ipintegration/get-product-type-bulk-upload-templates"
    ),
  getCompanyList: async (
    customerName,
    partySiteNumber,
    oracleAccountNumber,
    itemsPerPage,
    currentPageNumber
  ) =>
    await RequestFactory.get(
      documentUrl,
      `/oracleintegration/get-company-list?partysiteNumber=${partySiteNumber}&accountNumber=${oracleAccountNumber}&partyName=${customerName}&itemsPerPage=${itemsPerPage}&currentPageNumber=${currentPageNumber}`
    ),
  getFileNumberVolumes: async (data) =>
      await RequestFactory.post(
        certificateUrl,
        "/ipintegration/get-file-number-volumes",
        data
      ),
  getFileNumberSections: async (data) =>
      await RequestFactory.post(
        certificateUrl,
        "/ipintegration/get-file-number-sections",
        data
      ),
  getFileNumberDetails: async (data) =>
      await RequestFactory.post(
        certificateUrl,
        "/ipintegration/get-filenumber-details",
        data
      ),
  getCertificateDetails: async (data) => 
      await RequestFactory.post(
        certificateUrl,
        "/ipintegration/get-certificate-details",
        data
      ),
};

export default ProductTypeService;
