import { useSelector } from "react-redux";
import { values } from "./../../../../features/dkList/dkListSlice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DataTableSortProps } from "../../DocumentKnowledge";
import DocumentKnowledgeService from "../../../../services/DocumentKnowledgeService";

interface CommonDocumentProps {
  showNotUploaded: boolean;
  isJapanese: boolean;
  sorting: DataTableSortProps;
  setSorting: React.Dispatch<React.SetStateAction<DataTableSortProps>>;
  isCollapsed: boolean;
  collapsedTemplate: (rowData: any, field: any) => {};
}

const CommonDocuments = (props: CommonDocumentProps) => {
  const value = useSelector(values);
  const commonDocs = props.showNotUploaded
    ? value.commonDocs.filter((a: any) => a.link == null)
    : value.commonDocs;

  const fieldsEnglish = {
    segment: "Segment",
    isEssential: "Is Essential",
    sendToCustomer: "Send to Customer",
    agents: "Agents",
    documentNameEn: "Document Name",
    internalRemarks: "Internal Remarks",
    remarksEn: "Remarks English",
    sign: "Sign Y/N",
    documentFilename: "Document Filename",
  };

  const fieldsJapanese = {
    segment: "Segment",
    isEssential: "Is Essential",
    sendToCustomer: "Send to Customer",
    agents: "Agents",
    documentNameJpn: "Document Name (Japanese)*",
    internalRemarks: "Internal Remarks",
    remarksJpn: "Remarks Japanese*",
    sign: "Sign Y/N",
    documentFilename: "Document Filename",
  };

  const filenameTemplate = (props: any) => {
    return (
      <button
        type="button"
        className="btn-link"
        onClick={() =>
          DocumentKnowledgeService.downloadR2CDocument(
            props.id,
            props.documentFilename
          )
        }
      >
        {props.documentFilename}
      </button>
    );
  };

  const onSortChange = (sortProps: any) => {
    props.setSorting((current) => ({
      ...current,
      commonDoc: {
        column: sortProps.sortField,
        sort: sortProps.sortOrder,
      },
    }));
  };
  return (
    <DataTable
      key={"commonDocumentsGrid"}
      loading={value.isLoadingCommonDocsData}
      className={`ul-table`}
      value={commonDocs}
      sortMode="single"
      resizableColumns
      removableSort
      responsiveLayout="scroll"
      sortField={props.sorting.commonDoc.column}
      sortOrder={props.sorting.commonDoc.sort}
      onSort={onSortChange}
      showGridlines
      columnResizeMode="expand"
    >
      <Column field="index" header="index" hidden={true}></Column>
      {Object.entries(props.isJapanese ? fieldsJapanese : fieldsEnglish).map(
        (col) => {
          if (col[0] === "documentFilename")
            return (
              <Column
                key={`commonDocumentsGrid-${col[0]}`}
                field={col[0]}
                header={col[1]}
                body={filenameTemplate}
                sortable
              ></Column>
            );

          return (
            <Column
              key={`commonDocumentsGrid-${col[0]}`}
              field={col[0]}
              header={col[1]}
              sortable
              body={
                !props.isCollapsed
                  ? (e) => props.collapsedTemplate(e, col[0])
                  : null
              }
            ></Column>
          );
        }
      )}
    </DataTable>
  );
};

export default CommonDocuments;
