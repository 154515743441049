import { useCallback, useEffect, useState } from "react";
import Refiners from "../../../components/refiners/Refiners";
import { debounce } from "lodash";
import TemplateListRequestModel, {
  DateModel,
  LabelModel,
} from "../../../shared/models/service-models/TemplateListRequest.model";
import TemplateService from "../../../services/TemplateService";
import moment from "moment";
import ProjectTemplateMappingService from "../../../services/ProjectTemplateMappingService";
import { FlexProjectTemplateDetailModel } from "../../../shared/models/FlexProjectTemplateTaskDetail.model";

interface RefinerModel {
  [key: string]: string | boolean | Array<any> | undefined;
  field: string;
  label: string;
  loading: boolean;
  options: Array<any>;
  entity: string;
  element?: string;
  hideSelectAll?: boolean;
}

export interface ProjectTemplateDateRefiner {
  [key: string]: DateModel | undefined;
  publishedDate?: DateModel;
  modifiedDateUtc?: DateModel;
  createdDateUtc?: DateModel;
}

type ProjectTemplateListRefinerProps = {
  request: TemplateListRequestModel;
  setRequest: Function;
};

const ProjectTemplateListRefiners = (
  props: ProjectTemplateListRefinerProps
) => {
  const [customSearchText, setCustomSearchText] = useState("");
  const [refiners, setRefiners] = useState<RefinerModel[]>([
    {
      field: "certificateScheme",
      label: "Certificate Scheme",
      loading: false,
      options: [],
      entity: "certificateScheme",
      element: "",
    },
    {
      field: "deliveryPath",
      label: "Delivery Path / Agent",
      loading: false,
      options: [],
      entity: "deliveryPath",
      element: "",
    },
    {
      field: "FLEXTemplateName",
      label: "FLEX Template",
      loading: false,
      options: [],
      entity: "FLEXTemplateName",
      element: "Checkbox",
      hideSelectAll: true,
    },
    {
      field: "name",
      label: "GMA WB Templates",
      loading: false,
      options: [],
      entity: "name",
      element: "",
    },
    {
      field: "createdBy",
      label: "GMA WB Template Created by",
      loading: false,
      options: [],
      entity: "createdBy",
      element: "",
    },
    {
      field: "templateStatus",
      label: "GMA WB Template Status",
      loading: false,
      options: [{ label: "Draft" }, { label: "Published" }],
      entity: "templateStatus",
      element: "Checkbox",
    },
    {
      field: "createdDateUtc",
      label: "GMA WB Template Created on",
      loading: false,
      options: [],
      entity: "createdDateUtc",
      element: "Calendar",
    },
    {
      field: "modifiedBy",
      label: "GMA WB Template Updated by",
      loading: false,
      options: [],
      entity: "modifiedBy",
      element: "",
    },
    {
      field: "modifiedDateUtc",
      label: "GMA WB Template Updated on",
      loading: false,
      options: [],
      entity: "modifiedDateUtc",
      element: "Calendar",
    },
    {
      field: "version",
      label: "GMA WB Template Version",
      loading: false,
      options: [],
      entity: "version",
      element: "",
    },
    {
      field: "publishedDate",
      label: "Published Date",
      loading: false,
      options: [],
      entity: "publishedDate",
      element: "Calendar",
    },
  ]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      loadFlexTemplates();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const loadFlexTemplates = async () => {
    const flexTemplatesRefiner = refiners.find(
      (f) => f.field === "FLEXTemplateName"
    );

    updateRefiner(flexTemplatesRefiner!, (f: any) => ({
      ...f,
      isLoading: true,
    }));

    const result =
      await ProjectTemplateMappingService.getFlexTemplateListIntegration()
        .then((flexResponse: FlexProjectTemplateDetailModel[]) => {
          const _flexTemplates = flexResponse.map((data) => {
            return {
              label: data.templateName,
            } as LabelModel;
          });
          return _flexTemplates;
        })
        .catch(() => {
          return [];
        });

    updateRefiner(flexTemplatesRefiner!, (f: any) => ({
      ...f,
      isLoading: false,
      options: [...f.options, ...result],
    }));
  };

  const stringMapper = (item: any) => ({
    label: item,
  });

  const dateFormatter = (date: string | Date) => {
    return date ? moment(date).format("yyyy-MM-DD") : "";
  };

  const handleRefinerFilterRemove = (
    refiner: RefinerModel,
    removedFilterValue: any
  ) => {
    let newRefiners = {};
    if (Array.isArray(props.request[refiner.field])) {
      const array = props.request[refiner.field] as Array<LabelModel>;
      let _removedValue = array.filter((v: any) => v !== removedFilterValue);
      if (_removedValue.length > 0) {
        newRefiners = {
          ...props.request,
          [refiner.field]: array.filter((v: any) => v !== removedFilterValue),
        };
      } else {
        let _selectedRefiners = { ...props.request };
        delete _selectedRefiners[refiner.field];
        newRefiners = {
          ..._selectedRefiners,
        };
      }
      props.setRequest(newRefiners);
    }
  };

  const handleSelectedRefinerRemove = (
    refiner: RefinerModel,
    type?: string
  ) => {
    let _selectedRefiners = { ...props.request };
    if (type) {
      const dateRefiner = _selectedRefiners[refiner.field] as DateModel;
      delete dateRefiner[type!];
      if (Object.keys(dateRefiner).length === 0) {
        delete _selectedRefiners[refiner.field];
        const newRefiners = { ..._selectedRefiners };
        props.setRequest(newRefiners);
        return;
      }
      const newRefiners = { ..._selectedRefiners };
      props.setRequest(newRefiners);
    } else {
      delete _selectedRefiners[refiner.field];
      const newRefiners = { ..._selectedRefiners };
      props.setRequest(newRefiners);
    }
  };

  const handleCalendarDates = (
    refiner: RefinerModel,
    value: any,
    operator?: any
  ) => {
    let newRequest: any;
    let refinerField = props.request[refiner.field] as DateModel;
    if (operator === "DateFrom") {
      newRequest = {
        ...props.request,
        skip: 1,
        [refiner.field]: {
          ...refinerField,
          DateFrom: dateFormatter(value),
        },
      };
    } else if (operator === "DateTo") {
      newRequest = {
        ...props.request,
        skip: 1,
        [refiner.field]: {
          ...refinerField,
          DateTo: dateFormatter(value),
        },
      };
    } else {
      let _request: TemplateListRequestModel = { ...props.request, skip: 1 };
      delete _request[refiner.field];
      newRequest = {
        ..._request,
        [refiner.field]: {
          [operator]: dateFormatter(value),
        },
      };
    }
    props.setRequest(newRequest);
  };

  const handleSelectedRefinerChange = (
    refiner: RefinerModel,
    value: any,
    operator?: string
  ) => {
    if (refiner.element === "Calendar") {
      handleCalendarDates(refiner, value, operator);
    } else {
      if (value.length === 0) {
        const newRefiners: TemplateListRequestModel = {
          ...props.request,
          skip: 1,
        };
        delete newRefiners[refiner.field];
        props.setRequest(newRefiners);
      } else {
        const newRefiners: TemplateListRequestModel = {
          ...props.request,
          skip: 1,
          [refiner.field]: value,
        };
        props.setRequest(newRefiners);
      }
    }
  };

  const handleSelectedRefinersRemove = () => {
    props.setRequest({
      skip: 1,
      take: props.request.take,
      searchString: props.request.searchString,
    });
  };

  const updateRefiner = (refiner: RefinerModel, updateFunc: any) => {
    setRefiners((currRefiners) =>
      currRefiners.map((r) => (refiner.field === r.field ? updateFunc(r) : r))
    );
  };

  const handleRefinerFilter = async (
    refiner: RefinerModel,
    searchPhrase: any
  ) => {
    if (!searchPhrase.trim()) return;
    TemplateService.getProjectTemplateListRefiners(
      searchPhrase,
      refiner.field
    ).then((result) => {
      let newOptions: LabelModel[];
      newOptions = [...result.data.map((item: any) => stringMapper(item))];

      updateRefiner(refiner, (f: RefinerModel) => ({
        ...f,
        loading: false,
        options: newOptions,
      }));
    });
  };

  const debouncedRefinerFilter = useCallback(
    debounce(handleRefinerFilter, 2000),
    [handleRefinerFilter]
  );

  const handleCustomSearch = (searchText: string) => {
    props.setRequest({ ...props.request, searchString: searchText });
  };

  return (
    <>
      <Refiners
        onCustomSearch={handleCustomSearch}
        onCustomSearchTextChange={setCustomSearchText}
        customSearchText={customSearchText}
        showCustomSearch={true}
        refiners={refiners}
        selectedRefiners={props.request}
        onSelectedRefinerChange={handleSelectedRefinerChange}
        onFilterRefiner={(refiner: RefinerModel, searchPhrase: any) => {
          updateRefiner(refiner, (f: any) => ({ ...f, loading: true }));
          debouncedRefinerFilter(refiner, searchPhrase);
        }}
        onRemoveSelectedRefiner={handleSelectedRefinerRemove}
        onRemoveSelectedRefinerFilter={handleRefinerFilterRemove}
        onRemoveSelectedRefiners={handleSelectedRefinersRemove}
      />
    </>
  );
};

export default ProjectTemplateListRefiners;
