import { faFileImage, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgressBar } from "primereact/progressbar";
import { TabPanel, TabView } from "primereact/tabview";
import { useRef, useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProdDetailsAttachments,
  selectProdDetailValues,
} from "../../../../../features/createCertificate/createCertificateSlice";
import CertificateListService from "../../../../../services/CertificateListService";
import classes from "./AddAttachments.module.scss";
import { saveAs } from "file-saver";

const AddAttachments = (props) => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const attachmentTabs = ["Certificate", "Test Report", "Others"];
  const productDetailsValues = useSelector(selectProdDetailValues);

  const [uploadFileList, setUploadFileList] = useState([[], [], []]);

  const allowedFileSize = 4000;
  const allowedFileExtensions = [
    "JPG",
    "JPEG",
    "DOC",
    "DOCX",
    "BMP",
    "GIF",
    "PDF",
    "PNG",
  ];

  const handleChangeCertFiles = (files, attachmentTabIndex) => {
    if (files) {
      let fileArray = Array.from(files);
      if (fileArray && Array.isArray(fileArray) && fileArray.length > 0) {
        uploadFilesSelected(fileArray, attachmentTabIndex);
      }
    }
  };

  const validFileSize = (fileSize, allowedFileSize) => {
    let actualSize = fileSize / 1024 / 1024;
    return actualSize <= allowedFileSize;
  };

  const deleteAttachment = async (attachmentTabIndex, fileIndex) => {
    let fileId = uploadFileList[attachmentTabIndex][fileIndex].id;
    let deleteStoreCertFile = {
      fileId: fileId,
      fileName: uploadFileList[attachmentTabIndex][fileIndex].name,
    };

    CertificateListService.deleteAttachment(deleteStoreCertFile).then(
      (result) => {
        setUploadFileList((prevUploadFileList) => {
          let newFileUploadList = JSON.parse(
            JSON.stringify(prevUploadFileList)
          );

          newFileUploadList[attachmentTabIndex] = newFileUploadList[
            attachmentTabIndex
          ].filter((i) => i.id !== fileId);

          dispatch(updateProdDetailsAttachments(newFileUploadList));
          return newFileUploadList;
        });
      }
    );
  };

  const uploadFilesSelected = (fileArray, attachmentTabIndex) => {
    for (let index = 0; index < fileArray.length; index++) {
      fileArray[index].isRemovedFromUI = false;
      fileArray[index].isRemovedFromDb = false;
      fileArray[index].uploadPercent = 0;
      fileArray[index].fileResult = "";
      fileArray[index].isDeleting = false;
      fileArray[index].isUploading = false;

      if (validFileSize(fileArray[index].size, allowedFileSize) === false) {
        let messageDetail =
          fileArray[index].name +
          " failed to attach, file exceed size " +
          allowedFileSize +
          " GB";
        toast.current.show({
          severity: "error",
          summary: "Attach File",
          detail: messageDetail,
          life: 3000,
        });

        fileArray.splice(index, 1);
        index--;
      }
    }

    let newUploadFileList = null;
    setUploadFileList((prevUploadFileList) => {
      newUploadFileList = JSON.parse(JSON.stringify(prevUploadFileList));

      fileArray.forEach((file) => {
        newUploadFileList[attachmentTabIndex].push(file);
        let fileIndex = newUploadFileList[attachmentTabIndex].length - 1;
        uploadSingleFile(attachmentTabIndex, fileIndex, newUploadFileList);
      });

      return newUploadFileList;
    });
  };

  const uploadProgressEvent = (
    progressEvent,
    attachmentTabIndex,
    fileIndex
  ) => {
    const { loaded, total } = progressEvent;
    const percent = Math.floor((loaded * 100) / total);

    if (percent !== 100) {
      setUploadFileList((prevUploadFileList) => {
        let newUploadFileList = JSON.parse(JSON.stringify(prevUploadFileList));
        newUploadFileList[attachmentTabIndex][fileIndex].uploadPercent =
          percent;
        return newUploadFileList;
      });
    }
  };

  const uploadSingleFile = async function (
    attachmentTabIndex,
    fileIndex,
    newUploadFileList
  ) {
    let file = newUploadFileList[attachmentTabIndex][fileIndex];
    const fileExtension = file.name.split(".").pop().toUpperCase();
    const isFileTypeValid = allowedFileExtensions.includes(fileExtension);
    const isDuplicate =
      uploadFileList[attachmentTabIndex]?.some((x) => x.name == file.name) ===
      true;
    const isFileValid = !isDuplicate && isFileTypeValid;

    newUploadFileList[attachmentTabIndex][fileIndex] = {
      ...newUploadFileList[attachmentTabIndex][fileIndex],
    };
    newUploadFileList[attachmentTabIndex][fileIndex].name = file.name;
    newUploadFileList[attachmentTabIndex][fileIndex].isValid = isFileValid;

    if (isFileValid) {
      newUploadFileList[attachmentTabIndex][fileIndex].uploadPercent = 0;
      newUploadFileList[attachmentTabIndex][fileIndex].isUploading = true;

      const uploadFormData = new FormData();
      uploadFormData.append("file", file, file.name);

      newUploadFileList[attachmentTabIndex][fileIndex].fileResult =
        "Uploading file...";

      const response = await CertificateListService.addCertAttachment(
        uploadFormData,
        (res) => uploadProgressEvent(res, attachmentTabIndex, fileIndex)
      );

      if (response) {
        setUploadFileList((prevUploadFileList) => {
          let updatedUploadFileUploadList = JSON.parse(
            JSON.stringify(prevUploadFileList)
          );
          updatedUploadFileUploadList[attachmentTabIndex][
            fileIndex
          ].fileResult = "File successfully uploaded.";
          updatedUploadFileUploadList[attachmentTabIndex][
            fileIndex
          ].uploadPercent = 100;
          updatedUploadFileUploadList[attachmentTabIndex][
            fileIndex
          ].isUploaded = true;
          updatedUploadFileUploadList[attachmentTabIndex][fileIndex].id =
            response;
          updatedUploadFileUploadList[attachmentTabIndex][
            fileIndex
          ].isSuccess = true;

          dispatch(updateProdDetailsAttachments(updatedUploadFileUploadList));
          return updatedUploadFileUploadList;
        });
      }
    } else {
      setUploadFileList((prevUploadFileList) => {
        let updatedUploadFileUploadList = JSON.parse(
          JSON.stringify(prevUploadFileList)
        );

        updatedUploadFileUploadList[attachmentTabIndex][
          fileIndex
        ].isFileTypeValid = isFileTypeValid;

        updatedUploadFileUploadList[attachmentTabIndex][fileIndex].isDuplicate =
          isDuplicate;

        dispatch(updateProdDetailsAttachments(updatedUploadFileUploadList));
        return updatedUploadFileUploadList;
      });
    }
  };

  const displayValueTemplate = (value) => {
    return <></>;
  };

  const downloadFile = async (file) => {
    const data = {};
    data.fileId = file.id;
    data.fileName = file.name;

    await CertificateListService.downloadCertificateAttachment(data).then(
      function (response) {
        if (response) {
          saveAs(response, file.name);
        }
      }
    );
  };

  useEffect(() => {
    if (productDetailsValues) {
      if (productDetailsValues.attachments) {
        setUploadFileList(productDetailsValues.attachments);
      }
    } else {
      setUploadFileList([[], [], []]);
    }
  }, [productDetailsValues]);

  return (
    <>
      <div className={classes["new-cert-prod-dtls-col-hdr"]}>
        Attachments
        <label className={classes["new-cert-prod-dtls-col-hdr-lbl"]}>
          {" "}
          (Maximum upload file size: 4GB)
        </label>
      </div>
      <div
        className={`${classes["new-cert-prod-dtls-fl-upld-div"]} new-cert-prod-dtls-fl-upld-div`}
      >
        <div className="new-cert-prod-dtls-overrides">
          <TabView style={{ height: "100%" }}>
            {attachmentTabs.map((tab, tabIndex) => (
              <TabPanel
                className="new-cert-prod-dtls-tab-panel"
                header={tab}
                style={{ height: "100%" }}
                key={"tab-panel-" + tab}
              >
                <div style={{ height: "100%" }}>
                  <div className={classes["fl-upld-col-file-hdr"]}>
                    <FileUploader
                      disabled={props.disabled}
                      handleChange={(files) =>
                        handleChangeCertFiles(files, tabIndex)
                      }
                      name="file"
                      multiple={true}
                      children={
                        <div
                          className={`ul-grid__row ${classes["fl-upld-row"]}`}
                        >
                          <div
                            className={`ul-grid__column -x40 ${classes["fl-upld-col-left"]}`}
                          >
                            <button className="ul-button -app-secondary -medium">
                              SELECT FILES
                            </button>
                          </div>
                          <div
                            className={`ul-grid__column -x60 ${classes["fl-upld-col-right"]}`}
                          >
                            Drop files here to upload
                          </div>
                        </div>
                      }
                      classes={classes["fl-upld-btn-div"]}
                    />
                  </div>
                  <div
                    className={`fl-upld-col-file-list-div ${classes["fl-upld-col-file-list-div"]}`}
                  >
                    {uploadFileList &&
                      uploadFileList[tabIndex] &&
                      uploadFileList[tabIndex].length > 0 && (
                        <>
                          {uploadFileList[tabIndex].map((item, fileIndex) => {
                            return (
                              <div
                                key={
                                  "fl-upld-file-item-" +
                                  tabIndex +
                                  "-" +
                                  fileIndex
                                }
                                className={classes["fl-upld-col-file-item"]}
                              >
                                <div className="ul-grid__row">
                                  <div
                                    className={`ul-grid__column -x10 d-flex justify-content-center align-content-center ${classes["fl-upld-img-icon"]}`}
                                  >
                                    <FontAwesomeIcon
                                      icon={faFileImage}
                                      size="xl"
                                    />
                                  </div>
                                  <div className="ul-grid__column -x70">
                                    {item.uploadPercent === 100 &&
                                      item.isUploaded && (
                                        <a
                                          className={
                                            classes[
                                              "new-cert-prod-dtls-att-link"
                                            ]
                                          }
                                          onClick={() => downloadFile(item)}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    {item.uploadPercent < 100 && (
                                      <div> {item.name} </div>
                                    )}
                                    {item.isValid ? (
                                      <>
                                        {item.uploadPercent === 100 &&
                                          item.id && (
                                            <div
                                              className={
                                                item.isSuccess
                                                  ? classes["success"]
                                                  : ""
                                              }
                                            >
                                              {item.fileResult}
                                            </div>
                                          )}
                                        {item.uploadPercent >= 0 &&
                                          item.uploadPercent < 100 &&
                                          !item.id && (
                                            <div>Uploading file...</div>
                                          )}
                                      </>
                                    ) : (
                                      <div className={classes["invalid-file"]}>
                                        {!item.isFileTypeValid &&
                                          "File type not allowed. "}
                                        {item.isDuplicate &&
                                          "Duplicate file already exists."}
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className={`ul-grid__column -x20 ${classes["fl-upld-prcnt-div"]}`}
                                  >
                                    {item.uploadPercent === 100 && item.id && (
                                      <span
                                        className={classes["fl-upl-del-icon"]}
                                        onClick={() =>
                                          deleteAttachment(tabIndex, fileIndex)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTimes} />
                                      </span>
                                    )}
                                  </div>
                                </div>
                                {item.isValid && (
                                  <div className={classes["progressbar-div"]}>
                                    <ProgressBar
                                      value={item.uploadPercent}
                                      displayValueTemplate={
                                        displayValueTemplate
                                      }
                                    ></ProgressBar>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </>
                      )}
                  </div>
                </div>
              </TabPanel>
            ))}
          </TabView>
        </div>
      </div>
    </>
  );
};

export default AddAttachments;
