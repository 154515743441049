import useProjectTimestamp from "../../../../../hooks/useProjectTimestamp";
import ECDAdjustmentsConfirmationDialog from "../../../../project-management-pmr/shared/components/ecd-adjustments-confirmation-dialog/ECDAdjustmentsConfirmationDialog";
import TimeEntryModalSourceType from "../../../../project-management-pmr/shared/enums/time-entry-modal-source-type";
import TimeEntryModal from "../../../../project-management-pmr/time-entry-modal/TimeEntryModal";

type Props = {
  projectTimestampHook: ReturnType<typeof useProjectTimestamp>;
  onECDAdjustmentsConfirm: (adjustECDProjectLineIds: string[]) => void;
};

const TimestampElements = ({
  projectTimestampHook,
  onECDAdjustmentsConfirm,
}: Props) => {
  return (
    <>
      <ECDAdjustmentsConfirmationDialog
        onCancel={projectTimestampHook.onCancelECDAdjustmentsPrompt}
        onConfirm={onECDAdjustmentsConfirm}
        e2eProjectLineECDAdjustments={
          projectTimestampHook.e2eProjectLineECDAdjustments
        }
        visible={projectTimestampHook.ecdAdjustmentsDialogVisible}
        e2eProjectLineIds={projectTimestampHook.currentProjectLineIds}
      />

      {projectTimestampHook.timeEntryModalVisible && (
        <TimeEntryModal
          closeTimeEntryModal={() =>
            projectTimestampHook.setTimeEntryModalVisible(false)
          }
          visible={projectTimestampHook.timeEntryModalVisible}
          e2eProjectLineFlexTaskIds={
            projectTimestampHook.taskIdsWithInvalidTimeEntry
          }
          timeEntryModalSourceType={TimeEntryModalSourceType.TimeStamp}
        />
      )}
    </>
  );
};

export default TimestampElements;
