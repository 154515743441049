import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import classes from "./CloseIcon.module.scss";

const CloseIcon = ({ onCloseEvent }) => {
  const onClose = () => {
    onCloseEvent();
  };
  return (
    <FontAwesomeIcon
      icon={faXmark}
      onClick={onClose}
      className={classes["icon"]}
    />
  );
};

export default CloseIcon;
