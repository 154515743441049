const certificateRefinerColumns = [
    { Id: 'certificateNumber', Value: 'Certificate Number'},
    { Id: 'certificateType', Value: 'Certificate Scheme'},    
    { Id: 'status', Value: 'Certificate Status'},     
    { Id: 'issuingBody', Value: 'Certification Body'},  
    { Id: 'createdOn', Value: 'Created On'}, 
    { Id: 'expirationDate', Value: 'Expiration Date'}, 
    { Id: 'issueDate', Value: 'Issue Date'},
    { Id: 'mark', Value: 'Mark'},   
    { Id: 'partySiteNumber', Value: 'Product Owner Party Site Number'},
    { Id: 'ccn', Value: 'Scope / CCN'},
    { Id: 'updatedOn', Value: 'Updated On'}, 
    { Id: 'withdrawnDate', Value: 'Withdrawn Date'}, 
];

export default certificateRefinerColumns;
