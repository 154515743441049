import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./ProjectGridLegend.module.scss";
const ProjectGridLegend = () => {
  return (
    <>
      <span>
        <span>Milestone Status: </span>
        <span className={`${classes["iconSpacer"]}`}></span>
        <FontAwesomeIcon
          icon={faCircle}
          className={`${classes["inProgressIcon"]}  ${classes["iconSpacer"]}`}
          size={"xs"}
        />
        <span className={`${classes["iconLabelSpacer"]}`}>In Progress</span>

        <FontAwesomeIcon
          icon={faCircle}
          className={`${classes["completedIcon"]}  ${classes["iconSpacer"]}`}
          size={"xs"}
        />
        <span className={`${classes["iconLabelSpacer"]}`}>Completed</span>

        <FontAwesomeIcon
          icon={faCircle}
          className={`${classes["overdueIcon"]}  ${classes["iconSpacer"]}`}
          size={"xs"}
        />
        <span className={`${classes["iconLabelSpacer"]}`}>Overdue</span>

        <FontAwesomeIcon
          icon={faCircle}
          className={`${classes["notYetStartedIcon"]}  ${classes["iconSpacer"]}`}
          size={"xs"}
        />
        <span className={`${classes["iconLabelSpacer"]}`}>Not Yet Started</span>
      </span>
    </>
  );
};

export default ProjectGridLegend;
