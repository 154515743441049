import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedViewBy,
  updateViewBy,
  updateShowFlexTasks,
  selectSelectAll,
  updateSelectAll,
  selectSelectFavoritesModel,
  updateFavoritesModel,
  selectShowFlexTasks,
  selectRequest,
  updateRequest,
} from "../../../features/projectManagement/projectManagementSlice";
import classes from "./ProjectListHeader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faMaximize,
  faPencil,
  faSlash,
  faStamp,
  faStar,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import FavoriteTypesEnum from "../../../shared/favorites/FavoriteTypesEnum";
import {
  IProjectManagementViewByOption,
  projectManagementViewByOptions,
} from "../../../utils/constants/project-management.constants";
import { ProjectManagementViewByEnum } from "../../../enums/ProjectManagementViewBy";

const ProjectListHeader = (props: any) => {
  const { onHandleListLoading } = props;
  const dispatch = useDispatch();
  const selectAll = useSelector(selectSelectAll);
  const request = useSelector(selectRequest);
  const selectedView = useSelector(selectSelectedViewBy);
  const selectFavoritesModel = useSelector(selectSelectFavoritesModel);
  const showFlexTasks = useSelector(selectShowFlexTasks);

  const stampOptions = [{ id: 1, name: "stamp" }];

  const timeStampOptions = [{ id: 1, name: "time stamp" }];
  const [favoritTypeId, setFavoriteTypeId] = useState(
    FavoriteTypesEnum.MyProject
  );

  const [selectedStamp, setSelectedStamp] = useState(stampOptions[0]);
  const [selectedTimeStamp, setSelectedTimeStamp] = useState(
    timeStampOptions[0]
  );

  const viewByOptions: IProjectManagementViewByOption[] =
    projectManagementViewByOptions;
  const handleFlexTaskSwitch = (props: any) => {
    dispatch(updateShowFlexTasks(props.checked));

    dispatch(
      updateFavoritesModel({
        property: "isFlexTaskOnly",
        value: props.checked,
      })
    );
  };

  const handleSelectedViewSwitch = (props: any) => {
    dispatch(updateViewBy(props.value));

    dispatch(
      updateFavoritesModel({
        property: "viewBy",
        value: props.value,
      })
    );

    onHandleListLoading(props.value);
  };

  const handleSelectAll = (props: any) => {
    dispatch(updateSelectAll(props));
  };

  const stampOptionTemplate = (option: any) => {
    return (
      <div key={option.id}>
        <FontAwesomeIcon icon={faStamp} />
      </div>
    );
  };

  const selectedStampTemplate = (option: any, props: any) => {
    return (
      <div key={option.id}>
        <FontAwesomeIcon icon={faStamp} size="xs" />
      </div>
    );
  };

  const timeStampOptionTemplate = (option: any) => {
    return (
      <div key={option.id}>
        <FontAwesomeIcon icon={faStopwatch} />
      </div>
    );
  };

  const selectedTimeStampTemplate = (option: any, props: any) => {
    return (
      <div key={option.id}>
        <FontAwesomeIcon icon={faStopwatch} size="xs" />
      </div>
    );
  };
  const handleFavoriteSelect = (props: any) => {
    dispatch(updateViewBy(props["viewBy"]));
    dispatch(
      updateRequest({ property: "take", value: props["recordsPerPage"] })
    );
    dispatch(updateShowFlexTasks(props.isFlexTaskOnly));
    onHandleListLoading(props["viewBy"]);
  };
  const handleFavoriteInit = (props: any) => {
    let selectedViewCode = ProjectManagementViewByEnum.GmaWbProject;

    if (props) {
      if (props["viewBy"].code != selectedView.code) {
        dispatch(updateViewBy(props["viewBy"]));
        dispatch(
          updateFavoritesModel({
            property: "viewBy",
            value: props["viewBy"],
          })
        );
        selectedViewCode = props["viewBy"].code;
      }

      if (props["isFlexTaskOnly"] != showFlexTasks) {
        handleFlexTaskSwitch({ checked: props["isFlexTaskOnly"] });
      }

      if (props["recordsPerPage"] != request.take) {
        dispatch(
          updateRequest({ property: "take", value: props["recordsPerPage"] })
        );
      }
    } else {
      dispatch(
        updateFavoritesModel({
          property: "viewBy",
          value: viewByOptions.find((x) => x.code == selectedViewCode),
        })
      );
    }

    onHandleListLoading(viewByOptions.find((x) => x.code == selectedViewCode));
  };

  return (
    <div className={`${classes["project-header-container"]}`}>
      <div className={`${classes["left-section"]}`}>
        <div>
          <Checkbox
            onChange={(e: any) => handleSelectAll(e.checked)}
            checked={selectAll}
            className={`${classes["flexTaskToggle"]}`}
          />
        </div>
        <div className={`${classes["vl"]}`}></div>
        <div> View by: </div>
        <div>
          <Dropdown
            style={{ width: "200px" }}
            className={`${classes["dropdownViewBy"]}`}
            options={viewByOptions}
            optionLabel="name"
            value={selectedView}
            onChange={handleSelectedViewSwitch}
          />
        </div>
        <div> FLEX Tasks only </div>
        <div>
          <SwitchComponent
            id="checked"
            checked={showFlexTasks}
            cssClass={`${classes["flexTaskToggle"]}`}
            change={handleFlexTaskSwitch}
          ></SwitchComponent>
        </div>
        <div className={`${classes["vl"]}`}></div>
        <div title="Add Time Stamp">
          <Dropdown
            className={`${classes["dropDownPadding"]}`}
            value={selectedStamp}
            options={stampOptions}
            onChange={() => {}}
            optionLabel="stamp"
            valueTemplate={selectedStampTemplate}
            itemTemplate={stampOptionTemplate}
          />
        </div>
        <div title="Edit Time Stamp">
          <button type="button" className={`ul-button ${classes["btn-icon"]}`}>
            <span className="fa-layers fa-fw fa-lg">
              <FontAwesomeIcon icon={faStamp} />
              <FontAwesomeIcon icon={faPencil} transform="shrink-9" inverse />
            </span>
          </button>
        </div>
        <div>
          <button
            type="button"
            className={`ul-button ${classes["btn-icon"]}`}
            title="Remove Time Stamp"
          >
            <span className="fa-layers fa-fw fa-lg">
              <FontAwesomeIcon icon={faStamp} />
              <FontAwesomeIcon icon={faSlash} />
            </span>
          </button>
        </div>
        <div className={`${classes["vl"]}`}></div>
        <div title="Edit Time Entry">
          <Dropdown
            className={`${classes["dropDownPadding"]}`}
            value={selectedTimeStamp}
            options={timeStampOptions}
            onChange={() => {}}
            optionLabel="time stamp"
            valueTemplate={selectedTimeStampTemplate}
            itemTemplate={timeStampOptionTemplate}
          />
        </div>
        <div>
          <button
            type="button"
            className={`ul-button ${classes["btn-icon"]}`}
            title="Generate Status Report"
          >
            <FontAwesomeIcon icon={faFileInvoice}></FontAwesomeIcon>
          </button>
        </div>
      </div>
      <div className={`${classes["right-section"]}`}>
        <div>
          <button
            type="button"
            className={`ul-button ${classes["btn-icon"]}`}
            title="Favorites"
          >
            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
          </button>
        </div>
        <div>
          <button
            type="button"
            className={`ul-button ${classes["btn-icon"]}`}
            title="Expand Rows"
          >
            <FontAwesomeIcon icon={faMaximize}></FontAwesomeIcon>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectListHeader;
