const Remarks = ({ remarks }) => {
  return (
    <div className="pt-10">
      <div>Remarks</div>
      <div>{remarks}</div>
    </div>
  );
};

export default Remarks;
