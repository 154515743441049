import { createSlice } from "@reduxjs/toolkit";
import FACTORY_SITE_INFO_SOURCE from "../../pages/general-product-information/company-details/factory-site-information/FACTORY_SITE_INFO_SOURCE";

export const customerInformationInitialState = {
  companyId: null,
  partySiteNumber: "",
  hasSelectedPsn: false,
  hasSelectedCompanyName: false,
  companyName: "",
  streetAddress: "",
  city: "",
  country: "",
  zipCode: "",
  stateOrProvince: "",
  contactPerson: "",
  phone: "",
  title: "",
  email: "",
  website: "",
  directorName: "",
  fax: "",
  isSelected: true,
};

export const voltageDetailsInitialState = {
  voltageType: "DC",
  ratedCurrent: "",
  productRatings: "",
  operatingTemperatureRange: "",
  operationClasses: [],
  hasOtherOperationClass: false,
  otherOperationClass: "",
  harmonizedShippingCode: "",
  productClassificationClass1: false,
  productClassificationClass2: false,
  connectsToMainVoltage: false,
  externalPowerSupplyWillBeShipped: false,
  containsBattery: false,
  batteryType: "",
  isOtherBatteryType: false,
  otherBatteryType: "",
  isBatteryUserReplaceable: false,
  willBatteryBeShipped: false,
};

export const initialRadioState = {
  frequencyBand: "",
  operationFrequency: "",
  antennaGain: "",
  emissionDesignation: "",
  transmissionPowerOrPowerRange: "",
  bandwidth: "",
  channelSpacing: "",
  radioTechnology: "",
  otherRadioTechnology: "",
  radioTechnologyTypes: [],
  otherRadioTechnologyType: "",
  modulationTypes: [],
  otherModulationType: "",
};

const initialState = {
  gPICustomerInputValidationResult: null,
  formName: "",
  companyDetails: {
    applicantInformation: customerInformationInitialState,
    agentInformation: {
      ...customerInformationInitialState,
      isAgentInformationAvailable: false,
    },
    manufacturerInformation: {
      ...customerInformationInitialState,
      isSameAsApplicant: false,
    },
    ulFileNumber: {
      hasExistingULFileNumber: false,
      isULCBCertificateNumber: false,
      isOtherGMACertificate: false,
      fileNumber: "",
      volumeNumber: null,
      volumeNumberList: [],
      sectionNumber: null,
      sectionNumberList: [],
      certificateId: null,
      certificateScheme: "",
      certificateNumber: "",
      shouldCompareCertificationDetails: false,
    },
    factorySiteInformation: {
      factoriesCount: 1,
      hasFetchedCertDetails: false,
      factorySites: [
        {
          source: FACTORY_SITE_INFO_SOURCE.MANUAL_INPUT,
          isSelected: true,
          isFromCertDetails: false,
          ...customerInformationInitialState,
        },
      ],
    },
  },
  productDetails: {
    productType: {
      isModelsAutoPopulatedByCertificateSelection: false,
      productTypeOptions: [],
      productTypes: [],
      productName: "",
      baseModel: null,
      modelVariants: [],
      marketingName: "",
      productDescription: "",
      brandOrTrademark: "",
      brandOrTrademarkAttachments: [],
      isModelsModified: null,
    },
    voltageDetails: voltageDetailsInitialState,
    radioDetails: {
      radioCount: 1,
      otherEquipmentRadioType: "",
      radioSpecificationComment: "",
      equipmentRadioTypes: [],
      radios: [initialRadioState],
    },
  },
  ulProjectHandlerDetails: {
    ulProjectHandler: {
      officeName: "",
      officeAddress: "",
      emailAddress: "",
      telephoneNumber: "",
      name: "",
      country: "",
    },
  },
  localRepImporterInformation: [],
  isReadOnly: false,
  isSaveDisabled: true,
  isLoadingCounter: 0,
  finishedByCustomerEmail: "",
  isGiFormEditable: true,
  customerLinkId: "",
  manufacturerFromCertificate: customerInformationInitialState,
  keptCustomerDataDiscrepancies: [],
  existingCertDetails: null,
  validationBannerStatusType: "info",
  isShowValidationBanner: false,
  workflowPackageId: null,
};

export const createGeneralProductInformationSlice = createSlice({
  name: "generalProductInformationSlice",
  initialState,
  reducers: {
    updateGeneralProductInfoId: (state, action) => {
      state.id = action.payload;
    },
    updateHasFinalizedByCustomer: (state, action) => {
      state.hasFinalizedByCustomer = action.payload;
    },
    updateFormName: (state, action) => {
      state.gpiFormName = action.payload;
    },
    updateCompanyDetails: (state, action) => {
      state.companyDetails = action.payload;
    },
    updateApplicantInformation: (state, action) => {
      state.companyDetails.applicantInformation = action.payload;
    },
    updateAgentInformation: (state, action) => {
      state.companyDetails.agentInformation = action.payload;
    },
    updateManufacturerInformation: (state, action) => {
      state.companyDetails.manufacturerInformation = action.payload;
    },
    updateULFileNumber: (state, action) => {
      state.companyDetails.ulFileNumber = action.payload;
    },
    updateShouldCompareCertificationDetails: (state, action) => {
      state.companyDetails.ulFileNumber.shouldCompareCertificationDetails =
        action.payload;
    },
    updateFactorySiteInformation: (state, action) => {
      state.companyDetails.factorySiteInformation = action.payload;
    },
    updateMultipleFactorySiteInfoByCopySource: (state, action) => {
      state.companyDetails.factorySiteInformation.factorySites =
        state.companyDetails.factorySiteInformation.factorySites.map((f) => ({
          ...(f.source === action.payload.source
            ? { ...f, ...action.payload.newFactorySite }
            : f),
        }));
    },
    updateProductDetails: (state, action) => {
      state.productDetails = action.payload;
    },
    updateProductType: (state, action) => {
      state.productDetails.productType = action.payload;
    },
    updateProductTypeProductTypes: (state, action) => {
      state.productDetails.productType.productTypes = action.payload;
    },
    appendProductTypeProductTypes: (state, action) => {
      const newProductTypes = action.payload.filter(
        (x) =>
          !state.productDetails.productType.productTypes.some(
            (y) => y.hierarchyValue === x.hierarchyValue
          )
      );

      state.productDetails.productType.productTypes = [
        ...state.productDetails.productType.productTypes,
        ...newProductTypes,
      ];
    },
    updateProductTypeId: (state, action) => {
      state.productDetails.productType.productTypeId = action.payload;
    },
    updateIsModelsAutoPopulatedByCertificateSelection: (state, action) => {
      state.productDetails.productType.isModelsAutoPopulatedByCertificateSelection =
        action.payload;
    },
    updateBaseModel: (state, action) => {
      state.productDetails.productType.baseModel = action.payload;
    },
    updateModelVariants: (state, action) => {
      state.productDetails.productType.modelVariants = action.payload;
    },
    updateProductTypeOptions: (state, action) => {
      state.productDetails.productType.productTypeOptions = action.payload;
    },
    resetFactorySiteInformation: (state) => {
      state.companyDetails.factorySiteInformation = {
        factoriesCount: 1,
        hasFetchedCertDetails: false,
        factorySites: [
          {
            source: FACTORY_SITE_INFO_SOURCE.MANUAL_INPUT,
            isSelected: true,
            isFromCertDetails: false,
            ...customerInformationInitialState,
          },
        ],
      };
    },
    resetManufacturerInformation: (state) => {
      state.companyDetails.manufacturerInformation = {
        ...customerInformationInitialState,
        isSameAsApplicant: false,
      };
    },
    resetModelDetails: (state) => {
      state.productDetails.productType.productTypeId = "";
      state.productDetails.productType.productType = "";
      state.productDetails.productType.baseModel = null;
      state.productDetails.productType.modelVariants = [];
    },
    updateVoltageDetails: (state, action) => {
      state.productDetails.voltageDetails = action.payload;
    },
    updateULProjectHandleDetails: (state, action) => {
      state.ulProjectHandlerDetails.ulProjectHandler = action.payload;
    },
    updateLocalRepImporters: (state, action) => {
      state.localRepImporterInformation = action.payload;
    },
    updateRadioDetails: (state, action) => {
      state.productDetails.radioDetails = action.payload;
    },
    updateIsReadOnly: (state, action) => {
      state.isReadOnly = action.payload;
    },
    updateIsSaveDisabled: (state, action) => {
      state.isSaveDisabled = action.payload;
    },
    updateIsLoading: (state, action) => {
      if (action.payload) {
        state.isLoadingCounter = state.isLoadingCounter + 1;
      } else if (state.isLoadingCounter > 0) {
        state.isLoadingCounter = state.isLoadingCounter - 1;
      }
    },
    updateGPICustomerInputValidationResult: (state, action) => {
      state.gPICustomerInputValidationResult = action.payload;
    },
    updateGPIFormConfig: (state, action) => {
      state.gPIFormConfig = action.payload;
    },
    updateFinishedByCustomerEmail: (state, action) => {
      state.finishedByCustomerEmail = action.payload;
    },
    updateIsGiFormEditable: (state, action) => {
      state.isGiFormEditable = action.payload;
    },
    updateCustomerLinkId: (state, action) => {
      state.customerLinkId = action.payload;
    },
    updateManufacturerFromCertificate: (state, action) => {
      state.manufacturerFromCertificate = action.payload;
    },
    updateCustomerLastChange: (state, action) => {
      state.customerLastChange = action.payload;
    },
    updateKeptCustomerDataDiscrepancies: (state, action) => {
      state.keptCustomerDataDiscrepancies = action.payload;
    },
    addKeptCustomerDataDiscrepancies: (state, action) => {
      state.keptCustomerDataDiscrepancies = [
        ...state.keptCustomerDataDiscrepancies,
        action.payload,
      ];
    },
    updateExistingCertDetails: (state, action) => {
      state.existingCertDetails = action.payload;
    },
    updateManufacturerIsSameAsApplicant: (state, action) => {
      state.companyDetails.manufacturerInformation.isSameAsApplicant =
        action.payload;
    },
    updateIsModelsModified: (state, action) => {
      state.productDetails.productType.isModelsModified = action.payload;
    },
    updateIsRegisterAllFactory: (state, action) => {
      state.isRegisterAllFactory = action.payload;
    },
    updateValidationBannerStatusType: (state, action) => {
      state.validationBannerStatusType = action.payload;
    },
    updateIsShowValidationBanner: (state, action) => {
      state.isShowValidationBanner = action.payload;
    },
    updateULFileNumberHasLoadedInitially: (state, action) => {
      state.companyDetails.ulFileNumber.hasLoadedInitially = action.payload;
    },
    updateWorkflowPackageId: (state, action) => {
      state.workflowPackageId = action.payload;
    },
  },
});

export const {
  updateManufacturerIsSameAsApplicant,
  updateHasFinalizedByCustomer,
  updateIsModelsAutoPopulatedByCertificateSelection,
  updateMultipleFactorySiteInfoByCopySource,
  updateFormName,
  updateCompanyDetails,
  updateApplicantInformation,
  updateAgentInformation,
  updateULFileNumber,
  updateShouldCompareCertificationDetails,
  updateManufacturerInformation,
  updateFactorySiteInformation,
  updateProductDetails,
  updateProductType,
  updateProductTypeId,
  updateProductTypeOptions,
  updateVoltageDetails,
  updateGeneralProductInfoId,
  resetFactorySiteInformation,
  resetManufacturerInformation,
  resetModelDetails,
  updateULProjectHandleDetails,
  updateLocalRepImporters,
  updateRadioDetails,
  updateIsReadOnly,
  updateIsSaveDisabled,
  updateIsLoading,
  updateBaseModel,
  updateModelVariants,
  updateGPICustomerInputValidationResult,
  updateGPIFormConfig,
  appendProductTypeProductTypes,
  updateProductTypeProductTypes,
  updateFinishedByCustomerEmail,
  updateIsGiFormEditable,
  updateCustomerLinkId,
  updateManufacturerFromCertificate,
  updateCustomerLastChange,
  addKeptCustomerDataDiscrepancies,
  updateKeptCustomerDataDiscrepancies,
  updateExistingCertDetails,
  updateIsModelsModified,
  updateIsRegisterAllFactory,
  updateValidationBannerStatusType,
  updateIsShowValidationBanner,
  updateULFileNumberHasLoadedInitially,
  updateWorkflowPackageId,
} = createGeneralProductInformationSlice.actions;

export const selectGeneralProductInformation = (state) =>
  state.generalProductInformation;

export const selectApplicantInformation = (state) =>
  state.generalProductInformation.companyDetails.applicantInformation;

export const selectAgentInformation = (state) =>
  state.generalProductInformation.companyDetails.agentInformation;

export const selectManufacturerInformation = (state) =>
  state.generalProductInformation.companyDetails.manufacturerInformation;

export const selectULFileNumber = (state) =>
  state.generalProductInformation.companyDetails.ulFileNumber;

export const selectFactorySiteInformation = (state) =>
  state.generalProductInformation.companyDetails.factorySiteInformation;

export const selectProductType = (state) =>
  state.generalProductInformation.productDetails.productType;

export const selectProductDetails = (state) =>
  state.generalProductInformation.productDetails;

export const selectVoltageDetails = (state) =>
  state.generalProductInformation.productDetails.voltageDetails;

export const selectULProjectHandlerDetails = (state) =>
  state.generalProductInformation.ulProjectHandlerDetails.ulProjectHandler;

export const selectLocalRepImporter = (state) =>
  state.generalProductInformation.localRepImporterInformation;

export const selectRadioDetails = (state) =>
  state.generalProductInformation.productDetails.radioDetails;

export const selectIsReadOnly = (state) =>
  state.generalProductInformation.isReadOnly;

export const selectIsSaveDisabled = (state) =>
  state.generalProductInformation.isSaveDisabled;

export const selectIsLoading = (state) =>
  state.generalProductInformation.isLoadingCounter > 0;

export const selectIsModelsAutoPopulatedByCertificateSelection = (state) =>
  state.generalProductInformation.productDetails.productType
    .isModelsAutoPopulatedByCertificateSelection;

export const selectGPICustomerInputValidationResult = (state) =>
  state.generalProductInformation.gPICustomerInputValidationResult;

export const selectGPIFormConfig = (state) =>
  state.generalProductInformation.gPIFormConfig;

export const selectFinishedByCustomerEmail = (state) =>
  state.generalProductInformation.finishedByCustomerEmail;

export const selectIsGiFormEditable = (state) =>
  state.generalProductInformation.isGiFormEditable;

export const selectCustomerLinkId = (state) =>
  state.generalProductInformation.customerLinkId;

export const selectManufacturerFromCertificate = (state) =>
  state.generalProductInformation.manufacturerFromCertificate;

export const selectCustomerLastChangeSnapshot = (state) =>
  state.generalProductInformation.customerLastChange?.snapshot;

export const selectCustomerLastChangeHistoryId = (state) =>
  state.generalProductInformation.customerLastChange?.id;

export const selectKeptCustomerDataDiscrepancies = (state) =>
  state.generalProductInformation.keptCustomerDataDiscrepancies;

export const selectExistingCertDetails = (state) =>
  state.generalProductInformation.existingCertDetails;

export const selectShouldCompareExistingCertDetails = (state) =>
  state.generalProductInformation.companyDetails.ulFileNumber
    .shouldCompareCertificationDetails;

export const selectGeneralProductInfoId = (state) =>
  state.generalProductInformation.id;

export const selectIsRegisterAllFactory = (state) =>
  state.generalProductInformation.isRegisterAllFactory;

export const selectValidationBannerStatusType = (state) =>
  state.generalProductInformation.validationBannerStatusType;

export const selectIsShowValidationBanner = (state) =>
  state.generalProductInformation.isShowValidationBanner;

export const selectWorkflowPackageId = (state) =>
  state.generalProductInformation.workflowPackageId;

export default createGeneralProductInformationSlice.reducer;
